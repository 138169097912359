import React, { Component } from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import MailIcon from "@material-ui/icons/Mail";
import { createSelector } from "../common/orm";

const getMessages = createSelector(schema => {
    return schema.Message.filter(m => new Date(m.date_expired) >= new Date())
        .orderBy("id", "desc")
        .toRefArray();
});

const styles = theme => ({
    messageIcon: {
        marginRight: 16,
        marginBottom: -5,
        marginTop: 5
    },
    mailIcon: {
        marginTop: 8,
        marginBottom: -8,
        marginRight: 8,
        color: "#495057"
    },
    lastItem: {
        borderBottom: "3px solid " + theme.palette.primary.main
    },
    marginLeft: {
        marginLeft: 24,
        marginTop: -12
    }
});

class Messages extends Component {
    render() {
        const { classes, messages } = this.props;

        if (messages.length === 0) return null;

        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="h3">
                        <RecordVoiceOverIcon className={classes.messageIcon} /> Messages
                    </Typography>
                </Grid>
                {messages.map((m, i, arr) => {
                    return (
                        <Grid item xs={12} className={arr.length - 1 === i && classes.lastItem}>
                            <Typography className={classes.marginLeft}>
                                <MailIcon className={classes.mailIcon} />{" "}
                                <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: m.message }} />
                            </Typography>
                        </Grid>
                    );
                })}
            </>
        );
    }
}

Messages = connect(
    state => ({
        messages: getMessages(state)
    }),
    null
)(Messages);

export default withStyles(styles)(Messages);
