import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import AppContainer from "../common/AppContainer";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import PageHeader from "../common/PageHeader";
import TablePaginationActionsWrapped from "../common/Paginator";
import TextField from "../common/TextField";

import { ProposalView, StaffComment } from "../proposal/models";
import { ProposalReview } from "../home/models";
import { createSelector } from "../common/orm";

const getProposal = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    (state, ownProps) => (state.auth.user ? state.auth.user.role : null),
    (schema, id, auth) => {
        if (auth && auth === "User") {
            return schema.Proposal.filter(x => x.id === parseInt(id)).first();
        } else {
            return schema.ProposalView.filter(x => x.id === parseInt(id)).first();
        }
    }
);
const getComments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (state, ownProps) => (ownProps.location.serach !== "" ? ownProps.location.search.split("=")[1] === "true" : null),
    (schema, id, draft) => {
        return schema.StaffComment.filter(x => x.proposal === id && x.draft === draft)
            .orderBy("step", "asc")
            .toRefArray();
    }
);
const getReview = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (schema, id) => {
        return schema.ProposalReview.filter(x => x.proposal === id).first();
    }
);

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        backgroundColor: "#fff"
    },
    centerAlign: {
        textAlign: "center"
    },
    box: {
        "& textarea": {
            backgroundColor: "white !important",
            color: "black !important"
        }
    }
});

const columnData = [
    { id: "id", numeric: false, label: "Comment ID" },
    { id: "step", numeric: false, label: "Page" },
    { id: "comment", numeric: false, label: "Revision Requested or Comment" }
];

function getSteps() {
    return [
        "Introduction",
        "Project Collaborators",
        "General Information",
        "Narrative",
        "Activities and Milestones",
        "Budget",
        "Attachments",
        "Acquisition and Restoration",
        "Review / Submit"
    ];
}

class StaffFeeBack extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        order: "desc",
        orderBy: ""
    };
    componentDidMount() {
        document.title = "Proposal Feed Back - LCCMR Proposal and Grant Management System";
        const {
            authState,
            match,
            ormProposalReviewLoadDetailChildFilterMany,
            ormStaffCommentLoadDetailChildFilterMany
        } = this.props;
        if (authState && authState.user) {
            ormProposalReviewLoadDetailChildFilterMany({
                proposal: match.params.id
            });
            ormStaffCommentLoadDetailChildFilterMany({
                proposal: match.params.id
            });
        }
    }
    componentDidUpdate() {
        const {
            authState,
            match,
            ormProposalReviewLoadDetailChildFilterMany,
            ormStaffCommentLoadDetailChildFilterMany,
            review
        } = this.props;
        if (!review && authState && authState.user) {
            ormProposalReviewLoadDetailChildFilterMany({
                proposal: match.params.id
            });
            ormStaffCommentLoadDetailChildFilterMany({
                proposal: match.params.id
            });
        }
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };
    render() {
        const { authState, classes, comments, proposal, review } = this.props;
        const { rowsPerPage, order, orderBy, page } = this.state;

        var filtered = [];
        if (authState && authState.user && (authState.user.role === "Staff" || authState.user.role === "Member")) {
            filtered = comments.filter(x => x.comment_for === authState.user.role);
        } else {
            filtered = comments.filter(x => x.comment_for === "Applicant");
        }

        //draft_staff_comments

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title={`Proposal - ${proposal ? proposal.name : ""}`} />
                    <Grid item xs={12}>
                        {proposal && proposal.status === "Final Submitted" && (
                            <Form defaultValues={review}>
                                {formApi => (
                                    <form>
                                        <TextField
                                            className={classes.box}
                                            label="LCCMR Staff Summary Comments"
                                            field={
                                                authState.user.role === "Staff"
                                                    ? "final_member_comments2"
                                                    : "draft_applicant_comments"
                                            }
                                            multiline
                                            fullWidth
                                            disabled
                                        />
                                    </form>
                                )}
                            </Form>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                columnData={columnData}
                                stickyHeader
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                            />
                            <TableBody>
                                {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                    return (
                                        <TableRow key={n.id + n.suffix}>
                                            <CustomTableCell>{n.id}</CustomTableCell>
                                            <CustomTableCell>
                                                <Link to={"/proposal/" + (proposal && proposal.id) + "?step=" + n.step}>
                                                    {getSteps()[n.step]}
                                                </Link>
                                            </CustomTableCell>
                                            <CustomTableCell>{n.comment}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {filtered.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={3} className={classes.centerAlign}>
                                            <caption style={{display:"inline"}}>No Comments Found</caption>
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            {filtered.length > 25 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={3}
                                            count={3}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

StaffFeeBack = connect(
    (state, ownProps) => ({
        authState: state.auth,
        proposal: getProposal(state, ownProps),
        comments: getComments(state, ownProps),
        review: getReview(state, ownProps)
    }),
    {
        ...StaffComment.actions,
        ...ProposalReview.actions,
        ...ProposalView.actions
    }
)(StaffFeeBack);

export default withStyles(styles)(withRouter(StaffFeeBack));
