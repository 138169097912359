import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Form } from "react-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Visibility from "@material-ui/icons/Visibility";

import AppContainer from "../common/AppContainer";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import PageHeader from "../common/PageHeader";
import RadioGroup from "../common/RadioGroup";
import TablePaginationActionsWrapped from "../common/Paginator";
import TextField from "../common/TextField";

import { MemberEvaluation, ProposalReview } from "../home/models";
import { createSelector } from "../common/orm";
import { CircularProgress } from "@material-ui/core";

function getMedian(array) {
    array.sort(function(a, b) {
        return a - b;
    });
    var mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
}

const getProposals = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["year"]),
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (state, ownProps) => (state.auth.user ? state.auth.user.id : null),
    (schema, year, year_id, id) => {
        const setting = schema.SystemSetting.filter(x => x.year && parseInt(x.year) === year).first();

        const evalTwoClosed = setting && setting.member2_date ? new Date() > new Date(setting.member2_date) : false;
        var category;
        if (setting && setting["evaluation2_open"]) {
            return schema.ProposalView.filter(
                x =>
                    x.proposal_unique_id.includes(year) &&
                    (x.selected_presentation === false || x.selected_presentation === true) &&
                    x.secondary_status !== "Withdrawn" &&
                    x.secondary_status !== "Withdrawal Requested"
            )
                .orderBy("proposal_unique_id", "asc")
                .toModelArray()
                .map(view => {
                    var review = view.review_proposal.first();
                    const yearMembers = schema.RFPMember.all()
                        .filter(x => x.year_id === year_id)
                        .toRefArray();
                    var allEvaluations = view.eval_member
                        .all()
                        .filter(x => yearMembers.find(z => z.user === x.user_id))
                        .toModelArray()
                        .map(e => {
                            return {
                                user: schema.User.filter(x => x.id === e.user_id).first(),
                                ...e.ref
                            };
                        });
                    var evaluation = view.eval_member.filter(x => x.user_id === id).first();
                    var temp = evaluation ? evaluation : {};
                    var tempReview = review ? review : {};

                    const selected_total = allEvaluations.length - allEvaluations.filter(x => x.conflict).length;
                    const selected = allEvaluations.filter(x => x.selected).length;
                    const compiled = Math.round((selected / selected_total) * 100);

                    const conflict = allEvaluations
                        .filter(x => x.conflict)
                        .map(x => (x.user ? x.user.first_name + " " + x.user.last_name : "Unknown"))
                        .join(", ");

                    const note_1 = allEvaluations.filter(x => x.comments && x.comments.length > 0).map(x => x.comments);
                    const note_2 = allEvaluations
                        .filter(x => x.comments2 && x.comments2.length > 0)
                        .map(x => x.comments2);

                    const member_notes = evalTwoClosed ? note_2.join(", ") : note_1.join(", ");

                    // Category
                    category = schema.FundingCategory.filter(
                        x =>
                            x.category_name ===
                                (view.final_funding_category ? view.final_funding_category : view.funding_category) &&
                            x.year_label === year
                    ).first();

                    const avg = Math.round(
                        allEvaluations.filter(x => x.score2 !== null).reduce((a, b) => a + b.score2, 0) /
                            allEvaluations.filter(x => x.score2 !== null).length
                    );

                    const median = getMedian(allEvaluations.filter(x => x.score2 !== null).map(x => x.score2));

                    const score2_total = allEvaluations.filter(x => x.score2 !== null).length;
                    const scoring2Compiled = Math.round((score2_total / yearMembers.length) * 100);

                    

                    const avg_allocation = Math.round(
                        allEvaluations.filter(x => x.allocation !== null).reduce((a, b) => a + b.allocation, 0) /
                            allEvaluations.filter(x => x.allocation !== null).length
                    );
                    const median_allocation = getMedian(allEvaluations.filter(x => x.allocation !== null).map(x => x.allocation));
                    const score2_total_allocation = allEvaluations.filter(x => x.allocation !== null).length;
                    const scoring2Compiled_allocation = Math.round((score2_total_allocation / selected_total) * 100);

                    var org = schema.Organization.filter(x => x.id === view.organization_id).first();
                    category = schema.FundingCategory.filter(
                        x =>
                            x.category_name ===
                                (view.final_funding_category ? view.final_funding_category : view.funding_category) &&
                            parseInt(x.year_label) === year
                    ).first();

                    var secondarySuffix = null;
                    if (view.funding_category === "Small Projects" && !view.final_funding_category) {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === view.secondary_funding_category && parseInt(x.year_label) === year
                        ).first();
                    } else if (view.final_funding_category && view.final_funding_category === "Small Projects") {
                        secondarySuffix = schema.FundingCategory.filter(
                            x =>
                                x.category_name === view.final_secondary_funding_category &&
                                parseInt(x.year_label) === year
                        ).first();
                    }

                    var final_category = category && category.suffix ? category.suffix + ". " : "";
                    final_category += view.final_funding_category
                        ? view.final_funding_category
                        : view.funding_category
                        ? view.funding_category
                        : "";

                    if (view.final_funding_category) {
                        final_category +=
                            view.final_funding_category === "Small Projects" && view.final_secondary_funding_category
                                ? " / " +
                                  (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                  view.final_secondary_funding_category
                                : "";
                    } else {
                        final_category +=
                            view.funding_category === "Small Projects" && view.secondary_funding_category
                                ? " / " +
                                  (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                  view.secondary_funding_category
                                : "";
                    }

                    var num_selected = allEvaluations.reduce((a, b) => (b.selected ? a + 1 : a), 0);

                    return {
                        ...temp,
                        num_selected,
                        compiled: Math.round(
                            (num_selected / (yearMembers.length - allEvaluations.filter(x => x.conflict).length)) * 100
                        ),
                        selected_total: yearMembers.length - allEvaluations.filter(x => x.conflict).length,
                        id: view.id,
                        all_comments: evaluation
                            ? (evaluation.comments ? evaluation.comments + (evaluation.comments2 ? ", " : "") : "") +
                              (evaluation.comments2 ? evaluation.comments2 : "")
                            : "",
                        contingencies: view.contingencies,
                        selected_presentation: view.selected_presentation,
                        recommended_for_funding: view.recommended_for_funding,
                        recommended_amount: view.recommended_amount,
                        proposal_unique_id: view.proposal_unique_id,
                        name: view.name,
                        summary: view.summary,
                        category: category,
                        final_category,
                        secondarySuffix: secondarySuffix ? secondarySuffix.suffix : null,
                        secondary_category: view.secondary_funding_category,
                        suffix: category ? category.suffix : null,
                        funding_category: view.funding_category,
                        pm_full_name: view.pm_first_name + " " + view.pm_last_name,
                        funding_total: view.funding_total,
                        conflict,
                        compiled_num: compiled,
                        member_notes,
                        median: median !== null && !isNaN(median) ? Math.round(median) : NaN,
                        avg,
                        mem_scoring2: yearMembers.length,
                        score2_total,
                        scoring2Compiled: !isNaN(scoring2Compiled) ? scoring2Compiled : 0,
                        median_allocation: median_allocation !== null && !isNaN(median_allocation) ? Math.round(median_allocation) : NaN,
                        avg_allocation,
                        score2_total_allocation,
                        scoring2Compiled_allocation: !isNaN(scoring2Compiled_allocation) ? scoring2Compiled_allocation : 0,
                        final_score: tempReview.final_score,
                        final_member_comments2: tempReview.final_member_comments2,
                        evalid: temp ? temp.id : null,
                        organization: org ? org.organization_name : ""
                    };
                });
        } else {
            return schema.ProposalView.filter(
                x =>
                    x.proposal_unique_id.includes(year) &&
                    ["Revisions Submitted", "Final Submitted", "Revisions Needed"].includes(x.status) &&
                    x.secondary_status !== "Withdrawn" &&
                    x.secondary_status !== "Withdrawal Requested"
            )
                .orderBy("proposal_unique_id", "asc")
                .toModelArray()
                .map(view => {
                    var review = view.review_proposal.first();
                    const yearMembers = schema.RFPMember.all()
                        .filter(x => x.year_id === year_id)
                        .toRefArray();
                    var allEvaluations = view.eval_member
                        .all()
                        .filter(x => yearMembers.find(z => z.user === x.user_id))
                        .toModelArray()
                        .map(e => {
                            return {
                                user: schema.User.filter(x => x.id === e.user_id).first(),
                                ...e.ref
                            };
                        });
                    var evaluation = view.eval_member.filter(x => x.user_id === id).first();
                    var temp = evaluation ? evaluation : {};
                    var tempReview = review ? review : {};

                    const selected_total = allEvaluations.length - allEvaluations.filter(x => x.conflict).length;
                    const selected = allEvaluations.filter(x => x.selected).length;
                    const compiled = Math.round((selected / selected_total) * 100);

                    const conflict = allEvaluations
                        .filter(x => x.conflict)
                        .map(x => (x.user ? x.user.first_name + " " + x.user.last_name : "Unknown"))
                        .join(", ");

                    const note_1 = allEvaluations.filter(x => x.comments && x.comments.length > 0).map(x => x.comments);
                    const note_2 = allEvaluations
                        .filter(x => x.comments2 && x.comments2.length > 0)
                        .map(x => x.comments2);

                    const member_notes = evalTwoClosed ? note_2.join(", ") : note_1.join(", ");

                    const avg = Math.round(
                        allEvaluations.filter(x => x.score2 !== null).reduce((a, b) => a + b.score2, 0) /
                            allEvaluations.filter(x => x.score2 !== null).length
                    );

                    const median = getMedian(allEvaluations.filter(x => x.score2 !== null).map(x => x.score2));

                    const score2_total = allEvaluations.filter(x => x.score2 !== null).length;
                    const scoring2Compiled = Math.round((score2_total / yearMembers.length) * 100);

                    const avg_allocation = Math.round(
                        allEvaluations.filter(x => x.allocation !== null).reduce((a, b) => a + b.allocation, 0) /
                            allEvaluations.filter(x => x.allocation !== null).length
                    );

                    const median_allocation = getMedian(allEvaluations.filter(x => x.allocation !== null).map(x => x.allocation));

                    const score2_total_allocation = allEvaluations.filter(x => x.allocation !== null).length;
                    const scoring2Compiled_allocation = Math.round((score2_total_allocation / selected_total) * 100);

                    // Category
                    var category = schema.FundingCategory.filter(
                        x =>
                            x.category_name ===
                                (view.final_funding_category ? view.final_funding_category : view.funding_category) &&
                            x.year_label === year
                    ).first();

                    var org = schema.Organization.filter(x => x.id === view.organization_id).first();
                    category = schema.FundingCategory.filter(
                        x =>
                            x.category_name ===
                                (view.final_funding_category ? view.final_funding_category : view.funding_category) &&
                            parseInt(x.year_label) === year
                    ).first();

                    var secondarySuffix = null;
                    if (view.funding_category === "Small Projects" && !view.final_funding_category) {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === view.secondary_funding_category && parseInt(x.year_label) === year
                        ).first();
                    } else if (view.final_funding_category && view.final_funding_category === "Small Projects") {
                        secondarySuffix = schema.FundingCategory.filter(
                            x =>
                                x.category_name === view.final_secondary_funding_category &&
                                parseInt(x.year_label) === year
                        ).first();
                    }

                    var final_category = category && category.suffix ? category.suffix + ". " : "";
                    final_category += view.final_funding_category
                        ? view.final_funding_category
                        : view.funding_category
                        ? view.funding_category
                        : "";

                    if (view.final_funding_category) {
                        final_category +=
                            view.final_funding_category === "Small Projects" && view.final_secondary_funding_category
                                ? " / " +
                                  (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                  view.final_secondary_funding_category
                                : "";
                    } else {
                        final_category +=
                            view.funding_category === "Small Projects" && view.secondary_funding_category
                                ? " / " +
                                  (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                  view.secondary_funding_category
                                : "";
                    }

                    var num_selected = allEvaluations.reduce((a, b) => (b.selected ? a + 1 : a), 0);

                    return {
                        ...temp,
                        num_selected,
                        compiled: Math.round(
                            (num_selected / (yearMembers.length - allEvaluations.filter(x => x.conflict).length)) * 100
                        ),
                        selected_total: yearMembers.length - allEvaluations.filter(x => x.conflict).length,
                        id: view.id,
                        all_comments: evaluation
                            ? (evaluation.comments ? evaluation.comments + (evaluation.comments2 ? ", " : "") : "") +
                              (evaluation.comments2 ? evaluation.comments2 : "")
                            : "",
                        contingencies: view.contingencies,
                        selected_presentation: view.selected_presentation,
                        recommended_for_funding: view.recommended_for_funding,
                        recommended_amount: view.recommended_amount,
                        proposal_unique_id: view.proposal_unique_id,
                        name: view.name,
                        summary: view.summary,
                        category: category,
                        final_category,
                        secondarySuffix: secondarySuffix ? secondarySuffix.suffix : null,
                        secondary_category: view.secondary_funding_category,
                        suffix: category ? category.suffix : null,
                        funding_category: view.funding_category,
                        pm_full_name: view.pm_first_name + " " + view.pm_last_name,
                        funding_total: view.funding_total,
                        conflict,
                        compiled_num: compiled,
                        member_notes,
                        median: median !== null && !isNaN(median) ? Math.round(median) : NaN,
                        avg,
                        mem_scoring2: yearMembers.length,
                        score2_total,
                        scoring2Compiled: !isNaN(scoring2Compiled) ? scoring2Compiled : 0,
                        median_allocation: median_allocation !== null && !isNaN(median_allocation) ? Math.round(median_allocation) : NaN,
                        avg_allocation,
                        score2_total_allocation,
                        scoring2Compiled_allocation: !isNaN(scoring2Compiled_allocation) ? scoring2Compiled_allocation : 0,
                        final_score: tempReview.final_score,
                        final_member_comments2: tempReview.final_member_comments2,
                        evalid: temp ? temp.id : null,
                        organization: org ? org.organization_name : ""
                    };
                });
        }
    }
);

const getSystemSetting = createSelector(
    (state, ownProps) => ownProps.match.params["year"],
    (schema, year) => {
        return schema.SystemSetting.filter(x => x.year === year).first();
    }
);

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        backgroundColor: "#fff"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    link: {
        "&:hover": {
            cursor: "pointer"
        },
        color: "#006a9d",
        textDecoration: "underline"
    },
    cellWidth_2: {
        minWidth: "200px !important"
    },
    cellWidth_3: {
        minWidth: "300px !important"
    },
    cellWidth_35: {
        minWidth: "350px !important"
    },
    floatLeft: {
        float: "left"
    }
});

const columnData = [
    { id: "category", numeric: false, label: "Actions" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "summary", numeric: false, label: "Summary", allowSort: true },
    { id: "final_category", numeric: false, label: "Category/Subcategory", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "pm_full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Amount Requested", allowSort: true },
    { id: "final_score", numeric: true, label: "Staff Score", allowSort: true },
    { id: "final_member_comments2", numeric: false, label: "Staff Comments", allowSort: true },
    { id: "conflict", numeric: false, label: "Members With Conflict Of Interest", allowSort: true },
    { id: "compiled", numeric: true, label: "# Of Members Selecting For Presentation", allowSort: true },
    { id: "compiled_num", numeric: true, label: "Compiled Member Evaluation #1 Score", allowSort: true },
    { id: "member_notes", numeric: true, label: "Member Notes", allowSort: true },
    {
        id: "selected_presentation",
        numeric: false,
        label: "LCCMR Selected For Presentation",
        allowSort: true,
        bool: true
    },
    { id: "median", numeric: true, label: "Compiled Evaluation #2 Median Score", allowSort: true },
    { id: "avg", numeric: true, label: "Compiled Member Evaluation #2 Average Score", allowSort: true },
    { id: "scoring2Compiled", numeric: true, label: "# Of Members Scoring Evaluation #2", allowSort: true },
    { id: "recommended_for_funding", numeric: false, label: "LCCMR Recommended", allowSort: true, bool: true },
    { id: "recommended_amount", numeric: true, label: "LCCMR Recommended Amount", allowSort: true },
    { id: "contingencies", numeric: false, label: "Contingencies", allowSort: true }
];

const columnDataAllocation = [
    { id: "category", numeric: false, label: "Actions" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "summary", numeric: false, label: "Summary", allowSort: true },
    { id: "final_category", numeric: false, label: "Category/Subcategory", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "pm_full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Amount Requested", allowSort: true },
    { id: "final_score", numeric: true, label: "Staff Score", allowSort: true },
    { id: "final_member_comments2", numeric: false, label: "Staff Comments", allowSort: true },
    { id: "conflict", numeric: false, label: "Members With Conflict Of Interest", allowSort: true },
    { id: "compiled", numeric: true, label: "# Of Members Selecting For Presentation", allowSort: true },
    { id: "compiled_num", numeric: true, label: "Compiled Member Evaluation #1 Score", allowSort: true },
    { id: "member_notes", numeric: true, label: "Member Notes", allowSort: true },
    {
        id: "selected_presentation",
        numeric: false,
        label: "LCCMR Selected For Presentation",
        allowSort: true,
        bool: true
    },
    { id: "median_allocation", numeric: true, label: "Compiled Evaluation #2 Median Allocation", allowSort: true },
    { id: "avg_allocation", numeric: true, label: "Compiled Member Evaluation #2 Average Allocation", allowSort: true },
    { id: "scoring2Compiled_allocation", numeric: true, label: "# Of Members Allocating Evaluation #2", allowSort: true },
    { id: "recommended_for_funding", numeric: false, label: "LCCMR Recommended", allowSort: true, bool: true },
    { id: "recommended_amount", numeric: true, label: "LCCMR Recommended Amount", allowSort: true },
    { id: "contingencies", numeric: false, label: "Contingencies", allowSort: true }
];

class EvaluationResults extends Component {
    state = {
        page: 0,
        rowsPerPage: 100,
        order: "asc",
        review: false,
        member: false,
        loaded: false,
        defaultSorted: true
    };

    componentDidMount() {
        document.title =
            "Commission Evaluation and Recommendation Results - LCCMR Proposal and Grant Management System";
        const {
            authState,
            history,
            match,
            ormProposalReviewLoadDetailChildFilterMany,
            ormMemberEvaluationReloadOverWrite
        } = this.props;

        if (authState && authState.user && authState.user.role !== "Member") {
            history.push("/dashboard");
        }

        if (authState && authState.user) {
            ormProposalReviewLoadDetailChildFilterMany({
                year: match.params["year"]
            });
            //ormMemberEvaluationLoadDetailChildFilterMany({
            ormMemberEvaluationReloadOverWrite({
                year: match.params["year"]
            });
            this.setState({ loaded: true });
        } else {
            this.setState({ waitingSynce: true });
        }
    }

    componentDidUpdate() {
        const {
            authState,
            match,
            sync,
            ormProposalReviewLoadDetailChildFilterMany,
            ormMemberEvaluationReloadOverWrite
        } = this.props;
        const { loaded, member, review, waitingSynce } = this.state;

        if (waitingSynce && sync && sync.ready === false) {
            this.setState({ waitingSynce: false });
        }

        // This is messy if page is reload persistCallback reloadAll will come after LoadDetails below and then there will be no Evaluations in redux
        // Need to set waitingSynce to true if it missed on mount meaning page was reloaded and then wait till sync.ready is false to set flag to wait till true
        // meaning data can be pulled without it getting cleared.
        if (authState && authState.user && !waitingSynce && sync.ready && !loaded) {
            const _this = this;
            if (!review) {
                ormProposalReviewLoadDetailChildFilterMany(
                    {
                        year: match.params["year"]
                    },
                    function(data) {
                        _this.setState({ review: true });
                    }
                );
            }

            if (!member) {
                //ormMemberEvaluationLoadDetailChildFilterMany(
                ormMemberEvaluationReloadOverWrite(
                    {
                        year: match.params["year"]
                    },
                    function(data) {
                        _this.setState({ member: true });
                    }
                );
            }
            this.setState({ loaded: true });
        }
    }

    handleRequestSort = (event, property) => {
        const { setting } = this.props;
        const orderBy = property;
        let order = "desc";        
        
        var use_allocation = false;
        if (setting && parseInt(setting.year) >= 2023) {
            use_allocation = true;
        }

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        // don't allow sort
        if (
            setting &&
            !setting.compiled &&
            ["final_member_comments2", "conflict", "compiled", "compiled_num", "member_notes"].includes(property)
        ) {
            this.setState({ order, orderBy });
            return;
        } else if (setting && !setting.compiled2 && ["median", "avg", "scoring2Compiled", "median_allocation", "avg_allocation", "scoring2Compiled_allocation"].includes(property)) {
            this.setState({ order, orderBy });
            return;
        }
        var numeric, bool;
        this.props.proposals.sort(function(a, b) {
            if (use_allocation) {
                numeric = columnDataAllocation.find(cD => cD.id === orderBy).numeric;
                bool = columnDataAllocation.find(cD => cD.id === orderBy).bool;
            } else {
                numeric = columnData.find(cD => cD.id === orderBy).numeric;
                bool = columnData.find(cD => cD.id === orderBy).bool;
            }
            if (bool) {
                if (order === "desc") return (b[orderBy] || false) - (a[orderBy] || false);
                else return (a[orderBy] || false) - (b[orderBy] || false);
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    sortSubCategory = (list, radio) => {
        var grouped = {};

        for (var i = 0; i < list.length; i += 1) {
            if (list[i].secondarySuffix && !grouped[list[i].secondarySuffix]) {
                grouped[list[i].secondarySuffix] = [];
            } else if (!list[i].secondarySuffix && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[list[i].secondarySuffix ? list[i].secondarySuffix : ""].push(list[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                if (radio) {
                    if (a[radio] === b[radio]) return 0;
                    else if (a[radio] === null || typeof a[radio] === "undefined") return 1;
                    else if (b[radio] === null || typeof b[radio] === "undefined") return -1;
                    return parseInt(b[radio]) - parseInt(a[radio]);
                } else {
                    return (a.proposal_unique_id || "").toUpperCase() < (b.proposal_unique_id || "").toUpperCase()
                        ? -1
                        : 1;
                }
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        return arr;
    };

    defaultSort = radio => {
        const { proposals } = this.props;

        var grouped = {};

        for (var i = 0; i < proposals.length; i += 1) {
            if (proposals[i].category && !grouped[proposals[i].category.suffix]) {
                grouped[proposals[i].category.suffix] = [];
            } else if (!proposals[i].category && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[proposals[i].category ? proposals[i].category.suffix : ""].push(proposals[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                if (radio) {
                    if (a[radio] === b[radio]) return 0;
                    else if (a[radio] === null || typeof a[radio] === "undefined") return 1;
                    else if (b[radio] === null || typeof b[radio] === "undefined") return -1;
                    return parseInt(b[radio]) - parseInt(a[radio]);
                } else {
                    return (a.proposal_unique_id || "").toUpperCase() < (b.proposal_unique_id || "").toUpperCase()
                        ? -1
                        : 1;
                }
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        // This sorts H by secondary category and then ID.
        if (grouped["H"] && grouped["H"].length > 1) {
            grouped["H"] = this.sortSubCategory(grouped["H"], radio);
        }

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        this.props.proposals.sort(function(a, b) {
            return arr.findIndex(x => x.id === a.id) - arr.findIndex(z => z.id === b.id);
        });

        this.setState({ defaultSorted: false });
    };

    filterByRadio = value => {
        this.defaultSort(value);
    };

    radioSubSort = (list, key) => {
        var grouped = {};

        for (var i = 0; i < list.length; i += 1) {
            if (list[i].secondarySuffix && !grouped[list[i].secondarySuffix]) {
                grouped[list[i].secondarySuffix] = [];
            } else if (!list[i].secondarySuffix && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[list[i].secondarySuffix ? list[i].secondarySuffix : ""].push(list[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                if (a[key] === b[key]) return 0;
                else if (a[key] === null || typeof a[key] === "undefined") return 1;
                else if (b[key] === null || typeof b[key] === "undefined") return -1;
                return parseInt(b[key]) - parseInt(a[key]);
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        return arr;
    };

    radioSort = key => {
        const { setting } = this.props;
        const values = this.props.proposals;

        // don't allow sort
        if (setting && !setting.compiled && key === "compiled") {
            this.setState({ dummyState: "" });
            return;
        } else if (setting && !setting.compiled2 && ["median", "avg", "median_allocation", "avg_allocation"].includes(key)) {
            this.setState({ dummyState: "" });
            return;
        }

        var grouped = {};

        for (var i = 0; i < values.length; i += 1) {
            if (values[i].category && !grouped[values[i].category.suffix]) {
                grouped[values[i].category.suffix] = [];
            } else if (!values[i].category && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[values[i].category ? values[i].category.suffix : ""].push(values[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                if (a[key] === b[key]) {
                    return 0;
                }
                // nulls sort after anything else
                else if (a[key] === null || isNaN(a[key])) {
                    return 1;
                } else if (b[key] === null || isNaN(b[key])) {
                    return -1;
                } else {
                    return parseFloat(b[key]) < parseFloat(a[key]) ? -1 : 1;
                }
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        if (grouped["H"] && grouped["H"].length > 1) {
            grouped["H"] = this.sortSubCategory(grouped["H"], key);
        }

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        this.props.proposals.sort(function(a, b) {
            return arr.findIndex(x => x.id === a.id) - arr.findIndex(z => z.id === b.id);
        });

        this.setState({ dummyState: "" });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    filterByValue = (array, string) => {
        var keys = columnData.map(x => x.id);
        return array.filter(o =>
            keys.some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    render() {
        const { classes, proposals, setting, sync } = this.props;
        const { defaultSorted, filter, loaded, rowsPerPage, page, order, orderBy } = this.state;

        var use_allocation = false;
        if (setting && parseInt(setting.year) >= 2023) {
            use_allocation = true;
        }

        if (sync && sync.ready && loaded && defaultSorted) {
            this.defaultSort(null);
        }

        if (sync && !sync.ready && loaded && defaultSorted) {
            return (
                <AppContainer authenticated>
                    <Grid container spacing={16} justify="center" alignItems="center">
                        <PageHeader title="Commission Evaluation and Recommendation Results - Proposal Process" />
                        <Grid item>
                            <CircularProgress size={100} aria-label="Loading Icon" thickness={1.5} />
                            <Typography>Loading Data...</Typography>
                        </Grid>
                    </Grid>
                </AppContainer>
            );
        }

        var filtered_proposals;
        if (filter) {
            filtered_proposals = this.filterByValue(proposals, filter);
        } else {
            filtered_proposals = proposals;
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Commission Evaluation and Recommendation Results - Proposal Process" />
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                {formApi => (
                                    <form style={{ display: "inline-flex" }} onSubmit={formApi.submitForm}>
                                        {use_allocation ? (
                                            <RadioGroup
                                                field="filters"
                                                name="filters"
                                                eventHandle={val => this.radioSort(val)}
                                                fullWidth
                                                style={{marginTop: 4, marginBottom: 0}}
                                                options={[
                                                    {
                                                        label: "Staff Score By Category High to Low",
                                                        value: "final_score"
                                                    },
                                                    {
                                                        label: "Member Evaluation #1 Score By Category High to Low",
                                                        value: "compiled"
                                                    },
                                                    {
                                                        label:
                                                            "Compiled Evaluation #2 Median Allocation By Category High to Low",
                                                        value: "median_allocation"
                                                    },
                                                    {
                                                        label:
                                                            "Compiled Evaluation #2 Average Allocation By Category High to Low",
                                                        value: "avg_allocation"
                                                    }
                                                ]}
                                                label="Sort"
                                                alignment={true}
                                            />
                                        ) : (
                                            <RadioGroup
                                                field="filters"
                                                name="filters"
                                                eventHandle={val => this.radioSort(val)}
                                                style={{marginTop: 4, marginBottom: 0}}
                                                fullWidth
                                                options={[
                                                    {
                                                        label: "Staff Score By Category High to Low",
                                                        value: "final_score"
                                                    },
                                                    {
                                                        label: "Member Evaluation #1 Score By Category High to Low",
                                                        value: "compiled"
                                                    },
                                                    {
                                                        label:
                                                            "Compiled Evaluation #2 Median Score By Category High to Low",
                                                        value: "median"
                                                    },
                                                    {
                                                        label:
                                                            "Compiled Evaluation #2 Average Score By Category High to Low",
                                                        value: "avg"
                                                    }
                                                ]}
                                                label="Sort"
                                                alignment={true}
                                            />
                                        )}
                                    </form>
                                )}
                            </Form>
                        </Grid>
                        <Grid item xs={2}>
                            <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                {formApi => (
                                    <form style={{ float: "right"}} onSubmit={formApi.submitForm}>
                                        <TextField
                                            eventHandle={val => this.setState({ filter: val })}
                                            field="filter"
                                            label="Search List"
                                            filterInput
                                        />
                                    </form>
                                )}
                            </Form>
                        </Grid>
                        <Grid item xs={12} style={{overflowX:"auto", height: "calc(100vh - 420px)"}}>
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    columnData={use_allocation ? columnDataAllocation : columnData}
                                    stickyHeader
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />
                                <TableBody>
                                    {filtered_proposals
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(n => {
                                            return (
                                                <TableRow key={n.id}>
                                                    <CustomTableCell className={classes.nowrap}>
                                                        <Tooltip title="View Proposal">
                                                            <Button
                                                                color="primary"
                                                                className={classes.deleteWidth}
                                                                component={Link}
                                                                aria-label="View Proposal"
                                                                onClick={() => window.open("#/proposal/" + n.id)}>
                                                                <Visibility color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Typography>
                                                            <Link
                                                                onClick={() =>
                                                                    window.open("/reports/proposal/" + n.id)
                                                                }>
                                                                {n.proposal_unique_id}
                                                            </Link>
                                                        </Typography>
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.cellWidth_2}>
                                                        {n.name}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.cellWidth_35}>
                                                        {n.summary}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.cellWidth_2}>
                                                        {n.final_category}
                                                    </CustomTableCell>
                                                    <CustomTableCell>{n.organization}</CustomTableCell>
                                                    <CustomTableCell>{n.pm_full_name}</CustomTableCell>
                                                    <CustomTableCell>
                                                        ${n.funding_total ? n.funding_total.toLocaleString() : 0}
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        {!isNaN(n.final_score) ? n.final_score : "-"}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.cellWidth_3}>
                                                        {n.final_member_comments2}
                                                    </CustomTableCell>
                                                    {setting && setting.compiled && (
                                                        <>
                                                            <CustomTableCell>
                                                                {n.conflict ? n.conflict : "-"}
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                {n.num_selected} out of {n.selected_total}
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                {isNaN(n.compiled) ? 0 : n.compiled}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.cellWidth_3}>
                                                                {n.member_notes ? n.member_notes : "-"}
                                                            </CustomTableCell>
                                                        </>
                                                    )}
                                                    {!setting ||
                                                        (!setting.compiled && (
                                                            <>
                                                                <CustomTableCell>-</CustomTableCell>
                                                                <CustomTableCell>-</CustomTableCell>
                                                                <CustomTableCell>-</CustomTableCell>
                                                                <CustomTableCell>-</CustomTableCell>
                                                            </>
                                                        ))}
                                                    <CustomTableCell>
                                                        {setting && setting.presentation_selection_open && (
                                                            <>{n.selected_presentation ? "Yes" : "No"}</>
                                                        )}
                                                        {setting && !setting.presentation_selection_open && "-"}
                                                    </CustomTableCell>
                                                    {setting && setting.compiled2 && (
                                                        use_allocation ? (
                                                            <>
                                                                <CustomTableCell>
                                                                    {isNaN(n.median_allocation) ? "-" : "$" + n.median_allocation.toLocaleString()}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {isNaN(n.avg_allocation) ? "-" : "$" + n.avg_allocation.toLocaleString()}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.score2_total_allocation} out of {n.selected_total}
                                                                </CustomTableCell>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CustomTableCell>
                                                                    {isNaN(n.median) ? "-" : n.median}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {isNaN(n.avg) ? "-" : n.avg}
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.score2_total} out of {n.mem_scoring2}
                                                                </CustomTableCell>
                                                            </>
                                                        )
                                                    )}
                                                    {!setting ||
                                                        (!setting.compiled2 && (
                                                            <>
                                                                <CustomTableCell>-</CustomTableCell>
                                                                <CustomTableCell>-</CustomTableCell>
                                                                <CustomTableCell>-</CustomTableCell>
                                                            </>
                                                        ))}
                                                    <CustomTableCell>
                                                        {setting &&
                                                            setting.recommendations_open &&
                                                            (n.recommended_for_funding ? "Yes" : "No")}
                                                        {setting && !setting.recommendations_open && "-"}
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        {setting && setting.recommendations_open && (
                                                            <>
                                                                $
                                                                {n.recommended_amount
                                                                    ? n.recommended_amount.toLocaleString()
                                                                    : 0}
                                                            </>
                                                        )}
                                                        {setting && !setting.recommendations_open && "--"}
                                                    </CustomTableCell>
                                                    <CustomTableCell className={classes.cellWidth_2}>
                                                        {n.contingencies ? n.contingencies : "-"}
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {filtered_proposals.length < 1 && (
                                        <TableRow>
                                            <CustomTableCell
                                                colSpan={columnData.length}
                                                className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Summary Found</caption>
                                            </CustomTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                {filtered_proposals.length > 25 && (
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                colSpan={columnData.length}
                                                count={filtered_proposals.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                classes={{toolbar: classes.floatLeft}}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActionsWrapped}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                )}
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

EvaluationResults = connect(
    (state, ownProps) => ({
        sort: state.nav,
        sync: state.sync,
        authState: state.auth,
        proposals: getProposals(state, ownProps),
        setting: getSystemSetting(state, ownProps)
    }),
    {
        ...MemberEvaluation.actions,
        ...ProposalReview.actions
    }
)(EvaluationResults);

export default withStyles(styles)(withRouter(EvaluationResults));
