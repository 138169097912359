import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import TextField from "../../common/TextField";
import HelpLabel from "../../common/HelpLabel";
import { StatusUpdateTab } from "../../home/models";

const getTab = StatusUpdateTab.selectMessage(8);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    text: {
        "& textarea": {
            minHeight: 120,
            color: "#000000"
        }
    },
    text2: {
        "& textarea": {
            minHeight: 240,
            color: "#000000"
        }
    }
});


class FinalAbstract extends Component {

    componentDidMount() {
        const { ormParentUpdateLocalOnly, plan } = this.props;
        document.title = "Work Plan: Final Abstract - LCCMR";
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            if (!plan.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormParentUpdateLocalOnly({
                    id: plan.id,
                    fromReview: false
                });
            }
        }, 1);
    }    

    updatePlan(values, fromStepper) {
        const {
            ormParentUpdate,
            history,
            handleUnsavedFields,
            getDateTimeStamp,
            plan,
            ormParentUpdateLocalOnly,
            is_read_only
        } = this.props;
        const { id } = plan;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (is_read_only) {
                ormParentUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = plan.map;
        values.financial_capacity = plan.financial_capacity;
        values.modify_date_label = plan.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormParentUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                // Go Nowhere
            } else {
                values.activeStep = 11;
                values.activeSubStep = 0;
                ormParentUpdateLocalOnly({
                    id: id,
                    ...values
                });
            }
        }
    }
   
    render() {
        const { classes, ActionMenu, plan, handleUnsavedFields, tab, authState, proposal } = this.props;

        var is_read_only = true;
        if (authState && authState.user && authState.user.role === "User" && (proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Revisions Needed"))
            is_read_only = false;
        var is_staff = false;
        if (authState && authState.user && authState.user.role === "Staff")
            is_staff = true;
        if (authState && authState.user && authState.user.role === "Staff" && proposal.status === "Project Completed")
            is_read_only = false;
        var is_member = false;
        if (authState && authState.user && (authState.user.is_read_only || authState.user.role === "Member")) {
            is_read_only = true;
            is_member = true;
        }

        return (
            <Form
                getApi={el => (this.form = el)}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updatePlan(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16} style={{ marginTop: 0 }}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="titleAction">Final Abstract</Typography>
                                {ActionMenu}
                            </Grid>

                            <Grid item xs={12} lg={10} xl={7}>
                                <div class="insborder">
                                    <Typography>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: tab.tab_help_texts.find(tht => tht.label === "Instructions").text
                                            }}
                                        />
                                    </Typography>
                                </div>
                            </Grid>

                            {(is_staff || is_member) && (
                                <>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography>Status: <b>{proposal.status}</b></Typography>
                                </Grid>
                                {proposal.status === "Project Completed" && (
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography>Final Report Approved on: <b>{plan.last_action}</b></Typography>
                                    </Grid>
                                )}
                                </>
                            )}
                            {((is_staff || is_member) && (proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Revisions Needed")) ? (
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography>The project manager has yet to submit their abstract.</Typography>
                                </Grid>
                            ) : (is_member && proposal.status === "Final Abstract Submitted") ? (
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography>The abstract has been submitted and is pending review by LCCMR staff.</Typography>
                                </Grid>
                            ) : (
                                <>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <TextField disabled={is_read_only} field="project_outcomes_short" fullWidth className={classes.text} multiline maxWords="50">
                                        <HelpLabel
                                            inputLabel="Sound Bite of Project Outcomes and Results (50 word limit)"
                                            htmlText={true}
                                            showLabel={true}
                                            helpText={tab.tab_help_texts.find(tht => tht.label === "Sound Bite of Project Outcomes and Results").text}
                                        />
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} lg={10} xl={7}>
                                    <TextField disabled={is_read_only} multiline field="project_outcomes_long" className={classes.text2} fullWidth maxWords="300">
                                        <HelpLabel
                                            inputLabel="Overall Project Outcomes and Results (300 word limit)"
                                            htmlText={true}
                                            showLabel={true}
                                            helpText={tab.tab_help_texts.find(tht => tht.label === "Overall Project Outcomes and Results").text}
                                        />
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} lg={10} xl={7}>
                                    <TextField disabled={is_read_only} multiline field="project_results" fullWidth className={classes.text} maxWords="100">
                                        <HelpLabel
                                            inputLabel="Project Results Use and Dissemination (100 word limit)"
                                            htmlText={true}
                                            showLabel={true}
                                            helpText={tab.tab_help_texts.find(tht => tht.label === "Project Results Use and Dissemination").text}
                                        />
                                    </TextField>
                                </Grid>
                                </>
                            )}

                            <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ float: "left" }}
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                    className={classes.button}>
                                    Save Draft
                                </Button>
                                {(authState && authState.user && authState.user.role === "Staff" && proposal.status === "Project Completed") ? (
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        color="primary"
                                        onClick={() => this.setState({ draftClick: true })}>
                                        Save
                                    </Button>
                                ) : (
                                    <>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        onClick={() => this.setState({ draftClick: true })}
                                        className={classes.button}>
                                        Save Draft and Return to Dashboard
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        color="primary"
                                        onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                        Save and Proceed to Review
                                    </Button>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}


FinalAbstract = connect(
    (state, ownProps) => ({
        authState: state.auth,
        tab: getTab(state, ownProps),
    }), null
)(FinalAbstract);

export default withStyles(styles)(withRouter(FinalAbstract));
