import React, { Component } from "react";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core";

import HelpDialog from "./HelpDialog";

const styles = theme => ({
    helpGrid: {
        width: 20,
        height: 20,
        opacity: ".4",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.dark
        }
    },
    absolute: {
        height: theme.spacing.unit * 2
    },
    inline: {
        display: "inline"
    },
    helpButton: {
        marginLeft: 8,
        padding: 0
    },
    floatButton: {
        float: "right",
        marginBottom: -40,
        marginTop: 5,
        zIndex: 9
    },
    figureStyle: {
        margin: 0,
        padding: 0,
        display: "inline"
    }
});

class HelpLabel extends Component {
    state = {
        helpOpen: false
    };

    iconClick = e => {
        this.setState({ helpOpen: true });
        //FIXME: How to stop the input from being focused
        e.stopPropagation();
    };

    render() {
        const { helpText, classes, inputLabel, question, title, htmlText, showLabel, style } = this.props;

        const { helpOpen } = this.state;

        return (
            <>
                <Typography
                    style={style}
                    variant={title ? "h3" : null}
                    className={!inputLabel ? classes.absolute : classes.inline}>
                    {showLabel && inputLabel}
                </Typography>
                <figure className={classes.figureStyle}>
                    <IconButton
                        onClick={this.iconClick}
                        className={classNames(classes.helpButton, !showLabel && classes.floatButton)}
                        aria-label={"Open Help Dialog for " + inputLabel}>
                        <Typography 
                        style={{
                            textDecoration: "underline",
                            color: "rgba(0, 0, 0, 0.87)"
                        }}><i>Read Me</i></Typography>
                    </IconButton>
                </figure>
                <HelpDialog
                    question={inputLabel && inputLabel !== true ? inputLabel : question}
                    text={helpText}
                    htmlText={htmlText}
                    open={helpOpen}
                    title={title}
                    showHelp
                    onClose={() => 
                        this.setState({ helpOpen: false })
                    }
                />
            </>
        );
    }
}

export default withStyles(styles)(HelpLabel);
