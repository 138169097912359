import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    root: {
        display: "flex"
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit
    },
    group: {
        margin: "0"
    },
    fullWidth: {
        width: "100%"
    },
    horizontal: {
        margin: "0",
        display: "inline"
    },
    groupName: {
        color: "#212529"
    },
    error: {
        "& h3": {
            // No way to access actual text - probably because typography tag is being passed as label
            color: "#be0000"
        },
        "& svg": {
            // Keep color on help icon
            color: "rgba(0, 0, 0, 0.87)"
        }
    },
    label: {
        color: "#212529",
        fontSize: "14px",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
        fontWeight: 400,
        lineHeight: 1.5,
        cursor: "pointer"
    },
    disabledLabel: {
        color: "#212529",
        fontSize: "14px",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
        fontWeight: 400,
        lineHeight: 1.5,
    }
});

class RadioGroupWrapper extends Component {
    handleChange = (event, value) => {
        const { fieldApi, eventHandle, name } = this.props;
        const { setValue } = fieldApi;
        if (value === "true") value = true;
        if (value === "false") value = false;

        setValue(value);

        if (eventHandle) eventHandle(value, name);
    };

    handleClickLabel = (event, value, object) => {
        const { fieldApi } = this.props;
        const { getValue, setValue } = fieldApi;

        if (value === "true") value = true;
        if (value === "false") value = false;

        if (!object.disabled) {
            if (getValue() === value && getValue() !== "")
                setValue("");
            else
                this.handleChange(event, value);
        }
    };  
    
    handleClick = (event, value) => {
        const { fieldApi } = this.props;
        const { setValue } = fieldApi;

        if (event.target.value === value) {
            setValue("");
        } else {
            setValue(event.target.value);
        }
    }
    
    handleKeyboard = (event) => {
        const { fieldApi } = this.props;
        const { getValue, setValue } = fieldApi;

        var value = event.target.value
        if (value === "true") value = true;
        if (value === "false") value = false;

        if (value === getValue() && (event.which === 32 || event.which === 13))
            setTimeout(function() {
                setValue("");
            }, 100);
    }

    render() {
        const {
            fieldApi,
            label,
            children,
            options,
            classes,
            required,
            alignment,
            fullWidth,
            checkFocus,
            focusRemoval,
            style,
            ...rest
        } = this.props;

        const { getValue, getError, getFieldName } = fieldApi;

        var value = getValue(),
            error = getError();

        // FIXME: Material UI requires string values
        // Radio values are returned from API as boolean
        if (value === true) value = "true";
        if (value === false) value = "false";

        /*
            original props before accessibility change:

            control={<Radio onClick={e => e.stopPropagation()} color="primary" />}
            label={object.label}
        */

        return (
            <FormControl
                component="fieldset"
                style={style}
                required={required}
                error={error && true}
                className={classNames(classes.formControl, fullWidth && classes.fullWidth)}>
                <FormLabel component="legend" className={classes.groupName} classes={{ error: classes.error }}>
                    {label || children}
                </FormLabel>
                <RadioGroup
                    className={alignment ? classes.horizontal : classes.group}
                    value={value}
                    onChange={this.handleChange}
                    {...rest}>
                    {options.map(object => (
                        <FormControlLabel
                            disabled={object.disabled}
                            value={object.value}
                            control={
                                <Radio
                                    checked={value === object.value}
                                    inputProps={{ "aria-describedby": object.identifier ? object.identifier : object.value }}
                                    onClick={e => this.handleClick(e, object.value)}
                                    onKeyUp={e => this.handleKeyboard(e, object.value)}
                                    color="primary"
                                    inputRef={(input) => {
                                        if (checkFocus && checkFocus.highlightedField && checkFocus.highlightedField === getFieldName() && input != null) {
                                           input.focus();
                                           focusRemoval({
                                               id: checkFocus.id,
                                               highlightedField: null
                                           })
                                        }
                                    }}
                                />
                            }
                            label={
                                <label
                                    id={object.identifier ? object.identifier : object.value}
                                    onClick={e => this.handleClickLabel(null, object.value, object)}
                                    className={object.disabled ? classes.disabledLabel : classes.label}>
                                    {object.label}
                                </label>
                            }
                            onClick={e => e.stopPropagation()}
                        />
                    ))}
                </RadioGroup>
                {error && <FormHelperText style={{ marginTop: 0 }}>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

const FppRadioGroup = FormField(RadioGroupWrapper);

export default withStyles(styles)(FppRadioGroup);
