import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";

import { Proposal } from "../models";
import MonthPicker from "../../common/MonthPicker";
import TextField from "../../common/TextField";
import HelpLabel from "../../common/HelpLabel";
import { ProposalTab } from "../../home/models";

const getTab = ProposalTab.selectMessage(10);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    }
});

class NarrativeTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields, ormProposalUpdateLocalOnly, proposal } = this.props;
        document.title = "Proposal: Narrative - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!proposal.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormProposalUpdateLocalOnly({
                    id: proposal.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const {
            ormProposalUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            proposal,
            ormProposalUpdateLocalOnly
        } = this.props;
        const { id } = proposal;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (
                (proposal.status !== "Draft In Progress" &&
                    proposal.status !== "Draft Feedback Received" &&
                    proposal.status !== "Revisions Needed") ||
                (this.props.authState &&
                    this.props.authState.user &&
                    (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member"))
            ) {
                ormProposalUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = proposal.map;
        values.financial_capacity = proposal.financial_capacity;
        values.modify_date_label = proposal.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                handlePrev();
            } else {
                handleNext();
            }
        }
    }

    render() {
        const { classes, proposal, handleUnsavedFields, ActionMenu, tab, ormProposalUpdateLocalOnly } = this.props;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        return (
            <Form
                getApi={el => (this.form = el)}
                key={proposal.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={proposal.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="titleAction">Project Description</Typography>
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <TextField disabled={is_read_only} field="summary" maxWords="30" multiline fullWidth
                                            checkFocus={proposal}
                                            focusRemoval={ormProposalUpdateLocalOnly}>
                                    <HelpLabel
                                        inputLabel="Project Summary (30 word limit)"
                                        htmlText={true}
                                        showLabel={true}
                                        helpText={
                                            tab.tab_help_texts.find(
                                                tht => tht.label === "Project Summary. (30 word limit)"
                                            ).text
                                        }
                                    />
                                </TextField>
                                <TextField
                                    field="opportunity"
                                    maxWords="200"
                                    multiline
                                    checkFocus={proposal}
                                    focusRemoval={ormProposalUpdateLocalOnly}
                                    disabled={is_read_only}
                                    fullWidth
                                    label="Describe the opportunity or problem your proposal seeks to address. Include any relevant background information. (200 word limit)"
                                />
                                <TextField
                                    field="solution"
                                    maxWords="200"
                                    multiline
                                    checkFocus={proposal}
                                    focusRemoval={ormProposalUpdateLocalOnly}
                                    disabled={is_read_only}
                                    fullWidth
                                    label="What is your proposed solution to the problem or opportunity discussed above? Introduce us to the work you are seeking funding to do. You will be asked to expand on this proposed solution in Activities & Milestones. (200 word limit)"
                                />
                                <TextField
                                    field="outcome"
                                    maxWords="100"
                                    multiline
                                    checkFocus={proposal}
                                    focusRemoval={ormProposalUpdateLocalOnly}
                                    disabled={is_read_only}
                                    fullWidth
                                    label="What are the specific project outcomes as they relate to the public purpose of protection, conservation, preservation, and enhancement of the state’s natural resources? (100 word limit)"
                                />
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <HelpLabel
                                    inputLabel="Timeline"
                                    htmlText={true}
                                    showLabel={true}
                                    helpText={tab.tab_help_texts.find(tht => tht.label === "Timeline").text}
                                />
                                <br />
                                <MonthPicker
                                    field="timeline"
                                    disabled={is_read_only}
                                    checkFocus={proposal}
                                    focusRemoval={ormProposalUpdateLocalOnly}
                                    label={
                                        "Assuming your project begins July 1, " +
                                        proposal.ml_year +
                                        ", when will your project be complete? Please note, the standard project length is two years."
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="titleAction">Long-Term Implementation and Funding</Typography>
                                <TextField
                                    field="longterm_implementation"
                                    maxWords="100"
                                    checkFocus={proposal}
                                    focusRemoval={ormProposalUpdateLocalOnly}
                                    disabled={is_read_only}
                                    multiline
                                    fullWidth
                                    label="Describe how the results will be implemented and how any ongoing effort will be funded. If not already addressed as part of the project, how will findings, results, and products developed be implemented after project completion? If additional work is needed, how will this work be funded? (100 word limit)"></TextField>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ float: "left" }}
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                    className={classes.button}>
                                    Save and Return to Previous Step
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                    Save and Proceed to Next Step
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

NarrativeTab = connect(
    (state, ownProps) => ({
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...Proposal.actions
    }
)(NarrativeTab);

export default withStyles(styles)(withRouter(NarrativeTab));
