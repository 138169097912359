// Convert API name/id into react label/value

// Cache array object so react-form's shouldComponentUpdate sees it as constant
var tables = {};
export function MAKE_OPTIONS(table) {
    var key = table.join(":");
    if (!tables[key]) {
        table = table.filter(n => n);
        tables[key] = table.map(row => ({
            label: row,
            value: row
        }));
    }
    return tables[key];
}

export function MAKE_OPTIONS_YESNO(table) {
    /*var key = table.join(":");
    if (!tables[key]) {
        tables[key] = table.map(row => ({
            label: row.label,
            value: row.value,
            identifier: row.identifier ? row.identifier : row.value,
            disabled: row.disabled
        }));
    }
    return tables[key];*/
    return table.map(row => ({
        label: row.label,
        value: row.value,
        identifier: row.identifier ? row.identifier : row.value,
        disabled: row.disabled
    }));
}

export const budget_items = [
    "Personnel",
    "Contracts",
    "Fee Acquisition w/ PILT",
    "Fee Acquisition w/o PILT",
    "Easement Acquisition",
    "Easement Stewardship",
    "Travel",
    "Professional Services",
    "Direct Support Services",
    "DNR Land Acquisition Costs",
    "Capital Equipment",
    "Other Equipment/Tools",
    "Supplies/Materials",
    "DNR IDP",
    "Grand Total"
];

export const FORMAT_TABLE_VALUE = (val, round) => {
    /*if (!val)
        return 0;
    if (typeof val === 'string') {
        val = val.replace("$", "");
        val = val.replace(/,/g, '');
    }*/
    val = parseInt(val) || 0;
    if (val < 0) val = 0;
    if (round) val = Math.round(val / 100) * 100;
    return val;
};

export const COUNTIES = {
    Aitkin: "Aitkin",
    Anoka: "Anoka",
    Becker: "Becker",
    Beltrami: "Beltrami",
    Benton: "Benton",
    "Big Stone": "Big Stone",
    "Blue Earth": "Blue Earth",
    Brown: "Brown",
    Carlton: "Carlton",
    Carver: "Carver",
    Cass: "Cass",
    Chippewa: "Chippewa",
    Chisago: "Chisago",
    Clay: "Clay",
    Clearwater: "Clearwater",
    Cook: "Cook",
    Cottonwood: "Cottonwood",
    "Crow Wing": "Crow Wing",
    Dakota: "Dakota",
    Dodge: "Dodge",
    Douglas: "Douglas",
    Faribault: "Faribault",
    Fillmore: "Fillmore",
    Freeborn: "Freeborn",
    Goodhue: "Goodhue",
    Grant: "Grant",
    Hennepin: "Hennepin",
    Houston: "Houston",
    Hubbard: "Hubbard",
    Isanti: "Isanti",
    Itasca: "Itasca",
    Jackson: "Jackson",
    Kanabec: "Kanabec",
    Kandiyohi: "Kandiyohi",
    Kittson: "Kittson",
    Koochiching: "Koochiching",
    "Lac qui Parle": "Lac qui Parle",
    Lake: "Lake",
    "Lake of the Woods": "Lake of the Woods",
    "Le Sueur": "Le Sueur",
    Lincoln: "Lincoln",
    Lyon: "Lyon",
    McLeod: "McLeod",
    Mahnomen: "Mahnomen",
    Marshall: "Marshall",
    Martin: "Martin",
    Meeker: "Meeker",
    "Mille Lacs": "Mille Lacs",
    Morrison: "Morrison",
    Mower: "Mower",
    Murray: "Murray",
    Nicollet: "Nicollet",
    Nobles: "Nobles",
    Norman: "Norman",
    Olmsted: "Olmsted",
    "Otter Tail": "Otter Tail",
    Pennington: "Pennington",
    Pine: "Pine",
    Pipestone: "Pipestone",
    Polk: "Polk",
    Pope: "Pope",
    Ramsey: "Ramsey",
    "Red Lake": "Red Lake",
    Redwood: "Redwood",
    Renville: "Renville",
    Rice: "Rice",
    Rock: "Rock",
    Roseau: "Roseau",
    Scott: "Scott",
    Sherburne: "Sherburne",
    Sibley: "Sibley",
    "St. Louis": "St. Louis",
    Stearns: "Stearns",
    Steele: "Steele",
    Stevens: "Stevens",
    Swift: "Swift",
    Todd: "Todd",
    Traverse: "Traverse",
    Wabasha: "Wabasha",
    Wadena: "Wadena",
    Waseca: "Waseca",
    Washington: "Washington",
    Watonwan: "Watonwan",
    Wilkin: "Wilkin",
    Winona: "Winona",
    Wright: "Wright",
    "Yellow Medicine": "Yellow Medicine"
};

export const TOWNSHIPS = {
    "026": "026",
    "027": "027",
    "028": "028",
    "029": "029",
    "030": "030",
    "031": "031",
    "032": "032",
    "033": "033",
    "034": "034",
    "035": "035",
    "036": "036",
    "037": "037",
    "038": "038",
    "039": "039",
    "040": "040",
    "041": "041",
    "042": "042",
    "043": "043",
    "044": "044",
    "045": "045",
    "046": "046",
    "047": "047",
    "048": "048",
    "049": "049",
    "050": "050",
    "051": "051",
    "052": "052",
    "053": "053",
    "054": "054",
    "055": "055",
    "056": "056",
    "057": "057",
    "058": "058",
    "059": "059",
    "060": "060",
    "061": "061",
    "062": "062",
    "063": "063",
    "064": "064",
    "065": "065",
    "066": "066",
    "067": "067",
    "068": "068",
    "069": "069",
    "070": "070",
    "071": "071",
    "072": "072",
    "073": "073",
    "074": "074",
    "075": "075",
    "076": "076",
    "077": "077",
    "078": "078",
    "079": "079",
    "080": "080",
    "081": "081",
    "082": "082",
    "083": "083",
    "084": "084",
    "085": "085",
    "086": "086",
    "087": "087",
    "088": "088",
    "089": "089",
    "090": "090",
    "091": "091",
    "092": "092",
    "093": "093",
    "094": "094",
    "095": "095",
    "096": "096",
    "097": "097",
    "098": "098",
    "099": "099",
    "100": "100",
    "101": "101",
    "102": "102",
    "103": "103",
    "104": "104",
    "105": "105",
    "106": "106",
    "107": "107",
    "108": "108",
    "109": "109",
    "110": "110",
    "111": "111",
    "112": "112",
    "113": "113",
    "114": "114",
    "115": "115",
    "116": "116",
    "117": "117",
    "118": "118",
    "119": "119",
    "120": "120",
    "121": "121",
    "122": "122",
    "123": "123",
    "124": "124",
    "125": "125",
    "126": "126",
    "127": "127",
    "128": "128",
    "129": "129",
    "130": "130",
    "131": "131",
    "132": "132",
    "133": "133",
    "134": "134",
    "135": "135",
    "136": "136",
    "137": "137",
    "138": "138",
    "139": "139",
    "140": "140",
    "141": "141",
    "142": "142",
    "143": "143",
    "144": "144",
    "145": "145",
    "146": "146",
    "147": "147",
    "148": "148",
    "149": "149",
    "150": "150",
    "151": "151",
    "152": "152",
    "153": "153",
    "154": "154",
    "155": "155",
    "156": "156",
    "157": "157",
    "158": "158",
    "159": "159",
    "160": "160",
    "161": "161",
    "162": "162",
    "163": "163",
    "164": "164",
    "165": "165",
    "166": "166",
    "167": "167",
    "168": "168"
};

export const RANGES = {
    "01": "01",
    "02": "02",
    "03": "03",
    "04": "04",
    "05": "05",
    "06": "06",
    "07": "07",
    "08": "08",
    "09": "09",
    "10": "10",
    "11": "11",
    "12": "12",
    "13": "13",
    "14": "14",
    "15": "15",
    "16": "16",
    "17": "17",
    "18": "18",
    "19": "19",
    "20": "20",
    "21": "21",
    "22": "22",
    "23": "23",
    "24": "24",
    "25": "25",
    "26": "26",
    "27": "27",
    "28": "28",
    "29": "29",
    "30": "30",
    "31": "31",
    "32": "32",
    "33": "33",
    "34": "34",
    "35": "35",
    "36": "36",
    "37": "37",
    "38": "38",
    "39": "39",
    "40": "40",
    "41": "41",
    "42": "42",
    "43": "43",
    "44": "44",
    "45": "45",
    "46": "46",
    "47": "47",
    "48": "48",
    "49": "49",
    "50": "50",
    "51": "51"
};

export const SECTIONS = {
    "01": "01",
    "02": "02",
    "03": "03",
    "04": "04",
    "05": "05",
    "06": "06",
    "07": "07",
    "08": "08",
    "09": "09",
    "10": "10",
    "11": "11",
    "12": "12",
    "13": "13",
    "14": "14",
    "15": "15",
    "16": "16",
    "17": "17",
    "18": "18",
    "19": "19",
    "20": "20",
    "21": "21",
    "22": "22",
    "23": "23",
    "24": "24",
    "25": "25",
    "26": "26",
    "27": "27",
    "28": "28",
    "29": "29",
    "30": "30",
    "31": "31",
    "32": "32",
    "33": "33",
    "34": "34",
    "35": "35",
    "36": "36"
};

export const ACTIVITIES = {
    "Protect in Fee with PILT": "Protect in Fee with PILT",
    "Protect in Fee w/o PILT": "Protect in Fee w/o PILT",
    "Protect in Easement": "Protect in Easement",
    Restore: "Restore",
    Enhance: "Enhance",
    Other: "Other",
    "Not Applicable": "Not Applicable"
};

export const OPENS = {
    Full: "Full",
    Limited: "Limited",
    No: "No",
    "Not Applicable": "Not Applicable"
};

export const YESES = {
    Yes: "Yes",
    No: "No"
};

// Project Phase to allow withdrawal
export const allowedWithDrawal = ["Proposal", "Draft Work Plan", "Final Work Plan"];

// Staff Dashboard - Work Plan Section - Select Status(es)
export const WorkPlanStatuses2 = [
    "Draft Work Plan Due",
    "Draft Work Plan Submitted",
    "Draft Work Plan Revisions Needed",
    "Draft Work Plan Revisions Submitted",
    "Draft Work Plan Accepted",
    "Final Work Plan Due",
    "Final Work Plan Submitted",
    "Final Work Plan Revisions Needed",
    "Final Work Plan Revisions Submitted",
    "Final Work Plan Accepted"
];

// Staff Dashboard - Active Project Section - Select Status(es)
export const ActiveProjectStatuses = [
    "Update Due",
    "Update Submitted",
    "Update Revisions Needed",
    "Update Revisions Submitted",
    "Update Approved",
    "Additional Update In Progress",
    "Additional Update Submitted",
    "Additional Update Revisions Needed",
    "Additional Update Revisions Submitted",
    "Additional Update Approved",
    "Amendment In Progress",
    "Amendment Submitted",
    "Amendment Revisions Needed",
    "Amendment Revisions Submitted",
    "Amendment Approved",
    "Final Update Due",
    "Final Update Submitted",
    "Final Update Revisions Needed",
    "Final Update Revisions Submitted",
    "Final Update Approved",
    "Final Abstract Due",
    "Final Abstract Submitted",
    "Final Abstract Revisions Needed"
];

// Status at which to allow "Set Due Date and Send Work Plan Back to Applicant for Revisions"
export const workPlanRevisions = [
    "Draft Work Plan Submitted",
    "Draft Work Plan Revisions Submitted",
    "Final Work Plan Submitted",
    "Final Work Plan Revisions Submitted",
    "Draft Work Plan Accepted",
    "Final Work Plan Accepted"
];

// Status at which to allow "Add Staff Comment"
export const workPlanRevisions2 = [
    "Draft Work Plan Submitted",
    "Draft Work Plan Revisions Submitted",
    "Final Work Plan Submitted",
    "Final Work Plan Revisions Submitted",
    "Draft Work Plan Accepted",
    "Final Work Plan Accepted",
    "Final Work Plan Approved",
    "Final Abstract Due",
    "Final Abstract Submitted",
    "Final Abstract Revisions Needed"
];

// Staff Dashboard - Work Plan Section - Status in which staff awaiting lccmr action
export const workPlanComments = [
    "Draft Work Plan Submitted",
    "Draft Work Plan Revisions Submitted",
    "Final Work Plan Submitted",
    "Final Work Plan Revisions Submitted"
];

// ?
export const editWorkPlanStatuses = ["Draft Work Plan Due", "Draft Work Plan Revisions Needed", "Final Work Plan Due", "Final Work Plan Revisions Needed"];

// Status Log - Where to redirect back to depending on status
export const propStatus = ["Draft In Progress", "Draft Submitted", "Draft Feedback Received", "Final Submitted", "Revisions Needed", "Revisions Submitted"];
export const draftStatus = ['Draft Work Plan Due', 'Draft Work Plan Submitted', 'Draft Work Plan Revisions Needed', 'Draft Work Plan Revisions Submitted', 'Draft Work Plan Accepted'];
export const finalStatus = ['Final Work Plan Due', 'Final Work Plan Submitted', 'Final Work Plan Revisions Needed', 'Final Work Plan Revisions Submitted', 'Final Work Plan Accepted', 'Final Work Plan Approved', 'Final Abstract Due', 'Final Abstract Submitted', 'Final Abstract Revisions Needed', 'Project Completed'];