import React, { Component } from "react";
import { Tab, Tabs, withStyles } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import AppContainer from "../common/AppContainer";
import Grid from "@material-ui/core/Grid";
import PageHeader from "../common/PageHeader";
import { connect } from "react-redux";
import { Form } from "react-form";
import { StatusUpdateHelpText, StatusUpdateTab } from "../home/models";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "@material-ui/core/Button";

const getHelpMessages = StatusUpdateHelpText.selectAll();

const getTabs = StatusUpdateTab.selectAll();

const styles = () => ({
    fields: {
        padding: "15px",
        border: "1px solid #eee",
        marginBottom: 30
    },
    error: {
        color: "red"
    }
});

class StatusUpdateHelpMessages extends Component {
    toolbarConfig = {
        options: ["inline", "link", "list"],
        inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"]
        },
        link: {
            inDropdown: false,
            showOpenOptionOnHover: true,
            defaultTargetOption: "_self",
            options: ["link", "unlink"]
        },
        list: {
            inDropdown: false,
            options: ["ordered", "unordered"]
        }
    };

    constructor(props) {
        super(props);
        this.state = { selectedTab: "Introduction", subTab: null, formData: {}, errors: {}, updated: false };
    }

    componentDidMount() {
        document.title = "Configure Help Messages for Active Projects - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
        this.setFormData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.messages !== this.props.messages) {
            const { updated } = this.state;
            if (updated) {
                return;
            }
            this.setFormData();
        }
    }

    setFormData = async () => {
        const { ormStatusUpdateTabReload } = this.props;
        await ormStatusUpdateTabReload();
        const { tabs } = this.props;
        const { selectedTab, subTab, errors } = this.state;

        await Object.keys(errors).forEach(field => (errors[field].required = false));
        await this.setState(prev => ({ ...prev, errors }));

        let fieldIdx = 0;
        let formData = {};
        await tabs.forEach(obj => {
            if (obj.tab_name === selectedTab) {
                if (obj.sub_tabs.length) {
                    const helpTexts = obj.sub_tabs.find(tab => tab.tab_name === subTab).tab_help_texts;
                    while (fieldIdx !== helpTexts.length) {
                        const found = helpTexts.find(msg => msg.field_index === fieldIdx); // eslint-disable-line no-loop-func
                        formData[`${found.label}-${fieldIdx}`] = this.getHTMLToDraft(found.text);
                        errors[`${found.label}-${fieldIdx}`] = { required: false };
                        fieldIdx++;
                    }
                } else {
                    if (obj.tab_help_texts.length) {
                        while (fieldIdx !== obj.tab_help_texts.length) {
                            const found = obj.tab_help_texts.find(msg => msg.field_index === fieldIdx); // eslint-disable-line no-loop-func
                            formData[`${found.label}-${fieldIdx}`] = this.getHTMLToDraft(found.text);
                            errors[`${found.label}-${fieldIdx}`] = { required: false };
                            fieldIdx++;
                        }
                    }
                }
            }
        });

        await this.setState(prev => ({ ...prev, formData, errors }));
    };

    switchTab = async (e, tabVal) => {
        await this.setState(prev => ({ ...prev, selectedTab: tabVal }));
        await this.setFormData();
    };

    switchSubTab = async (e, tabVal) => {
        await this.setState(prev => ({ ...prev, subTab: tabVal }));
        await this.setFormData();
    };

    changeEditorState = (contentState, field) => {
        let { formData, errors } = this.state;
        formData[field] = contentState;
        errors[field].required = !(draftToHtml(convertToRaw(formData[field].getCurrentContent())).length > 8);
        this.setState(prev => ({ ...prev, formData, errors }));
    };

    getHTMLToDraft = html => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
    };

    isBudgetSubTabSelected = () => {
        const { subTab } = this.state;
        const budgetSubTabs = ["Summary"];
        if (budgetSubTabs.some(val => val === subTab)) {
            return subTab;
        }
        this.setState(prev => ({ ...prev, subTab: "Summary" }));
        return subTab;
    };

    isAcqAndResSubtabSelected = () => {
        const { subTab } = this.state;
        const acqAndResSubTabs = ["Parcel List"];
        if (acqAndResSubTabs.some(val => val === subTab)) {
            return subTab;
        }
        this.setState(prev => ({ ...prev, subTab: "Parcel List" }));
        return subTab;
    };

    ifErrors = () => {
        const { errors } = this.state;
        return Object.keys(errors).filter(field => errors[field].required).length > 0;
    };

    submitForm = () => {
        const { ormStatusUpdateHelpTextUpdate, messages } = this.props;
        const { selectedTab, subTab, formData } = this.state;
        let updatedData = [];
        let updatedForm = {};
        Object.keys(formData).forEach(async (field, idx) => {
            let temp = {};
            let fieldID;

            try {
                fieldID = messages.find(
                    msg =>
                        msg.tab_name_label === selectedTab &&
                        msg.label === `${field.replace(`-${idx}`, "")}` &&
                        msg.field_index === idx
                ).id;
            } catch (e) {
                fieldID = messages.find(
                    msg =>
                        msg.tab_name_label === `Parent: ${selectedTab} => Child: ${subTab}` &&
                        msg.label === `${field.replace(`-${idx}`, "")}` &&
                        msg.field_index === idx
                ).id;
            }
            if (!(fieldID in updatedData.map(p => p.id))) {
                temp.id = fieldID;
                temp.text = draftToHtml(convertToRaw(formData[field].getCurrentContent()));
                updatedData.push(temp);
                updatedForm[field] = formData[field];

                this.setState(prev => ({ ...prev, updated: true }));
                await ormStatusUpdateHelpTextUpdate(temp);
            }
        });
        this.setState(prev => ({ ...prev, formData: updatedForm }));
    };

    render() {
        const { tabs, classes } = this.props;
        const { selectedTab, formData, errors } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Configure Help Messages for Active Projects" />

                    <Tabs
                        value={selectedTab}
                        onChange={this.switchTab}
                        variant="scrollable"
                        indicatorColor="primary"
                        scrollButtons="auto">
                        {tabs.map(tab => (
                            <Tab value={tab.tab_name} label={tab.tab_name} />
                        ))}
                    </Tabs>

                    {selectedTab === "Budget" && (
                        <Grid item xs={12}>
                            <Tabs
                                value={this.isBudgetSubTabSelected()}
                                onChange={this.switchSubTab}
                                variant="scrollable"
                                scrollButtons="auto">
                                {tabs
                                    .find(parent => parent.tab_name === selectedTab)
                                    .sub_tabs.map(tab => (
                                        <Tab value={tab.tab_name} label={tab.tab_name} />
                                    ))}
                            </Tabs>
                        </Grid>
                    )}

                    {selectedTab === "Acquisition and Restoration" && (
                        <Grid item xs={12}>
                            <Tabs
                                value={this.isAcqAndResSubtabSelected()}
                                onChange={this.switchSubTab}
                                variant="scrollable"
                                scrollButtons="auto">
                                {tabs
                                    .find(parent => parent.tab_name === selectedTab)
                                    .sub_tabs.map(tab => (
                                        <Tab value={tab.tab_name} label={tab.tab_name} />
                                    ))}
                            </Tabs>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={formData}
                            onSubmit={this.submitForm}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        {Object.keys(formData).map((field, idx) => (
                                            <Grid item xs={12} key={`parent-${field}`} className={classes.fields}>
                                                <label>
                                                    <h3>{field.replace(`-${idx}`, "")}</h3>
                                                </label>
                                                <Editor
                                                    toolbar={this.toolbarConfig}
                                                    editorState={formData[field]}
                                                    onEditorStateChange={editorState =>
                                                        this.changeEditorState(editorState, field)
                                                    }>
                                                    <textarea
                                                        disabled
                                                        value={draftToHtml(
                                                            convertToRaw(formData[field].getCurrentContent())
                                                        )}
                                                    />
                                                </Editor>
                                                {errors[field].required && (
                                                    <div className={classes.error}>*This field is required!</div>
                                                )}
                                            </Grid>
                                        ))}

                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={this.ifErrors()}>
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth component={Link} to="/adm/home">
                                                Return to Admin Home
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

StatusUpdateHelpMessages = connect(
    (state, ownProps) => ({
        authState: state.auth,
        messages: getHelpMessages(state, ownProps),
        tabs: getTabs(state, ownProps)
    }),
    {
        ...StatusUpdateHelpText.actions,
        ...StatusUpdateTab.actions
    }
)(StatusUpdateHelpMessages);

export default withStyles(styles)(withRouter(StatusUpdateHelpMessages));
