import React, { Component } from "react";
import { FormField } from "react-form";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import { FixedSizeList } from "react-window";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "4px 12px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,64,110,.50)"
        },
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: 300
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #be0000"
        }
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #be0000"
    },
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap"
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -8,
        marginBottom: -12,
        //position: "relative",
        whiteSpace: "normal"
    },
    dark: {
        "& input": {
            color: "#000000 !important"
        },
        "& div": {
            color: "#000000 !important"
        },
        "& textarea": {
            color: "#000000 !important"
        }
    },
    whiteSpace: {
        whiteSpace: "nowrap"
    },
    disabled: {
        backgroundColor: "#EEEEEE"
    },

    formControl: {
        marginTop: 16,
        maxWidth: "100%",
        minWidth: 150,
        marginBottom: 8
    }
});

class SelectWrapper extends Component {
    handleItemClick = (clickedVal, previousVal) => {
        if (previousVal.includes(clickedVal)) {
            previousVal = previousVal.filter(i => i !== clickedVal);
        } else {
            previousVal.push(clickedVal);
        }

        const { fieldApi, eventHandle } = this.props;
        const { setValue, getFieldName } = fieldApi;

        setValue(previousVal);

        if (eventHandle) eventHandle(previousVal, getFieldName());
        this.setState({ foo: "bar" }); // Force rerender
    };

    render() {
        const { classes, fieldApi, children, label, options, disabled, 
            checkFocus,
            focusRemoval, ...rest } = this.props;
        const { getValue, getFieldName } = fieldApi;

        const val = getValue();

        let InputProps = {};

        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput, disabled && classes.disabled),
            error: classes.bootstrapInputError
        };

        const MenuProps = {
            PaperProps: {
                style: {
                    width: 300,
                    minWidth: 300
                }
            },
            MenuListProps: {
                component: Wrapper,
                itemData: { onClick: this.handleItemClick, options, val }
            }
            //anchorReference: 'anchorPosition',
            //anchorPosition: {left: 100, top: 500}
            //anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
        };

        return (
            <FormControl className={classes.formControl}>
                <InputLabel
                    shrink={true}
                    classes={{
                        root: classes.rootLabel,
                        shrink: classes.rootLabelShrink
                    }}>
                    {label}
                </InputLabel>
                <Select
                    multiple
                    value={val}
                    fullWidth={true}
                    disableUnderline={true}
                    InputProps={InputProps}
                    disabled={disabled}
                    inputRef={(input) => {
                        if (checkFocus && checkFocus.highlightedField && checkFocus.highlightedField === getFieldName() && input != null) {
                           input.focus();
                           focusRemoval({
                               id: checkFocus.id,
                               highlightedField: null
                           })
                        }
                    }}
                    className={classNames(
                        classes.bootstrapInput,
                        disabled && classes.disabled,
                        disabled && classes.dark
                    )}
                    classes={{
                        input: classNames(classes.bootstrapInput, disabled && classes.disabled),
                        error: classes.bootstrapInputError
                    }}
                    renderValue={selected => selected.join(", ")}
                    MenuProps={MenuProps}
                    {...rest}
                />
            </FormControl>
        );
    }
}

const Row = props => {
    const {
        index,
        style,
        data: { onClick, options, val }
    } = props;
    const value = options[index].value;
    const label = options[index].label;
    var test = JSON.parse(JSON.stringify(style));
    test.paddingTop = 0;
    test.paddingBottom = 0;
    test.width = "250px"; // Remove horizontal scrollbar

    return (
        <MenuItem style={test} key={value} value={value} onClick={() => onClick(value, val)}>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={val.indexOf(value) > -1}
                        value={label}
                    />
                }
                label={label}
            />
        </MenuItem>
    );
};

const Wrapper = React.forwardRef((props, ref) => (
    <FixedSizeList
        {...props}
        height={205}
        itemCount={props.itemData.options.length}
        itemSize={40}
        width={300}
        ref={ref}>
        {Row}
    </FixedSizeList>
));

const TextField = FormField(SelectWrapper);

export default withStyles(styles)(TextField);
