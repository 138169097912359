import React, { Component } from "react";
import classNames from "classnames";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import MuiTextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";

import { withStyles, Tooltip } from "@material-ui/core";

const styles = theme => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "8px 12px",
        width: "calc(100% - 24px)",
        minWidth: "250",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,64,110,.50)"
        },
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: 300
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #be0000"
        }
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #be0000"
    },
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap"
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginBottom: -16,
        position: "relative",
        whiteSpace: "normal",
        lineHeight: 1.5
    },
    whiteSpace: {
        whiteSpace: "nowrap"
    },
    disabled: {
        backgroundColor: "#EEEEEE"
    },
    wordCount: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -16,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
    wordCountSmall: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -10,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
    marginDense: {
        marginTop: 8,
        marginBottom: 4
    },
    dark: {
        "& input": {
            color: "#000000 !important"
        },
        "& div": {
            color: "#000000 !important"
        },
        "& textarea": {
            color: "#000000 !important"
        }
    },
    listItem: {
        borderBottom: "1px solid #666666",
        "&:focus": {
            backgroundColor: "rgba(0, 0, 0, 0.08) !important"
        }
    },
});

// See https://react-form.js.org/#/custom-input
class TextFieldWrapper extends Component {
    handleChange = e => {
        let value = e.target.value;

        const { fieldApi, eventHandle, readOnly, numberRange, min, max } = this.props;
        const { setValue, getFieldName } = fieldApi;

        if (numberRange) {
            if (parseInt(value) < min) {
                setValue(min.toString());
                if (eventHandle) eventHandle(min.toString(), getFieldName());
            } else if (parseInt(value) > max) {
                setValue(max.toString());
                if (eventHandle) eventHandle(max.toString(), getFieldName());
            } else {
                setValue((!isNaN(parseInt(value)) ? parseInt(value, 10) : "").toString());
                if (eventHandle) eventHandle(value, getFieldName());
            }
            return;
        }

        if (!readOnly) {
            setValue(value);

            if (eventHandle) eventHandle(value, getFieldName());
        }
    };

    render() {
        const {
            SelectProps,
            classes,
            label,
            id,
            includeEmpty,
            handleChange,
            multiline,
            filterInput,
            value,
            sortOptions
        } = this.props;

        let InputProps = {};

        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput),
            error: classes.bootstrapInputError
        };

        let sProps = {};
        if (SelectProps !== undefined) {
            sProps = SelectProps;
        }

        if (value && filterInput) {
            InputProps["endAdornment"] = (
                <InputAdornment position="end">
                    <Tooltip title="Clear Search">
                        <IconButton aria-label="Clear Search" onClick={this.handleClear}>
                            <Cancel />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            );
        }

        return (
            <MuiTextField
                select
                value={value}
                label={label}
                id={id}
                onChange={handleChange}
                SelectProps={sProps}
                InputProps={InputProps}
                multiline={multiline}
                readOnly
                InputLabelProps={{
                    shrink: true,
                    classes: {
                        root: classes.rootLabel,
                        shrink: classes.rootLabelShrink
                    }
                }}
                margin="normal"
                classes={{
                    marginNormal: classes.marginDense
                }}>
                {includeEmpty && (
                    <MenuItem className={classes.listItem} key="-- Empty --" value="-- Empty --">
                        <ListItemText primary="-- Empty --" />
                    </MenuItem>
                )}
                {sortOptions.map(x => {
                    return (
                        <MenuItem className={classes.listItem} key={x.value} value={x.value}>
                            <ListItemText primary={x.label} />
                        </MenuItem>
                    );
                })}
            </MuiTextField>
        );
    }
}

export default withStyles(styles)(TextFieldWrapper);
