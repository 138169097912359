import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import CustomTabelCell from "../../common/TableCell";
import TextField from "../../common/TextField";
import Snackbar from "../../common/Snackbar";
import { createSelector } from "../../common/orm";
import { ProposalReview } from "../../home/models";

const getStaff = createSelector(schema => {
    return schema.User.filter(x => x.role === "Staff").toRefArray();
});

const getStaffScore = createSelector(
    (state, ownProps) => ownProps.proposal.id,
    (schema, proposal) => {
        return schema.StaffScore.filter(x => x.proposal === proposal).toModelArray();
    }
);

const getReview = createSelector(
    (state, ownProps) => ownProps.proposal.id,
    (schema, proposal) => {
        return schema.ProposalReview.filter(x => x.proposal === proposal).first();
    }
);

function getMedian(array) {
    array.sort(function(a, b) {
        return a - b;
    });
    var mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
}

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginTop: 0
        }
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    flex: {
        flex: 1
    },
    total: {
        fontWeight: "Bold",
        color: "#000000"
    },
    text: {
        width: 200
    }
});

const keys = [
    {
        key: "staff_1",
        slot: 1
    },
    {
        key: "staff_2",
        slot: 2
    },
    {
        key: "staff_3",
        slot: 3
    },
    {
        key: "staff_4",
        slot: 4
    },
    {
        key: "staff_5",
        slot: 5
    },
    {
        key: "staff_6",
        slot: 6
    }
];

const categories = [
    {
        label: "Funding Priorities",
        field: "funding_priorities"
    },
    {
        label: "Multiple Benefits",
        field: "multiple_benefits"
    },
    {
        label: "Outcomes",
        field: "outcomes"
    },
    {
        label: "Knowledge Base",
        field: "knowledge_base"
    },
    {
        label: "Extent of Impact",
        field: "extent_of_impact"
    },
    {
        label: "Innovation",
        field: "innovation"
    },
    {
        label: "Scientific/Technical Basis",
        field: "scientific_technical_basis"
    },
    {
        label: "Urgency",
        field: "urgency"
    },
    {
        label: "Capacity and Readiness",
        field: "capacity_and_readiness"
    },
    {
        label: "Leverage",
        field: "leverage"
    },
];

class Aggregate_Score extends Component {
    state = {
        proposalPage: false,
        dont: false,
        error: false,
        message: ""
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Staff Aggregate Scoring and Final Staff Score - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentDidUpdate() {
        //resets form to include id after creation of score
        if (this.state.dont) {
            this.setState({ dont: false });
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        const validateScrore = score => {
            if (parseInt(score) < 0 || parseInt(score) > 100) {
                return "Score must be between 0-100";
            } else if (isNaN(parseInt(score))) {
                return "Valid Score between 0-100 is required";
            }
            return null;
        };

        return {
            final_score: validateScrore(values.final_score)
        };
    };

    submit = values => {
        const {
            authState,
            getStaffKeys,
            proposal,
            ormProposalReviewUpdate,
            ormProposalReviewCreateLocalOnly,
            ormProposalReviewCreate
        } = this.props;

        const staff = getStaffKeys();

        fetch(`api/db/proposalreviews?format=json&t=${Date.now()}&proposal=${proposal.id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            }
        })
            .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
            })
            .then(data => {
                if (data.length === 0) {
                    if (!values.id) {
                        ormProposalReviewCreate({
                            proposal_id: proposal.id,
                            ...staff,
                            ...values
                        }).then(id => {
                            this.setState({ dont: true });
                        });
                    }
                } else if (data.length === 1) {
                    if (values.id) {
                        ormProposalReviewUpdate({
                            ...values
                        });
                    } else {
                        ormProposalReviewCreateLocalOnly({
                            ...data[0],
                            proposal: data[0].proposal_id
                        });
                        this.setState({
                            error: true,
                            message: "Existing review was found! Please re-enter data!",
                            dont: true
                        });
                    }
                } else {
                    this.setState({ error: true, message: "Error!" });
                }
            })
            .catch(e => {
                console.log(e);
                this.setState({ error: true, message: "Error!" });
            });
    };

    getObj = (review, scores) => {
        if (!review) {
            var temp_arr = [];
            const staff = this.props.getStaffKeys();
            keys.forEach(x => {
                const user = this.props.allStaff.find(z => z.id === staff[x.key]);
                const score = scores.find(x => x.user.id === user.id);
                temp_arr[x.slot] = {
                    user: user ? user : null,
                    ...score
                };
            });
            return temp_arr;
        }

        var arr = [];
        for (var i = 1; i < 7; i++) {
            //const id = review[`staff_${i}_id`];
            const id = review.ref[`staff_${i.toString()}`];
            //const score = scores.find(x => x.user_id === id);
            const score = scores.find(x => x.user.id === id);
            if (score && score.done) {
                arr[score.slot] = score;
            } else {
                arr[i] = {
                    user: review[`staff_${i}`]
                };
            }
        }
        return arr;
    };

    avg = (key, obj) => {
        var arr = [];
        obj.forEach(x => {
            if (x && x[key]) {
                arr.push(x[key]);
            }
        });
        const avg = (arr.reduce((a, b) => a + b, 0) / arr.length).toLocaleString();
        if (isNaN(avg)) {
            return "-";
        } else {
            return Math.round(avg);
        }
    };

    median = (key, obj) => {
        var arr = [];
        obj.forEach(x => {
            if (x && x[key]) {
                arr.push(x[key]);
            }
        });
        const median = getMedian(arr);
        if (isNaN(median)) {
            return "-";
        } else {
            return Math.round(median);
        }
    };

    range = (key, obj) => {
        var arr = [];
        obj.forEach(x => {
            if (x && x[key]) {
                arr.push(x[key]);
            }
        });
        const max = Math.max(...arr)
        const min = Math.min(...arr)
        const range = max - min;
        if (range === 0 || isNaN(range) || range === Infinity || range === -Infinity)
            return "-"
        return range;
    };

    sumMedian = obj => {
        return this.median("total", obj);
    };

    sumAverage = obj => {
        return this.avg("total", obj);
    };

    sumRange = obj => {
        return this.range("total", obj);
    };

    render() {
        const { classes, settings, scores, review, ActionMenu } = this.props;
        const { dont } = this.state;

        const obj = this.getObj(review, scores);

        return (
            <>
                {!dont && (
                    <Form
                        defaultValues={review}
                        validateOnSubmit={true}
                        validateError={this.errorValidator}
                        dontValidateOnMount={true}
                        onSubmit={this.submit}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16} style={{ marginTop: 0 }}>
                                    <Grid item xs={12} lg={10} xl={10}>
                                        <Typography variant="titleAction">
                                            Staff Aggregate Scoring and Final Staff Score
                                        </Typography>
                                        {ActionMenu}
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={10}>
                                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                                            <ExpansionPanelSummary
                                                className={classes.expansionSummaryRoot}
                                                expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant="h3" style={{ width: "100%" }}>
                                                    <QuestionAnswerIcon className={classes.expansionIcon} />
                                                    Scoring
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <CustomTabelCell>
                                                                Scoring Item
                                                            </CustomTabelCell>
                                                            <CustomTabelCell>
                                                                Staff 1 (
                                                                {obj[1] && obj[1].user
                                                                    ? obj[1].user.first_name + " " + obj[1].user.last_name
                                                                    : "Empty"}
                                                                )
                                                            </CustomTabelCell>
                                                            <CustomTabelCell>
                                                                Staff 2 (
                                                                {obj[2] && obj[2].user
                                                                    ? obj[2].user.first_name + " " + obj[2].user.last_name
                                                                    : "Empty"}
                                                                )
                                                            </CustomTabelCell>
                                                            <CustomTabelCell>
                                                                Staff 3 (
                                                                {obj[3] && obj[3].user
                                                                    ? obj[3].user.first_name + " " + obj[3].user.last_name
                                                                    : "Empty"}
                                                                )
                                                            </CustomTabelCell>
                                                            <CustomTabelCell>
                                                                Staff 4 (
                                                                {obj[4] && obj[4].user
                                                                    ? obj[4].user.first_name + " " + obj[4].user.last_name
                                                                    : "Empty"}
                                                                )
                                                            </CustomTabelCell>
                                                            <CustomTabelCell>
                                                                Staff 5 (
                                                                {obj[5] && obj[5].user
                                                                    ? obj[5].user.first_name + " " + obj[5].user.last_name
                                                                    : "Empty"}
                                                                )
                                                            </CustomTabelCell>
                                                            <CustomTabelCell>
                                                                Staff 6 (
                                                                {obj[6] && obj[6].user
                                                                    ? obj[6].user.first_name + " " + obj[6].user.last_name
                                                                    : "Empty"}
                                                                )
                                                            </CustomTabelCell>
                                                            <CustomTabelCell>Range</CustomTabelCell>
                                                            <CustomTabelCell>Median Score</CustomTabelCell>
                                                            <CustomTabelCell>Average Score</CustomTabelCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {categories.map(x => {
                                                            return (
                                                                <TableRow>
                                                                    <CustomTabelCell>{x.label}</CustomTabelCell>
                                                                    <CustomTabelCell style={{textAlign:"center"}}>
                                                                        {obj[1] && (obj[1][x.field] || obj[1][x.field] === 0)  ? obj[1][x.field] : "-"}
                                                                    </CustomTabelCell>
                                                                    <CustomTabelCell style={{textAlign:"center"}}>
                                                                        {obj[2] && (obj[2][x.field] || obj[2][x.field] === 0) ? obj[2][x.field] : "-"}
                                                                    </CustomTabelCell>
                                                                    <CustomTabelCell style={{textAlign:"center"}}>
                                                                        {obj[3] && (obj[3][x.field] || obj[3][x.field] === 0) ? obj[3][x.field] : "-"}
                                                                    </CustomTabelCell>
                                                                    <CustomTabelCell style={{textAlign:"center"}}>
                                                                        {obj[4] && (obj[4][x.field] || obj[4][x.field] === 0) ? obj[4][x.field] : "-"}
                                                                    </CustomTabelCell>
                                                                    <CustomTabelCell style={{textAlign:"center"}}>
                                                                        {obj[5] && (obj[5][x.field] || obj[5][x.field] === 0) ? obj[5][x.field] : "-"}
                                                                    </CustomTabelCell>
                                                                    <CustomTabelCell style={{textAlign:"center"}}>
                                                                        {obj[6] && (obj[6][x.field] || obj[6][x.field] === 0) ? obj[6][x.field] : "-"}
                                                                    </CustomTabelCell>
                                                                    <CustomTabelCell style={{textAlign:"center"}}>
                                                                        <Typography className={classes.total}>
                                                                            {this.range(x.field, obj)}
                                                                        </Typography>
                                                                    </CustomTabelCell>
                                                                    <CustomTabelCell style={{textAlign:"center"}}>
                                                                        <Typography className={classes.total}>
                                                                            {this.median(x.field, obj)}
                                                                        </Typography>
                                                                    </CustomTabelCell>
                                                                    <CustomTabelCell style={{textAlign:"center"}}>
                                                                        <Typography className={classes.total}>
                                                                            {this.avg(x.field, obj)}
                                                                        </Typography>
                                                                    </CustomTabelCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                        <TableRow>
                                                            <CustomTabelCell>Calculated Total Score</CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[1] && (obj[1]["total"] || obj[1]["total"] === 0) ? obj[1]["total"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[2] && (obj[2]["total"] || obj[2]["total"] === 0) ? obj[2]["total"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[3] && (obj[3]["total"] || obj[3]["total"] === 0) ? obj[3]["total"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[4] && (obj[4]["total"] || obj[4]["total"] === 0) ? obj[4]["total"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[5] && (obj[5]["total"] || obj[5]["total"] === 0) ? obj[5]["total"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[6] && (obj[6]["total"] || obj[6]["total"] === 0) ? obj[6]["total"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                <Typography className={classes.total}>
                                                                    {this.sumRange(obj)}
                                                                </Typography>
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                <Typography className={classes.total}>
                                                                    {this.sumMedian(obj)}
                                                                </Typography>
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                <Typography className={classes.total}>
                                                                    {this.sumAverage(obj)}
                                                                </Typography>
                                                            </CustomTabelCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <CustomTabelCell>Staff Entered Score</CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[1] && (obj[1]["combined"] || obj[1]["combined"] === 0) ? obj[1]["combined"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[2] && (obj[2]["combined"] || obj[2]["combined"] === 0) ? obj[2]["combined"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[3] && (obj[3]["combined"] || obj[3]["combined"] === 0) ? obj[3]["combined"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[4] && (obj[4]["combined"] || obj[4]["combined"] === 0) ? obj[4]["combined"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[5] && (obj[5]["combined"] || obj[5]["combined"] === 0) ? obj[5]["combined"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                {obj[6] && (obj[6]["combined"] || obj[6]["combined"] === 0) ? obj[6]["combined"] : "-"}
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                <Typography className={classes.total}>
                                                                    {this.range("combined", obj)}
                                                                </Typography>
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                <Typography className={classes.total}>
                                                                    {this.median("combined", obj)}
                                                                </Typography>
                                                            </CustomTabelCell>
                                                            <CustomTabelCell style={{textAlign:"center"}}>
                                                                <Typography className={classes.total}>
                                                                    {this.avg("combined", obj)}
                                                                </Typography>
                                                            </CustomTabelCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={10}>
                                        <TextField
                                            disabled={settings.staff_eval_open}
                                            field="final_score"
                                            label="Final Score"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={10} className={classes.rightAlign}>
                                        <Button
                                            disabled={settings.staff_eval_open}
                                            variant="contained"
                                            type="submit"
                                            style={{ float: "left" }}
                                            className={classes.button}>
                                            Save
                                        </Button>
                                        <Button
                                            component={Link}
                                            to="/adm/proposal/process"
                                            variant="contained"
                                            style={{ float: "left" }}
                                            className={classes.button}>
                                            Return to Proposal Process Page
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                )}
                <Snackbar
                    handleSnackbarClose={() => this.setState({ error: false })}
                    snackbarOpen={this.state.error}
                    message={this.state.message}
                />
            </>
        );
    }
}

Aggregate_Score = connect(
    (state, ownProps) => ({
        authState: state.auth,
        allStaff: getStaff(state, ownProps),
        scores: getStaffScore(state, ownProps),
        review: getReview(state, ownProps)
    }),
    {
        ...ProposalReview.actions
    }
)(Aggregate_Score);

export default withStyles(styles)(withRouter(Aggregate_Score));
