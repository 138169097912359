import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import * as authActions from "../auth/actions";
import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from "../common/TextField";
import logo from "../../images/logo.jpg";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

const styles = {
    marginBottom: {
        marginBottom: 40
    },
    leftDivide: {
        paddingRight: 32,
        borderRight: "2px solid #666666"
    },
    rightDivide: {
        paddingLeft: 32
    },
    loginError: {
        color: "#be0000",
        textAlign: "center"
    },
    marginBottomButton: {
        marginBottom: 24
    },
    imageSide: {
        height: 150,
        cursor: "pointer",
        marginBottom: 40
    },
    forgot: {
        float: "right",
        marginTop: 8
    }
};

class HomePage extends Component {
    inputs = [];

    continueRegister = () => {
        this.props.history.push("/register");
    };

    componentDidMount() {
        document.title = "LCCMR Proposal and Grant Management System - Welcome/Login Page";
    }

    logOut = () => {
        this.props.authLogout();
    };

    continueDashboard = () => {
        this.props.history.push("/dashboard");
    };

    errorValidator = values => {
        const validateUsername = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid email address" : null;
        };
        const validatePassword = value => {
            return !value ? "Password is required." : null;
        };

        const objErrors = {
            email: validateUsername(values.email),
            password: validatePassword(values.password)
        };
        const entries = Object.entries(objErrors).reverse();
        for (const [k, v] of entries) {
            if (v && this.inputs[k]) this.inputs[k].focus();
        }
        return objErrors;
    };

    loginSubmit = (values, e, formApi) => {
        this.props.authLogin(values).then(() => {
            if (this.props.authState.error) {
                formApi.setValue("password", "");
            } else if (this.props.authState.user) {
                if (this.props.authState.user.redirect) {
                    this.props.history.push(this.props.authState.user.redirect.replace("#", "") + "?old=True");
                } else {
                    this.continueDashboard();
                }
            }
        });
    };

    render() {
        const { classes, authState } = this.props;
        if (!authState) return "...";

        return (
            <AppContainer>
                <Grid container spacing={40} justify="center"> 
                    <Grid item xs={11} md={8} lg={6} xl={4}>
                        <Typography className={classes.marginBottom}>
                            The LCCMR proposal and grants management system is designed to create and report on your
                            project from proposal to completion with ease.
                        </Typography>
                        <Grid container>
                            <Grid item xs={6} className={classes.leftDivide}>
                                <Typography variant="h2" gutterBottom>
                                    New Users
                                </Typography>
                                <Typography className={classes.marginBottom}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={this.continueRegister}>
                                        Register for an Account
                                    </Button>
                                </Typography>
                                {authState.user && (
                                    <div>
                                        <Typography variant="h2" gutterBottom>
                                            Welcome, {authState.user.first_name}
                                        </Typography>
                                        <Button
                                            onClick={this.continueDashboard}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            className={classes.marginBottomButton}>
                                            Continue to Dashboard
                                        </Button>
                                        <Button onClick={this.logOut} fullWidth>
                                            Logout
                                        </Button>
                                    </div>
                                )}
                                {!authState.user && (
                                    <Form
                                        dontValidateOnMount="true"
                                        validateOnSubmit="true"
                                        validateError={this.errorValidator}
                                        onSubmit={this.loginSubmit}>
                                        {formApi => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Typography variant="h2" gutterBottom autoFocus>
                                                    Existing Users
                                                </Typography>
                                                <TextField
                                                    field="email"
                                                    label="Email"
                                                    login={true}
                                                    inputRef={input => {
                                                        this.inputs["email"] = input;
                                                    }}
                                                    fullWidth
                                                />
                                                <TextField
                                                    field="password"
                                                    label="Password"
                                                    type="password"
                                                    login={true}
                                                    inputRef={input => {
                                                        this.inputs["password"] = input;
                                                    }}
                                                    fullWidth
                                                />
                                                <Typography className={classes.loginError} gutterBottom>
                                                    {authState.error}
                                                </Typography>
                                                <Submit label="Login" gridSize={24} loading={authState.pending} />
                                                <Button
                                                    component={Link}
                                                    to="/password/reset"
                                                    className={classes.forgot}>
                                                    Forgot your password?
                                                </Button>
                                            </form>
                                        )}
                                    </Form>
                                )}
                            </Grid>
                            <Grid item xs={6} className={classes.rightDivide}  style={{marginBottom:80}}>
                                <img
                                    onClick={() =>
                                        window.open(
                                            "https://www.legacy.mn.gov/environment-natural-resources-trust-fund"
                                        )
                                    }
                                    className={classes.imageSide}
                                    alt="Environment and Natural Resources Trust Fund Logo"
                                    src={logo}
                                />
                                <Typography variant="h2" gutterBottom>
                                    Need Help?
                                </Typography>
                                <Typography className={classes.marginBottom}>
                                    Please contact us at
                                    <br />
                                    <a href="mailto:lccmr@lccmr.mn.gov" style={{ "text-decoration": "underline" }}>
                                        {" "}
                                        lccmr@lccmr.mn.gov{" "}
                                    </a>
                                    <br />
                                    651-296-2406
                                </Typography>
                                <Typography variant="h2" gutterBottom>
                                    Accessibility Statement
                                </Typography>
                                <Typography gutterBottom>
                                    LCCMR is committed to ensuring digital accessibility for people with disabilities.
                                    We are continually improving the user experience for everyone, and applying the
                                    relevant accessibility standards. Please let us know if you encounter accessibility
                                    barriers on LCCMR's Grant and Project Management System.
                                </Typography>
                                <Typography>
                                    The system supports the latest versions of Google Chrome, Mozilla Firefox, Apple
                                    Safari, or Microsoft Edge. It is best viewed on a desktop computer.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

HomePage = connect(
    state => ({
        authState: state.auth
    }),
    {
        ...authActions
    }
)(HomePage);

export default withStyles(styles)(withRouter(HomePage));
