import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";

import TextField from "../../common/TextField";
import HelpLabel from "../../common/HelpLabel";
import Select from "../../common/Select";
import states from "../../common/states.json";
import { WorkPlanTab } from "../../home/models";
import { ManagerRequest } from "../models";

const getTab = WorkPlanTab.selectMessage(2);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    link: {
        cursor: "pointer"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    }
});

class Collaborators_ProjectManagerTab extends Component {
    state = {
        formkey: null,
        OrgDialogOpen: false,
    };

    componentDidMount() {
        const { handleUnsavedFields, ormParentUpdateLocalOnly, plan } = this.props;
        document.title = "Work Plan: Project Manager Info - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!plan.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormParentUpdateLocalOnly({
                    id: plan.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return email && !re.test(email) ? "Invalid email address" : null;
        };
        var valObj = {
            pm_email: validateEmail(values.pm_email)
        };
        return valObj;
    };

    updatePlan(values, fromStepper) {
        const { id } = this.props.proposal;
        const {
            ormParentUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            plan,
            ormParentUpdateLocalOnly,
            is_read_only
        } = this.props;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (is_read_only) {
                ormParentUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = plan.map;
        values.financial_capacity = plan.financial_capacity;
        values.modify_date_label = plan.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormParentUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                handlePrev();
            } else {
                handleNext();
            }
        }
    }

    applyOther = (value, element) => {
        const { ormParentUpdateLocalOnly } = this.props;
        const { id } = this.props.plan;
        ormParentUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    render() {
        const { classes, plan, is_read_only, handleUnsavedFields, ActionMenu, tab, proposal, ormManagerRequestCreate, authState, manager_requests, ormParentUpdateLocalOnly } = this.props;
        const { OrgDialogOpen, formkey, } = this.state;

        var is_dnr = false;
        if (authState && authState.user.is_read_only) is_dnr = true;
        var is_member = false;
        if (authState && authState.user.role === "Member") is_member = true;

        return (
            <>
            <Form
                getApi={el => (this.form = el)}
                key={plan.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                validateError={values => this.errorValidator(values)}
                onSubmit={(values, fromStepper) => this.updatePlan(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16} style={{ marginTop: 0 }}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <HelpLabel
                                    inputLabel="Project Manager"
                                    title={true}
                                    style={{ display: "inline-block", marginTop: 8 }}
                                    showLabel={true}
                                    htmlText={true}
                                    helpText={
                                        tab.sub_tabs
                                            .find(tht => tht.tab_name === "Project Manager Info")
                                            .tab_help_texts.find(tht => tht.label === "Project Manager").text
                                    }
                                />
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Grid container spacing={16}>
                                    <Grid item xs={6}>
                                        <TextField title={plan.status && "Project manager can only be edited via request to change project manager"} disabled={is_read_only || plan.status} field="pm_first_name" fullWidth label="First Name"checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} />
                                        {!is_dnr && !is_member && proposal.status !== "Project Completed" && manager_requests.length === 0 && (
                                            <Link
                                                onClick={() =>
                                                    this.setState({
                                                        OrgDialogOpen: true,
                                                        formkey: window.performance.now()
                                                    })
                                                }
                                                className={classes.link}>
                                                Do you need to change project manager? If so, click here
                                            </Link>
                                        )}
                                        {manager_requests.length > 0 && (
                                            <Typography variant="caption" style={{color:"#d32f2f"}}>A project manager request has already been initiated. Please wait for LCCMR Staff to review. You may also withdraw your previous request using the select action menu.</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField title={plan.status && "Project manager can only be edited via request to change project manager"} checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} disabled={is_read_only || plan.status} field="pm_last_name" fullWidth label="Last Name" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={9}>
                                                <TextField disabled={is_read_only} field="pm_office_phone" fullWidth phoneNumber label="Office Phone" checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField disabled={is_read_only} field="pm_office_ext" fullWidth label="Extension" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField disabled={is_read_only} field="pm_mobile_phone" fullWidth phoneNumber label="Mobile Telephone" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField disabled={is_read_only} field="pm_email" fullWidth label="Email" checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField disabled={is_read_only} field="pm_mailing_address" fullWidth label="Mailing Address" checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField disabled={is_read_only} field="pm_address_2" fullWidth label="Address 2" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField disabled={is_read_only} field="pm_city" fullWidth label="City" checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select disabled={is_read_only} field="pm_state" label="State" options={states} fullWidth autoComplete checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField disabled={is_read_only} field="pm_zip" fullWidth label="Zip Code" checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <HelpLabel
                                    inputLabel="Project Manager Qualification"
                                    title={true}
                                    style={{ display: "inline-block", marginTop: 8 }}
                                    showLabel={true}
                                    htmlText={true}
                                    helpText={
                                        tab.sub_tabs
                                            .find(tht => tht.tab_name === "Project Manager Info")
                                            .tab_help_texts.find(tht => tht.label === "Project Manager Qualification").text
                                    }
                                />
                                <Grid container spacing={16}>
                                    <Grid item xs={6}>
                                        <TextField disabled={is_read_only} field="pm_job_title" label="Job Title" fullWidth checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ float: "left" }}
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                    className={classes.button}>
                                    Save and Return to Previous Step
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                    Save and Proceed to Next Step
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
            <ManagerDialog
                open={OrgDialogOpen}
                proposal={proposal}
                help_text={tab.sub_tabs.find(tht => tht.tab_name === "Project Manager Info").tab_help_texts.find(tht => tht.label === "Change Project Manager").text}
                ormManagerRequestCreate={ormManagerRequestCreate}
                returnDialog={() => this.setState({ OrgDialogOpen: false })}
                classes={classes}
                formkey={formkey}
            />
            </>
        );
    }
}

class ManagerDialog extends Component {
    state = {
        OrgThanksDialogOpen: false
    };

    saveRequest(values) {
        const { ormManagerRequestCreate, returnDialog, proposal } = this.props;
        const { setError } = this.formApi;
        var is_amendment = false;
        if (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") {
            is_amendment = true;
        }
        ormManagerRequestCreate({
            proposal_id: proposal.id,
            status: "New",
            is_amendment: is_amendment,
            ...values
        }).then(response => {
            if (response.error) {
                setError("email", "User with this email does not exist in the system.")
            } else {
                returnDialog();
                this.setState({ OrgThanksDialogOpen: true });
            }
        });
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        var valObj = {
            first_name: validateRequired(values.first_name),
            last_name: validateRequired(values.last_name),
            email: validateRequired(values.email),
            why: validateRequired(values.why),
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, formkey, help_text } = this.props;
        const { OrgThanksDialogOpen } = this.state;


        return (
            <>
                <Dialog role="main" aria-label="Request to Change Project Manager" open={open}>
                    <Toolbar>
                        <Typography variant="h1" className={classes.flex}>
                            Request to Change Project Manager
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                key={formkey}
                                validateOnSubmit={true}
                                getApi={el => (this.formApi = el)}
                                validateError={this.errorValidator}
                                onSubmit={values => this.saveRequest(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <div class="insborder">
                                                    <Typography>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: help_text
                                                            }}
                                                        />
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField label="First Name" field="first_name" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField label="Last Name" field="last_name" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField label="Email" field="email" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField multiline label="Why is the change needed?" field="why" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => returnDialog()}>
                                                    Cancel Request
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save and Submit Request
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog role="main" aria-label="Thank You for Your Submission" open={OrgThanksDialogOpen}>
                    <DialogTitle>Thank You for Your Submission</DialogTitle>
                    <DialogContent>
                        <Typography>
                            An email has been sent to LCCMR staff for review.  You and the new project manager will be notified by email when the project manager change has been approved.   
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                window.location.reload();
                            }}
                            color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}



Collaborators_ProjectManagerTab = connect(
    (state, ownProps) => ({
        authState: state.auth,
        tab: getTab(state, ownProps)
    }),
    {
        ...ManagerRequest.actions
    }
)(Collaborators_ProjectManagerTab);

export default withStyles(styles)(withRouter(Collaborators_ProjectManagerTab));
