import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";

export class Proposal extends Model {
    static get fields() {
        return {
            organization: fk("Organization", "proposal_organization"),
            fiscal_agent: fk("FiscalAgent", "proposal_fiscal_agent")
        };
    }
    /*static get user_only() {
        return true;
    }*/
    static get modelName() {
        return "Proposal";
    }
}
orm.register(Proposal);

export class ProposalView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "ProposalView";
    }
}
orm.register(ProposalView);

export class HistoricalProposal extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "HistoricalProposal";
    }
}
orm.register(HistoricalProposal);

export class ProposalPastProject extends Model {
    static get fields() {
        return {
            applied_proposal: fk("Proposal", "past_project"),
            original_proposal: fk("HistoricalProposal", "past_project2")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalPastProject";
    }
}
orm.register(ProposalPastProject);

export class ProposalCollaborator extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "collaborator_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalCollaborator";
    }
}
orm.register(ProposalCollaborator);

export class ProposalActivity extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "activity_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalActivity";
    }
}
orm.register(ProposalActivity);

export class ProposalMilestone extends Model {
    static get fields() {
        return {
            activity: fk("ProposalActivity", "milestone_activities")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalMilestone";
    }
}
orm.register(ProposalMilestone);

export class ProposalPersonnel extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "personnel_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalPersonnel";
    }
}
orm.register(ProposalPersonnel);

export class ProposalContract extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "contract_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalContract";
    }
}
orm.register(ProposalContract);

export class ProposalExpenditure extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "expenditure_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalExpenditure";
    }
}
orm.register(ProposalExpenditure);

export class ProposalAcquisition extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "acquisition_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalAcquisition";
    }
}
orm.register(ProposalAcquisition);

export class ProposalTravel extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "travel_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalTravel";
    }
}
orm.register(ProposalTravel);

export class ProposalPrinting extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "printing_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalPrinting";
    }
}
orm.register(ProposalPrinting);

export class ProposalExpense extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "expense_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalExpense";
    }
}
orm.register(ProposalExpense);

export class ProposalNonENRTFFund extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "nonenrtffund_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalNonENRTFFund";
    }
}
orm.register(ProposalNonENRTFFund);

export class ProposalAttachment extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "attachment_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalAttachment";
    }
}
orm.register(ProposalAttachment);

export class ProposalParcel extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "parcel_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalParcel";
    }
}
orm.register(ProposalParcel);

export class ProposalDelegate extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "delegate_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "ProposalDelegate";
    }
}
orm.register(ProposalDelegate);

export class StatusLog extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "log_proposal")
        };
    }
    static get modelName() {
        return "StatusLog";
    }
    static get dontLoadByDefault() {
        return true;
    }
}
orm.register(StatusLog);

export class StaffComment extends Model {
    static get fields() {
        return {
            proposal: fk("Proposal", "comment_proposal"),
            user: fk("User", "comment_user")
        };
    }
    static get modelName() {
        return "StaffComment";
    }
    static get dontLoadByDefault() {
        return true;
    }
}
orm.register(StaffComment);

export class MemberEvaluationComplete extends Model {
    static get fields() {
        return {
            year: fk("SystemSetting", "setting_evalcomp"),
            user: fk("User", "user_evalcomp")
        };
    }
    static get member_or_staff() {
        return true;
    }
    static get modelName() {
        return "MemberEvaluationComplete";
    }
}
orm.register(MemberEvaluationComplete);

export class ProposalCollaboratorView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "ProposalCollaboratorView";
    }
}
orm.register(ProposalCollaboratorView);

export class ProposalActivityView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "ProposalActivityView";
    }
}
orm.register(ProposalActivityView);

export class ProposalMilestoneView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "ProposalMilestoneView";
    }
}
orm.register(ProposalMilestoneView);
