import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles, Typography } from "@material-ui/core";
import { WorkPlanTab, StatusUpdateTab } from "../../home/models";

const getTab = WorkPlanTab.selectMessage(1);
const getStatusTab = StatusUpdateTab.selectMessage(1);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    marginTop: {
        marginTop: "1.4em"
    }
});

class IntroductionTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Work Plan: Introduction - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (_this.props.appContainer.current) {
                _this.props.appContainer.current.scrollTop();
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    render() {
        const { classes, is_read_only, history, handleNext, ActionMenu, tab, last_step, ormParentUpdateLocalOnly, show_parcel, status_tab, proposal } = this.props;
        const { id } = this.props.plan;

        var instructions = tab.tab_help_texts.find(tht => tht.label === "Introduction").text;
        if (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed")
            instructions = status_tab.tab_help_texts.find(tht => tht.label === "Instructions:").text;
        else if (proposal.status === "Project Completed")
            instructions = status_tab.tab_help_texts.find(tht => tht.label === "Completed Project Instructions").text;

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography variant="titleAction">Introduction</Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography gutterBottom>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: instructions
                            }}
                        />
                    </Typography>
                </Grid>
                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && (
                    <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                ormParentUpdateLocalOnly({
                                    id: id,
                                    activeStep: last_step,
                                    activeSubStep: 0
                                });
                            }}
                            className={classes.button}>
                            Jump to Written Status Report
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                ormParentUpdateLocalOnly({
                                    id: id,
                                    activeStep: 5,
                                    activeSubStep: 8
                                });
                            }}
                            className={classes.button}>
                            Jump to Spending Status Report
                        </Button>
                        {!show_parcel && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    ormParentUpdateLocalOnly({
                                        id: id,
                                        activeStep: 7,
                                        activeSubStep: 0
                                    });
                                }}
                                className={classes.button}>
                                Jump to Acquisition or Restoration Status Report
                            </Button>
                        )}
                    </Grid>
                )}
                <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                    <Button variant="contained" type="submit" disabled={is_read_only} onClick={() => history.push("/dashboard/")} className={classes.button}>
                        Return to Dashboard
                    </Button>
                    <Button disabled={is_read_only} variant="contained" type="submit" color="primary" onClick={() => handleNext()}>
                        Proceed to Next Step
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

IntroductionTab = connect(
    (state, ownProps) => ({
        tab: getTab(state, ownProps),
        status_tab: getStatusTab(state, ownProps),
        authState: state.auth
    }),
    null
)(IntroductionTab);

export default withStyles(styles)(withRouter(IntroductionTab));
