import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DownloadIcon from "@material-ui/icons/GetApp";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import { DropzoneArea } from 'material-ui-dropzone';
import Select from "../common/Select";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import PageHeader from "../common/PageHeader";
import TablePaginationActionsWrapped from "../common/Paginator";
import { propStatus, draftStatus, finalStatus } from "../../api/constants";

import { ProposalView, StatusLog } from "../proposal/models";
import { createSelector } from "../common/orm";

const getProposal = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    (state, ownProps) => (state.auth.user ? state.auth.user.is_read_only ? "Staff" : state.auth.user.role : null),
    (schema, id, auth) => {
        if (auth && auth === "User") {
            return schema.Proposal.filter(x => x.id === parseInt(id)).first();
        } else {
            return schema.ProposalView.filter(x => x.id === parseInt(id)).first();
        }
    }
);

const getStatuses = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (schema, id) => {
        return schema.StatusLog.filter(x => x.proposal === id)
            .orderBy("date", "asc")
            .toRefArray();
    }
);

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        backgroundColor: "#fff"
    },
    centerAlign: {
        textAlign: "center"
    },
    rightAlign: {
        textAlign: "right"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    button: {
        backgroundColor:"#ffcccc",
        "&:hover": {
            backgroundColor: "#ff8080 !important"
        }
    }
});

class StaffFeeBack extends Component {
    state = {
        loaded: false,
        page: 0,
        rowsPerPage: 100,
        staff_filter: "All",
        dialogOpen: false,
        record_id: null,
        key: null
    };
    componentDidMount() {
        document.title = "Activity Log - LCCMR Proposal and Grant Management System";
        const { authState, match, ormStatusLogLoadDetailChildFilterMany } = this.props;

        if (authState && authState.user) {
            this.setState({ loaded: true });
            ormStatusLogLoadDetailChildFilterMany({ proposal: match.params.id });
        }
    }
    componentDidUpdate() {
        const { match, ormStatusLogLoadDetailChildFilterMany } = this.props;

        if (!this.state.loaded) {
            this.setState({ loaded: true });
            ormStatusLogLoadDetailChildFilterMany({ proposal: match.params.id });
        }
    }
    handleChangePage = (event, page) => {
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    handleChange = e => {
        let value = e[0];
        if (!value) return;

        this.getBase64(value).then(data => {
            if (this.selectApi) {
                this.selectApi.setValue("saved_file", data);
            }
        });
    };

    staffRecord = values => {
        const { ormStatusLogCreate, ormStatusLogUpdate, proposal, authState } = this.props;
        const { record_id } = this.state;
        if (!record_id) {
            ormStatusLogCreate({
                proposal_id: proposal.id,
                user_id: authState.user.id,
                type: "Staff",
                ad_hoc: true,
                ...values
            });
        } else {
            ormStatusLogUpdate({
                ...values
            });
        }
        this.setState({ dialogOpen: false, record_id: null });
    };

    render() {
        const { classes, history, statuses, proposal, authState, ormStatusLogDelete } = this.props;
        const { rowsPerPage, page, staff_filter, dialogOpen, record_id, key } = this.state;

        if (!proposal) return "";

        var goBack;
        if (propStatus.includes(proposal.status))
            goBack = "/proposal/"
        else if (draftStatus.includes(proposal.status))
            goBack = "/draft/workplan/"
        else if (finalStatus.includes(proposal.status))
            goBack = "/final/workplan/"
        

        var is_admin = false;
        if (authState && authState.user.role === "Staff") is_admin = true;

        var columnData = [];
        if (is_admin) {
            columnData = [
                { id: "actions", numeric: false, label: "Actions" },
                { id: "activity_id", numeric: false, label: "Activity ID" },
                { id: "project_phase", numeric: false, label: "Project Phase" },
                { id: "status", numeric: false, label: "Activity" },
                { id: "date", numeric: false, label: "Date" },
                { id: "user", numeric: false, label: "User" },
                { id: "saved_file", numeric: false, label: "Saved File" },
                { id: "notes", numeric: false, label: "Notes" }
            ];
        } else {
            columnData = [
                { id: "activity_id", numeric: false, label: "Activity ID" },
                { id: "project_phase", numeric: false, label: "Project Phase" },
                { id: "status", numeric: false, label: "Activity" },
                { id: "date", numeric: false, label: "Date" },
                { id: "saved_file", numeric: false, label: "Saved File" }
            ];
        }

        statuses.forEach((n, i) => (n.activity_id = i + 1));

        var filtered_statuses = statuses;
        if (staff_filter === "Staff Added") {
            filtered_statuses = statuses.filter(n => n.ad_hoc);
        } else if (staff_filter === "Proposal") {
            filtered_statuses = statuses.filter(
                n => n.project_phase === "Proposal" || n.project_phase === "Proposal Evaluation"
            );
        } else if (staff_filter === "Work Plan") {
            filtered_statuses = statuses.filter(
                n => n.project_phase === "Work Plan in Development"
            );
        }  else if (staff_filter === "Active Project" || staff_filter === "Completed Project") {
            filtered_statuses = statuses.filter(n => n.project_phase === staff_filter);
        } else if (staff_filter === "Saved Records") {
            filtered_statuses = statuses.filter(n => n.saved_file_link !== "-");
        }

        var dialogDefaultValues = record_id;
        if (!dialogDefaultValues && authState) {
            dialogDefaultValues = {};
            dialogDefaultValues["user_label"] = authState.user.first_name + " " + authState.user.last_name;
            dialogDefaultValues["ad_hoc"] = true;
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title={`Work Plan ${proposal.proposal_unique_id}: ${proposal.name}`} />
                    {is_admin && (
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Search:</FormLabel>
                                <RadioGroup
                                    aria-label="staff_filter"
                                    name="staff_filter"
                                    value={staff_filter}
                                    onChange={e => this.setState({ staff_filter: e.target.value })}>
                                    <FormControlLabel value="All" control={<Radio color="primary" />} label="All" />
                                    <FormControlLabel value="Staff Added" control={<Radio color="primary" />} label="Staff Added" />
                                    <FormControlLabel value="Proposal" control={<Radio color="primary" />} label="Proposal" />
                                    <FormControlLabel value="Work Plan" control={<Radio color="primary" />} label="Work Plan" />
                                    <FormControlLabel value="Active Project" control={<Radio color="primary" />} label="Active Project" />
                                    <FormControlLabel value="Completed Project" control={<Radio color="primary" />} label="Completed Project" />
                                    <FormControlLabel value="Saved Records" control={<Radio color="primary" />} label="Saved Records" />
                                </RadioGroup>
                            </FormControl>
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ width: 250 }}
                                    onClick={() =>
                                        this.setState({
                                            dialogOpen: true,
                                            record_id: null,
                                            key: window.performance.now()
                                        })
                                    }>
                                    <AddCircleIcon />
                                    &nbsp;&nbsp;&nbsp;Add Staff Record
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ width: 250, marginLeft: 24 }}
                                    onClick={() => window.open("/adm/activity_log/" + proposal.id + "/download")}>
                                    <DownloadIcon />
                                    &nbsp;&nbsp;&nbsp;Download Activity Log
                                </Button>
                            </span>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Table className={classes.table}>
                            <EnhancedTableHead stickyHeader columnData={columnData} />
                            <TableBody>
                                {filtered_statuses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                    if ((is_admin && n.ad_hoc) || !n.ad_hoc) {
                                        return (
                                            <TableRow key={n.id + n.suffix}>
                                                {is_admin && (
                                                    <CustomTableCell>
                                                        <Tooltip title="Edit Record">
                                                            <Button
                                                                color="primary"
                                                                aria-label="Edit Record"
                                                                className={classes.deleteWidth}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        dialogOpen: true,
                                                                        record_id: n,
                                                                        key: window.performance.now()
                                                                    })
                                                                }>
                                                                <EditIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                    </CustomTableCell>
                                                )}
                                                <CustomTableCell>{n.activity_id}</CustomTableCell>
                                                <CustomTableCell>{n.project_phase}</CustomTableCell>
                                                <CustomTableCell>{n.status}</CustomTableCell>
                                                <CustomTableCell>{n.date_label}</CustomTableCell>
                                                {is_admin && <CustomTableCell>{n.user_label}</CustomTableCell>}
                                                <CustomTableCell>
                                                    {((is_admin && n.ad_hoc) || !n.ad_hoc) && (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: n.saved_file_link
                                                            }}
                                                        />
                                                    )}
                                                </CustomTableCell>
                                                {is_admin && <CustomTableCell>{n.notes}</CustomTableCell>}
                                            </TableRow>
                                        );
                                    }
                                    return null;
                                })}
                                {filtered_statuses.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                            <caption style={{display:"inline"}}>No Statuses Found</caption>
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            {filtered_statuses.length > 25 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={columnData.length}
                                            count={filtered_statuses.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </Grid>
                    <Grid item xs={12} className={classes.rightAlign}>
                        <Button style={{ float: "left" }} variant="contained" onClick={() => history.push("/dashboard/")}>
                            Return to Dashboard
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => history.push(goBack + proposal.id)}>
                            Return to Project
                        </Button>
                    </Grid>
                </Grid>
                <Dialog role="main" aria-label={!record_id ? "Add Staff Record" : "Edit Staff Record"} open={dialogOpen}>
                    <Toolbar>
                        <Typography variant="h1" className={classes.flex}>
                            {!record_id ? "Add" : "Edit"} Staff Record
                        </Typography>
                        <IconButton
                            aria-label="Close Dialog"
                            onClick={() => {
                                this.setState({ dialogOpen: false });
                            }}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                defaultValues={dialogDefaultValues}
                                key={key}
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                getApi={el => (this.selectApi = el)}
                                onSubmit={values => this.staffRecord(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={16}>
                                            {dialogDefaultValues.ad_hoc && (
                                                <>
                                                <TextField fullWidth disabled label="Staff" field="user_label" multiline />
                                                <Select
                                                    field="status"
                                                    label="Type"
                                                    options={[
                                                        { label: "Telephone Call", value: "Telephone Call" },
                                                        { label: "Email", value: "Email" },
                                                        { label: "Meeting", value: "Meeting" },
                                                        { label: "Document", value: "Document" },
                                                        { label: "Image", value: "Image" }
                                                    ]}
                                                    fullWidth
                                                />
                                                <Typography style={{marginTop:16}}>Upload Email or File</Typography>
                                                <DropzoneArea
                                                    acceptedFiles={['image/*', 'application/*', '']}
                                                    dropzoneText="Drag and drop file or click to select file"
                                                    showAlerts={false}
                                                    maxFileSize={20000000}
                                                    filesLimit={1}
                                                    onChange={(files) => {this.handleChange(files)}}
                                                />
                                                {/*
                                                
                                                    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
                                                    'application/vnd.ms-outlook', 'application/octet-stream', 
                                                    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', '.msg',
                                                    'application/pdf'
                                                <FileInput field="saved_file" id="saved_file" fullWidth label="Upload Email or File" /> */}
                                                <TextField fullWidth label="File Name (Optional)" field="file_name" />
                                                </>
                                            )}
                                            <TextField fullWidth label="Notes" field="notes" multiline />
                                            <Grid item xs={4}>
                                                <Button
                                                    fullWidth
                                                    onClick={() => {
                                                        this.setState({ dialogOpen: false });
                                                    }}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            {record_id && dialogDefaultValues.ad_hoc && (
                                                <Grid item xs={4}>
                                                    <Button
                                                        className={classes.button}
                                                        fullWidth
                                                        onClick={() => {
                                                            if (window.confirm("Are you sure you wish to delete this staff record?")) {
                                                                ormStatusLogDelete(record_id.id);
                                                                this.setState({ dialogOpen: false });
                                                            }
                                                        }}>
                                                        Delete
                                                    </Button>
                                                </Grid>
                                            )}
                                            <Grid item xs={4}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainer>
        );
    }
}

StaffFeeBack = connect(
    (state, ownProps) => ({
        authState: state.auth,
        proposal: getProposal(state, ownProps),
        statuses: getStatuses(state, ownProps)
    }),
    {
        ...StatusLog.actions,
        ...ProposalView.actions
    }
)(StaffFeeBack);

export default withStyles(styles)(withRouter(StaffFeeBack));
