import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import TextField from "../../common/TextField";
import CustomTableCell from "../../common/TableCell";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../../common/Paginator";
import { createSelector } from "../../common/orm";
import { WorkPlanTab } from "../../home/models";

const getComments = createSelector(
    (state, ownProps) => parseInt(ownProps.proposal.id),
    (schema, proposal) => {
        var wpc = schema.WorkPlanComment.filter(x => x.proposal_id === proposal)
            .orderBy("id")
            .toModelArray()
            .map(x => {
                return {
                    user_: x.user,
                    uid: x.ref.user,
                    ...x.ref
                };
            });
            wpc.forEach((w, i) => w.commentid = i + 1);
            wpc = wpc.filter(x => x.comment_for === "Applicant" && !x.resolved);
            return wpc;
    }
);

const getTab = WorkPlanTab.selectMessage(28);
const getReview = createSelector(
    (state, ownProps) => parseInt(ownProps.proposal.id),
    (schema, proposal) => {
        return schema.WorkPlanReview.filter(x => x.proposal_id === proposal).first();
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    marginTop: {
        marginTop: "1.4em"
    },
    box: {
        "& textarea": {
            color: "black"
        }
    },
    centerAlign: {
        textAlign: "center"
    },
    asterisk: {
        whiteSpace: "normal",
        lineHeight: 1.5,
        color: "#212529 !important",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif"
    }
});

const columnData = [
    { id: "id", numeric: false, label: "Comment ID" },
    { id: "date", numeric: false, date: true, label: "Date Created" },
    { id: "step", numeric: false, label: "Page" },
    { id: "comment", numeric: false, label: "Revision Requested or Comment" }
];

class Final_Comments extends Component {
    state = {
        page: 0,
        rowsPerPage: 25,
        order: "desc",
        orderBy: "",
        isDraft: this.props.match.path === "/draft/workplan/:id"
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Comments and Revisions - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (_this.props.appContainer.current) {
                _this.props.appContainer.current.scrollTop();
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props.comments.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc") return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    formatDate = date => {
        const d = new Date(date.replace(/-/g, "/") + " 12:00:00");
        const dateFns = new DateFnsUtils();
        return dateFns.format(d, "MM/dd/yyyy");
    };

    render() {
        const { authState, plan, classes, history, comments, review, getSteps, handlePrev, handleNext, proposal, is_read_only, ActionMenu, tab } = this.props;
        const { isDraft, order, orderBy, page, rowsPerPage } = this.state;

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography variant="titleAction">
                        Comments and Revisions
                        {isDraft && (
                            <span className={classes.asterisk}>
                                <b>&thinsp;(new)</b>
                            </span>
                        )}
                    </Typography>
                    {ActionMenu}
                </Grid>
                {plan && plan.next_status_update_date && comments.length > 1 && (
                    plan.reporting_status === "Update Due" ||
                    plan.reporting_status === "Final Update Due" ||
                    plan.reporting_status === "Additional Update In Progress" ||
                    plan.reporting_status === "Additional Update Revisions Needed" ||
                    plan.reporting_status === "Update Revisions Needed" ||
                    plan.reporting_status === "Final Update Revisions Needed" ||
                    plan.amendment_status === "Amendment In Progress" ||
                    plan.amendment_status === "Amendment Revisions Needed" ||
                    proposal.status === "Final Abstract Due" ||
                    proposal.status === "Final Abstract Revisions Needed" ||
                    proposal.status === "Draft Work Plan Due" ||
                    proposal.status === "Draft Work Plan Revisions Needed" ||
                    proposal.status === "Final Work Plan Due" ||
                    proposal.status === "Final Work Plan Revisions Needed"
                ) && (
                    <>
                        <Grid item xs={12} lg={10} xl={7}>
                            <div class="insborder">
                                <Typography>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: tab.tab_help_texts.find(tht => tht.label === "Instructions").text
                                        }}
                                    />
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={10} xl={7}>
                            <Typography>Submit Revisions By Date: <b>{plan.next_status_update_date}</b></Typography>
                        </Grid>
                    </>
                )}
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography>Staff Lead for this Work Plan: {proposal.staff_lead_label}</Typography>
                </Grid>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.lccmr.mn.gov/about/staff-index.html"
                            style={{ "text-decoration": "underline" }}>
                            Contact LCCMR Staff
                        </a>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {authState && authState.user && authState.user.id === proposal.user_id && (
                        <>
                            <Form defaultValues={review}>
                                {formApi => (
                                    <form>
                                        <TextField
                                            className={classes.box}
                                            label="LCCMR Staff Summary Comments"
                                            field={"external_pm"}
                                            multiline
                                            fullWidth
                                            disabled
                                        />
                                    </form>
                                )}
                            </Form>
                            <Form defaultValues={proposal}>
                                {formApi => (
                                    <form>
                                        <TextField className={classes.box} label="Contingencies" field="contingencies" multiline fullWidth disabled />
                                    </form>
                                )}
                            </Form>
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="titleAction">Table of Comments</Typography>
                    <Table className={classes.table}>
                        <EnhancedTableHead stickyHeader columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                        <TableBody>
                            {comments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, index) => {
                                return (
                                    <TableRow key={n.id + n.suffix}>
                                        <CustomTableCell>{n.commentid}</CustomTableCell>
                                        <CustomTableCell>{this.formatDate(n.date)}</CustomTableCell>
                                        <CustomTableCell>{getSteps[n.step]}</CustomTableCell>
                                        <CustomTableCell>{n.comment}</CustomTableCell>
                                    </TableRow>
                                );
                            })}
                            {comments.length < 1 && (
                                <TableRow>
                                    <CustomTableCell colSpan={3} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Comments Found</caption>
                                    </CustomTableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        {comments.length > 25 && (
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={3}
                                        count={3}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                    />
                                </TableRow>
                            </TableFooter>
                        )}
                    </Table>
                </Grid>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Return to Previous Step
                    </Button>
                    <Button variant="contained" type="submit" disabled={is_read_only} onClick={() => history.push("/dashboard/")} className={classes.button}>
                        Return to Dashboard
                    </Button>
                    <Button disabled={is_read_only} variant="contained" type="submit" color="primary" onClick={() => handleNext()}>
                        Proceed to Next Step
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

Final_Comments = connect(
    (state, ownProps) => ({
        authState: state.auth,
        comments: getComments(state, ownProps),
        review: getReview(state, ownProps),
        tab: getTab(state, ownProps)
    }),
    null
)(Final_Comments);

export default withStyles(styles)(withRouter(Final_Comments));
