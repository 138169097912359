import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { withStyles, Typography } from "@material-ui/core";

import TextField from "../../common/TextField";
import HelpLabel from "../../common/HelpLabel";
import { WorkPlanTab } from "../../home/models";

const getTab = WorkPlanTab.selectMessage(8);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    }
});

class Acquisition_RestorationTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields, ormParentUpdateLocalOnly, plan } = this.props;
        document.title = "Work Plan: Restoration - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!plan.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormParentUpdateLocalOnly({
                    id: plan.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updatePlan(values, fromStepper) {
        const {
            ormParentUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            plan,
            is_read_only,
            ormParentUpdateLocalOnly
        } = this.props;
        const { id } = plan;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (is_read_only) {
                ormParentUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = plan.map;
        values.financial_capacity = plan.financial_capacity;
        values.modify_date_label = plan.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormParentUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                handlePrev();
            } else {
                handleNext();
            }
        }
    }

    render() {
        const { classes, plan, proposal, is_read_only, handleUnsavedFields, ActionMenu, tab, ormParentUpdateLocalOnly } = this.props;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={proposal.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updatePlan(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16} style={{ marginTop: 0 }}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <HelpLabel
                                    inputLabel="Restoration Information"
                                    title={true}
                                    style={{ display: "inline-block", marginTop: 8 }}
                                    showLabel={true}
                                    htmlText={true}
                                    helpText={
                                        tab.sub_tabs
                                            .find(tht => tht.tab_name === "Restoration")
                                            .tab_help_texts.find(tht => tht.label === "Restoration Information").text
                                    }
                                />
                                <Typography variant="titleAction"></Typography>
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <TextField
                                    field="restoration_permanent_protection"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="1) Provide a statement confirming that all restoration activities completed with these funds will occur on land permanently protected by a conservation easement or public ownership."
                                />
                                <TextField
                                    field="restoration_management_plans"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="2) Summarize the components and expected outcomes of restoration and management plans for the parcels to be restored by your organization, how these plans are kept on file by your organization, and overall strategies for long-term plan implementation."
                                />
                                <TextField
                                    field="restoration_nveeg"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="3) Describe how restoration efforts will utilize and follow the Board of Soil and Water Resources “Native Vegetation Establishment and Enhancement Guidelines” in order to ensure ecological integrity and pollinator enhancement."
                                />
                                <TextField
                                    field="restoration_longterm_needs"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="4) Describe how the long-term maintenance and management needs of the parcel being restored with these funds will be met and financed into the future."
                                />
                                <TextField
                                    field="restoration_ccm"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="5) Describe how consideration will be given to contracting with Conservation Corps of Minnesota for any restoration activities."
                                />
                                <TextField
                                    field="restoration_evaluations"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="6) Provide a statement indicating that evaluations will be completed on parcels where activities were implemented both 1) initially after activity completion and 2) three years later as a follow-up. Evaluations should analyze improvements to the parcel and whether goals have been met, identify any problems with the implementation, and identify any findings that can be used to improve implementation of future restoration efforts at the site or elsewhere."
                                />
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ float: "left" }}
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                    className={classes.button}>
                                    Save and Return to Previous Step
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                    Save and Proceed to Next Step
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

Acquisition_RestorationTab = connect(
    (state, ownProps) => ({
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    null
)(Acquisition_RestorationTab);

export default withStyles(styles)(withRouter(Acquisition_RestorationTab));
