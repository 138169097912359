import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    header: {
        backgroundColor: "#eeeeee",
        margin: "0 -20px",
        padding: "20px 30px 10px 30px !important",
        flexBasis: "auto",
        width: "calc(100% + 40px)",
        maxWidth: "calc(100% + 40px)",
        borderBottom: "3px solid " + theme.palette.primary.main,
        marginTop: -28
    },
    screenReader: {
        textDecoration: "none",
        position: "absolute",
        width: 1,
        height: 1,
        padding: 0,
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        border: 0,
        zIndex: 2000,
    }
});

class PageHeader extends Component {
    render() {
        const { classes, title, admin_page, activeStep, activeSubStep } = this.props;

        return (
            <Grid item xs={12} className={classes.header}>
                <Typography variant="h2">
                    {title} {admin_page && " -> "} {admin_page && <a href="#/adm/home">Return to Admin Homepage</a>}
                </Typography>
                {activeStep && (
                    <div className={classes.screenReader} aria-live="polite">{activeStep} {activeSubStep}</div>
                )}
            </Grid>
        );
    }
}

export default withStyles(styles)(PageHeader);
