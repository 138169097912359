import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";

export class FinalWorkPlanView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "FinalWorkPlanView";
    }
}
orm.register(FinalWorkPlanView);

export class DraftWorkPlanView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "DraftWorkPlanView";
    }
}
orm.register(DraftWorkPlanView);

export class DraftWorkPlan extends Model {
    static get fields() {
        return {
            organization: fk("Organization", "draftworkplan_organization"),
            fiscal_agent: fk("FiscalAgent", "draftworkplan_fiscal_agent")
        };
    }
    /*static get user_only() {
        return true;
    }*/
    static get modelName() {
        return "DraftWorkPlan";
    }
}
orm.register(DraftWorkPlan);

export class FinalWorkPlan extends Model {
    static get fields() {
        return {
            organization: fk("Organization", "finalworkplan_organization"),
            fiscal_agent: fk("FiscalAgent", "finalworkplan_fiscal_agent")
        };
    }
    /*static get user_only() {
        return true;
    }*/
    static get modelName() {
        return "FinalWorkPlan";
    }
}
orm.register(FinalWorkPlan);

export class DraftProposalPastProject extends Model {
    static get fields() {
        return {
            applied_proposal: fk("DraftWorkPlan", "draft_past_project"),
            original_proposal: fk("HistoricalProposal", "draft_past_project2")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalPastProject";
    }
}
orm.register(DraftProposalPastProject);

export class FinalProposalPastProject extends Model {
    static get fields() {
        return {
            applied_proposal: fk("FinalWorkPlan", "final_past_project"),
            original_proposal: fk("HistoricalProposal", "final_past_project2")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalPastProject";
    }
}
orm.register(FinalProposalPastProject);

export class DraftProposalCollaborator extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_collaborator_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalCollaborator";
    }
}
orm.register(DraftProposalCollaborator);

export class FinalProposalCollaborator extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_collaborator_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalCollaborator";
    }
}
orm.register(FinalProposalCollaborator);

export class DraftProposalActivity extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_activity_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalActivity";
    }
}
orm.register(DraftProposalActivity);

export class DraftProposalMilestone extends Model {
    static get fields() {
        return {
            activity: fk("DraftProposalActivity", "draft_milestone_activities")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalMilestone";
    }
}
orm.register(DraftProposalMilestone);

export class FinalProposalActivity extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_activity_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalActivity";
    }
}
orm.register(FinalProposalActivity);

export class FinalProposalMilestone extends Model {
    static get fields() {
        return {
            activity: fk("FinalProposalActivity", "final_milestone_activities")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalMilestone";
    }
}
orm.register(FinalProposalMilestone);

export class DraftProposalPersonnel extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_personnel_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalPersonnel";
    }
}
orm.register(DraftProposalPersonnel);

export class FinalProposalPersonnel extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_personnel_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalPersonnel";
    }
}
orm.register(FinalProposalPersonnel);

export class DraftProposalContract extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_contract_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalContract";
    }
}
orm.register(DraftProposalContract);

export class FinalProposalContract extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_contract_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalContract";
    }
}
orm.register(FinalProposalContract);

export class DraftProposalExpenditure extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_expenditure_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalExpenditure";
    }
}
orm.register(DraftProposalExpenditure);

export class FinalProposalExpenditure extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_expenditure_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalExpenditure";
    }
}
orm.register(FinalProposalExpenditure);

export class DraftProposalAcquisition extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_acquisition_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalAcquisition";
    }
}
orm.register(DraftProposalAcquisition);

export class FinalProposalAcquisition extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_acquisition_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalAcquisition";
    }
}
orm.register(FinalProposalAcquisition);

export class DraftProposalTravel extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_travel_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalTravel";
    }
}
orm.register(DraftProposalTravel);

export class FinalProposalTravel extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_travel_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalTravel";
    }
}
orm.register(FinalProposalTravel);

export class DraftProposalPrinting extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_printing_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalPrinting";
    }
}
orm.register(DraftProposalPrinting);

export class FinalProposalPrinting extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_printing_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalPrinting";
    }
}
orm.register(FinalProposalPrinting);

export class DraftProposalExpense extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_expense_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalExpense";
    }
}
orm.register(DraftProposalExpense);

export class FinalProposalExpense extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_expense_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalExpense";
    }
}
orm.register(FinalProposalExpense);

export class DraftProposalNonENRTFFund extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_nonenrtffund_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalNonENRTFFund";
    }
}
orm.register(DraftProposalNonENRTFFund);

export class FinalProposalNonENRTFFund extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_nonenrtffund_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalNonENRTFFund";
    }
}
orm.register(FinalProposalNonENRTFFund);

export class DraftProposalAttachment extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_attachment_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalAttachment";
    }
}
orm.register(DraftProposalAttachment);

export class FinalProposalAttachment extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_attachment_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalAttachment";
    }
}
orm.register(FinalProposalAttachment);

export class DraftProposalParcel extends Model {
    static get fields() {
        return {
            proposal: fk("DraftWorkPlan", "draft_parcel_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "DraftProposalParcel";
    }
}
orm.register(DraftProposalParcel);

export class FinalProposalParcel extends Model {
    static get fields() {
        return {
            proposal: fk("FinalWorkPlan", "final_parcel_proposal")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "FinalProposalParcel";
    }
}
orm.register(FinalProposalParcel);

export class WorkPlanComment extends Model {
    static get fields() {
        return {
            user: fk("User", "comment_user2")
        };
    }
    static get modelName() {
        return "WorkPlanComment";
    }
    static get dontLoadByDefault() {
        return true;
    }
}
orm.register(WorkPlanComment);

export class WorkPlanReview extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "WorkPlanReview";
    }
    static get dontLoadByDefault() {
        return true;
    }
}
orm.register(WorkPlanReview);

export class DraftProposalCollaboratorView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "DraftProposalCollaboratorView";
    }
}
orm.register(DraftProposalCollaboratorView);

export class DraftProposalActivityView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "DraftProposalActivityView";
    }
}
orm.register(DraftProposalActivityView);

export class DraftProposalMilestoneView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "DraftProposalMilestoneView";
    }
}
orm.register(DraftProposalMilestoneView);

export class FinalProposalCollaboratorView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "FinalProposalCollaboratorView";
    }
}
orm.register(FinalProposalCollaboratorView);

export class FinalProposalActivityView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "FinalProposalActivityView";
    }
}
orm.register(FinalProposalActivityView);

export class FinalProposalMilestoneView extends Model {
    static get fields() {
        return {};
    }
    static get dontLoadByDefault() {
        return true;
    }
    static get modelName() {
        return "FinalProposalMilestoneView";
    }
}
orm.register(FinalProposalMilestoneView);

export class StatusUpdate extends Model {
    static get fields() {
        return {
            work_plan: fk("FinalWorkPlan", "status_update")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "StatusUpdate";
    }
}
orm.register(StatusUpdate);

export class AmendmentLog extends Model {
    static get fields() {
        return {
            work_plan: fk("FinalWorkPlan", "amendment")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "AmendmentLog";
    }
}
orm.register(AmendmentLog);

export class StatusUpdateActivity extends Model {
    static get fields() {
        return {
            status_update: fk("StatusUpdate", "status_update_activity"),
            activity: fk("FinalProposalActivity", "status_update_activity")
        };
    }
    static get user_only() {
        return true;
    }
    static get modelName() {
        return "StatusUpdateActivity";
    }
}
orm.register(StatusUpdateActivity);

export class ManagerRequest extends Model {
    static get fields() {
        return {};
    }
    /*static get dontLoadByDefault() {
        return true;
    }*/
    static get modelName() {
        return "ManagerRequest";
    }
}
orm.register(ManagerRequest);

export class CompletionRequest extends Model {
    static get fields() {
        return {};
    }
    /*static get dontLoadByDefault() {
        return true;
    }*/
    static get modelName() {
        return "CompletionRequest";
    }
}
orm.register(CompletionRequest);
