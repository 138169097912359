import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles, Typography } from "@material-ui/core";

import { Proposal } from "../models";
import { ProposalTab } from "../../home/models";

const getTab = ProposalTab.selectMessage(1);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    }
});

class IntroductionTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Introduction - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    render() {
        const { classes, history, handleNext, ActionMenu, proposal, tab } = this.props;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography variant="titleAction">Proposal Introduction & Instructions</Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography gutterBottom>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: tab.tab_help_texts.find(tht => tht.label === "Instructions:").text
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={is_read_only}
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Return to Dashboard
                    </Button>
                    <Button
                        disabled={is_read_only}
                        variant="contained"
                        type="submit"
                        color="primary"
                        onClick={() => handleNext()}>
                        Proceed to Next Step
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

IntroductionTab = connect(
    (state, ownProps) => ({
        authState: state.auth,
        tab: getTab(state, ownProps)
    }),
    {
        ...Proposal.actions
    }
)(IntroductionTab);

export default withStyles(styles)(withRouter(IntroductionTab));
