import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import * as navActions from "../common/actions";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import CustomTableCell from "../common/TableCell";
import TablePaginationActionsWrapped from "../common/Paginator";
import EnhancedTableHead from "../common/EnhancedTableHead";
import Table from "@material-ui/core/Table";
import TextField from "../common/TextField";
import TableFooter from "@material-ui/core/TableFooter";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";

import PersonIcon from "@material-ui/icons/Person";

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";

import MemberMessages from "../home/MemberMessages";

import { createSelector, reloadListOfModels } from "../common/orm";

const getRFPs = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});

const getYears = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});

const getActiveProjects = createSelector((schema) => {
    return schema.ProposalView.all()
        .filter(x => x.status === "Final Work Plan Approved" || x.status === "Final Abstract Due" || x.status === "Final Abstract Submitted" || x.status === "Final Abstract Revisions Needed")
        .orderBy("proposal_unique_id", "desc")
        .toModelArray()
        .map(view => {
            var plan = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === view.id).first();

            if (plan) {
                var org = schema.Organization.filter(x => x.id === plan.organization_id).first();
                return {
                    id: view.id, // Propsal ID
                    proposal_unique_id: view.proposal_unique_id,
                    citation_ml_year: view.citation_ml_year,
                    ml_year: view.ml_year,
                    subdivision: view.subdivision,
                    name: plan.name,
                    category: plan.funding_category,
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : "",
                    full_name: plan.pm_first_name + " " + plan.pm_last_name,
                    appropriation_amount: view.appropriation_amount,
                    amount_spent: plan.amount_spent,
                    staff_lead_label: view.staff_lead_label,
                    // Narrative Search
                    summary: plan.summary,
                    opportunity: plan.opportunity,
                    solution: plan.solution,
                    outcome: plan.outcome,
                    longterm_implementation: plan.longterm_implementation,
                    // Appropriation Search
                    app_lanuage: view.app_lanuage,
                    additional_app_lanuage: view.additional_app_lanuage,
                    // Abstract Search
                    project_outcomes_short: plan.project_outcomes_short,
                    project_outcomes_long: plan.project_outcomes_long,
                    project_results: plan.project_results,
                    //Milestones
                    milestone_fields: plan.milestone_fields
                };
            }
            return null;
        });
    }
);

const getCompletedProjects = createSelector((schema) => {
    return schema.ProposalView.all()
        .filter(x => x.status === "Project Completed")
        .orderBy("proposal_unique_id", "desc")
        .toModelArray()
        .map(view => {
            var plan = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === view.id).first();

            if (plan) {
                var org = schema.Organization.filter(x => x.id === plan.organization_id).first();
                return {
                    id: view.id, // Propsal ID
                    proposal_unique_id: view.proposal_unique_id,
                    citation_ml_year: view.citation_ml_year,
                    ml_year: view.ml_year,
                    subdivision: view.subdivision,
                    name: plan.name,
                    category: plan.funding_category,
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : "",
                    full_name: plan.pm_first_name + " " + plan.pm_last_name,
                    appropriation_amount: view.appropriation_amount,
                    amount_spent: plan.amount_spent,
                    staff_lead_label: view.staff_lead_label,
                    // Narrative Search
                    summary: plan.summary,
                    opportunity: plan.opportunity,
                    solution: plan.solution,
                    outcome: plan.outcome,
                    longterm_implementation: plan.longterm_implementation,
                    // Appropriation Search
                    app_lanuage: view.app_lanuage,
                    additional_app_lanuage: view.additional_app_lanuage,
                    // Abstract Search
                    project_outcomes_short: plan.project_outcomes_short,
                    project_outcomes_long: plan.project_outcomes_long,
                    project_results: plan.project_results,
                    //Milestones
                    milestone_fields: plan.milestone_fields
                };
            }
            return null;
        });
    }
);

const styles = theme => ({
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginTop: 0
        }
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    border: {
        marginTop: 10,
        border: "1px solid #979797",
        borderRadius: 4,
        flex: 1
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "400px"
    },
    textBox: {
        width: "95%",
        maxWidth: "400px"
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        backgroundColor: "#fff"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    floatLeft: {
        float: "left"
    }
});

const columnDataActiveProject = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Project ID", allowSort: true },
    { id: "ml_year", numeric: false, label: "ML Year / Subd", allowSort: true, combined: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "category", numeric: false, label: "Category", allowSort: true, minWidth:"250px" },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "appropriation_amount", numeric: true, label: "Amount Appropriated", allowSort: true },
    { id: "staff_lead_label", numeric: false, label: "Staff Lead", allowSort: true },
];

const columnDataFinalProject = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Project ID", allowSort: true },
    { id: "ml_year", numeric: false, label: "ML Year / Subd", allowSort: true, combined: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "category", numeric: false, label: "Category", allowSort: true, minWidth:"250px" },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "amount_spent", numeric: true, label: "Amount Spent", allowSort: true },
];

class MemberDashboard extends Component {
    state = {
        cp_year: ["All"],
        ap_year: ["All"],
        activeproject_textCache: "",
        completedproject_textCache: "",
        project_page: 0,
        project_rowsPerPage: 10,
        projectc_page: 0,
        projectc_rowsPerPage: 10
    };

    componentDidMount() {
        const { authState } = this.props;
        document.title = "Dashboard - LCCMR Proposal and Grant Management System";

        if (authState && authState.user) {
            this.props.reloadListOfModels(["SystemSetting"]);
            this.props.reloadListOfModels(["ProposalView"]);
            this.props.reloadListOfModels(["DraftWorkPlanView"]);
            this.props.reloadListOfModels(["FinalWorkPlanView"]);
        }
    }

    handleChangeCPYear = event => {
        this.changeCPYear(event.target.value);
    };
    changeCPYear = year_list => {
        var posAll = year_list.indexOf("All");
        var empty = year_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.cp_year.includes("-- Empty --")) {
            year_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.cp_year.includes("-- Empty --") && year_list.length === 0) {
            year_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.cp_year.includes("-- Empty --")) {
            year_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                year_list = ["All"];
            }
        }

        if (posAll > -1 && year_list.length < this.state.cp_year.length) {
            year_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && this.props.years.map(x => x.year).length > 0) {
            if (year_list.length > this.state.cp_year.length && this.state.cp_year.includes("All")) {
                year_list.splice(posAll, 1);
                year_list = this.props.years.filter(x => x.year !== year_list[0]).map(x => x.year);
            } else {
                year_list = ["All"];
            }
        }

        if (year_list.length === 0) {
            year_list = ["-- Empty --"];
        }

        this.setState({ cp_year: year_list });
    };
    handleChangeAPYear = event => {
        this.changeAPYear(event.target.value);
    };
    changeAPYear = year_list => {
        var posAll = year_list.indexOf("All");
        var empty = year_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.ap_year.includes("-- Empty --")) {
            year_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.ap_year.includes("-- Empty --") && year_list.length === 0) {
            year_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.ap_year.includes("-- Empty --")) {
            year_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                year_list = ["All"];
            }
        }

        if (posAll > -1 && year_list.length < this.state.ap_year.length) {
            year_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && this.props.years.map(x => x.year).length > 0) {
            if (year_list.length > this.state.ap_year.length && this.state.ap_year.includes("All")) {
                year_list.splice(posAll, 1);
                year_list = this.props.years.filter(x => x.year !== year_list[0]).map(x => x.year);
            } else {
                year_list = ["All"];
            }
        }

        if (year_list.length === 0) {
            year_list = ["-- Empty --"];
        }

        this.setState({ ap_year: year_list });
    };
    filterByValueProject = (array, string) => {
        return array.filter(o =>
            [
                "proposal_unique_id",
                "ml_year",
                "subdivision",
                "name",
                "category",
                "organization_name",
                "full_name",
                "appropriation_amount",
                "staff_lead_label",
                "summary",
                "opportunity",
                "solution",
                "outcome",
                "longterm_implementation",
                "app_lanuage",
                "additional_app_lanuage",
                "project_outcomes_short",
                "project_outcomes_long",
                "project_results",
                "milestone_fields",
            ].some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };
    filterByValueProject2 = (array, string) => {
        return array.filter(o =>
            [
                "proposal_unique_id",
                "ml_year",
                "subdivision",
                "name",
                "category",
                "organization_name",
                "full_name",
                "amount_spent",
                "summary",
                "opportunity",
                "solution",
                "outcome",
                "longterm_implementation",
                "app_lanuage",
                "additional_app_lanuage",
                "project_outcomes_short",
                "project_outcomes_long",
                "project_results",
                "milestone_fields",
            ].some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };
    handleRequestSort = (event, property, sortKey) => {
        const orderBy = property;
        let order = "desc";

        if (sortKey === "activeProjects" && this.state.project_orderBy === property && this.state.project_order === "desc") {
            order = "asc";
        } else if (sortKey === "completedProjects" && this.state.projectc_orderBy === property && this.state.projectc_order === "desc") {
            order = "asc";
        }

        var columnData = [];
        if (sortKey === "activeProjects") {
            columnData = columnDataActiveProject;
        } else if (sortKey === "completedProjects") {
            columnData = columnDataFinalProject;
        }

        this.props[sortKey].sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;
            var combined = columnData.find(cD => cD.id === orderBy).combined;
            if (date) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined" || a[orderBy] === "-") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined" || b[orderBy] === "-") return -1;
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else if (combined) {
                if (order === "desc")
                    return (b[orderBy] + " " + b["subdivision"] || "").toUpperCase() < (a[orderBy] + " " + a["subdivision"] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] + " " + a["subdivision"] || "").toUpperCase() < (b[orderBy] + " " + b["subdivision"] || "").toUpperCase() ? -1 : 1;
            } else {
                if (order === "desc") return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        if (sortKey === "activeProjects") {
            this.setState({ project_order: order, project_orderBy: orderBy });
        } else if (sortKey === "completedProjects") {
            this.setState({ projectc_order: order, projectc_orderBy: orderBy });
        }
    };
    handleTextFieldActiveProject = event => {
        this.setState({ activeproject_textCache: event });
    };
    handleTextFieldCompletedProject = event => {
        this.setState({ completedproject_textCache: event });
    };
    handleChangePage = (event, page, pageKey) => {
        this.setState({ [pageKey]: page });
    };
    handleChangeRowsPerPage = (event, pageKey, rowKey) => {
        this.setState({ [rowKey]: event.target.value, [pageKey]: 0 });
    };

    render() {
        const { classes, rpfYears, navState, navToggleMemberTask, navToggleActiveProject, navToggleCompletedProject, years, completedProjects, activeProjects } = this.props;
        const {
            activeproject_textCache,
            completedproject_textCache,
            project_order,
            project_orderBy,
            project_page,
            project_rowsPerPage,
            projectc_order,
            projectc_orderBy,
            projectc_page,
            projectc_rowsPerPage,
            cp_year,
            ap_year,
        } = this.state;

        const years_list = years.map(x => x.year);

        // COMPLETED PRojects
        var completed_projects_filtered = completedProjects;
        if (completedproject_textCache) {
            completed_projects_filtered = this.filterByValueProject2(completed_projects_filtered, completedproject_textCache);
        }
        if (!cp_year.includes("All")) {
            completed_projects_filtered = completed_projects_filtered.filter(x => cp_year.includes(x.ml_year))
        }

         // Active Projects
         var active_projects_filtered = activeProjects;
         if (activeproject_textCache) {
             active_projects_filtered = this.filterByValueProject(active_projects_filtered, activeproject_textCache);
         }
         if (!ap_year.includes("All")) {
            active_projects_filtered = active_projects_filtered.filter(x => ap_year.includes(x.ml_year))
         }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="LCCMR Member Dashboard" />
                    <MemberMessages />
                    <Grid item xs={12}>
                        <ExpansionPanel defaultExpanded={navState.memberTaskExpanded} CollapseProps={{ unmountOnExit: true }} onChange={navToggleMemberTask}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <PersonIcon className={classes.expansionIcon} />
                                    My Member Evaluations
                                </Typography>
                            </ExpansionPanelSummary>
                            {rpfYears.map((x, index) => {
                                return (
                                    <ExpansionPanelDetails key={index} style={!navState.memberTaskExpanded ? { visibility: "hidden" } : {}}>
                                        <Grid container spacing={16} className={classes.border}>
                                            <Grid item xs={12}>
                                                <Typography variant="h3">
                                                    M.L. {x.year} RFP (FY{parseInt(x.year.substring(2)) + 1})
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Button disabled={!x.evaluation1_open} fullWidth component={Link} to={`/member/select/presentation/${x.year}`}>
                                                    My Proposal Review and Evaluation #1: Presentation Selection M.L. {x.year} RFP
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Button fullWidth component={Link} to={`/workplan/review/${x.year}`}>
                                                    Work Plan Review M.L. {x.year} RFP
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Button
                                                    disabled={!x.evaluation2_open}
                                                    fullWidth
                                                    component={Link}
                                                    to={`/member/evaluation/presentation/${x.year}`}>
                                                    My Proposal Review and Evaluation #2: Scoring Post-Presentation M.L. {x.year} RFP
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Button disabled={!x.evaluation1_open} fullWidth component={Link} to={`/evaluation/result/${x.year}/${x.id}`}>
                                                    Commission Evaluation and Recommendation Results M.L. {x.year} RFP
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={4}></Grid>
                                            <Grid item xs={12} md={6} xl={4}></Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                );
                            })}
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.activeProjectExpand} onChange={navToggleActiveProject} CollapseProps={{ unmountOnExit: true }}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} />
                                    Active Projects
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography id="ap-years-checkbox-label">Select M.L. Year(s):</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="ap-years-checkbox-label"
                                            id="ap-years-checkbox"
                                            multiple
                                            value={ap_year}
                                            onChange={this.handleChangeAPYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="-- Empty --" value="-- Empty --">
                                                <Checkbox color="primary" checked={ap_year.includes("-- Empty --") && !ap_year.includes("All")} />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={ap_year.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {years_list.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={ap_year.includes("All") || ap_year.includes(year)} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        className={classes.textBox}
                                                        eventHandle={this.handleTextFieldActiveProject}
                                                        field="project_filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <ActiveProjectTable 
                                            classes={classes}
                                            columnDataActiveProject={columnDataActiveProject}
                                            project_order={project_order}
                                            project_orderBy={project_orderBy}
                                            handleRequestSort={this.handleRequestSort}
                                            active_projects_filtered={active_projects_filtered}
                                            project_page={project_page}
                                            project_rowsPerPage={project_rowsPerPage}
                                            handleChangePage={this.handleChangePage}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.completedProjectExpand} onChange={navToggleCompletedProject} CollapseProps={{ unmountOnExit: true }}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} />
                                    Completed Projects
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography id="cp-years-checkbox-label">Select M.L. Year(s):</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="cp-years-checkbox-label"
                                            id="cp-years-checkbox"
                                            multiple
                                            value={cp_year}
                                            onChange={this.handleChangeCPYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="-- Empty --" value="-- Empty --">
                                                <Checkbox color="primary" checked={cp_year.includes("-- Empty --") && !cp_year.includes("All")} />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={cp_year.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {years_list.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={cp_year.includes("All") || cp_year.includes(year)} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        className={classes.textBox}
                                                        eventHandle={this.handleTextFieldCompletedProject}
                                                        field="project_filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <CompletedProjectTable 
                                            classes={classes}
                                            columnDataFinalProject={columnDataFinalProject}
                                            projectc_order={projectc_order}
                                            projectc_orderBy={projectc_orderBy}
                                            handleRequestSort={this.handleRequestSort}
                                            completed_projects_filtered={completed_projects_filtered}
                                            projectc_page={projectc_page}
                                            projectc_rowsPerPage={projectc_rowsPerPage}
                                            handleChangePage={this.handleChangePage}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <Button component={Link} variant="contained" color="primary" to={"/adm/search#member"}>Open Advanced Search</Button>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

class ActiveProjectTable extends Component {
    wpDisplayFunding = n => {
        return n.appropriation_amount !== null && typeof n.appropriation_amount !== "undefined" ? "$" + n.appropriation_amount.toLocaleString() : "-";
    };

    render() {
        const { 
            classes, columnDataActiveProject, project_order, project_orderBy, handleRequestSort, active_projects_filtered, project_page,
            project_rowsPerPage, handleChangePage, handleChangeRowsPerPage 
        } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    name="activeProjects"
                    columnData={columnDataActiveProject}
                    stickyHeader
                    order={project_order}
                    orderBy={project_orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {active_projects_filtered
                        .slice(project_page * project_rowsPerPage, project_page * project_rowsPerPage + project_rowsPerPage)
                        .map(n => {
                            if (!n)
                                return null;
                            return (
                                <TableRow key={n.id}>
                                    <CustomTableCell className={classes.nowrap}>
                                        <Tooltip title={`View Work Plan ${n.proposal_unique_id}`}>
                                            <Button
                                                color="primary"
                                                className={classes.deleteWidth}
                                                aria-label={`View Work Plan ${n.proposal_unique_id}`}
                                                onClick={() => window.open(`#/final/workplan/${n.id}`)}>
                                                <Visibility color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                    <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                    <CustomTableCell>{n.citation_ml_year + " " + n.subdivision}</CustomTableCell>
                                    <CustomTableCell>{n.name}</CustomTableCell>
                                    <CustomTableCell>{n.category}</CustomTableCell>
                                    <CustomTableCell>{n.organization_name}</CustomTableCell>
                                    <CustomTableCell>
                                        {n.full_name}
                                    </CustomTableCell>
                                    <CustomTableCell>{this.wpDisplayFunding(n)}</CustomTableCell>
                                    <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                                </TableRow>
                            );
                        })}
                    {active_projects_filtered.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataActiveProject.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Active Projects Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataActiveProject.length}
                            count={active_projects_filtered.length}
                            rowsPerPage={project_rowsPerPage}
                            page={project_page}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={(e, page) => handleChangePage(e, page, "project_page")}
                            onChangeRowsPerPage={e => handleChangeRowsPerPage(e, "project_page", "project_rowsPerPage")}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

class CompletedProjectTable extends Component {
    wpDisplaySpent = n => {
        return n.amount_spent !== null && typeof n.amount_spent !== "undefined" ? "$" + n.amount_spent.toLocaleString() : "-";
    };  

    render() {
        const { 
            classes, columnDataFinalProject, projectc_order, projectc_orderBy, handleRequestSort, completed_projects_filtered, projectc_page,
            projectc_rowsPerPage, handleChangePage, handleChangeRowsPerPage 
        } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    name="completedProjects"
                    columnData={columnDataFinalProject}
                    stickyHeader
                    order={projectc_order}
                    orderBy={projectc_orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {completed_projects_filtered
                        .slice(projectc_page * projectc_rowsPerPage, projectc_page * projectc_rowsPerPage + projectc_rowsPerPage)
                        .map(n => {
                            return (
                                <TableRow key={n.id}>
                                    <CustomTableCell className={classes.nowrap}>
                                        <Tooltip title={`View Completed Project ${n.proposal_unique_id}`}>
                                            <Button
                                                color="primary"
                                                className={classes.deleteWidth}
                                                aria-label={`View Completed Project ${n.proposal_unique_id}`}
                                                onClick={() => window.open(`#/final/workplan/${n.id}`)}>
                                                <Visibility color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                    <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                    <CustomTableCell>{n.citation_ml_year + " " + n.subdivision}</CustomTableCell>
                                    <CustomTableCell>{n.name}</CustomTableCell>
                                    <CustomTableCell>{n.category}</CustomTableCell>
                                    <CustomTableCell>{n.organization_name}</CustomTableCell>
                                    <CustomTableCell>
                                        {n.full_name}
                                    </CustomTableCell>
                                    <CustomTableCell>{this.wpDisplaySpent(n)}</CustomTableCell>
                                </TableRow>
                            );
                        })}
                    {completed_projects_filtered.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataActiveProject.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Completed Projects Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataActiveProject.length}
                            count={completed_projects_filtered.length}
                            rowsPerPage={projectc_rowsPerPage}
                            page={projectc_page}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={(e, page) => handleChangePage(e, page, "projectc_page")}
                            onChangeRowsPerPage={e => handleChangeRowsPerPage(e, "projectc_page", "projectc_rowsPerPage")}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

MemberDashboard = connect(
    (state, ownProps) => ({
        authState: state.auth,
        rpfYears: getRFPs(state, ownProps),
        activeProjects: getActiveProjects(state, ownProps),
        completedProjects: getCompletedProjects(state, ownProps),
        years: getYears(state, ownProps),
        navState: state.nav
    }),
    {
        ...navActions,
        reloadListOfModels: reloadListOfModels
    }
)(MemberDashboard);

export default withStyles(styles)(withRouter(MemberDashboard));
