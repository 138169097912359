import React, { Component } from "react";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import {
    Proposal,
    ProposalPastProject,
    ProposalCollaborator,
    ProposalActivity,
    ProposalMilestone,
    ProposalPersonnel,
    ProposalContract,
    ProposalExpenditure,
    ProposalAcquisition,
    ProposalTravel,
    ProposalPrinting,
    ProposalExpense,
    ProposalNonENRTFFund,
    ProposalAttachment,
    ProposalParcel
} from "./models";

import {
    DraftWorkPlan,
    DraftProposalCollaborator,
    DraftProposalActivity,
    DraftProposalMilestone,
    DraftProposalPastProject,
    DraftProposalPersonnel,
    DraftProposalContract,
    DraftProposalExpenditure,
    DraftProposalAcquisition,
    DraftProposalTravel,
    DraftProposalPrinting,
    DraftProposalExpense,
    DraftProposalNonENRTFFund,
    DraftProposalAttachment,
    DraftProposalParcel,
    FinalProposalCollaborator,
    FinalProposalActivity,
    FinalProposalMilestone,
    FinalProposalPastProject,
    FinalProposalPersonnel,
    FinalProposalContract,
    FinalProposalExpenditure,
    FinalProposalAcquisition,
    FinalProposalTravel,
    FinalProposalPrinting,
    FinalProposalExpense,
    FinalProposalNonENRTFFund,
    FinalProposalAttachment,
    FinalProposalParcel,
    FinalWorkPlan
} from "../workplan/models";

import Select from "../common/Select";
import TextField from "../common/TextField";
import HelpLabel from "../common/HelpLabel";
import Switch from "../common/Switch";
import { createSelector, reloadListOfModels } from "../common/orm";

const allProposals = createSelector(schema => {
    return schema.Proposal.all()
        .orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray()
        .map(proposal => ({
            _ProposalPastProject: proposal.past_project
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalCollaborator: proposal.collaborator_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalActivity: proposal.activity_proposal
                .all()
                .orderBy("id")
                .toModelArray()
                .map(act => ({
                    milestones: act.milestone_activities
                        .all()
                        .orderBy("id")
                        .toRefArray(),
                    ...act.ref
                })),
            _ProposalPersonnel: proposal.personnel_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalContract: proposal.contract_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalExpenditure: proposal.expenditure_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalAcquisition: proposal.acquisition_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalTravel: proposal.travel_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalPrinting: proposal.printing_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalExpense: proposal.expense_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalNonENRTFFund: proposal.nonenrtffund_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalAttachment: proposal.attachment_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalParcel: proposal.parcel_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            ...proposal.ref
        }));
});

const allDraftWorkPlans = createSelector(schema => {
    return schema.DraftWorkPlan.all()
        .orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray()
        .map(proposal => ({
            _ProposalPastProject: proposal.draft_past_project
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalCollaborator: proposal.draft_collaborator_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalActivity: proposal.draft_activity_proposal
                .all()
                .orderBy("id")
                .toModelArray()
                .map(act => ({
                    milestones: act.draft_milestone_activities
                        .all()
                        .orderBy("id")
                        .toRefArray(),
                    ...act.ref
                })),
            _ProposalPersonnel: proposal.draft_personnel_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalContract: proposal.draft_contract_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalExpenditure: proposal.draft_expenditure_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalAcquisition: proposal.draft_acquisition_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalTravel: proposal.draft_travel_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalPrinting: proposal.draft_printing_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalExpense: proposal.draft_expense_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalNonENRTFFund: proposal.draft_nonenrtffund_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalAttachment: proposal.draft_attachment_proposal
                .all()
                .orderBy("id")
                .filter(x => x.type !== "Research Addendum" && x.type !== "Media Links")
                .toRefArray(),
            _ProposalParcel: proposal.draft_parcel_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            ...proposal.ref
        }));
});

const allFinalWorkPlans = createSelector(schema => {
    return schema.FinalWorkPlan.all()
        .orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray()
        .map(proposal => ({
            _ProposalPastProject: proposal.final_past_project
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalCollaborator: proposal.final_collaborator_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalActivity: proposal.final_activity_proposal
                .all()
                .orderBy("id")
                .toModelArray()
                .map(act => ({
                    milestones: act.final_milestone_activities
                        .all()
                        .orderBy("id")
                        .toRefArray(),
                    ...act.ref
                })),
            _ProposalPersonnel: proposal.final_personnel_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalContract: proposal.final_contract_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalExpenditure: proposal.final_expenditure_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalAcquisition: proposal.final_acquisition_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalTravel: proposal.final_travel_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalPrinting: proposal.final_printing_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalExpense: proposal.final_expense_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalNonENRTFFund: proposal.final_nonenrtffund_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            _ProposalAttachment: proposal.final_attachment_proposal
                .all()
                .orderBy("id")
                .filter(x => x.type !== "Research Addendum" && x.type !== "Media Links")
                .toRefArray(),
            _ProposalParcel: proposal.final_parcel_proposal
                .all()
                .orderBy("id")
                .toRefArray(),
            ...proposal.ref
        }));
});

const styles = {
    dialogMin: {
        minWidth: 400
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    }
};

function compare_unique_ids(a, b) {
    if (a.proposal_unique_id > b.proposal_unique_id) return 1;
    if (b.proposal_unique_id > a.proposal_unique_id) return -1;

    return 0;
}

class NewProposal extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            copyProposal: null,
            showCopyFields: false
        };
    }

    createNew(values) {
        const { proposals } = this.props;
        const { copyProposal } = this.state;

        const temp = proposals.find(x => x.id === copyProposal);

        if (typeof temp !== "undefined") {
            if (temp.primary_phase === "Draft Proposal" || temp.primary_phase === "Proposal") {
                this.addProposal(values);
            } else {
                if (temp.status.includes("Draft")) {
                    this.addProposalFromWorkPlan(values, "Draft", "draftWorkPlans");
                } else {
                    this.addProposalFromWorkPlan(values, "Final", "finalWorkPlans");
                }
            }
        } else {
            this.addProposal(values);
        }
    }

    addProposalFromWorkPlan(values, type, plansKey) {
        const { returnDialog, ormProposalCreate, history, authState } = this.props;
        const { copyProposal } = this.state;

        var new_proposal = {
            name: values.name,
            ml_year: (new Date().getFullYear() + 1).toString(),
            status: "Draft In Progress",
            secondary_status: "None",
            primary_phase: "Draft Proposal"
        };

        var saved_previous_id = null;

        if (copyProposal) {
            var previous_proposal = this.props[plansKey].find(cY => cY.proposal_record_id === copyProposal);
            // Deep copy
            previous_proposal = JSON.parse(JSON.stringify(previous_proposal));
            saved_previous_id = previous_proposal.id;
            delete previous_proposal.id;
            delete previous_proposal.proposal_unique_id;
            delete previous_proposal.authorized;
            delete previous_proposal.major_contributors;
            delete previous_proposal.accurate;
            delete previous_proposal.modified_user;
            delete previous_proposal.status_date;
            delete previous_proposal.update_date;
            delete previous_proposal.funding_category;
            delete previous_proposal.secondary_funding_category;

            // Delete this becuase logic change from from proposal to work plan
            delete previous_proposal.patent_royalty_revenue_potential;

            // Later properties overwrite earlier properties with the same name.
            new_proposal = { ...previous_proposal, ...new_proposal };
        } else {
            // Append default user values
            new_proposal["pm_first_name"] = authState.user.first_name;
            new_proposal["pm_last_name"] = authState.user.last_name;
            new_proposal["pm_office_phone"] = authState.user.phone;
            new_proposal["pm_office_ext"] = authState.user.ext;
            new_proposal["pm_email"] = authState.user.email;
        }

        ormProposalCreate(new_proposal).then(id => {
            var copyData = {
                new_id: id,
                copy_id: saved_previous_id,
                type: type.toLowerCase()
            };

            if (copyProposal) {
                fetch("/proposal/copy", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Token " + authState.user.auth_token
                    },
                    body: JSON.stringify(copyData)
                })
                    .then(result => {
                        if (!result.ok) {
                            return result.text().then(text => {
                                throw new Error(text);
                            });
                        } else {
                            return result.json();
                        }
                    })
                    .then(data => {
                        this.createLocalData(data);
                        this.props.reloadListOfModels(["ProposalActivity", "ProposalMilestone"]);
                        returnDialog();
                        history.push("/proposal/" + id);
                    })
                    .catch(e => {
                        returnDialog();
                    });
            } else {
                returnDialog();
                history.push("/proposal/" + id);
            }
        });
    }

    addProposal(values) {
        const { proposals, returnDialog, ormProposalCreate, history, authState } = this.props;
        const { copyProposal } = this.state;

        var new_proposal = {
            name: values.name,
            ml_year: (new Date().getFullYear() + 1).toString(),
            // ml_year: "2025",
            status: "Draft In Progress",
            secondary_status: "None",
            primary_phase: "Draft Proposal"
        };

        var saved_previous_id = null;

        // Use values from a previous year
        if (copyProposal) {
            var previous_proposal = proposals.find(cY => cY.id === copyProposal);
            // Deep copy
            previous_proposal = JSON.parse(JSON.stringify(previous_proposal));
            saved_previous_id = previous_proposal.id;
            delete previous_proposal.id;
            delete previous_proposal.proposal_unique_id;

            /*if (previous_proposal.final_funding_category !== null) {
                previous_proposal.funding_category = previous_proposal.final_funding_category;
                previous_proposal.secondary_funding_category = previous_proposal.final_secondary_funding_category;
            }*/

            if (previous_proposal.final_location_scale !== null) {
                previous_proposal.location_scale = previous_proposal.final_location_scale;
                previous_proposal.location_areas = previous_proposal.final_location_areas;
            }

            if (previous_proposal.final_impact_scale !== null) {
                previous_proposal.impact_scale = previous_proposal.final_impact_scale;
                previous_proposal.impact_areas = previous_proposal.final_impact_areas;
            }

            if (previous_proposal.final_impact_occur !== null) {
                previous_proposal.impact_occur = previous_proposal.final_impact_occur;
            }

            delete previous_proposal.final_funding_category;
            delete previous_proposal.final_secondary_funding_category;
            delete previous_proposal.funding_category;
            delete previous_proposal.secondary_funding_category;
            delete previous_proposal.final_location_scale;
            delete previous_proposal.final_location_areas;
            delete previous_proposal.final_impact_scale;
            delete previous_proposal.final_impact_areas;
            delete previous_proposal.final_impact_occur;
            delete previous_proposal.status;
            delete previous_proposal.secondary_status;
            delete previous_proposal.primary_phase;
            delete previous_proposal.peer_status;
            delete previous_proposal.modified_user;
            delete previous_proposal.update_date;
            delete previous_proposal.status_date;
            delete previous_proposal.secondary_date;
            delete previous_proposal.primary_date;
            delete previous_proposal.withdrawal_reason;
            delete previous_proposal.prev_status;
            delete previous_proposal.due_date;
            //delete previous_proposal.staff_lead;
            delete previous_proposal.selected_presentation;
            delete previous_proposal.recommended_for_funding;
            delete previous_proposal.recommended_amount;
            delete previous_proposal.appropriation_amount;
            delete previous_proposal.contingencies;
            delete previous_proposal.peer_review;
            delete previous_proposal.peer_waived;
            delete previous_proposal.explanation_waiver;
            //delete previous_proposal.ineligible_expenses_count;
            //delete previous_proposal.classified_count;
            //delete previous_proposal.cap_exp_count;
            //delete previous_proposal.stewardship_count;
            delete previous_proposal.lock_research_addendum;
            delete previous_proposal.workplan_approved;
            delete previous_proposal.workplan_date;
            delete previous_proposal.governor_date;
            //delete previous_proposal.through;
            //delete previous_proposal.agency;
            delete previous_proposal.special_session;
            delete previous_proposal.article;
            delete previous_proposal.citation_ml_year;
            delete previous_proposal.chapter;
            delete previous_proposal.section;
            delete previous_proposal.subdivision;
            delete previous_proposal.app_lanuage;
            delete previous_proposal.additional_app_lanuage;
            delete previous_proposal.additional_citation;
            delete previous_proposal.legislative_notes;
            delete previous_proposal.legislative_date;

            // Later properties overwrite earlier properties with the same name.
            new_proposal = { ...previous_proposal, ...new_proposal };
        } else {
            // Append default user values
            new_proposal["pm_first_name"] = authState.user.first_name;
            new_proposal["pm_last_name"] = authState.user.last_name;
            new_proposal["pm_office_phone"] = authState.user.phone;
            new_proposal["pm_office_ext"] = authState.user.ext;
            new_proposal["pm_email"] = authState.user.email;
        }
        new_proposal["recommended_amount"] = null;

        ormProposalCreate(new_proposal).then(id => {
            // We need to also copy all the child models
            if (copyProposal) {
                var copyData = {
                    new_id: id,
                    copy_id: saved_previous_id,
                    type: "proposal"
                };

                fetch("/proposal/copy", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Token " + authState.user.auth_token
                    },
                    body: JSON.stringify(copyData)
                })
                    .then(result => {
                        if (!result.ok) {
                            return result.text().then(text => {
                                throw new Error(text);
                            });
                        } else {
                            return result.json();
                        }
                    })
                    .then(data => {
                        this.createLocalData(data);
                        this.props.reloadListOfModels(["ProposalActivity", "ProposalMilestone"]);
                        returnDialog();
                        history.push("/proposal/" + id);
                    })
                    .catch(e => {
                        returnDialog();
                    });
            } else {
                returnDialog();
                history.push("/proposal/" + id);
            }
        });
    }

    // The relationship between Activity and Milestone doesn't want to work with code below(No milestone appear until reloaded).
    // Work around due to time is just reload with reloadListOfModels.
    createLocalData = data => {
        const runs = [
            //'Activity',
            "PastProject",
            "Collaborator",
            "Personnel",
            "Contract",
            "Expenditure",
            "Acquisition",
            "Travel",
            "Printing",
            "Expense",
            "NonENRTFFund",
            "Attachment",
            "Parcel"
            //'Milestone'
        ];

        const _this = this;

        runs.forEach(x => {
            data[x].forEach(obj => {
                if (x === "PastProject") {
                    _this.props[`ormProposal${x}CreateLocalOnly`]({
                        ...obj,
                        applied_proposal: obj.applied_proposal_id
                    });
                } else if (x === "Milestone") {
                    _this.props[`ormProposal${x}CreateLocalOnly`]({ ...obj, activity: obj.activity_id });
                } else {
                    _this.props[`ormProposal${x}CreateLocalOnly`]({ ...obj, proposal: obj.proposal_id });
                }
            });
        });
    };

    proposalDropDown = value => {
        this.setState({ copyProposal: value });
    };

    showCopyFields = value => {
        this.setState({ showCopyFields: value, copyProposal: null });
    };

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, proposals } = this.props;

        const { showCopyFields } = this.state;

        function makeOptionsProposals(table) {
            return table.sort(compare_unique_ids).map(row => ({
                label: row.proposal_unique_id + ": " + row.name,
                value: row.id
            }));
        }

        return (
            <Dialog role="main" aria-label="Create New Proposal" open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        Create New Proposal
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.minWidth}>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={values => this.createNew(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField label="Project Title (8 word limit)" field="name" fullWidth maxWords="8">
                                                <HelpLabel
                                                    inputLabel="Project Title (8 word limit)"
                                                    helpText="Please use a distinct and descriptive title."
                                                />
                                            </TextField>
                                            {proposals.length > 0 && (
                                                <div>
                                                    <Switch
                                                        field="switch"
                                                        name="switch"
                                                        eventHandle={value => this.showCopyFields(value)}
                                                        label="Would you like to copy from a previous proposal?"
                                                    />
                                                    {showCopyFields && (
                                                        <Select
                                                            field="fa"
                                                            label="Your Previous Proposals"
                                                            eventHandle={value => this.proposalDropDown(value)}
                                                            options={makeOptionsProposals(proposals)}
                                                            fullWidth
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Create Proposal
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

NewProposal = connect(
    (state, ownProps) => ({
        proposals: allProposals(state),
        draftWorkPlans: allDraftWorkPlans(state),
        finalWorkPlans: allFinalWorkPlans(state),
        authState: state.auth
    }),
    {
        reloadListOfModels: reloadListOfModels,

        ...Proposal.actions,
        ...ProposalPastProject.actions,
        ...ProposalCollaborator.actions,
        ...ProposalActivity.actions,
        ...ProposalMilestone.actions,
        ...ProposalPersonnel.actions,
        ...ProposalContract.actions,
        ...ProposalExpenditure.actions,
        ...ProposalAcquisition.actions,
        ...ProposalTravel.actions,
        ...ProposalPrinting.actions,
        ...ProposalExpense.actions,
        ...ProposalNonENRTFFund.actions,
        ...ProposalAttachment.actions,
        ...ProposalParcel.actions,

        ...DraftWorkPlan.actions,
        ...DraftProposalCollaborator.actions,
        ...DraftProposalActivity.actions,
        ...DraftProposalMilestone.actions,
        ...DraftProposalPastProject.actions,
        ...DraftProposalPersonnel.actions,
        ...DraftProposalContract.actions,
        ...DraftProposalExpenditure.actions,
        ...DraftProposalAcquisition.actions,
        ...DraftProposalTravel.actions,
        ...DraftProposalPrinting.actions,
        ...DraftProposalExpense.actions,
        ...DraftProposalNonENRTFFund.actions,
        ...DraftProposalAttachment.actions,
        ...DraftProposalParcel.actions,
        ...FinalWorkPlan.actions,
        ...FinalProposalCollaborator.actions,
        ...FinalProposalActivity.actions,
        ...FinalProposalMilestone.actions,
        ...FinalProposalPastProject.actions,
        ...FinalProposalPersonnel.actions,
        ...FinalProposalContract.actions,
        ...FinalProposalExpenditure.actions,
        ...FinalProposalAcquisition.actions,
        ...FinalProposalTravel.actions,
        ...FinalProposalPrinting.actions,
        ...FinalProposalExpense.actions,
        ...FinalProposalNonENRTFFund.actions,
        ...FinalProposalAttachment.actions,
        ...FinalProposalParcel.actions
    }
)(NewProposal);

export default withStyles(styles)(withRouter(NewProposal));
