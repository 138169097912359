import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import AppContainer from "../common/AppContainer";
import Checkbox from "../common/Checkbox";
import TextField from "../common/TextField";
import Select from "../common/Select";
import PageHeader from "../common/PageHeader";
import { Organization } from "../home/models";
import { createSelector } from "../common/orm";
import states from "../common/states.json";

const getOrganization = Organization.selectByUrlId();
const getStaff = createSelector(schema => {
    return schema.User.filter(u => u.role === "Staff" && u.is_active)
        .orderBy("last_name", "asc")
        .toRefArray();
});

class EditOrganization extends Component {
    componentDidMount() {
        document.title = "Edit Organization - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        return {
            organization_name: validateRequired(values.organization_name)
        };
    };

    submitForm = values => {
        const { ormOrganizationUpdate, ormOrganizationCreate, organization, history } = this.props;

        if (!organization) {
            ormOrganizationCreate(values);
        } else {
            ormOrganizationUpdate({
                id: values.id,
                ...values
            });
        }
        history.push("/adm/organizations");
    };

    render() {
        const { organization, staff } = this.props;

        var staff_dropdown = [];
        staff.forEach(function(s) {
            staff_dropdown.push({ label: s.first_name + " " + s.last_name, value: s.id });
        });

        return (
            <AppContainer authenticated>
                <Grid container justify="center" spacing={40}>
                    <PageHeader
                        title={
                            organization ? "Edit Organization: " + organization.organization_name : "New Organization"
                        }
                        admin_page
                    />
                    <Grid item xs={11} md={8} lg={6}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={organization}
                            validateError={this.errorValidator}
                            onSubmit={this.submitForm}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={6} style={{ marginBottom: -16 }}>
                                            <Checkbox field="is_active" label="Active" />
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ marginBottom: -16 }}>
                                            <Select
                                                field="staff_lead_id"
                                                label="Active Staff Lead"
                                                options={staff_dropdown}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="organization_name" label="Organization Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="department" label="Department" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select
                                                field="recipient"
                                                label="Recipient"
                                                options={[
                                                    { label: "Federal Government", value: "Federal Government" },
                                                    {
                                                        label: "For-Profit Business/Entity",
                                                        value: "For-Profit Business/Entity"
                                                    },
                                                    { label: "K-12 Education", value: "K-12 Education" },
                                                    {
                                                        label: "Local/Regional Government",
                                                        value: "Local/Regional Government"
                                                    },
                                                    {
                                                        label: "Non-Profit Business/Entity",
                                                        value: "Non-Profit Business/Entity"
                                                    },
                                                    {
                                                        label: "Private College/University",
                                                        value: "Private College/University"
                                                    },
                                                    {
                                                        label: "Public College/University",
                                                        value: "Public College/University"
                                                    },
                                                    { label: "State Government", value: "State Government" },
                                                    { label: "Tribal", value: "Tribal" }
                                                ]}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="web_address" label="Web Address" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="mailing_address" label="Mailing Address" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="address_2" label="Address 2" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="city" label="City" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select field="state" label="State" options={states} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="zip" label="Zip" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField phoneNumber field="phone" label="Phone Number" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth component={Link} to="/adm/organizations">
                                                Return to Manage Organization
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

EditOrganization = connect(
    (state, ownProps) => ({
        authState: state.auth,
        organization: getOrganization(state, ownProps),
        staff: getStaff(state, ownProps)
    }),
    Organization.actions
)(EditOrganization);

export default withRouter(EditOrganization);
