import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MuiTooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import { Proposal, ProposalCollaborator } from "../models";

import RadioGroup from "../../common/RadioGroup";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import TextField from "../../common/TextField";
import HelpLabel from "../../common/HelpLabel";
import { MAKE_OPTIONS_YESNO } from "../../../api/constants";
import { createSelector } from "../../common/orm";
import { ProposalTab } from "../../home/models";

const getProposalCollaborators = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalCollaborator.filter({ proposal: id })
            .orderBy("id")
            .toRefArray();
    }
);
const getTab = ProposalTab.selectMessage(2);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    hide: {
        display: "none"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    centerAlign: {
        textAlign: "center"
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    }
});

const columnDataCollaborators = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "name", numeric: false, label: "Name" },
    { id: "organization", numeric: false, label: "Organization" },
    { id: "role", numeric: false, label: "Role" },
    { id: "receiving_funds", numeric: false, label: "Receiving Funds?" }
];

class Collaborators_PartnersTab extends Component {
    state = {
        CollaboratorDialogOpen: false,
        collaborator: null,
        formKey: null,
        order: "asc",
        orderBy: "name"
    };
    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Project Partner Info - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    deleteCollaborator = id => {
        const { ormProposalCollaboratorDelete } = this.props;
        ormProposalCollaboratorDelete(id);
    };

    render() {
        const {
            classes,
            proposalcollaborators,
            ActionMenu,
            handleNext,
            handlePrev,
            history,
            ormProposalCollaboratorUpdate,
            ormProposalCollaboratorCreate,
            proposal,
            tab
        } = this.props;
        const { CollaboratorDialogOpen, formkey, collaborator, order, orderBy } = this.state;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <HelpLabel
                        inputLabel={"Project Partners and Collaborators"}
                        title={true}
                        style={{ display: "inline-block", marginTop: 8 }}
                        showLabel={true}
                        htmlText={true}
                        helpText={
                            tab.sub_tabs
                                .find(tht => tht.tab_name === "Project Partner Info")
                                .tab_help_texts.find(tht => tht.label === "Project Partners and Collaborators").text
                        }
                    />
                    {ActionMenu}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={is_read_only}
                        onClick={() =>
                            this.setState({
                                CollaboratorDialogOpen: true,
                                collaborator: null,
                                formkey: window.performance.now()
                            })
                        }>
                        <AddCircleOutlineIcon />
                        &nbsp;&nbsp; Add Project Collaborator
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Table className={classes.table}>
                        <EnhancedTableHead
                            columnData={columnDataCollaborators}
                            stickyHeader
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                        />
                        <TableBody>
                            {proposalcollaborators.map(n => {
                                return (
                                    <TableRow key={n.id}>
                                        <TableCell className={classes.nowrap}>
                                            <MuiTooltip title="Edit Collaborator">
                                                <Button
                                                    color="primary"
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label={"Edit Collaborator"}
                                                    onClick={() =>
                                                        this.setState({
                                                            CollaboratorDialogOpen: true,
                                                            collaborator: n,
                                                            formkey: window.performance.now()
                                                        })
                                                    }>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete Collaborator">
                                                <Button
                                                    color="primary"
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label={"Delete Collaborator"}
                                                    onClick={() => this.deleteCollaborator(n.id)}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="View Collaborator">
                                                <Button
                                                    color="primary"
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label={"View Collaborator"}
                                                    onClick={() =>
                                                        this.setState({
                                                            CollaboratorDialogOpen: true,
                                                            collaborator: n,
                                                            formkey: window.performance.now()
                                                        })
                                                    }>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </TableCell>
                                        <TableCell>{n.name}</TableCell>
                                        <TableCell>{n.organization}</TableCell>
                                        <TableCell>{n.role}</TableCell>
                                        <TableCell>{n.receiving_funds ? "✔" : ""}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {proposalcollaborators.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={5} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Records</caption>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        disabled={is_read_only}
                        variant="contained"
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button disabled={is_read_only} variant="contained" color="primary" onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>
                <PartnerDialog
                    open={CollaboratorDialogOpen}
                    ormProposalCollaboratorCreate={ormProposalCollaboratorCreate}
                    ormProposalCollaboratorUpdate={ormProposalCollaboratorUpdate}
                    returnDialog={() => this.setState({ CollaboratorDialogOpen: false })}
                    classes={classes}
                    formkey={formkey}
                    collaborator={collaborator}
                    proposal={proposal.id}
                    is_read_only={is_read_only}
                />
            </Grid>
        );
    }
}

class PartnerDialog extends Component {
    saveOrgFiscalAgent(values) {
        const {
            ormProposalCollaboratorCreate,
            ormProposalCollaboratorUpdate,
            returnDialog,
            collaborator,
            proposal
        } = this.props;

        if (!collaborator) {
            ormProposalCollaboratorCreate({
                proposal_id: proposal,
                ...values
            });
        } else {
            ormProposalCollaboratorUpdate({
                ...values
            });
        }
        returnDialog();
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name && name !== false ? "Required" : null;
        };
        return {
            name: validateRequired(values.name), // Name required in Django
            organization: validateRequired(values.organization),
            role: validateRequired(values.role),
            receiving_funds: validateRequired(values.receiving_funds)
        };
    };

    render() {
        const { classes, open, returnDialog, formkey, collaborator, is_read_only } = this.props;

        var interact_string = !collaborator ? "Add" : "Edit";
        if (is_read_only) {
            interact_string = "View";
        }

        return (
            <Dialog role="main" aria-label={interact_string + " Project Partner / Collaborator"} open={open}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        {interact_string} Project Partner / Collaborator
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={formkey}
                            defaultValues={collaborator}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={values => this.saveOrgFiscalAgent(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField label="Name" field="name" fullWidth disabled={is_read_only} />
                                            <TextField
                                                label="Organization"
                                                field="organization"
                                                fullWidth
                                                disabled={is_read_only}
                                            />
                                            <TextField
                                                label="Role"
                                                field="role"
                                                multiline
                                                maxWords="50"
                                                fullWidth
                                                disabled={is_read_only}
                                            />
                                            <RadioGroup
                                                field="receiving_funds"
                                                name="receiving_funds"
                                                label="Receiving Funds?"
                                                fullWidth
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS_YESNO([
                                                    { label: "Yes", value: "true", disabled: is_read_only },
                                                    { label: "No", value: "false", disabled: is_read_only }
                                                ])}
                                                alignment={true}></RadioGroup>
                                            <Grid container spacing={16}>
                                                {!is_read_only && (
                                                    <>
                                                        <Grid item xs={6}>
                                                            <Button fullWidth onClick={() => returnDialog()}>
                                                                Return
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                fullWidth
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={is_read_only}>
                                                                Save
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                )}
                                                {is_read_only && (
                                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                                        <Button
                                                            style={{ width: 200 }}
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary">
                                                            Close
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

Collaborators_PartnersTab = connect(
    (state, ownProps) => ({
        proposalcollaborators: getProposalCollaborators(state, ownProps),
        authState: state.auth,
        tab: getTab(state, ownProps)
    }),
    {
        ...Proposal.actions,
        ...ProposalCollaborator.actions
    }
)(Collaborators_PartnersTab);

export default withStyles(styles)(withRouter(Collaborators_PartnersTab));
