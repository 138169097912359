import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import BuildIcon from "@material-ui/icons/Build";
import GetApp from "@material-ui/icons/GetApp";
import PersonIcon from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";

import AppContainer from "../common/AppContainer";
import CustomTableCell from "../common/TableCell";
import Checkbox from "../common/Checkbox";
import EnhancedTableHead from "../common/EnhancedTableHead";
import PageHeader from "../common/PageHeader";
import RadioGroup from "../common/RadioGroup";
import TablePaginationActionsWrapped from "../common/Paginator";
import TextField from "../common/TextField";
import Select from "../common/Select";
import Snackbar from "../common/Snackbar";
import { createSelector } from "../common/orm";
import { MemberEvaluation, ProposalReview } from "../home/models";

const getProposalForPresentation = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["year"]),
    (state, ownProps) => (ownProps.location.serach !== "" ? parseInt(ownProps.location.search.split("=")[1]) : null),
    (state, ownProps) => ownProps.match.path,
    (schema, year, id, url) => {
        const setting = schema.SystemSetting.filter(x => x.year && parseInt(x.year) === year).first();

        if (setting && !setting["evaluation2_open"]) {
            if (url === "/adm/select/presentation/:year") {
                return schema.ProposalView.filter(
                    x =>
                        ["Revisions Submitted", "Final Submitted", "Revisions Needed"].includes(x.status) &&
                        x.proposal_unique_id.includes(year) &&
                        x.secondary_status !== "Withdrawn" &&
                        x.secondary_status !== "Withdrawal Requested"
                )
                    .orderBy("proposal_unique_id", "asc")
                    .toModelArray()
                    .map(view => {
                        var review = view.review_proposal.first();
                        var evaluation = view.eval_member.filter(x => x.user_id === id).first();
                        var temp = evaluation ? evaluation : {};
                        var tempReview = review ? review : {};
                        var org = schema.Organization.filter(x => x.id === view.organization_id).first();
                        var category = schema.FundingCategory.filter(
                            x =>
                                x.category_name ===
                                    (view.final_funding_category
                                        ? view.final_funding_category
                                        : view.funding_category) && parseInt(x.year_label) === year
                        ).first();

                        var secondarySuffix = null;
                        if (view.funding_category === "Small Projects" && !view.final_funding_category) {
                            secondarySuffix = schema.FundingCategory.filter(
                                x =>
                                    x.category_name === view.secondary_funding_category &&
                                    parseInt(x.year_label) === year
                            ).first();
                        } else if (view.final_funding_category && view.final_funding_category === "Small Projects") {
                            secondarySuffix = schema.FundingCategory.filter(
                                x =>
                                    x.category_name === view.final_secondary_funding_category &&
                                    parseInt(x.year_label) === year
                            ).first();
                        }

                        var final_category = category && category.suffix ? category.suffix + ". " : "";
                        final_category += view.final_funding_category
                            ? view.final_funding_category
                            : view.funding_category
                            ? view.funding_category
                            : "";

                        if (view.final_funding_category) {
                            final_category +=
                                view.final_funding_category === "Small Projects" &&
                                view.final_secondary_funding_category
                                    ? " / " +
                                      (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                      view.final_secondary_funding_category
                                    : "";
                        } else {
                            final_category +=
                                view.funding_category === "Small Projects" && view.secondary_funding_category
                                    ? " / " +
                                      (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                      view.secondary_funding_category
                                    : "";
                        }

                        return {
                            ...temp,
                            id: view.id,
                            proposal_unique_id: view.proposal_unique_id,
                            name: view.name,
                            summary: view.summary,
                            category: category,
                            final_category,
                            secondarySuffix: secondarySuffix ? secondarySuffix.suffix : null,
                            suffix: category ? category.suffix : null,
                            funding_category: view.funding_category,
                            secondary_category: view.secondary_funding_category,
                            pm_full_name: view.pm_first_name + " " + view.pm_last_name,
                            funding_total: view.funding_total,
                            final_score: tempReview.final_score,
                            final_member_comments2: tempReview.final_member_comments2,
                            evalid: temp ? temp.id : null,
                            organization: org ? org.organization_name : ""
                        };
                    });
            } else {
                return schema.ProposalView.filter(
                    x =>
                        ["Revisions Submitted", "Final Submitted", "Revisions Needed"].includes(x.status) &&
                        x.proposal_unique_id.includes(year) &&
                        x.selected_presentation &&
                        x.secondary_status !== "Withdrawn" &&
                        x.secondary_status !== "Withdrawal Requested"
                )
                    .orderBy("proposal_unique_id", "asc")
                    .toModelArray()
                    .map(view => {
                        var review = view.review_proposal.first();
                        var evaluation = view.eval_member.filter(x => x.user_id === id).first();
                        var temp = evaluation ? evaluation : {};
                        var tempReview = review ? review : {};
                        var org = schema.Organization.filter(x => x.id === view.organization_id).first();
                        var category = schema.FundingCategory.filter(
                            x =>
                                x.category_name ===
                                    (view.final_funding_category
                                        ? view.final_funding_category
                                        : view.funding_category) && parseInt(x.year_label) === year
                        ).first();

                        var secondarySuffix = null;
                        if (view.funding_category === "Small Projects" && !view.final_funding_category) {
                            secondarySuffix = schema.FundingCategory.filter(
                                x =>
                                    x.category_name === view.secondary_funding_category &&
                                    parseInt(x.year_label) === year
                            ).first();
                        } else if (view.final_funding_category && view.final_funding_category === "Small Projects") {
                            secondarySuffix = schema.FundingCategory.filter(
                                x =>
                                    x.category_name === view.final_secondary_funding_category &&
                                    parseInt(x.year_label) === year
                            ).first();
                        }

                        var final_category = category && category.suffix ? category.suffix + ". " : "";
                        final_category += view.final_funding_category
                            ? view.final_funding_category
                            : view.funding_category
                            ? view.funding_category
                            : "";

                        if (view.final_funding_category) {
                            final_category +=
                                view.final_funding_category === "Small Projects" &&
                                view.final_secondary_funding_category
                                    ? " / " +
                                      (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                      view.final_secondary_funding_category
                                    : "";
                        } else {
                            final_category +=
                                view.funding_category === "Small Projects" && view.secondary_funding_category
                                    ? " / " +
                                      (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                      view.secondary_funding_category
                                    : "";
                        }

                        return {
                            ...temp,
                            id: view.id,
                            proposal_unique_id: view.proposal_unique_id,
                            name: view.name,
                            summary: view.summary,
                            category: category,
                            final_category,
                            secondarySuffix: secondarySuffix ? secondarySuffix.suffix : null,
                            suffix: category ? category.suffix : null,
                            funding_category: view.funding_category,
                            secondary_category: view.secondary_funding_category,
                            pm_full_name: view.pm_first_name + " " + view.pm_last_name,
                            funding_total: view.funding_total,
                            final_score: tempReview.final_score,
                            final_member_comments2: tempReview.final_member_comments2,
                            evalid: temp ? temp.id : null,
                            organization: org ? org.organization_name : ""
                        };
                    });
            }
        } else {
            if (url === "/adm/select/presentation/:year") {
                return schema.ProposalView.filter(
                    x =>
                        x.proposal_unique_id.includes(year) &&
                        (x.selected_presentation === false || x.selected_presentation === true) &&
                        x.secondary_status !== "Withdrawn" &&
                        x.secondary_status !== "Withdrawal Requested"
                )
                    .orderBy("proposal_unique_id", "asc")
                    .toModelArray()
                    .map(view => {
                        var review = view.review_proposal.first();
                        var evaluation = view.eval_member.filter(x => x.user_id === id).first();
                        var temp = evaluation ? evaluation : {};
                        var tempReview = review ? review : {};
                        var org = schema.Organization.filter(x => x.id === view.organization_id).first();
                        var category = schema.FundingCategory.filter(
                            x =>
                                x.category_name ===
                                    (view.final_funding_category
                                        ? view.final_funding_category
                                        : view.funding_category) && parseInt(x.year_label) === year
                        ).first();

                        var secondarySuffix = null;
                        if (view.funding_category === "Small Projects" && !view.final_funding_category) {
                            secondarySuffix = schema.FundingCategory.filter(
                                x =>
                                    x.category_name === view.secondary_funding_category &&
                                    parseInt(x.year_label) === year
                            ).first();
                        } else if (view.final_funding_category && view.final_funding_category === "Small Projects") {
                            secondarySuffix = schema.FundingCategory.filter(
                                x =>
                                    x.category_name === view.final_secondary_funding_category &&
                                    parseInt(x.year_label) === year
                            ).first();
                        }

                        var final_category = category && category.suffix ? category.suffix + ". " : "";
                        final_category += view.final_funding_category
                            ? view.final_funding_category
                            : view.funding_category
                            ? view.funding_category
                            : "";

                        if (view.final_funding_category) {
                            final_category +=
                                view.final_funding_category === "Small Projects" &&
                                view.final_secondary_funding_category
                                    ? " / " +
                                      (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                      view.final_secondary_funding_category
                                    : "";
                        } else {
                            final_category +=
                                view.funding_category === "Small Projects" && view.secondary_funding_category
                                    ? " / " +
                                      (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                      view.secondary_funding_category
                                    : "";
                        }

                        return {
                            ...temp,
                            id: view.id,
                            proposal_unique_id: view.proposal_unique_id,
                            name: view.name,
                            summary: view.summary,
                            category: category,
                            final_category,
                            secondarySuffix: secondarySuffix ? secondarySuffix.suffix : null,
                            suffix: category ? category.suffix : null,
                            funding_category: view.funding_category,
                            secondary_category: view.secondary_funding_category,
                            pm_full_name: view.pm_first_name + " " + view.pm_last_name,
                            funding_total: view.funding_total,
                            final_score: tempReview.final_score,
                            final_member_comments2: tempReview.final_member_comments2,
                            evalid: temp ? temp.id : null,
                            organization: org ? org.organization_name : ""
                        };
                    });
            } else {
                return schema.ProposalView.filter(
                    x =>
                        x.proposal_unique_id.includes(year) &&
                        x.selected_presentation &&
                        x.secondary_status !== "Withdrawn" &&
                        x.secondary_status !== "Withdrawal Requested"
                )
                    .orderBy("proposal_unique_id", "asc")
                    .toModelArray()
                    .map(view => {
                        var review = view.review_proposal.first();
                        var evaluation = view.eval_member.filter(x => x.user_id === id).first();
                        var temp = evaluation ? evaluation : {};
                        var tempReview = review ? review : {};
                        var org = schema.Organization.filter(x => x.id === view.organization_id).first();
                        var category = schema.FundingCategory.filter(
                            x =>
                                x.category_name ===
                                    (view.final_funding_category
                                        ? view.final_funding_category
                                        : view.funding_category) && parseInt(x.year_label) === year
                        ).first();

                        var secondarySuffix = null;
                        if (view.funding_category === "Small Projects" && !view.final_funding_category) {
                            secondarySuffix = schema.FundingCategory.filter(
                                x =>
                                    x.category_name === view.secondary_funding_category &&
                                    parseInt(x.year_label) === year
                            ).first();
                        } else if (view.final_funding_category && view.final_funding_category === "Small Projects") {
                            secondarySuffix = schema.FundingCategory.filter(
                                x =>
                                    x.category_name === view.final_secondary_funding_category &&
                                    parseInt(x.year_label) === year
                            ).first();
                        }

                        var final_category = category && category.suffix ? category.suffix + ". " : "";
                        final_category += view.final_funding_category
                            ? view.final_funding_category
                            : view.funding_category
                            ? view.funding_category
                            : "";

                        if (view.final_funding_category) {
                            final_category +=
                                view.final_funding_category === "Small Projects" &&
                                view.final_secondary_funding_category
                                    ? " / " +
                                      (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                      view.final_secondary_funding_category
                                    : "";
                        } else {
                            final_category +=
                                view.funding_category === "Small Projects" && view.secondary_funding_category
                                    ? " / " +
                                      (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                                      view.secondary_funding_category
                                    : "";
                        }

                        return {
                            ...temp,
                            id: view.id,
                            proposal_unique_id: view.proposal_unique_id,
                            name: view.name,
                            summary: view.summary,
                            category: category,
                            final_category,
                            secondarySuffix: secondarySuffix ? secondarySuffix.suffix : null,
                            suffix: category ? category.suffix : null,
                            funding_category: view.funding_category,
                            secondary_category: view.secondary_funding_category,
                            pm_full_name: view.pm_first_name + " " + view.pm_last_name,
                            funding_total: view.funding_total,
                            final_score: tempReview.final_score,
                            final_member_comments2: tempReview.final_member_comments2,
                            evalid: temp ? temp.id : null,
                            organization: org ? org.organization_name : ""
                        };
                    });
            }
        }
    }
);

const getMembers = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["year"]),
    (schema, year) => {
        return schema.User.filter(x => x.role === "Member" && x.is_active)
            .toModelArray()
            .map(member => ({
                label: member.first_name + " " + member.last_name,
                value: member.id
            }));
    }
);

const getYear = createSelector(
    (state, ownProps) => ownProps.match.params["year"],
    (schema, year) => {
        return schema.SystemSetting.filter(x => x.year === year).first();
    }
);

const getRFPYears = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        marginBottom: 20,
        backgroundColor: "#fff"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginTop: 0
        }
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    flex: {
        flex: 1
    },
    progress: {
        width: "100%",
        textAlign: "center"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    topListText: {
        textAlign: "center",
        padding: 0
    },
    link: {
        "&:hover": {
            cursor: "pointer"
        },
        color: "#006a9d",
        textDecoration: "underline"
    },
    noh: {
        "&:hover": {
            backgroundColor: "white"
        }
    },
    wrapper: {
        maxHeight: 500,
        overflow: "auto !important"
    },
    cellWidth_27: {
        minWidth: "270px !important"
    },
    cellWidth_32: {
        minWidth: "320px !important"
    }
});

const columnDataProposalSelection = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "final_category", numeric: false, label: "Category", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "pm_full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Amount Requested", allowSort: true },
    { id: "final_score", numeric: true, label: "Staff Score", allowSort: true },
    { id: "conflict", numeric: false, label: "I have a conflict", allowSort: true },
    { id: "score", numeric: false, label: "My Score #1 (Optional)", allowSort: true },
    { id: "selected", numeric: false, label: "I Select for Presentation", allowSort: true },
    { id: "comments", numeric: false, label: "My Commments", allowSort: true }
];

const columnDataProposalEvaluation = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "final_category", numeric: false, label: "Category", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "pm_full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Amount Requested", allowSort: true },
    { id: "final_score", numeric: true, label: "Staff Score", allowSort: true },
    { id: "conflict", numeric: false, label: "I have a conflict", allowSort: true },
    { id: "score2", numeric: false, label: "My Score #2 (Optional)", allowSort: true },
    { id: "comments2", numeric: false, label: "My Commments", allowSort: true }
];

const columnDataProposalEvaluationAllocation = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "final_category", numeric: false, label: "Category", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "pm_full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Amount Requested", allowSort: true },
    { id: "final_score", numeric: true, label: "Staff Score", allowSort: true },
    { id: "conflict", numeric: false, label: "I have a conflict", allowSort: true },
    { id: "allocation", numeric: false, label: "My Allocation Amount", allowSort: true },
    { id: "comments2", numeric: false, label: "My Commments", allowSort: true }
];

class MemberDashboard extends Component {
    state = {
        defaultSorted: false,
        anchorEl: null,
        finishedCount: 2,
        page: 0,
        rowsPerPage: 100,
        order: "desc",
        orderBy: "",
        submit: false,
        submitSort: false,
        startedSubmit: false,
        filter: null,
        radioFilter: false,
        loaded: true,
        loading: false,
        staffLoading: false,
        year: this.props.match.params["year"],
        open: false,
        forPresentation: this.props.match.path === "/adm/select/presentation/:year" ? true : false,
        errorOpen: false,
        isSuccess: false,
        scoringError: false,
        numberError: false,
        panel: false,
        numberMessage: "Error!",
        disabledFields: []
    };
    Formref = React.createRef();

    componentDidMount() {
        document.title = "Proposal Evaluation - LCCMR Proposal and Grant Management System";
        const { authState, forPresentation, history, rfpYear } = this.props;

        //if proposal year evaliation isn't open redirect to dashboard
        if (
            rfpYear &&
            ((forPresentation && rfpYear && !rfpYear.evaluation1_open) ||
                (!forPresentation && rfpYear && !rfpYear.evaluation1_open))
        ) {
            history.push("/dashboard");
        }

        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    };

    componentDidUpdate = e => {
        const { history, forPresentation, rfpYear } = this.props;
        const { submitSort, finishedCount } = this.state;

        //if proposal year evaluation isn't open redirect to dashboard
        if (
            (forPresentation && rfpYear && !rfpYear.evaluation1_open) ||
            (!forPresentation && rfpYear && !rfpYear.evaluation1_open)
        ) {
            history.push("/dashboard");
        }

        if (submitSort && finishedCount === 2) {
            this.setState({ submitSort: false, startedSubmit: true });
        }
    };

    handleRequestSort = (event, property) => {
        const { rfpYear } = this.props;
        const { forPresentation } = this.state;
        const orderBy = property;
        let order = "desc";

        var use_allocation = false;
        if (rfpYear && parseInt(rfpYear.year) >= 2023) {
            use_allocation = true;
        }

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        if (this.state.startedSubmit) {
            if (order === "desc") {
                order = "asc";
            } else {
                order = "desc";
            }
        }

        // This is messy!
        // If property to sort by is editable then get current store from Form then sort and match props to that order.
        if (
            (this.Formref.current && orderBy === "conflict") ||
            orderBy === "selected" ||
            orderBy === "comments" ||
            orderBy === "comments2" ||
            orderBy === "score" ||
            orderBy === "score2" ||
            orderBy === "allocation"
        ) {
            var test = this.Formref.current.store.getState();

            var arr = [];

            Object.keys(test.values).forEach(x => {
                arr.push(test.values[x]);
            });

            var value = arr.sort(function(a, b) {
                if (orderBy === "conflict" || orderBy === "selected") {
                    if (order === "desc") return b[orderBy] - a[orderBy];
                    else return a[orderBy] - b[orderBy];
                } else if (orderBy === "score" || orderBy === "score2" || orderBy === "allocation") {
                    if (a[orderBy] === b[orderBy]) return 0;
                    else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                    else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                    if (order === "desc") return parseInt(b[orderBy]) - parseInt(a[orderBy]);
                    return parseInt(a[orderBy]) - parseInt(b[orderBy]);
                } else {
                    if (order === "desc")
                        return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                    else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
                }
            });

            this.props.values.sort(function(a, b) {
                return value.findIndex(x => x.id === a.id) - value.findIndex(z => z.id === b.id);
            });

            this.setState({ order, orderBy });
            return;
        }

        this.props.values.sort(function(a, b) {            
            var numeric = (forPresentation ? columnDataProposalSelection : use_allocation ? columnDataProposalEvaluationAllocation : columnDataProposalEvaluation).find(
                cD => cD.id === orderBy
            ).numeric;
            if (numeric) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                if (order === "desc") return parseInt(b[orderBy]) - parseInt(a[orderBy]);
                return parseInt(a[orderBy]) - parseInt(b[orderBy]);
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    filterByValue = (array, string) => {
        var keys = [
            "proposal_unique_id",
            "name",
            "summary",
            "funding_category",
            "final_category",
            "suffix",
            "organization",
            "pm_full_name",
            "funding_total",
            "final_score",
            "final_member_comments2",
            "allocation",
            "comments2"
        ];
        return array.filter(o =>
            keys.some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    actionMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    actionMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    sumbit = values => {
        const {
            authState,
            rfpYear,
            ormMemberEvaluationCreateLocalOnly,
            ormMemberEvaluationUpdateLocalOnly
        } = this.props;
        const { forPresentation, member, submit } = this.state;

        var use_allocation = false;
        if (rfpYear && parseInt(rfpYear.year) >= 2023) {
            use_allocation = true;
        }

        var create = [];
        var update = [];

        var exitEarly = false;
        var score_error = false;
        var numberMessage = "";

        const keys = Object.keys(values);
        if (submit && !forPresentation && use_allocation) {
            var total_allocation = 0;
            keys.forEach(x => {
                var obj = values[x];
                total_allocation += obj.allocation;
            });
            
            if (total_allocation > rfpYear.allocation_amount) {
                score_error = true;
                exitEarly = true;
                numberMessage = "The total amount you allocated ($" + total_allocation.toLocaleString() + ") is greater than what staff have set aside ($" + rfpYear.allocation_amount.toLocaleString() + ")";
            }
            if (total_allocation < rfpYear.allocation_amount) {
                if (!window.confirm("You have $" + (rfpYear.allocation_amount - total_allocation).toLocaleString() + " of funds remaining to allocate. Do you want to allocate these funds? Press OK to continue submitting to staff otherwise press cancel and allocate the funds.")) {
                    return;
                }
            }
        }
        keys.forEach(x => {
            const obj = values[x];

            // Members need to have conflict or score for every proposal for evaluation #2. If submit to lock and there isn't either
            // exit early and show message.
            if (submit && !forPresentation && !use_allocation) {
                if (!obj.conflict && (obj.score2 === null || isNaN(obj.score2))) {
                    exitEarly = true;
                }
            }

            if (forPresentation) {
                const temp_score = parseInt(obj.score);
                if (obj.score && (isNaN(obj.score) || temp_score < 0 || temp_score > 100)) {
                    score_error = true;
                    exitEarly = true;
                    numberMessage = `Proposal (${
                        obj.proposal_unique_id
                    }) requires a valid score between 0-100. Current score is (${obj.score ? obj.score : "Empty"})`;
                }
            } else if (!use_allocation) {
                const temp_score2 = parseInt(obj.score2);
                if ((submit && isNaN(obj.score2)) || temp_score2 < 0 || temp_score2 > 100) {
                    score_error = true;
                    exitEarly = true;
                    numberMessage = `Proposal (${
                        obj.proposal_unique_id
                    }) requires a valid score between 0-100. Current score is (${obj.score2 ? obj.score2 : "Empty"})`;
                }
            }

            if (obj.evalid) {
                update.push({
                    id: obj.evalid,
                    proposal_id: obj.id,
                    user_id: member,
                    score: obj.score,
                    score2: obj.score2,
                    allocation: obj.allocation,
                    conflict: obj.conflict,
                    selected: obj.selected,
                    comments: obj.comments,
                    comments2: obj.comments2
                });
            } else {
                create.push({
                    proposal_id: obj.id,
                    user_id: member,
                    score: obj.score,
                    score2: obj.score2,
                    allocation: obj.allocation,
                    conflict: obj.conflict,
                    selected: obj.selected,
                    comments: obj.comments,
                    comments2: obj.comments2
                });
            }
        });

        if (!exitEarly) {
            var finishedCount = 0;
            this.setState({ finishedCount: 0, submitSort: true });
            fetch("/member/evaluation/update", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                },
                body: JSON.stringify(update)
            })
                .then(result => {
                    if (!result.ok) {
                        return result.text().then(text => {
                            throw new Error(text);
                        });
                    } else {
                        return result.json();
                    }
                })
                .then(data => {
                    data.forEach(x => {
                        //proposal is fk so remove '_id' from key
                        ormMemberEvaluationUpdateLocalOnly({
                            ...x,
                            proposal: x.proposal_id
                        });
                    });
                    finishedCount += 1;
                    var isSuccess = false;
                    if (finishedCount === 2) {
                        isSuccess = true;
                    }
                    this.setState({ finishedCount: finishedCount, isSuccess });
                })
                .catch(e => {
                    finishedCount += 1;
                    this.setState({ errorOpen: true, finishedCount: finishedCount });
                });

            fetch("/member/evaluation/create", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token
                },
                body: JSON.stringify(create)
            })
                .then(result => {
                    if (!result.ok) {
                        return result.text().then(text => {
                            throw new Error(text);
                        });
                    } else {
                        return result.json();
                    }
                })
                .then(data => {
                    data.forEach(x => {
                        //proposal is fk so remove '_id' from key
                        ormMemberEvaluationCreateLocalOnly({
                            ...x,
                            proposal: x.proposal_id
                        });
                    });
                    finishedCount += 1;
                    var isSuccess = false;
                    if (finishedCount === 2) {
                        isSuccess = true;
                    }
                    this.setState({ finishedCount: finishedCount, isSuccess });
                })
                .catch(e => {
                    finishedCount += 1;
                    this.setState({ errorOpen: true, finishedCount: finishedCount });
                });

            if (submit) {
                fetch("member/evaluation/complete", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Token " + authState.user.auth_token
                    },
                    body: JSON.stringify({
                        id: member,
                        number: forPresentation ? 1 : 2,
                        year: rfpYear.id
                    })
                });
                this.setState({ submit: false });
            }
        } else {
            if (score_error) {
                this.setState({ numberError: true, numberMessage });
            } else {
                this.setState({ scoringError: true });
            }
        }
    };

    getDefaultValues = sorted => {
        const { forPresentation } = this.state;
        var defaultValues = {};

        if (forPresentation) {
            sorted.forEach(x => {
                defaultValues[x.id.toString()] = x;
            });
        } else {
            sorted.forEach(x => {
                var automatically_fund = x.name === "LCC Legacy Website" || x.name.includes("LCCMR Buffer") || (x.name.includes("Contract Agreement") && x.organization === "MN DNR") || (x.name.includes("LCCMR") && x.organization === "Legislative-Citizen Commission on Minnesota Resources")
                if (automatically_fund) {
                    x.allocation = x.funding_total;
                }

                defaultValues[x.id.toString()] = {
                    ...x,
                    comments2: !x.comments2 ? x.comments : x.comments2
                };
            });
        }
        return defaultValues;
    };

    clearInfo = (e, id, formApi) => {
        var temp;
        const { disabledFields } = this.state;

        if (e) {
            //clear out fields because conflict
            temp = formApi.getValue(id);
            temp.conflict = e;
            temp.selected = false;
            temp["score"] = null;
            temp["score2"] = null;
            temp["allocation"] = null;
            temp["comments"] = "";
            temp["comments2"] = "";
            formApi.setValue(id.toString(), temp);
            disabledFields.push(id);
        } else {
            temp = formApi.getValue(id);
            temp.conflict = e;
            formApi.setValue(id.toString(), temp);
            var index = disabledFields.indexOf(id);
            if (index !== -1) {
                disabledFields.splice(index, 1);
            }
        }
        this.setState({ disabledFields: disabledFields });
    };

    filterByRadio = radioFilter => {
        const { rfpYear } = this.props;
        const { forPresentation } = this.state;

        var use_allocation = false;
        if (rfpYear && parseInt(rfpYear.year) >= 2023) {
            use_allocation = true;
        }

        var form;
        var formValues = [];

        if (radioFilter === "proposal_category")
            this.defaultSort();

        if (radioFilter === "staff_score" || radioFilter === "categoryscore") {
            var grouped = {};
            form = this.Formref.current.store.getState();
            formValues = [];

            Object.keys(form.values).forEach(x => {
                formValues.push(form.values[x]);
            });

            for (var i = 0; i < formValues.length; i += 1) {
                if (!grouped[formValues[i].final_category]) {
                    grouped[formValues[i].final_category] = [];
                }
                grouped[formValues[i].final_category].push(formValues[i]);
            }

            for (var group in grouped) {
                grouped[group] = grouped[group].sort(function(a, b) {
                    if (radioFilter === "categoryscore") {
                        if (forPresentation) {
                            if (a.score === b.score) return 0;
                            else if (a.score === null || typeof a.score === "undefined") return 1;
                            else if (b.score === null || typeof b.score === "undefined") return -1;
                            return parseInt(b.score) - parseInt(a.score);
                        } else if (use_allocation) {
                            if (a.allocation === b.allocation) return 0;
                            else if (a.allocation === null || typeof a.allocation === "undefined") return 1;
                            else if (b.allocation === null || typeof b.allocation === "undefined") return -1;
                            return parseInt(b.allocation) - parseInt(a.allocation);
                        } else {
                            if (a.score2 === b.score2) return 0;
                            else if (a.score2 === null || typeof a.score2 === "undefined") return 1;
                            else if (b.score2 === null || typeof b.score2 === "undefined") return -1;
                            return parseInt(b.score2) - parseInt(a.score2);
                        }
                    } else {
                        if (a["final_score"] === b["final_score"]) return 0;
                        else if (a["final_score"] === null || typeof a["final_score"] === "undefined") return 1;
                        else if (b["final_score"] === null || typeof b["final_score"] === "undefined") return -1;
                        return parseInt(b.final_score) - parseInt(a.final_score);
                    }
                });
            }
            var keys = Object.keys(grouped).sort(function(a, b) {
                return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
            });

            var arr = [];

            keys.forEach(x => {
                arr = arr.concat(grouped[x]);
            });

            this.props.values.sort(function(a, b) {
                return arr.findIndex(x => x.id === a.id) - arr.findIndex(z => z.id === b.id);
            });
        }

        if (radioFilter === "score" || radioFilter === "score2" ||  radioFilter === "allocation" || radioFilter === "final_score") {
            form = this.Formref.current.store.getState();
            formValues = [];

            Object.keys(form.values).forEach(x => {
                formValues.push(form.values[x]);
            });

            formValues.sort(function(a, b) {
                if (a[radioFilter] === b[radioFilter]) return 0;
                else if (a[radioFilter] === null || typeof a[radioFilter] === "undefined") return 1;
                else if (b[radioFilter] === null || typeof b[radioFilter] === "undefined") return -1;
                return parseInt(b[radioFilter]) - parseInt(a[radioFilter]);
            });

            this.props.values.sort(function(a, b) {
                return formValues.findIndex(x => x.id === a.id) - formValues.findIndex(z => z.id === b.id);
            });
        }

        //dummy state to cause render
        this.setState({ dummy_state: "", radioFilter, orderBy: null });
    };

    countTotal = () => {
        const { values } = this.props;
        return values.length;
    };

    getUpdateTotals = () => {
        const evals = this.props.values;
        const { rfpYear } = this.props;
        const { loaded, panel, member } = this.state;

        var use_allocation = false;
        if (rfpYear && parseInt(rfpYear.year) >= 2023) {
            use_allocation = true;
        }
        
        var objKey = this.state.forPresentation ? "selected" : use_allocation ? "allocation" : "score2";

        var conflict = 0;
        var count = 0;
        var allocated_funds = 0;
        if (!panel && !member) {
            return {
                conflict: 0,
                count: 0,
                allocated_funds: 0
            };
        } else if (this.Formref && this.Formref.current) {
            const values = this.Formref.current.store.getState().values;
            const keys = Object.keys(values);

            keys.forEach(x => {
                if (values[x] && values[x].conflict) conflict += 1;

                if (this.state.forPresentation && values[x] && values[x][objKey]) {
                    count += 1;
                } else if (
                    !this.state.forPresentation &&
                    values[x] &&
                    values[x][objKey] !== "" &&
                    values[x][objKey] !== null &&
                    typeof values[x][objKey] !== "undefined"
                ) {
                    count += 1;
                    allocated_funds += values[x][objKey];
                }
            });

            return {
                count: count,
                conflict: conflict,
                allocated_funds: allocated_funds
            };
        } else if (evals.length > 0 && loaded) {
            evals.forEach(x => {
                if (x.conflict) conflict += 1;

                if (
                    (this.state.forPresentation && x[objKey]) ||
                    (!this.state.forPresentation && x[objKey] !== null &&
                        x[objKey] !== "" && typeof x[objKey] !== "undefined")
                )
                    count += 1;
                    if (!isNaN(x[objKey]))
                        allocated_funds += x[objKey];
            });

            return {
                count: count,
                conflict: conflict,
                allocated_funds: allocated_funds
            };
        } else {
            return {
                conflict: 0,
                count: 0,
                allocated_funds: 0
            };
        }
    };

    setScore = (e, id, formApi) => {
        var temp = formApi.getValue(id);
        temp.score = e === "" ? null : e;
        formApi.setValue(id.toString(), temp);
        this.setState({ dummy_state: "" });
    };

    setScore2 = (e, id, formApi) => {
        var temp = formApi.getValue(id);
        temp.score2 = e === "" ? null : e;
        formApi.setValue(id.toString(), temp);
        this.setState({ dummy_state: "" });
    };

    setAllocation = (e, id, formApi) => {
        var temp = formApi.getValue(id);
        temp.allocation = e === "" ? null : e;
        formApi.setValue(id.toString(), temp);
        this.setState({ dummy_state: "" });
    };

    selectForPresentation = (e, id, formApi) => {
        const { forPresentation } = this.state;
        var temp;
        if (forPresentation && e) {
            const obj = this.getUpdateTotals();
            if (obj.count > this.props.rfpYear.num_selections) {
                temp = formApi.getValue(id);
                temp.selected = false;
                formApi.setValue(id.toString(), temp);
                this.setState({ selectError: true });
                return;
            }
        }

        temp = formApi.getValue(id);
        temp.selected = e;
        formApi.setValue(id.toString(), temp);
        this.setState({ dummy_state: "" });
    };

    changeyear = (e) => {
        const { history, match } = this.props;
        if (match.path === "/adm/evaluation/presentation/:year")
            history.push("/adm/evaluation/presentation/" + e)
        else
            history.push("/adm/select/presentation/" + e)
    }

    handleMemberChange = e => {
        const {
            history,
            match,
            ormMemberEvaluationLoadDetailChildFilterMany,
            ormProposalReviewLoadDetailChildFilterMany
        } = this.props;
        const _this = this;

        if (!e) {
            history.push({ search: `` });
            this.setState({ member: null, panel: false });
            return;
        }

        this.setState({ panel: true });

        // add url to query string so we can use in selector in case there is multiple member evaluations in
        // the redux store.
        history.push({ search: `member=${e.toString()}` });

        this.setState({ loaded: true, member: e, loading: true, staffLoading: true, defaultSorted: true });
        ormProposalReviewLoadDetailChildFilterMany(
            {
                year: match.params["year"]
            },
            function(data) {
                _this.setState({ staffLoading: false });
            }
        );

        ormMemberEvaluationLoadDetailChildFilterMany(
            {
                year: match.params["year"],
                user: e
            },
            function(data) {
                _this.setState({ loading: false });
            }
        );
    };

    sortSubCategory = list => {
        var grouped = {};

        for (var i = 0; i < list.length; i += 1) {
            if (list[i].secondarySuffix && !grouped[list[i].secondarySuffix]) {
                grouped[list[i].secondarySuffix] = [];
            } else if (!list[i].secondarySuffix && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[list[i].secondarySuffix ? list[i].secondarySuffix : ""].push(list[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                return (a.proposal_unique_id || "").toUpperCase() < (b.proposal_unique_id || "").toUpperCase() ? -1 : 1;
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        // This sorts H by secondary category and then ID.
        if (grouped["H"] && grouped["H"].length > 1) {
            grouped["H"] = this.sortSubCategory(grouped["H"]);
        }

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        return arr;
    };

    defaultSort = () => {
        const { values } = this.props;

        var grouped = {};

        for (var i = 0; i < values.length; i += 1) {
            if (values[i].category && !grouped[values[i].category.suffix]) {
                grouped[values[i].category.suffix] = [];
            } else if (!values[i].category && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[values[i].category ? values[i].category.suffix : ""].push(values[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                return (a.proposal_unique_id || "").toUpperCase() < (b.proposal_unique_id || "").toUpperCase() ? -1 : 1;
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        if (grouped["H"] && grouped["H"].length > 1) {
            grouped["H"] = this.sortSubCategory(grouped["H"]);
        }

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        this.props.values.sort(function(a, b) {
            return arr.findIndex(x => x.id === a.id) - arr.findIndex(z => z.id === b.id);
        });

        this.setState({ defaultSorted: false });
    };

    reSort = () => {
        const { radioFilter, orderBy } = this.state;
        if (radioFilter && !orderBy) {
            this.filterByRadio(radioFilter);
        } else if (orderBy) {
            this.handleRequestSort(null, orderBy);
        } else {
            this.defaultSort();
        }
        this.setState({ submitSort: false, startedSubmit: false });
    };

    render() {
        const { classes, match, members, sync, rfpYear, values, rfpYears} = this.props;
        const {
            anchorEl,
            defaultSorted,
            forPresentation,
            page,
            rowsPerPage,
            order,
            orderBy,
            filter,
            loading,
            member,
            staffLoading,
            finishedCount,
            startedSubmit,
            disabledFields
        } = this.state;

        var use_allocation = false;
        if (rfpYear && parseInt(rfpYear.year) >= 2023) {
            use_allocation = true;
        }

        values.forEach(x => {
            var automatically_fund2 = x.name === "LCC Legacy Website" || x.name.includes("LCCMR Buffer") || (x.name.includes("Contract Agreement") && x.organization === "MN DNR") || (x.name.includes("LCCMR") && x.organization === "Legislative-Citizen Commission on Minnesota Resources")
            if (automatically_fund2) {
                x.allocation = x.funding_total;
            }
        })

        const years = rfpYears.filter(x => x.evaluation1_open).map(x => ({
            label: x.year,
            value: x.year
        }));

        var filtered;
        if (filter) {
            filtered = this.filterByValue(values, filter);
        } else {
            filtered = values;
        }

        const temp = this.getUpdateTotals();
        const total = this.countTotal();

        if (sync && sync.ready && !loading && !staffLoading && defaultSorted) {
            this.defaultSort();            
            
            var test = [];
            values.forEach(function(v) {
                if (v.conflict)
                    test.push(v.id)
            })
            this.setState({disabledFields: test})
        }

        if (
            finishedCount === 2 &&
            sync &&
            sync.ready &&
            !loading &&
            !staffLoading &&
            startedSubmit &&
            this.Formref.current
        ) {
            this.reSort();
        }

        const read_only = rfpYear && rfpYear.recommendations_open;

        var prevent_submit = false;
        if (this.formApi) {
            var form_errors = this.formApi.getError();
            for (var key in form_errors) {
                if (form_errors[key]["allocation"])
                    prevent_submit = true;
            }
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title={"Staff Data Entry for Proposal Evaluation #" + (forPresentation ? 1 : 2)} />
                    <Grid item xs={12}>
                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Typography>Current RFP Year: {rfpYear.year}</Typography>
                                    <Select
                                        field="rfpy"
                                        name="rfpy"
                                        style={{width:300}}
                                        eventHandle={this.changeyear}
                                        fullWidth
                                        options={years}
                                        label="Change RFP Year"
                                    /><br/>
                                    <Select
                                        field="member"
                                        name="member"
                                        style={{width:300}}
                                        eventHandle={this.handleMemberChange}
                                        fullWidth
                                        options={[{ label: "-- Empty --", value: null }, ...members]}
                                        label="Select Member To Enter Data on Behalf:"
                                    />
                                </form>
                            )}
                        </Form>
                        <div style={{ float: "right", marginTop:-160 }}>
                            <Button variant="contained" color="primary" onClick={this.actionMenuOpen}>
                                <BuildIcon />
                                &nbsp;&nbsp;&nbsp;Select Action
                            </Button>
                        </div>
                        <Menu
                            className={classes.list}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.actionMenuClose}>
                            <MenuItem
                                onClick={() =>
                                    window.open(
                                        `/adm/reports/mef${forPresentation ? 1 : 2}/?year=${
                                            match.params["year"]
                                        }&member=${member}`
                                    )
                                }
                                disabled={!member}
                                style={{
                                    borderBottom: "1px solid #666666"
                                }}>
                                <ListItemIcon>
                                    <GetApp />
                                </ListItemIcon>
                                <ListItemText
                                    primary={`Download My Evaluation #${
                                        forPresentation ? "1" : use_allocation ? "2" : "2"
                                    }`}
                                />
                            </MenuItem>
                        </Menu>
                    </Grid>
                    {(finishedCount !== 2 || (loading && staffLoading)) && (
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <CircularProgress size={100} aria-label="Loading Icon" thickness={1.5} />
                            <Typography>Loading...</Typography>
                        </Grid>
                    )}
                    {finishedCount !== 2 && !loading && !staffLoading && defaultSorted && (
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <CircularProgress size={100} aria-label="Loading Icon" thickness={1.5} />
                            <Typography>Loading...</Typography>
                        </Grid>
                    )}
                    {finishedCount === 2 && !loading && !staffLoading && (
                        <>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>Review Summary</b>
                                </Typography>
                                <Typography>
                                    You have {forPresentation ? "selected" : use_allocation ? "allocated funds for" : "scored"}
                                    &nbsp;
                                    <b>{temp.count}</b> out of{" "}
                                    <b>{forPresentation ? (rfpYear ? rfpYear.num_selections : "") : total}</b>
                                    &nbsp;
                                    {forPresentation
                                        ? "that you wish to present to the commission"
                                        : "proposals that presented"}
                                </Typography>
                                <Typography>
                                    You have indicated you have a potential conflict of interest on{" "}
                                    <b>{temp.conflict}</b> out of <b>{total}</b> proposals.
                                </Typography>
                                {!forPresentation && use_allocation && (
                                    <>
                                    <Typography>
                                       You have allocated{" "}
                                        <b style={{color: temp.allocated_funds === rfpYear.allocation_amount ? "green" : temp.allocated_funds < rfpYear.allocation_amount ? "gold" : temp.allocated_funds > rfpYear.allocation_amount ? "red" : "black"}}>${temp.allocated_funds.toLocaleString()}</b> out of <b>${rfpYear.allocation_amount.toLocaleString()}</b>.
                                        {temp.allocated_funds > rfpYear.allocation_amount && <b>&nbsp;Error: You have allocated more funds than are available.</b>}
                                    </Typography>
                                    <Typography>
                                        You have{" "}
                                        <b>${(rfpYear.allocation_amount - temp.allocated_funds).toLocaleString()}</b> remaining to allocate.
                                    </Typography>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <ExpansionPanel
                                    onChange={(obj, e) => {
                                        this.setState({ panel: e });
                                    }}
                                    expanded={this.state.panel}
                                    disabled={member ? false : true}>
                                    <ExpansionPanelSummary
                                        className={classes.expansionSummaryRoot}
                                        expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h3" style={{ width: "100%" }}>
                                            <PersonIcon className={classes.expansionIcon} />
                                            Member Evaluation #
                                            {forPresentation
                                                ? "1: Scoring and Selecting Proposal for Presentation"
                                                : "2: Post-Presentation Evaluation"}
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container>
                                            <Grid item xs={10}>
                                                <Form
                                                    defaultValues={{ filters: "proposal_category" }}
                                                    dontValidateOnMount={true}
                                                    validateOnSubmit={true}>
                                                    {formApi => (
                                                        <form
                                                            style={{ display: "inline-flex" }}
                                                            onSubmit={formApi.submitForm}>
                                                            <RadioGroup
                                                                field="filters"
                                                                name="filters"
                                                                eventHandle={val => this.filterByRadio(val)}
                                                                fullWidth
                                                                options={[
                                                                    {
                                                                        label: "Proposal ID (Low to High) By Category",
                                                                        value: "proposal_category"
                                                                    },
                                                                    {
                                                                        label: "Staff Score (High to Low) By Category",
                                                                        value: "staff_score"
                                                                    },
                                                                    {
                                                                        label: (!forPresentation && use_allocation) ? "My Allocations (High to Low) By Category" : "My Score (High to Low) By Category",
                                                                        value: "categoryscore"
                                                                    }
                                                                ]}
                                                                label="Sort"
                                                                alignment={true}
                                                            />
                                                        </form>
                                                    )}
                                                </Form>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                                    {formApi => (
                                                        <form
                                                            style={{ float: "right", marginTop: 10 }}
                                                            onSubmit={formApi.submitForm}>
                                                            <TextField
                                                                eventHandle={val => this.setState({ filter: val })}
                                                                field="filter"
                                                                label="Search List"
                                                                filterInput
                                                            />
                                                        </form>
                                                    )}
                                                </Form>
                                            </Grid>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails>
                                        <Form
                                            validateOnSubmit={true}
                                            validateError={this.errorValidator}
                                            ref={this.Formref}
                                            getApi={el => (this.formApi = el)}
                                            defaultValues={this.getDefaultValues(values)}
                                            dontValidateOnMount={true}
                                            onSubmit={(values, e, formApi) => this.sumbit(values, e, formApi)}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm} style={{ width: "calc(100%)" }}>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={12}>
                                                            <Table className={classes.table}>
                                                                <EnhancedTableHead
                                                                    stickyHeader
                                                                    columnData={forPresentation ? columnDataProposalSelection : use_allocation ? columnDataProposalEvaluationAllocation : columnDataProposalEvaluation}
                                                                    order={order}
                                                                    orderBy={orderBy}
                                                                    onRequestSort={this.handleRequestSort}
                                                                />
                                                                <TableBody>
                                                                    {filtered
                                                                        .slice(
                                                                            page * rowsPerPage,
                                                                            page * rowsPerPage + rowsPerPage
                                                                        )
                                                                        .map((n, index) => {
                                                                            var automatically_fund = n.name === "LCC Legacy Website" || n.name.includes("LCCMR Buffer") || (n.name.includes("Contract Agreement") && n.organization === "MN DNR") || (n.name.includes("LCCMR") && n.organization === "Legislative-Citizen Commission on Minnesota Resources")
                                                                            return (
                                                                                <TableRow key={n.id + "term"}>
                                                                                    <CustomTableCell
                                                                                        className={classes.nowrap}>
                                                                                        <Tooltip title="View Proposal">
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className={
                                                                                                    classes.deleteWidth
                                                                                                }
                                                                                                component={Link}
                                                                                                aria-label="View Proposal"
                                                                                                onClick={e => {
                                                                                                    e.preventDefault();
                                                                                                    window.open(
                                                                                                        "#/proposal/" +
                                                                                                            n.id
                                                                                                    );
                                                                                                }}>
                                                                                                <Visibility color="primary" />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell>
                                                                                        <Tooltip title="View Proposal Word Document">
                                                                                            <Typography
                                                                                                className={
                                                                                                    classes.link
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    window.open(
                                                                                                        "/reports/proposal/" +
                                                                                                            n.id
                                                                                                    )
                                                                                                }>
                                                                                                {
                                                                                                    n.proposal_unique_id
                                                                                                }
                                                                                            </Typography>
                                                                                        </Tooltip>
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell
                                                                                        className={
                                                                                            classes.cellWidth_32
                                                                                        }>
                                                                                        {n.name}
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell
                                                                                        className={
                                                                                            classes.cellWidth_27
                                                                                        }>
                                                                                        {n.final_category}
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell>
                                                                                        {n.organization}
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell>
                                                                                        {n.pm_full_name}
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell>
                                                                                        $
                                                                                        {n.funding_total
                                                                                            ? n.funding_total.toLocaleString()
                                                                                            : 0}
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell>
                                                                                        {n.final_score}
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell>
                                                                                        <Checkbox
                                                                                            aria-label="Conflict CheckBox"
                                                                                            disabled={read_only || automatically_fund}
                                                                                            field={`${n.id}.conflict`}
                                                                                            eventHandle={e =>
                                                                                                this.clearInfo(
                                                                                                    e,
                                                                                                    n.id,
                                                                                                    formApi
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell>
                                                                                        {forPresentation && (
                                                                                            <TextField
                                                                                                aria-label="Score #1 Textbox"
                                                                                                eventHandle={e =>
                                                                                                    this.setScore(
                                                                                                        e,
                                                                                                        n.id,
                                                                                                        formApi
                                                                                                    )
                                                                                                }
                                                                                                style={{
                                                                                                    width: 50
                                                                                                }}
                                                                                                field={`${n.id}.score`}
                                                                                                disabled={read_only || disabledFields.includes(n.id) ||
                                                                                                    (formApi.values[n.id] ? formApi.values[n.id].conflict : false)
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                        {!forPresentation && use_allocation && (
                                                                                            <TextField
                                                                                                aria-label="Allocation Textbox"
                                                                                                useTextFormat
                                                                                                eventHandle={e => {
                                                                                                    if (e)
                                                                                                        e = parseInt(e);
                                                                                                    if (e && (n.final_category.includes("Small Projects")))
                                                                                                        e = n.funding_total;
                                                                                                    if (e && e < 250000 && !n.final_category.includes("Small Projects"))
                                                                                                        this.formApi.setError(`${n.id}.allocation`, "Error: Allocated funding cannot be less than $250,000");
                                                                                                    else if (e > n.funding_total)
                                                                                                        this.formApi.setError(`${n.id}.allocation`, "Error: Amount entered for proposal " + n.proposal_unique_id + " is greater than amount requested. Please revise.");
                                                                                                    //else if (e % 1000 !== 0)
                                                                                                        //this.formApi.setError(`${n.id}.allocation`, "Round to the nearest 1000th");
                                                                                                    else {
                                                                                                        this.formApi.setError(`${n.id}.allocation`, null);
                                                                                                        this.setAllocation(
                                                                                                            e,
                                                                                                            n.id,
                                                                                                            formApi
                                                                                                        )
                                                                                                    }
                                                                                                }}
                                                                                                onBlur={e => {
                                                                                                    if (e.currentTarget.value && e.currentTarget.value !== "") {
                                                                                                        var val_blur = parseInt(e.currentTarget.value.replace("$", "").replace(/,/g, ''));
                                                                                                        var round_value = Math.round(val_blur/1000) * 1000;
                                                                                                        if (round_value !== 0) {
                                                                                                            this.formApi.setValue(`${n.id}.allocation`, round_value);
                                                                                                            this.setAllocation(
                                                                                                                round_value,
                                                                                                                n.id,
                                                                                                                formApi
                                                                                                            )
                                                                                                        }
                                                                                                        else {
                                                                                                            this.formApi.setValue(`${n.id}.allocation`, "");
                                                                                                            this.setAllocation(
                                                                                                                "",
                                                                                                                n.id,
                                                                                                                formApi
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                }}
                                                                                                style={{
                                                                                                    width: 120
                                                                                                }}
                                                                                                field={`${n.id}.allocation`}
                                                                                                disabled={read_only || disabledFields.includes(n.id) || automatically_fund ||
                                                                                                    (formApi.values[n.id] ? formApi.values[n.id].conflict : false)
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                        {!forPresentation && !use_allocation && (
                                                                                            <TextField
                                                                                                aria-label="Score #2 Textbox"
                                                                                                eventHandle={e =>
                                                                                                    this.setScore2(
                                                                                                        e,
                                                                                                        n.id,
                                                                                                        formApi
                                                                                                    )
                                                                                                }
                                                                                                style={{
                                                                                                    width: 50
                                                                                                }}
                                                                                                field={`${n.id}.score2`}
                                                                                                disabled={read_only || disabledFields.includes(n.id) ||
                                                                                                    (formApi.values[n.id] ? formApi.values[n.id].conflict : false)
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                    </CustomTableCell>
                                                                                    {forPresentation && (
                                                                                        <CustomTableCell>
                                                                                            <Tooltip
                                                                                                title={`Select Proposal ${n.proposal_unique_id} for presentation`}>
                                                                                                <Checkbox
                                                                                                    aria-label="Selected for presentation Textbox"
                                                                                                    field={`${n.id}.selected`}
                                                                                                    eventHandle={e =>
                                                                                                        this.selectForPresentation(
                                                                                                            e,
                                                                                                            n.id,
                                                                                                            formApi
                                                                                                        )
                                                                                                    }
                                                                                                    disabled={read_only || disabledFields.includes(n.id) ||
                                                                                                        (formApi.values[n.id] ? formApi.values[n.id].conflict : false)
                                                                                                    }
                                                                                                />
                                                                                            </Tooltip>
                                                                                        </CustomTableCell>
                                                                                    )}
                                                                                    <CustomTableCell>
                                                                                        <TextField
                                                                                            aria-label={`Comments ${
                                                                                                forPresentation
                                                                                                    ? 1
                                                                                                    : 2
                                                                                            } TextBox`}
                                                                                            field={`${
                                                                                                n.id
                                                                                            }.comments${
                                                                                                forPresentation
                                                                                                    ? ""
                                                                                                    : 2
                                                                                            }`}
                                                                                            multiline
                                                                                            style={{ width: 200 }}
                                                                                            disabled={read_only || disabledFields.includes(n.id) ||
                                                                                                (formApi.values[n.id] ? formApi.values[n.id].conflict : false)
                                                                                            }
                                                                                        />
                                                                                    </CustomTableCell>
                                                                                </TableRow>
                                                                            );
                                                                        })}
                                                                    {filtered.length < 1 && (
                                                                        <TableRow>
                                                                            <CustomTableCell
                                                                                colSpan={11}
                                                                                className={classes.centerAlign}>
                                                                                <caption style={{display:"inline"}}>No Proposals Found</caption>
                                                                            </CustomTableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={12}>
                                                            {filtered.length > 25 && (
                                                                <Table>
                                                                    <TableFooter>
                                                                        <TableRow>
                                                                            <TablePagination
                                                                                colSpan={11}
                                                                                count={filtered.length}
                                                                                rowsPerPage={rowsPerPage}
                                                                                page={page}
                                                                                onChangePage={this.handleChangePage}
                                                                                onChangeRowsPerPage={
                                                                                    this.handleChangeRowsPerPage
                                                                                }
                                                                                ActionsComponent={
                                                                                    TablePaginationActionsWrapped
                                                                                }
                                                                            />
                                                                        </TableRow>
                                                                    </TableFooter>
                                                                </Table>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={12} style={{ textAlign: "center", marginTop:8 }}>
                                                            {prevent_submit ? (
                                                                <Tooltip title="Fix All Errors Before Saving">
                                                                    <div>
                                                                        <Button
                                                                            name="submit"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            style={{ marginRight: 5 }}
                                                                            type="submit"
                                                                            disabled={prevent_submit}
                                                                            onClick={e => {
                                                                                this.setState({ submit: false });
                                                                            }}>
                                                                            Save
                                                                        </Button>
                                                                        <Button
                                                                            name="submitstaff"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            style={{ marginLeft: 5 }}
                                                                            type="submit"
                                                                            disabled={prevent_submit}
                                                                            onClick={e => {
                                                                                this.setState({ submit: true });
                                                                            }}>
                                                                            Save and Submit to Staff
                                                                        </Button>
                                                                    </div>
                                                                </Tooltip>
                                                            ) : (
                                                                <>
                                                                <Button
                                                                    name="submit"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    style={{ marginRight: 5 }}
                                                                    type="submit"
                                                                    disabled={prevent_submit}
                                                                    onClick={e => {
                                                                        this.setState({ submit: false });
                                                                    }}>
                                                                    Save
                                                                </Button>
                                                                <Button
                                                                    name="submitstaff"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    style={{ marginLeft: 5 }}
                                                                    type="submit"
                                                                    disabled={prevent_submit}
                                                                    onClick={e => {
                                                                        this.setState({ submit: true });
                                                                    }}>
                                                                    Save and Submit to Staff
                                                                </Button>
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            )}
                                        </Form>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Snackbar
                    handleSnackbarClose={() => this.setState({ isSuccess: false })}
                    isSuccess
                    snackbarOpen={this.state.isSuccess}
                />
                <Snackbar
                    handleSnackbarClose={() => this.setState({ selectError: false })}
                    snackbarOpen={this.state.selectError}
                    message={"You are trying to select too many for presentation!"}
                />
                <Snackbar
                    handleSnackbarClose={() => this.setState({ scoringError: false })}
                    snackbarOpen={this.state.scoringError}
                    message={"You need to mark conflict or score every proposal!"}
                />
                <Snackbar
                    handleSnackbarClose={() => this.setState({ numberError: false })}
                    snackbarOpen={this.state.numberError}
                    message={this.state.numberMessage}
                />
                <Snackbar
                    handleSnackbarClose={() => this.setState({ errorOpen: false })}
                    snackbarOpen={this.state.errorOpen}
                    message={"Error!"}
                />
            </AppContainer>
        );
    }
}

MemberDashboard = connect(
    (state, ownProps) => ({
        sync: state.sync,
        authState: state.auth,
        members: getMembers(state, ownProps),
        values: getProposalForPresentation(state, ownProps),
        rfpYear: getYear(state, ownProps),
        rfpYears: getRFPYears(state)
    }),
    {
        ...MemberEvaluation.actions,
        ...ProposalReview.actions
    }
)(MemberDashboard);

export default withStyles(styles)(withRouter(MemberDashboard));
