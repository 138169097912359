import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Visibility from "@material-ui/icons/Visibility";
import { withStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "../../common/TextField";
import Checkbox from "../../common/Checkbox";
import CustomTableCell from "../../common/TableCell";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import { StatusUpdateTab } from "../../home/models";
import MonthPicker from "../../common/MonthPicker";
import RadioGroup from "../../common/RadioGroup";
import { createSelector } from "../../common/orm";
import { AmendmentLog } from "../models";

const getStatusUpdateTab = StatusUpdateTab.selectMessage(7);
const getAmendments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        var wp = session.FinalWorkPlan.filter(f => f.proposal_record_id === id).toModelArray()[0];
        return session.AmendmentLog.filter(x => x.work_plan === wp.id)
            .orderBy("id", "desc")
            .toModelArray();
    }
);

const getCollaborators = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalCollaborator.filter({ proposal: id }).toRefArray();
    }
);

const getActivities = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalActivity.filter({ proposal: id }).toModelArray()
            .map(activity => ({
                ...activity.ref,
                milestones: activity.final_milestone_activities.all().toModelArray()
            }));
    }
);

const getPersonnel = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalPersonnel.filter({ proposal: id }).toModelArray();
    }
);

const getContracts = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalContract.filter({ proposal: id }).toModelArray();
    }
);

const getExpenditures = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalExpenditure.filter({ proposal: id }).toModelArray();
    }
);

const getAcquisitions = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalAcquisition.filter({ proposal: id }).toModelArray();
    }
);

const getTravels = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalTravel.filter({ proposal: id }).toModelArray();
    }
);

const getPrintings = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalPrinting.filter({ proposal: id }).toModelArray();
    }
);

const getExpenses = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalExpense.filter({ proposal: id }).toModelArray();
    }
);

const getFunds = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalNonENRTFFund.filter({ proposal: id }).toModelArray();
    }
);

const getParcels = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalParcel.filter({ proposal: id }).toRefArray();
    }
);

const getAttachments = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (session, id) => {
        return session.FinalProposalAttachment.filter({ proposal: id }).toModelArray();
    }
);

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        backgroundColor: "#fff"
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
});


class AmendmentTab extends Component {
    typingTimer = [];
    state = {
        order: "desc",
        orderBy: "amendment_id",
        OrgDialogOpen: false,
        req: null,
        formkey: null,
    };

    componentDidMount() {
        const { ormAmendmentLogUpdate, amendments, ormParentUpdateLocalOnly, plan } = this.props;
        document.title = "Work Plan: Amendments - LCCMR";
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            if (!plan.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormParentUpdateLocalOnly({
                    id: plan.id,
                    fromReview: false
                });
            }
        }, 1);

        var in_progress_amendment = amendments.filter(su => !su.date_accepted);
        if (in_progress_amendment.length > 0) {
            
            var detected_changes = this.detected_html();
            var string = "<ul>"; 
            detected_changes.forEach(dc => string += "<li>" + dc + "</li>"); 
            string += "</ul>";
            if (string.length > 0) {
                ormAmendmentLogUpdate({
                    id: in_progress_amendment[0].id,
                    changes_detected: string
                });
            }
        }
    }

    updateAmendment = (field_value, amendment_id) => {
        const { ormAmendmentLogUpdate } = this.props;
        if (this.typingTimer["justification"]) clearTimeout(this.typingTimer["justification"]);
        this.typingTimer["justification"] = setTimeout(function() {
            ormAmendmentLogUpdate({
                id: amendment_id,
                justification: field_value
            });
        }, 500);
    };

    updateAmendment2 = (field_value, field_name, amendment_id) => {
        const { ormAmendmentLogUpdate } = this.props;
        ormAmendmentLogUpdate({
            id: amendment_id,
            [field_name]: field_value
        });
    };

    updateManagerRequest = (field_value, field_name, amendment_id) => {
        const { ormManagerRequestUpdate } = this.props;
        ormManagerRequestUpdate({
            id: amendment_id,
            [field_name]: field_value
        });
    };

    updateCompletionRequest = (field_value, field_name, amendment_id) => {
        const { ormCompletionRequestUpdate } = this.props;
        ormCompletionRequestUpdate({
            id: amendment_id,
            [field_name]: field_value
        });
    };

    handleRequestSort = (event, property) => {
        const { authState } = this.props;
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }


        var columnData = [
            { id: "amendment_id", label: "Amendment ID", allowSort: true, numeric: true },
            { id: "request_type", numeric: false, label: "Request Type", allowSort: true },
            { id: "detected_changes", numeric: false, label: "Changes made on the following pages" },
            { id: "justification", numeric: false, label: "Explanation & Justification for Amendment Request (30 word minimum and word limit 75)", allowSort: true },
            { id: "date_submitted", numeric: false, label: "Date Submitted", allowSort: true, date: true },
            { id: "date_accepted", numeric: false, label: "Approved?", allowSort: true, date: true  },
            { id: "date_accepted", numeric: false, label: "Date of LCCMR Action", allowSort: true, date: true },
        ];
        if (authState && authState.user && authState.user.role === "Staff") {
            columnData = [
                { id: "amendment_id", label: "Amendment ID", allowSort: true, numeric: true },
                { id: "request_type", numeric: false, label: "Request Type", allowSort: true },
                { id: "detected_changes", numeric: false, label: "Changes made on the following pages" },
                { id: "justification", numeric: false, label: "Explanation & Justification for Amendment Request (30 word minimum and word limit 75)", allowSort: true },
                { id: "date_submitted", numeric: false, label: "Date Submitted", allowSort: true, date: true },
                { id: "staff_reviewed", numeric: false, label: "Reviewed and Approved By Staff", allowSort: true, bool: true },
                { id: "date_accepted", numeric: false, label: "Date of LCCMR Action", allowSort: true, date: true },
            ];
        }

        this.props.amendments.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;
            if (date) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined" || a[orderBy] === "-") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined" || b[orderBy] === "-") return -1;
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (numeric) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            }  else {
                if (order === "desc") return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    detected_html = () => {
        const { plan, collaborators, activities, personnel, contracts, expenditures, acquisitions, travels, printings, expenses, funds, parcels, attachments } = this.props;
        var detected_changes = []
        if (plan.detected_changes) {
            plan.detected_changes.forEach(dc => {
                if (!detected_changes.includes(dc.tab)) 
                    detected_changes.push(dc.tab)
            });
        }
        collaborators.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        activities.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
            a.milestones.forEach(b => {
                if (b.detected_changes) {
                    b.detected_changes.forEach(dc => {
                        if (!detected_changes.includes(dc.tab)) 
                            detected_changes.push(dc.tab)
                    });
                }
            })
        })
        personnel.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        contracts.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        expenditures.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        acquisitions.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        travels.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        printings.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        expenses.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        funds.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        attachments.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        parcels.forEach(a => {
            if (a.detected_changes) {
                a.detected_changes.forEach(dc => {
                    if (!detected_changes.includes(dc.tab)) 
                        detected_changes.push(dc.tab)
                });
            }
        })
        return detected_changes;
    }

    render() {
        const { authState, classes, ActionMenu, amendments, is_read_only, plan, statusUpdateTab, manager_requests, completion_requests, ormParentUpdateLocalOnly } = this.props;
        const { order, orderBy, OrgDialogOpen, req, formkey } = this.state;
        
        var in_progress_amendment = amendments.filter(su => !su.date_accepted);

        var detected_changes = this.detected_html();

        var columnData = [
            { id: "amendment_id", label: "Amendment ID", allowSort: true, numeric: true },
            { id: "request_type", numeric: false, label: "Request Type", allowSort: true },
            { id: "detected_changes", numeric: false, label: "Changes made on the following pages" },
            { id: "justification", numeric: false, label: "Explanation & Justification for Amendment Request (30 word minimum and word limit 75)", allowSort: true },
            { id: "date_submitted", numeric: false, label: "Date Submitted", allowSort: true, date: true },
            { id: "date_accepted", numeric: false, label: "Approved?", allowSort: true, date: true  },
            { id: "date_accepted", numeric: false, label: "Date of LCCMR Action", allowSort: true, date: true },
        ];
        var is_staff = false;
        if (authState && authState.user && authState.user.role === "Staff") {
            is_staff = true;
            columnData = [
                { id: "amendment_id", label: "Amendment ID", allowSort: true, numeric: true },
                { id: "request_type", numeric: false, label: "Request Type", allowSort: true },
                { id: "detected_changes", numeric: false, label: "Changes made on the following pages" },
                { id: "justification", numeric: false, label: "Explanation & Justification for Amendment Request (30 word minimum and word limit 75)", allowSort: true },
                { id: "date_submitted", numeric: false, label: "Date Submitted", allowSort: true, date: true },
                { id: "staff_reviewed", numeric: false, label: "Reviewed and Approved By Staff", allowSort: true, bool: true, aLabel:"review_staff" },
                { id: "date_accepted", numeric: false, label: "Date of LCCMR Action", allowSort: true, date: true },
            ];
        }
        var is_member = false;
        if (authState && authState.user && (authState.user.is_read_only || authState.user.role === "Member")) {
            is_member = true;
        }

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12} xl={10}>
                    <Typography variant="titleAction">Amendments</Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12} xl={10}>
                    <div style={{ border: "1px solid darkgrey", padding: "10px" }}>
                        <Typography gutterBottom>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: statusUpdateTab.tab_help_texts.find(tht => tht.label === "Amendments Instructions").text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Table className={classes.table}>
                        <EnhancedTableHead
                            columnData={columnData}
                            stickyHeader
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                        />
                        <TableBody>
                            {!is_member && manager_requests.map(n => {
                                return (
                                    <TableRow>
                                        <CustomTableCell>-</CustomTableCell>
                                        <CustomTableCell>Project Manager</CustomTableCell>
                                        <CustomTableCell>
                                            Previous Manager: {plan.pm_first_name} {plan.pm_last_name} ({plan.pm_email})<br/>
                                            New Manager: {n.first_name} {n.last_name} ({n.email})
                                        </CustomTableCell>
                                        <CustomTableCell>{n.why}</CustomTableCell>
                                        <CustomTableCell>{n.date_submitted_label}</CustomTableCell>
                                        {is_staff && (
                                            <CustomTableCell>
                                                <Form
                                                    dontValidateOnMount={true}
                                                    validateOnSubmit={true}
                                                    defaultValues={n}>
                                                    {formApi => (
                                                        <form onSubmit={formApi.submitForm}>
                                                            <Checkbox
                                                                field="staff_reviewed"
                                                                aLabel="review_staff"
                                                                aria-label="Reviewed and Approved By Staff"
                                                                disabled={n.date_accepted ? true : false}
                                                                fullWidth
                                                                eventHandle={(field_value, e, field_name) => {
                                                                    this.updateManagerRequest(field_value, field_name, n.id);
                                                                }}
                                                            />
                                                        </form>
                                                    )}
                                                </Form>
                                            </CustomTableCell>
                                        )}
                                        {!is_staff && (
                                            <CustomTableCell></CustomTableCell>
                                        )}
                                        <CustomTableCell></CustomTableCell>
                                    </TableRow>
                                )
                            })}
                            {!is_member && completion_requests.map(n => {
                                var d_1, d_2;
                                if (plan.timeline_restore) {
                                    d_1 = new Date(plan.timeline_restore + "T12:00:00")
                                    d_2 = new Date(n.timeline + "T12:00:00")
                                } else {
                                    d_1 = new Date(plan.timeline + "T12:00:00")
                                    d_2 = new Date(n.timeline + "T12:00:00")
                                }
                                return (
                                    <TableRow>
                                        <CustomTableCell>-</CustomTableCell>
                                        <CustomTableCell>Completion Date</CustomTableCell>
                                        <CustomTableCell>
                                            Previous Completion Date: {d_1.toLocaleDateString()} New Completion Date: {d_2.toLocaleDateString()}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {(n.legislative_amendment || n.q1) && (
                                                <Tooltip title="View Completion Date Request">
                                                    <Button
                                                        color="primary"
                                                        className={classes.deleteWidth}
                                                        aria-label="View Completion Date Request"
                                                        onClick={() => {
                                                            this.setState({OrgDialogOpen: true, req: n,  formkey: window.performance.now()})
                                                        }}>
                                                        <Visibility color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            {!n.legislative_amendment && (
                                                n.why
                                            )}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.date_submitted_label}</CustomTableCell>
                                        {is_staff && (
                                            <CustomTableCell>
                                                <Form
                                                    dontValidateOnMount={true}
                                                    validateOnSubmit={true}
                                                    defaultValues={n}>
                                                    {formApi => (
                                                        <form onSubmit={formApi.submitForm}>
                                                            <Checkbox
                                                                field="staff_reviewed"
                                                                aLabel="review_staff"
                                                                aria-label="Reviewed and Approved By Staff"
                                                                disabled={n.date_staff_approved ? true : false}
                                                                fullWidth
                                                                eventHandle={(field_value, e, field_name) => {
                                                                    this.updateCompletionRequest(field_value, field_name, n.id);
                                                                }}
                                                            />
                                                        </form>
                                                    )}
                                                </Form>
                                                {n.status === "Approved" && "Staff Approved; Pending Legislative Action"}
                                            </CustomTableCell>
                                        )}
                                        {!is_staff && (
                                            <CustomTableCell>{n.status === "Approved" && "Staff Approved; Pending Legislative Action"}</CustomTableCell>
                                        )}
                                        <CustomTableCell>{n.date_staff_approved_label}</CustomTableCell>
                                    </TableRow>
                                )
                            })}
                            {amendments.map(n => {
                                return (
                                    <TableRow key={n.id}>
                                        {((plan.status === "Amendment In Progress" || plan.status === "Amendment Revisions Needed") && !n.date_accepted && (is_staff || is_member)) ? (
                                            <>
                                            <CustomTableCell>{n.amendment_id}</CustomTableCell>
                                            <CustomTableCell colSpan={columnData.length - 1} className={classes.centerAlign}>This amendment is in progress waiting to be submitted by the project manager.</CustomTableCell>
                                            </>
                                        ) : 
                                        ((plan.status === "Amendment Submitted" || plan.status === "Amendment Revisions Submitted") && !n.date_accepted && is_member) ? (
                                            <>
                                            <CustomTableCell>{n.amendment_id}</CustomTableCell>
                                            <CustomTableCell colSpan={columnData.length - 1} className={classes.centerAlign}>This amendment or revisions to an amendment has been submitted and is pending review by LCCMR staff.</CustomTableCell>
                                            </>
                                        ) : (
                                            <>
                                            <CustomTableCell>{n.amendment_id}</CustomTableCell>
                                            <CustomTableCell>{n.request_type}</CustomTableCell>
                                            <CustomTableCell>
                                                {n.date_accepted ? (
                                                    <div dangerouslySetInnerHTML={{ __html: n.changes_detected }} />
                                                ) : (
                                                    <div>{detected_changes.length > 0 ? (
                                                        <ul>{detected_changes.map(dc => <li>{dc}</li>)}</ul>
                                                    ) : <><br/>None Detected</>}
                                                    </div>
                                                )}
                                            </CustomTableCell>
                                            <CustomTableCell>{n.date_accepted ? n.justification : (
                                                <Form
                                                    dontValidateOnMount={true}
                                                    validateOnSubmit={true}
                                                    defaultValues={in_progress_amendment[0]}>
                                                    {formApi => (
                                                        <form onSubmit={formApi.submitForm}>
                                                            <TextField
                                                                field="justification"
                                                                checkFocus={plan}
                                                                focusRemoval={ormParentUpdateLocalOnly}
                                                                maxWords="75"
                                                                multiline
                                                                aria-label="Explanation & Justification for Amendment Request (30 word minimum and word limit 75)"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                eventHandle={(field_value) => {
                                                                    this.updateAmendment(field_value, in_progress_amendment[0].id);
                                                                }}
                                                            />
                                                        </form>
                                                    )}
                                                </Form>
                                            )}</CustomTableCell>
                                            <CustomTableCell>{n.date_submitted_label}</CustomTableCell>
                                            {is_staff && (
                                                <CustomTableCell>
                                                    <Form
                                                        dontValidateOnMount={true}
                                                        validateOnSubmit={true}
                                                        defaultValues={n}>
                                                        {formApi => (
                                                            <form onSubmit={formApi.submitForm}>
                                                                <Checkbox
                                                                    field="staff_reviewed"
                                                                    aLabel="review_staff"
                                                                    aria-label="Reviewed and Approved By Staff"
                                                                    disabled={n.date_accepted ? true : false}
                                                                    fullWidth
                                                                    eventHandle={(field_value, e, field_name) => {
                                                                        this.updateAmendment2(field_value, field_name, n.id);
                                                                    }}
                                                                />
                                                            </form>
                                                        )}
                                                    </Form>
                                                </CustomTableCell>
                                            )}
                                            {!is_staff && (
                                                <CustomTableCell>{n.date_accepted && "Yes"}</CustomTableCell>
                                            )}
                                            <CustomTableCell>{n.date_accepted_label}</CustomTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                );
                            })}
                            {amendments.length < 1 && manager_requests.length < 1 && completion_requests.length < 1 && (
                                <TableRow>
                                    <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Amendments Found</caption>
                                    </CustomTableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                <CompletionDialog
                    open={OrgDialogOpen}
                    req={req}
                    returnDialog={() => this.setState({ OrgDialogOpen: false })}
                    classes={classes}
                    formkey={formkey}
                />
            </Grid>
        );
    }
}

class CompletionDialog extends Component {
    render() {
        const { classes, open, returnDialog, formkey, req } = this.props;

        return (
            <Dialog role="main" aria-label="Completion Date Request" open={open}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        Completion Date Request
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={formkey}
                            defaultValues={req}
                            validateOnSubmit={true}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <MonthPicker
                                                field="timeline"
                                                label="Select the new completion date."
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField disabled={true} maxWords="75" multiline label="Why is the change needed?" field="why" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="q1"
                                                name="q1"
                                                label="I only need more time to complete the project and any costs after the appropriation end date will be covered with other funds as described in the Non-ENRTF Funds Contributed section of the budget."
                                                fullWidth
                                                options={[
                                                    { label: "Yes", value: "true" , disabled: true},
                                                    { label: "No", value: "false", disabled: true }
                                                ]}
                                                alignment={true}>
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="q2"
                                                name="q2"
                                                label="I have exhausted all other options and talked with LCCMR staff. I need to extend the availability of funds and completion date to complete the project. I need to request a legislative extension to carry forward the availability of funds to complete the project."
                                                fullWidth
                                                options={[
                                                    { label: "Yes", value: "true", disabled: true },
                                                    { label: "No", value: "false", disabled: true }
                                                ]}
                                                alignment={true}>
                                            </RadioGroup>
                                        </Grid>
                                        {(req.q2 === "true" || req.q2 === true) && (
                                            <Grid item xs={12}>
                                                <TextField disabled={true} multiline maxWords="100" label="If an extension is not passed by the legislature, what parts of the project will not be completed?" field="q3" fullWidth />
                                            </Grid>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}


AmendmentTab = connect(
    (state, ownProps) => ({
        authState: state.auth,
        amendments: getAmendments(state, ownProps),
        collaborators: getCollaborators(state, ownProps),
        activities: getActivities(state, ownProps),
        personnel: getPersonnel(state, ownProps),
        contracts: getContracts(state, ownProps),
        expenditures: getExpenditures(state, ownProps),
        acquisitions: getAcquisitions(state, ownProps),
        statusUpdateTab: getStatusUpdateTab(state, ownProps),
        travels: getTravels(state, ownProps),
        printings: getPrintings(state, ownProps),
        expenses: getExpenses(state, ownProps),
        funds: getFunds(state, ownProps),
        parcels: getParcels(state, ownProps),
        attachments: getAttachments(state, ownProps),
    }),
    {
        ...AmendmentLog.actions
    }
)(AmendmentTab);

export default withStyles(styles)(withRouter(AmendmentTab));
