import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { Form } from "react-form";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from "../common/TextField";
import HelpDialog from "../common/HelpDialog";

import * as authActions from "./actions";

const styles = {
    registerError: {
        color: "#be0000",
        marginTop: 8,
        marginBottom: -8
    },
    textAlignCenter: {
        textAlign: "center"
    }
};

class RegisterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            helpOpen: false,
            helpEmail: null
        };
        this.inputs = [];
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid email address" : null;
        };
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        const validateTelephone = telephone => {
            if (telephone && telephone.trim() !== "(   )    -" && !telephone.match(/^\(\d{3}\)[ ]?\d{3}[-]?\d{4}$/))
                return "Invalid Telephone";
            else return null;
        };

        const objErrors = {
            first_name: validateRequired(values.first_name),
            last_name: validateRequired(values.last_name),
            email: validateEmail(values.email),
            phone: validateTelephone(values.phone),
            password: validatePassword(values.password, values.password2),
            password2: validatePassword(values.password, values.password2)
        };
        const entries = Object.entries(objErrors).reverse();
        for (const [k, v] of entries) {
            if (v && this.inputs[k]) this.inputs[k].focus();
        }
        return objErrors;
    };

    componentDidMount() {
        document.title = "Register New User - LCCMR Proposal and Grant Management System";
    }

    registerSubmit = values => {
        values.email = values.email.toLowerCase();

        this.props.authRegister(values).then(() => {
            if (this.props.register.success) {
                this.setState({ helpOpen: true, helpEmail: values.email });
            }
        });
    };

    render() {
        const { classes, register, history } = this.props;
        const { helpOpen, helpEmail } = this.state;

        return (
            <AppContainer>
                <Form
                    getApi={el => (this.form = el)}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={this.errorValidator}
                    onSubmit={this.registerSubmit}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container justify="center" spacing={24}>
                                <Grid item xs={11} md={8} lg={6}>
                                    <Typography variant="h2">User Registration</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" spacing={24}>
                                <Grid item xs={11} md={8} lg={6}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                field="first_name"
                                                label="First Name"
                                                fullWidth
                                                autoFocus
                                                inputRef={input => {
                                                    this.inputs["first_name"] = input;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                field="last_name"
                                                label="Last Name"
                                                fullWidth
                                                inputRef={input => {
                                                    this.inputs["last_name"] = input;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                phoneNumber
                                                field="phone"
                                                label="Telephone"
                                                fullWidth
                                                inputRef={input => {
                                                    this.inputs["phone"] = input;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="ext" label="Telephone Ext" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                field="email"
                                                label="Email (this is your user name)"
                                                fullWidth
                                                inputRef={input => {
                                                    this.inputs["email"] = input;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography id="pwdr1">
                                                Password Requirements:
                                                <ul>
                                                    <li id="pwdr2">Minimum 8 characters.</li>
                                                    <li id="pwdr3">Cannot be part of your name or email.</li>
                                                    <li id="pwdr4">Cannot be entirely numeric.</li>
                                                </ul>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                passwordaria
                                                required
                                                field="password"
                                                type="password"
                                                label="Password"
                                                fullWidth
                                                inputRef={input => {
                                                    this.inputs["password"] = input;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                passwordaria
                                                required
                                                field="password2"
                                                type="password"
                                                label="Retype Password"
                                                fullWidth
                                                inputRef={input => {
                                                    this.inputs["password2"] = input;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Submit
                                                label="Register"
                                                altAction="/#"
                                                altLabel="Return to Home Page"
                                                loading={register.pending}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                className={classNames(classes.textAlignCenter, classes.registerError)}>
                                                {register.error}
                                            </Typography>
                                            <Typography variant="h2" gutterBottom>
                                                Need Help?
                                            </Typography>
                                            <Typography>
                                                Please contact us at
                                                <br />
                                                <a href="mailto:lccmr@lccmr.mn.gov"> lccmr@lccmr.mn.gov </a>
                                                <br />
                                                651-296-2406
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <HelpDialog
                    open={helpOpen}
                    onClose={() => history.push("/")}
                    question="Account Registered"
                    text={
                        <div>
                            Your account has been successfully registered in the LCCMR Proposal and Grant Management
                            System. In order to log in you must first confirm your email address at <b>{helpEmail}</b>.
                            <br />
                            <br />
                            It might take up to an hour to receive your confirmation mail. Please also check your junk
                            or spam mail folder in case you don't see it in your inbox. This seems to occur mostly with
                            gmail users.
                            <br />
                            <br />
                            If you still did not receive it please contact us at:
                            <br />
                            <a href="mailto:lccmr@lccmr.mn.gov"> lccmr@lccmr.mn.gov </a>
                            <br />
                            651-296-2406
                        </div>
                    }
                />
            </AppContainer>
        );
    }
}

RegisterPage = connect(state => ({ register: (state.auth && state.auth.register) || {} }), authActions)(RegisterPage);

export default withStyles(styles)(withRouter(RegisterPage));
