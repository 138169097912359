import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AppContainer from "../common/AppContainer";

import { connect } from "react-redux";
import * as authActions from "./actions";

const styles = {
    passwordError: {
        color: "#be0000"
    }
};

class AccountConfirmation extends Component {
    componentDidMount() {
        this.props.authAccountConfirm({
            uid: this.props.match.params["uid"],
            token: this.props.match.params["token"]
        });
    }

    render() {
        const { classes, accountConfirm } = this.props;
        return (
            <AppContainer>
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} md={6}>
                        <Typography variant="h2" gutterBottom>
                            Account Activation
                        </Typography>
                        <Typography gutterBottom className={classes.passwordError}>
                            {accountConfirm.error}
                        </Typography>
                        {(accountConfirm.success || !accountConfirm.error) && (
                            <Typography gutterBottom>Your account has been activated!</Typography>
                        )}
                        {(accountConfirm.success || !accountConfirm.error) && (
                            <Button fullWidth component={Link} to="/">
                                Return to Home Page
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

AccountConfirmation = connect(
    state => ({ accountConfirm: (state.auth && state.auth.accountConfirm) || {} }),
    authActions
)(AccountConfirmation);

export default withStyles(styles)(withRouter(AccountConfirmation));
