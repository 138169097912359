import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import { Proposal, ProposalPastProject } from "../models";

import TextField from "../../common/TextField";
import CheckboxGroup from "../../common/CheckboxGroup";
import HelpLabel from "../../common/HelpLabel";
import RadioGroup from "../../common/RadioGroup";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import Select from "../../common/Select";
import Subquestion from "../../common/Subquestion";
import { MAKE_OPTIONS } from "../../../api/constants";
import { getValue } from "../../../api/utils";
import { createSelector } from "../../common/orm";

import cities from "../../common/cities.json";
import counties from "../../common/counties.json";
import watersheds from "../../common/watersheds.json";
import ecoregions from "../../common/ecoregions.json";
import lccmrregions from "../../common/lccmrregions.json";
import MultiSelect from "../../common/MultiSelect";
import { ProposalTab } from "../../home/models";

const getFundingCategories = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        var prop = session.Proposal.withId(id);
        return session.FundingCategory.filter(FC => FC.year_label === prop.ml_year)
            .orderBy("suffix")
            .toRefArray();
    }
);
const getAllProposals = createSelector(schema => {
    return schema.HistoricalProposal.all()
        .orderBy(["ml_year", "name"], ["desc", "asc"])
        .toRefArray();
});
const getPastProjects = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalPastProject.filter({
            applied_proposal: id
        })
            .orderBy("id")
            .toModelArray();
    }
);
const getTab = ProposalTab.selectMessage(9);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    }
});

const columnData = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        width: "20px"
    },
    { id: "name", numeric: false, label: "Project" },
    { id: "approp", numeric: false, label: "Appropriation" },
    { id: "funded", numeric: true, label: "Amount Awarded" }
];

class GeneralTab extends Component {
    state = {
        filterMyProjects: false,
        selectkey: window.performance.now(),
        historicalFilter: null
    };

    componentDidMount() {
        const { handleUnsavedFields, ormProposalUpdateLocalOnly, proposal } = this.props;
        document.title = "Proposal: General Information - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!proposal.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormProposalUpdateLocalOnly({
                    id: proposal.id,
                    fromReview: false
                });
            }
        }, 1);
        //this.formKey = window.performance.now();
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name),
            ml_year: isRequired(values.ml_year)
        };

        return valObj;
    };

    updateProposal(values, fromStepper) {
        const {
            ormProposalUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            proposal,
            ormProposalUpdateLocalOnly
        } = this.props;
        const { id } = proposal;

        // reformat to be array
        // treat empty array as untouched. "none" as No. Two strings as Yes.
        if (values["patent_royalty_revenue_potential"] === null) {
            values["patent_royalty_revenue_potential"] = [];
        } else if (values["patent_royalty_revenue_potential"] === "none") {
            values["patent_royalty_revenue_potential"] = ["none"];
        } else if (values["patent_royalty_revenue_potential"]) {
            values["patent_royalty_revenue_potential"] = values.patent_royalty_revenue_potential
                ? values.patent_royalty_revenue_potential.split("=")
                : [];
        }

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (
                (proposal.status !== "Draft In Progress" &&
                    proposal.status !== "Draft Feedback Received" &&
                    proposal.status !== "Revisions Needed") ||
                proposal.secondary_status === "Withdrawal Requested" ||
                proposal.secondary_status === "Withdrawn" ||
                (this.props.authState &&
                    this.props.authState.user &&
                    (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member"))
            ) {
                ormProposalUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = proposal.map;
        values.financial_capacity = proposal.financial_capacity;
        values.modify_date_label = proposal.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                handlePrev();
            } else {
                handleNext();
            }
        }
    }

    applyOther = (value, element) => {
        const { ormProposalUpdateLocalOnly } = this.props;
        const { id } = this.props.proposal;

        const funding_category = this.props.proposal.final_funding_category
            ? this.props.proposal.final_funding_category
            : this.props.proposal.funding_category;

        ormProposalUpdateLocalOnly({
            id: id,
            [element]: value
        });

        if (
            (element === "funding_category" || element === "final_funding_category") &&
            funding_category === "Small Projects" &&
            value !== "Small Projects"
        ) {
            ormProposalUpdateLocalOnly({
                id: id,
                [element]: value
            });
            if (element === "funding_category") {
                this.form.setValue("secondary_funding_category", "");
            } else {
                this.form.setValue("final_secondary_funding_category", "");
            }
        } else {
            ormProposalUpdateLocalOnly({
                id: id,
                [element]: value
            });
        }
        // Clear existing values when changing scale
        if (element === "location_scale" || element === "final_location_scale") {
            ormProposalUpdateLocalOnly({
                id: id,
                location_areas: []
            });
            if (element === "location_scale") {
                this.form.setValue("location_areas", []);
            } else {
                this.form.setValue("final_location_areas", []);
            }
            this.setState({ selectkey: window.performance.now() });
        }
        // Clear existing values when changing scale
        if (element === "impact_scale" || element === "final_impact_scale") {
            ormProposalUpdateLocalOnly({
                id: id,
                impact_areas: []
            });
            if (element === "impact_scale") {
                this.form.setValue("impact_areas", []);
            } else {
                this.form.setValue("final_impact_areas", []);
            }
            this.setState({ selectkey: window.performance.now() });
        } else this.setState({ foo: "bar" });
    };

    MAKE_OPTIONS = table => {
        return table.map(row => ({
            label: row.category_name + " (" + row.suffix + ")",
            value: row.category_name
        }));
    };

    filterByValue = (array, string) => {
        return array.filter(o =>
            Object.keys(o).some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    radioSetTextNull = (e, formApi) => {
        formApi.setValue("reinvestment_description", null);
    };

    setTextToNUll = (e, formApi) => {
        if (e === "") {
            formApi.setValue("reinvestment_description", null);
        }
    };

    getSubdivision = arr => {
        var sub_str = "";
        arr.forEach(x => {
            if (x !== null && x.toLowerCase().includes("subd.")) {
                sub_str = x;
            }
        });
        return sub_str;
    };

    getDefaultValues = proposal => {
        var defaultValues = { ...proposal.formData };

        // treat empty array as untouched. "none" as No. Two strings as Yes.
        if (defaultValues["patent_royalty_revenue_potential"].length === 0) {
            defaultValues["patent_royalty_revenue_potential"] = null;
        } else if (defaultValues["patent_royalty_revenue_potential"].length === 1) {
            defaultValues["patent_royalty_revenue_potential"] = "none";
        } else {
            defaultValues["patent_royalty_revenue_potential"] = defaultValues["patent_royalty_revenue_potential"].join(
                "="
            );
        }

        return defaultValues;
    };

    render() {
        const {
            classes,
            proposal,
            handleUnsavedFields,
            ActionMenu,
            categories,
            proposalList,
            pastProjects,
            ormProposalPastProjectDelete,
            ormProposalPastProjectCreate,
            ormProposalUpdateLocalOnly,
            authState,
            tab
        } = this.props;
        const { filterMyProjects, selectkey, historicalFilter } = this.state;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));
                
        const Easement = getValue(proposal, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(proposal, "land_rights").indexOf("Fee Acquisition") > -1;
        const ml_year = getValue(proposal, "ml_year");
        const children_services = getValue(proposal, "children_services");
        const asset_tenthousand = getValue(proposal, "asset_tenthousand");
        const acquisition_tenthousand = getValue(proposal, "acquisition_tenthousand");

        const showSub =
            "Patent, Copyright, or Royalty Potential=Potential revenue generated or net income from the sale of products or assets developed or acquired with ENRTF funding";
        const funding_category = proposal.final_funding_category
            ? getValue(proposal, "final_funding_category")
            : getValue(proposal, "funding_category");
        const location_scale = proposal.final_location_scale
            ? getValue(proposal, "final_location_scale")
            : getValue(proposal, "location_scale");
        const impact_scale = proposal.final_impact_scale
            ? getValue(proposal, "final_impact_scale")
            : getValue(proposal, "impact_scale");

        var pList = proposalList;
        if (filterMyProjects) {
            pList = proposalList.filter(pL => pL.user_id === authState.user.id);
        }
        if (historicalFilter) {
            pList = this.filterByValue(pList, historicalFilter);
        }
        pList = pList.filter(
            pL => pL.id !== proposal.id && !pastProjects.some(item => item.original_proposal.id === pL.id)
        );

        // Set this so Filter Project List is selected by default
        if (!proposal.formData._temp2) proposal.formData._temp2 = "All Projects";

        const sorted_pastProjects = pastProjects.sort((a, b) => {
            if (!a.original_proposal.approp || !b.original_proposal.approp)
                return -1;
            const a_arr = a.original_proposal.approp.split(",");
            const b_arr = b.original_proposal.approp.split(",");
            const a_str = this.getSubdivision(a_arr);
            const b_str = this.getSubdivision(b_arr);

            if (a_arr[0] === b_arr[0]) {
                const a_digit = parseInt(a_str.replace(/[^0-9]/g, ""));
                const b_digit = parseInt(b_str.replace(/[^0-9]/g, ""));
                if (a_digit === b_digit) {
                    const a_char = a_str.replace(/[0-9]/g, "");
                    const b_char = b_str.replace(/[0-9]/g, "");
                    return a_char - b_char;
                } else {
                    return a_digit < b_digit ? -1 : 0;
                }
            } else {
                return a_arr[0] < b_arr[0] ? -1 : 0;
            }
        });

        return (
            <Form
                getApi={el => (this.form = el)}
                //key={this.formKey}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={this.getDefaultValues(proposal)}
                formDidUpdate={() => handleUnsavedFields(true)}
                validateError={values => this.errorValidator(values)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="titleAction">Project Basic Information</Typography>
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <TextField 
                                    disabled={is_read_only} 
                                    field="name" 
                                    fullWidth 
                                    maxWords={proposal.status === "Draft In Progress" ? "8" : null}
                                    checkFocus={proposal}
                                    focusRemoval={ormProposalUpdateLocalOnly}
                                >
                                    <HelpLabel
                                        inputLabel="Project Title (8 word limit)"
                                        htmlText={true}
                                        showLabel={true}
                                        helpText={tab.tab_help_texts.find(tht => tht.label === "Project Title").text}
                                    />
                                </TextField>
                                <Grid container spacing={16}>
                                    <Grid item xs={6}>
                                        <TextField field="ml_year" disabled={true} fullWidth>
                                            <HelpLabel
                                                inputLabel="Session Year"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={
                                                    tab.tab_help_texts.find(tht => tht.label === "Session Year").text
                                                }
                                            />
                                        </TextField>
                                        <TextField useTextFormat field="funding_total" fullWidth disabled={true}>
                                            <HelpLabel
                                                inputLabel="ENRTF Funds Requested"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={
                                                    tab.tab_help_texts.find(tht => tht.label === "ENRTF Funds Requested").text
                                                }
                                            />
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField field="create_date_label" label="Created Date" fullWidth disabled={true} />
                                        <TextField
                                            field="modify_date_label"
                                            label="Last Modified Date"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            eventHandle={this.applyOther}
                                            checkFocus={proposal}
                                            focusRemoval={ormProposalUpdateLocalOnly}
                                            field={
                                                proposal.final_funding_category
                                                    ? "final_funding_category"
                                                    : "funding_category"
                                            }
                                            disabled={is_read_only}
                                            options={this.MAKE_OPTIONS(categories)}
                                            fullWidth>
                                            <HelpLabel
                                                inputLabel="LCCMR Funding Category"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={
                                                    tab.tab_help_texts.find(
                                                        tht => tht.label === "LCCMR Funding Category"
                                                    ).text
                                                }
                                            />
                                        </Select>
                                        {funding_category === "Small Projects" && (
                                            <Subquestion
                                                component={
                                                    <Select
                                                        field={
                                                            proposal.final_funding_category
                                                                ? "final_secondary_funding_category"
                                                                : "secondary_funding_category"
                                                        }
                                                        disabled={is_read_only}
                                                        checkFocus={proposal}
                                                        focusRemoval={ormProposalUpdateLocalOnly}
                                                        label="Secondary LCCMR Funding Category"
                                                        options={this.MAKE_OPTIONS(
                                                            categories.filter(c => c.category_name !== "Small Projects")
                                                        )}
                                                        fullWidth
                                                    />
                                                }
                                            />
                                        )}
                                        <CheckboxGroup
                                            field="land_rights"
                                            eventHandle={this.applyOther}
                                            disabled={is_read_only}
                                            label="Does your project include restoration or acquisition of land rights? (please check all that apply)"
                                            row={true}
                                            options={MAKE_OPTIONS([
                                                "Restoration",
                                                "Conservation Easement Acquisition",
                                                "Fee Acquisition",
                                                "Easement (Other) Acquisition"
                                            ])}>
                                            <HelpLabel
                                                inputLabel="Does your project include restoration or acquisition of land rights?"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={
                                                    tab.tab_help_texts.find(
                                                        tht =>
                                                            tht.label ===
                                                            "Does your project include restoration or acquisition of land rights?"
                                                    ).text
                                                }
                                            />
                                        </CheckboxGroup>
                                        <RadioGroup
                                            field="include_research"
                                            name="include_research"
                                            label="Does your project include original, hypothesis-driven research?"
                                            fullWidth
                                            options={[
                                                { label: "Yes", value: "true", disabled: is_read_only },
                                                { label: "No", value: "false", disabled: is_read_only }
                                            ]}
                                            alignment={true}></RadioGroup>
                                        <RadioGroup
                                            field="patent_royalty_revenue_potential"
                                            name="patent_royalty_revenue_potential"
                                            checkFocus={proposal}
                                            focusRemoval={ormProposalUpdateLocalOnly}
                                            label={
                                                <>
                                                    Does your project have potential for royalties, copyrights, patents, sale of products and assets, or revenue generation?
                                                </>
                                            }
                                            fullWidth
                                            options={[
                                                {
                                                    label: "Yes",
                                                    identifier: "Patent",
                                                    value:
                                                        "Patent, Copyright, or Royalty Potential=Potential revenue generated or net income from the sale of products or assets developed or acquired with ENRTF funding",
                                                    disabled: is_read_only
                                                },
                                                { label: "No", value: "none", disabled: is_read_only }
                                            ]}
                                            alignment={true}></RadioGroup>
                                        {formApi.values.patent_royalty_revenue_potential === showSub && (
                                            <Subquestion
                                                component={
                                                    <RadioGroup
                                                        field="understand_potential"
                                                        name="understand_potential"
                                                        label={
                                                            <Typography>
                                                                Do you understand and acknowledge IP and revenue-return
                                                                and sharing requirements in{" "}
                                                                <a
                                                                    style={{
                                                                        textDecoration: "underline",
                                                                        fontWeight: "bold",
                                                                        color: "rgba(0, 0, 0, 0.87)"
                                                                    }}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href="https://www.revisor.mn.gov/statutes/cite/116P.10">
                                                                    M.S. 116P.10
                                                                </a>{" "}
                                                                ?
                                                            </Typography>
                                                        }
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", identifier: "Yes1", value: "true", disabled: is_read_only },
                                                            { label: "No", identifier: "No1", value: "false", disabled: is_read_only }
                                                        ]}
                                                        alignment={true}></RadioGroup>
                                                }
                                            />
                                        )}
                                        {formApi.values.patent_royalty_revenue_potential === showSub && (
                                            <Subquestion
                                                component={
                                                    <>
                                                        <RadioGroup
                                                            field="understand_revenue"
                                                            name="understand_revenue"
                                                            eventHandle={e => this.radioSetTextNull(e, formApi)}
                                                            label={
                                                                <Typography>
                                                                    Do you wish to request reinvestment of any revenues
                                                                    into your project instead of returning revenue to
                                                                    the ENRTF?
                                                                </Typography>
                                                            }
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", identifier: "Yes2", value: "true", disabled: is_read_only },
                                                                { label: "No", identifier: "No2", value: "false", disabled: is_read_only }
                                                            ]}
                                                            alignment={true}></RadioGroup>
                                                        {formApi.values.understand_revenue && (
                                                            <Subquestion
                                                                component={
                                                                    <TextField
                                                                        field="reinvestment_description"
                                                                        name="reinvestment_description"
                                                                        checkFocus={proposal}
                                                                        focusRemoval={ormProposalUpdateLocalOnly}
                                                                        eventHandle={e =>
                                                                            this.setTextToNUll(e, formApi)
                                                                        }
                                                                        label={
                                                                            <Typography>
                                                                                If so, describe here (1) the source and estimated amounts of any revenue and (2) how you propose to use those revenues:
                                                                            </Typography>
                                                                        }
                                                                        multiline
                                                                        disabled={is_read_only}
                                                                        fullWidth
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                }
                                            />
                                        )}
                                        {ml_year >= 2024 && (
                                            <>
                                            <RadioGroup
                                                field="asset_tenthousand"
                                                name="asset_tenthousand"
                                                eventHandle={this.applyOther}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Does your project include the pre-design, design, construction, or renovation of a building, trail, campground, or other fixed capital asset costing $10,000 or more or large-scale stream or wetland restoration?"
                                                        htmlText={true}
                                                        showLabel={true}
                                                        helpText={
                                                            tab.tab_help_texts.find(
                                                                tht => tht.label === "Does your project include the pre-design, design, construction, or renovation of a building, trail, campground, or other fixed capital asset costing $10,000 or more or large-scale stream or wetland restoration?"
                                                            ).text
                                                        }
                                                    />
                                                }
                                                fullWidth
                                                options={[
                                                    { label: "Yes", value: "true", disabled: is_read_only },
                                                    { label: "No", value: "false", disabled: is_read_only }
                                                ]}
                                                alignment={true}></RadioGroup>
                                                {asset_tenthousand && ml_year >= 2025 && (
                                                    <Subquestion
                                                        component= {
                                                            <Typography>
                                                                If yes, a 25% non-ENRTF match and other requirements apply. Please download and complete a <a
                                                                    style={{
                                                                        textDecoration: "underline",
                                                                        fontWeight: "bold",
                                                                        color: "rgba(0, 0, 0, 0.87)"
                                                                    }}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href="https://www.lccmr.mn.gov/funding_process/capital_construction_project_questionnaire.pdf">
                                                                    Capital Construction Project Questionnaire
                                                                </a> and <a
                                                                    style={{
                                                                        textDecoration: "underline",
                                                                        fontWeight: "bold",
                                                                        color: "rgba(0, 0, 0, 0.87)"
                                                                    }}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href="https://www.lccmr.mn.gov/funding_process/capital_construction_budget_addendum.xlsx">
                                                                    Budget Addendum
                                                                </a> prior to proceeding with your proposal. When complete, upload 
                                                                to the "Supplemental Attachments" section on the Attachments page (Tab 7) prior to submitting the proposal.
                                                            </Typography>
                                                        }
                                                    />
                                                )}
                                            {(Fee || Easement) && (
                                                <>
                                                <RadioGroup
                                                    field="acquisition_tenthousand"
                                                    name="acquisition_tenthousand"
                                                    eventHandle={this.applyOther}
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Is the purpose of the acquisition for construction of a building(s), trail, campground, or other fixed capital asset costing $10,000 or more or large- scale stream or wetland restoration, either now or in the future?"
                                                            htmlText={true}
                                                            showLabel={true}
                                                            helpText={
                                                                tab.tab_help_texts.find(
                                                                    tht => tht.label === "Is the purpose of the acquisition for construction of a building(s), trail, campground, or other fixed capital asset costing $10,000 or more or large- scale stream or wetland restoration, either now or in the future?"
                                                                ).text
                                                            }
                                                        />
                                                    }
                                                    fullWidth
                                                    options={[
                                                        { label: "Yes", value: "true", disabled: is_read_only },
                                                        { label: "No", value: "false", disabled: is_read_only }
                                                    ]}
                                                    alignment={true} />
                                                    {acquisition_tenthousand && ml_year >= 2025 && (
                                                        <Subquestion
                                                            component= {
                                                                <Typography>
                                                                    If yes, a 25% non-ENRTF match and other requirements apply. Please download and complete a <a
                                                                        style={{
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold",
                                                                            color: "rgba(0, 0, 0, 0.87)"
                                                                        }}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href="https://www.lccmr.mn.gov/funding_process/capital_construction_project_questionnaire.pdf">
                                                                        Capital Construction Project Questionnaire
                                                                    </a> and <a
                                                                        style={{
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold",
                                                                            color: "rgba(0, 0, 0, 0.87)"
                                                                        }}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href="https://www.lccmr.mn.gov/funding_process/capital_construction_budget_addendum.xlsx">
                                                                        Budget Addendum
                                                                    </a> prior to proceeding with your proposal. When complete, upload 
                                                                    to the "Supplemental Attachments" section on the Attachments page (Tab 7) prior to submitting the proposal.
                                                                </Typography>
                                                            }
                                                        />
                                                    )}
                                                    </>
                                                )}
                                                <RadioGroup
                                                    field="children_services"
                                                    name="children_services"
                                                    eventHandle={this.applyOther}
                                                    label={
                                                        <HelpLabel
                                                            inputLabel={
                                                                <span>
                                                                    Do you propose using an appropriation from the Environment and Natural Resources Trust Fund to 
                                                                    conduct a project that provides children's services 
                                                                    (as defined in Minnesota Statutes section <a
                                                                        style={{
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold",
                                                                            color: "rgba(0, 0, 0, 0.87)"
                                                                        }}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href="https://www.revisor.mn.gov/statutes/cite/299C.61#stat.299C.61.7">
                                                                        section 299C.61 Subd.7
                                                                    </a> as "the provision of care, treatment, education, training, instruction, or recreation to children")?
                                                                </span>
                                                            }
                                                            htmlText={true}
                                                            showLabel={true}
                                                            helpText={
                                                                tab.tab_help_texts.find(
                                                                    tht => tht.label === "Do you propose using an appropriation from the Environment and Natural Resources Trust Fund to conduct a project that provides children's services?"
                                                                ).text
                                                            }
                                                        />
                                                    }
                                                    fullWidth
                                                    options={[
                                                        { label: "Yes", value: "true", disabled: is_read_only },
                                                        { label: "No", value: "false", disabled: is_read_only }
                                                    ]}
                                                    alignment={true}></RadioGroup>
                                                {children_services && (
                                                    <Subquestion
                                                        component={
                                                            <RadioGroup
                                                                field="background_checks"
                                                                name="background_checks"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel={
                                                                            <span>
                                                                                If you answered yes to the above question, do you certify that background checks are performed for 
                                                                                background check crimes, as defined in Minnesota Statutes,{" "}
                                                                                <a
                                                                                    style={{
                                                                                        textDecoration: "underline",
                                                                                        fontWeight: "bold",
                                                                                        color: "rgba(0, 0, 0, 0.87)"
                                                                                    }}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    href="https://www.revisor.mn.gov/statutes/cite/299C.61#stat.299C.61.2">
                                                                                     section 299C.61, Subd. 2
                                                                                </a>, on all employees, contractors, and volunteers who have or may have access to a child to 
                                                                                whom children's services are provided by your organization?
                                                                            </span>
                                                                        }
                                                                        htmlText={true}
                                                                        showLabel={true}
                                                                        helpText={
                                                                            tab.tab_help_texts.find(
                                                                                tht => tht.label === "Do you certify that background checks are performed for background check crimes on all employees, contractors, and volunteers who have or may have access to a child?"
                                                                            ).text
                                                                        }
                                                                    />
                                                                }
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "true", disabled: is_read_only },
                                                                    { label: "No", value: "false", disabled: is_read_only }
                                                                ]}
                                                                alignment={true}></RadioGroup>

                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="titleAction">Project Location</Typography>
                                        <Select
                                            eventHandle={this.applyOther}
                                            disabled={is_read_only}
                                            checkFocus={proposal}
                                            focusRemoval={ormProposalUpdateLocalOnly}
                                            field={
                                                proposal.final_location_scale
                                                    ? "final_location_scale"
                                                    : "location_scale"
                                            }
                                            label="What is the best scale for describing where your work will take place?"
                                            options={[
                                                /*{ label: "City", value: "City" },
                                                { label: "County", value: "County" },
                                                { label: "Watershed", value: "Watershed" },
                                                {
                                                    label: "Ecological Subsection",
                                                    value: "Ecological Subsection"
                                                },*/
                                                { label: "Region", value: "Region" },
                                                { label: "Statewide", value: "Statewide" }
                                            ]}
                                            fullWidth
                                        />
                                        {location_scale && location_scale !== "Statewide" && (
                                            <Subquestion
                                                component={
                                                    <MultiSelect
                                                        key={selectkey}
                                                        checkFocus={proposal}
                                                        focusRemoval={ormProposalUpdateLocalOnly}
                                                        disabled={is_read_only}
                                                        field={
                                                            proposal.final_location_scale
                                                                ? "final_location_areas"
                                                                : "location_areas"
                                                        }
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Choose the areas:"
                                                                htmlText={true}
                                                                showLabel={true}
                                                                helpText={
                                                                    tab.tab_help_texts.find(
                                                                        tht => tht.label === "What is the best scale for describing where your work will take place? (Choose the areas:)"
                                                                    ).text
                                                                }
                                                            />
                                                        }
                                                        options={
                                                            location_scale === "City"
                                                                ? cities
                                                                : location_scale === "County"
                                                                ? counties
                                                                : location_scale === "Watershed"
                                                                ? watersheds
                                                                : location_scale === "Ecological Subsection"
                                                                ? ecoregions
                                                                : location_scale === "Region"
                                                                ? lccmrregions
                                                                : []
                                                        }
                                                    />
                                                }
                                            />
                                        )}
                                        <Select
                                            eventHandle={this.applyOther}
                                            field={proposal.final_impact_scale ? "final_impact_scale" : "impact_scale"}
                                            disabled={is_read_only}
                                            checkFocus={proposal}
                                            focusRemoval={ormProposalUpdateLocalOnly}
                                            label="What is the best scale to describe the area impacted by your work?"
                                            options={[
                                                { label: "Region", value: "Region" },
                                                { label: "Statewide", value: "Statewide" }
                                            ]}
                                            fullWidth
                                        />
                                        {impact_scale && impact_scale !== "Statewide" && (
                                            <Subquestion
                                                component={
                                                    <MultiSelect
                                                        key={selectkey + 1}
                                                        field={
                                                            proposal.final_impact_areas
                                                                ? "final_impact_areas"
                                                                : "impact_areas"
                                                        }
                                                        disabled={is_read_only}
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Choose the areas:"
                                                                htmlText={true}
                                                                showLabel={true}
                                                                helpText={
                                                                    tab.tab_help_texts.find(
                                                                        tht => tht.label === "What is the best scale to describe the area impacted by your work? (Choose the areas:)"
                                                                    ).text
                                                                }
                                                            />
                                                        }
                                                        options={
                                                            impact_scale === "City"
                                                                ? cities
                                                                : impact_scale === "County"
                                                                ? counties
                                                                : impact_scale === "Watershed"
                                                                ? watersheds
                                                                : impact_scale === "Ecological Subsection"
                                                                ? ecoregions
                                                                : impact_scale === "Region"
                                                                ? lccmrregions
                                                                : []
                                                        }
                                                    />
                                                }
                                            />
                                        )}
                                        <CheckboxGroup
                                            field={proposal.final_impact_occur ? "final_impact_occur" : "impact_occur"}
                                            disabled={is_read_only}
                                            row={true}
                                            label="When will the work impact occur? (please check all that apply)"
                                            options={MAKE_OPTIONS(["During the Project", "In the Future"])}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={9}>
                                                <Select
                                                    eventHandle={v => {
                                                        if (v !== "0")
                                                            ormProposalPastProjectCreate({
                                                                original_proposal: v,
                                                                applied_proposal: proposal.id
                                                            });
                                                        formApi.setValue("_temp", "");
                                                    }}
                                                    field="_temp"
                                                    disabled={is_read_only}
                                                    options={
                                                        pList.length > 0
                                                            ? pList.filter(pL => pL.ml_year < proposal.ml_year && pL.ml_year >= (proposal.ml_year - 6)).map(pL => {
                                                                  return {
                                                                      label:
                                                                          "(" +
                                                                          pL.ml_year +
                                                                          ") " +
                                                                          pL.name +
                                                                          " - " +
                                                                          pL.first_name +
                                                                          " " +
                                                                          pL.last_name,
                                                                      value: pL.id
                                                                  };
                                                              })
                                                            : [{ label: "No Projects Found", value: "0" }]
                                                    }
                                                    fullWidth>
                                                    <HelpLabel
                                                        inputLabel="Other ENRTF Appropriations Awarded in the Last Six Years"
                                                        htmlText={true}
                                                        showLabel={true}
                                                        helpText={
                                                            tab.tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Other ENRTF Appropriations Awarded in the Last Six Years"
                                                            ).text
                                                        }
                                                    />
                                                </Select>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    eventHandle={val => this.setState({ historicalFilter: val })}
                                                    field="_temp3"
                                                    label="Search Project List"
                                                    fullWidth
                                                    filterInput
                                                />
                                            </Grid>
                                        </Grid>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead stickyHeader columnData={columnData} />
                                            <TableBody>
                                                {sorted_pastProjects.map(n => {
                                                    return (
                                                        <TableRow key={n.id}>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Tooltip title="Remove Proposal">
                                                                    <Button
                                                                        color="primary"
                                                                        className={classes.deleteWidth}
                                                                        disabled={is_read_only}
                                                                        onClick={() =>
                                                                            ormProposalPastProjectDelete(n.id)
                                                                        }>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                ({n.original_proposal.ml_year}){" "}
                                                                {n.original_proposal.name}
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                {n.original_proposal.approp}
                                                            </CustomTableCell>
                                                            <CustomTableCell>
                                                                {parseInt(n.original_proposal.funding).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                        style: "currency",
                                                                        currency: "usd",
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 0
                                                                    }
                                                                )}
                                                            </CustomTableCell>
                                                            {/*<CustomTableCell></CustomTableCell>
                                                            <CustomTableCell></CustomTableCell>*/}
                                                        </TableRow>
                                                    );
                                                })}
                                                {sorted_pastProjects.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Proposals Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ float: "left" }}
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                    className={classes.button}>
                                    Save and Return to Previous Step
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                    Save and Proceed to Next Step
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

GeneralTab = connect(
    (state, ownProps) => ({
        categories: getFundingCategories(state, ownProps),
        proposalList: getAllProposals(state, ownProps),
        pastProjects: getPastProjects(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
        //budgetTotal: getBudgetTotal(state, ownProps)
    }),
    {
        ...Proposal.actions,
        ...ProposalPastProject.actions
    }
)(GeneralTab);

export default withStyles(styles)(withRouter(GeneralTab));
