import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MuiTooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import RadioGroup from "../../common/RadioGroup";
import HelpLabel from "../../common/HelpLabel";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import Select from "../../common/Select";
import TextField from "../../common/TextField";
import Subquestion from "../../common/Subquestion";
import WarningDialog from "../../common/WarningDialog";

import { getValue, setValue } from "../../../api/utils";
import { MAKE_OPTIONS_YESNO } from "../../../api/constants";
import { createSelector } from "../../common/orm";

import { ProposalPrinting } from "../models";
import { ProposalTab } from "../../home/models";

const getProposalPrintings = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalPrinting.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getTab = ProposalTab.selectMessage(12);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    hide: {
        display: "none"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    categoryTotalRow: {
        borderBottom: "none",
        height: 30
    },
    totalRow: {
        borderTop: "1px solid rgba(224, 224, 224, 1);",
        borderBottom: "1px solid rgba(224, 224, 224, 1);",
        height: 48
    },
    categoryTotalCell: {
        borderBottom: "none",
        textAlign: "right"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    totalCell: {
        borderBottom: "none",
        textAlign: "right",
        width: 60
    },
    amountCell: {
        width: 60,
        textAlign: "right"
    },
    centerAlign: {
        textAlign: "center"
    }
});

const columnDataPrintings = [
    { id: "actions", numeric: false, label: "Actions" },
    { id: "category", numeric: false, label: "Category" },
    { id: "description", numeric: false, label: "Description" },
    { id: "purpose", numeric: false, label: "Purpose" },
    { id: "ineligible", numeric: false, label: "Generally Ineligible" },
    { id: "amount", numeric: false, label: "$ Amount" }
];

const printing_options = [
    { label: "Printing", value: "Printing" },
    { label: "Publication", value: "Publication" }
];

class BudgetPrintingTab extends Component {
    state = {
        PrintingDialogObj: null,
        WarningDialogObj: null
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Budget Printing and Publication ";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { id } = this.props.proposal;
        const { ormProposalUpdate, history, handleUnsavedFields, handleNext } = this.props;

        if (typeof fromStepper === "object" && fromStepper !== null) {
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];
        }

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper !== "object") {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else {
                handleNext();
            }
        }
    }

    savePrinting(values) {
        const { id } = this.props.proposal;
        const { ormProposalPrintingCreate, ormProposalPrintingUpdate } = this.props;

        if (!values.ineligible_expenses) {
            values["justify_ineligible_expenses"] = null;
        }

        if (values.id) {
            ormProposalPrintingUpdate({
                ...values
            });
            this.props.calcFundingTotal();
        } else {
            ormProposalPrintingCreate({
                ...values,
                proposal: id
            }).then(response => {
                this.props.calcFundingTotal();
            });
        }
        this.setState({ PrintingDialogObj: null });
    }

    deleteRecord = () => {
        this.props.ormProposalPrintingPromiseDelete(this.state.WarningDialogObj.id).then(id => {
            this.props.calcFundingTotal();
        });
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    errorValidator = values => {
        var valObj = {};

        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        const isValidValue = path => {
            let val = getValue(values, path);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, val >= 0 ? null : "Value must be >= 0.");
        };

        isRequired("category");
        isRequired("description");
        isRequired("purpose");
        isValidValue("amount");
        isRequired("ineligible_expenses");
        if (values.ineligible_expenses) {
            isRequired("justify_ineligible_expenses");
        } else {
            setValue(valObj, "justify_ineligible_expenses", null);
        }

        return valObj;
    };

    applyOther = (value, element) => {
        const { ormProposalUpdateLocalOnly } = this.props;
        const { id } = this.props.proposal;
        ormProposalUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    deletePrinting = n => {
        const { ormProposalPrintingDelete } = this.props;
        ormProposalPrintingDelete(n.id);
    };

    formatMoney = amount => {
        var amountnum = parseFloat(amount);
        var amountstring = amountnum.toFixed(2);
        if (amountnum >= 1000) {
            amountstring =
                amountstring.substring(0, amountstring.length - 6) +
                "," +
                amountstring.substring(amountstring.length - 6);
        }
        if (amountnum >= 1000000) {
            amountstring =
                amountstring.substring(0, amountstring.length - 10) +
                "," +
                amountstring.substring(amountstring.length - 10);
        }
        return amountstring;
    };

    render() {
        const { classes, proposalprintings, ActionMenu, history, handleNext, handlePrev, proposal, tab } = this.props;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        const { PrintingDialogObj, WarningDialogObj } = this.state;

        var total = 0.0;
        var cat1Total = 0.0;
        var cat2Total = 0.0;

        var interact_string = PrintingDialogObj && PrintingDialogObj["id"] ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        proposalprintings.map(x => (total += x.amount));
        proposalprintings.forEach(x => {
            if (x.category === "Printing") {
                cat1Total += x.amount;
            }
        });
        proposalprintings.forEach(x => {
            if (x.category === "Publication") {
                cat2Total += x.amount;
            }
        });

        total = "$" + this.formatMoney(total);
        cat1Total = "$" + this.formatMoney(cat1Total);
        cat2Total = "$" + this.formatMoney(cat2Total);

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <Typography variant="titleAction">Printing and Publication Details</Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12}>
                    <div class="insborder">
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: tab.sub_tabs
                                        .find(tht => tht.tab_name === "Printing and Publication")
                                        .tab_help_texts.find(tht => tht.label === "Instructions and Requirements:").text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        type="submit"
                        color="primary"
                        fullWidth
                        onClick={() => this.setState({ PrintingDialogObj: { id: null, ineligible_expenses: false } })}>
                        <AddCircleOutlineIcon />
                        &nbsp;&nbsp; Add New Printing or Publication
                    </Button>
                    <br />
                    <br />
                    <Table className={classes.table}>
                        <EnhancedTableHead stickyHeader columnData={columnDataPrintings} />
                        <TableBody>
                            {proposalprintings.map(n => {
                                return (
                                    <TableRow key={n.id}>
                                        <TableCell className={classes.nowrap}>
                                            <MuiTooltip title="Edit">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Edit"
                                                    onClick={() => this.setState({ PrintingDialogObj: n })}>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Delete"
                                                    onClick={() => this.setState({ WarningDialogObj: n })}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="View">
                                                <Button
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="View"
                                                    onClick={() => this.setState({ PrintingDialogObj: n })}>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </TableCell>
                                        <TableCell>{n.category}</TableCell>
                                        <TableCell>{n.description}</TableCell>
                                        <TableCell>{n.purpose}</TableCell>
                                        <TableCell className={classes.centerAlign}>
                                            {n.ineligible_expenses ? "X" : ""}
                                        </TableCell>
                                        <TableCell className={classes.amountCell}>
                                            {n.amount ? this.formatMoney(n.amount) : ""}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {proposalprintings.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={6} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Records</caption>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        {proposalprintings.length >= 1 && (
                            <TableBody>
                                <TableRow className={classes.categoryTotalRow}>
                                    <TableCell colSpan={5} className={classes.categoryTotalCell}>
                                        Printing Total
                                    </TableCell>
                                    <TableCell className={classes.totalCell}>{cat1Total}</TableCell>
                                </TableRow>
                                <TableRow className={classes.categoryTotalRow}>
                                    <TableCell colSpan={5} className={classes.categoryTotalCell}>
                                        Publication Total
                                    </TableCell>
                                    <TableCell className={classes.totalCell}>{cat2Total}</TableCell>
                                </TableRow>
                                <TableRow className={classes.totalRow}>
                                    <TableCell colSpan={5} className={classes.categoryTotalCell}>
                                        <b>Total</b>
                                    </TableCell>
                                    <TableCell className={classes.totalCell}>
                                        <b>{total}</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </Grid>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        color="primary"
                        className={classes.button}
                        onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={PrintingDialogObj}
                    onClose={() => this.setState({ PrintingDialogObj: null })}>
                    <Form
                        defaultValues={PrintingDialogObj}
                        validateOnSubmit={true}
                        dontValidateOnMount={true}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.savePrinting(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle
                                    className={classes.dialogTitleMargin}
                                    disableTypography
                                    id="form-dialog-title">
                                    <Typography variant="h3">
                                        {interact_string} Printing and Publication Entry
                                    </Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => this.setState({ PrintingDialogObj: null })}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Select
                                                field="category"
                                                label="Category"
                                                fullWidth
                                                disabled={is_read_only}
                                                options={printing_options}
                                            />
                                            <TextField
                                                field="description"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Description (i.e., Item or category of items include quantity)"
                                            />
                                            <TextField
                                                field="purpose"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Purpose (Explain purpose of item/s)"
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                field="amount"
                                                fullWidth
                                                disabled={is_read_only}
                                                useTextFormat
                                                label="Amount"
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="ineligible_expenses"
                                                name="ineligible_expenses"
                                                label=""
                                                fullWidth
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS_YESNO([
                                                    { label: "Yes", value: "true", disabled: is_read_only },
                                                    { label: "No", value: "false", disabled: is_read_only }
                                                ])}
                                                alignment={true}>
                                                <HelpLabel
                                                    inputLabel="Generally Ineligible Expenses Justification"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Printing and Publication")
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Generally Ineligible Expenses Justification"
                                                            ).text
                                                    }
                                                />
                                            </RadioGroup>
                                            {formApi.values.ineligible_expenses && (
                                                <Subquestion
                                                    component={
                                                        <TextField
                                                            field="justify_ineligible_expenses"
                                                            multiline
                                                            fullWidth
                                                            disabled={is_read_only}
                                                            label=""
                                                        />
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.setState({ PrintingDialogObj: null })}>Cancel</Button>
                                    <Button color="primary" variant="contained" type="submit" disabled={is_read_only}>
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>

                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Printing record"
                    text={"Are you sure you want to delete this Printing record?"}
                />
            </Grid>
        );
    }
}

BudgetPrintingTab = connect(
    (state, ownProps) => ({
        proposalprintings: getProposalPrintings(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...ProposalPrinting.actions
    }
)(BudgetPrintingTab);

export default withStyles(styles)(withRouter(BudgetPrintingTab));
