import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import { withStyles, Typography } from "@material-ui/core";

import TextField from "../../common/TextField";
import HelpLabel from "../../common/HelpLabel";
import { WorkPlanTab } from "../../home/models";

const getTab = WorkPlanTab.selectMessage(8);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    }
});

class Acquisition_FeeTab extends Component {
    componentDidMount() {
        const { handleUnsavedFields, ormParentUpdateLocalOnly, plan } = this.props;
        document.title = "Work Plan: Fee Acquisition - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!plan.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormParentUpdateLocalOnly({
                    id: plan.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updatePlan(values, fromStepper) {
        const {
            ormParentUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            plan,
            is_read_only,
            ormParentUpdateLocalOnly
        } = this.props;
        const { id } = plan;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (is_read_only) {
                ormParentUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = plan.map;
        values.financial_capacity = plan.financial_capacity;
        values.modify_date_label = plan.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormParentUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                handlePrev();
            } else {
                handleNext();
            }
        }
    }

    render() {
        const { classes, plan, proposal, is_read_only, handleUnsavedFields, ActionMenu, tab, ormParentUpdateLocalOnly } = this.props;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={proposal.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updatePlan(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16} style={{ marginTop: 0 }}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <HelpLabel
                                    inputLabel="Fee Title Acquisition Information"
                                    title={true}
                                    style={{ display: "inline-block", marginTop: 8 }}
                                    showLabel={true}
                                    htmlText={true}
                                    helpText={
                                        tab.sub_tabs
                                            .find(tht => tht.tab_name === "Fee Acquisition")
                                            .tab_help_texts.find(tht => tht.label === "Fee Title Acquisition Information").text
                                    }
                                />
                                <Typography variant="titleAction"></Typography>
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <TextField
                                    field="fee_selection_process"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="1) Describe the selection process for identifying and including proposed parcels on the parcel list, including an explanation of the criteria and decision-making process used to rank and prioritize parcels."
                                />
                                <TextField
                                    field="fee_resource_plans"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="2) List all adopted state, regional, or local natural resource plans in which the lands included in the parcel list are identified for the acquisition purposes you propose. Include the URL to the plan if one is available."
                                />
                                <TextField
                                    field="fee_management_plans"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="3) For any parcels acquired in fee title, a restoration and management plan must be prepared. Summarize the components and expected outcomes of restoration and management plans for parcels acquired by your organization, how these plans are kept on file by your organization, and overall strategies for long-term plan implementation, including how long-term maintenance and management needs of the parcel will be financed into the future."
                                />
                                <TextField
                                    field="fee_county_board_approval"
                                    multiline
                                    disabled={is_read_only}
                                    checkFocus={plan} 
                                    focusRemoval={ormParentUpdateLocalOnly}
                                    fullWidth
                                    label="4) For each parcel to be conveyed to a State of Minnesota entity (e.g., DNR) after purchase, provide a statement confirming that county board approval will be obtained."
                                />
                                <TextField
                                    field="fee_dnr_approval"
                                    multiline
                                    disabled={is_read_only}
                                    fullWidth
                                    label={
                                        <Typography style={{ lineHeight: 1 }}>
                                            5) If applicable (see{" "}
                                            <a
                                                style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.87)" }}
                                                rel="noopener noreferrer"
                                                href="https://www.revisor.mn.gov/statutes/cite/116P.17"
                                                target="_blank">
                                                M.S. 116P.17
                                            </a>
                                            ), provide a statement confirming that written approval from the DNR Commissioner will be obtained 10 business days
                                            prior to any final acquisition transaction.
                                        </Typography>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ float: "left" }}
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                    className={classes.button}>
                                    Save and Return to Previous Step
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                    Save and Proceed to Next Step
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

Acquisition_FeeTab = connect(
    (state, ownProps) => ({
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    null
)(Acquisition_FeeTab);

export default withStyles(styles)(withRouter(Acquisition_FeeTab));
