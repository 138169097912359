import React from "react";
import SubquestionIcon from "./icons/Subquestion";
import Grid from "@material-ui/core/Grid";

const Subquestion = ({ component, large, small }) => (
    <Grid container>
        <Grid item xs={1}>
            {large && (
                <div style={{ marginTop: 28 }}>
                    <SubquestionIcon />
                </div>
            )}
            {small && (
                <div style={{ marginTop: -24 }}>
                    <SubquestionIcon />
                </div>
            )}
            {!large && !small && <SubquestionIcon />}
        </Grid>
        <Grid item xs={11}>
            {component}
        </Grid>
    </Grid>
);

export default Subquestion;
