import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";

import Header from "./Header";
import NotReady from "./NotReady";
import Footer from "./Footer";

const styles = theme => ({
    appContainer: {
        // The parent container of the header, sidebar, content
        width: "100%",
        paddingTop: "12rem",
        height: "calc(100% - 12rem - 56px)" // Height 100% - padding; This is set so the dashboard map can be 100%
    },
    contentContainer: {
        // The spacing on Material UI's grid works great when spacing between grid items, however the container itself is not spaced
        "& > div:first-child, & > form:first-child": {
            //, & > form div:first-child
            width: "calc(100% - 40px)",
            //marginLeft: 20,
            //marginRight: 20,
            margin: 20,
            marginBottom: 40
        }
    },
    contentContainerWithFooter: {
        // The spacing on Material UI's grid works great when spacing between grid items, however the container itself is not spaced
        "& > div:first-child, & > form:first-child": {
            //, & > form div:first-child
            marginBottom: 76 // The regular 20 margin plus 56 sized footer
        }
    },
});

const withRouterAndRef = Wrapped => {
    const WithRouter = withRouter(({ forwardRef, ...otherProps }) => <Wrapped ref={forwardRef} {...otherProps} />);
    const WithRouterAndRef = React.forwardRef((props, ref) => <WithRouter {...props} forwardRef={ref} />);
    const name = Wrapped.displayName || Wrapped.name;
    WithRouterAndRef.displayName = `withRouterAndRef(${name})`;
    return WithRouterAndRef;
};

class AppContainer extends Component {
    constructor(props) {
        super(props);
        this.targetRef = React.createRef();
    }

    go = e => {
        // just using the "href="#skipnavigation"" doesn't scroll or focus on target within router.
        // Need to unfocus the skip link and scroll to target and focus. Target link needs to

        e.preventDefault();
        this.props.history.push({ hash: "skipnavigation", search: this.props.location.search });
        e.target.blur();
        this.targetRef.current.scrollIntoView();
        this.targetRef.current.focus();
    };

    render() {
        const { children, classes, authenticated, navState, authState, synced, syncState, style } = this.props;

        //Object.keys(syncState.pending).length is a hack
        // We want to know the first time the application is loading and everything is being fetched
        // syncState.ready is called upon every save so it cant be used

        return (
            <div className={classes.appContainer} style={style}>
                <div role="complementary">
                    <a
                        href={`#${this.props.location.pathname}${
                            this.props.location.search ? this.props.location.search : ""
                        }`}
                        onClick={this.go}
                        class="skip-navigation">
                        Skip to main content
                    </a>
                </div>
                <Header authenticated={authenticated} />
                <div role="main">
                <a // eslint-disable-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
                    tabIndex="-1"
                    id={`${this.props.location.pathname}${
                        this.props.location.search ? this.props.location.search : ""
                    }`}
                    name={`${this.props.location.pathname}${
                        this.props.location.search ? this.props.location.search : ""
                    }`}
                    ref={this.targetRef}
                />
                <div
                    className={classNames(
                        classes.contentContainer,
                        classes.height,
                        !authenticated && classes.contentContainerWithFooter
                    )}>
                    {!authenticated && children}
                    {authenticated &&
                        authState &&
                        authState.user &&
                        syncState &&
                        syncState.pending &&
                        typeof syncState.pending !== "undefined" &&
                        Object.keys(syncState.pending).length >= 5 && <NotReady storeReady={false} />}
                    {authenticated &&
                        (!authState || !authState.user) &&
                        syncState &&
                        syncState.pending &&
                        typeof syncState.pending !== "undefined" &&
                        Object.keys(syncState.pending).length < 5 && <NotReady storeReady={navState.storeReady} />}
                    {authenticated &&
                        authState &&
                        authState.user &&
                        !synced &&
                        syncState &&
                        syncState.pending &&
                        typeof syncState.pending !== "undefined" &&
                        Object.keys(syncState.pending).length < 5 &&
                        children}
                    {authenticated &&
                        authState &&
                        authState.user &&
                        synced &&
                        syncState &&
                        syncState.pending &&
                        typeof syncState.pending !== "undefined" &&
                        Object.keys(syncState.pending).length < 5 && <NotReady message="Loading..." />}
                    {(authenticated && typeof synced === "undefined" && !authState) ||
                        (authState &&
                            !authState.user &&
                            navState &&
                            navState.storeReady &&
                            typeof syncState.pending === "undefined" &&
                            this.props.location.pathname !== "/register" &&
                            this.props.match.path !== "/" &&
                            this.props.match.path !== "/password/reset" &&
                            this.props.match.path !== "/set/confirm/:uid/:token" &&
                            this.props.match.path !== "/activate/:uid/:token" && <NotReady storeReady={true} />)}
                </div>
                {!authenticated && <Footer />}
                </div>
            </div>
        );
    }
}

AppContainer = connect(
    state => ({
        navState: state.nav,
        authState: state.auth,
        syncState: state.sync
    }),
    null,
    null,
    { forwardRef: true }
)(AppContainer);

export default withStyles(styles)(withRouterAndRef(AppContainer));
