import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import header from "../../images/header.webp";
import headerdev from "../../images/headerdev.webp";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    header: {
        height: "12rem",
        backgroundImage: "url(" + header + ")",
        backgroundPosition: "0% 40%",
        backgroundSize: "cover"
    },
    headerdev: {
        height: "12rem",
        backgroundImage: "url(" + headerdev + ")",
        backgroundPosition: "0% 40%",
        backgroundSize: "cover"
    },
    headerContainer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        background: "#333333"
    },
    heading: {
        fontSize: "2em",
        textDecoration: "none",
        fontFamily: "georgia, serif",
        textShadow: "1px 1px 1px #000",
        letterSpacing: ".15rem",
        color: "#fff !important",
        display: "inline-block",
        lineHeight: 1.33
    }
});

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, theError: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log(error);
        return { hasError: true, theError: error };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        /*fetch("/error/post", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                error: error,
                error_info: errorInfo
            })
        })*/
    }

    render() {
        const { classes } = this.props;
        const { theError } = this.state;

        if (this.state.hasError) {
            return (
                <>
                    <AppBar position="absolute" className={window.location.hostname === "lccmrprojectmgmt.leg.mn" ? classes.header : classes.headerdev}>
                        <div className={classes.headerContainer}>
                            <Grid container justify="center">
                                <Grid item>
                                    <Typography variant="h1" className={classes.heading}>
                                        LCCMR Proposal and Grant Management System
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </AppBar>
                    <Grid container spacing={24} style={{ marginLeft: 24, marginTop: 220 }}>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                                You have encountered an unhandled error!
                            </Typography>
                            <Typography gutterBottom>Please contact LCCMR staff with the actions you were performing leading up to this error and the contents of the error below. Thank you.</Typography>
                            <Typography gutterBottom>{theError.message}</Typography>
                            <Typography>
                                <a rel="noopener noreferrer" href="/">
                                    Return to Application
                                </a>
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            );
        }

        return this.props.children;
    }
}

export default withStyles(styles)(ErrorBoundary);
