import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import AppContainer from "../common/AppContainer";
import Checkbox from "../common/Checkbox";
import TextField from "../common/TextField";
import DatePicker from "../common/DatePicker";
import Select from "../common/Select";
import PageHeader from "../common/PageHeader";
import { StaffSlots, User } from "../home/models";

const getUser = User.selectByUrlId();
const getUsers = User.selectAll();
const getSlots = StaffSlots.selectAll();

class EditUser extends Component {
    componentDidMount() {
        document.title = "Edit User - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };

        const validateUniqueEmail = (id, email) => {
            const user = this.props.allUsers.find(x => x.id !== id && x.email === email);
            return user ? "Email Already In Use" : null;
        };

        return {
            first_name: validateRequired(values.first_name),
            last_name: validateRequired(values.last_name),
            email: validateUniqueEmail(values.id, values.email)
        };
    };

    submitForm = values => {
        const { ormUserUpdate, history } = this.props;

        if (values.hire_date) values.hire_date = values.hire_date.replace(" 12:00:00", "");
        if (values.termination_date) values.termination_date = values.termination_date.replace(" 12:00:00", "");

        ormUserUpdate({
            id: values.id,
            ...values
        });

        if (values.role === "Staff") {
            this.slotSave(values.slots, values.id);
        }

        if (values.role === "Staff") history.push("/adm/staff");
        else history.push("/adm/users");
    };

    slotSave = (num, id) => {
        const { staffSlots, ormStaffSlotsUpdate } = this.props;
        const staffSlotExisting = staffSlots.find(x => x.staff === id);

        if (!staffSlotExisting) {
            //Doesn't have slot already and if slot isn't empty choice set slot
            if (num !== "" || num !== null) {
                var slot = staffSlots.find(x => x.slot === num);
                slot.staff = id;
                ormStaffSlotsUpdate({
                    ...slot
                });
            }
        } else {
            if (num === "" || num === null) {
                //existing but set to empty slot clear slot
                staffSlotExisting.staff = null;
                ormStaffSlotsUpdate({
                    ...staffSlotExisting
                });
            } else {
                if (staffSlotExisting.slot === num) {
                    //didn't change
                    ormStaffSlotsUpdate({
                        ...staffSlotExisting
                    });
                } else {
                    //had slot but changed slot number set old staff to null
                    staffSlotExisting.staff = null;
                    ormStaffSlotsUpdate({
                        ...staffSlotExisting
                    });

                    //set new slot to staff
                    var newSlot = staffSlots.find(x => x.slot === num);
                    if (newSlot) {
                        newSlot.staff = id;
                        ormStaffSlotsUpdate({
                            ...newSlot
                        });
                    }
                }
            }
        }
    };

    getSlots = () => {
        const { staffSlots, user } = this.props;
        var open = [""];

        for (var x in staffSlots) {
            //open or belongs to user being currently edited
            if (staffSlots[x].staff === null || staffSlots[x].staff === user.id) {
                open.push(staffSlots[x].slot);
            }
        }
        open = open.sort((a, b) => a - b);
        return open.map(x => ({
            label: x,
            value: x
        }));
    };

    getDefaultValues = () => {
        const { staffSlots, user } = this.props;
        var defaultValues = {};
        defaultValues = { ...user };

        if (user.role === "Staff") {
            var hasSlot = staffSlots.find(x => x.staff === user.id);

            if (hasSlot) {
                defaultValues.slots = hasSlot.slot;
            }
        }
        return defaultValues;
    };

    render() {
        const { user } = this.props;
        if (!user) return "";

        var is_staff = false;
        if (user.role === "Staff") is_staff = true;
        if (user.hire_date && !user.hire_date.includes("12:00:00")) user.hire_date = user.hire_date + " 12:00:00";
        if (user.termination_date && !user.termination_date.includes("12:00:00"))
            user.termination_date = user.termination_date + " 12:00:00";

        return (
            <AppContainer authenticated>
                <Grid container justify="center" spacing={40}>
                    <PageHeader title={"Edit " + (is_staff ? "Staff" : "User") + ": " + user.first_name} admin_page />
                    <Grid item xs={11} md={8} lg={6}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={this.getDefaultValues()}
                            validateError={this.errorValidator}
                            onSubmit={this.submitForm}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        {!is_staff && (
                                            <>
                                                <Grid item xs={12} sm={6} style={{ marginBottom: -16 }}>
                                                    <Checkbox field="is_active" label="Active" />
                                                </Grid>
                                                <Grid item xs={12} sm={6} style={{ marginBottom: -16 }}>
                                                    <Checkbox
                                                        field="is_read_only"
                                                        label="Read Only Access for DNR Grant Management"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField field="first_name" label="First Name" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField field="last_name" label="Last Name" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField phoneNumber field="phone" label="Telephone" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField field="ext" label="Telephone Ext" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField field="email" label="Email (username)" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Select
                                                        field="role"
                                                        label="Role"
                                                        options={[
                                                            { label: "User", value: "User" },
                                                            { label: "Staff", value: "Staff" },
                                                            { label: "Reviewer", value: "Reviewer" },
                                                            { label: "Member", value: "Member" }
                                                        ]}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {is_staff && (
                                            <>
                                                <Grid item xs={12} style={{ marginBottom: -16 }}>
                                                    <Checkbox field="is_active" label="Active" />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Select
                                                        field="slots"
                                                        label="Staff Slot"
                                                        options={this.getSlots()}
                                                        disabled={this.getSlots().length === 1}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Select
                                                        field="role"
                                                        label="Role"
                                                        options={[
                                                            { label: "User", value: "User" },
                                                            { label: "Staff", value: "Staff" },
                                                            { label: "Reviewer", value: "Reviewer" },
                                                            { label: "Member", value: "Member" }
                                                        ]}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <DatePicker field="hire_date" label="Hire Date" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <DatePicker
                                                        field="termination_date"
                                                        label="Termination Date"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Select
                                                        field="prefix"
                                                        label="Prefix"
                                                        options={[
                                                            { label: "Mr.", value: "Mr." },
                                                            { label: "Mrs.", value: "Mrs." },
                                                            { label: "Ms.", value: "Ms." }
                                                        ]}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField field="title" label="Title" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField field="first_name" label="First Name" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField field="last_name" label="Last Name" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField phoneNumber field="phone" label="Work Phone" fullWidth />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField field="email" label="Email (username)" fullWidth />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                component={Link}
                                                to={is_staff ? "/adm/staff" : "/adm/users"}>
                                                Return to Manage {is_staff ? "Staff" : "Users"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

EditUser = connect(
    (state, ownProps) => ({
        authState: state.auth,
        user: getUser(state, ownProps),
        allUsers: getUsers(state, ownProps),
        staffSlots: getSlots(state)
    }),
    {
        ...User.actions,
        ...StaffSlots.actions
    }
)(EditUser);

export default withRouter(EditUser);
