import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import { Proposal } from "../models";
import { Organization, FiscalAgent, ProposalTab } from "../../home/models";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import HelpLabel from "../../common/HelpLabel";
import Subquestion from "../../common/Subquestion";
import Select from "../../common/Select";
import states from "../../common/states.json";

import { getValue } from "../../../api/utils";
import { createSelector } from "../../common/orm";

const getOrganizations = createSelector(schema => {
    return schema.Organization.filter({ is_active: true })
        .orderBy("organization_name")
        .toRefArray();
});

const getOrgFiscalAgents = createSelector(schema => {
    return schema.FiscalAgent.filter({ is_active: true })
        .orderBy("name")
        .toRefArray();
});
const getTab = ProposalTab.selectMessage(2);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    link: {
        cursor: "pointer"
    }
});

class Collaborators_OrganizationTab extends Component {
    state = {
        selectkey: window.performance.now(),
        formkey: null,
        OrgDialogOpen: false,
        AgentDialogOpen: false
    };
    typingTimer = null;

    componentDidMount() {
        const { handleUnsavedFields, ormProposalUpdateLocalOnly, proposal } = this.props;
        document.title = "Proposal: Organization Info - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!proposal.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormProposalUpdateLocalOnly({
                    id: proposal.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { id } = this.props.proposal;
        const {
            ormProposalUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            proposal,
            ormProposalUpdateLocalOnly,
            authState
        } = this.props;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (
                (proposal.status !== "Draft In Progress" &&
                    proposal.status !== "Draft Feedback Received" &&
                    proposal.status !== "Revisions Needed") ||
                proposal.secondary_status === "Withdrawal Requested" ||
                proposal.secondary_status === "Withdrawn" ||
                (authState &&
                    authState.user &&
                    (authState.user.role === "Staff" || authState.user.role === "Member"))
            ) {
                ormProposalUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = proposal.map;
        values.financial_capacity = proposal.financial_capacity;
        values.modify_date_label = proposal.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                handlePrev();
            } else {
                handleNext();
            }
        }
    }

    handleHasAgent = (value, element) => {
        const { ormProposalUpdateLocalOnly } = this.props;
        const { id } = this.props.proposal;
        ormProposalUpdateLocalOnly({
            id: id,
            [element]: value,
        });
        if (value === true || value === "true") {
            this.setState({ hideAgent: false });
        } else {
            this.setState({ hideAgent: true });
            ormProposalUpdateLocalOnly({
                id: id,
                fiscal_agent: null,
                fiscal_agent_id: null,
                agent_contact_name: null,
                agent_contact_email: null,
                agent_contact_phone: null
            });
        }
        this.setState({ selectkey: window.performance.now() });
    };

    handleOrgChoice = (val, element) => {
        const { ormProposalUpdateLocalOnly } = this.props;
        const { id } = this.props.proposal;
        ormProposalUpdateLocalOnly({
            id: id,
            [element]: val
        });
        this.setState({ selectkey: window.performance.now() });
    };

    errorValidator = values => {
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return email && !re.test(email) ? "Invalid email address" : null;
        };
        var valObj = {
            agent_contact_email: validateEmail(values.agent_contact_email)
        };

        return valObj;
    };

    descriptionTimer = (val, element) => {
        const { ormProposalUpdateLocalOnly, proposal } = this.props;
        const { id } = proposal;

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            ormProposalUpdateLocalOnly({
                id: id,
                [element]: val
            });
        }, 300);
    };

    render() {
        const {
            classes,
            proposal,
            organizations,
            fiscalagents,
            handleUnsavedFields,
            ActionMenu,
            ormOrganizationCreate,
            ormFiscalAgentCreate,
            ormProposalUpdateLocalOnly,
            tab,
            authState
        } = this.props;
        const { selectkey, formkey, OrgDialogOpen, AgentDialogOpen } = this.state;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (authState &&
                authState.user &&
                (authState.user.role === "Staff" || authState.user.role === "Member" || authState.user.is_read_only));

        const has_fiscal_agent = getValue(proposal, "has_fiscal_agent");

        // Why isn't this working in the selector?
        proposal.formData["org_data"] = proposal.org_data;
        proposal.formData["agent_data"] = proposal.agent_data;

        const recipient = getValue(proposal, "org_data.recipient");
        const dont_show_fiscal = recipient === "State Government" || recipient === "Federal Government";

        var ORG_CHOICE_LIST = organizations.map(x => {
            return { label: x.label, value: x.id };
        });

        var AGENT_CHOICE_LIST = fiscalagents.map(x => {
            return { label: x.name, value: x.id };
        });
        AGENT_CHOICE_LIST.unshift({ label: "[Clear Fiscal Agent]", value: null })

        var is_dnr = false;
        if (authState && authState.user.is_read_only) is_dnr = true;
        var is_member = false;
        if (authState && authState.user.role === "Member") is_member = true;

        return (
            <>
                <Form
                    getApi={el => (this.form = el)}
                    key={selectkey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    defaultValues={proposal.formData}
                    formDidUpdate={() => handleUnsavedFields(true)}
                    validateError={this.errorValidator}
                    onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={16} style={{ marginTop: 0 }}>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography variant="titleAction">Organization Contact Information</Typography>
                                    {ActionMenu}
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Select
                                        field="organization"
                                        disabled={is_read_only}
                                        options={ORG_CHOICE_LIST}
                                        checkFocus={proposal}
                                        focusRemoval={ormProposalUpdateLocalOnly}
                                        fullWidth
                                        eventHandle={(val, ele) => this.handleOrgChoice(val, ele)}>
                                        <HelpLabel
                                            showLabel={true}
                                            inputLabel="Organization"
                                            htmlText={true}
                                            helpText={
                                                tab.sub_tabs
                                                    .find(tht => tht.tab_name === "Organization Info")
                                                    .tab_help_texts.find(tht => tht.label === "Organization").text
                                            }
                                        />
                                    </Select>
                                    {!is_dnr && !is_member && (
                                        <Link
                                            onClick={() =>
                                                this.setState({ OrgDialogOpen: true, formkey: window.performance.now() })
                                            }
                                            className={classes.link}>
                                            Don't see your organization? Is the address incorrect? Request to have it added
                                            or updated.
                                        </Link>
                                    )}
                                    <TextField field="org_data.web_address" disabled fullWidth label="Web Address" />
                                    <Grid container spacing={16}>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="org_data.mailing_address"
                                                disabled
                                                fullWidth
                                                label="Mailing Address"
                                            />
                                            <TextField field="org_data.city" disabled fullWidth label="City" />
                                            <TextField field="org_data.zip" disabled fullWidth label="Zip Code" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                field="org_data.address_2"
                                                disabled
                                                fullWidth
                                                label="Address 2"
                                            />
                                            <Select
                                                field="org_data.state"
                                                label="State"
                                                disabled
                                                options={states}
                                                fullWidth
                                            />
                                            <TextField
                                                phoneNumber
                                                field="org_data.phone"
                                                disabled
                                                fullWidth
                                                label="Phone"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography variant="h3" gutterBottom>
                                        Organization Description
                                    </Typography>
                                    <TextField
                                        disabled={is_read_only}
                                        field="org_description"
                                        checkFocus={proposal}
                                        focusRemoval={ormProposalUpdateLocalOnly}
                                        multiline
                                        eventHandle={this.descriptionTimer}
                                        maxWords="200"
                                        fullWidth>
                                        <HelpLabel
                                            showLabel={true}
                                            inputLabel="Provide Description of the Organization. (200 word limit)"
                                            htmlText={true}
                                            helpText={
                                                tab.sub_tabs
                                                    .find(tht => tht.tab_name === "Organization Info")
                                                    .tab_help_texts.find(
                                                        tht =>
                                                            tht.label ===
                                                            "Provide Description of the Organization. (200 word limit)"
                                                    ).text
                                            }
                                        />
                                    </TextField>
                                    {!dont_show_fiscal && (
                                        <>
                                            <HelpLabel
                                                showLabel={true}
                                                title={true}
                                                inputLabel="Fiscal Agent"
                                                htmlText={true}
                                                helpText={
                                                    tab.sub_tabs
                                                        .find(tht => tht.tab_name === "Organization Info")
                                                        .tab_help_texts.find(tht => tht.label === "Fiscal Agent").text
                                                }
                                            />
                                            <RadioGroup
                                                field="has_fiscal_agent"
                                                name="has_fiscal_agent"
                                                checkFocus={proposal}
                                                focusRemoval={ormProposalUpdateLocalOnly}
                                                label="Does the organization have a fiscal agent for this project?"
                                                eventHandle={this.handleHasAgent}
                                                fullWidth
                                                options={[
                                                    { label: "Yes", value: "true", disabled: is_read_only },
                                                    { label: "No", value: "false", disabled: is_read_only }
                                                ]}
                                                alignment={true}></RadioGroup>
                                            {has_fiscal_agent && (
                                                <Subquestion
                                                    component={
                                                        <>
                                                            <Select
                                                                field="fiscal_agent"
                                                                label="Fiscal Agent Name"
                                                                checkFocus={proposal}
                                                                focusRemoval={ormProposalUpdateLocalOnly}
                                                                disabled={is_read_only}
                                                                options={AGENT_CHOICE_LIST}
                                                                fullWidth
                                                                eventHandle={(val, ele) =>
                                                                    this.handleOrgChoice(val, ele)
                                                                }
                                                            />
                                                            <Link
                                                                onClick={() =>
                                                                    this.setState({
                                                                        AgentDialogOpen: true,
                                                                        formkey: window.performance.now()
                                                                    })
                                                                }
                                                                className={classes.link}>
                                                                Don't see your fiscal agent? Request to have it added.
                                                            </Link>
                                                            <Grid container spacing={16}>
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        field="agent_contact_name"
                                                                        fullWidth
                                                                        disabled={is_read_only}
                                                                        label="Contact Name"
                                                                        checkFocus={proposal}
                                                                        focusRemoval={ormProposalUpdateLocalOnly}
                                                                    />
                                                                    <TextField
                                                                        field="agent_contact_email"
                                                                        fullWidth
                                                                        disabled={is_read_only}
                                                                        label="Contact Email"
                                                                        checkFocus={proposal}
                                                                        focusRemoval={ormProposalUpdateLocalOnly}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        field="agent_contact_phone"
                                                                        disabled={is_read_only}
                                                                        fullWidth
                                                                        phoneNumber
                                                                        label="Contact Phone"
                                                                        checkFocus={proposal}
                                                                        focusRemoval={ormProposalUpdateLocalOnly}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <TextField
                                                                field="agent_data.web_address"
                                                                disabled
                                                                fullWidth
                                                                label="Web Address"
                                                            />
                                                            <Grid container spacing={16}>
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        field="agent_data.mailing_address"
                                                                        disabled
                                                                        fullWidth
                                                                        label="Mailing Address"
                                                                    />
                                                                    <TextField
                                                                        field="agent_data.city"
                                                                        disabled
                                                                        fullWidth
                                                                        label="City"
                                                                    />
                                                                    <TextField
                                                                        field="agent_data.zip"
                                                                        disabled
                                                                        fullWidth
                                                                        label="Zip Code"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField
                                                                        field="agent_data.address_2"
                                                                        disabled
                                                                        fullWidth
                                                                        label="Address 2"
                                                                    />
                                                                    <Select
                                                                        field="agent_data.state"
                                                                        label="State"
                                                                        disabled
                                                                        options={states}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        style={{ float: "left" }}
                                        disabled={is_read_only}
                                        onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                        className={classes.button}>
                                        Save and Return to Previous Step
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        onClick={() => this.setState({ draftClick: true })}
                                        className={classes.button}>
                                        Save Draft and Return to Dashboard
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        color="primary"
                                        onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                        Save and Proceed to Next Step
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <OrganizationDialog
                    open={OrgDialogOpen}
                    ormOrganizationCreate={ormOrganizationCreate}
                    returnDialog={() => this.setState({ OrgDialogOpen: false })}
                    classes={classes}
                    formkey={formkey}
                    label="Division, College, Center, or Program Name"
                    value={
                        tab.sub_tabs
                            .find(tht => tht.tab_name === "Organization Info")
                            .tab_help_texts.find(tht => tht.label === "Division, College, Center, or Program Name").text
                    }
                />
                <FiscalAgentDialog
                    open={AgentDialogOpen}
                    ormFiscalAgentCreate={ormFiscalAgentCreate}
                    returnDialog={() => this.setState({ AgentDialogOpen: false })}
                    classes={classes}
                    formkey={formkey}
                />
            </>
        );
    }
}

class OrganizationDialog extends Component {
    state = {
        OrgThanksDialogOpen: false
    };

    saveOrganization(values) {
        const { ormOrganizationCreate, returnDialog } = this.props;
        ormOrganizationCreate({
            is_active: false,
            ...values
        }).then(response => {
            returnDialog();
            this.setState({ OrgThanksDialogOpen: true });
        });
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        /*const validateTelephone = telephone => {
            if (telephone && telephone.trim() !== "(   )    -" && !telephone.match(/^\(\d{3}\)[ ]?\d{3}[-]?\d{4}$/))
                return "Invalid Telephone";
            else return null;
        };*/
        var valObj = {
            organization_name: validateRequired(values.organization_name)
            //phone: validateTelephone(values.phone)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, formkey, label, value } = this.props;
        const { OrgThanksDialogOpen } = this.state;

        return (
            <>
                <Dialog role="main" aria-label="Request to Add Organization" open={open}>
                    <Toolbar>
                        <Typography variant="h1" className={classes.flex}>
                            Request to Add Organization
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                key={formkey}
                                validateOnSubmit={true}
                                validateError={this.errorValidator}
                                onSubmit={values => this.saveOrganization(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Organization Name"
                                                    field="organization_name"
                                                    fullWidth
                                                />
                                                <TextField field="department" fullWidth>
                                                    <HelpLabel
                                                        showLabel={true}
                                                        inputLabel={label}
                                                        htmlText={true}
                                                        helpText={value}
                                                    />
                                                </TextField>
                                                <TextField label="Web Address" field="web_address" fullWidth />
                                                <Grid container spacing={16}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Mailing Address"
                                                            field="mailing_address"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField label="Address 2" field="address_2" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField label="City" field="city" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            field="state"
                                                            label="State"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField label="Zip Code" field="zip" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField phoneNumber label="Phone" field="phone" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button fullWidth onClick={() => returnDialog()}>
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            fullWidth
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary">
                                                            Submit Request
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog role="main" aria-label="Thank You for Your Submission" open={OrgThanksDialogOpen}>
                    <DialogTitle>Thank You for Your Submission</DialogTitle>
                    <DialogContent>
                        <Typography>
                            An email has been sent to LCCMR staff for review. You will be notified by email when the new
                            organization has been added. Please fill it in when you have been notified it has been
                            added.
                        </Typography>
                        <Typography>
                            In the meantime you can leave the sponsoring organization blank and continue with your
                            application.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ OrgThanksDialogOpen: false });
                                returnDialog();
                            }}
                            color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

class FiscalAgentDialog extends Component {
    state = {
        OrgThanksDialogOpen: false
    };

    saveOrgFiscalAgent(values) {
        const { ormFiscalAgentCreate, returnDialog } = this.props;

        ormFiscalAgentCreate({
            is_active: false,
            ...values
        }).then(response => {
            returnDialog();
            this.setState({ OrgThanksDialogOpen: true });
        });
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        var valObj = {
            name: validateRequired(values.name)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, formkey } = this.props;
        const { OrgThanksDialogOpen } = this.state;

        return (
            <>
                <Dialog role="main" aria-label="Request to Add Fiscal Agent" open={open}>
                    <Toolbar>
                        <Typography variant="h1" className={classes.flex}>
                            Request to Add Fiscal Agent
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                key={formkey}
                                validateOnSubmit={true}
                                validateError={this.errorValidator}
                                onSubmit={values => this.saveOrgFiscalAgent(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <TextField label="Fiscal Agent Name" field="name" fullWidth />
                                                <TextField label="Web Address" field="web_address" fullWidth />
                                                <Grid container spacing={16}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="Mailing Address"
                                                            field="mailing_address"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField label="Address 2" field="address_2" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField label="City" field="city" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            field="state"
                                                            label="State"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField label="Zip Code" field="zip" fullWidth />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={6}>
                                                        <Button fullWidth onClick={() => returnDialog()}>
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            fullWidth
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary">
                                                            Submit Request
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog role="main" aria-label="Thank You for Your Submission" open={OrgThanksDialogOpen}>
                    <DialogTitle>Thank You for Your Submission</DialogTitle>
                    <DialogContent>
                        <Typography>
                            An email has been sent to LCCMR staff for review. You will be notified by email when the new
                            fiscal agent has been added. Please fill it in when you have been notified it has been
                            added.
                        </Typography>
                        <Typography>
                            In the meantime you can leave the fiscal agent blank and continue with your application.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ OrgThanksDialogOpen: false });
                                returnDialog();
                            }}
                            color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

Collaborators_OrganizationTab = connect(
    (state, ownProps) => ({
        organizations: getOrganizations(state, ownProps),
        fiscalagents: getOrgFiscalAgents(state, ownProps),
        authState: state.auth,
        tab: getTab(state, ownProps)
    }),
    {
        ...Proposal.actions,
        ...Organization.actions,
        ...FiscalAgent.actions
    }
)(Collaborators_OrganizationTab);

export default withStyles(styles)(withRouter(Collaborators_OrganizationTab));
