import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MuiTooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import { createSelector } from "../../common/orm";
import Select from "../../common/Select";
import HelpLabel from "../../common/HelpLabel";
import TextField from "../../common/TextField";
import WarningDialog from "../../common/WarningDialog";
import RadioGroup from "../../common/RadioGroup";
import { getValue, setValue } from "../../../api/utils";

import { ProposalNonENRTFFund } from "../models";
import { ProposalTab } from "../../home/models";

const getFunds = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalNonENRTFFund.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getTab = ProposalTab.selectMessage(12);

const type_options = [
    { value: "Cash", label: "Cash" },
    { value: "In-Kind", label: "In-Kind" }
];

const status_options = [
    { value: "Potential", label: "Potential" },
    { value: "Pending", label: "Pending" },
    { value: "Secured", label: "Secured" }
];

const styles = theme => ({
    button: {
        marginRight: theme.spacing.unit * 2
    },
    normalCell: {
        width: 80
    },
    amountCell: {
        width: 60,
        textAlign: "right"
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    hide: {
        display: "none"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    tableCellNoBorder: {
        border: "none"
    },
    tableCellBold: {
        fontWeight: "Bold",
        color: "#000000"
    },
    rightAlign: {
        textAlign: "right"
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
});

const columnDataFunds = [
    { id: "actions", numeric: false, label: "Actions" },
    { id: "type", numeric: false, label: "Type" },
    { id: "source", numeric: false, label: "Source" },
    { id: "specific_source", numeric: false, label: "Specific Source" },
    { id: "use", numeric: false, label: "Use" },
    { id: "status", numeric: false, label: "Status" },
    { id: "amount", numeric: false, label: "$ Amount" }
];

class BudgetFundsTab extends Component {
    state = {
        FundsDialogObj: null,
        WarningDialogObj: null,
        error: null
    };

    handleClose = () => {
        this.setState({ FundsDialogObj: null });
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Budget Fund ";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        var valObj = {};

        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        const isValidValue = path => {
            let val = getValue(values, path);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, val >= 0 ? null : "Value must be >= 0.");
        };

        isRequired("type");
        isRequired("source");
        isRequired("specific_source");
        isRequired("description");
        isValidValue("amount");
        isRequired("status");

        return valObj;
    };

    saveFunds(values) {
        const { id } = this.props.proposal;
        const { ormProposalNonENRTFFundCreate, ormProposalNonENRTFFundUpdate } = this.props;

        if (values.id) {
            ormProposalNonENRTFFundUpdate({
                ...values
            });
            this.props.calcFundingTotal();
        } else {
            ormProposalNonENRTFFundCreate({
                ...values,
                proposal: id
            }).then(response => {
                this.props.calcFundingTotal();
            });
        }
        this.setState({ FundsDialogObj: null });
    }

    deleteRecord = () => {
        this.props.ormProposalNonENRTFFundPromiseDelete(this.state.WarningDialogObj.id).then(id => {
            this.props.calcFundingTotal();
        });
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    handleClose = () => {
        this.setState({ FundsDialogObj: false });
    };

    formatMoney = amount => {
        var amountnum = parseFloat(amount);
        var amountstring = amountnum.toFixed(2);
        if (amountnum >= 1000) {
            amountstring =
                amountstring.substring(0, amountstring.length - 6) +
                "," +
                amountstring.substring(amountstring.length - 6);
        }
        if (amountnum >= 1000000) {
            amountstring =
                amountstring.substring(0, amountstring.length - 10) +
                "," +
                amountstring.substring(amountstring.length - 10);
        }
        return amountstring;
    };

    render() {
        const { classes, funds, ActionMenu, handleNext, handlePrev, history, proposal, tab } = this.props;
        const { FundsDialogObj, WarningDialogObj } = this.state;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        var total = 0.0;

        var interact_string = FundsDialogObj && FundsDialogObj["id"] ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        funds.map(x => (total += x.amount));

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <Typography variant="titleAction">Non-ENRTF Funds Contributed to the Project</Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12}>
                    <div style={{ border: "1px solid darkgrey", padding: "10px" }}>
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: tab.sub_tabs
                                        .find(tht => tht.tab_name === "Non-ENRTF Funds Contributed")
                                        .tab_help_texts.find(
                                            tht => tht.label === "Non-ENRTF Funds Contributed to the Project"
                                        ).text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        disabled={is_read_only}
                        type="submit"
                        color="primary"
                        fullWidth
                        onClick={() =>
                            this.setState({
                                FundsDialogObj: {
                                    id: null,
                                    classified: false,
                                    ineligible_expenses: false
                                }
                            })
                        }>
                        <AddCircleOutlineIcon />
                        &nbsp;&nbsp; Add Non-ENRTF Funds Contributed to the Project
                    </Button>
                    <br />
                    <br />
                    <Table className={classes.table}>
                        <EnhancedTableHead stickyHeader columnData={columnDataFunds} />
                        <TableBody>
                            {funds.map(n => {
                                return (
                                    <TableRow key={n.id}>
                                        <TableCell className={classes.nowrap}>
                                            <MuiTooltip title="Edit">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Edit"
                                                    onClick={() => this.setState({ FundsDialogObj: n })}>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Delete"
                                                    onClick={() => this.setState({ WarningDialogObj: n })}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="View">
                                                <Button
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="View"
                                                    onClick={() => this.setState({ FundsDialogObj: n })}>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </TableCell>
                                        <TableCell className={classes.normalCell}>{n.type}</TableCell>
                                        <TableCell className={classes.normalCell}>{n.source}</TableCell>
                                        <TableCell>{n.specific_source}</TableCell>
                                        <TableCell>{n.description}</TableCell>
                                        <TableCell className={classes.normalCell}>{n.status}</TableCell>
                                        <TableCell className={classes.amountCell}>
                                            {n.amount ? this.formatMoney(n.amount) : ""}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {funds.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={7} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Records</caption>
                                    </TableCell>
                                </TableRow>
                            )}
                            {funds.length > 0 && (
                                <TableRow key={"total"}>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder,
                                            classes.rightAlign
                                        )}>
                                        Total
                                    </TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.rightAlign,
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder
                                        )}>
                                        $&nbsp;{total.toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        color="primary"
                        className={classes.button}
                        onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>
                <Dialog role="main" aria-label={interact_string + " Non-ENRTF Funds Contributed"} open={FundsDialogObj ? true : false} onClose={this.handleClose} disableBackdropClick={true}>
                    <Form
                        defaultValues={FundsDialogObj}
                        validateOnSubmit={true}
                        dontValidateOnMount={true}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.saveFunds(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle
                                    className={classes.dialogTitleMargin}
                                    disableTypography
                                    id="form-dialog-title">
                                    <Typography variant="h1" className={classes.flex}>{interact_string} Non-ENRTF Funds Contributed</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={this.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={4}>
                                            <Select
                                                field="type"
                                                fullWidth
                                                disabled={is_read_only}
                                                margin="normal"
                                                options={type_options}
                                                label="Type of Funds"></Select>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="source"
                                                name="source"
                                                fullWidth
                                                disabled={is_read_only}
                                                options={[
                                                    { label: "State", value: "State", disabled: is_read_only },
                                                    { label: "Non-State", value: "Non-State", disabled: is_read_only }
                                                ]}
                                                alignment={true}
                                                label={"Source of Funds"}
                                            />

                                            <TextField
                                                multiline
                                                field="specific_source"
                                                fullWidth
                                                disabled={is_read_only}>
                                                <HelpLabel
                                                    inputLabel="Specific Source of Funds, including Legal Citation for State Funds"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Non-ENRTF Funds Contributed")
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Specific Source of Funds, including Legal Citation for State Funds"
                                                            ).text
                                                    }
                                                />
                                            </TextField>

                                            <TextField
                                                field="description"
                                                multiline
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Describe Use"></TextField>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                field="amount"
                                                useTextFormat
                                                label="Amount"
                                                margin="normal"
                                                fullWidth
                                                disabled={is_read_only}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Select
                                                field="status"
                                                margin="normal"
                                                options={status_options}
                                                label="Status"
                                                fullWidth
                                                disabled={is_read_only}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.setState({ FundsDialogObj: null })}>Cancel</Button>
                                    <Button color="primary" variant="contained" type="submit" disabled={is_read_only}>
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>

                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Non-ENRTF Funds Contributed record"
                    text={"Are you sure you want to delete this Non-ENRTF Funds Contributed record?"}
                />
            </Grid>
        );
    }
}

BudgetFundsTab = connect(
    (state, ownProps) => ({
        funds: getFunds(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...ProposalNonENRTFFund.actions
    }
)(BudgetFundsTab);

export default withStyles(styles)(withRouter(BudgetFundsTab));
