import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import TextField from "../common/TextField";
import Select from "../common/Select";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import { createSelector } from "../common/orm";
import { FundingCategory } from "../home/models";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    }
});

const getCategories = createSelector(schema => {
    return schema.FundingCategory.all()
        .orderBy(["year_id", "suffix"], ["desc", "asc"])
        .toRefArray();
});
const getRFPs = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});

const columnData = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "year", numeric: true, label: "RFP Year" },
    { id: "category_name", numeric: true, label: "Category Name" },
    { id: "suffix", numeric: false, label: "Suffix" }
];

class CategoryTable extends Component {
    state = {
        messageDialogOpen: false,
        messageDialogOpen2: false,
        messageId: null
    };

    componentDidMount() {
        document.title = "Configure RFP Funding Categories - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    render() {
        const {
            categories,
            classes,
            ormFundingCategoryDelete,
            ormFundingCategoryCreate,
            ormFundingCategoryUpdate,
            years
        } = this.props;
        const { messageId, messageDialogOpen, messageDialogOpen2 } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Configure RFP Funding Categories" admin_page />
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                            Funding Categories
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.setState({ messageDialogOpen: true, messageId: null })}>
                            Add New Category
                        </Button>
                        <Button
                            style={{ marginLeft: 24 }}
                            variant="contained"
                            color="primary"
                            onClick={() => this.setState({ messageDialogOpen2: true })}>
                            Copy Categories from Previous Year
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Table className={classes.table}>
                            <EnhancedTableHead stickyHeader columnData={columnData} />
                            <TableBody>
                                {categories.map(n => {
                                    return (
                                        <TableRow key={n.id}>
                                            <CustomTableCell className={classes.nowrap}>
                                                <Tooltip title="Edit Category">
                                                    <Button
                                                        color="primary"
                                                        className={classes.deleteWidth}
                                                        onClick={() =>
                                                            this.setState({ messageDialogOpen: true, messageId: n })
                                                        }>
                                                        <CreateIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Delete Category">
                                                    <Button
                                                        color="primary"
                                                        className={classes.deleteWidth}
                                                        onClick={() => ormFundingCategoryDelete(n.id)}>
                                                        <DeleteIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                            <CustomTableCell>{n.year_label}</CustomTableCell>
                                            <CustomTableCell>{n.category_name}</CustomTableCell>
                                            <CustomTableCell>{n.suffix}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {categories.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={3} className={classes.centerAlign}>
                                            <caption style={{display:"inline"}}>No Categories Found</caption>
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <CategoryDialog
                    ormFundingCategoryCreate={ormFundingCategoryCreate}
                    ormFundingCategoryUpdate={ormFundingCategoryUpdate}
                    years={years}
                    classes={classes}
                    returnDialog={() => this.setState({ messageDialogOpen: false })}
                    open={messageDialogOpen}
                    messageId={messageId}
                />
                <CopyDialog
                    ormFundingCategoryCreate={ormFundingCategoryCreate}
                    years={years}
                    categories={categories}
                    classes={classes}
                    returnDialog={() => this.setState({ messageDialogOpen2: false })}
                    open={messageDialogOpen2}
                />
            </AppContainer>
        );
    }
}

class CategoryDialog extends Component {
    addMessage(values) {
        const { returnDialog, ormFundingCategoryCreate, ormFundingCategoryUpdate, messageId } = this.props;

        if (messageId) {
            ormFundingCategoryUpdate({
                id: messageId.id,
                ...values
            });
        } else {
            ormFundingCategoryCreate(values);
        }
        returnDialog();
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        const isRequired1 = val => {
            return !val || val.length > 1 ? "Error" : null;
        };
        var valObj = {
            year_id: isRequired(values.year_id),
            category_name: isRequired(values.category_name),
            suffix: isRequired1(values.suffix)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, messageId, years } = this.props;

        var years_dropdown = [];
        years.forEach(function(s) {
            years_dropdown.push({ label: s.year, value: s.id });
        });

        return (
            <Dialog role="main" aria-label={!messageId ? "Add New Funding Category" : "Edit Funding Category"} open={open}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        {!messageId ? "Add New" : "Edit"} Funding Category
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={messageId}
                            onSubmit={values => this.addMessage(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <Select
                                                field="year_id"
                                                label="RFP Year"
                                                options={years_dropdown}
                                                fullWidth
                                            />
                                            <TextField field="category_name" label="Category Name" fullWidth />
                                            <TextField field="suffix" label="Suffix" fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class CopyDialog extends Component {
    addMessage(values) {
        const { returnDialog, ormFundingCategoryCreate, categories } = this.props;

        var cats = categories.filter(c => c.year_id === values.year1);

        cats.forEach(function(c) {
            ormFundingCategoryCreate({
                year_id: values.year2,
                category_name: c.category_name,
                suffix: c.suffix
            });
        });
        returnDialog();
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            year1: isRequired(values.year1),
            year2: isRequired(values.year2)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, years } = this.props;

        var years_dropdown = [];
        years.forEach(function(s) {
            years_dropdown.push({ label: s.year, value: s.id });
        });

        return (
            <Dialog role="main" aria-label="Copy Funding Categories" open={open}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        Copy Funding Categories
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={values => this.addMessage(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <Select
                                                field="year1"
                                                label="Copy Categories From"
                                                options={years_dropdown}
                                                fullWidth
                                            />
                                            <Select
                                                field="year2"
                                                label="Copy Categories To"
                                                options={years_dropdown}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Copy
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

CategoryTable = connect(
    (state, ownProps) => ({
        authState: state.auth,
        categories: getCategories(state, ownProps),
        years: getRFPs(state, ownProps)
    }),
    {
        ...FundingCategory.actions
    }
)(CategoryTable);

export default withStyles(styles)(withRouter(CategoryTable));
