import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CreateIcon from "@material-ui/icons/Create";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

import { withStyles } from "@material-ui/core";

import { Proposal, ProposalParcel } from "../models";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import MultiSelect from "../../common/MultiSelect";
import HelpLabel from "../../common/HelpLabel";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import Subquestion from "../../common/Subquestion";
import counties from "../../common/counties.json";
import { getValue } from "../../../api/utils";
import TablePaginationActionsWrapped from "../../common/Paginator";
import { createSelector } from "../../common/orm";
import { ProposalTab } from "../../home/models";

const getParcels = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalParcel.filter({ proposal: id })
            .orderBy("id", "asc")
            .toRefArray();
    }
);
const getTab = ProposalTab.selectMessage(14);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    centerAlign: {
        textAlign: "center"
    },
    hide: {
        display: "none"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    }
});

const columnData = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        width: "20px"
    },
    { id: "name", numeric: false, label: "Name", allowSort: true },
    { id: "county", numeric: false, label: "County", allowSort: true },
    {
        id: "site_significance",
        numeric: false,
        label: "Site Significance",
        allowSort: true
    },
    { id: "activity", numeric: false, label: "Activity", allowSort: true },
    { id: "acres", numeric: true, label: "Acres", allowSort: true },
    { id: "miles", numeric: true, label: "Miles", allowSort: true },
    {
        id: "estimated_cost",
        numeric: true,
        label: "Estimated Cost",
        allowSort: true
    },
    {
        id: "owner_type",
        numeric: false,
        label: "Type of Landowner",
        allowSort: true
    },
    {
        id: "title_holder",
        numeric: false,
        label: "Proposed Title or Easement Holder",
        allowSort: true
    },
    { id: "status", numeric: false, label: "Status of Work", allowSort: true },
    { id: "notes", numeric: false, label: "Notes", allowSort: true }
];

class ParcelTab extends Component {
    state = {
        dialogOpen: false,
        parcelId: null,
        key: null,
        order: "asc",
        orderBy: "name",
        page: 0,
        rowsPerPage: 25
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Parcel List - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props.parcels.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    render() {
        const {
            classes,
            proposal,
            parcels,
            ormProposalParcelDelete,
            ActionMenu,
            ormProposalParcelCreate,
            ormProposalParcelUpdate,
            ormProposalParcelUpdateLocalOnly,
            handleNext,
            handlePrev,
            history,
            tab
        } = this.props;
        const { dialogOpen, parcelId, key, order, orderBy, page, rowsPerPage } = this.state;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));
        var totalAcres = 0.0;
        var totalMiles = 0.0;
        var totalCost = 0;

        parcels.forEach(p => {
            if (p.acres) {
                totalAcres += parseFloat(p.acres);
            }
            if (p.miles) {
                totalMiles += parseFloat(p.miles);
            }
            if (p.estimated_cost) {
                totalCost += parseInt(p.estimated_cost);
            }
        });

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <HelpLabel
                        inputLabel="Parcel List"
                        style={{ display: "inline-block", marginTop: 8 }}
                        showLabel={true}
                        htmlText={true}
                        helpText={
                            tab.sub_tabs
                                .find(tht => tht.tab_name === "Parcel List")
                                .tab_help_texts.find(tht => tht.label === "Parcel List").text
                        }
                    />
                    {ActionMenu}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        style={{ marginBottom: 8 }}
                        variant="contained"
                        fullWidth
                        disabled={is_read_only}
                        autoFocus={proposal.highlightedField === "parcel_table"}
                        color="primary"
                        onClick={() => {
                            ormProposalParcelCreate({ proposal: proposal.id }).then(response => {
                                this.setState({
                                    dialogOpen: true,
                                    parcelId: response,
                                    key: window.performance.now()
                                });
                            });
                        }}>
                        <AddCircleIcon />
                        &nbsp;&nbsp;&nbsp;Add Parcel
                    </Button>
                    <Table className={classes.table}>
                        <EnhancedTableHead
                            columnData={columnData}
                            stickyHeader
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                        />
                        <TableBody>
                            {parcels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                return (
                                    <TableRow key={n.id}>
                                        <CustomTableCell className={classes.nowrap}>
                                            <Tooltip title="Edit Parcel">
                                                <Button
                                                    color="primary"
                                                    aria-label="Edit Parcel"
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    onClick={() =>
                                                        this.setState({
                                                            dialogOpen: true,
                                                            parcelId: n.id,
                                                            key: window.performance.now()
                                                        })
                                                    }>
                                                    <CreateIcon color="primary" />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Delete Parcel">
                                                <Button
                                                    color="primary"
                                                    aria-label="Delete Parcel"
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    onClick={() => ormProposalParcelDelete(n.id)}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="View Parcel">
                                                <Button
                                                    color="primary"
                                                    aria-label="View Parcel"
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    onClick={() =>
                                                        this.setState({
                                                            dialogOpen: true,
                                                            parcelId: n.id,
                                                            key: window.performance.now()
                                                        })
                                                    }>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </Tooltip>
                                        </CustomTableCell>
                                        <CustomTableCell>{n.name}</CustomTableCell>
                                        <CustomTableCell>{n.county.join(", ")}</CustomTableCell>
                                        <CustomTableCell>{n.site_significance}</CustomTableCell>
                                        <CustomTableCell>{n.activity}</CustomTableCell>
                                        <CustomTableCell>{n.acres}</CustomTableCell>
                                        <CustomTableCell>{n.miles}</CustomTableCell>
                                        <CustomTableCell>
                                            {n.estimated_cost ? n.estimated_cost.toLocaleString() : null}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.owner_type}</CustomTableCell>
                                        <CustomTableCell>{n.title_holder}</CustomTableCell>
                                        <CustomTableCell>{n.status}</CustomTableCell>
                                        <CustomTableCell>{n.notes}</CustomTableCell>
                                    </TableRow>
                                );
                            })}
                            {parcels.length < 1 && (
                                <TableRow>
                                    <CustomTableCell colSpan={11} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Parcels Found</caption>
                                    </CustomTableCell>
                                </TableRow>
                            )}
                            {parcels.length > 0 && (
                                <TableRow>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell>
                                        <b>Totals</b>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <b>{Math.round((totalAcres + Number.EPSILON) * 100) / 100}</b>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <b>{totalMiles.toPrecision(3)}</b>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <b>{"$ " + totalCost.toLocaleString()}</b>
                                    </CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                </TableRow>
                            )}
                            {parcels.length > 25 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={12}
                                            count={parcels.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={is_read_only}
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button
                        variant="contained"
                        disabled={is_read_only}
                        type="submit"
                        color="primary"
                        onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>
                <ParcelDialog
                    open={dialogOpen}
                    ormProposalParcelUpdateLocalOnly={ormProposalParcelUpdateLocalOnly}
                    ormProposalParcelUpdate={ormProposalParcelUpdate}
                    parcelId={parcelId}
                    returnDialog={() => this.setState({ dialogOpen: false })}
                    classes={classes}
                    formkey={key}
                    parcels={parcels}
                    proposal={proposal}
                    tab={tab}
                />
            </Grid>
        );
    }
}

class ParcelDialog extends Component {
    state = {
        selectKey: window.performance.now()
    };

    addParcel(values) {
        const { returnDialog, ormProposalParcelUpdate } = this.props;

        ormProposalParcelUpdate({
            ...values
        });

        returnDialog();
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name)
        };

        return valObj;
    };

    applyOther = (value, element) => {
        const { ormProposalParcelUpdateLocalOnly, parcelId, parcels } = this.props;
        const parc = parcels.find(p => p.id === parcelId);
        ormProposalParcelUpdateLocalOnly({
            ...parc,
            [element]: value
        });
        this.setState({ selectKey: window.performance.now() });
    };

    render() {
        const { classes, open, returnDialog, parcelId, formkey, parcels, proposal, tab } = this.props;
        const { selectKey } = this.state;

        const parc = parcels.find(p => p.id === parcelId);
        const owner_type = getValue(parc, "owner_type");
        const activity = getValue(parc, "activity");

        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        var interact_string = parc && parc.name ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        var show_justification = false;
        if (
            (owner_type === "Private" && activity === "Restoration") ||
            (owner_type === "Public" && activity === "Fee Title")
        )
            show_justification = true;

        var status_options = [];
        if (activity === "Restoration") {
            status_options = [
                { label: "Has Not Begun", value: "Has Not Begun" },
                { label: "In Progress", value: "In Progress" },
                { label: "Completed", value: "Completed" },
                {
                    label: "No Longer Being Considered",
                    value: "No Longer Being Considered"
                }
            ];
        } else {
            status_options = [
                { label: "Has Not Begun", value: "Has Not Begun" },
                {
                    label: "Negotiations In Progress",
                    value: "Negotiations In Progress"
                },
                { label: "Property Under Contract", value: "Property Under Contract" },
                { label: "Purchased", value: "Purchased" },
                {
                    label: "No Longer Being Considered",
                    value: "No Longer Being Considered"
                }
            ];
        }

        return (
            <Dialog role="main" aria-label={interact_string + " Parcel"} open={open}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        {interact_string} Parcel
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={formkey}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={parc}
                            onSubmit={values => this.addParcel(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField field="name" fullWidth disabled={is_read_only}>
                                                <HelpLabel
                                                    inputLabel="Parcel Name"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Parcel List")
                                                            .tab_help_texts.find(tht => tht.label === "Parcel Name")
                                                            .text
                                                    }
                                                />
                                            </TextField>
                                            <MultiSelect
                                                field="county"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="County"
                                                options={counties}
                                            />
                                            <TextField field="site_significance" fullWidth disabled={is_read_only}>
                                                <HelpLabel
                                                    inputLabel="Site Significance"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Parcel List")
                                                            .tab_help_texts.find(
                                                                tht => tht.label === "Site Significance"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                            <Select
                                                eventHandle={this.applyOther}
                                                field="activity"
                                                label="Activity"
                                                disabled={is_read_only}
                                                options={[
                                                    { label: "Fee Title", value: "Fee Title" },
                                                    {
                                                        label: "Conservation Easement",
                                                        value: "Conservation Easement"
                                                    },
                                                    {
                                                        label: "Easement - Other",
                                                        value: "Easement - Other"
                                                    },
                                                    { label: "Restoration", value: "Restoration" }
                                                ]}
                                                fullWidth
                                            />
                                            <Grid container spacing={16}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        useFloatFormat
                                                        field="acres"
                                                        label="Number of Acres"
                                                        disabled={is_read_only}
                                                        fullWidth></TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        useFloatFormat
                                                        field="miles"
                                                        fullWidth
                                                        disabled={is_read_only}>
                                                        <HelpLabel
                                                            inputLabel="Miles"
                                                            showLabel={true}
                                                            htmlText={true}
                                                            helpText={
                                                                tab.sub_tabs
                                                                    .find(tht => tht.tab_name === "Parcel List")
                                                                    .tab_help_texts.find(tht => tht.label === "Miles")
                                                                    .text
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        useTextFormat
                                                        field="estimated_cost"
                                                        label="Estimated Cost"
                                                        fullWidth
                                                        disabled={is_read_only}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Select
                                                eventHandle={this.applyOther}
                                                field="owner_type"
                                                label="Type of Current Land Owner"
                                                disabled={is_read_only}
                                                options={[
                                                    { label: "Public", value: "Public" },
                                                    { label: "Private", value: "Private" }
                                                ]}
                                                fullWidth
                                            />
                                            {show_justification && (
                                                <Subquestion
                                                    component={
                                                        <TextField
                                                            field="justification"
                                                            label="Justification"
                                                            fullWidth
                                                            disabled={is_read_only}
                                                        />
                                                    }
                                                />
                                            )}
                                            <TextField
                                                field="title_holder"
                                                label="Proposed Title or Easement Holder"
                                                fullWidth
                                                disabled={is_read_only}
                                            />
                                            <Select
                                                key={selectKey}
                                                field="status"
                                                label="Status of Work"
                                                options={status_options}
                                                fullWidth
                                                disabled={is_read_only}
                                            />
                                            <TextField
                                                multiline
                                                field="notes"
                                                label="Notes"
                                                fullWidth
                                                disabled={is_read_only}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={is_read_only}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

ParcelTab = connect(
    (state, ownProps) => ({
        parcels: getParcels(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...Proposal.actions,
        ...ProposalParcel.actions
    }
)(ParcelTab);

export default withStyles(styles)(withRouter(ParcelTab));
