import { ORM, Model as ORMModel, ForeignKey, createSelector as ormSelector } from "redux-orm";
import { AUTH_LOGOUT } from "../auth/actions";

const orm = new ORM();
export default orm;

export function createSelector(...args) {
    return ormSelector(orm, state => state.orm, ...args);
}

export function selectAll(cls, serialize = obj => obj.ref) {
    return createSelector(schema =>
        schema[cls.modelName]
            .all()
            .toModelArray()
            .map(serialize)
    );
}

export function selectByUrlId(cls, serialize = obj => obj.ref, param = "id") {
    return createSelector(
        (state, ownProps) => ownProps.match.params[param],
        (schema, objId) => {
            cls = schema[cls.modelName];
            if (cls.hasId(objId)) {
                var obj = cls.withId(objId);
                return serialize(obj);
            } else {
                return null;
            }
        }
    );
}

export function selectByProposalId(cls, serialize = obj => obj.ref) {
    return createSelector(
        (state, ownProps) => parseInt(ownProps.match.params["id"]),
        (schema, objId) => {
            cls = schema[cls.modelName];
            var obj;
            if (cls.modelName === "Proposal") {
                if (cls.idExists(objId)) {
                    obj = cls.withId(objId);
                    return serialize(obj);
                } else {
                    // FIXME: Render a 404 page?
                    return {};
                }
            } else {
                obj = schema[cls.modelName]
                    .all()
                    .filter(imp => imp.proposal === objId)
                    .toModelArray()
                    .map(serialize);
                return obj;
            }
        }
    );
}

function isRealUser(authState) {
    return authState && authState.user;
}

export class BaseModel extends ORMModel {
    static selectAll(serializer) {
        return selectAll(this, serializer);
    }

    static selectByUrlId(serializer, param = "id") {
        return selectByUrlId(this, serializer, param);
    }

    static selectByProposalId(serializer) {
        return selectByProposalId(this, serializer);
    }

    static get source() {
        return null;
    }

    // Restrict Loading initially when loading
    // Default False
    static get dontLoadByDefault() {
        return null;
    }

    // Only Load if user is admin (Search Views)
    // Default False
    static get admin_only() {
        return null;
    }

    // Only Load if user is regular (Each individual model)
    // Default False
    static get user_only() {
        return null;
    }

    // Only Load if user is member or staff
    // Default False
    static get member_or_staff() {
        return null;
    }

    static get actions() {
        const functionPrefix = `orm${this.modelName}`,
            typePrefix = `ORM_${this.modelName.toUpperCase()}`;
        return {
            [`${functionPrefix}Reload`]: params => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState();
                    if (!isRealUser(state.auth)) return;

                    if (cls.dontLoadByDefault === true && !params) return;

                    if (state.auth.user.role !== "Staff" && cls.admin_only) return;

                    if (state.auth.user.role !== "User" && cls.user_only) return;

                    if (state.auth.user.role === "User" && cls.member_or_staff) return;

                    dispatch({
                        type: `${typePrefix}_PULLING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };
                    fetch(`${cls.source}?format=json&t=${Date.now()}`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then(data =>
                            dispatch({
                                type: `${typePrefix}_PULLED`,
                                payload: data
                            })
                        )
                        .catch(e => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_PULLERROR`,
                                error: e
                            });
                        });
                };
            },
            [`${functionPrefix}ReloadOverWrite`]: (filters, callback) => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState();
                    if (!isRealUser(state.auth)) {
                        return;
                    }

                    dispatch({
                        type: `${typePrefix}_PULLING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };

                    var filterstr = "";
                    var keys = Object.keys(filters);

                    keys.forEach(x => {
                        filterstr += x + "=" + filters[x] + "&";
                    });

                    fetch(`${cls.source}?format=json&${filterstr}`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then(data => {
                            dispatch({
                                type: `${typePrefix}_PULLED`,
                                payload: data
                            });
                            if (callback) callback(data);
                        })
                        .catch(e => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_PULLERROR`,
                                error: e
                            });
                        });
                };
            }
        };
    }

    static fromResponse(data) {
        return data;
    }

    static reducer(action, cls) {
        const prefix = `ORM_${cls.modelName.toUpperCase()}`;
        switch (action.type) {
            case `${prefix}_PULLED`:
                const ids = action.payload.map(obj => obj.id);
                cls.exclude(obj => ids.includes(obj.id)).delete();
                action.payload.forEach(obj => cls.upsert(cls.fromResponse(obj)));
                break;
            default:
                break;
        }
    }
}

export class Model extends BaseModel {
    static get source() {
        return `/api/db/${this.pluralName}`;
    }

    static get pluralName() {
        return `${this.modelName.toLowerCase()}s`;
    }

    static get dontLoadByDefault() {
        return false;
    }

    static get admin_only() {
        return false;
    }

    static get user_only() {
        return false;
    }

    static get member_or_staff() {
        return false;
    }

    static createAction({ type, payload, effectIfLoggedIn }) {
        return (dispatch, getState) => {
            const state = getState();
            const { auth } = state;
            let action = {
                type: type,
                payload: payload
            };
            if (isRealUser(auth)) {
                action.meta = {
                    offline: {
                        effect: {
                            ...effectIfLoggedIn,
                            body: JSON.stringify(this.toRequest(payload)),
                            headers: {
                                Accept: "application/json",
                                Authorization: "Token " + auth.user.auth_token
                            }
                        },
                        commit: {
                            type: `${type}_PUSHED`
                        },
                        rollback: {
                            type: `${type}_PUSHERROR`,
                            meta: { objectId: payload.id }
                        }
                    }
                };
            }
            dispatch(action);
        };
    }

    static fail(message) {
        message = `LCCMR ORM Error: ${message}`;
        console.error(message);
        throw new Error(message);
    }

    static toRequest(data) {
        var req = {};
        Object.entries(data).forEach(([key, value]) => {
            if (this.fields[key] instanceof ForeignKey) {
                req[key + "_id"] = value;
            } else if (!(key in req)) {
                req[key] = value;
            }
        });
        return req;
    }

    static fromResponse(data) {
        Object.keys(data).forEach(key => {
            const field = key.replace(/_id$/, "");
            if (field !== key && this.fields[field] instanceof ForeignKey) {
                data[field] = data[key];
                delete data[key];
            }
        });
        return data;
    }

    static get actions() {
        const functionPrefix = `orm${this.modelName}`,
            typePrefix = `ORM_${this.modelName.toUpperCase()}`,
            baseActions = super.actions;

        return {
            [`${functionPrefix}Create`]: payload => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        dispatch({
                            type: `${typePrefix}_CREATE_PUSHERROR`,
                            error: new Error("Not logged in.")
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_CREATE_PUSHING`
                    });
                    return fetch(`${cls.source}?format=json&t=${Date.now()}`, {
                        method: "POST",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                        .then(result => {
                            if (!result.ok) {
                                return result.text().then(text => {
                                    throw new Error(text);
                                });
                            } else {
                                return result.json();
                            }
                        })
                        .then(data => {
                            data = cls.fromResponse(data);
                            dispatch({
                                type: `${typePrefix}_CREATE`,
                                payload: data
                            });
                            dispatch({
                                type: `${typePrefix}_CREATE_PUSHED`
                            });
                            return data.id;
                        })
                        .catch(e =>
                            dispatch({
                                type: `${typePrefix}_CREATE_PUSHERROR`,
                                error: e
                            })
                        );
                };
            },
            [`${functionPrefix}PromiseDelete`]: id => {
                var cls = this;
                return function(dispatch, getState) {
                    // Used when you need to wait for request to perform something because with offilne it isn't easy to catch
                    // when operation is performed with out bunch of work.

                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth)) {
                        return;
                    }
                    return fetch(`${cls.source}/${id}?&t=${Date.now()}`, {
                        method: "DELETE",
                        payload: { id },
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Token " + auth.user.auth_token
                        }
                    })
                        .then(result => {
                            if (!result.ok) {
                                return result.text().then(text => {
                                    throw new Error(text);
                                });
                            } else {
                                dispatch({
                                    type: `${typePrefix}_DELETE`,
                                    payload: { id: id }
                                });
                                dispatch({
                                    type: `${typePrefix}_PUSHED`
                                });
                                return id;
                            }
                        })
                        .catch(e =>
                            dispatch({
                                type: `${typePrefix}_PUSHERROR`,
                                error: e
                            })
                        );
                };
            },
            [`${functionPrefix}LoadDetail`]: (id, callback) => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState();
                    dispatch({
                        type: `${typePrefix}_DETAIL_LOADING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };
                    fetch(`${cls.source}/${id}?format=json`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Object)) {
                                throw new Error(data.detail || data);
                            }
                            return [data];
                        })
                        .then(data => {
                            dispatch({
                                type: `${typePrefix}_DETAIL_LOADED`,
                                payload: data
                            });
                            if (callback) callback(data);
                        })
                        .catch(e => {
                            dispatch({
                                type: `${typePrefix}_DETAIL_ERROR`,
                                error: e
                            });
                        });
                };
            },
            [`${functionPrefix}LoadDetailChild`]: (id, callback) => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState();
                    dispatch({
                        type: `${typePrefix}_DETAIL_LOADING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };
                    fetch(`${cls.source}?format=json&filter=${id}`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then(data => {
                            dispatch({
                                type: `${typePrefix}_DETAIL_LOADED`,
                                payload: data
                            });
                            if (callback) callback(data);
                        })
                        .catch(e => {
                            dispatch({
                                type: `${typePrefix}_DETAIL_ERROR`,
                                error: e
                            });
                        });
                };
            },
            [`${functionPrefix}LoadDetailChildFilterMany`]: (filters, callback) => {
                var cls = this;
                return function(dispatch, getState) {
                    const state = getState();
                    dispatch({
                        type: `${typePrefix}_DETAIL_LOADING`
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token
                    };
                    var filterstr = "";
                    var keys = Object.keys(filters);

                    keys.forEach(x => {
                        filterstr += x + "=" + filters[x] + "&";
                    });

                    fetch(`${cls.source}?format=json&${filterstr}`, options)
                        .then(result => result.json())
                        .then(data => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then(data => {
                            dispatch({
                                type: `${typePrefix}_DETAIL_LOADED`,
                                payload: data
                            });
                            if (callback) callback(data);
                        })
                        .catch(e => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT
                                });
                            }

                            dispatch({
                                type: `${typePrefix}_DETAIL_ERROR`,
                                error: e
                            });
                        });
                };
            },
            [`${functionPrefix}Update`]: payload =>
                this.createAction({
                    type: `${typePrefix}_UPDATE`,
                    payload: payload,
                    effectIfLoggedIn: {
                        url: `${this.source}/${payload.id}?t=${Date.now()}`,
                        method: "PATCH"
                    }
                }),
            [`${functionPrefix}UpdateLocalOnly`]: payload => ({
                type: `${typePrefix}_UPDATE`,
                payload: payload
            }),
            [`${functionPrefix}CreateLocalOnly`]: payload => ({
                type: `${typePrefix}_CREATE`,
                payload: payload
            }),
            [`${functionPrefix}Delete`]: objId =>
                this.createAction({
                    type: `${typePrefix}_DELETE`,
                    payload: { id: objId },
                    effectIfLoggedIn: {
                        url: `${this.source}/${objId}?&t=${Date.now()}`,
                        method: "DELETE"
                    }
                }),
            ...baseActions
        };
    }

    static reducer(action, cls) {
        const prefix = `ORM_${cls.modelName.toUpperCase()}`,
            errorPattern = new RegExp(`^${prefix}_([^_]+)_PUSHERROR$`),
            { payload, meta } = action,
            objId = (payload && payload.id) || (meta && meta.objectId);
        switch (action.type) {
            case `${prefix}_CREATE`:
                cls.create(payload || {});
                break;
            case `${prefix}_UPDATE`:
            case `${prefix}_CREATE_PUSHED`:
            case `${prefix}_UPDATE_PUSHED`:
                if (!cls.hasId(objId)) {
                    break;
                }
                cls.withId(objId).update(action.payload);
                break;
            case `${prefix}_DETAIL_LOADED`:
                action.payload.forEach(obj => cls.upsert(cls.fromResponse(obj)));
                break;
            case `${prefix}_CREATE_PUSHERROR`:
            case `${prefix}_UPDATE_PUSHERROR`:
            case `${prefix}_DETAIL_ERROR`:
                if (!cls.hasId(objId)) {
                    break;
                }
                cls.withId(objId).update({
                    serverError: payload.response || payload.status
                });
                break;
            case `${prefix}_DELETE`:
                if (!cls.hasId(objId)) {
                    break;
                }
                cls.withId(objId).delete();
                break;
            case `${prefix}_DELETE_ALL_RECORDS`:
                cls.all().delete();
                break;
            default:
                if (action.type.match(errorPattern)) {
                    console.warn(action);
                } else {
                    super.reducer(action, cls);
                }
        }
    }

    _onDelete() {
        const virtualFields = this.getClass().virtualFields;
        for (const key in virtualFields) {
            // eslint-disable-line
            if (this[key] !== null) {
                const relatedQs = this[key];
                if (relatedQs.exists()) {
                    relatedQs.delete();
                }
            }
        }
    }
}

export function reloadAll() {
    return function(dispatch) {
        orm.registry.forEach(model => {
            const fn = model.actions[`orm${model.modelName}Reload`];
            dispatch(fn());
        });
    };
}

export function reloadListOfModels(models) {
    return function(dispatch) {
        models.forEach(x => {
            const model = orm.registry.find(z => z.modelName === x);
            const fn = model.actions[`orm${model.modelName}Reload`];
            dispatch(fn("test"));
        });
    };
}

export function ormLogOutSync() {
    return function(dispatch) {
        dispatch({
            type: "ORM_LOGOUT_SYNC"
        });
    };
}

// Basically empties redux. Used on log out to reset redux in case any issues with data in the store.
export function clearAll() {
    return function(dispatch) {
        orm.registry.forEach(model => {
            dispatch({
                type: `ORM_${model.modelName.toUpperCase()}_DELETE_ALL_RECORDS`
            });
        });
    };
}

export function syncReducer(state = {}, action) {
    let { type } = action;
    if (action.meta && action.meta.offline) {
        type = `${type}_PUSHING`;
    }
    const logOutSyncPattern = /^ORM_LOGOUT_SYNC$/;
    const pushPattern = /^ORM_([^_]+)_([^_]+)_(PUSH[^_]+)$/;
    const pullPattern = /^ORM_([^_]+)_((PULL[^_]+))$/;
    const detailPattern = /^ORM_([^_]+)_(DETAIL)_([^_]+)$/;
    var match = type.match(pushPattern) || type.match(pullPattern) || type.match(detailPattern) || type.match(logOutSyncPattern);
    if (!match) {
        return state;
    }

    if (type.match(logOutSyncPattern)) {
        let count = 0;
        orm.registry.forEach(model => {
            count += 1;
        });

        return {
            ready: true,
            progress: count,
            total: count,
            pending: {},
            error: {}
        };
    }

    let pending = {},
        error = {};
    let [, modelName, actionName, statusName] = match;
    let total = 0;
    orm.registry.forEach(model => {
        total += 1;
        if (model.modelName.toUpperCase() === modelName) {
            modelName = model.modelName;
        } else if (state.error && state.error[model.modelName]) {
            error[model.modelName] = state.error[model.modelName];
        } else if (state.pending && state.pending[model.modelName]) {
            pending[model.modelName] = state.pending[model.modelName];
        }
    });

    switch (statusName) {
        case "PUSHING":
        case "PULLING":
        case "LOADING":
            pending[modelName] = actionName;
            break;
        case "PUSHED":
        case "PULLED":
        case "LOADED":
            break;
        case "PUSHERROR":
        case "PULLERROR":
        case "ERROR":
            error[modelName] = actionName;
            break;
        default:
            break;
    }
    const pendingCount = Object.keys(pending).length;
    let progress, ready;
    if (pendingCount > 0) {
        progress = total - pendingCount;
        ready = false;
    } else {
        progress = total;
        ready = true;
    }
    return {
        ready,
        progress,
        total,
        pending,
        error
    };
}
