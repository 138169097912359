import React, { Component } from "react";
import classNames from "classnames";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import CustomTableCell from "./TableCell";

const styles = {
    centerAlign: {
        textAlign: "center",
    },
    root: {
        "&:focus p": {
            color: "rgba(255, 255, 255, 0.7)"
        },
        "&:hover p": {
            color: "rgba(255, 255, 255, 0.7)"
        },
        "&:focus svg": {
            opacity: 0.5
        },
        "&:hover svg": {
            opacity: 0.5
        },
    },
    sticky: {
        position: "sticky",
        top: 0,
        zIndex: 999
    },
    tableHead: {
        backgroundColor: "#333333 !important"
    }
};

class EnhancedTableHead extends Component {
    createSortHandler = property => event => {
        if (this.props.name) {
            this.props.onRequestSort(event, property, this.props.name);
            return;
        }
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, classes, orderBy, columnData, actions, stickyHeader } = this.props;

        return (
            <TableHead className={classes.tableHead}>
                <TableRow>
                    {actions && (
                        <CustomTableCell className={stickyHeader && classes.sticky} key={"actions"} sortDirection={false} style={{ width: "auto" }}>
                            <Typography>{"Actions"}</Typography>
                        </CustomTableCell>
                    )}
                    {columnData.map(column => {
                        return (
                            <CustomTableCell
                                key={column.id}
                                sortDirection={orderBy === column.id ? order : false}
                                style={{ width: column.width ? column.width : "auto", minWidth: column.minWidth ? column.minWidth : "auto" }}
                                className={classNames(column.center && classes.centerAlign, stickyHeader && classes.sticky)}>
                                {column.allowSort && (
                                    <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                                        <TableSortLabel
                                            classes={{ root: classes.root }}
                                            active={orderBy === column.id}
                                            direction={order}
                                            onClick={this.createSortHandler(column.id)}>
                                            <Typography id={column.aLabel ? column.aLabel : null}>
                                                {column.label}
                                            </Typography>
                                        </TableSortLabel>
                                    </Tooltip>
                                )}
                                {!column.allowSort && (
                                    <Typography id={column.aLabel ? column.aLabel : null}>{column.label}</Typography>
                                )}
                            </CustomTableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

export default withStyles(styles)(EnhancedTableHead);
