import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

import AppContainer from "../common/AppContainer";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import Select from "../common/Select";
import PageHeader from "../common/PageHeader";
import { createSelector } from "../common/orm";
import { RFPMember } from "../home/models";

const getRFPYears = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});

const getMembers = createSelector(schema => {
    return schema.User.filter(x => x.is_active && x.role === "Member")
        .orderBy(["last_name", "first_name"], ["asc", "asc"])
        .toRefArray();
});

const allMembers = createSelector(schema => {
    return schema.User.filter(x => x.role === "Member")
        .orderBy(["last_name", "first_name"], ["asc", "asc"])
        .toRefArray();
});

const getRFPMembers = createSelector(
    (state, ownProps) => (ownProps.location.serach !== "" ? parseInt(ownProps.location.search.split("=")[1]) : null),
    (schema, year) => {
        return schema.RFPMember.filter(x => x.year_id === year).toRefArray();
    }
);

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        backgroundColor: "#fff"
    },
    select: {
        width: 200
    },
    tableSelect: {
        width: 220,
        marginTop: "-22px !important",
        paddingLeft: 4,
        paddingRight: 4
    }
});

const commonColumnData = [
    { id: "year", numeric: false, label: "RFP Year" },
    { id: "slot", numeric: false, label: "Slot" },
    { id: "name", numeric: false, label: "Name" }
];

class BatchProposal extends Component {
    state = {
        dont: false,
        selected: []
    };
    Formref = React.createRef();

    componentDidMount() {
        document.title = "Configure RFP Members - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    componentDidUpdate = e => {
        //allows changing of form values to different RFP year
        if (this.state.dont) {
            const arr = this.props.rfpMembers.map(x => x.user);
            this.setState({ dont: false, selected: arr });
        }
    };

    handleYear = e => {
        const { history, rfpYears } = this.props;
        const index = rfpYears.findIndex(x => x.id === e);
        const active = index > -1 ? rfpYears[index] : {};
        this.setState({ dont: true, activeRFP: active });

        history.push({ search: `year=${e.toString()}` });
    };

    getMembers = user => {
        const { allMembers, members, rfpYears } = this.props;
        const { activeRFP, selected } = this.state;

        if (!activeRFP) {
            return [];
        }
        const options = rfpYears.findIndex(x => x.id === activeRFP.id) > 0 ? allMembers : members;

        const list = options.filter(x => user === x.id || (user !== x.id && !selected.includes(x.id)));
        var open = [{ id: null, first_name: "", last_name: "" }, ...list];
        return open.map(x => ({
            label: x.first_name + " " + x.last_name,
            value: x.id
        }));
    };

    handleMember = (id, e, formApi) => {
        const { selected } = this.state;

        var temp;
        var savedId;
        temp = formApi.getValue(id);
        savedId = temp["user"];
        temp["user"] = e;
        formApi.setValue(id.toString(), temp);

        var arr = [];

        if (!savedId && !e) {
            arr = selected;
        } else if (savedId === e) {
            arr = selected;
        } else if (!savedId && e) {
            arr = [...selected, e];
        } else {
            const index = selected.findIndex(x => x === savedId);
            if (index > -1) {
                selected.splice(index, 1);
                if (e) {
                    arr = [...selected, e];
                } else {
                    arr = [...selected];
                }
            }
        }

        this.setState({ selected: arr });
    };

    getDefaultObjects = e => {
        const { activeRFP } = this.state;
        var obj = {};
        const keys = Object.keys(e);
        keys.forEach(x => {
            obj[e[parseInt(x)].slot] = e[x];
        });
        return activeRFP ? obj : {};
    };

    save = values => {
        const { history, ormRFPMemberCreate, ormRFPMemberDelete, ormRFPMemberUpdate } = this.props;
        const { activeRFP } = this.state;
        const keys = Object.keys(values);
        var arr = [];
        var delete_arr = [];

        keys.forEach(x => {
            if (values[x].user) {
                arr.push(values[x]);
            } else if (values[x].id && !values[x].user) {
                delete_arr.push(values[x]);
            }
        });

        arr.forEach(x => {
            if (x.id) {
                ormRFPMemberUpdate({
                    ...x
                });
            } else {
                ormRFPMemberCreate({
                    year_id: activeRFP.id,
                    ...x
                });
            }
        });

        delete_arr.forEach(x => {
            if (x.id) {
                ormRFPMemberDelete(x.id);
            }
        });

        // Read Me: since there's really no good way to tell when all reqs finished just make the form year have to be selected again.
        if (this.yearFormApi) {
            this.yearFormApi.resetAll();
        }
        history.push({ search: "" });
        this.setState({ activeRFP: null });
    };

    render() {
        const { classes, rfpYears, rfpMembers } = this.props;
        const { activeRFP, dont } = this.state;

        const years = rfpYears.map(x => ({
            label: x.year,
            value: x.id
        }));

        var list = new Array(18).fill(undefined).map((val, idx) => ({ slot: idx }));
        rfpMembers.forEach(x => {
            const obj = list[x.slot];
            list[x.slot] = { ...obj, ...x };
        });

        const spliced = list.splice(1, 17);

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Administration - Configure RFP Members" admin_page />
                    <Grid item xs={12}>
                        <Form getApi={el => (this.yearFormApi = el)} dontValidateOnMount={true}>
                            {formApi => (
                                <form>
                                    <Select
                                        className={classes.select}
                                        field="rfp_year"
                                        label="RFP Year/M.L Year"
                                        options={years}
                                        eventHandle={val => {
                                            this.handleYear(val);
                                        }}
                                    />
                                </form>
                            )}
                        </Form>
                    </Grid>
                    {!dont && (
                        <Grid item xs={12}>
                            <Form
                                getApi={el => (this.formApi = el)}
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                defaultValues={this.getDefaultObjects(spliced)}
                                onSubmit={this.save}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm} style={{ width: "100%" }}>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                <Table className={classes.table}>
                                                    <EnhancedTableHead stickyHeader columnData={commonColumnData} />
                                                    <TableBody>
                                                        {spliced.map((n, index) => {
                                                            return (
                                                                <TableRow key={index}>
                                                                    <CustomTableCell>
                                                                        {activeRFP ? activeRFP.year : ""}
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.slot}</CustomTableCell>
                                                                    <CustomTableCell>
                                                                        <Select
                                                                            eventHandle={e =>
                                                                                this.handleMember(n.slot, e, formApi)
                                                                            }
                                                                            disabled={!activeRFP}
                                                                            className={classes.tableSelect}
                                                                            label=" "
                                                                            options={
                                                                                formApi.values[n.slot]
                                                                                    ? this.getMembers(
                                                                                          formApi.values[n.slot].user
                                                                                      )
                                                                                    : []
                                                                            }
                                                                            field={n.slot + ".user"}
                                                                        />
                                                                    </CustomTableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <Button
                                                    disabled={!activeRFP}
                                                    variant="contained"
                                                    color="primary"
                                                    type="sumbit">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </Grid>
                    )}
                </Grid>
            </AppContainer>
        );
    }
}

BatchProposal = connect(
    (state, ownProps) => ({
        authState: state.auth,
        syncState: state.sync,
        rfpMembers: getRFPMembers(state, ownProps),
        allMembers: allMembers(state, ownProps),
        members: getMembers(state),
        rfpYears: getRFPYears(state)
    }),
    {
        ...RFPMember.actions
    }
)(BatchProposal);

export default withStyles(styles)(withRouter(BatchProposal));
