import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import { MAKE_OPTIONS } from "../../../api/constants";
import { getValue } from "../../../api/utils";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import cities from "../../common/cities.json";
import counties from "../../common/counties.json";
import watersheds from "../../common/watersheds.json";
import ecoregions from "../../common/ecoregions.json";
import lccmrregions from "../../common/lccmrregions.json";

import CheckboxGroup from "../../common/CheckboxGroup";
import HelpLabel from "../../common/HelpLabel";
import TextField from "../../common/TextField";
import MultiSelect from "../../common/MultiSelect";
import Select from "../../common/Select";
import Subquestion from "../../common/Subquestion";
import { createSelector } from "../../common/orm";
import { Proposal } from "../models";
import { ProposalTab } from "../../home/models";

const getFundingCategories = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        var prop = session.Proposal.withId(id);
        return session.FundingCategory.filter(FC => FC.year_label === prop.ml_year).toRefArray();
    }
);

const getTab = ProposalTab.selectMessage(15);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    flex: {
        flex: 1
    },
    total: {
        fontWeight: "Bold",
        color: "#000000"
    }
});

const keys = [
    "impact_scale",
    "impact_occur",
    "impact_areas",
    "location_areas",
    "location_scale",
    "funding_category",
    "secondary_funding_category"
];

function getDefaultValues(proposal) {
    var obj = {};

    obj["name"] = proposal.name;
    obj["pm_first_name"] = proposal.pm_first_name;
    obj["pm_last_name"] = proposal.pm_last_name;

    keys.forEach(x => {
        if (proposal[`final_${x}`]) {
            obj[`final_${x}`] = proposal[`final_${x}`];
        } else {
            if (Array.isArray(proposal[x])) {
                //create new array so it doesnt reference non final
                obj[`final_${x}`] = [...proposal[x]];
            } else {
                obj[`final_${x}`] = proposal[x];
            }
        }
    });
    return obj;
}

class Staff_Score extends Component {
    state = {
        orig_proposal: { ...this.props.proposal },
        proposal: { ...getDefaultValues(this.props.proposal) },
        dont: false,
        selectkey: window.performance.now()
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Staff Admin - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentDidUpdate() {
        const { proposal } = this.props;
        const { orig_proposal } = this.state;
        //resets form to include id after creation of score
        if (this.state.dont) {
            this.setState({ dont: false });
        }

        if (!orig_proposal && proposal) {
            this.setState({
                orig_proposal: { ...proposal },
                proposal: { ...getDefaultValues(this.props.proposal) },
                dont: true
            });
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { proposal, ormProposalUpdate, ormProposalUpdateLocalOnly } = this.props;
        const { id } = proposal;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (
                (proposal.status !== "Draft In Progress" &&
                    proposal.status !== "Draft Feedback Received" &&
                    proposal.status !== "Revisions Needed") ||
                (this.props.authState &&
                    this.props.authState.user &&
                    (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member"))
            ) {
                ormProposalUpdateLocalOnly({
                    id: id,
                    activeStep: fromStepper["step"],
                    activeSubStep: fromStepper["subStep"]
                });
                return;
            }
        }
        const name = values.name;
        delete values.name;

        var keysChanged = [];

        keys.forEach(x => {
            if (Array.isArray(this.state.orig_proposal[x])) {
                const arr1 = this.state.orig_proposal[x].sort((a, b) => a - b).join(",");
                const arr2 = values[`final_${x}`].sort((a, b) => a - b).join(",");
                const arr3 = this.state.orig_proposal[`final_${x}`]
                    ? this.state.orig_proposal[`final_${x}`].sort((a, b) => a - b).join(",")
                    : "";
                if (
                    ((!this.state.orig_proposal[`final_${x}`] ||
                        (this.state.orig_proposal[`final_${x}`] &&
                            this.state.orig_proposal[`final_${x}`].length === 0)) &&
                        arr1 !== arr2) ||
                    (arr3 && arr2 !== arr3)
                ) {
                    keysChanged.push(x);
                }
            } else if (
                (!this.state.orig_proposal[`final_${x}`] && this.state.orig_proposal[x] !== values[`final_${x}`]) ||
                (this.state.orig_proposal[`final_${x}`] &&
                    this.state.orig_proposal[`final_${x}`] !== values[`final_${x}`])
            ) {
                keysChanged.push(x);
            }
        });

        var final = { ...this.state.orig_proposal };

        keysChanged.forEach(x => {
            if (x === "funding_category" && values[`final_${x}`] !== "Small Projects") {
                final["final_secondary_funding_category"] = null;
                values["final_secondary_funding_category"] = null;
                final[`final_${x}`] = values[`final_${x}`];
            } else if (
                x === "secondary_funding_category" &&
                values["final_funding_category"] === "Small Projects" &&
                !keysChanged.includes("final_funding_category")
            ) {
                final[`final_${x}`] = values[`final_${x}`];
                final[`final_funding_category`] = values[`final_funding_category`];
            } else if (
                x === "impact_areas" &&
                this.state.orig_proposal[`final_${x}`] === null &&
                !keysChanged.includes("impact_scale")
            ) {
                final[`final_${x}`] = values[`final_${x}`];
                final[`final_impact_scale`] = values[`final_impact_scale`];
            } else if (
                x === "location_areas" &&
                this.state.orig_proposal[`final_${x}`] === null &&
                !keysChanged.includes("location_scale")
            ) {
                final[`final_${x}`] = values[`final_${x}`];
                final[`final_location_scale`] = values[`final_location_scale`];
            } else {
                final[`final_${x}`] = values[`final_${x}`];
            }
        });

        if (name === "") {
            final.name = proposal.name;
        } else {
            final.name = name;
        }
        final.pm_first_name = values.pm_first_name;
        final.pm_last_name = values.pm_last_name;

        ormProposalUpdate({
            ...final
        });
        this.setState({ orig_proposal: { ...final }, proposal: getDefaultValues(final), dont: true });
    }

    applyOther = (value, element) => {
        const { proposal } = this.state;
        var values = { ...proposal };

        values[element] = value;

        if (
            element === "final_funding_category" &&
            proposal.final_funding_category === "Small Projects" &&
            value !== "Small Projects"
        ) {
            values["final_secondary_funding_category"] = "";
            this.form.setValue("final_secondary_funding_category", "");
        }

        // Clear existing values when changing scale
        if (element === "final_location_scale") {
            values["final_ocation_areas"] = [];
            this.form.setValue("final_location_areas", []);
            this.setState({ selectkey: window.performance.now() });
        }
        // Clear existing values when changing scale
        if (element === "final_impact_scale") {
            values["final_impact_areas"] = [];
            this.form.setValue("final_impact_areas", []);
            this.setState({ selectkey: window.performance.now() });
        }

        this.setState({ proposal: values });
    };

    MAKE_OPTIONS = table => {
        return table.map(row => ({
            label: row.category_name + " (" + row.suffix + ")",
            value: row.category_name
        }));
    };

    render() {
        const { classes, settings, categories, proposal, ActionMenu, tab } = this.props;
        const { selectkey } = this.state;

        if (!proposal) {
            return null;
        }

        const final_funding_category = getValue(this.state.proposal, "final_funding_category");
        const final_location_scale = getValue(this.state.proposal, "final_location_scale");
        const final_impact_scale = getValue(this.state.proposal, "final_impact_scale");

        return (
            <Form
                getApi={el => (this.form = el)}
                defaultValues={this.state.proposal}
                validateOnSubmit={true}
                validateError={this.errorValidator}
                dontValidateOnMount={true}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16} style={{ marginTop: 0 }}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="titleAction">Proposal Admin Screen</Typography>
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography>
                                    Staff Lead for this Proposal:{" "}
                                    {proposal.staff_lead_label}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <TextField disabled={settings.staff_eval_open} field="name" fullWidth>
                                    <HelpLabel
                                        inputLabel="Project Title"
                                        showLabel={true}
                                        htmlText={true}
                                        helpText={
                                            tab.sub_tabs
                                                .find(tht => tht.tab_name === "Staff Admin")
                                                .tab_help_texts.find(tht => tht.label === "Project Title").text
                                        }
                                    />
                                </TextField>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <TextField label="Project Manager First Name" field="pm_first_name" fullWidth />
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <TextField label="Project Manager Last Name" field="pm_last_name" fullWidth />
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Select
                                    disabled={settings.staff_eval_open}
                                    eventHandle={this.applyOther}
                                    field="final_funding_category"
                                    options={this.MAKE_OPTIONS(categories)}
                                    fullWidth>
                                    <HelpLabel
                                        inputLabel="LCCMR Funding Category"
                                        showLabel={true}
                                        htmlText={true}
                                        helpText={
                                            tab.sub_tabs
                                                .find(tht => tht.tab_name === "Staff Admin")
                                                .tab_help_texts.find(tht => tht.label === "LCCMR Funding Category").text
                                        }
                                    />
                                </Select>
                                {final_funding_category === "Small Projects" && (
                                    <Subquestion
                                        component={
                                            <Select
                                                disabled={settings.staff_eval_open}
                                                eventHandle={this.applyOther}
                                                field="final_secondary_funding_category"
                                                label="Secondary LCCMR Funding Category"
                                                options={this.MAKE_OPTIONS(
                                                    categories.filter(c => c.category_name !== "Small Projects")
                                                )}
                                                fullWidth
                                            />
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <HelpLabel
                                    inputLabel="Project Location"
                                    title={true}
                                    style={{ display: "inline-block", marginTop: 8 }}
                                    showLabel={true}
                                    htmlText={true}
                                    helpText={
                                        tab.sub_tabs
                                            .find(tht => tht.tab_name === "Staff Admin")
                                            .tab_help_texts.find(tht => tht.label === "Project Location").text
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Select
                                    disabled={settings.staff_eval_open}
                                    eventHandle={this.applyOther}
                                    field="final_location_scale"
                                    label="What is the best scale for describing where your work will take place?"
                                    options={[
                                        { label: "Region", value: "Region" },
                                        { label: "Statewide", value: "Statewide" }
                                    ]}
                                    fullWidth
                                />
                                {final_location_scale && final_location_scale !== "Statewide" && (
                                    <Subquestion
                                        component={
                                            <MultiSelect
                                                disabled={settings.staff_eval_open}
                                                key={selectkey}
                                                field="final_location_areas"
                                                label={
                                                    <HelpLabel
                                                        showLabel={true}
                                                        inputLabel="Choose the areas:"
                                                        htmlText={true}
                                                        helpText={
                                                            tab.sub_tabs
                                                                .find(tht => tht.tab_name === "Staff Admin")
                                                                .tab_help_texts.find(
                                                                    tht => tht.label === "What is the best scale for describing where your work will take place? (Choose the areas:)"
                                                                ).text
                                                        }
                                                    />
                                                }
                                                options={
                                                    final_location_scale === "City"
                                                        ? cities
                                                        : final_location_scale === "County"
                                                        ? counties
                                                        : final_location_scale === "Watershed"
                                                        ? watersheds
                                                        : final_location_scale === "Ecological Subsection"
                                                        ? ecoregions
                                                        : final_location_scale === "Region"
                                                        ? lccmrregions
                                                        : []
                                                }
                                            />
                                        }
                                    />
                                )}
                                <Select
                                    disabled={settings.staff_eval_open}
                                    eventHandle={this.applyOther}
                                    field="final_impact_scale"
                                    label="What is the best scale to describe the area impacted by your work?"
                                    options={[
                                        { label: "Region", value: "Region" },
                                        { label: "Statewide", value: "Statewide" }
                                    ]}
                                    fullWidth
                                />
                                {final_impact_scale && final_impact_scale !== "Statewide" && (
                                    <Subquestion
                                        component={
                                            <MultiSelect
                                                disabled={settings.staff_eval_open}
                                                key={selectkey + 1}
                                                field="final_impact_areas"
                                                label={
                                                    <HelpLabel
                                                        showLabel={true}
                                                        inputLabel="Choose the areas:"
                                                        htmlText={true}
                                                        helpText={
                                                            tab.sub_tabs
                                                                .find(tht => tht.tab_name === "Staff Admin")
                                                                .tab_help_texts.find(
                                                                    tht => tht.label === "What is the best scale to describe the area impacted by your work? (Choose the areas:)"
                                                                ).text
                                                        }
                                                    />
                                                }
                                                options={
                                                    final_impact_scale === "City"
                                                        ? cities
                                                        : final_impact_scale === "County"
                                                        ? counties
                                                        : final_impact_scale === "Watershed"
                                                        ? watersheds
                                                        : final_impact_scale === "Ecological Subsection"
                                                        ? ecoregions
                                                        : final_impact_scale === "Region"
                                                        ? lccmrregions
                                                        : []
                                                }
                                            />
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <CheckboxGroup
                                    disabled={settings.staff_eval_open}
                                    field="final_impact_occur"
                                    row={true}
                                    label="When will the work impact occur? (please check all that apply)"
                                    options={MAKE_OPTIONS(["During the Project", "In the Future"])}
                                />
                            </Grid>

                            <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                <Button
                                    disabled={settings.staff_eval_open}
                                    variant="contained"
                                    type="submit"
                                    style={{ float: "left" }}
                                    className={classes.button}>
                                    Save
                                </Button>
                                <Button
                                    component={Link}
                                    to="/adm/proposal/process"
                                    variant="contained"
                                    style={{ float: "left" }}
                                    className={classes.button}>
                                    Return to Proposal Process Page
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

Staff_Score = connect(
    (state, ownProps) => ({
        tab: getTab(state, ownProps),
        authState: state.auth,
        categories: getFundingCategories(state, ownProps)
    }),
    {
        ...Proposal.actions
    }
)(Staff_Score);

export default withStyles(styles)(withRouter(Staff_Score));
