import React, { Component } from "react";
import { FormField } from "react-form";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        display: "flex"
    },
    inline: {
        display: "inline",
        cursor: "pointer"
    },
    disabled: {
        display: "inline",
        cursor: "cursor"
    },
    icon: {
        "& > span:first-child": {
            //switchBase broken?
            height: "28px"
        }
    },
    error: {
        "& h3": {
            // No way to access actual text - probably because typography tag is being passed as label
            color: "#be0000"
        },
        "& svg": {
            // Keep color on help icon
            color: "rgba(0, 0, 0, 0.87)"
        }
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
        width: "100%",
        display: "inline"
    },
    formControlNoMargin: {
        marginTop: theme.spacing.unit * 2,
        width: "100%",
        display: "inline"
    }
});

class SwitchWrapper extends Component {
    handleChange = (event, value) => {
        const { disabled, fieldApi, eventHandle } = this.props;
        if (disabled) {
            return;
        }
        const { setValue, getFieldName } = fieldApi;
        setValue(value);

        if (eventHandle) eventHandle(value, getFieldName());
    };

    render() {
        const { fieldApi, label, classes, required, field, helperText, noMargin, labelNo, labelYes, children, style, checkFocus, focusRemoval, ...rest } = this.props;

        const { getValue, getError, getFieldName } = fieldApi;

        const value = getValue() || "",
            error = getError();

        const fname = getFieldName();

        return (
            <FormControl style={style} required={required} error={error} className={noMargin ? classes.formControlNoMargin : classes.formControl}>
                <InputLabel style={{ whiteSpace: "nowrap", marginBottom: 36 }} shrink={true} htmlFor={fname} classes={{ error: classes.error }}>
                    {label || children}
                </InputLabel>
                <br />
                <Typography className={rest.disabled ? classes.disabled : classes.inline} onClick={() => this.handleChange(null, false)}>
                    {labelNo ? labelNo : "No"}
                </Typography>
                <Switch
                    checked={value}
                    onChange={this.handleChange}
                    value={field}
                    color="primary"
                    aria-describedby={helperText && fname + "_help"}
                    id={fname}
                    inputRef={(input) => {
                        if (checkFocus && checkFocus.highlightedField && checkFocus.highlightedField === getFieldName() && input != null) {
                           input.focus();
                           focusRemoval({
                               id: checkFocus.id,
                               highlightedField: null,
                           })
                        }
                    }}
                    classes={{
                        root: classes.icon
                    }}
                    {...rest}
                />
                <Typography className={rest.disabled ? classes.disabled : classes.inline} onClick={() => this.handleChange(null, true)}>
                    {labelYes ? labelYes : "Yes"}
                </Typography>
                {helperText && <FormHelperText id={fname + "_help"}>{helperText}</FormHelperText>}
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

const FppSwitch = FormField(SwitchWrapper);

export default withStyles(styles)(FppSwitch);
