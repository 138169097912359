import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import MuiTooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { withStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

import { ProposalContract } from "../models";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import HelpLabel from "../../common/HelpLabel";
import Subquestion from "../../common/Subquestion";
import Select from "../../common/Select";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import { getValue, setValue } from "../../../api/utils";
import { MAKE_OPTIONS_YESNO } from "../../../api/constants";
import WarningDialog from "../../common/WarningDialog";
import { createSelector } from "../../common/orm";
import { ProposalTab } from "../../home/models";

const getContracts = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalContract.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getTab = ProposalTab.selectMessage(12);

const project_role_options = [
    { value: "Sub award", label: "Sub award" },
    {
        value: "Professional or Technical Service Contract",
        label: "Professional or Technical Service Contract"
    },
    {
        value: "Internal services or fees (uncommon)",
        label: "Internal services or fees (uncommon)"
    }
];

const AcquisitionOptions = [
    { value: "Sub award", label: "Sub award" },
    {
        value: "Professional or Technical Service Contract",
        label: "Professional or Technical Service Contract"
    },
    {
        value: "Internal services or fees (uncommon)",
        label: "Internal services or fees (uncommon)"
    },
    { value: "Acquisition costs", label: "Acquisition costs" }
];

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    hide: {
        display: "none"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    tableCellAlignRight: {
        textAlign: "right"
    },
    tableCellAlignCenter: {
        textAlign: "center"
    },
    tableCellNoBorder: {
        border: "none"
    },
    tableCellBold: {
        fontWeight: "Bold",
        color: "#000000"
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
});

class Budget_ContractsTab extends Component {
    state = {
        ContractDialogObj: null,
        WarningDialogObj: null
    };

    handleClose = () => {
        this.setState({ ContractDialogObj: null });
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Budget Professional / Technical Contracts - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        var valObj = {};

        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        const isValidValue = path => {
            let val = getValue(values, path);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, val >= 0 ? null : "Value must be >= 0.");
        };

        isRequired("entity");
        isRequired("project_role");
        isRequired("description");
        //isRequired("single_source");
        isRequired("ineligible_expenses");
        isValidValue("amount");
        if (values.ineligible_expenses) {
            isRequired("justify_ineligible_expenses");
        } else {
            setValue(valObj, "justify_ineligible_expenses", null);
        }

        return valObj;
    };

    saveContract(values) {
        const { id } = this.props.proposal;
        const { ormProposalContractCreate, ormProposalContractUpdate } = this.props;

        if (!values.ineligible_expenses) {
            values["justify_ineligible_expenses"] = null;
        }

        values["fte"] = this.calculateFte(values);

        if (values.id) {
            ormProposalContractUpdate({
                ...values
            });
            this.props.calcFundingTotal();
        } else {
            ormProposalContractCreate({
                ...values,
                proposal: id
            }).then(response => {
                this.props.calcFundingTotal();
            });
        }
        this.setState({ ContractDialogObj: null });
    }

    deleteRecord = () => {
        this.props.ormProposalContractPromiseDelete(this.state.WarningDialogObj.id).then(id => {
            this.props.calcFundingTotal();
        });
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    getTableData = contracts => {
        var table_data = [];

        contracts.forEach(c => {
            let newObj = { ...c };
            newObj["ineligible_expenses"] = c.ineligible_expenses ? "X" : "";
            newObj["fte"] = this.calculateFte(newObj);
            table_data.push(newObj);
        });

        return table_data;
    };

    calculateFte = values => {
        return parseFloat((values.annual_fte * values.years).toFixed(2));
    };

    handleSource = (val, formApi) => {
        if (val) {
            formApi.setValue("ineligible_expenses", true);
        }
    };

    render() {
        const { classes, contracts, ActionMenu, handleNext, handlePrev, history, proposal, tab } = this.props;
        const { ContractDialogObj, WarningDialogObj } = this.state;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        var interact_string = ContractDialogObj && ContractDialogObj["id"] ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        const column_data = [
            { id: "actions", numeric: false, label: "Actions", width: "20px" },
            {
                id: "entity",
                numeric: false,
                label: "Entity",
            },
            {
                id: "project_role",
                numeric: false,
                label: "Contract or Service Type",
            },
            {
                id: "description",
                numeric: false,
                label: "Description of Service Provided",
            },
            {
                id: "ineligible_expenses",
                numeric: false,
                label: "Generally Ineligible",
            },
            {
                id: "annual_fte",
                numeric: true,
                label: "FTE/Year",
            },
            { id: "years", numeric: true, label: "# of Years" },
            {
                id: "fte",
                numeric: true,
                label: "Total FTE",
                align: "center"
            },
            {
                id: "amount",
                numeric: true,
                label: "Approx. $ Amount"
            }
        ];

        const table_data = this.getTableData(contracts);

        let fteTotal = 0;
        let amtTotal = 0;
        table_data.forEach(d => {
            fteTotal += d.fte;
            amtTotal += d.amount;
        });
        fteTotal = Math.round((fteTotal + Number.EPSILON) * 100) / 100;

        var onlyAcquistion = false;
        if (proposal.land_rights.length > 0) {
            onlyAcquistion = true;
        }

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <Typography variant="titleAction">Professional/Technical/Services Contracts Details</Typography>
                    {ActionMenu}
                </Grid>

                <Grid item xs={12}>
                    <div class="insborder">
                        <Typography gutterBottom>Instructions and Requirements</Typography>
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: tab.sub_tabs
                                        .find(tht => tht.tab_name === "Professional / Technical Contracts")
                                        .tab_help_texts.find(tht => tht.label === "Instructions and Requirements").text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="raised"
                        disabled={is_read_only}
                        color="primary"
                        onClick={() =>
                            this.setState({
                                ContractDialogObj: {
                                    id: null,
                                    single_source: false,
                                    ineligible_expenses: false,
                                    justify_ineligible_expenses: null
                                }
                            })
                        }>
                        <AddCircleIcon />
                        &nbsp;&nbsp;&nbsp;Add New Contract or Service
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Table className={classes.table}>
                        <EnhancedTableHead stickyHeader columnData={column_data} />
                        <TableBody>
                            {table_data.map(d => {
                                let pObj = contracts.filter(o => o.id === d.id)[0];
                                return (
                                    <TableRow key={d.id}>
                                        <TableCell className={classes.nowrap}>
                                            <MuiTooltip title="Edit">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Edit"
                                                    onClick={() => this.setState({ ContractDialogObj: pObj })}>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Delete"
                                                    onClick={() => this.setState({ WarningDialogObj: pObj })}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="View">
                                                <Button
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="View"
                                                    onClick={() => this.setState({ ContractDialogObj: pObj })}>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </TableCell>
                                        <TableCell>{d.entity}</TableCell>
                                        <TableCell>{d.project_role}</TableCell>
                                        <TableCell>{d.description}</TableCell>
                                        <TableCell className={classes.tableCellAlignCenter}>
                                            {d.ineligible_expenses}
                                        </TableCell>
                                        <TableCell className={classes.tableCellAlignCenter}>{d.annual_fte}</TableCell>
                                        <TableCell className={classes.tableCellAlignCenter}>{d.years}</TableCell>
                                        <TableCell className={classes.tableCellAlignCenter}>{d.fte}</TableCell>
                                        <TableCell className={classes.tableCellAlignRight}>
                                            {d.amount.toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {table_data.length > 0 && (
                                <TableRow key={"total"}>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classNames(classes.tableCellNoBorder, classes.tableCellBold)}>
                                        Total FTE
                                    </TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.tableCellAlignRight,
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder
                                        )}>
                                        {fteTotal}
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableCellNoBorder, classes.tableCellBold)}>
                                        Total Contract Amount
                                    </TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.tableCellAlignRight,
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder
                                        )}>
                                        $&nbsp;{amtTotal.toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            )}
                            {table_data.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={9} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Records</caption>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        color="primary"
                        className={classes.button}
                        onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>

                <Dialog role="main" aria-label={interact_string + " Service / Contract Detail"} open={ContractDialogObj ? true : false} onClose={this.handleClose} disableBackdropClick={true}>
                    <Form
                        defaultValues={ContractDialogObj}
                        validateOnSubmit={true}
                        dontValidateOnMount={true}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.saveContract(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle
                                    className={classes.dialogTitleMargin}
                                    disableTypography
                                    id="form-dialog-title">
                                    <Typography variant="h1" className={classes.flex}>{interact_string} Service / Contract Detail</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={this.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <TextField field="entity" fullWidth disabled={is_read_only} margin="normal">
                                                <HelpLabel
                                                    inputLabel="Entity"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht =>
                                                                    tht.tab_name ===
                                                                    "Professional / Technical Contracts"
                                                            )
                                                            .tab_help_texts.find(tht => tht.label === "Entity").text
                                                    }
                                                />
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                field="project_role"
                                                fullWidth
                                                disabled={is_read_only}
                                                margin="normal"
                                                options={onlyAcquistion ? AcquisitionOptions : project_role_options}>
                                                <HelpLabel
                                                    inputLabel="Contract or Service Type"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht =>
                                                                    tht.tab_name ===
                                                                    "Professional / Technical Contracts"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht => tht.label === "Contract or Service Type"
                                                            ).text
                                                    }
                                                />
                                            </Select>
                                        </Grid>

                                        <TextField
                                            field="description"
                                            multiline
                                            fullWidth
                                            disabled={is_read_only}
                                            maxWords="50"
                                            label="Explain what work the entity will be providing or contributing to the project."></TextField>
                                            
                                        {proposal.ml_year < 2022 && (
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    field="single_source"
                                                    name="single_source"
                                                    label=""
                                                    fullWidth
                                                    disabled={is_read_only}
                                                    options={MAKE_OPTIONS_YESNO([
                                                        { label: "Yes", value: "true", disabled: is_read_only },
                                                        { label: "No", value: "false", disabled: is_read_only }
                                                    ])}
                                                    alignment={true}
                                                    eventHandle={val => this.handleSource(val, formApi)}>
                                                    Is this a single source contract?
                                                </RadioGroup>
                                            </Grid>
                                        )}
                                        <Grid item xs={4}>
                                            <TextField
                                                field="annual_fte"
                                                useFloatFormat
                                                fullWidth
                                                disabled={is_read_only}
                                                margin="normal">
                                                <HelpLabel
                                                    inputLabel="FTE/Year"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht =>
                                                                    tht.tab_name ===
                                                                    "Professional / Technical Contracts"
                                                            )
                                                            .tab_help_texts.find(tht => tht.label === "FTE/Year").text
                                                    }
                                                />
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                useFloatFormat
                                                field="years"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="# of Years"
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                disabled={true}
                                                field="fte"
                                                fullWidth
                                                useFloatFormat
                                                label="Total FTE"
                                                margin="normal"
                                                value={this.calculateFte(formApi.values)}
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                field="amount"
                                                fullWidth
                                                disabled={is_read_only}
                                                useTextFormat
                                                label="Contract Amount"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="ineligible_expenses"
                                                name="ineligible_expenses"
                                                fullWidth
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS_YESNO([
                                                    { label: "Yes", value: "true", disabled: is_read_only },
                                                    { label: "No", value: "false", disabled: is_read_only }
                                                ])}
                                                alignment={true}>
                                                <HelpLabel
                                                    inputLabel="Generally Ineligible Expenses Justification"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(
                                                                tht =>
                                                                    tht.tab_name ===
                                                                    "Professional / Technical Contracts"
                                                            )
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Generally Ineligible Expenses Justification"
                                                            ).text
                                                    }
                                                />
                                            </RadioGroup>
                                            {formApi.values.ineligible_expenses && (
                                                <Subquestion
                                                    component={
                                                        <TextField
                                                            field="justify_ineligible_expenses"
                                                            multiline
                                                            fullWidth
                                                            disabled={is_read_only}
                                                            label=""
                                                        />
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose}>Cancel</Button>
                                    <Button color="primary" variant="contained" disabled={is_read_only} type="submit">
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>

                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Contract record"
                    text={"Are you sure you want to delete this Contract record?"}
                />
            </Grid>
        );
    }
}

Budget_ContractsTab = connect(
    (state, ownProps) => ({
        contracts: getContracts(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...ProposalContract.actions
    }
)(Budget_ContractsTab);

export default withStyles(styles)(withRouter(Budget_ContractsTab));
