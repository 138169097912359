import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import CreateIcon from "@material-ui/icons/Create";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import TextField from "../common/TextField";
import TablePaginationActionsWrapped from "../common/Paginator";
import { createSelector } from "../common/orm";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    }
});

const getAgents = createSelector(schema => {
    return schema.FiscalAgent.all()
        .orderBy(["is_active", "name"], ["desc", "asc"])
        .toRefArray();
});

const columnDataAgent = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "name", numeric: false, label: "Fiscal Agent Name", allowSort: true },
    { id: "contact_name", numeric: false, label: "Contact Name", allowSort: true },
    { id: "is_active", numeric: false, label: "Active", allowSort: true, bool: true }
];

class Agents extends Component {
    state = {
        page: 0,
        rowsPerPage: 25,
        order: "desc",
        orderBy: "is_active",
        filter: null
    };

    componentDidMount() {
        document.title = "Manage Fiscal Agents - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props.agents.sort(function(a, b) {
            var date = columnDataAgent.find(cD => cD.id === orderBy).date;
            var numeric = columnDataAgent.find(cD => cD.id === orderBy).numeric;
            var bool = columnDataAgent.find(cD => cD.id === orderBy).bool;

            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    filterByValue = (array, string) => {
        return array.filter(o =>
            Object.keys(o).some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    render() {
        const { agents, classes } = this.props;
        const { page, rowsPerPage, order, orderBy, filter } = this.state;

        var filtered_agents;
        if (filter) {
            filtered_agents = this.filterByValue(agents, filter);
        } else {
            filtered_agents = agents;
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Manage Fiscal Agents" admin_page />
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: 24 }}
                            component={Link}
                            to={"/adm/agents/new"}>
                            <AddCircleIcon />
                            &nbsp;&nbsp;&nbsp;Add New Fiscal Agent
                        </Button>
                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                            {formApi => (
                                <form style={{ float: "right" }} onSubmit={formApi.submitForm}>
                                    <TextField
                                        eventHandle={val => this.setState({ filter: val })}
                                        field="filter"
                                        label="Search"
                                        filterInput
                                    />
                                </form>
                            )}
                        </Form>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                columnData={columnDataAgent}
                                order={order}
                                orderBy={orderBy}
                                stickyHeader
                                onRequestSort={this.handleRequestSort}
                            />
                            <TableBody>
                                {filtered_agents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                    return (
                                        <TableRow key={n.id}>
                                            <CustomTableCell className={classes.nowrap}>
                                                <Tooltip title="Edit Fiscal Agent">
                                                    <Button
                                                        color="primary"
                                                        className={classes.deleteWidth}
                                                        component={Link}
                                                        to={"/adm/agents/" + n.id}>
                                                        <CreateIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                            <CustomTableCell>{n.name}</CustomTableCell>
                                            <CustomTableCell>{n.contact_name}</CustomTableCell>
                                            <CustomTableCell>{n.is_active ? "Yes" : "No"}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {filtered_agents.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                            <caption style={{display:"inline"}}>No Fiscal Agents Found</caption>
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            {filtered_agents.length > 10 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={4}
                                            count={filtered_agents.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

Agents = connect(
    (state, ownProps) => ({
        authState: state.auth,
        agents: getAgents(state, ownProps)
    }),
    null
)(Agents);

export default withStyles(styles)(withRouter(Agents));
