import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core";

import HelpLabel from "../../common/HelpLabel";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import { createSelector } from "../../common/orm";
import WarningDialog from "../../common/WarningDialog";
import Switch from "../../common/Switch";

import { Proposal } from "../models";
import { ProposalTab } from "../../home/models";

const getProposalPersonnel = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalPersonnel.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getProposalContract = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalContract.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getProposalExpenditure = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalExpenditure.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getProposalAcquisition = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalAcquisition.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getProposalTravel = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalTravel.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getProposalPrinting = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalPrinting.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getProposalExpense = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalExpense.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getProposalNonENRTFFund = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalNonENRTFFund.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getTab = ProposalTab.selectMessage(12);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    totalRow: {
        borderTop: "1px solid rgba(224, 224, 224, 1);",
        borderBottom: "1px solid rgba(224, 224, 224, 1);",
        height: 48
    },
    categoryTotalCell: {
        borderBottom: "none",
        textAlign: "right"
    },
    totalCell: {
        borderBottom: "none",
        textAlign: "right",
        width: 60
    },
    stndCell: {
        minWidth: "10%"
    },
    medCell: {
        minWidth: "25%"
    },
    wideCell: {
        minWidth: "45%"
    },
    amountCell: {
        width: 60,
        textAlign: "right"
    },
    gridPaddingRight: {
        paddingRight: 20
    },
    gridPaddingTop: {
        paddingTop: 40
    },
    gridMargin: {
        margin: "20px 0px"
    },
    label: {
        padding: "15px 10px"
    },
    centerAlign: {
        textAlign: "center"
    },
    categoryTitle: {
        backgroundColor: "#ddd",
        color: "black"
    },
    bold: {
        fontWeight: "bold"
    }
});

const columnDataSummarys = [
    { id: "category", numeric: false, label: "Category" },
    { id: "name", numeric: false, label: "Name / Entity" },
    { id: "type", numeric: false, label: "Subcategory / Type" },
    { id: "description", numeric: false, label: "Description" },
    { id: "purpose", numeric: false, label: "Purpose" },
    { id: "ineligible", numeric: false, label: "Generally Ineligible" },
    { id: "benefits", numeric: false, label: "% Benefits" },
    { id: "fte", numeric: false, label: "FTE" },
    { id: "classified", numeric: false, label: "Classified?" },
    { id: "amount", numeric: false, label: "$ Amount" }
];

const columnDataIneligibles = [
    { id: "category", numeric: false, label: "Category" },
    { id: "name", numeric: false, label: "Name / Entity" },
    { id: "type", numeric: false, label: "Subcategory or Type" },
    { id: "description", numeric: false, label: "Description" },
    {
        id: "justification",
        numeric: false,
        label: "Justification Ineligible Expense or Classified Staff Request",
    }
];

const columnDataFunds = [
    { id: "source", numeric: false, label: "Source/Type" },
    { id: "specific_source", numeric: false, label: "Specific Source" },
    { id: "description", numeric: false, label: "Description" },
    { id: "status", numeric: false, label: "Status" },
    { id: "amount", numeric: false, label: "$ Amount" }
];

class Budget_SummaryTab extends Component {
    state = {
        WarningDialogCheck: true
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Budget Summary";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    applyOther = (value, element) => {
        const { ormProposalUpdateLocalOnly } = this.props;
        const { id } = this.props.proposal;
        ormProposalUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    buildListObj = (list, title) => {
        var newObj = {
            total: 0.0,
            totalString: "",
            title: title,
            list: [...list]
        };

        list.map(x => {
            newObj.total += x.amount;
            if (x.masterCategory == null) {
                x.masterCategory = title;
            }
            x.amountString = this.formatMoney(x.amount);
            return x;
        });

        newObj.totalString = "$" + this.formatMoney(newObj.total);

        return newObj;
    };

    filterType = (list, type) => {
        return list.filter(function(el) {
            return el.type === type;
        });
    };

    filterNotType = (list, type) => {
        return list.filter(function(el) {
            return el.type !== type;
        });
    };

    filterCategory = (list, category) => {
        return list.filter(function(el) {
            return el.category === category;
        });
    };

    filterNotCategory = (list, category) => {
        return list.filter(function(el) {
            return el.category !== category;
        });
    };

    filterSource = (list, source) => {
        return list.filter(function(el) {
            return el.source === source;
        });
    };

    filterIneligible = list => {
        return list.filter(function(el) {
            return el.ineligible_expenses === true || el.classified === true;
        });
    };

    calcTotal = list => {
        var total = 0.0;
        list.map(x => (total += x.amount));
        return total;
    };
    
    updateProposal(values, fromStepper) {
        const {
            ormProposalUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            proposal,
            ormProposalUpdateLocalOnly
        } = this.props;
        const { id } = proposal;

        if (typeof fromStepper.step !== "undefined") {
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (
                (proposal.status !== "Draft In Progress" &&
                    proposal.status !== "Draft Feedback Received" &&
                    proposal.status !== "Revisions Needed") ||
                proposal.secondary_status === "Withdrawal Requested" ||
                proposal.secondary_status === "Withdrawn" ||
                (this.props.authState &&
                    this.props.authState.user &&
                    (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member"))
            ) {
                ormProposalUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = proposal.map;
        values.financial_capacity = proposal.financial_capacity;
        values.modify_date_label = proposal.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormProposalUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                handlePrev();
            } else {
                handleNext();
            }
        }
    }

    formatMoney = amount => {
        var amountnum = parseFloat(amount);
        var amountstring = amountnum.toFixed(0);
        if (amountnum >= 1000) {
            amountstring =
                amountstring.substring(0, amountstring.length - 3) +
                "," +
                amountstring.substring(amountstring.length - 3);
        }
        if (amountnum >= 1000000) {
            amountstring =
                amountstring.substring(0, amountstring.length - 7) +
                "," +
                amountstring.substring(amountstring.length - 7);
        }
        return amountstring;
    };

    handleWarningClose = () => {
        if (this.state.WarningDialogCheck) {
            this.setState({ WarningDialogCheck: false });
        }
    };

    render() {
        const {
            classes,
            proposalpersonnel,
            proposalcontracts,
            proposalexpenditures,
            proposalacquisitions,
            proposaltravels,
            proposalprintings,
            proposalexpenses,
            proposalfunds,
            ActionMenu,
            history,
            proposal,handleUnsavedFields,
            tab
        } = this.props;

        var full_list = [
            ...proposalpersonnel,
            ...proposalcontracts,
            ...proposalexpenditures,
            ...proposalacquisitions,
            ...proposaltravels,
            ...proposalprintings,
            ...proposalexpenses
        ];

        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        var expenditures_filter_cap = this.filterCategory(proposalexpenditures, "Capital Expenditure");
        var expenditures_filter_equip = this.filterNotCategory(proposalexpenditures, "Capital Expenditure");

        var travels_filter_inmn = this.filterType(proposaltravels, "Travel In Minnesota");
        var travels_filter_outmn = this.filterType(proposaltravels, "Travel Outside Minnesota");

        var funds_filter_state = this.filterSource(proposalfunds, "State");
        var funds_filter_nonstate = this.filterSource(proposalfunds, "Non-State");

        var personnel = this.buildListObj(proposalpersonnel, "Personnel");
        var contracts = this.buildListObj(proposalcontracts, "Professional and Technical Contracts");
        var equip_expenditures = this.buildListObj(expenditures_filter_equip, "Equipment, Tools, and Supplies");
        var cap_expenditures = this.buildListObj(expenditures_filter_cap, "Capital Expenditures");
        var acquisitions = this.buildListObj(proposalacquisitions, "Acquisition and Stewardship");
        var inmn_travels = this.buildListObj(travels_filter_inmn, "Travel In Minnesota");
        var outmn_travels = this.buildListObj(travels_filter_outmn, "Travel Outside Minnesota");
        var printings = this.buildListObj(proposalprintings, "Printing and Publication");
        var other_expenses = this.buildListObj(proposalexpenses, "Other");

        var summaryTotalpre = this.calcTotal(full_list);
        var totalIsThousand = summaryTotalpre % 1000 === 0;
        var summaryTotal = "$" + this.formatMoney(summaryTotalpre);

        var warningDialogOpen = false;
        if (!totalIsThousand && !is_read_only && this.state.WarningDialogCheck) warningDialogOpen = true;

        var ineligible_full_list = this.filterIneligible(full_list);
        var ineligibles = this.buildListObj(ineligible_full_list, "Classified Staff or Generally Ineligible Expenses");

        var state_funds = this.buildListObj(funds_filter_state, "State");
        var nonstate_funds = this.buildListObj(funds_filter_nonstate, "Non-State");

        var nonenrtffundsTotalpre = this.calcTotal(proposalfunds);
        var nonenrtffundsTotal = "$" + this.formatMoney(nonenrtffundsTotalpre);

        var total_project_cost = "$" + this.formatMoney(summaryTotalpre + nonenrtffundsTotalpre); 

        return (
            <Form
                getApi={el => (this.form = el)}
                key={proposal.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={proposal.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => {
                    console.log("HIT")
                    this.updateProposal(values, fromStepper)}
                }>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
            <div>
                <Grid container spacing={24} className={classes.gridMargin}>
                    <Grid item xs={12} lg={12} xl={12}>
                        <HelpLabel
                            inputLabel="Budget Summary"
                            title={true}
                            showLabel={true}
                            htmlText={true}
                            helpText={
                                tab.sub_tabs
                                    .find(tht => tht.tab_name === "Summary")
                                    .tab_help_texts.find(tht => tht.label === "Budget Summary").text
                            }
                        />
                        {ActionMenu}
                    </Grid>

                    <Grid container spacing={12} className={classes.gridPaddingTop}>
                        <Grid item xs={12} lg={12} xl={12} className={classes.gridPaddingRight}>
                            <Table className={classes.table}>
                                <EnhancedTableHead stickyHeader columnData={columnDataSummarys} />
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={10} className={classes.categoryTitle}>
                                            <b>{personnel.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {personnel.list.map(n => {
                                        return (
                                            <TableRow key={personnel.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell>{n.position_name}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.project_role}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.ineligible_expenses ? "X" : ""}
                                                </TableCell>
                                                <TableCell>
                                                    {n.benefits_pct < 1.0
                                                        ? parseInt(n.benefits_pct * 100) + "%"
                                                        : parseInt(n.benefits_pct) + "%"}
                                                </TableCell>
                                                <TableCell>{n.fte}</TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.classified ? "X" : ""}
                                                </TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {personnel.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{personnel.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{personnel.totalString}</b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={10} className={classes.categoryTitle}>
                                            <b>{contracts.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {contracts.list.map(n => {
                                        return (
                                            <TableRow key={contracts.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell>{n.entity}</TableCell>
                                                <TableCell>{n.project_role}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.ineligible_expenses ? "X" : ""}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.fte}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {contracts.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{contracts.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{contracts.totalString}</b>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={10} className={classes.categoryTitle}>
                                            <b>{equip_expenditures.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {equip_expenditures.list.map(n => {
                                        return (
                                            <TableRow key={equip_expenditures.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.ineligible_expenses ? "X" : ""}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {equip_expenditures.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{equip_expenditures.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{equip_expenditures.totalString}</b>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={10} className={classes.categoryTitle}>
                                            <b>{cap_expenditures.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {cap_expenditures.list.map(n => {
                                        return (
                                            <TableRow key={cap_expenditures.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.ineligible_expenses ? "X" : ""}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {cap_expenditures.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{cap_expenditures.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{cap_expenditures.totalString}</b>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={10} className={classes.categoryTitle}>
                                            <b>{acquisitions.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {acquisitions.list.map(n => {
                                        return (
                                            <TableRow key={acquisitions.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>
                                                    {n.description +
                                                        (n.acres
                                                            ? " Acres: " + Number.parseFloat(n.acres).toFixed(1) + " "
                                                            : "") +
                                                        (n.parcels ? " Parcels: " + n.parcels + " " : "") +
                                                        (n.miles
                                                            ? " Miles: " + Number.parseFloat(n.miles).toFixed(1)
                                                            : "")}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.ineligible_expenses ? "X" : ""}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {acquisitions.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{acquisitions.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{acquisitions.totalString}</b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={10} className={classes.categoryTitle}>
                                            <b>{inmn_travels.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {inmn_travels.list.map(n => {
                                        return (
                                            <TableRow key={inmn_travels.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.ineligible_expenses ? "X" : ""}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {inmn_travels.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{inmn_travels.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{inmn_travels.totalString}</b>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={10} className={classes.categoryTitle}>
                                            <b>{outmn_travels.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {outmn_travels.list.map(n => {
                                        return (
                                            <TableRow key={outmn_travels.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.ineligible_expenses ? "X" : ""}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {outmn_travels.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{outmn_travels.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{outmn_travels.totalString}</b>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={10} className={classes.categoryTitle}>
                                            <b>{printings.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {printings.list.map(n => {
                                        return (
                                            <TableRow key={printings.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.category}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.ineligible_expenses ? "X" : ""}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {printings.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{printings.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{printings.totalString}</b>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell colSpan={10} className={classes.categoryTitle}>
                                            <b>{other_expenses.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {other_expenses.list.map(n => {
                                        return (
                                            <TableRow key={other_expenses.title + "_" + n.id.toString()}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.purpose}</TableCell>
                                                <TableCell className={classes.centerAlign}>
                                                    {n.ineligible_expenses ? "X" : ""}
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {other_expenses.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>{other_expenses.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{other_expenses.totalString}</b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={9} className={classes.categoryTotalCell}>
                                            <b>Total Amount Requested</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{summaryTotal}</b>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    <Grid container spacing={12} className={classes.gridPaddingTop}>
                        <Grid item xs={12} lg={12} xl={12} className={classes.gridPaddingRight}>
                            <Typography className={classes.label}>
                                <b>{ineligibles.title}</b>
                            </Typography>
                            <Table className={classes.table}>
                                <EnhancedTableHead stickyHeader columnData={columnDataIneligibles} />
                                <TableBody>
                                    {ineligibles.list.map(n => {
                                        return (
                                            <TableRow key={ineligibles.title + "_" + n.id.toString()}>
                                                <TableCell className={classes.stndCell}>{n.masterCategory}</TableCell>
                                                <TableCell className={classes.stndCell}>
                                                    {n.position_name}
                                                    {n.entity}
                                                </TableCell>
                                                <TableCell className={classes.stndCell}>
                                                    {n.masterCategory === "Personnel" ? null : n.project_role}
                                                    {n.masterCategory === "Capital Expenditure"
                                                        ? n.masterCategory
                                                        : null}
                                                    {n.category}
                                                </TableCell>
                                                <TableCell className={classes.medCell}>
                                                    {n.project_role && n.description ? null : n.project_role}
                                                    {n.description}
                                                </TableCell>
                                                <TableCell className={classes.wideCell}>
                                                    {n.justify_ineligible_expenses}
                                                    {n.ineligible_expenses_reasons}
                                                    {proposal.ml_year < 2022 && (
                                                        <span style={{ fontWeight: "bold", display: "block" }}>
                                                            {n.single_source === true
                                                                ? "This is a single source contract."
                                                                : ""}
                                                        </span>
                                                    )}
                                                    <span style={{ fontWeight: "bold", display: "block" }}>
                                                        {typeof n.classified_reasons == "string" &&
                                                        n.classified_reasons.length > 0
                                                            ? "Classified : "
                                                            : ""}
                                                    </span>
                                                    {typeof n.classified_reasons == "string" &&
                                                    n.classified_reasons.length > 0
                                                        ? n.classified_reasons
                                                        : null}
                                                    <span style={{ fontWeight: "bold", display: "block" }}>
                                                        {typeof n.explanation == "string" && n.explanation.length > 0
                                                            ? "Additional Explanation : "
                                                            : ""}
                                                    </span>
                                                    {typeof n.explanation == "string" && n.explanation.length > 0
                                                        ? n.explanation
                                                        : null}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {ineligibles.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={10} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    <Grid container spacing={12} className={classes.gridPaddingTop}>
                        <Grid item xs={12} lg={9} xl={7} className={classes.gridPaddingRight}>
                            <Typography className={classes.label}>
                                <b>Non-ENRTF Funds Contributed</b>
                            </Typography>
                            <Table className={classes.table}>
                                <EnhancedTableHead stickyHeader columnData={columnDataFunds} />
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={5} className={classes.categoryTitle}>
                                            <b>{state_funds.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {state_funds.list.map(n => {
                                        return (
                                            <TableRow key={state_funds.title + "_" + n.id.toString()}>
                                                <TableCell>{n.type}</TableCell>
                                                <TableCell>{n.specific_source}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.status}</TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {state_funds.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={5} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={4} className={classes.categoryTotalCell}>
                                            <b>{state_funds.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{state_funds.totalString}</b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} className={classes.categoryTitle}>
                                            <b>{nonstate_funds.title}</b>
                                        </TableCell>
                                    </TableRow>
                                    {nonstate_funds.list.map(n => {
                                        return (
                                            <TableRow key={nonstate_funds.title + "_" + n.id.toString()}>
                                                <TableCell>{n.type}</TableCell>
                                                <TableCell>{n.specific_source}</TableCell>
                                                <TableCell>{n.description}</TableCell>
                                                <TableCell>{n.status}</TableCell>
                                                <TableCell className={classes.amountCell}>
                                                    {n.amount ? n.amountString : ""}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {nonstate_funds.list.length < 1 && (
                                        <TableRow>
                                            <TableCell colSpan={5} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Records</caption>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={4} className={classes.categoryTotalCell}>
                                            <b>{nonstate_funds.title + " Sub Total"}</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{nonstate_funds.totalString}</b>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.totalRow}>
                                        <TableCell colSpan={4} className={classes.categoryTotalCell}>
                                            <b>Total</b>
                                        </TableCell>
                                        <TableCell className={classes.totalCell}>
                                            <b>{nonenrtffundsTotal}</b>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            {proposal.ml_year >= 2025 && (
                                <>
                                <div style={{float:"right"}}>
                                <br/>
                                <Typography style={{marginRight: 16}}>
                                    <b>Total Project Cost: {total_project_cost}</b>
                                </Typography>
                                </div>
                                <div style={{float:"right", marginRight:40}}>
                                <br/>
                                <br/>
                                <br/>
                                <Switch field="accurate" label="This amount accurately reflects total project cost."/>
                                </div>
                                </>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={8} className={classes.gridPaddingTop}>
                        <Grid item xs={12} className={classes.rightAlign}>
                            <Button
                                variant="raised"
                                disabled={is_read_only}
                                onClick={() => history.push("/dashboard/")}
                                className={classes.button}>
                                Save Draft and Return to Dashboard
                            </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={is_read_only}
                                onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                Save and Proceed to Next Step
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <WarningDialog
                    noActions={true}
                    open={warningDialogOpen}
                    onClose={this.handleWarningClose}
                    htmlText={true}
                    title="Total ENRTF Funds Requested Warning"
                    text={
                        "The Total ENRTF Funds Requested Amount is not a round thousand dollars.<br/> Currently the total is <b>" +
                        summaryTotal +
                        "</b>. Please adjust your budget items so that the total request is to a round thousand dollar amount. " +
                        "You may adjust any item or combination of items in your budget."
                    }
                />
            </div>
            </form>
                )}
            </Form>
        );
    }
}

Budget_SummaryTab = connect(
    (state, ownProps) => ({
        proposalpersonnel: getProposalPersonnel(state, ownProps),
        proposalcontracts: getProposalContract(state, ownProps),
        proposalexpenditures: getProposalExpenditure(state, ownProps),
        proposalacquisitions: getProposalAcquisition(state, ownProps),
        proposaltravels: getProposalTravel(state, ownProps),
        proposalprintings: getProposalPrinting(state, ownProps),
        proposalexpenses: getProposalExpense(state, ownProps),
        proposalfunds: getProposalNonENRTFFund(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...Proposal.actions
    }
)(Budget_SummaryTab);

export default withStyles(styles)(withRouter(Budget_SummaryTab));
