import "./index.css";
import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import JssProvider from "react-jss/lib/JssProvider";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider as ReduxProvider } from "react-redux";
import { createReducer } from "redux-orm";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import localForage from "localforage";

import { MuiThemeProvider, createMuiTheme, createGenerateClassName } from "@material-ui/core/styles";

import Messages from "./components/admin/Messages";
import ProposalHelpMessages from "./components/admin/ProposalHelpMessages";
import MemberHelpMessages from "./components/admin/MemberHelpMessages";
import WorkPlanHelpMessages from "./components/admin/WorkPlanHelpMessages";
import AdmMember from "./components/admin/Members";
import AdmMemberEdit from "./components/admin/EditMember";
import ManagerApprove from "./components/admin/ManagerApprove";
import AdmUser from "./components/admin/Users";
import AdmUserEdit from "./components/admin/EditUser";
import AdmCategories from "./components/admin/FundingCategories";
import AdmRFPMembers from "./components/admin/ConfigureMember";
import AdmHome from "./components/admin/Home";
import AdmOrg from "./components/admin/Organizations";
import AdmOrgEdit from "./components/admin/EditOrganization";
import AdmAgent from "./components/admin/Agents";
import AdmAgentEdit from "./components/admin/EditAgent";
import AdmStaff from "./components/admin/Staff";
import AdmnBatch from "./components/admin/BatchProposal";
import AdmStaffEntrySelection from "./components/admin/StaffEntrySelection";
import AdmProposal from "./components/admin/ProposalProcess";
import AdmProposalProcessSummary from "./components/admin/ProposalProcessSummary";
import AdmReport from "./components/admin/Reports";
import AdmSearch from "./components/admin/Search";
import AdmWithdrawal from "./components/admin/Withdrawal";
import HomePage from "./components/home/HomePage";
import PasswordPage from "./components/auth/PasswordPage";
import PasswordSetPage from "./components/auth/PasswordSetPage";
import PasswordRecoverPage from "./components/auth/PasswordRecoverPage";
import MemberMessages from "./components/admin/MemberMessages";
import MemberEvaluation from "./components/member/MemberEvaluation";
import EvaluationResult from "./components/member/EvaluationResults";
import AccountConfirmation from "./components/auth/AccountConfirmation";
import UpdatePage from "./components/auth/UpdatePage";
import ParentDashboard from "./components/home/ParentDashboard";
import RegisterPage from "./components/auth/RegisterPage";
import Proposal from "./components/proposal/List";
import CreateWorkPlan from "./components/admin/CreateWorkPlan";
import LateRecommendationWorkPlan from "./components/admin/LateRecommended";
import WorkPlan from "./components/workplan/list";
import WorkPlanReview from "./components/member/WorkPlanReview";
import FeedBack from "./components/proposal/StaffFeedBack";
import ActivityLog from "./components/workplan/Activity_log";
import registerServiceWorker from "./registerServiceWorker";

import navReducer from "./components/common/reducers";
import ErrorBoundary from "./components/common/ErrorBoundary";
import { storeReady } from "./components/common/actions";
import authReducer from "./components/auth/reducers";
import orm, { reloadAll, syncReducer } from "./components/common/orm";
import WarningDialogWrapper from "./components/common/WarningDialogWrapper";
import EmailTemplatesConf from "./components/admin/EmailTemplatesConf";
import StatusUpdateHelpMessages from "./components/admin/StatusUpdateHelpMessages";

const theme = createMuiTheme({
    props: {
        MuiButton: {
            disableRipple: true
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif"
    },
    overrides: {
        MuiInputLabel: {
            root: {
                fontSize: 14,
                color: "#212529 !important"
            },
            shrink: {
                transform: "translate(0, 0px) scale(1)"
            }
        },
        MuiTableSortLabel: {
            root: {
                color: "#fff",
                "&:hover": {
                    color: "#bbdefb"
                },
                "&:focus": {
                    color: "#bbdefb"
                }
            },
            active: {
                color: "#bbdefb"
            }
        },
        MuiListItem: {
            root: {
                fontSize: 16,
                fontWeight: 400,
                color: "#212529"
            },
            button: {
                "&:focus": {
                    backgroundColor: "inherit"
                },
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.08) !important"
                }
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: 14,
                fontWeight: 400,
                color: "#212529",
                minHeight: 22
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: 14
            },
            error: {
                color: "#be0000 !important"
            }
        },
        MuiTableCell: {
            head: {
                backgroundColor: "#333333 !important",
                fontWeight: 500,
                color: "#ffffff"
            }
            /*body: {
                "& button": {
                    color: "#990033"
                }
            }*/
        },
        MuiTypography: {
            h2: {
                // Title of pages
                color: "#495057",
                fontSize: "1.55rem",
                lineHeight: 1.17
            },
            h3: {
                // Section titles
                fontWeight: 500,
                fontSize: "1.15rem",
                color: "#495057",
                lineHeight: 1.2
            },
            titleAction: {
                // Section titles next to action menu
                fontWeight: 500,
                fontSize: "1.15rem",
                color: "#495057",
                lineHeight: 1.2,
                float: "left",
                marginTop: 8,
                fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif"
            },
            body1: {
                // Regular text
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 1.5,
                color: "#212529"
            }
        },
        MuiButton: {
            root: {
                textTransform: "none",
                backgroundColor: "#E1EEF9",
                "&:hover": {
                    backgroundColor: "#CDDAE5",
                    textDecoration: "none"
                },
                border: "none",
                boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)"
            },
            /*text: {
                //color: "#be0000",
                backgroundColor: "#d0ab88"
            },*/
            contained: {
                border: "none"
            },
            focusVisible: {
                boxShadow: "0 0 0 0.2rem rgba(0,64,110,.50) !important",
                opacity: ".9"
            }
        },
        MuiTouchRipple: {
            childPulsate: {
                animation: "none !important"
            }
        }
    },
    palette: {
        primary: {
            light: "#4f98ce",
            main: "#006a9d",
            dark: "#00406e",
            /*light: "#A3ADB8",
            main: "#83929F",
            dark: "#4E6172",*/
            contrastText: "#fff"
        },
        secondary: {
            light: "#ff4040",
            main: "#cc0000",
            dark: "#cc0000",
            /*light: "#A3ADB8",
            main: "#83929F",
            dark: "#4E6172",*/
            contrastText: "#fff"
        }
    }
});

const generateClassName = createGenerateClassName({
    productionPrefix: "lccmr"
});

const logger = createLogger();
const reducer = combineReducers({
    auth: authReducer,
    orm: createReducer(orm),
    sync: syncReducer,
    nav: navReducer
});

const initialState = {
    nav: {
        sw: { checking: true }
    }
};

const middleware = process.env.NODE_ENV === "production" ? applyMiddleware(thunk) : applyMiddleware(thunk, logger);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    initialState,
    composeEnhancers(
        middleware,
        offline({
            ...offlineConfig,
            retry: (action, retries) => (retries > 3 ? null : retries * 1000),
            persistOptions: {
                storage: localForage,
                serialize: false
            },
            persistAutoRehydrate: () =>
                offlineConfig.persistAutoRehydrate({
                    stateReconciler: (state, inboundState) => {
                        // Don't carry over pending from previous session
                        // Loading icon becomes stuck
                        if (inboundState.auth && inboundState.auth.pending) {
                            inboundState.auth.pending = null;
                        }
                        // Don't wipe out sw notification if it happened during init
                        if (state.nav && state.nav.sw) {
                            inboundState.nav = {
                                ...inboundState.nav,
                                sw: state.nav.sw
                            };
                        }
                        inboundState.offline = {
                            ...state.offline,
                            ...inboundState.offline,
                            online: state.offline.online,
                            netInfo: state.offline.netInfo,
                            busy: state.offline.busy
                        };

                        // If member, no auth information, and inboud state clear it out. Otherwise leave inbound for staff on proposal process summary page.
                        if (
                            inboundState.orm &&
                            inboundState.orm.MemberEvaluation &&
                            (!inboundState.auth || (inboundState.auth && inboundState.auth.user && inboundState.auth.user.role === "Member"))
                        ) {
                            inboundState.orm.MemberEvaluation.items = [];
                            inboundState.orm.MemberEvaluation.itemsById = {};
                            inboundState.orm.MemberEvaluation.meta = {};
                        }

                        // Expanded member task panel on page load
                        if (inboundState.nav) {
                            inboundState.nav.memberTaskExpanded = true;
                        }

                        // When registering a brand new model (ex: FarmSurvey)
                        // the new model is not present in inboundState.orm
                        // but it is in state.orm.
                        // Add the empty orm models through state.orm so the new model
                        // is present but overwrite any existing models through inboundState.orm
                        inboundState.orm = {
                            ...state.orm,
                            ...inboundState.orm
                        };

                        inboundState.sync = state.sync;
                        
                        return inboundState;
                    }
                }),
            persistCallback: () => {
                store.dispatch(storeReady());
                store.dispatch(reloadAll(true));
            }
        })
    )
);

const getConfirmation = (message, callback) => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <WarningDialogWrapper message={message} callback={callback} />
        </MuiThemeProvider>,
        document.getElementById("pp")
    );
};

ReactDOM.render(
    <ErrorBoundary>
        <ReduxProvider store={store}>
            <JssProvider generateClassName={generateClassName}>
                <MuiThemeProvider theme={theme}>
                    <HashRouter getUserConfirmation={getConfirmation}>
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route path="/password/reset" component={PasswordPage} />
                            <Route path="/set/confirm/:uid/:token" component={PasswordSetPage} />
                            <Route path="/reset/confirm/:uid/:token" component={PasswordRecoverPage} />
                            <Route path="/activate/:uid/:token" component={AccountConfirmation} />
                            <Route path="/register" component={RegisterPage} />
                            <Route path="/updateuser" component={UpdatePage} />
                            <Route path="/dashboard" component={ParentDashboard} />
                            <Route path="/draft/workplan/:id" component={WorkPlan} />
                            <Route path="/create/workplan" component={CreateWorkPlan} />
                            <Route path="/late/workplan" component={LateRecommendationWorkPlan} />
                            <Route path="/final/workplan/:id" component={WorkPlan} />
                            <Route path="/workplan/review/:year" component={WorkPlanReview} />
                            <Route path="/proposal/:id" component={Proposal} />
                            <Route path="/proposalview/:id" component={Proposal} />
                            <Route path="/feedback/:id" component={FeedBack} />
                            <Route path="/activity/log/:id" component={ActivityLog} />
                            <Route path="/evaluation/result/:year/:id" component={EvaluationResult} />
                            <Route path="/member/select/presentation/:year" component={MemberEvaluation} />
                            <Route path="/member/evaluation/presentation/:year" component={MemberEvaluation} />
                            <Route path="/adm/members/:id" component={AdmMemberEdit} />
                            <Route path="/adm/members" component={AdmMember} />
                            <Route path="/adm/member/messages" component={MemberMessages} />
                            <Route path="/adm/helpmessages/member" component={MemberHelpMessages} />
                            <Route path="/adm/helpmessages/proposal" component={ProposalHelpMessages} />
                            <Route path="/adm/helpmessages/workplan" component={WorkPlanHelpMessages} />
                            <Route path="/adm/helpmessages/status-update" component={StatusUpdateHelpMessages} />
                            <Route path="/adm/configure/email-templates" component={EmailTemplatesConf} />
                            <Route path="/adm/messages" component={Messages} />
                            <Route path="/adm/users/:id" component={AdmUserEdit} />
                            <Route path="/adm/users" component={AdmUser} />
                            <Route path="/adm/staff" component={AdmStaff} />
                            <Route path="/adm/withdrawal" component={AdmWithdrawal} />
                            <Route path="/adm/batch/update" component={AdmnBatch} />
                            <Route path="/adm/select/presentation/:year" component={AdmStaffEntrySelection} />
                            <Route path="/adm/evaluation/presentation/:year" component={AdmStaffEntrySelection} />
                            <Route path="/adm/reports" component={AdmReport} />
                            <Route path="/adm/search" component={AdmSearch} />
                            <Route path="/adm/organizations/:id" component={AdmOrgEdit} />
                            <Route path="/adm/organizations" component={AdmOrg} />
                            <Route path="/adm/proposalprocess" component={AdmProposal} />
                            <Route path="/adm/proposal/process" component={AdmProposalProcessSummary} />
                            <Route path="/adm/agents/:id" component={AdmAgentEdit} />
                            <Route path="/adm/agents" component={AdmAgent} />
                            <Route path="/adm/home" component={AdmHome} />
                            <Route path="/adm/categories" component={AdmCategories} />
                            <Route path="/adm/configure/members" component={AdmRFPMembers} />
                            <Route path="/adm/manager_request/:id" component={ManagerApprove} />
                        </Switch>
                    </HashRouter>
                </MuiThemeProvider>
            </JssProvider>
        </ReduxProvider>
    </ErrorBoundary>,
    document.getElementById("root")
);

registerServiceWorker(store.dispatch);
