import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Typography } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Checkbox from "../common/Checkbox";
import Close from "@material-ui/icons/Close";
import CreateIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";

import AppContainer from "../common/AppContainer";
import CustomTableCell from "../common/TableCell";
import DatePicker from "../common/DatePicker";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import TextField from "../common/TextField";
import Select from "../common/Select";
import Snackbar from "../common/Snackbar";
import PageHeader from "../common/PageHeader";
import RadioButton from "../common/RadioButtons";
import WarningDialog from "../common/WarningDialog";
import { MemberTerm, User } from "../home/models";
import { createSelector } from "../common/orm";
import states from "../common/states.json";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    space: {
        marginTop: 20,
        marginBottom: 10
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    btn: {
        width: 250
    }
});

const getMember = User.selectByUrlId();
const getStaff = createSelector(schema => {
    return schema.User.filter(u => u.role === "Staff" && u.is_active)
        .orderBy("last_name", "asc")
        .toRefArray();
});

const getTerms = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (schema, id) => {
        return schema.MemberTerm.filter(term => term.user === id).toRefArray();
    }
);

const getAllTerms = MemberTerm.selectAll();

const columnTermData = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "str_term", numeric: false, label: "Terms", allowSort: true },
    { id: "appointment_date", numeric: false, label: "Appt Dates", allowSort: true },
    { id: "appointing_authority", numeric: false, label: "Appointing Authority", allowSort: true }
];

class EditMember extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        order: "desc",
        orderBy: "is_active",
        filter: null,
        term: null,
        dialogOpen: false,
        snackbarOpen: false,
        memberSnackbarOpen: false,
        warningDialog: false,
        warngingDialogID: false,
        activeTerm: null
    };

    componentDidMount() {
        document.title = "Edit Member - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        const emailValidator = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid email address" : null;
        };

        return {
            first_name: validateRequired(values.first_name),
            last_name: validateRequired(values.last_name),
            email: !values.id ? emailValidator(values.email) : null
        };
    };

    submitForm = values => {
        const { member, ormUserCreate, ormUserUpdate, history } = this.props;

        if (!member) {
            ormUserCreate({
                ...values,
                role: "Member"
            }).then(id => {
                if (id.error && id.error.message && id.error.message.includes("user with this email already exists")) {
                    this.setState({ memberSnackbarOpen: true });
                } else {
                    history.push(`/adm/members/${id}`);
                }
            });
        } else {
            ormUserUpdate({
                id: values.id,
                ...values
            });
        }
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props.terms.sort(function(a, b) {
            var date = columnTermData.find(cD => cD.id === orderBy).date;
            var numeric = columnTermData.find(cD => cD.id === orderBy).numeric;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
        if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
        if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
        if (b_start < a_start && a_end < b_end) return true; // a in b
        return false;
    };

    addTerm = values => {
        const { member, ormMemberTermCreate, ormMemberTermUpdate, allTerms } = this.props;

        var rfpTerms = [];

        //Terms should not overlap
        if (member && member.id) {
            //existing term don't check overlap on self
            rfpTerms = allTerms.filter(x => x.user === member.id && x.id !== values.id);
        } else {
            rfpTerms = allTerms.filter(x => x.user === member.id);
        }

        for (var x in rfpTerms) {
            var inputStart = new Date(values.start_date);
            var inputEnd = new Date(values.end_date);

            var start = new Date(rfpTerms[x].start_date + " 12:00:00");
            var end = new Date(rfpTerms[x].end_date + " 12:00:00");

            if (this.dateRangeOverlaps(start.getTime(), end.getTime(), inputStart.getTime(), inputEnd.getTime())) {
                this.setState({ snackbarOpen: true, start, end });
                return;
            }
        }

        if (member && member.id) {
            values.start_date = values.start_date.replace(" 12:00:00", "");
            values.end_date = values.end_date.replace(" 12:00:00", "");
            values.appointment_date = values.appointment_date.replace(" 12:00:00", "");
            if (!values.id) {
                ormMemberTermCreate({
                    user_id: member.id,
                    ...values
                });
            } else {
                ormMemberTermUpdate({
                    ...values
                });
            }
            this.setState({ dialogOpen: false });
        }
    };

    validateDate = date => {
        var newDate = date.replace(" 12:00:00", "");
        if (!/^\d{4}-\d{2}-\d{2}$/.test(newDate)) return false;

        var parts = newDate.split("-");
        var year = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var day = parseInt(parts[2], 10);

        if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    };

    termErrorValidator = values => {
        const dateValidator = date => {
            if (!date || date === "") {
                return "Required";
            }

            if (!this.validateDate(date)) {
                return "Invalid Date";
            }

            return null;
        };

        const checkEmpty = value => {
            if (!value || value === "") {
                return "Required";
            }
            return null;
        };

        return {
            start_date: dateValidator(values.start_date),
            end_date: dateValidator(values.end_date),
            appointment_date: dateValidator(values.appointment_date),
            appointing_authority: checkEmpty(values.appointing_authority)
        };
    };

    getDefaultValues = member => {
        var values = {};
        if (member && member.id) {
            values = { ...member };
        } else {
            values.is_active = false;
        }
        return values;
    };

    render() {
        const { classes, member, ormMemberTermDelete, terms } = this.props;
        const {
            activeTerm,
            dialogOpen,
            page,
            rowsPerPage,
            order,
            orderBy,
            snackbarOpen,
            memberSnackbarOpen,
            warningDialog,
            warngingDialogID,
            start,
            end
        } = this.state;

        var dateFormatedTerm = activeTerm ? { ...activeTerm } : {};
        if (dateFormatedTerm.id) {
            dateFormatedTerm.start_date = dateFormatedTerm.start_date + " 12:00:00";
            dateFormatedTerm.end_date = dateFormatedTerm.end_date + " 12:00:00";
            dateFormatedTerm.appointment_date = dateFormatedTerm.appointment_date + " 12:00:00";
        }

        return (
            <AppContainer authenticated>
                <Grid container justify="center" spacing={40}>
                    <PageHeader title={member ? "Edit Member: " + member.first_name : "New Member"} admin_page />
                    <Grid item xs={11}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={this.getDefaultValues(member)}
                            validateError={this.errorValidator}
                            onSubmit={this.submitForm}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <Checkbox field="is_active" label="Active" style={{ marginTop: 16 }} />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <Select
                                                field="prefix"
                                                label="Prefix"
                                                options={[
                                                    { label: "", value: "" },
                                                    { label: "Mr.", value: "Mr." },
                                                    { label: "Mrs.", value: "Mrs." },
                                                    { label: "Ms.", value: "Ms." }
                                                ]}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <Select
                                                field="gov_prefix"
                                                label="Legislative Prefix"
                                                options={[
                                                    { label: "", value: "" },
                                                    { label: "Citizen", value: "Citizen" },
                                                    { label: "Rep.", value: "Rep." },
                                                    { label: "Sen.", value: "Sen." }
                                                ]}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="first_name" label="First Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="last_name" label="Last Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField field="email" label="Email (username)" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField phoneNumber field="phone" label="Home Phone" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField phoneNumber field="phone_2" label="Work Phone" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <TextField phoneNumber field="phone_3" label="Cell Phone" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <Select
                                                field="role"
                                                label="Role"
                                                options={[
                                                    { label: "User", value: "User" },
                                                    { label: "Staff", value: "Staff" },
                                                    { label: "Reviewer", value: "Reviewer" },
                                                    { label: "Member", value: "Member" }
                                                ]}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}></Grid>
                                        <Typography variant="h3">Check for preferred mailing address</Typography>
                                        <Grid container spacing={16}>
                                            <Grid item xs={1}>
                                                <RadioButton
                                                    field="preferred_address"
                                                    name="preferred_address"
                                                    fullWidth
                                                    optionvalue="Home"
                                                />
                                            </Grid>
                                            <Grid item xs={11}>
                                                <TextField field="address" label="Home Address" fullWidth />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs>
                                                <TextField field="city" label="City" fullWidth />
                                            </Grid>
                                            <Grid item xs>
                                                <Select field="state" label="State" options={states} fullWidth />
                                            </Grid>
                                            <Grid item xs>
                                                <TextField field="zip" label="Zip" fullWidth />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={16}>
                                            <Grid item xs={1}>
                                                <RadioButton
                                                    field="preferred_address"
                                                    name="preferred_address"
                                                    fullWidth
                                                    optionvalue="Office"
                                                />
                                            </Grid>
                                            <Grid item xs={11}>
                                                <TextField field="address_2" label="Office Address" fullWidth />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs>
                                                <TextField field="city_2" label="City" fullWidth />
                                            </Grid>
                                            <Grid item xs>
                                                <Select field="state_2" label="State" options={states} fullWidth />
                                            </Grid>
                                            <Grid item xs>
                                                <TextField field="zip_2" label="Zip" fullWidth />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth component={Link} to="/adm/members">
                                                Return to Manage Members
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                        <Grid item xs={12} className={classes.space} style={{ display: !member ? "none" : "" }}>
                            <Button
                                className={classes.btn}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.setState({ dialogOpen: true, activeTerm: {} });
                                }}>
                                &nbsp;&nbsp;Add Term&nbsp;&nbsp;
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{ display: !member ? "none" : "" }}>
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    columnData={columnTermData}
                                    stickyHeader
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />
                                <TableBody>
                                    {terms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                        return (
                                            <TableRow key={n.id}>
                                                <CustomTableCell className={classes.nowrap}>
                                                    <Tooltip title="Edit Term">
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({ activeTerm: n, dialogOpen: true })
                                                            }
                                                            color="primary"
                                                            className={classes.deleteWidth}>
                                                            <CreateIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete Term">
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    warngingDialogID: n.id,
                                                                    warningDialog: true
                                                                })
                                                            }
                                                            color="primary"
                                                            className={classes.deleteWidth}>
                                                            <DeleteIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                </CustomTableCell>
                                                <CustomTableCell>{n.str_term}</CustomTableCell>
                                                <CustomTableCell>{n.appointment_date}</CustomTableCell>
                                                <CustomTableCell>{n.appointing_authority}</CustomTableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {terms.length < 1 && (
                                        <TableRow>
                                            <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Terms Found</caption>
                                            </CustomTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                {terms.length > 25 && (
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                colSpan={7}
                                                count={terms.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActionsWrapped}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                )}
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog role="main" aria-label={!dateFormatedTerm.id ? "Add Term" : "Edit Term"} open={dialogOpen}>
                    <Toolbar>
                        <Typography variant="h1" className={classes.flex}>
                            {!dateFormatedTerm.id ? "Add" : "Edit"} Term
                        </Typography>
                        <IconButton
                            aria-label="Close Dialog"
                            onClick={() => {
                                this.setState({ dialogOpen: false });
                            }}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                defaultValues={dateFormatedTerm}
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                validateError={this.termErrorValidator}
                                onSubmit={values => this.addTerm(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={16}>
                                            <DatePicker
                                                style={{ marginTop: 10 }}
                                                field="start_date"
                                                label="Start Date"
                                                fullWidth
                                            />
                                            <DatePicker field="end_date" label="End Date" fullWidth />
                                            <DatePicker field="appointment_date" label="Appointment Date" fullWidth />
                                            <Select
                                                field="appointing_authority"
                                                label="Appointing Authority"
                                                options={[
                                                    {
                                                        label: "House of Representatives",
                                                        value: "House of Representatives"
                                                    },
                                                    { label: "Governor", value: "Governor" },
                                                    { label: "Senate", value: "Senate" }
                                                ]}
                                                fullWidth
                                            />
                                            <TextField
                                                fullWidth
                                                label="Appointment Notes"
                                                field="appointment_notes"
                                                multiline
                                            />
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    onClick={() => {
                                                        this.setState({ dialogOpen: false });
                                                    }}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={() => {
                        ormMemberTermDelete(warngingDialogID);
                        this.setState({ warningDialog: false });
                    }}
                    cancelText={"No"}
                    cancelAction={() => this.setState({ warningDialog: false })}
                    open={warningDialog}
                    title="Delete Term"
                    text={"Are you sure you want to delete this Term?"}
                />
                <Snackbar
                    handleSnackbarClose={() => {
                        this.setState({ snackbarOpen: false });
                    }}
                    origin={{ vertical: "top", horizontal: "center" }}
                    snackbarOpen={snackbarOpen}
                    message={`The date you entered for this term overlaps with another for this member! The term is ${
                        start ? start.getFullYear() : ""
                    }-${end ? end.getFullYear() + "!" : ""}`}
                />
                <Snackbar
                    handleSnackbarClose={() => {
                        this.setState({ memberSnackbarOpen: false });
                    }}
                    snackbarOpen={memberSnackbarOpen}
                    message={"Email Already Exists in system!"}
                />
            </AppContainer>
        );
    }
}

EditMember = connect(
    (state, ownProps) => ({
        authState: state.auth,
        member: getMember(state, ownProps),
        staff: getStaff(state, ownProps),
        terms: getTerms(state, ownProps),
        allTerms: getAllTerms(state)
    }),
    {
        ...MemberTerm.actions,
        ...User.actions
    }
)(EditMember);

export default withStyles(styles)(withRouter(EditMember));
