import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";
import { differenceInMonths } from "date-fns";
import { withStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { editWorkPlanStatuses, workPlanComments, WorkPlanStatuses2, ActiveProjectStatuses } from "../../api/constants";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Visibility from "@material-ui/icons/Visibility";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "../common/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import Cancel from "@material-ui/icons/Cancel";

import IconButton from "@material-ui/core/IconButton";
import AnnouncementIcon from "@material-ui/icons/Announcement";

import * as navActions from "../common/actions";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import CustomTableCell from "../common/TableCell";
import TablePaginationActionsWrapped from "../common/Paginator";
import EnhancedTableHead from "../common/EnhancedTableHead";

import { ProposalReview } from "./models";
import { createSelector, reloadListOfModels } from "../common/orm";

function checkForWarning(years, reviews, obj, auth, workplan_table, wp_due_date = null, has_pm_change) {
    const year = years.find(x => obj && x.year === obj.ml_year);
    var warning = false;
    var warningMessage = "";

    if (year) {
        const today = new Date();
        const due_date = new Date(obj.due_date + "T23:00:00"); //almost end of day since not really strict date

        if (obj.primary_phase === "Proposal") {
            const close_date = new Date(year.final_date);
            const review = reviews.find(x => x.proposal === obj.id);
            const months = differenceInMonths(today, close_date);

            // if scores are locked don't show warning
            if (!year.staff_eval_open && (obj.status === "Final Submitted" || obj.status === "Revisions Submitted") && today > close_date && months >= 1) {
                if ((!review || !review.final_score) && obj.secondary_status !== "Withdrawn") {
                    warning = true;
                    warningMessage = " Missing Staff Score";
                }
            }
        } else if (workplan_table && obj.primary_phase.includes("Work Plan") && obj.status.includes("Due")) {
            const close_date = year.final_created ? new Date(year.finalplan_date) : new Date(year.draftplan_date);
            const override_due_date = wp_due_date ? new Date(wp_due_date) : null;
            const status_string = obj.primary_phase.includes("Final") ? "Final" : "Draft";

            if (override_due_date && today > override_due_date) {
                warning = true;
                warningMessage = ` ${status_string} Work Plan not submitted`;
            } else if (!override_due_date && today > close_date) {
                warning = true;
                warningMessage = ` ${status_string} Work Plan not submitted`;
            }
        }

        //revisions common for both user and staff
        if (obj.status.includes("Revisions Needed") && today > due_date) {
            warning = true;
            warningMessage += "Revisions Over Due";

            //for staff display if score exists
            if (obj.status === "Revisions Needed" && auth && auth.user && auth.user.role === "Staff") {
                const review = reviews.find(x => x.proposal === obj.id);
                warningMessage += `. ${review && review.final_score ? "Has Score." : "Missing Staff Score."}`;
            }
        }
    }   
    if (has_pm_change)
        warningMessage += " PM Change Request Needs Review";

    if (warning) {
        return warningMessage;
    } else {
        return null;
    }
}

function formatDate(date, add) {
    if (!date) {
        return "";
    }
    var temp;
    if (add) {
        temp = new Date(date + "T12:00:00");
    } else {
        temp = new Date(date);
    }
    const dateFns = new DateFnsUtils();
    return dateFns.format(temp, "MM/dd/yyyy");
}

function getDueDate(years, object, wp_due_date = null) {
    const year = years.find(x => x.year === object.ml_year);
    const date = new Date();
    if (year) {
        var final_date = new Date(year.final_date);
        var draft_date = new Date(year.draft_date);
        if (object.primary_phase === "Draft Proposal" || object.primary_phase === "Proposal") {
            if (object.status === "Draft In Progress") {
                if (year.funding_open && date < draft_date) {
                    return formatDate(year.draft_date, false);
                } else {
                    return formatDate(final_date);
                }
            } else if (object.status === "Revisions Needed") {
                return formatDate(object.due_date, true);
            } else {
                return formatDate(final_date, false);
            }
        } else if (object.primary_phase === "Draft Work Plan" || object.primary_phase === "Final Work Plan") {
            if (object.status === "Draft Work Plan Due") {
                if (wp_due_date) {
                    return formatDate(wp_due_date, true);
                } else {
                    return formatDate(year.draftplan_date, true);
                }
            } else if (object.status === "Final Work Plan Due") {
                if (wp_due_date) {
                    return formatDate(wp_due_date, true);
                } else {
                    return formatDate(year.finalplan_date, true);
                }
            } else if (object.status === "Draft Work Plan Revisions Needed" || object.status === "Final Work Plan Revisions Needed") {
                return formatDate(object.due_date, true);
            }
        }
    } else {
        return "---";
    }
}

const getReviews = ProposalReview.selectAll();
const getProposals = createSelector(
    (state, ownProps) => (state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema, id, auth) => {
        const years = schema.SystemSetting.all().toRefArray();
        const reviews = schema.ProposalReview.all().toRefArray();

        return schema.ProposalView.all()
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(view => {
                var category = schema.FundingCategory.filter(
                    x =>
                        x.category_name === (view.final_funding_category ? view.final_funding_category : view.funding_category) && x.year_label === view.ml_year
                ).first();

                var secondarySuffix = null;
                if (view.funding_category === "Small Projects" && !view.final_funding_category) {
                    secondarySuffix = schema.FundingCategory.filter(
                        x => x.category_name === view.secondary_funding_category && x.year_label === view.ml_year
                    ).first();
                } else if (view.final_funding_category && view.final_funding_category === "Small Projects") {
                    secondarySuffix = schema.FundingCategory.filter(
                        x => x.category_name === view.final_secondary_funding_category && x.year_label === view.ml_year
                    ).first();
                }

                var final_category = category && category.suffix ? category.suffix + ". " : "";
                final_category += view.final_funding_category ? view.final_funding_category : view.funding_category ? view.funding_category : "";

                if (view.final_funding_category) {
                    final_category +=
                        view.final_funding_category === "Small Projects" && view.final_secondary_funding_category
                            ? " / " + (secondarySuffix ? secondarySuffix.suffix + ". " : "") + view.final_secondary_funding_category
                            : "";
                } else {
                    final_category +=
                        view.funding_category === "Small Projects" && view.secondary_funding_category
                            ? " / " + (secondarySuffix ? secondarySuffix.suffix + ". " : "") + view.secondary_funding_category
                            : "";
                }

                var org = schema.Organization.filter(x => x.id === view.organization_id).first();
                var scores = view.score_proposal
                    .all()
                    .filter(z => z.user === id)
                    .first();

                return {
                    staff_lead_label: view.staff_lead_label,
                    final_category,
                    warningMessage: checkForWarning(years, reviews, view, auth, false, null, view.has_pm_change),
                    table_due_date: getDueDate(years, view),
                    full_name: view.pm_first_name + " " + view.pm_last_name,
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : "",
                    eval_status: scores ? (scores.done ? "Complete" : "Not Complete") : "",
                    ...view.ref
                };
            });
    }
);
const getPeerReview = createSelector(
    (state, ownProps) => (state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema, id, auth) => {
        const years = schema.SystemSetting.all().toRefArray();

        return schema.ProposalView.all()
            .filter(x => x.peer_review)
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(view => {
                var plan;
                if (view.primary_phase === "Draft Work Plan") {
                    plan = schema.DraftWorkPlanView.filter(x => x.proposal_record_id === view.id).first();
                } else {
                    plan = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === view.id).first();
                }

                var category;
                var secondarySuffix;
                var final_category;
                var org;
                var status_date_label;
                var addendum_date_label = null;

                if (plan) {
                    category = schema.FundingCategory.filter(x => x.category_name === plan.funding_category && x.year_label === plan.ml_year).first();

                    secondarySuffix = null;
                    if (plan.funding_category === "Small Projects") {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === plan.secondary_funding_category && x.year_label === plan.ml_year
                        ).first();
                    }

                    status_date_label = plan.status_date_label;

                    if (!view.status.includes("Due") && !view.status.includes("Needed")) {
                        addendum_date_label = plan.addendum_date_label ? plan.addendum_date_label : "";
                    }

                    final_category = category && category.suffix ? category.suffix + ". " : "";
                    final_category += plan.funding_category ? plan.funding_category : "";

                    final_category +=
                        plan.funding_category === "Small Projects" && plan.secondary_funding_category
                            ? " / " + (secondarySuffix ? secondarySuffix.suffix + ". " : "") + plan.secondary_funding_category
                            : "";

                    org = schema.Organization.filter(x => x.id === plan.organization_id).first();
                }

                return {
                    id: view.id,
                    ml_year: view.ml_year,
                    staff_lead_id: view.staff_lead_id,
                    proposal_unique_id: view.proposal_unique_id,
                    recommended_amount: view.appropriation_amount !== null ? view.appropriation_amount : view.recommended_amount,
                    name: plan ? plan.name : "",
                    peer_status: view.peer_status,
                    pm_first_name: plan ? plan.pm_first_name : "",
                    pm_last_name: plan ? plan.pm_last_name : "",
                    final_category,
                    funding_total: plan ? plan.funding_total : "",
                    status: view.status,
                    status_date_label,
                    addendum_date_label,
                    secondary_status: view.secondary_status,
                    staff_lead_label: view.staff_lead_label,
                    table_due_date: getDueDate(years, view),
                    full_name: plan ? plan.pm_first_name + " " + plan.pm_last_name : "",
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : ""
                };
            });
    }
);
const getWorkPlans = createSelector(
    (state, ownProps) => (state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema, id, auth) => {
        const years = schema.SystemSetting.all().toRefArray();
        const reviews = schema.ProposalReview.all().toRefArray();

        return schema.ProposalView.all()
            .filter(x => (x.primary_phase === "Draft Work Plan" || x.primary_phase === "Final Work Plan") && x.status !== "Final Work Plan Approved" && x.status !== "Final Abstract Due" && x.status !== "Final Abstract Submitted" && x.status !== "Final Abstract Revisions Needed" && x.status !== "Project Completed")
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(view => {
                var plan;
                var category;
                var secondarySuffix;
                var final_category;
                var org;
                var status_date_label;
                var wp_due_date;

                if (view.primary_phase === "Draft Work Plan") {
                    plan = schema.DraftWorkPlanView.filter(x => x.proposal_record_id === view.id).first();
                } else {
                    plan = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === view.id).first();
                }

                if (plan) {
                    category = schema.FundingCategory.filter(x => x.category_name === plan.funding_category && x.year_label === plan.ml_year).first();

                    secondarySuffix = null;
                    if (plan.funding_category === "Small Projects") {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === plan.secondary_funding_category && x.year_label === plan.ml_year
                        ).first();
                    }

                    status_date_label = plan.status_date_label;

                    final_category = category && category.suffix ? category.suffix + ". " : "";
                    final_category += plan.funding_category ? plan.funding_category : "";

                    final_category +=
                        plan.funding_category === "Small Projects" && plan.secondary_funding_category
                            ? " / " + (secondarySuffix ? secondarySuffix.suffix + ". " : "") + plan.secondary_funding_category
                            : "";

                    wp_due_date = plan.wp_due_date;

                    org = schema.Organization.filter(x => x.id === plan.organization_id).first();
                }

                return {
                    id: view.id,
                    recommended_amount: view.appropriation_amount !== null ? view.appropriation_amount : view.recommended_amount,
                    staff_lead_id: view.staff_lead_id,
                    ml_year: view.ml_year,
                    proposal_unique_id: view.proposal_unique_id,
                    name: plan ? plan.name : "",
                    peer_status: view.peer_status,
                    pm_first_name: plan ? plan.pm_first_name : "",
                    pm_last_name: plan ? plan.pm_last_name : "",
                    final_category,
                    funding_total: plan ? plan.funding_total : null,
                    status: view.status,
                    status_date_label,
                    secondary_status: view.secondary_status,
                    staff_lead_label: view.staff_lead_label,
                    warningMessage: checkForWarning(years, reviews, view, auth, true, wp_due_date),
                    table_due_date: getDueDate(years, view, wp_due_date),
                    full_name: plan ? plan.pm_first_name + " " + plan.pm_last_name : "",
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : ""
                };
            });
    }
);

const getActiveProjects = createSelector(
    (state, ownProps) => (state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema, id, auth) => {
        const years = schema.SystemSetting.all().toRefArray();
        const reviews = schema.ProposalReview.all().toRefArray();

        return schema.ProposalView.all()
            .filter(x => x.status === "Final Work Plan Approved" || x.status === "Final Abstract Due" || x.status === "Final Abstract Submitted" || x.status === "Final Abstract Revisions Needed")
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(view => {
                var plan;
                var category;
                var secondarySuffix;
                var final_category;
                var org;
                var status_date_label;
                var wp_due_date;
                var next_status_update_date;
                var reporting_status;
                var last_action;
                var amendment_status;
                var includes_amendment;
                var status_update_submission_date;
                var amendment_submission_date;

                plan = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === view.id).first();

                if (plan) {
                    category = plan.funding_category;

                    secondarySuffix = null;
                    if (plan.funding_category === "Small Projects") {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === plan.secondary_funding_category && x.year_label === plan.ml_year
                        ).first();
                    }

                    status_date_label = plan.status_date_label;

                    final_category = category && category.suffix ? category.suffix + ". " : "";
                    final_category += plan.funding_category ? plan.funding_category : "";

                    final_category +=
                        plan.funding_category === "Small Projects" && plan.secondary_funding_category
                            ? " / " + (secondarySuffix ? secondarySuffix.suffix + ". " : "") + plan.secondary_funding_category
                            : "";

                    wp_due_date = plan.wp_due_date;

                    org = schema.Organization.filter(x => x.id === plan.organization_id).first();

                    next_status_update_date = plan.next_status_update_date;
                    reporting_status = plan.reporting_status;
                    last_action = plan.last_action;
                    amendment_status = plan.status;
                    includes_amendment = plan.includes_amendment;
                    status_update_submission_date = plan.status_update_submission_date;
                    amendment_submission_date = plan.amendment_submission_date;
                }

                return {
                    id: view.id,
                    recommended_amount: view.appropriation_amount !== null ? view.appropriation_amount : view.recommended_amount,
                    staff_lead_id: view.staff_lead_id,
                    ml_year: view.ml_year,
                    citation_ml_year: view.citation_ml_year,
                    proposal_unique_id: view.proposal_unique_id,
                    name: plan ? plan.name : "",
                    peer_status: view.peer_status,
                    pm_first_name: plan ? plan.pm_first_name : "",
                    pm_last_name: plan ? plan.pm_last_name : "",
                    final_category,
                    category,
                    last_action,
                    next_status_update_date,
                    reporting_status,
                    funding_total: plan ? plan.funding_total : null,
                    status: view.status,
                    amendment_due_date: plan ? plan.amendment_due_date : null,
                    status_date_label,
                    amendment_status,
                    secondary_status: view.secondary_status,
                    staff_lead_label: view.staff_lead_label,
                    includes_amendment,
                    warningMessage: checkForWarning(years, reviews, view, auth, true, wp_due_date),
                    statusWarningMessage: checkForStatus(next_status_update_date, reporting_status, amendment_status, view.has_pm_change, status_update_submission_date, amendment_submission_date, view.has_cd_change, view.status, last_action),
                    table_due_date: getDueDate(years, view, wp_due_date),
                    full_name: plan ? plan.pm_first_name + " " + plan.pm_last_name : "",
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : "",
                    subdivision: view.subdivision,
                    appropriation_amount: view.appropriation_amount,
                    has_pm_change: view.has_pm_change,
                    has_cd_change: view.has_cd_change
                };
            });
    }
);

const getCompletedProjects = createSelector(
    (state, ownProps) => (state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema, id, auth) => {
        const years = schema.SystemSetting.all().toRefArray();
        const reviews = schema.ProposalReview.all().toRefArray();

        return schema.ProposalView.all()
            .filter(x => x.status === "Project Completed")
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(view => {
                var plan;
                var category;
                var secondarySuffix;
                var final_category;
                var org;
                var status_date_label;
                var wp_due_date;
                var next_status_update_date;
                var reporting_status;
                var last_action;
                var amendment_status;
                var includes_amendment;
                var status_update_submission_date;
                var amendment_submission_date;

                plan = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === view.id).first();

                if (plan) {
                    category = plan.funding_category;

                    secondarySuffix = null;
                    if (plan.funding_category === "Small Projects") {
                        secondarySuffix = schema.FundingCategory.filter(
                            x => x.category_name === plan.secondary_funding_category && x.year_label === plan.ml_year
                        ).first();
                    }

                    status_date_label = plan.status_date_label;

                    final_category = category && category.suffix ? category.suffix + ". " : "";
                    final_category += plan.funding_category ? plan.funding_category : "";

                    final_category +=
                        plan.funding_category === "Small Projects" && plan.secondary_funding_category
                            ? " / " + (secondarySuffix ? secondarySuffix.suffix + ". " : "") + plan.secondary_funding_category
                            : "";

                    wp_due_date = plan.wp_due_date;

                    org = schema.Organization.filter(x => x.id === plan.organization_id).first();

                    next_status_update_date = plan.next_status_update_date;
                    reporting_status = plan.reporting_status;
                    last_action = plan.last_action;
                    amendment_status = plan.status;
                    includes_amendment = plan.includes_amendment;
                    status_update_submission_date = plan.status_update_submission_date;
                    amendment_submission_date = plan.amendment_submission_date;
                }

                return {
                    id: view.id,
                    recommended_amount: view.appropriation_amount !== null ? view.appropriation_amount : view.recommended_amount,
                    staff_lead_id: view.staff_lead_id,
                    ml_year: view.ml_year,
                    citation_ml_year: view.citation_ml_year,
                    proposal_unique_id: view.proposal_unique_id,
                    name: plan ? plan.name : "",
                    peer_status: view.peer_status,
                    pm_first_name: plan ? plan.pm_first_name : "",
                    pm_last_name: plan ? plan.pm_last_name : "",
                    final_category,
                    category,
                    last_action,
                    next_status_update_date,
                    reporting_status,
                    funding_total: plan ? plan.funding_total : null,
                    status: view.status,
                    amendment_due_date: plan ? plan.amendment_due_date : null,
                    status_date_label,
                    amendment_status,
                    secondary_status: view.secondary_status,
                    staff_lead_label: view.staff_lead_label,
                    includes_amendment,
                    warningMessage: checkForWarning(years, reviews, view, auth, true, wp_due_date),
                    statusWarningMessage: checkForStatus(next_status_update_date, reporting_status, amendment_status, view.has_pm_change, status_update_submission_date, amendment_submission_date, view.has_cd_change, view.status, last_action),
                    table_due_date: getDueDate(years, view, wp_due_date),
                    full_name: plan ? plan.pm_first_name + " " + plan.pm_last_name : "",
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : "",
                    subdivision: view.subdivision,
                    appropriation_amount: view.appropriation_amount,
                    timeline: plan ? plan.timeline : null,
                    amount_spent: plan ? plan.amount_spent : null,
                    has_pm_change: view.has_pm_change,
                    has_cd_change: view.has_cd_change
                };
            });
    }
);

function checkForStatus(next_status_update_date, reporting_status, amendment_status, has_pm_change, status_update_submission_date, amendment_submission_date, has_cd_change, proposal_status, last_action_date) {
    if (has_pm_change)
        return "PM Change Request Needs Review";

    if (has_cd_change)
        return "Completion Date Change Request Needs Review";

    var today = new Date();

    var last_action = null;
    if (proposal_status === "Final Abstract Submitted" && last_action_date) {
        last_action = new Date(last_action_date);
        last_action = last_action.setDate(last_action.getDate() + 10);
        if (today > last_action) {
            return "Final Abstract Needs Review";
        }
    }
    
    var status_update_submitted = null;
    var amendment_submitted = null;
    if (status_update_submission_date) {
        status_update_submitted = new Date(status_update_submission_date);
        status_update_submitted = status_update_submitted.setDate(status_update_submitted.getDate() + 10);
        if (today > status_update_submitted) {
            if (reporting_status === "Update Submitted" || reporting_status === "Final Update Submitted" || reporting_status === "Update Revisions Submitted" || reporting_status === "Final Update Revisions Submitted" || reporting_status === "Additional Update Submitted" || reporting_status === "Additional Update Revisions Submitted")
                return "Needs Review";
        }
    }
    if (amendment_submission_date) {
        amendment_submitted = new Date(amendment_submission_date);
        amendment_submitted = amendment_submitted.setDate(amendment_submitted.getDate() + 10);
        if (today > amendment_submitted) {
            if (amendment_status === "Amendment Submitted" || amendment_status === "Amendment Revisions Submitted")
                return "Needs Review";
        }
    }
}

const getYears = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        backgroundColor: "#fff"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted"
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    flex: {
        flex: 1
    },
    progress: {
        width: "100%",
        textAlign: "center"
    },
    textBox: {
        width: "95%",
        maxWidth: "400px"
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "400px"
    },
    chip: {
        marginTop: 4,
        marginRight: 8
    },
    floatLeft: {
        float: "left"
    }
});

function getValidStatuses() {
    return ["Draft Submitted", "Draft Feedback Received", "Final Submitted", "Revisions Needed", "Revisions Submitted"];
}

const columnDataProposal = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "final_category", numeric: false, label: "Category/Sub Category", allowSort: true, minWidth:"250px" },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true },
    { id: "status_date_label", numeric: false, label: "Date Of Last Action", date: true, allowSort: true },
    { id: "table_due_date", numeric: false, label: "Next Due Date", allowSort: true, date: true },
    { id: "staff_lead_label", numeric: false, label: "Staff Lead", allowSort: true },
    { id: "eval_status", numeric: false, label: "My Evaluation Status", allowSort: true },
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true },
    { id: "warningMessage", numeric: false, label: "Warning", allowSort: true }
];

const columnDataWorkPlan = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Project ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "final_category", numeric: false, label: "Category/Sub Category", allowSort: true, minWidth:"250px" },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "recommended_amount", numeric: true, label: "Funding", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true },
    { id: "status_date_label", numeric: false, label: "Date Of Last Action", date: true, allowSort: true },
    { id: "table_due_date", numeric: false, label: "Next Due Date", allowSort: true, date: true },
    { id: "staff_lead_label", numeric: false, label: "Staff Lead", allowSort: true },
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true },
    { id: "peer_status", numeric: false, label: "Peer Review Status", allowSort: true },
    { id: "warningMessage", numeric: false, label: "Warning", allowSort: true }
];

const columnDataActiveProject = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Project ID", allowSort: true },
    { id: "ml_year", numeric: false, label: "ML Year / Subd", allowSort: true, combined: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "final_category", numeric: false, label: "Category", allowSort: true, minWidth:"250px" },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "recommended_amount", numeric: true, label: "Funding", allowSort: true },
    { id: "reporting_status", numeric: false, label: "Reporting Status", allowSort: true },
    { id: "last_action", numeric: false, label: "Date Of Last Action", date: true, allowSort: true },
    { id: "next_status_update_date", numeric: false, label: "Next Due Date", allowSort: true, date: true },
    { id: "staff_lead_label", numeric: false, label: "Staff Lead", allowSort: true },
    { id: "includes_amendment", numeric: false, label: "Update Includes Amendment Request", allowSort: true },
    { id: "statusWarningMessage", numeric: false, label: "Warning", allowSort: true }
];

const columnDataPeer = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "final_category", numeric: false, label: "Category/Sub Category", allowSort: true, minWidth:"250px" },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "recommended_amount", numeric: true, label: "Funding", allowSort: true },
    { id: "status_date_label", numeric: false, label: "Date Work Plan Last Submitted", date: true, allowSort: true },
    { id: "addendum_date_label", numeric: false, label: "Addendum Date of Last Action", allowSort: true, date: true },
    { id: "peer_status", numeric: false, label: "Peer Review Status", allowSort: true }
];

const columnDataFinalProject = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Project ID", allowSort: true },
    { id: "ml_year", numeric: false, label: "ML Year / Subd", allowSort: true, combined: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "final_category", numeric: false, label: "Category", allowSort: true, minWidth:"250px" },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "recommended_amount", numeric: true, label: "Funding", allowSort: true },
    { id: "reporting_status", numeric: false, label: "Project Status", allowSort: true },
    { id: "timeline", numeric: false, label: "Completion Date", date: true, allowSort: true },
    { id: "amount_spent", numeric: true, label: "Amount Spent", allowSort: true },
    { id: "amount_remaining", numeric: true, label: "Amount Returned", allowSort: true },
    { id: "post_project", numeric: false, label: "Post Project Impact Reporting", allowSort: true },
    { id: "last_action", numeric: false, label: "Date Of Last Action", date: true, allowSort: true },
    { id: "statusWarningMessage", numeric: false, label: "Warning", allowSort: true }
];

class StaffWorkLoadManagement extends Component {
    state = {
        proposalExpanded: true,
        planExpanded: false,
        peerExpanded: false,
        assignedFilter: "All",
        pastdueFilter: "All",
        proposal_year: ["All"],
        cp_year: ["All"],
        cp_year2: ["All"],
        status_list: ["All"],
        assignedPlanFilter: "All",
        assignedProjectFilter: "All",
        assignedCProjectFilter: "All",
        peerreview_proposal_year: ["All"],
        workplan_proposal_year: ["All"],
        workplan_status_list: ["All"],
        activeproject_status_list: ["All"],
        set_list: false,
        textCache: "",
        workplan_textCache: "",
        activeproject_textCache: "",
        completedproject_textCache: "",
        page: 0,
        rowsPerPage: 10,
        workplan_page: 0,
        workplan_rowsPerPage: 10,
        peer_page: 0,
        peer_rowsPerPage: 10,
        project_page: 0,
        project_rowsPerPage: 10,
        projectc_page: 0,
        projectc_rowsPerPage: 10
    };

    componentDidMount() {
        const { authState } = this.props;
        document.title = "Dashboard - LCCMR Proposal and Grant Management System";

        if (authState && authState.user) {
            //this.props.reloadListOfModels(["SystemSetting"]);
            this.props.reloadListOfModels(["FinalWorkPlanView"]);
            this.props.reloadListOfModels(["ProposalView"]);
            this.props.reloadListOfModels(["DraftWorkPlanView"]);
        }
    }
    handleChangeCPYear = event => {
        this.changeCPYear(event.target.value);
    };
    changeCPYear = year_list => {
        var posAll = year_list.indexOf("All");
        var empty = year_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.cp_year.includes("-- Empty --")) {
            year_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.cp_year.includes("-- Empty --") && year_list.length === 0) {
            year_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.cp_year.includes("-- Empty --")) {
            year_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                year_list = ["All"];
            }
        }

        if (posAll > -1 && year_list.length < this.state.cp_year.length) {
            year_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && this.props.years.map(x => x.year).length > 0) {
            if (year_list.length > this.state.cp_year.length && this.state.cp_year.includes("All")) {
                year_list.splice(posAll, 1);
                year_list = this.props.years.filter(x => x.year !== year_list[0]).map(x => x.year);
            } else {
                year_list = ["All"];
            }
        }

        if (year_list.length === 0) {
            year_list = ["-- Empty --"];
        }

        this.setState({ cp_year: year_list });
    };
    handleChangeCPYear2 = event => {
        this.changeCPYear2(event.target.value);
    };
    changeCPYear2 = year_list => {
        var posAll = year_list.indexOf("All");
        var empty = year_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.cp_year2.includes("-- Empty --")) {
            year_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.cp_year2.includes("-- Empty --") && year_list.length === 0) {
            year_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.cp_year2.includes("-- Empty --")) {
            year_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                year_list = ["All"];
            }
        }

        if (posAll > -1 && year_list.length < this.state.cp_year2.length) {
            year_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && this.props.years.map(x => x.year).length > 0) {
            if (year_list.length > this.state.cp_year2.length && this.state.cp_year2.includes("All")) {
                year_list.splice(posAll, 1);
                year_list = this.props.years.filter(x => x.year !== year_list[0]).map(x => x.year);
            } else {
                year_list = ["All"];
            }
        }

        if (year_list.length === 0) {
            year_list = ["-- Empty --"];
        }

        this.setState({ cp_year2: year_list });
    };

    changeProposalYear = year_list => {
        var posAll = year_list.indexOf("All");
        var empty = year_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.proposal_year.includes("-- Empty --")) {
            year_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.proposal_year.includes("-- Empty --") && year_list.length === 0) {
            year_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.proposal_year.includes("-- Empty --")) {
            year_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                year_list = ["All"];
            }
        }

        if (posAll > -1 && year_list.length < this.state.proposal_year.length) {
            year_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && this.props.years.map(x => x.year).length > 0) {
            if (year_list.length > this.state.proposal_year.length && this.state.proposal_year.includes("All")) {
                year_list.splice(posAll, 1);
                year_list = this.props.years.filter(x => x.year !== year_list[0]).map(x => x.year);
            } else {
                year_list = ["All"];
            }
        }

        if (year_list.length === 0) {
            year_list = ["-- Empty --"];
        }

        this.setState({ proposal_year: year_list });
    };
    changeProposalStatus = status_list => {
        var posAll = status_list.indexOf("All");
        var empty = status_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.status_list.includes("-- Empty --")) {
            status_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.status_list.includes("-- Empty --") && status_list.length === 0) {
            status_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.status_list.includes("-- Empty --")) {
            status_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                status_list = ["All"];
            }
        }

        if (posAll > -1 && status_list.length < this.state.status_list.length) {
            status_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && status_list.length > this.state.status_list.length) {
            if (status_list.length > this.state.status_list.length && this.state.status_list.includes("All")) {
                status_list.splice(posAll, 1);
                status_list = getValidStatuses()
                    .filter(x => x !== status_list[0])
                    .map(x => x);
            } else {
                status_list = ["All"];
            }
        }

        if (status_list.length === 0) {
            status_list = ["-- Empty --"];
        }

        this.setState({ status_list });
    };
    changeWorkPlanYear = year_list => {
        var posAll = year_list.indexOf("All");
        var empty = year_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.workplan_proposal_year.includes("-- Empty --")) {
            year_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.workplan_proposal_year.includes("-- Empty --") && year_list.length === 0) {
            year_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.workplan_proposal_year.includes("-- Empty --")) {
            year_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                year_list = ["All"];
            }
        }

        if (posAll > -1 && year_list.length < this.state.workplan_proposal_year.length) {
            year_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && this.props.years.map(x => x.year).length > 0) {
            if (year_list.length > this.state.workplan_proposal_year.length && this.state.workplan_proposal_year.includes("All")) {
                year_list.splice(posAll, 1);
                year_list = this.props.years.filter(x => x.year !== year_list[0]).map(x => x.year);
            } else {
                year_list = ["All"];
            }
        }

        if (year_list.length === 0) {
            year_list = ["-- Empty --"];
        }

        this.setState({ workplan_proposal_year: year_list });
    };
    changeWorkPlanStatus = workplan_status_list => {
        var posAll = workplan_status_list.indexOf("All");
        var empty = workplan_status_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.workplan_status_list.includes("-- Empty --")) {
            workplan_status_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.workplan_status_list.includes("-- Empty --") && workplan_status_list.length === 0) {
            workplan_status_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.workplan_status_list.includes("-- Empty --")) {
            workplan_status_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                workplan_status_list = ["All"];
            }
        }

        if (posAll > -1 && workplan_status_list.length < this.state.workplan_status_list.length) {
            workplan_status_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && workplan_status_list.length > this.state.workplan_status_list.length) {
            if (workplan_status_list.length > this.state.workplan_status_list.length && this.state.workplan_status_list.includes("All")) {
                workplan_status_list.splice(posAll, 1);
                workplan_status_list = WorkPlanStatuses2.filter(x => x !== workplan_status_list[0]).map(x => x);
            } else {
                workplan_status_list = ["All"];
            }
        }

        if (workplan_status_list.length === 0) {
            workplan_status_list = ["-- Empty --"];
        }

        this.setState({ workplan_status_list });
    };
    changeReviewYear = review_year_list => {
        var posAll = review_year_list.indexOf("All");
        var empty = review_year_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.peerreview_proposal_year.includes("-- Empty --")) {
            review_year_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.peerreview_proposal_year.includes("-- Empty --") && review_year_list.length === 0) {
            review_year_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.peerreview_proposal_year.includes("-- Empty --")) {
            review_year_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                review_year_list = ["All"];
            }
        }

        if (posAll > -1 && review_year_list.length < this.state.peerreview_proposal_year.length) {
            review_year_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && this.props.years.map(x => x.year).length > 0) {
            if (review_year_list.length > this.state.peerreview_proposal_year.length && this.state.peerreview_proposal_year.includes("All")) {
                review_year_list.splice(posAll, 1);
                review_year_list = this.props.years.filter(x => x.year !== review_year_list[0]).map(x => x.year);
            } else {
                review_year_list = ["All"];
            }
        }

        if (review_year_list.length === 0) {
            review_year_list = ["-- Empty --"];
        }

        this.setState({ peerreview_proposal_year: review_year_list });
    };
    changeActiveProjectStatus = activeproject_status_list => {
        var posAll = activeproject_status_list.indexOf("All");
        var empty = activeproject_status_list.indexOf("-- Empty --");

        if (empty > -1 && !this.state.activeproject_status_list.includes("-- Empty --")) {
            activeproject_status_list = ["-- Empty --"];
            posAll = -1;
        }

        if (empty > -1 && this.state.activeproject_status_list.includes("-- Empty --") && activeproject_status_list.length === 0) {
            activeproject_status_list = [];
            posAll = -1;
        } else if (empty > -1 && this.state.activeproject_status_list.includes("-- Empty --")) {
            activeproject_status_list.splice(empty, 1);
            if (posAll > -1) {
                posAll = -1;
                activeproject_status_list = ["All"];
            }
        }

        if (posAll > -1 && activeproject_status_list.length < this.state.activeproject_status_list.length) {
            activeproject_status_list.splice(posAll, 1);
            posAll = -1;
        }

        if (posAll > -1 && activeproject_status_list.length > this.state.activeproject_status_list.length) {
            if (activeproject_status_list.length > this.state.activeproject_status_list.length && this.state.activeproject_status_list.includes("All")) {
                activeproject_status_list.splice(posAll, 1);
                activeproject_status_list = ActiveProjectStatuses.filter(x => x !== activeproject_status_list[0]).map(x => x);
            } else {
                activeproject_status_list = ["All"];
            }
        }

        if (activeproject_status_list.length === 0) {
            activeproject_status_list = ["-- Empty --"];
        }

        this.setState({ activeproject_status_list });
    };
    handleChangeProposalYear = event => {
        this.changeProposalYear(event.target.value);
    };
    handleChangeProposalStatus = event => {
        this.changeProposalStatus(event.target.value);
    };
    handleTextField = event => {
        this.setState({ textCache: event });
    };
    handleChangeWorkPlanYear = event => {
        this.changeWorkPlanYear(event.target.value);
    };
    handleChangeWorkPlanStatus = event => {
        this.changeWorkPlanStatus(event.target.value);
    };
    handleTextFieldWorkplan = event => {
        this.setState({ workplan_textCache: event });
    };
    handleTextFieldActiveProject = event => {
        this.setState({ activeproject_textCache: event });
    };
    handleTextFieldCompletedProject = event => {
        this.setState({ completedproject_textCache: event });
    };
    handleChangeReviewYear = event => {
        this.changeReviewYear(event.target.value);
    };
    handleChangeActiveProjectStatus = event => {
        this.changeActiveProjectStatus(event.target.value);
    };
    handleChangePage = (event, page, pageKey) => {
        this.setState({ [pageKey]: page });
    };
    handleChangeRowsPerPage = (event, pageKey, rowKey) => {
        this.setState({ [rowKey]: event.target.value, [pageKey]: 0 });
    };
    clearTextCache = () => {
        this.setState({ textCache: "" });
    };
    clearWorkplanTextCache = () => {
        this.setState({ workplan_textCache: "" });
    };
    filterByValue = (array, string) => {
        return array.filter(o =>
            [
                "proposal_unique_id",
                "name",
                "full_name",
                "final_category",
                "funding_total",
                "full_name",
                "organization_name",
                "staff_lead_label",
                "status",
                "peer",
                "status_date_label",
                "table_due_date",
                "eval_status",
                "secondary_status"
            ].some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };
    filterByValueProject = (array, string) => {
        return array.filter(o =>
            [
                "appropriation_amount",
                "category",
                "final_category",
                "full_name",
                "funding_total",
                "last_action",
                "ml_year",
                "name",
                "next_status_update_date",
                "organization_name",
                "peer_status",
                "proposal_unique_id",
                "recommended_amount",
                "reporting_status",
                "secondary_status",
                "staff_lead_label",
                "status",
                "status_date_label",
                "subdivision",
                "amendment_status"
            ].some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    filterAndSort = (assignedFilter, proposal_year, status_list, text) => {
        const { authState, proposals } = this.props;
        var arr = proposals;

        if (assignedFilter !== "All") {
            arr = arr.filter(x => x.staff_lead_id === authState.user.id);
        }

        if (!proposal_year.includes("All")) {
            arr = arr.filter(x => proposal_year.includes(x.ml_year));
        }

        if (!status_list.includes("All")) {
            arr = arr.filter(x => status_list.includes(x.status));
        }

        if (text) {
            arr = this.filterByValue(arr, text);
        }

        return arr;
    };

    filterAndSortWokplans = (assignedFilter, year, status_list, text) => {
        const { authState, workPlans } = this.props;
        var arr = workPlans;

        if (assignedFilter !== "All") {
            arr = arr.filter(x => x.staff_lead_id === authState.user.id);
        }

        if (!year.includes("All")) {
            arr = arr.filter(x => year.includes(x.ml_year));
        }

        if (!status_list.includes("All")) {
            arr = arr.filter(x => status_list.includes(x.status));
        }

        if (text) {
            arr = this.filterByValue(arr, text);
        }

        return arr;
    };

    handleRequestSort = (event, property, sortKey) => {
        const orderBy = property;
        let order = "desc";

        if (sortKey === "proposals" && this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        } else if (sortKey === "workPlans" && this.state.workplan_orderBy === property && this.state.workplan_order === "desc") {
            order = "asc";
        } else if (sortKey === "activeProjects" && this.state.project_orderBy === property && this.state.project_order === "desc") {
            order = "asc";
        } else if (sortKey === "completedProjects" && this.state.projectc_orderBy === property && this.state.projectc_order === "desc") {
            order = "asc";
        } else if (this.state.peer_orderBy === property && this.state.peer_order === "desc") {
            order = "asc";
        }

        var columnData = [];
        if (sortKey === "proposals") {
            columnData = columnDataProposal;
        } else if (sortKey === "workPlans") {
            columnData = columnDataWorkPlan;
        } else if (sortKey === "activeProjects") {
            columnData = columnDataActiveProject;
        } else if (sortKey === "completedProjects") {
            columnData = columnDataFinalProject;
        } else {
            columnData = columnDataPeer;
        }

        this.props[sortKey].sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;
            var combined = columnData.find(cD => cD.id === orderBy).combined;
            if (date) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined" || a[orderBy] === "-") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined" || b[orderBy] === "-") return -1;
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else if (combined) {
                if (order === "desc")
                    return (b[orderBy] + " " + b["subdivision"] || "").toUpperCase() < (a[orderBy] + " " + a["subdivision"] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] + " " + a["subdivision"] || "").toUpperCase() < (b[orderBy] + " " + b["subdivision"] || "").toUpperCase() ? -1 : 1;
            } else {
                if (order === "desc") return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        if (sortKey === "proposals") {
            this.setState({ order, orderBy });
        } else if (sortKey === "workPlans") {
            this.setState({ workplan_order: order, workplan_orderBy: orderBy });
        } else if (sortKey === "activeProjects") {
            this.setState({ project_order: order, project_orderBy: orderBy });
        } else if (sortKey === "completedProjects") {
            this.setState({ projectc_order: order, projectc_orderBy: orderBy });
        } else {
            this.setState({ peer_order: order, peer_orderBy: orderBy });
        }
    };

    render() {
        const {
            classes,
            workPlans,
            proposals,
            peerReviews,
            activeProjects,
            completedProjects,
            years,
            navState,
            navTogglePeer,
            navToggleWorkPlan,
            navToggleActiveProject,
            navToggleCompletedProject,
            navToggleProposal,
            authState
        } = this.props;

        const {
            assignedFilter,
            pastdueFilter,
            assignedPlanFilter,
            assignedProjectFilter,
            assignedCProjectFilter,
            proposal_year,
            workplan_proposal_year,
            peerreview_proposal_year,
            status_list,
            workplan_status_list,
            activeproject_status_list,
            textCache,
            workplan_textCache,
            activeproject_textCache,
            completedproject_textCache,
            order,
            workplan_order,
            orderBy,
            workplan_orderBy,
            page,
            workplan_page,
            rowsPerPage,
            workplan_rowsPerPage,
            peer_order,
            peer_orderBy,
            peer_page,
            peer_rowsPerPage,
            project_order,
            project_orderBy,
            project_page,
            project_rowsPerPage,
            projectc_order,
            projectc_orderBy,
            projectc_page,
            projectc_rowsPerPage,
            cp_year,
            cp_year2,
        } = this.state;

        const years_list = years.map(x => x.year);

        var filtered = this.filterAndSort(assignedFilter, proposal_year, status_list, textCache);

        const pmAction = filtered.reduce((a, b) => (b.status === "Draft Feedback Received" || b.status === "Revisions Needed" ? a + 1 : a), 0);
        const lccmrAction = filtered.reduce(
            (a, b) => (b.status === "Draft Submitted" || b.status === "Final Submitted" || b.status === "Revisions Submitted" ? a + 1 : a),
            0
        );

        var draftCount;
        if (!proposal_year.includes("All")) {
            draftCount = proposals.reduce((a, b) => (b.status === "Draft In Progress" && proposal_year.includes(b.ml_year) ? a + 1 : a), 0);
        } else {
            draftCount = proposals.reduce((a, b) => (b.status === "Draft In Progress" ? a + 1 : a), 0);
        }

        filtered = filtered.filter(x => x.status !== "Draft In Progress");

        // WORKPLAN
        var InputWorkPlanProps = {};
        if (workplan_textCache) {
            InputWorkPlanProps["endAdornment"] = (
                <InputAdornment position="end">
                    <Tooltip title="Clear Search">
                        <IconButton aria-label="Clear Search" onClick={this.clearWorkplanTextCache}>
                            <Cancel />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            );
        }

        var draftCountWorkPlan;
        var finalWorkPlan;
        if (!workplan_proposal_year.includes("All")) {
            draftCountWorkPlan = workPlans.reduce((a, b) => (b.status === "Draft Work Plan Due" && workplan_proposal_year.includes(b.ml_year) ? a + 1 : a), 0);
            finalWorkPlan = workPlans.reduce((a, b) => (b.status === "Final Work Plan Due" && workplan_proposal_year.includes(b.ml_year) ? a + 1 : a), 0);
        } else {
            draftCountWorkPlan = workPlans.reduce((a, b) => (b.status === "Draft Work Plan Due" ? a + 1 : a), 0);
            finalWorkPlan = workPlans.reduce((a, b) => (b.status === "Final Work Plan Due" ? a + 1 : a), 0);
        }

        var workplan_filtered = this.filterAndSortWokplans(assignedPlanFilter, workplan_proposal_year, workplan_status_list, workplan_textCache);
        const workplan_pmAction = workplan_filtered.reduce((a, b) => (editWorkPlanStatuses.includes(b.status) ? a + 1 : a), 0);
        const workplan_lccmrAction = workplan_filtered.reduce((a, b) => (workPlanComments.includes(b.status) ? a + 1 : a), 0);

        // REVIEW
        var peerreview_filtered = [];

        if (!peerreview_proposal_year.includes("All")) {
            peerreview_filtered = peerReviews.filter(x => peerreview_proposal_year.includes(x.ml_year));
        } else {
            peerreview_filtered = peerReviews;
        }

        // COMPLTED PRojects

        var completed_projects_filtered = [];
        if (assignedCProjectFilter !== "All") {
            completed_projects_filtered = completedProjects.filter(x => x.staff_lead_id === authState.user.id);
        } else {
            completed_projects_filtered = completedProjects;
        }
        if (completedproject_textCache) {
            completed_projects_filtered = this.filterByValueProject(completed_projects_filtered, completedproject_textCache);
        }
        if (!cp_year.includes("All")) {
            completed_projects_filtered = completed_projects_filtered.filter(x => cp_year.includes(x.ml_year))
        }
        if (!cp_year2.includes("All")) {
            completed_projects_filtered = completed_projects_filtered.filter(x => {
                if (x.timeline) {
                    var d = new Date(x.timeline)
                    return cp_year2.includes(d.getFullYear().toString())
                }
                return false;
            })
        }
        const statistic_cp_total = completed_projects_filtered.length;
        var appropriated = 0;
        var spent = 0;
        var returned = 0;
        completed_projects_filtered.forEach(function(comp_proj) {
            appropriated += comp_proj.appropriation_amount;
            spent += comp_proj.amount_spent;
            returned += (comp_proj.appropriation_amount - comp_proj.amount_spent);
        })
        const statistic_cp_appropriated = "$" + appropriated.toLocaleString();
        const statistic_cp_spent = "$" + spent.toLocaleString();
        const statistic_cp_returned = "$" + returned.toLocaleString();
        const statistic_cp_warnings = 0

        // Active Projects
        var active_projects_filtered = [];

        if (assignedProjectFilter !== "All") {
            active_projects_filtered = activeProjects.filter(x => x.staff_lead_id === authState.user.id);
        } else {
            active_projects_filtered = activeProjects;
        }
        if (pastdueFilter !== "All") {
            active_projects_filtered = active_projects_filtered.filter(x => {
                if (x.next_status_update_date) {
                    var today = new Date();
                    var next_date = new Date(x.next_status_update_date);
                    if (today > next_date) return true;
                }
                /*if (x.amendment_due_date) {
                    var next_date2 = new Date(x.amendment_due_date);
                    if (today > next_date2) return true;
                }*/
                return false;
            });
        }
        if (!activeproject_status_list.includes("All")) {
            active_projects_filtered = active_projects_filtered.filter(x => activeproject_status_list.includes(x.status) || activeproject_status_list.includes(x.reporting_status) || activeproject_status_list.includes(x.amendment_status));
        }
        if (activeproject_textCache) {
            active_projects_filtered = this.filterByValueProject(active_projects_filtered, activeproject_textCache);
        }

        const statistic_ap_submitted = active_projects_filtered.filter(
            x => x.reporting_status === "Update Submitted" || x.reporting_status === "Final Update Submitted" || x.reporting_status === "Additional Update Submitted" || x.reporting_status === "Update Revisions Submitted" || x.reporting_status === "Final Update Revisions Submitted" || x.reporting_status === "Additional Update Revisions Submitted"
        ).length;
        const statistic_ap_due = active_projects_filtered.filter(x => x.reporting_status === "Update Due" || x.reporting_status === "Final Update Due").length;
        const statistic_ap_past_due = active_projects_filtered.filter(x => {
            var today = new Date();
            var next_date = new Date(x.next_status_update_date);
            if (today > next_date) return true;
            return false;
        }).length;
        const statistic_amendment_past_due = active_projects_filtered.filter(x => {
            if (x.amendment_status !== "Amendment Revisions Needed" && x.amendment_status !== "Amendment In Progress")
                return false;
            var today = new Date();
            var next_date = new Date(x.amendment_due_date);
            if (today > next_date) return true;
            return false;
        }).length;
        const statistic_ap_pm_action = active_projects_filtered.filter(
            x => x.status === "Final Abstract Due" || x.status === "Final Abstract Revisions Needed" || x.reporting_status === "Update Revisions Needed" || x.reporting_status === "Final Update Revisions Needed" || x.reporting_status === "Additional Update Revisions Needed" || x.amendment_status === "Amendment Revisions Needed" || x.amendment_status === "Amendment In Progress"
        ).length;
        const statistic_ap_lccmr_action = active_projects_filtered.filter(
            x => x.status === "Final Abstract Submitted" || x.reporting_status === "Update Revisions Submitted" || x.reporting_status === "Final Update Revisions Submitted" || x.reporting_status === "Additional Update Revisions Submitted" 
            || x.reporting_status === "Update Submitted" || x.reporting_status === "Final Update Submitted" || x.reporting_status === "Additional Update Submitted" 
            || x.amendment_status === "Amendment Submitted" || x.amendment_status === "Amendment Revisions Submitted" || x.has_pm_change || x.has_cd_change
        ).length;

        const statistic_ap_action = statistic_ap_pm_action + statistic_ap_lccmr_action;
        const statistic_ap_warnings = active_projects_filtered.filter(x => x.statusWarningMessage).length;

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Dashboard" />
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.proposalExpand} onChange={navToggleProposal} CollapseProps={{ unmountOnExit: true }}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} />
                                    Proposals
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Search by Staff:</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="assigned"
                                                name="assigned"
                                                value={assignedFilter}
                                                onChange={e => this.setState({ assignedFilter: e.target.value })}>
                                                <FormControlLabel value="All" control={<Radio color="primary" />} label="All" />
                                                <FormControlLabel value="me" control={<Radio color="primary" />} label="Assigned to me" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Typography id="proposal-years-checkbox-label">Select Year(s) :</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-years-checkbox-label"
                                            id="proposal-years-checkbox"
                                            multiple
                                            value={proposal_year}
                                            onChange={this.handleChangeProposalYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="-- Empty --" value="-- Empty --">
                                                <Checkbox color="primary" checked={proposal_year.includes("-- Empty --") && !proposal_year.includes("All")} />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={proposal_year.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {years_list.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={proposal_year.includes("All") || proposal_year.includes(year)} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="proposal-statuses-checkbox-label">Select Status(es) :</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-statuses-checkbox-label"
                                            id="proposal-statuses-checkbox"
                                            multiple
                                            value={status_list}
                                            onChange={this.handleChangeProposalStatus}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="Empty" value="-- Empty --">
                                                <Checkbox color="primary" checked={status_list.includes("-- Empty --") && !status_list.includes("All")} />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={status_list.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {getValidStatuses().map(status => (
                                                <MenuItem key={status} value={status}>
                                                    <Checkbox color="primary" checked={status_list.includes("All") || status_list.includes(status)} />
                                                    <ListItemText primary={status} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        className={classes.textBox}
                                                        eventHandle={this.handleTextField}
                                                        field="proposal_filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h3">Summary Statistics</Typography>
                                        <Chip className={classes.chip} color="primary" label={`Draft Proposals In Progress:  ${draftCount}`} />
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            label={`Draft Proposals Submitted:  ${filtered.reduce(
                                                (a, b) => (b.status === "Draft Submitted" || b.status === "Draft Feedback Received" ? a + 1 : a),
                                                0
                                            )}`}
                                        />
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            label={`Final Proposals Submitted:  ${filtered.reduce(
                                                (a, b) => (b.primary_phase !== "Draft Proposal" ? a + 1 : a),
                                                0
                                            )}`}
                                        />
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            label={`Final Proposals Total Amount:  $${filtered
                                                .filter(z => z.primary_phase !== "Draft Proposal")
                                                .reduce((a, b) => a + b.funding_total, 0)
                                                .toLocaleString()}`}
                                        />

                                        <Typography variant="h3" style={{ marginTop: 30 }}>
                                            Workload Statistics
                                        </Typography>
                                        <Chip className={classes.chip} color="primary" label={`Awaiting Project Manager Action: ${pmAction}`} />
                                        <Chip className={classes.chip} color="primary" label={`Awaiting LCCMR Action:  ${lccmrAction}`} />
                                        <Chip className={classes.chip} color="primary" label={`Total Awaiting Action:  ${pmAction + lccmrAction}`} />
                                        <Chip
                                            className={classes.chip}
                                            color="secondary"
                                            label={`Warnings:  ${filtered.reduce((a, b) => (b.warningMessage ? a + 1 : a), 0)}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <ProposalTable 
                                            classes={classes}
                                            columnDataProposal={columnDataProposal}
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={this.handleRequestSort}
                                            filtered={filtered}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            handleChangePage={this.handleChangePage}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.workPlanExpand} onChange={navToggleWorkPlan} CollapseProps={{ unmountOnExit: true }}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} />
                                    Work Plans
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Search by Staff:</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="assigned"
                                                name="assigned"
                                                value={assignedPlanFilter}
                                                onChange={e => this.setState({ assignedPlanFilter: e.target.value })}>
                                                <FormControlLabel value="All" control={<Radio color="primary" />} label="All" />
                                                <FormControlLabel value="me" control={<Radio color="primary" />} label="Assigned to me" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Typography id="workplan-years-checkbox-label">Select Year(s) :</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="workplan-years-checkbox-label"
                                            id="workplan-years-checkbox"
                                            multiple
                                            value={workplan_proposal_year}
                                            onChange={this.handleChangeWorkPlanYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="-- Empty --" value="-- Empty --">
                                                <Checkbox
                                                    color="primary"
                                                    checked={workplan_proposal_year.includes("-- Empty --") && !workplan_proposal_year.includes("All")}
                                                />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={workplan_proposal_year.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {years_list.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={workplan_proposal_year.includes("All") || workplan_proposal_year.includes(year)}
                                                    />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="workplan-statuses-checkbox-label">Select Status(es) :</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="workplan-statuses-checkbox-label"
                                            id="workplan-statuses-checkbox"
                                            multiple
                                            value={workplan_status_list}
                                            onChange={this.handleChangeWorkPlanStatus}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="Empty" value="-- Empty --">
                                                <Checkbox
                                                    color="primary"
                                                    checked={workplan_status_list.includes("-- Empty --") && !workplan_status_list.includes("All")}
                                                />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={workplan_status_list.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {WorkPlanStatuses2.map(status => (
                                                <MenuItem key={status} value={status}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={workplan_status_list.includes("All") || workplan_status_list.includes(status)}
                                                    />
                                                    <ListItemText primary={status} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        className={classes.textBox}
                                                        eventHandle={this.handleTextFieldWorkplan}
                                                        field="wp_filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h3">Summary Statistics</Typography>
                                        <Chip className={classes.chip} color="primary" label={`Draft Work Plans In Progress:  ${draftCountWorkPlan}`} />
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            label={`Draft Work Plans Submitted:  ${workplan_filtered.reduce(
                                                (a, b) =>
                                                    b.status === "Draft Work Plan Submitted" ||
                                                    b.status === "Draft Work Plan Revisions Needed" ||
                                                    b.status === "Draft Work Plan Revisions Submitted" ||
                                                    b.status === "Draft Work Plan Accepted"
                                                        ? a + 1
                                                        : a,
                                                0
                                            )}`}
                                        />
                                        <Chip className={classes.chip} color="primary" label={`Final Work Plans In Progress: ${finalWorkPlan}`} />
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            label={`Final Work Plans Submitted:  ${workplan_filtered.reduce(
                                                (a, b) =>
                                                    (b.status === "Final Work Plan Submitted" ||
                                                        b.status === "Final Work Plan Revisions Needed" ||
                                                        b.status === "Final Work Plan Revisions Submitted" ||
                                                        b.status === "Final Work Plan Accepted" ||
                                                        b.status === "Final Work Plan Approved") &&
                                                    b.secondary_status !== "Withdrawn" &&
                                                    b.secondary_status !== "Withdrawal Requested"
                                                        ? a + 1
                                                        : a,
                                                0
                                            )}`}
                                        />
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            label={`Work Plans Total Amount:  $${workplan_filtered
                                                .reduce((a, b) => a + (b.recommended_amount ? b.recommended_amount : 0), 0)
                                                .toLocaleString()}`}
                                        />

                                        <Typography variant="h3" style={{ marginTop: 30 }}>
                                            Workload Statistics
                                        </Typography>
                                        <Chip className={classes.chip} color="primary" label={`Awaiting Project Manager Action: ${workplan_pmAction}`} />
                                        <Chip className={classes.chip} color="primary" label={`Awaiting LCCMR Action:  ${workplan_lccmrAction}`} />
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            label={`Total Awaiting Action:  ${workplan_pmAction + workplan_lccmrAction}`}
                                        />
                                        <Chip
                                            className={classes.chip}
                                            color="secondary"
                                            label={`Warnings:  ${workplan_filtered.reduce((a, b) => (b.warningMessage ? a + 1 : a), 0)}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <WorkPlanTable 
                                            classes={classes}
                                            columnDataWorkPlan={columnDataWorkPlan}
                                            workplan_order={workplan_order}
                                            workplan_orderBy={workplan_orderBy}
                                            handleRequestSort={this.handleRequestSort}
                                            workplan_filtered={workplan_filtered}
                                            workplan_page={workplan_page}
                                            workplan_rowsPerPage={workplan_rowsPerPage}
                                            handleChangePage={this.handleChangePage}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.peerExpand} onChange={navTogglePeer} CollapseProps={{ unmountOnExit: true }}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} />
                                    Peer Review
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography id="peerreview-years-checkbox-label">Select Year(s) :</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="peerreview-years-checkbox-label"
                                            id="peerreview-years-checkbox"
                                            multiple
                                            value={peerreview_proposal_year}
                                            onChange={this.handleChangeReviewYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="-- Empty --" value="-- Empty --">
                                                <Checkbox
                                                    color="primary"
                                                    checked={peerreview_proposal_year.includes("-- Empty --") && !peerreview_proposal_year.includes("All")}
                                                />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={peerreview_proposal_year.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {years_list.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={peerreview_proposal_year.includes("All") || peerreview_proposal_year.includes(year)}
                                                    />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h3">Summary Statistics</Typography>
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            label={`Peer Reviews In Progress:  ${peerreview_filtered.reduce(
                                                (a, b) => (b.peer_status === "Peer Review In Progress" ? a + 1 : a),
                                                0
                                            )}`}
                                        />
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            label={`Peer Reviews Completed:  ${peerreview_filtered.reduce(
                                                (a, b) => (b.peer_status === "Peer Review Complete" ? a + 1 : a),
                                                0
                                            )}`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <PeerReviewTable 
                                            classes={classes}
                                            columnDataPeer={columnDataPeer}
                                            peer_order={peer_order}
                                            peer_orderBy={peer_orderBy}
                                            handleRequestSort={this.handleRequestSort}
                                            peerreview_filtered={peerreview_filtered}
                                            peer_page={peer_page}
                                            peer_rowsPerPage={peer_rowsPerPage}
                                            handleChangePage={this.handleChangePage}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.activeProjectExpand} onChange={navToggleActiveProject} CollapseProps={{ unmountOnExit: true }}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} />
                                    Active Projects
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Search by Staff:</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="assigned"
                                                name="assigned"
                                                value={assignedProjectFilter}
                                                onChange={e => this.setState({ assignedProjectFilter: e.target.value })}>
                                                <FormControlLabel value="All" control={<Radio color="primary" />} label="All" />
                                                <FormControlLabel value="me" control={<Radio color="primary" />} label="Assigned to me" />
                                            </RadioGroup>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Search by Past Due:</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="Search by Past Due"
                                                name="past_due"
                                                value={pastdueFilter}
                                                onChange={e => this.setState({ pastdueFilter: e.target.value })}>
                                                <FormControlLabel value="All" control={<Radio color="primary" />} label="All" />
                                                <FormControlLabel value="past" control={<Radio color="primary" />} label="Past Due" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Typography id="active-statuses-checkbox-label">Select Status(es) :</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="active-statuses-checkbox-label"
                                            multiple
                                            value={activeproject_status_list}
                                            onChange={this.handleChangeActiveProjectStatus}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="Empty" value="-- Empty --">
                                                <Checkbox
                                                    color="primary"
                                                    checked={activeproject_status_list.includes("-- Empty --") && !activeproject_status_list.includes("All")}
                                                />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={activeproject_status_list.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {ActiveProjectStatuses.map(status => (
                                                <MenuItem key={status} value={status}>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={activeproject_status_list.includes("All") || activeproject_status_list.includes(status)}
                                                    />
                                                    <ListItemText primary={status} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        className={classes.textBox}
                                                        eventHandle={this.handleTextFieldActiveProject}
                                                        field="project_filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h3">Workload Statistics</Typography>
                                        <Chip className={classes.chip} color="primary" label={`Updates Submitted: ${statistic_ap_submitted}`} />
                                        <Chip className={classes.chip} color="primary" label={`Updates Due in Next 45 Days:  ${statistic_ap_due}`} />
                                        <Chip className={classes.chip} color="primary" label={`Updates Past Due:  ${statistic_ap_past_due}`} />
                                        <Chip className={classes.chip} color="primary" label={`Amendments Past Due:  ${statistic_amendment_past_due}`} />
                                        <Chip className={classes.chip} color="primary" label={`Awaiting Project Manager Action:  ${statistic_ap_pm_action}`} />
                                        <Chip className={classes.chip} color="primary" label={`Awaiting LCCMR Action:  ${statistic_ap_lccmr_action}`} />
                                        <Chip className={classes.chip} color="primary" label={`Total Awaiting Action:  ${statistic_ap_action}`} />
                                        <Chip className={classes.chip} color="secondary" label={`Warnings:  ${statistic_ap_warnings}`} />
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <ActiveProjectTable 
                                            classes={classes}
                                            columnDataActiveProject={columnDataActiveProject}
                                            project_order={project_order}
                                            project_orderBy={project_orderBy}
                                            handleRequestSort={this.handleRequestSort}
                                            active_projects_filtered={active_projects_filtered}
                                            project_page={project_page}
                                            project_rowsPerPage={project_rowsPerPage}
                                            handleChangePage={this.handleChangePage}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel expanded={navState.completedProjectExpand} onChange={navToggleCompletedProject} CollapseProps={{ unmountOnExit: true }}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <BookmarksIcon className={classes.expansionIcon} />
                                    Completed Projects
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Search by Staff:</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="assigned"
                                                name="assigned"
                                                value={assignedCProjectFilter}
                                                onChange={e => this.setState({ assignedCProjectFilter: e.target.value })}>
                                                <FormControlLabel value="All" control={<Radio color="primary" />} label="All" />
                                                <FormControlLabel value="me" control={<Radio color="primary" />} label="Assigned to me" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Typography id="cp-years-checkbox-label">Select M.L. Year(s):</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="cp-years-checkbox-label"
                                            id="cp-years-checkbox"
                                            multiple
                                            value={cp_year}
                                            onChange={this.handleChangeCPYear}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="-- Empty --" value="-- Empty --">
                                                <Checkbox color="primary" checked={cp_year.includes("-- Empty --") && !cp_year.includes("All")} />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={cp_year.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {years_list.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={cp_year.includes("All") || cp_year.includes(year)} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="cp2-years-checkbox-label">Select Calendar Year Project Complete:</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="cp2-years-checkbox-label"
                                            id="cp2-years-checkbox"
                                            multiple
                                            value={cp_year2}
                                            onChange={this.handleChangeCPYear2}
                                            input={<Input />}
                                            renderValue={selected => selected.join(", ")}>
                                            <MenuItem key="-- Empty --" value="-- Empty --">
                                                <Checkbox color="primary" checked={cp_year2.includes("-- Empty --") && !cp_year2.includes("All")} />
                                                <ListItemText primary="-- Empty --" />
                                            </MenuItem>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={cp_year2.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {years_list.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={cp_year2.includes("All") || cp_year2.includes(year)} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        className={classes.textBox}
                                                        eventHandle={this.handleTextFieldCompletedProject}
                                                        field="project_filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h3">Summary Statistics</Typography>
                                        <Chip className={classes.chip} color="primary" label={`Completed Projects: ${statistic_cp_total}`} />
                                        <Chip className={classes.chip} color="primary" label={`Amount Appropriated:  ${statistic_cp_appropriated}`} />
                                        <Chip className={classes.chip} color="primary" label={`Amount Spent:  ${statistic_cp_spent}`} />
                                        <Chip className={classes.chip} color="primary" label={`Amount Returned:  ${statistic_cp_returned}`} />
                                        <Chip className={classes.chip} color="primary" label={`Warnings:  ${statistic_cp_warnings}`} />
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <CompletedProjectTable 
                                            classes={classes}
                                            columnDataFinalProject={columnDataFinalProject}
                                            projectc_order={projectc_order}
                                            projectc_orderBy={projectc_orderBy}
                                            handleRequestSort={this.handleRequestSort}
                                            completed_projects_filtered={completed_projects_filtered}
                                            projectc_page={projectc_page}
                                            projectc_rowsPerPage={projectc_rowsPerPage}
                                            handleChangePage={this.handleChangePage}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

class ProposalTable extends Component {
    render() {
        const { classes, columnDataProposal, order, orderBy, handleRequestSort, filtered, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    name="proposals"
                    columnData={columnDataProposal}
                    stickyHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                        return (
                            <TableRow key={n.id}>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title={`View Proposal ${n.proposal_unique_id}`}>
                                        <Button
                                            color="primary"
                                            className={classes.deleteWidth}
                                            aria-label={`View Proposal ${n.proposal_unique_id}`}
                                            onClick={() => window.open("#/proposal/" + n.id)}>
                                            <Visibility color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                                <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                <CustomTableCell>{n.name}</CustomTableCell>
                                <CustomTableCell>{n.final_category}</CustomTableCell>
                                <CustomTableCell>{n.organization_name}</CustomTableCell>
                                <CustomTableCell>
                                    {n.pm_first_name} {n.pm_last_name}
                                </CustomTableCell>
                                <CustomTableCell>${n.funding_total ? n.funding_total.toLocaleString() : 0}</CustomTableCell>
                                <CustomTableCell>{n.status}</CustomTableCell>
                                <CustomTableCell>{n.status_date_label}</CustomTableCell>
                                <CustomTableCell>{n.table_due_date}</CustomTableCell>
                                <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                                <CustomTableCell>{n.eval_status}</CustomTableCell>
                                <CustomTableCell>{n.secondary_status}</CustomTableCell>
                                <CustomTableCell>
                                    {n.warningMessage && (
                                        <Tooltip placement="left" title={n.warningMessage}>
                                            <IconButton style={{ color: "#a80a05" }}>
                                                <AnnouncementIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {filtered.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataProposal.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Proposals Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataProposal.length}
                            count={filtered.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={(e, page) => handleChangePage(e, page, "page")}
                            onChangeRowsPerPage={e => handleChangeRowsPerPage(e, "page", "rowsPerPage")}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

class WorkPlanTable extends Component {
    render() {
        const { 
            classes, columnDataWorkPlan, workplan_order, workplan_orderBy, handleRequestSort, workplan_filtered, workplan_page,
            workplan_rowsPerPage, handleChangePage, handleChangeRowsPerPage 
        } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    name="workPlans"
                    columnData={columnDataWorkPlan}
                    stickyHeader
                    order={workplan_order}
                    orderBy={workplan_orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {workplan_filtered
                        .slice(workplan_page * workplan_rowsPerPage, workplan_page * workplan_rowsPerPage + workplan_rowsPerPage)
                        .map(n => {
                            return (
                                <TableRow key={n.id}>
                                    <CustomTableCell className={classes.nowrap}>
                                        <Tooltip title={`View Work Plan ${n.proposal_unique_id}`}>
                                            <Button
                                                color="primary"
                                                className={classes.deleteWidth}
                                                aria-label={`View Work Plan ${n.proposal_unique_id}`}
                                                onClick={() =>
                                                    window.open(`#/${n.status.includes("Draft") ? "draft" : "final"}/workplan/${n.id}`)
                                                }>
                                                <Visibility color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                    <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                    <CustomTableCell>{n.name}</CustomTableCell>
                                    <CustomTableCell>{n.final_category}</CustomTableCell>
                                    <CustomTableCell>{n.organization_name}</CustomTableCell>
                                    <CustomTableCell>
                                        {n.pm_first_name} {n.pm_last_name}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        {n.recommended_amount !== null ? "$" + n.recommended_amount.toLocaleString() : "-"}
                                    </CustomTableCell>
                                    <CustomTableCell>{n.status}</CustomTableCell>
                                    <CustomTableCell>{n.status_date_label}</CustomTableCell>
                                    <CustomTableCell>{n.table_due_date}</CustomTableCell>
                                    <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                                    <CustomTableCell>{n.secondary_status}</CustomTableCell>
                                    <CustomTableCell>{n.peer_status}</CustomTableCell>
                                    <CustomTableCell>
                                        {n.warningMessage && (
                                            <Tooltip placement="left" title={n.warningMessage}>
                                                <IconButton style={{ color: "#a80a05" }}>
                                                    <AnnouncementIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </CustomTableCell>
                                </TableRow>
                            );
                        })}
                    {workplan_filtered.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataWorkPlan.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Work Plans Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataWorkPlan.length}
                            count={workplan_filtered.length}
                            rowsPerPage={workplan_rowsPerPage}
                            page={workplan_page}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={(e, page) => handleChangePage(e, page, "workplan_page")}
                            onChangeRowsPerPage={e => handleChangeRowsPerPage(e, "workplan_page", "workplan_rowsPerPage")}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

class PeerReviewTable extends Component {
    render() {
        const { 
            classes, columnDataPeer, peer_order, peer_orderBy, handleRequestSort, peerreview_filtered, peer_page,
            peer_rowsPerPage, handleChangePage, handleChangeRowsPerPage 
        } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    name="peerReviews"
                    columnData={columnDataPeer}
                    stickyHeader
                    order={peer_order}
                    orderBy={peer_orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {peerreview_filtered.slice(peer_page * peer_rowsPerPage, peer_page * peer_rowsPerPage + peer_rowsPerPage).map(n => {
                        return (
                            <TableRow key={n.id}>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title={`View Work Plan ${n.proposal_unique_id}`}>
                                        <Button
                                            color="primary"
                                            className={classes.deleteWidth}
                                            aria-label={`View Work Plan ${n.proposal_unique_id}`}
                                            onClick={() =>
                                                window.open(`#/${n.status.includes("Draft") ? "draft" : "final"}/workplan/${n.id}`)
                                            }>
                                            <Visibility color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                                <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                <CustomTableCell>{n.name}</CustomTableCell>
                                <CustomTableCell>{n.final_category}</CustomTableCell>
                                <CustomTableCell>{n.organization_name}</CustomTableCell>
                                <CustomTableCell>
                                    {n.pm_first_name} {n.pm_last_name}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {n.recommended_amount !== null ? "$" + n.recommended_amount.toLocaleString() : "-"}
                                </CustomTableCell>
                                <CustomTableCell>{n.status_date_label}</CustomTableCell>
                                <CustomTableCell>{n.addendum_date_label}</CustomTableCell>
                                <CustomTableCell>{n.peer_status}</CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {peerreview_filtered.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataPeer.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Peer Reviews Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataPeer.length}
                            count={peerreview_filtered.length}
                            rowsPerPage={peer_rowsPerPage}
                            page={peer_page}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={(e, page) => handleChangePage(e, page, "peer_page")}
                            onChangeRowsPerPage={e => handleChangeRowsPerPage(e, "peer_page", "peer_rowsPerPage")}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

class ActiveProjectTable extends Component {
    wpDisplayFunding = n => {
        return n.appropriation_amount !== null && typeof n.appropriation_amount !== "undefined" ? "$" + n.appropriation_amount.toLocaleString() : "-";
    };
    
    displayCombinedStatus = n => {
        var return_string = ""
        if (n.status === "Final Work Plan Approved") {
            if (n.reporting_status !== "-") { // Has Status Update
                return_string += n.reporting_status
            }
            if (n.amendment_status) { // Has Active Amendment
                if (return_string !== "")
                    return_string += " & " + n.amendment_status
                else
                    return_string += n.amendment_status
            }
            if (return_string === "") // No status update or amendment - return status (Final Work Plan Approved)
                return_string += n.status
        } else {
            return_string += n.status
            if (n.reporting_status !== "-" && n.reporting_status !== "Update Approved" && n.reporting_status !== "Final Update Approved") { // Has Status Update
                if (return_string !== "")
                    return_string += " & " + n.reporting_status
                else
                    return_string += n.reporting_status
            }
            if (n.amendment_status && n.amendment_status !== "Amendment Approved") { // Has Active Amendment
                if (return_string !== "")
                    return_string += " & " + n.amendment_status
                else
                    return_string += n.amendment_status
            }
        }
        return return_string;
    };

    render() {
        const { 
            classes, columnDataActiveProject, project_order, project_orderBy, handleRequestSort, active_projects_filtered, project_page,
            project_rowsPerPage, handleChangePage, handleChangeRowsPerPage 
        } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    name="activeProjects"
                    columnData={columnDataActiveProject}
                    stickyHeader
                    order={project_order}
                    orderBy={project_orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {active_projects_filtered
                        .slice(project_page * project_rowsPerPage, project_page * project_rowsPerPage + project_rowsPerPage)
                        .map(n => {
                            return (
                                <TableRow key={n.id}>
                                    <CustomTableCell className={classes.nowrap}>
                                        <Tooltip title={`View Work Plan ${n.proposal_unique_id}`}>
                                            <Button
                                                color="primary"
                                                className={classes.deleteWidth}
                                                aria-label={`View Work Plan ${n.proposal_unique_id}`}
                                                onClick={() => window.open(`#/final/workplan/${n.id}`)}>
                                                <Visibility color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                    <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                    <CustomTableCell>{n.citation_ml_year + " " + n.subdivision}</CustomTableCell>
                                    <CustomTableCell>{n.name}</CustomTableCell>
                                    <CustomTableCell>{n.category}</CustomTableCell>
                                    <CustomTableCell>{n.organization_name}</CustomTableCell>
                                    <CustomTableCell>
                                        {n.pm_first_name} {n.pm_last_name}
                                    </CustomTableCell>
                                    <CustomTableCell>{this.wpDisplayFunding(n)}</CustomTableCell>
                                    <CustomTableCell>{this.displayCombinedStatus(n)}</CustomTableCell>
                                    <CustomTableCell>
                                        {n.last_action !== "-" ? n.last_action : n.status_date_label.split(" ")[0]}
                                    </CustomTableCell>
                                    <CustomTableCell>{n.next_status_update_date}</CustomTableCell>
                                    <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                                    <CustomTableCell>{n.includes_amendment}</CustomTableCell>
                                    <CustomTableCell>
                                        {n.statusWarningMessage && (
                                            <Tooltip placement="left" title={n.statusWarningMessage}>
                                                <IconButton style={{ color: "#a80a05" }}>
                                                    <AnnouncementIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </CustomTableCell>
                                </TableRow>
                            );
                        })}
                    {active_projects_filtered.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataActiveProject.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Active Projects Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataActiveProject.length}
                            count={active_projects_filtered.length}
                            rowsPerPage={project_rowsPerPage}
                            page={project_page}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={(e, page) => handleChangePage(e, page, "project_page")}
                            onChangeRowsPerPage={e => handleChangeRowsPerPage(e, "project_page", "project_rowsPerPage")}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

class CompletedProjectTable extends Component {
    wpDisplayFunding = n => {
        return n.appropriation_amount !== null && typeof n.appropriation_amount !== "undefined" ? "$" + n.appropriation_amount.toLocaleString() : "-";
    };    
    wpDisplaySpent = n => {
        return n.amount_spent !== null && typeof n.amount_spent !== "undefined" ? "$" + n.amount_spent.toLocaleString() : "-";
    };  
    wpDisplayRemaining = n => {
        return "$" + (n.appropriation_amount - n.amount_spent).toLocaleString()
    };   
    
    displayCombinedStatus = n => {
        var return_string = ""
        if (n.status === "Final Work Plan Approved") {
            if (n.reporting_status !== "-") { // Has Status Update
                return_string += n.reporting_status
            }
            if (n.amendment_status) { // Has Active Amendment
                if (return_string !== "")
                    return_string += " & " + n.amendment_status
                else
                    return_string += n.amendment_status
            }
            if (return_string === "") // No status update or amendment - return status (Final Work Plan Approved)
                return_string += n.status
        } else {
            return_string += n.status
            if (n.reporting_status !== "-" && n.reporting_status !== "Update Approved" && n.reporting_status !== "Final Update Approved") { // Has Status Update
                if (return_string !== "")
                    return_string += " & " + n.reporting_status
                else
                    return_string += n.reporting_status
            }
            if (n.amendment_status && n.amendment_status !== "Amendment Approved") { // Has Active Amendment
                if (return_string !== "")
                    return_string += " & " + n.amendment_status
                else
                    return_string += n.amendment_status
            }
        }
        return return_string;
    };

    render() {
        const { 
            classes, columnDataFinalProject, projectc_order, projectc_orderBy, handleRequestSort, completed_projects_filtered, projectc_page,
            projectc_rowsPerPage, handleChangePage, handleChangeRowsPerPage 
        } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    name="completedProjects"
                    columnData={columnDataFinalProject}
                    stickyHeader
                    order={projectc_order}
                    orderBy={projectc_orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {completed_projects_filtered
                        .slice(projectc_page * projectc_rowsPerPage, projectc_page * projectc_rowsPerPage + projectc_rowsPerPage)
                        .map(n => {
                            return (
                                <TableRow key={n.id}>
                                    <CustomTableCell className={classes.nowrap}>
                                        <Tooltip title={`View Completed Project ${n.proposal_unique_id}`}>
                                            <Button
                                                color="primary"
                                                className={classes.deleteWidth}
                                                aria-label={`View Completed Project ${n.proposal_unique_id}`}
                                                onClick={() => window.open(`#/final/workplan/${n.id}`)}>
                                                <Visibility color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                    <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                    <CustomTableCell>{n.citation_ml_year + " " + n.subdivision}</CustomTableCell>
                                    <CustomTableCell>{n.name}</CustomTableCell>
                                    <CustomTableCell>{n.category}</CustomTableCell>
                                    <CustomTableCell>{n.organization_name}</CustomTableCell>
                                    <CustomTableCell>
                                        {n.pm_first_name} {n.pm_last_name}
                                    </CustomTableCell>
                                    <CustomTableCell>{this.wpDisplayFunding(n)}</CustomTableCell>
                                    <CustomTableCell>{this.displayCombinedStatus(n)}</CustomTableCell>
                                    <CustomTableCell>{formatDate(n.timeline ? n.timeline : null, true)}</CustomTableCell>
                                    <CustomTableCell>{this.wpDisplaySpent(n)}</CustomTableCell>
                                    <CustomTableCell>{this.wpDisplayRemaining(n)}</CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell>
                                        {n.last_action !== "-" ? n.last_action : n.status_date_label.split(" ")[0]}
                                    </CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                </TableRow>
                            );
                        })}
                    {completed_projects_filtered.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataActiveProject.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Completed Projects Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataActiveProject.length}
                            count={completed_projects_filtered.length}
                            rowsPerPage={projectc_rowsPerPage}
                            page={projectc_page}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={(e, page) => handleChangePage(e, page, "projectc_page")}
                            onChangeRowsPerPage={e => handleChangeRowsPerPage(e, "projectc_page", "projectc_rowsPerPage")}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

StaffWorkLoadManagement = connect(
    (state, ownProps) => ({
        sync: state.sync,
        authState: state.auth,
        years: getYears(state, ownProps),
        proposals: getProposals(state, ownProps),
        workPlans: getWorkPlans(state, ownProps),
        peerReviews: getPeerReview(state, ownProps),
        activeProjects: getActiveProjects(state, ownProps),
        completedProjects: getCompletedProjects(state, ownProps),
        reviews: getReviews(state, ownProps),
        navState: state.nav
    }),
    {
        ...navActions,
        reloadListOfModels: reloadListOfModels
    }
)(StaffWorkLoadManagement);

export default withStyles(styles)(withRouter(StaffWorkLoadManagement));
