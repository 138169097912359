import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Visibility from "@material-ui/icons/Visibility";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import Messages from "./Messages";
import AppContainer from "../common/AppContainer";
import RadioGroup from "../common/RadioGroup";
import PageHeader from "../common/PageHeader";
import TextField from "../common/TextField";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import TablePaginationActionsWrapped from "../common/Paginator";
import { createSelector, reloadListOfModels } from "../common/orm";
import { ProposalReview, SystemSetting } from "./models";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        backgroundColor: "#fff"
    },
    floatLeft: {
        float: "left"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginTop: 24
        }
    },
    expansionSummaryRoot2: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    flex: {
        flex: 1
    },
    progress: {
        width: "100%",
        textAlign: "center"
    }
});

const getReviews = ProposalReview.selectAll();
const getSystemSettings = SystemSetting.selectAll();
const getProposals = createSelector(
    (state, ownProps) => (state.auth && state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema) => {
        const years = schema.SystemSetting.all().toRefArray();
        return schema.ProposalView.filter(x => x.status !== "Final Work Plan Approved" && x.status !== "Draft Submitted" && x.status !== "Draft In Progress" && x.status !== "Final Abstract Due" && x.status !== "Final Abstract Revisions Needed" && x.status !== "Final Abstract Submitted" && x.status !== "Project Completed" && ((x.through === "Pass Through" && x.agency === "MN DNR") || (!x.through && !x.agency)))
            .filter(x => {
                if (!x.through && !x.agency) {
                    var org = schema.Organization.filter(z => z.id === x.organization_id).first();

                    if (!org || (org.recipient === "State Government" || org.recipient === "Public College/University"))
                        return false;
                }
                return true;
            })
            .filter(x => x.secondary_status !== "Not Selected for Further Consideration" && 
            x.secondary_status !== "Not Recommended for Funding" && 
            x.secondary_status !== "Withdrawn" && 
            x.secondary_status !== "Not Passed By Legislature" &&
            x.secondary_status !== "Governor Veto")
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(x => {
                var name,
                    category,
                    full_name,
                    funding_total,
                    pm_first_name,
                    pm_last_name,
                    status_date_label,
                    wp_due_date,
                    org,
                    next_status_update_date,
                    reporting_status,
                    last_action;

                if (x.primary_phase === "Draft Work Plan" || x.primary_phase === "Final Work Plan") {
                    var plan;
                    if (x.primary_phase === "Draft Work Plan") {
                        plan = schema.DraftWorkPlanView.filter(view => view.proposal_record_id === x.id).first();
                    } else {
                        plan = schema.FinalWorkPlanView.filter(view => view.proposal_record_id === x.id).first();
                    }

                    if (plan) {
                        name = plan.name;
                        category = plan.funding_category;
                        funding_total = x.appropriation_amount ? x.appropriation_amount : x.recommended_amount;
                        pm_first_name = plan ? plan.pm_first_name : "";
                        pm_last_name = plan ? plan.pm_last_name : "";
                        status_date_label = plan.status_date_label;
                        if (pm_first_name) full_name = pm_first_name;
                        if (pm_last_name) full_name += " " + pm_last_name;
                        org = schema.Organization.filter(x => x.id === plan.organization_id).first();

                        wp_due_date = plan.wp_due_date;
                        next_status_update_date = plan.next_status_update_date;
                        reporting_status = plan.reporting_status;
                        last_action = plan.last_action;
                    }
                } else {
                    name = x.name;
                    category = !x.final_funding_category ? (x.funding_category ? x.funding_category : "") : x.final_funding_category;
                    funding_total = x.funding_total ? x.funding_total : 0;
                    status_date_label = x.status_date_label;
                    org = schema.Organization.filter(z => z.id === x.organization_id).first();

                    pm_first_name = x ? x.pm_first_name : "";
                    pm_last_name = x ? x.pm_last_name : "";
                    if (pm_first_name) full_name = pm_first_name;
                    if (pm_last_name) full_name += " " + pm_last_name;
                }

                return {
                    id: x.id,
                    full_name,
                    staff_lead_id: x.staff_lead_id,
                    ml_year: x.ml_year,
                    proposal_unique_id: x.proposal_unique_id,
                    primary_phase: x.primary_phase,
                    recommended_amount: x.recommended_amount,
                    name,
                    pm_first_name,
                    pm_last_name,
                    category,
                    funding_total,
                    status: x.status,
                    status_date_label,
                    staff_lead_label: x.staff_lead_label,
                    secondary_status: x.secondary_status,
                    table_due_date: getDueDate(years, x, wp_due_date),
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : "",
                    subdivision: x.subdivision,
                    appropriation_amount: x.appropriation_amount,
                    next_status_update_date,
                    reporting_status,
                    last_action
                };
            });
    }
);

const getPlans = createSelector(
    (state, ownProps) => (state.auth && state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema) => {
        const years = schema.SystemSetting.all().toRefArray();
        return schema.ProposalView.filter(x => (x.status === "Final Work Plan Approved" || x.status === "Final Abstract Due" || x.status === "Final Abstract Revisions Needed" || x.status === "Final Abstract Submitted") && ((x.through === "Pass Through" && x.agency === "MN DNR") || (!x.through && !x.agency)))
        .filter(x => {
            if (!x.through && !x.agency) {
                var plan = schema.FinalWorkPlanView.filter(view => view.proposal_record_id === x.id).first();

                if (plan) {
                    var org = schema.Organization.filter(z => z.id === plan.organization_id).first();

                    if (!org || (org.recipient === "State Government" || org.recipient === "Public College/University"))
                        return false;
                }
            }
            return true;
        })
        .filter(x => x.secondary_status !== "Not Selected for Further Consideration" && 
        x.secondary_status !== "Not Recommended for Funding" && 
        x.secondary_status !== "Withdrawn" && 
        x.secondary_status !== "Not Passed By Legislature" &&
        x.secondary_status !== "Governor Veto")
        .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(x => {
                var name,
                    category,
                    full_name,
                    funding_total,
                    pm_first_name,
                    pm_last_name,
                    status_date_label,
                    wp_due_date,
                    org,
                    next_status_update_date,
                    reporting_status,
                    amendment_status,
                    includes_amendment,
                    last_action;

                var plan = schema.FinalWorkPlanView.filter(view => view.proposal_record_id === x.id).first();

                if (plan) {
                    name = plan.name;
                    category = plan.funding_category;
                    funding_total = x.appropriation_amount ? x.appropriation_amount : x.recommended_amount;
                    pm_first_name = plan ? plan.pm_first_name : "";
                    pm_last_name = plan ? plan.pm_last_name : "";
                    status_date_label = plan.status_date_label;
                    if (pm_first_name) full_name = pm_first_name;
                    if (pm_last_name) full_name += " " + pm_last_name;
                    org = schema.Organization.filter(z => z.id === plan.organization_id).first();

                    wp_due_date = plan.wp_due_date;
                    next_status_update_date = plan.next_status_update_date;
                    reporting_status = plan.reporting_status;
                    last_action = plan.last_action;
                    amendment_status = plan.status;
                    includes_amendment = plan.includes_amendment
                }
               

                return {
                    id: x.id,
                    full_name,
                    ml_year: x.ml_year,
                    citation_ml_year: x.citation_ml_year,
                    proposal_unique_id: x.proposal_unique_id,
                    primary_phase: x.primary_phase,
                    recommended_amount: x.recommended_amount,
                    name,
                    staff_lead_label: x.staff_lead_label,
                    pm_first_name,
                    pm_last_name,
                    category,
                    funding_total,
                    status: x.status,
                    amendment_status,
                    status_date_label,
                    includes_amendment,
                    secondary_status: x.secondary_status,
                    table_due_date: getDueDate(years, x, wp_due_date),
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : "",
                    subdivision: x.subdivision,
                    appropriation_amount: x.appropriation_amount,
                    next_status_update_date,
                    reporting_status,
                    last_action
                };
            });
    }
);

const getCompletedProjects = createSelector(
    (state, ownProps) => (state.auth && state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema) => {
        const years = schema.SystemSetting.all().toRefArray();


        return schema.ProposalView.all()
            .filter(x => (x.status === "Project Completed") && ((x.through === "Pass Through" && x.agency === "MN DNR") || (!x.through && !x.agency)))
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(x => {
                var name,
                    category,
                    full_name,
                    funding_total,
                    pm_first_name,
                    pm_last_name,
                    status_date_label,
                    wp_due_date,
                    org,
                    next_status_update_date,
                    reporting_status,
                    amendment_status,
                    includes_amendment,
                    last_action;

                var plan = schema.FinalWorkPlanView.filter(view => view.proposal_record_id === x.id).first();

                if (plan) {
                    name = plan.name;
                    category = plan.funding_category;
                    funding_total = x.appropriation_amount ? x.appropriation_amount : x.recommended_amount;
                    pm_first_name = plan ? plan.pm_first_name : "";
                    pm_last_name = plan ? plan.pm_last_name : "";
                    status_date_label = plan.status_date_label;
                    if (pm_first_name) full_name = pm_first_name;
                    if (pm_last_name) full_name += " " + pm_last_name;
                    org = schema.Organization.filter(z => z.id === plan.organization_id).first();

                    wp_due_date = plan.wp_due_date;
                    next_status_update_date = plan.next_status_update_date;
                    reporting_status = plan.reporting_status;
                    last_action = plan.last_action;
                    amendment_status = plan.status;
                    includes_amendment = plan.includes_amendment
                }
               

                return {
                    id: x.id,
                    full_name,
                    ml_year: x.ml_year,
                    citation_ml_year: x.citation_ml_year,
                    proposal_unique_id: x.proposal_unique_id,
                    primary_phase: x.primary_phase,
                    recommended_amount: x.recommended_amount,
                    name,
                    staff_lead_label: x.staff_lead_label,
                    pm_first_name,
                    pm_last_name,
                    category,
                    funding_total,
                    status: x.status,
                    amendment_status,
                    status_date_label,
                    includes_amendment,
                    secondary_status: x.secondary_status,
                    table_due_date: getDueDate(years, x, wp_due_date),
                    organization_name: org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : "",
                    subdivision: x.subdivision,
                    appropriation_amount: x.appropriation_amount,
                    next_status_update_date,
                    reporting_status,
                    last_action
                };
            });
    }
);

function formatDate(date, add) {
    if (!date) {
        return "";
    }
    var temp;
    if (add) {
        temp = new Date(date + "T12:00:00");
    } else {
        temp = new Date(date);
    }
    const dateFns = new DateFnsUtils();
    return dateFns.format(temp, "MM/dd/yyyy");
}

function getDueDate(years, object, wp_due_date = null) {
    const year = years.find(x => x.year === object.ml_year);
    const date = new Date();
    if (year) {
        var final_date = new Date(year.final_date);
        var draft_date = new Date(year.draft_date);
        if (object.primary_phase === "Draft Proposal" || object.primary_phase === "Proposal") {
            if (object.status === "Draft In Progress") {
                if (year.funding_open && date < draft_date) {
                    return formatDate(year.draft_date, false);
                } else {
                    return formatDate(final_date);
                }
            } else if (object.status === "Revisions Needed") {
                return formatDate(object.due_date, true);
            } else {
                return formatDate(final_date, false);
            }
        } else if (object.primary_phase === "Draft Work Plan" || object.primary_phase === "Final Work Plan") {
            if (object.status === "Draft Work Plan Due") {
                if (wp_due_date) {
                    return formatDate(wp_due_date, true);
                } else {
                    return formatDate(year.draftplan_date, true);
                }
            } else if (object.status === "Final Work Plan Due") {
                if (wp_due_date) {
                    return formatDate(wp_due_date, true);
                } else {
                    return formatDate(year.finalplan_date, true);
                }
            } else if (object.status === "Draft Work Plan Revisions Needed" || object.status === "Final Work Plan Revisions Needed") {
                return formatDate(object.due_date, true);
            }
        }
    } else {
        return "---";
    }
}

const columnDataProposal = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "category", numeric: false, label: "Category", allowSort: true, minWidth:"250px" },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true },
    { id: "status_date_label", numeric: false, label: "Date Of Last Action", date: true, allowSort: true },
    { id: "table_due_date", numeric: false, label: "Next Due Date", allowSort: true, date: true },
    { id: "staff_lead_label", numeric: false, label: "LCCMR Lead", allowSort: true },
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true }
];

const columnDataWorkPlan = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Project ID", allowSort: true },
    { id: "ml_year", numeric: false, label: "ML Year / Subd", allowSort: true, combined: true, minWidth:"90px" },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "category", numeric: false, label: "Category", allowSort: true, minWidth:"250px"  },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "reporting_status", numeric: false, label: "Reporting Status", allowSort: true },
    { id: "includes_amendment", numeric: false, label: "Includes Amendment Request", allowSort: true },
    { id: "last_action", numeric: false, label: "Date Of Last Action", date: true, allowSort: true },
    { id: "next_status_update_date", numeric: false, label: "Next Due Date", allowSort: true, date: true },
    { id: "staff_lead_label", numeric: false, label: "LCCMR Lead", allowSort: true },
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Process Status", allowSort: true },
    { id: "ilr", numeric: false, label: "Initial Land Acquisition Reporting Status", allowSort: true }
];

const columnDataCompletedProject = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Project ID", allowSort: true },
    { id: "ml_year", numeric: false, label: "ML Year / Subd", allowSort: true, combined: true, minWidth:"90px" },
    { id: "name", numeric: false, label: "Project Title", allowSort: true, minWidth:"300px" },
    { id: "category", numeric: false, label: "Category", allowSort: true, minWidth:"250px"  },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true, minWidth:"200px" },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "staff_lead_label", numeric: false, label: "LCCMR Lead", allowSort: true },
];

class Dashboard extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        proposalFilter: null,
        proposalFilterYear: null,
        order: "desc",
        orderBy: "proposal_unique_id",
        wpPage: 0,
        wpRowsPerPage: 10,
        wpFilter: null,
        wpOrder: "desc",
        wpOrderBy: "proposal_unique_id",
        cPage: 0,
        cRowsPerPage: 10,
        cFilter: null,
        cOrder: "desc",
        cOrderBy: "proposal_unique_id"
    };

    componentDidMount() {
        const { authState } = this.props;
        document.title = "Dashboard - LCCMR Proposal and Grant Management System";

        if (authState && authState.user) {
            //this.props.reloadListOfModels(["SystemSetting"]);
            this.props.reloadListOfModels(["ProposalView"]);
            this.props.reloadListOfModels(["DraftWorkPlanView"]);
            this.props.reloadListOfModels(["FinalWorkPlanView"]);
        }
    }

    filterByValue = (array, string) => {
        return array.filter(o =>
            [
                "proposal_unique_id",
                "name",
                "category",
                "organization_name",
                "full_name",
                "funding_total",
                "status",
                "status_date_label",
                "table_due_date",
                "staff_lead_label",
                "secondary_status"
            ].some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    wpHandleChangePage = (event, wpPage) => {
        this.setState({ wpPage });
    };

    cHandleChangePage = (event, cPage) => {
        this.setState({ cPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    wpHandleChangeRowsPerPage = event => {
        this.setState({ wpRowsPerPage: event.target.value, wpPage: 0 });
    };

    cHandleChangeRowsPerPage = event => {
        this.setState({ cRowsPerPage: event.target.value, cPage: 0 });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        var sort = this.props.proposals;

        sort.sort(function(a, b) {
            var date = columnDataProposal.find(cD => cD.id === orderBy).date;
            var numeric = columnDataProposal.find(cD => cD.id === orderBy).numeric;
            if (date) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (numeric) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc") return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    wpHandleRequestSort = (event, property) => {
        const wpOrderBy = property;
        let wpOrder = "desc";

        if (this.state.wpOrderBy === property && this.state.wpOrder === "desc") {
            wpOrder = "asc";
        }

        var sort = this.props.plans;

        sort.sort(function(a, b) {
            var date = columnDataWorkPlan.find(cD => cD.id === wpOrderBy).date;
            var numeric = columnDataWorkPlan.find(cD => cD.id === wpOrderBy).numeric;
            var combined = columnDataWorkPlan.find(cD => cD.id === wpOrderBy).combined;
            if (date) {
                if (a[wpOrderBy] === b[wpOrderBy]) return 0;
                else if (a[wpOrderBy] === null || typeof a[wpOrderBy] === "undefined" || a[wpOrderBy] === "-") return 1;
                else if (b[wpOrderBy] === null || typeof b[wpOrderBy] === "undefined" || b[wpOrderBy] === "-") return -1;
                if (wpOrder === "desc") return new Date(b[wpOrderBy]) < new Date(a[wpOrderBy]) ? -1 : 1;
                else return new Date(a[wpOrderBy]) < new Date(b[wpOrderBy]) ? -1 : 1;
            } else if (numeric) {
                if (a[wpOrderBy] === b[wpOrderBy]) return 0;
                else if (a[wpOrderBy] === null || typeof a[wpOrderBy] === "undefined") return 1;
                else if (b[wpOrderBy] === null || typeof b[wpOrderBy] === "undefined") return -1;
                if (wpOrder === "desc") return parseFloat(b[wpOrderBy]) < parseFloat(a[wpOrderBy]) ? -1 : 1;
                else return parseFloat(a[wpOrderBy]) < parseFloat(b[wpOrderBy]) ? -1 : 1;
            } else if (combined) {
                if (wpOrder === "desc")
                    return (b[wpOrderBy] + " " + b["subdivision"] || "").toUpperCase() < (a[wpOrderBy] + " " + a["subdivision"] || "").toUpperCase() ? -1 : 1;
                else return (a[wpOrderBy] + " " + a["subdivision"] || "").toUpperCase() < (b[wpOrderBy] + " " + b["subdivision"] || "").toUpperCase() ? -1 : 1;
            } else {
                if (wpOrder === "desc") return (b[wpOrderBy] || "").toUpperCase() < (a[wpOrderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[wpOrderBy] || "").toUpperCase() < (b[wpOrderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ wpOrder, wpOrderBy });
    };

    cHandleRequestSort = (event, property) => {
        const cOrderBy = property;
        let cOrder = "desc";

        if (this.state.cOrderBy === property && this.state.cOrder === "desc") {
            cOrder = "asc";
        }

        var sort = this.props.plans;

        sort.sort(function(a, b) {
            var date = columnDataWorkPlan.find(cD => cD.id === cOrderBy).date;
            var numeric = columnDataWorkPlan.find(cD => cD.id === cOrderBy).numeric;
            var combined = columnDataWorkPlan.find(cD => cD.id === cOrderBy).combined;
            if (date) {
                if (a[cOrderBy] === b[cOrderBy]) return 0;
                else if (a[cOrderBy] === null || typeof a[cOrderBy] === "undefined" || a[cOrderBy] === "-") return 1;
                else if (b[cOrderBy] === null || typeof b[cOrderBy] === "undefined" || b[cOrderBy] === "-") return -1;
                if (cOrder === "desc") return new Date(b[cOrderBy]) < new Date(a[cOrderBy]) ? -1 : 1;
                else return new Date(a[cOrderBy]) < new Date(b[cOrderBy]) ? -1 : 1;
            } else if (numeric) {
                if (a[cOrderBy] === b[cOrderBy]) return 0;
                else if (a[cOrderBy] === null || typeof a[cOrderBy] === "undefined") return 1;
                else if (b[cOrderBy] === null || typeof b[cOrderBy] === "undefined") return -1;
                if (cOrder === "desc") return parseFloat(b[cOrderBy]) < parseFloat(a[cOrderBy]) ? -1 : 1;
                else return parseFloat(a[cOrderBy]) < parseFloat(b[cOrderBy]) ? -1 : 1;
            } else if (combined) {
                if (cOrder === "desc")
                    return (b[cOrderBy] + " " + b["subdivision"] || "").toUpperCase() < (a[cOrderBy] + " " + a["subdivision"] || "").toUpperCase() ? -1 : 1;
                else return (a[cOrderBy] + " " + a["subdivision"] || "").toUpperCase() < (b[cOrderBy] + " " + b["subdivision"] || "").toUpperCase() ? -1 : 1;
            } else {
                if (cOrder === "desc") return (b[cOrderBy] || "").toUpperCase() < (a[cOrderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[cOrderBy] || "").toUpperCase() < (b[cOrderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ cOrder, cOrderBy });
    };

    getViewButton = (n, classes) => {
        var label = " Proposal "
        var link = "proposal";
        if (n.status.includes("Work Plan")) {
            label = " Work Plan ";
            if (n.status.includes("Final")) {
                link = "final/workplan";
            } else {
                link = "draft/workplan";
            }
        }
        return (
            <Tooltip title={"View" + label + n.proposal_unique_id}>
                <Button
                    color="primary"
                    className={classes.deleteWidth}
                    aria-label={"View" + label + n.proposal_unique_id}
                    onClick={() => window.open(`#/${link}/${n.id}`)}>
                    <Visibility color="primary" />
                </Button>
            </Tooltip>
        );
    };

    wpGetViewButton = (n, classes) => {
        return (
            <Tooltip title={`View Active Project ${n.proposal_unique_id}`}>
                <Button
                    color="primary"
                    className={classes.deleteWidth}
                    aria-label={`View Active Project ${n.proposal_unique_id}`}
                    onClick={() => window.open(`#/final/workplan/${n.id}`)}>
                    <Visibility color="primary" />
                </Button>
            </Tooltip>
        );
    };

    displayFunding = n => {
        return n.funding_total !== null && typeof n.funding_total !== "undefined" ? "$" + n.funding_total.toLocaleString() : "-";
    };

    wpDisplayFunding = n => {
        return n.appropriation_amount !== null && typeof n.appropriation_amount !== "undefined" ? "$" + n.appropriation_amount.toLocaleString() : "-";
    };
    
    displayCombinedStatus = n => {
        var return_string = ""
        if (n.status === "Final Work Plan Approved") {
            if (n.reporting_status !== "-") { // Has Status Update
                return_string += n.reporting_status
            }
            if (n.amendment_status) { // Has Active Amendment
                if (return_string !== "")
                    return_string += " & " + n.amendment_status
                else
                    return_string += n.amendment_status
            }
            if (return_string === "") // No status update or amendment - return status (Final Work Plan Approved)
                return_string += n.status
        } else {
            return_string += n.status
            if (n.reporting_status !== "-" && n.reporting_status !== "Update Approved" && n.reporting_status !== "Final Update Approved") { // Has Status Update
                if (return_string !== "")
                    return_string += " & " + n.reporting_status
                else
                    return_string += n.reporting_status
            }
            if (n.amendment_status && n.amendment_status !== "Amendment Approved") { // Has Active Amendment
                if (return_string !== "")
                    return_string += " & " + n.amendment_status
                else
                    return_string += n.amendment_status
            }
        }
        return return_string;
    };

    render() {
        const { classes } = this.props;
        const { proposals, plans, completedProjects } = this.props;
        const {
            page,
            rowsPerPage,
            proposalFilter,
            proposalFilterYear,
            orderBy,
            order,
            wpPage,
            wpRowsPerPage,
            wpFilter,
            wpOrderBy,
            wpOrder,
            cPage,
            cRowsPerPage,
            cFilter,
            cOrderBy,
            cOrder
        } = this.state;

        // Text Filter
        var filtered_proposals = proposals;
        if (proposalFilter) {
            filtered_proposals = this.filterByValue(filtered_proposals, proposalFilter);
        }
        var filtered_plans = plans;
        if (wpFilter) {
            filtered_plans = this.filterByValue(filtered_plans, wpFilter);
        }
        var filtered_completed_projects = completedProjects;
        if (cFilter) {
            filtered_completed_projects = this.filterByValue(filtered_completed_projects, cFilter);
        }

        // Year Filter
        if (proposalFilterYear && proposalFilterYear !== "All") {
            filtered_proposals = filtered_proposals.filter(p => {
                if (proposalFilterYear === "Current Year") return parseInt(p.ml_year) === new Date().getFullYear() + 1;
                else return parseInt(p.ml_year) <= new Date().getFullYear();
            });
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Dashboard" />
                    <Messages />
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> Proposals
                                    <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={{ filters: "All" }}>
                                        {formApi => (
                                            <form style={{ float: "right", marginTop: -24 }} onSubmit={formApi.submitForm}>
                                                <RadioGroup
                                                    field="filters"
                                                    style={{ marginBottom: 0 }}
                                                    name="filters"
                                                    eventHandle={val => this.setState({ proposalFilterYear: val })}
                                                    fullWidth
                                                    options={[
                                                        { label: "All", value: "All" },
                                                        { label: "Current Year", value: "Current Year" },
                                                        { label: "Past", value: "Past" }
                                                    ]}
                                                    alignment={true}
                                                    label="Search by Year"
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right", marginTop: -16 }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={val => this.setState({ proposalFilter: val })}
                                                        field="filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <ProposalTable 
                                            classes={classes}
                                            columnDataProposal={columnDataProposal}
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={this.handleRequestSort}
                                            filtered_proposals={filtered_proposals}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            handleChangePage={this.handleChangePage}
                                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            getViewButton={this.getViewButton}
                                            displayFunding={this.displayFunding}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot2} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> Active Projects
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right", marginTop: -16 }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={val => this.setState({ wpFilter: val })}
                                                        field="filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <ProjectTable 
                                            classes={classes}
                                            columnDataWorkPlan={columnDataWorkPlan}
                                            wpOrder={wpOrder}
                                            wpOrderBy={wpOrderBy}
                                            wpHandleRequestSort={this.wpHandleRequestSort}
                                            filtered_plans={filtered_plans}
                                            wpPage={wpPage}
                                            wpRowsPerPage={wpRowsPerPage}
                                            wpHandleChangePage={this.wpHandleChangePage}
                                            wpHandleChangeRowsPerPage={this.wpHandleChangeRowsPerPage}
                                            wpGetViewButton={this.wpGetViewButton}
                                            wpDisplayFunding={this.wpDisplayFunding}
                                            displayCombinedStatus={this.displayCombinedStatus}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot2} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> Completed Projects
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right", marginTop: -16 }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={val => this.setState({ cFilter: val })}
                                                        field="filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12} style={{overflowX:"auto"}}>
                                        <CompletedTable 
                                            classes={classes}
                                            columnDataCompletedProject={columnDataCompletedProject}
                                            cOrder={cOrder}
                                            cOrderBy={cOrderBy}
                                            cHandleRequestSort={this.cHandleRequestSort}
                                            filtered_completed_projects={filtered_completed_projects}
                                            cPage={cPage}
                                            cRowsPerPage={cRowsPerPage}
                                            cHandleChangePage={this.cHandleChangePage}
                                            cHandleChangeRowsPerPage={this.cHandleChangeRowsPerPage}
                                            wpDisplayFunding={this.wpDisplayFunding}
                                        />
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

class ProposalTable extends Component {
    render() {
        const { classes, columnDataProposal, order, orderBy, handleRequestSort, filtered_proposals, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, getViewButton, displayFunding } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    columnData={columnDataProposal}
                    stickyHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {filtered_proposals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                        return (
                            <TableRow key={n.id}>
                                <CustomTableCell className={classes.nowrap}>
                                    {getViewButton(n, classes)}
                                </CustomTableCell>
                                <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                <CustomTableCell>{n.name}</CustomTableCell>
                                <CustomTableCell>{n.category}</CustomTableCell>
                                <CustomTableCell>{n.organization_name}</CustomTableCell>
                                <CustomTableCell>{n.full_name}</CustomTableCell>
                                <CustomTableCell>{displayFunding(n)}</CustomTableCell>
                                <CustomTableCell>{n.status}</CustomTableCell>
                                <CustomTableCell>{n.status_date_label}</CustomTableCell>
                                <CustomTableCell>{n.table_due_date}</CustomTableCell>
                                <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                                <CustomTableCell>{n.secondary_status}</CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {filtered_proposals.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataProposal.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Proposals Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataProposal.length}
                            count={filtered_proposals.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

class ProjectTable extends Component {
    render() {
        const { classes, columnDataWorkPlan, wpOrder, wpOrderBy, wpHandleRequestSort, filtered_plans, wpPage, wpRowsPerPage, wpHandleChangePage, wpHandleChangeRowsPerPage, wpGetViewButton, wpDisplayFunding, displayCombinedStatus } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    columnData={columnDataWorkPlan}
                    stickyHeader
                    order={wpOrder}
                    orderBy={wpOrderBy}
                    onRequestSort={wpHandleRequestSort}
                />
                <TableBody>
                    {filtered_plans.slice(wpPage * wpRowsPerPage, wpPage * wpRowsPerPage + wpRowsPerPage).map(n => {
                        return (
                            <TableRow key={n.id}>
                                <CustomTableCell className={classes.nowrap}>
                                    {wpGetViewButton(n, classes)}
                                </CustomTableCell>
                                <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                <CustomTableCell>{n.citation_ml_year + " " + n.subdivision}</CustomTableCell>
                                <CustomTableCell>{n.name}</CustomTableCell>
                                <CustomTableCell>{n.category}</CustomTableCell>
                                <CustomTableCell>{n.organization_name}</CustomTableCell>
                                <CustomTableCell>{n.full_name}</CustomTableCell>
                                <CustomTableCell>{wpDisplayFunding(n)}</CustomTableCell>
                                <CustomTableCell>{displayCombinedStatus(n)}</CustomTableCell>
                                <CustomTableCell>{n.includes_amendment}</CustomTableCell>
                                <CustomTableCell>
                                    {n.last_action !== "-" ? n.last_action : n.status_date_label.split(" ")[0]}
                                </CustomTableCell>
                                <CustomTableCell>{n.next_status_update_date}</CustomTableCell>
                                <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                                <CustomTableCell>{n.secondary_status}</CustomTableCell>
                                <CustomTableCell></CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {filtered_plans.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataWorkPlan.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Projects Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataWorkPlan.length}
                            count={filtered_plans.length}
                            rowsPerPage={wpRowsPerPage}
                            page={wpPage}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={wpHandleChangePage}
                            onChangeRowsPerPage={wpHandleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

class CompletedTable extends Component {
    render() {
        const { classes, columnDataCompletedProject, cOrder, cOrderBy, cHandleRequestSort, filtered_completed_projects, cPage, cRowsPerPage, cHandleChangePage, cHandleChangeRowsPerPage, wpDisplayFunding } = this.props;

        return (
            <Table className={classes.table}>
                <EnhancedTableHead
                    columnData={columnDataCompletedProject}
                    stickyHeader
                    order={cOrder}
                    orderBy={cOrderBy}
                    onRequestSort={cHandleRequestSort}
                />
                <TableBody>
                    {filtered_completed_projects && filtered_completed_projects.slice(cPage * cRowsPerPage, cPage * cRowsPerPage + cRowsPerPage).map(n => {
                        return (
                            <TableRow key={n.id}>
                                <CustomTableCell className={classes.nowrap}>
                                    <Tooltip title={`View Completed Project ${n.proposal_unique_id}`}>
                                        <Button
                                            color="primary"
                                            className={classes.deleteWidth}
                                            aria-label={`View Completed Project ${n.proposal_unique_id}`}
                                            onClick={() => window.open(`#/final/workplan/${n.id}`)}>
                                            <Visibility color="primary" />
                                        </Button>
                                    </Tooltip>
                                </CustomTableCell>
                                <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                <CustomTableCell>{n.citation_ml_year + " " + n.subdivision}</CustomTableCell>
                                <CustomTableCell>{n.name}</CustomTableCell>
                                <CustomTableCell>{n.category}</CustomTableCell>
                                <CustomTableCell>{n.organization_name}</CustomTableCell>
                                <CustomTableCell>{n.full_name}</CustomTableCell>
                                <CustomTableCell>{wpDisplayFunding(n)}</CustomTableCell>
                                <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {(!filtered_completed_projects || filtered_completed_projects.length < 1) && (
                        <TableRow>
                            <CustomTableCell colSpan={columnDataCompletedProject.length} className={classes.centerAlign}>
                                <caption style={{display:"inline"}}>No Projects Found</caption>
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            colSpan={columnDataCompletedProject.length}
                            count={filtered_completed_projects ? filtered_completed_projects.length : 0}
                            rowsPerPage={cRowsPerPage}
                            page={cPage}
                            classes={{toolbar: classes.floatLeft}}
                            onChangePage={cHandleChangePage}
                            onChangeRowsPerPage={cHandleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }
}

Dashboard = connect(
    (state, ownProps) => ({
        authState: state.auth,
        proposals: getProposals(state, ownProps),
        plans: getPlans(state, ownProps),
        completedProjects: getCompletedProjects(state, ownProps),
        years: getSystemSettings(state),
        reviews: getReviews(state, ownProps)
    }),
    {
        reloadListOfModels: reloadListOfModels
    }
)(Dashboard);

export default withStyles(styles)(withRouter(Dashboard));
