import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import MuiTooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { withStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

import { DraftProposalPersonnel, FinalProposalPersonnel } from "../models";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import { getValue, setValue } from "../../../api/utils";
import { MAKE_OPTIONS_YESNO } from "../../../api/constants";
import WarningDialog from "../../common/WarningDialog";
import { createSelector } from "../../common/orm";
import { WorkPlanTab } from "../../home/models";

const getPersonnel = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalPersonnel.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalPersonnel.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);
const getTab = WorkPlanTab.selectMessage(6);

const styles = theme => ({
    button: {
        marginRight: theme.spacing.unit * 2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    hide: {
        display: "none"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    tableCellNoBorder: {
        border: "none"
    },
    tableCellBold: {
        fontWeight: "Bold",
        color: "#000000"
    },
    rightAlign: {
        textAlign: "right"
    },
    centerAlign: {
        textAlign: "center"
    },
    noMarginTop: {
        marginTop: 0
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
});

class Budget_PersonnelTab extends Component {
    state = {
        PersonnelDialogObj: null,
        WarningDialogObj: null,
        ormPropPrefix: this.props.match.path === "/draft/workplan/:id" ? "Draft" : "Final"
    };

    handleClose = () => {
        this.setState({ PersonnelDialogObj: null });
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "WorkPlan: Budget Personnel - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (_this.props.appContainer.current) {
                _this.props.appContainer.current.scrollTop();
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        var valObj = {};

        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        const isValidValue = path => {
            let val = getValue(values, path);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, val >= 0 ? null : "Value must be >= 0.");
        };

        const isValidNonZeroValue = path => {
            let val = getValue(values, path);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, val > 0 ? null : "Value must be > 0.");
        };

        isRequired("position_name");
        isRequired("project_role");
        isValidValue("amount");
        isValidValue("benefits_pct");
        isValidNonZeroValue("annual_fte");
        isValidNonZeroValue("years");
        isRequired("classified");
        if (values.classified) {
            isRequired("classified_reasons");
        } else {
            setValue(valObj, "classifield_reasons", null);
            if (values.ineligible_expenses) {
                isRequired("ineligible_expenses_reasons");
            }
        }
        if (!values.ineligible_expenses) setValue(valObj, "ineligible_expenses_reasons", null);
        isRequired("ineligible_expenses");

        return valObj;
    };

    savePersonnel(values) {
        const { id } = this.props.plan;

        if (!values.classified) {
            values["classified_reasons"] = null;
        }

        if (!values.ineligible_expenses) {
            values["ineligible_expenses_reasons"] = null;
        }

        values["fte"] = this.calculateFte(values);

        if (values.id) {
            this.props[`orm${this.state.ormPropPrefix}ProposalPersonnelUpdate`]({
                ...values
            });
            this.props.calcFundingTotal();
        } else {
            this.props[`orm${this.state.ormPropPrefix}ProposalPersonnelCreate`]({
                ...values,
                proposal: id
            }).then(response => {
                this.props.calcFundingTotal();
            });
        }
        this.setState({ PersonnelDialogObj: null });
    }

    deleteRecord = () => {
        this.props[`orm${this.state.ormPropPrefix}ProposalPersonnelPromiseDelete`](this.state.WarningDialogObj.id).then(
            id => {
                this.props.calcFundingTotal();
            }
        );
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    calculateFte = values => {
        return parseFloat((values.annual_fte * values.years).toFixed(2));
    };

    render() {
        const { classes, ActionMenu, personnel, handleNext, handlePrev, history, is_read_only, tab } = this.props;
        const { WarningDialogObj, PersonnelDialogObj } = this.state;

        var interact_string = PersonnelDialogObj && PersonnelDialogObj["id"] ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        const column_data = [
            { id: "actions", numeric: false, label: "Actions", width: "20px" },
            {
                id: "position_name",
                numeric: false,
                label: "Position Name/Type",
            },
            {
                id: "project_role",
                numeric: false,
                label: "Project Role/Description",
            },
            {
                id: "ineligible_expenses",
                numeric: false,
                label: "Generally Ineligible",
            },
            {
                id: "benefits_pct",
                numeric: true,
                label: "% Benefits",
            },
            {
                id: "annual_fte",
                numeric: true,
                label: "FTE/Year",
            },
            { id: "years", numeric: true, label: "# of Years" },
            { id: "fte", numeric: true, label: "Total FTE" },
            {
                id: "classified",
                numeric: false,
                label: "Is this a classified position? (State Agencies Only)",
            },
            {
                id: "amount",
                numeric: true,
                label: "Approx. $ Amount",
            }
        ];

        let fteTotal = 0;
        let amtTotal = 0;
        personnel.forEach(p => {
            fteTotal += p.fte;
            amtTotal += p.amount;
        });
        fteTotal = Math.round((fteTotal + Number.EPSILON) * 100) / 100;

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <Typography variant="titleAction">Personnel Budget Details</Typography>
                    {ActionMenu}
                </Grid>

                <Grid item xs={12}>
                    <div class="insborder">
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: tab.sub_tabs
                                        .find(tht => tht.tab_name === "Personnel")
                                        .tab_help_texts.find(tht => tht.label === "Instructions and Requirements:").text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="raised"
                        disabled={is_read_only}
                        color="primary"
                        onClick={() =>
                            this.setState({
                                PersonnelDialogObj: {
                                    id: null,
                                    classified: false,
                                    ineligible_expenses: false
                                }
                            })
                        }>
                        <AddCircleIcon />
                        &nbsp;&nbsp;&nbsp;Add New Personnel Detail
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Table className={classes.table} size="small">
                        <EnhancedTableHead stickyHeader columnData={column_data} />
                        <TableBody>
                            {personnel.map((d, index) => {
                                // let pObj = personnel.filter(o => o.id === d.id)[0];
                                return (
                                    <TableRow key={d.id}>
                                        <TableCell className={classes.nowrap}>
                                            <MuiTooltip title="Edit">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    disabled={is_read_only}
                                                    aria-label="Edit"
                                                    onClick={() => this.setState({ PersonnelDialogObj: d })}>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    disabled={is_read_only}
                                                    aria-label="Delete"
                                                    onClick={() => this.setState({ WarningDialogObj: d })}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="View">
                                                <Button
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="View"
                                                    onClick={() => this.setState({ PersonnelDialogObj: d })}>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </TableCell>
                                        <TableCell>{d.position_name}</TableCell>
                                        <TableCell>{d.project_role}</TableCell>
                                        <TableCell className={classes.centerAlign}>
                                            {d.ineligible_expenses ? "Yes" : "No"}
                                        </TableCell>
                                        <TableCell className={classes.centerAlign}>
                                            {d.benefits_pct < 1.0
                                                ? parseInt(d.benefits_pct * 100) + "%"
                                                : parseInt(d.benefits_pct) + "%"}
                                        </TableCell>
                                        <TableCell className={classes.centerAlign}>{d.annual_fte}</TableCell>
                                        <TableCell className={classes.centerAlign}>{d.years}</TableCell>
                                        <TableCell className={classes.centerAlign}>{this.calculateFte(d)}</TableCell>
                                        <TableCell className={classes.centerAlign}>
                                            {d.classified ? "Yes" : "No"}
                                        </TableCell>
                                        <TableCell className={classes.rightAlign}>
                                            {d.amount.toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {personnel.length > 0 && (
                                <TableRow key={"total"}>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classNames(classes.tableCellBold, classes.tableCellNoBorder)}>
                                        Total FTE
                                    </TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.centerAlign,
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder
                                        )}>
                                        {fteTotal}
                                    </TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder,
                                            classes.rightAlign
                                        )}>
                                        Total Personnel Amount
                                    </TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.rightAlign,
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder
                                        )}>
                                        $&nbsp;{amtTotal.toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            )}
                            {personnel.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={10} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Records</caption>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button
                        variant="raised"
                        disabled={is_read_only}
                        color="primary"
                        className={classes.button}
                        onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>

                <Dialog role="main" aria-label={interact_string + " Personnel Detail"} open={PersonnelDialogObj ? true : false} onClose={this.handleClose} disableBackdropClick={true}>
                    <Form
                        defaultValues={PersonnelDialogObj}
                        validateOnSubmit={true}
                        dontValidateOnMount={true}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.savePersonnel(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle
                                    className={classes.dialogTitleMargin}
                                    disableTypography
                                    id="form-dialog-title">
                                    <Typography variant="h1" className={classes.flex}>{interact_string} Personnel Detail</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={this.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="position_name"
                                                fullWidth
                                                disabled={is_read_only}
                                                margin="normal">
                                                <HelpLabel
                                                    inputLabel="Position Name/Type"
                                                    htmlText={true}
                                                    showLabel={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Personnel")
                                                            .tab_help_texts.find(
                                                                tht => tht.label === "Position Name/Type"
                                                            ).text
                                                    }
                                                />
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                field="project_role"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Project Role/Description"
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                field="amount"
                                                useTextFormat
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Amount"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                useFloatFormat
                                                field="benefits_pct"
                                                fullWidth
                                                disabled={is_read_only}
                                                margin="normal">
                                                <HelpLabel
                                                    inputLabel="% Benefits"
                                                    htmlText={true}
                                                    showLabel={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Personnel")
                                                            .tab_help_texts.find(tht => tht.label === "% Benefits").text
                                                    }
                                                />
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}></Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                useFloatFormat
                                                field="annual_fte"
                                                fullWidth
                                                disabled={is_read_only}
                                                margin="normal">
                                                <HelpLabel
                                                    inputLabel="FTE/Year"
                                                    htmlText={true}
                                                    showLabel={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Personnel")
                                                            .tab_help_texts.find(tht => tht.label === "FTE/Year").text
                                                    }
                                                />
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                field="years"
                                                fullWidth
                                                disabled={is_read_only}
                                                useFloatFormat
                                                label="# of Years"
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                disabled={true}
                                                field="fte"
                                                useFloatFormat
                                                fullWidth
                                                label="Total FTE"
                                                margin="normal"
                                                value={formApi.values ? this.calculateFte(formApi.values) : null}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography>
                                                <i>For State Agencies Only</i>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="classified"
                                                name="classified"
                                                fullWidth
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS_YESNO([
                                                    { label: "Yes", identifier: "Classified_Yes", value: "true", disabled: is_read_only },
                                                    { label: "No", identifier: "Classified_No", value: "false", disabled: is_read_only }
                                                ])}
                                                alignment={true}>
                                                <HelpLabel
                                                    inputLabel="Is this a classified position?"
                                                    htmlText={true}
                                                    showLabel={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Personnel")
                                                            .tab_help_texts.find(
                                                                tht => tht.label === "Is this a classified position?"
                                                            ).text
                                                    }
                                                />
                                            </RadioGroup>
                                            {formApi.values.classified && (
                                                <Subquestion
                                                    component={
                                                        <div>
                                                            <Typography>
                                                                Please provide a request for approval to use ENRTF to
                                                                pay for classified staff. The request should include an
                                                                explanation of how the agency will backfill that part of
                                                                the classified staff salary proposed to be paid for with
                                                                these funds.
                                                            </Typography>
                                                            <TextField
                                                                field="classified_reasons"
                                                                multiline
                                                                fullWidth
                                                                disabled={is_read_only}
                                                                className={classes.noMarginTop}
                                                            />
                                                        </div>
                                                    }
                                                    large={true}
                                                />
                                            )}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="ineligible_expenses"
                                                name="ineligible_expenses"
                                                fullWidth
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS_YESNO([
                                                    { label: "Yes", identifier: "Ineligible", value: "true", disabled: is_read_only },
                                                    { label: "No", identifier: "Eligible", value: "false", disabled: is_read_only }
                                                ])}
                                                alignment={true}>
                                                <HelpLabel
                                                    inputLabel="Generally Ineligible Expenses Justification"
                                                    htmlText={true}
                                                    showLabel={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Personnel")
                                                            .tab_help_texts.find(
                                                                tht =>
                                                                    tht.label ===
                                                                    "Generally Ineligible Expenses Justification"
                                                            ).text
                                                    }
                                                />
                                            </RadioGroup>
                                            {formApi.values.ineligible_expenses && (
                                                <Subquestion
                                                    component={
                                                        <TextField
                                                            field="ineligible_expenses_reasons"
                                                            multiline
                                                            fullWidth
                                                            disabled={is_read_only}
                                                            label=""
                                                        />
                                                    }
                                                    small={true}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    {is_read_only && (
                                        <Button onClick={() => this.setState({ PersonnelDialogObj: null })}>
                                            Close
                                        </Button>
                                    )}
                                    {!is_read_only && (
                                        <>
                                            <Button onClick={() => this.setState({ PersonnelDialogObj: null })}>
                                                Cancel
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled={is_read_only}
                                                type="submit">
                                                Save
                                            </Button>
                                        </>
                                    )}
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>

                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Personnel record"
                    text={"Are you sure you want to delete this Personnel record?"}
                />
            </Grid>
        );
    }
}

Budget_PersonnelTab = connect(
    (state, ownProps) => ({
        personnel: getPersonnel(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...DraftProposalPersonnel.actions,
        ...FinalProposalPersonnel.actions
    }
)(Budget_PersonnelTab);

export default withStyles(styles)(withRouter(Budget_PersonnelTab));
