import React, { Component } from "react";
import classNames from "classnames";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    root: {
        display: "flex"
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit
    },
    group: {
        margin: "0"
    },
    fullWidth: {
        width: "100%"
    },
    horizontal: {
        margin: "0",
        display: "inline"
    },
    groupName: {
        color: "#212529"
    },
    error: {
        "& h3": {
            // No way to access actual text - probably because typography tag is being passed as label
            color: "#be0000"
        },
        "& svg": {
            // Keep color on help icon
            color: "rgba(0, 0, 0, 0.87)"
        }
    }
});

class RadioGroupWrapper extends Component {
    handleChange = (event, value) => {
        const { eventHandle, name } = this.props;
        if (value === "true") value = true;
        if (value === "false") value = false;

        if (eventHandle) eventHandle(value, name);
    };

    render() {
        const {
            value,
            error,
            label,
            noLabel,
            children,
            options,
            classes,
            required,
            alignment,
            fullWidth,
            style,
            ...rest
        } = this.props;

        // FIXME: Material UI requires string values
        // Radio values are returned from API as boolean
        if (value === true) value = "true"; // eslint-disable-line
        if (value === false) value = "false"; // eslint-disable-line

        return (
            <FormControl
                component="fieldset"
                style={style}
                required={required}
                error={error && true}
                className={classNames(classes.formControl, fullWidth && classes.fullWidth)}>
                {!noLabel && (
                    <FormLabel component="legend" className={classes.groupName} classes={{ error: classes.error }}>
                        {label || children}
                    </FormLabel>
                )}
                <RadioGroup
                    className={alignment ? classes.horizontal : classes.group}
                    value={value}
                    onChange={this.handleChange}
                    {...rest}>
                    {options.map(object => (
                        <FormControlLabel
                            disabled={object.disabled}
                            value={object.value}
                            control={<Radio onClick={e => e.stopPropagation()} color="primary" />}
                            label={object.label}
                            onClick={e => e.stopPropagation()}
                        />
                    ))}
                </RadioGroup>
                {error && <FormHelperText style={{ marginTop: 0 }}>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

export default withStyles(styles)(RadioGroupWrapper);
