import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import DomainIcon from "@material-ui/icons/Domain";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import { withStyles } from "@material-ui/core";

import { Proposal, ProposalActivity, ProposalMilestone } from "../models";
import TextField from "../../common/TextField";
import HelpLabel from "../../common/HelpLabel";
import WarningDialog from "../../common/WarningDialog";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import MonthPicker from "../../common/MonthPicker";
import { createSelector } from "../../common/orm";
import { getValue } from "../../../api/utils";
import { ProposalTab } from "../../home/models";

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted"
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    deleteWidth: {
        position: "absolute",
        right: 60,
        padding: "6px !important",
        marginTop: -5
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth2: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    minWidth: {
        minWidth: 600
    },
    hide: {
        display: "none"
    }
});

const columnData = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        width: "20px"
    },
    { id: "mile_num", numeric: true, label: "Milestone #", allowSort: true },
    { id: "description", numeric: false, label: "Title", allowSort: true },
    { id: "completion_date", numeric: false, label: "Approximate Completion Date", allowSort: true }
];

const getActivities = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalActivity.filter({ proposal: id })
            .orderBy("id")
            .toModelArray()
            .map(activity => ({
                ...activity.ref,
                milestones: activity.milestone_activities
                    .all()
                    .orderBy(["id"], ["asc"])
                    .toModelArray()
                    .map((mil, index) => ({
                        ...mil.ref,
                        mile_num: index + 1
                    }))
            }));
    }
);

const getTab = ProposalTab.selectMessage(11);

class ActivityTab extends Component {
    form = [];
    state = {
        expanded: 0,
        deleteOpen: false,
        deleteName: null,
        deleteId: null,
        key: null,
        dialogOpen: false,
        milestone: null,
        activity: null,
        order: "asc",
        orderBy: "mile_num"
    };

    componentDidMount() {
        const { handleUnsavedFields, proposal, ormProposalUpdateLocalOnly } = this.props;
        document.title = "Proposal: Activities and Milestones - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!proposal.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormProposalUpdateLocalOnly({
                    id: proposal.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateName = (value, id) => {
        const { ormProposalActivityUpdateLocalOnly } = this.props;

        ormProposalActivityUpdateLocalOnly({
            id: id,
            title: value
        });
        this.setState({ foo: "bar" });
    };

    updateActivity(values) {
        const { ormProposalActivityUpdate, handleUnsavedFields } = this.props;

        ormProposalActivityUpdate({
            id: values.id,
            ...values
        });

        handleUnsavedFields(false);
    }

    updateAll(draftClick, previousStep) {
        const { history, handleNext, handlePrev } = this.props;

        this.form.forEach(function(fo) {
            fo.submitForm();
        });

        if (draftClick) {
            history.push("/dashboard/");
        } else if (previousStep) {
            handlePrev();
        } else {
            handleNext();
        }
    }

    handleRequestSort = (event, property, items) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        items.sort(function(a, b) {
            if (orderBy === "mile_num") {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else if (orderBy === "completion_date") {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    render() {
        const {
            classes,
            proposal,
            viewMode,
            handleUnsavedFields,
            ActionMenu,
            activities,
            ormProposalActivityCreate,
            ormProposalMilestoneCreate,
            ormProposalMilestoneDelete,
            ormProposalActivityDelete,
            ormProposalMilestoneUpdate,
            ormProposalUpdateLocalOnly,
            tab
        } = this.props;
        const {
            expanded,
            deleteOpen,
            deleteName,
            deleteId,
            key,
            dialogOpen,
            milestone,
            activity,
            order,
            orderBy
        } = this.state;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        const timeline = getValue(proposal, "timeline");

        return (
            <Grid container spacing={16}>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography variant="titleAction">Activities and Milestones</Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12} lg={10} xl={7}>
                    <div style={{ border: "1px solid darkgrey", padding: "10px" }}>
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: tab.tab_help_texts.find(tht => tht.label === "Instructions:").text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} lg={10} xl={7}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        disabled={is_read_only}
                        autoFocus={proposal.highlightedField === "activity_button"}
                        onClick={() => {
                            this.setState({ expanded: activities.length });
                            ormProposalActivityCreate({ proposal: proposal.id }).then(() =>
                                this.props.appContainer.current.scrollBottom()
                            );
                        }}>
                        <AddCircleOutline />
                        &nbsp;&nbsp;&nbsp;Add Activity
                    </Button>
                </Grid>
                <Grid item xs={12} lg={10} xl={7}>
                    {activities.length === 0 && (
                        <Typography variant="h3" style={{ textAlign: "center" }}>
                            No Activities Added
                        </Typography>
                    )}
                    {activities.map((a, i) => {
                        return (
                            <ExpansionPanel
                             //CollapseProps={{ unmountOnExit: true }}
                                defaultExpanded={viewMode ? true : false}
                                expanded={viewMode ? null : expanded === i || proposal.review_activity === a.id}
                                onChange={() => {
                                    if (expanded === i) this.setState({ expanded: -1 });
                                    else this.setState({ expanded: i });
                                }}>
                                <ExpansionPanelSummary
                                    className={classes.expansionSummaryRoot}
                                    expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h3" style={{ width: "85%" }}>
                                        <DomainIcon className={classes.expansionIcon} /> Activity {i + 1} -{" "}
                                        {!a.title && <i>Add Title Below...</i>} {a.title}
                                    </Typography>
                                    <Tooltip title="Delete Activity">
                                        <Button
                                            color="primary"
                                            className={is_read_only ? classes.hide : classes.deleteWidth}
                                            disabled={is_read_only}
                                            aria-label="Delete Activity"
                                            onClick={e => {
                                                e.stopPropagation();
                                                this.setState({
                                                    deleteOpen: true,
                                                    deleteName: a.title,
                                                    deleteId: a.id
                                                });
                                            }}>
                                            <DeleteIcon color="primary" />
                                        </Button>
                                    </Tooltip>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <Form
                                                getApi={el => this.form.push(el)}
                                                key={"Activity_" + a.id}
                                                dontValidateOnMount={true}
                                                validateOnSubmit={true}
                                                defaultValues={a}
                                                formDidUpdate={() => handleUnsavedFields(true)}
                                                onSubmit={values => this.updateActivity(values)}>
                                                {formApi => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <Grid container spacing={16}>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    maxWords="20"
                                                                    disabled={is_read_only}
                                                                    eventHandle={v => this.updateName(v, a.id)}
                                                                    field="title"
                                                                    checkFocus={proposal.review_activity === a.id && proposal}
                                                                    focusRemoval={ormProposalUpdateLocalOnly}
                                                                    fullWidth>
                                                                    <HelpLabel
                                                                        inputLabel="Activity Title (20 word limit)"
                                                                        htmlText={true}
                                                                        showLabel={true}
                                                                        helpText={
                                                                            tab.tab_help_texts.find(
                                                                                tht =>
                                                                                    tht.label ===
                                                                                    "Activity Title (20 word limit)"
                                                                            ).text
                                                                        }
                                                                    />
                                                                </TextField>
                                                                <TextField
                                                                    multiline
                                                                    disabled={is_read_only}
                                                                    maxWords="200"
                                                                    field="description"
                                                                    checkFocus={proposal.review_activity === a.id && proposal}
                                                                    focusRemoval={ormProposalUpdateLocalOnly}
                                                                    fullWidth>
                                                                    <HelpLabel
                                                                        inputLabel="Activity Description (200 word limit)"
                                                                        htmlText={true}
                                                                        showLabel={true}
                                                                        helpText={
                                                                            tab.tab_help_texts.find(
                                                                                tht =>
                                                                                    tht.label ===
                                                                                    "Activity Description (200 word limit)"
                                                                            ).text
                                                                        }
                                                                    />
                                                                </TextField>
                                                                <TextField
                                                                    useTextFormat
                                                                    field="budget"
                                                                    checkFocus={proposal.review_activity === a.id && proposal}
                                                                    focusRemoval={ormProposalUpdateLocalOnly}
                                                                    disabled={is_read_only}
                                                                    label="ENRTF Budget ($)"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                )}
                                            </Form>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <HelpLabel
                                                inputLabel="Milestones"
                                                htmlText={true}
                                                showLabel={true}
                                                helpText={
                                                    tab.tab_help_texts.find(tht => tht.label === "Milestones").text
                                                }
                                            />
                                            <Typography>Note: Activities typically have 3-5 milestones.</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                disabled={is_read_only}
                                                autoFocus={proposal.highlightedField === "milestone_button" && proposal.review_activity === a.id}
                                                onClick={() => {
                                                    this.setState({
                                                        dialogOpen: true,
                                                        milestone: null,
                                                        activity: a.id,
                                                        key: window.performance.now()
                                                    });
                                                }}>
                                                <AddCircleOutline />
                                                &nbsp;&nbsp;&nbsp;Add Milestone
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Table className={classes.table}>
                                                <EnhancedTableHead
                                                    columnData={columnData}
                                                    stickyHeader
                                                    order={order}
                                                    orderBy={orderBy}
                                                    name={a.milestones}
                                                    onRequestSort={this.handleRequestSort}
                                                />
                                                <TableBody>
                                                    {a.milestones.map(n => {
                                                        return (
                                                            <TableRow key={n.id}>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Edit Milestone">
                                                                        <Button
                                                                            color="primary"
                                                                            autoFocus={proposal.highlightedField === "milestone_table" && proposal.review_activity === a.id && proposal.review_milestone === n.id}
                                                                            className={
                                                                                is_read_only
                                                                                    ? classes.hide
                                                                                    : classes.deleteWidth2
                                                                            }
                                                                            aria-label="Edit Milestone"
                                                                            disabled={is_read_only}
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    dialogOpen: true,
                                                                                    milestone: n,
                                                                                    activity: null,
                                                                                    key: window.performance.now()
                                                                                })
                                                                            }>
                                                                            <EditIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete Milestone">
                                                                        <Button
                                                                            color="primary"
                                                                            className={
                                                                                is_read_only
                                                                                    ? classes.hide
                                                                                    : classes.deleteWidth2
                                                                            }
                                                                            aria-label="Delete Milestone"
                                                                            disabled={is_read_only}
                                                                            onClick={() =>
                                                                                ormProposalMilestoneDelete(n.id)
                                                                            }>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="View Milestone">
                                                                        <Button
                                                                            color="primary"
                                                                            className={
                                                                                !is_read_only
                                                                                    ? classes.hide
                                                                                    : classes.deleteWidth2
                                                                            }
                                                                            aria-label="View Milestone"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    dialogOpen: true,
                                                                                    milestone: n,
                                                                                    activity: null,
                                                                                    key: window.performance.now()
                                                                                })
                                                                            }>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.mile_num}</CustomTableCell>
                                                                <CustomTableCell>{n.description}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    {n.completion_date &&
                                                                        new Date(
                                                                            n.completion_date + "T12:00:00"
                                                                        ).toLocaleDateString()}
                                                                </CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {a.milestones.length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell
                                                                colSpan={columnData.length}
                                                                className={classes.centerAlign}>
                                                                <caption style={{display:"inline"}}>No Milestones Added</caption>
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })}
                </Grid>
                <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => this.updateAll(false, true)}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={is_read_only}
                        onClick={() => this.updateAll(true, false)}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={is_read_only}
                        onClick={() => this.updateAll(false, false)}
                        className={classes.button}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>
                <WarningDialog
                    confirmAction={() => {
                        ormProposalActivityDelete(deleteId);
                        this.setState({ deleteOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteOpen: false })}
                    open={deleteOpen}
                    title="Delete Activity"
                    confirmText="Delete Activity"
                    text={
                        "Are you sure you wish to delete " +
                        (deleteName ? deleteName : "this activity") +
                        "? Any milestones entered for this activity will also be deleted."
                    }
                />
                <MilestoneDialog
                    open={dialogOpen}
                    ormProposalMilestoneUpdate={ormProposalMilestoneUpdate}
                    ormProposalMilestoneCreate={ormProposalMilestoneCreate}
                    milestone={milestone}
                    returnDialog={() => this.setState({ dialogOpen: false })}
                    classes={classes}
                    formkey={key}
                    timeline={timeline}
                    activity={activity}
                    proposal={proposal}
                />
            </Grid>
        );
    }
}

class MilestoneDialog extends Component {
    addMilestone(values) {
        const {
            returnDialog,
            ormProposalMilestoneUpdate,
            ormProposalMilestoneCreate,
            milestone,
            activity
        } = this.props;

        if (!milestone) {
            ormProposalMilestoneCreate({
                activity: activity,
                ...values
            });
        } else {
            ormProposalMilestoneUpdate({
                ...values
            });
        }

        returnDialog();
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            description: isRequired(values.description),
            completion_date: isRequired(values.completion_date)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, formkey, milestone, timeline, proposal } = this.props;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member"));
        var interact_string = !milestone ? "Add New" : "Edit";
        if (is_read_only) {
            interact_string = "View";
        }

        return (
            <Dialog role="main" aria-label={interact_string + " Milestone"} open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        {interact_string} Milestone
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={formkey}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={milestone}
                            onSubmit={values => this.addMilestone(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField
                                                maxWords="15"
                                                field="description"
                                                disabled={is_read_only}
                                                fullWidth
                                                autoFocus
                                                label={"Title (15 word limit)"}
                                            />
                                            <MonthPicker
                                                maxDate={timeline}
                                                disabled={!timeline || is_read_only}
                                                field="completion_date"
                                                label={
                                                    <Typography>
                                                        Approximate Completion Date
                                                        {!timeline && (
                                                            <>
                                                                <br />
                                                                <i style={{ color: "rgba(0, 0, 0, 0.58)" }}>
                                                                    You must set the project completion date (Narrative)
                                                                    prior to milestone dates
                                                                </i>
                                                            </>
                                                        )}
                                                        {timeline && (
                                                            <>
                                                                <br />
                                                                <i style={{ color: "rgba(0, 0, 0, 0.58)" }}>
                                                                    Select the month + year
                                                                </i>
                                                            </>
                                                        )}
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={is_read_only}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

ActivityTab = connect(
    (state, ownProps) => ({
        activities: getActivities(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...Proposal.actions,
        ...ProposalActivity.actions,
        ...ProposalMilestone.actions
    }
)(ActivityTab);

export default withStyles(styles)(withRouter(ActivityTab));
