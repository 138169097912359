import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import AppContainer from "../common/AppContainer";
import Checkbox from "../common/Checkbox";
import TextField from "../common/TextField";
import Select from "../common/Select";
import PageHeader from "../common/PageHeader";
import { FiscalAgent } from "../home/models";
import states from "../common/states.json";

const getAgent = FiscalAgent.selectByUrlId();

class EditAgent extends Component {
    componentDidMount() {
        document.title = "Edit Fiscal Agent - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        return {
            name: validateRequired(values.name)
        };
    };

    submitForm = values => {
        const { ormFiscalAgentUpdate, ormFiscalAgentCreate, agent, history } = this.props;

        if (!agent) {
            ormFiscalAgentCreate(values);
        } else {
            ormFiscalAgentUpdate({
                id: values.id,
                ...values
            });
        }
        history.push("/adm/agents");
    };

    render() {
        const { agent } = this.props;

        return (
            <AppContainer authenticated>
                <Grid container justify="center" spacing={40}>
                    <PageHeader title={agent ? "Edit Fiscal Agent: " + agent.name : "New Fiscal Agent"} admin_page />
                    <Grid item xs={11} md={8} lg={6}>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={agent}
                            validateError={this.errorValidator}
                            onSubmit={this.submitForm}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={9}>
                                            <TextField field="name" label="Fiscal Agent Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={3} style={{ marginTop: 24 }}>
                                            <Checkbox field="is_active" label="Active" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="web_address" label="Web Address" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="mailing_address" label="Mailing Address" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="address_2" label="Address 2" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="city" label="City" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select field="state" label="State" options={states} fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField field="zip" label="Zip" fullWidth />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={16}>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth component={Link} to="/adm/agents">
                                                Return to Manage Fiscal Agent
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

EditAgent = connect(
    (state, ownProps) => ({
        authState: state.auth,
        agent: getAgent(state, ownProps)
    }),
    FiscalAgent.actions
)(EditAgent);

export default withRouter(EditAgent);
