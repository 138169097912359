import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";

import TextField from "../../common/TextField";
import { WorkPlanTab } from "../../home/models";

const getTab = WorkPlanTab.selectMessage(9);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    marginTop: {
        marginTop: "1.4em"
    },
    asterisk: {
        whiteSpace: "normal",
        lineHeight: 1.5,
        color: "#212529 !important",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif"
    }
});

class Dissemination extends Component {
    state = {
        isDraft: this.props.match.path === "/draft/workplan/:id"
    };

    componentDidMount() {
        const { handleUnsavedFields, ormParentUpdateLocalOnly, plan } = this.props;
        document.title = "Work Plan: Dissemination - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted       
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!plan.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormParentUpdateLocalOnly({
                    id: plan.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updatePlan(values, fromStepper) {
        const {
            ormParentUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            plan,
            is_read_only,
            ormParentUpdateLocalOnly
        } = this.props;
        const { id } = plan;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (is_read_only) {
                ormParentUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // See Attachments.js for an explanation of why I'm doing this
        values.map = plan.map;
        values.financial_capacity = plan.financial_capacity;
        values.modify_date_label = plan.modify_date_label;
        values.update_date = getDateTimeStamp();

        ormParentUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (this.state.draftClick) {
                history.push("/dashboard/");
            } else if (this.state.previousStep) {
                handlePrev();
            } else {
                handleNext();
            }
        }
    }

    render() {
        const { classes, handleUnsavedFields, ActionMenu, plan, is_read_only, tab, ormParentUpdateLocalOnly } = this.props;
        const { isDraft } = this.state;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={plan.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={plan.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updatePlan(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="titleAction">
                                    Dissemination
                                    {isDraft && (
                                        <span className={classes.asterisk}>
                                            <b>&thinsp;(new)</b>
                                        </span>
                                    )}
                                </Typography>
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <div class="insborder">
                                    <Typography>Instructions and Requirements:</Typography>
                                    <Typography gutterBottom>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: tab.tab_help_texts.find(tht => tht.label === "Instructions and Requirements:").text
                                            }}
                                        />
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <TextField disabled={is_read_only} field="dissemination" label="Describe Dissemination Efforts." multiline fullWidth checkFocus={plan} focusRemoval={ormParentUpdateLocalOnly} />
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{ float: "left" }}
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                    className={classes.button}>
                                    Save and Return to Previous Step
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    onClick={() => this.setState({ draftClick: true })}
                                    className={classes.button}>
                                    Save Draft and Return to Dashboard
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={is_read_only}
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                    Save and Proceed to Next Step
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

Dissemination = connect(
    (state, ownProps) => ({
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    null
)(Dissemination);

export default withStyles(styles)(withRouter(Dissemination));
