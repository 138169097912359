import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import MuiTooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { withStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

import { ProposalAcquisition } from "../models";
import TextField from "../../common/TextField";
import HelpLabel from "../../common/HelpLabel";
import Select from "../../common/Select";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TableCell from "../../common/TableCell";
import { getValue, setValue } from "../../../api/utils";
import WarningDialog from "../../common/WarningDialog";
import { createSelector } from "../../common/orm";
import { ProposalTab } from "../../home/models";

const category_options = [
    { value: "Fee Title Acquisition", label: "Fee Title Acquisition" },
    {
        value: "Conservation Easement Acquisition",
        label: "Conservation Easement Acquisition"
    },
    { value: "Trail Easement Acquisition", label: "Trail Easement Acquisition" },
    { value: "Other Acquisition", label: "Other Acquisition" },
    { value: "Stewardship Funds", label: "Stewardship Funds" }
];

const acre_category_options = ["Fee Title Acquisition", "Other Acquisition", "Conservation Easement Acquisition"];

const mile_category_options = ["Other Acquisition", "Trail Easement Acquisition"];

const parcel_category_options = [
    "Fee Title Acquisition",
    "Other Acquisition",
    "Conservation Easement Acquisition",
    "Trail Easement Acquisition"
];

const description_category_options = ["Stewardship Funds"];

const getAcquisitions = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalAcquisition.filter({ proposal: id })
            .orderBy("id")
            .toModelArray();
    }
);
const getTab = ProposalTab.selectMessage(12);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    hide: {
        display: "none"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    tableCellAlignRight: {
        textAlign: "right"
    },
    tableCellAlignCenter: {
        textAlign: "center"
    },
    tableCellNoBorder: {
        border: "none"
    },
    tableCellBold: {
        fontWeight: "Bold",
        color: "#000000"
    },
    centerAlign: {
        textAlign: "center"
    }
});

class Budget_AcquisitionTab extends Component {
    state = {
        AcquisitionDialogObj: null,
        WarningDialogObj: null
    };

    handleClose = () => {
        this.setState({ AcquisitionDialogObj: null });
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: Budget Acquisition - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        var valObj = {};

        const isRequired = path => {
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };

        const isValidValue = path => {
            let val = getValue(values, path);
            if (!val && val !== 0) {
                setValue(valObj, path, "Required");
                return;
            }
            setValue(valObj, path, val >= 0 ? null : "Value must be >= 0.");
        };

        setValue(valObj, "acres", null);
        setValue(valObj, "miles", null);
        setValue(valObj, "parcels", null);
        setValue(valObj, "description", null);

        isRequired("category");
        isValidValue("amount");

        if (values.category === "Fee Title Acquisition") {
            isValidValue("acres");
            isValidValue("parcels");
        }

        if (values.category === "Other Acquisition") {
            isValidValue("acres");
            isValidValue("miles");
            isValidValue("parcels");
        }

        if (values.category === "Conservation Easement Acquisition") {
            isValidValue("acres");
            isValidValue("parcels");
        }

        if (values.category === "Trail Easement Acquisition") {
            isValidValue("miles");
            isValidValue("parcels");
        }

        if (values.category === "Stewardship Funds") {
            isRequired("description");
        }

        return valObj;
    };

    saveAcquisition(values) {
        const { id } = this.props.proposal;
        const { ormProposalAcquisitionCreate, ormProposalAcquisitionUpdate } = this.props;

        if (values.category === "Fee Title Acquisition") {
            values["miles"] = null;
            values["description"] = null;
        }

        if (values.category === "Other Acquisition") {
            values["description"] = null;
        }

        if (values.category === "Conservation Easement Acquisition") {
            values["miles"] = null;
            values["description"] = null;
        }

        if (values.category === "Trail Easement Acquisition") {
            values["acres"] = null;
            values["description"] = null;
        }

        if (values.category === "Stewardship Funds") {
            values["acres"] = null;
            values["miles"] = null;
            values["parcels"] = null;
        }

        if (values.id) {
            ormProposalAcquisitionUpdate({
                ...values
            });
            this.props.calcFundingTotal();
        } else {
            ormProposalAcquisitionCreate({
                ...values,
                proposal: id
            }).then(response => {
                this.props.calcFundingTotal();
            });
        }
        this.setState({ AcquisitionDialogObj: null });
    }

    deleteRecord = () => {
        this.props.ormProposalAcquisitionPromiseDelete(this.state.WarningDialogObj.id).then(id => {
            this.props.calcFundingTotal();
        });
        this.setState({ WarningDialogObj: null });
    };

    cancelDeleteRecord = () => {
        this.setState({ WarningDialogObj: null });
    };

    render() {
        const { classes, acquisitions, ActionMenu, handleNext, handlePrev, history, proposal, tab } = this.props;
        const { AcquisitionDialogObj, WarningDialogObj } = this.state;
        const is_read_only =
            (proposal.status !== "Draft In Progress" &&
                proposal.status !== "Draft Feedback Received" &&
                proposal.status !== "Revisions Needed") ||
            proposal.secondary_status === "Withdrawal Requested" ||
            proposal.secondary_status === "Withdrawn" ||
            (this.props.authState &&
                this.props.authState.user &&
                (this.props.authState.user.role === "Staff" || this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only));

        var interact_string = AcquisitionDialogObj && AcquisitionDialogObj["id"] ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        const column_data = [
            { id: "actions", numeric: false, label: "Actions", width: "20px" },
            {
                id: "category",
                numeric: false,
                label: "Category",
            },
            {
                id: "description",
                numeric: false,
                label: "Description",
            },
            {
                id: "acres",
                numeric: true,
                label: "Approx. Acres",
            },
            {
                id: "parcels",
                numeric: true,
                label: "Approx. Parcels",
            },
            {
                id: "miles",
                numeric: true,
                label: "Approx. Miles",
            },
            {
                id: "amount",
                numeric: true,
                label: "$ Amount",
            }
        ];

        let amtTotal = 0;
        acquisitions.forEach(a => {
            amtTotal += a.amount;
        });

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <Typography variant="titleAction">Acquisition Details</Typography>
                    {ActionMenu}
                </Grid>

                <Grid item xs={12}>
                    <div class="insborder">
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: tab.sub_tabs
                                        .find(tht => tht.tab_name === "Acquisition")
                                        .tab_help_texts.find(tht => tht.label === "Instructions and Requirements:").text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="raised"
                        color="primary"
                        disabled={is_read_only}
                        onClick={() => this.setState({ AcquisitionDialogObj: { id: null } })}>
                        <AddCircleIcon />
                        &nbsp;&nbsp;&nbsp;Add New Acquisition or Stewardship
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Table className={classes.table}>
                        <EnhancedTableHead stickyHeader columnData={column_data} />
                        <TableBody>
                            {acquisitions.map(d => {
                                return (
                                    <TableRow key={d.id}>
                                        <TableCell className={classes.nowrap}>
                                            <MuiTooltip title="Edit">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Edit"
                                                    onClick={n => this.setState({ AcquisitionDialogObj: d })}>
                                                    <EditIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="Delete">
                                                <Button
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="Delete"
                                                    onClick={() => this.setState({ WarningDialogObj: d })}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                            <MuiTooltip title="View">
                                                <Button
                                                    className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                    aria-label="View"
                                                    onClick={n => this.setState({ AcquisitionDialogObj: d })}>
                                                    <ViewIcon color="primary" />
                                                </Button>
                                            </MuiTooltip>
                                        </TableCell>
                                        <TableCell>{d.category}</TableCell>
                                        <TableCell>{d.description}</TableCell>
                                        <TableCell className={classes.tableCellAlignCenter}>
                                            {d.acres ? d.acres.toLocaleString() : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCellAlignCenter}>
                                            {d.parcels ? d.parcels.toLocaleString() : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCellAlignCenter}>
                                            {d.miles ? d.miles.toLocaleString() : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCellAlignRight}>
                                            {d.amount ? d.amount.toLocaleString() : ""}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {acquisitions.length > 0 && (
                                <TableRow key={"total"}>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell className={classes.tableCellNoBorder}></TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder,
                                            classes.tableCellAlignRight
                                        )}>
                                        Total Amount
                                    </TableCell>
                                    <TableCell
                                        className={classNames(
                                            classes.tableCellAlignRight,
                                            classes.tableCellBold,
                                            classes.tableCellNoBorder
                                        )}>
                                        $&nbsp;{amtTotal.toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            )}
                            {acquisitions.length < 1 && (
                                <TableRow>
                                    <TableCell colSpan={7} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Records</caption>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button
                        disabled={is_read_only}
                        variant="raised"
                        onClick={() => history.push("/dashboard/")}
                        className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button
                        disabled={is_read_only}
                        variant="raised"
                        color="primary"
                        className={classes.button}
                        onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>

                <Dialog
                    open={AcquisitionDialogObj ? true : false}
                    onClose={this.handleClose}
                    disableBackdropClick={true}>
                    <Form
                        defaultValues={AcquisitionDialogObj}
                        validateOnSubmit={true}
                        dontValidateOnMount={true}
                        validateError={values => this.errorValidator(values)}
                        onSubmit={values => this.saveAcquisition(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle
                                    className={classes.dialogTitleMargin}
                                    disableTypography
                                    id="form-dialog-title">
                                    <Typography variant="h3">{interact_string} Acquisition or Stewardship</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={this.handleClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Select
                                                field="category"
                                                fullWidth
                                                disabled={is_read_only}
                                                label="Category"
                                                margin="normal"
                                                options={category_options}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                useTextFormat
                                                field="amount"
                                                label="Amount"
                                                disabled={is_read_only}
                                                margin="normal"
                                            />
                                        </Grid>

                                        {acre_category_options.indexOf(formApi.values.category) > -1 && (
                                            <Grid item xs={4}>
                                                <TextField
                                                    field="acres"
                                                    useFloatFormat
                                                    fullWidth
                                                    disabled={is_read_only}
                                                    label="Approximate # Acres"
                                                    margin="normal"
                                                />
                                            </Grid>
                                        )}

                                        {mile_category_options.indexOf(formApi.values.category) > -1 && (
                                            <Grid item xs={4}>
                                                <TextField
                                                    field="miles"
                                                    useFloatFormat
                                                    fullWidth
                                                    disabled={is_read_only}
                                                    label="Approximate # Miles"
                                                    margin="normal"
                                                />
                                            </Grid>
                                        )}

                                        {parcel_category_options.indexOf(formApi.values.category) > -1 && (
                                            <Grid item xs={4}>
                                                <TextField
                                                    field="parcels"
                                                    useFloatFormat
                                                    fullWidth
                                                    disabled={is_read_only}
                                                    label="Approximate # Parcels"
                                                    margin="normal"
                                                />
                                            </Grid>
                                        )}

                                        {description_category_options.indexOf(formApi.values.category) > -1 && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="description"
                                                    fullWidth
                                                    disabled={is_read_only}
                                                    margin="normal">
                                                    <HelpLabel
                                                        inputLabel="Stewardship Fund Holder"
                                                        showLabel={true}
                                                        htmlText={true}
                                                        helpText={
                                                            tab.sub_tabs
                                                                .find(tht => tht.tab_name === "Acquisition")
                                                                .tab_help_texts.find(
                                                                    tht => tht.label === "Stewardship Fund Holder"
                                                                ).text
                                                        }
                                                    />
                                                </TextField>
                                            </Grid>
                                        )}
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose}>Cancel</Button>
                                    <Button color="primary" variant="contained" disabled={is_read_only} type="submit">
                                        Save
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>

                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={this.deleteRecord}
                    cancelText={"No"}
                    cancelAction={this.cancelDeleteRecord}
                    open={!!WarningDialogObj}
                    title="Delete Acquisition record"
                    text={"Are you sure you want to delete this Acquisition record?"}
                />
            </Grid>
        );
    }
}

Budget_AcquisitionTab = connect(
    (state, ownProps) => ({
        acquisitions: getAcquisitions(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...ProposalAcquisition.actions
    }
)(Budget_AcquisitionTab);

export default withStyles(styles)(withRouter(Budget_AcquisitionTab));
