import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import Switch from "../../common/Switch";

import { Proposal } from "../models";
import { StaffScore } from "../../home/models";
import TextField from "../../common/TextField";
import { createSelector } from "../../common/orm";

const getStaffScore = createSelector(
    (state, ownProps) => ownProps.proposal.id,
    (state, ownProps) => state.auth.user.id,
    (schema, proposal, id) => {
        return schema.StaffScore.filter(x => x.user === id && x.proposal === proposal).first();
    }
);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginTop: 0
        }
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    flex: {
        flex: 1
    },
    total: {
        fontWeight: "Bold",
        color: "#000000"
    }
});

const categories = [
    {
        label: "Funding Priorities",
        field: "funding_priorities"
    },
    {
        label: "Multiple Benefits",
        field: "multiple_benefits"
    },
    {
        label: "Outcomes",
        field: "outcomes"
    },
    {
        label: "KnowledgeBase",
        field: "knowledge_base"
    },
    {
        label: "Extent of Impact",
        field: "extent_of_impact"
    },
    {
        label: "Innovation",
        field: "innovation"
    },
    {
        label: "Scientific/Technical Basis",
        field: "scientific_technical_basis"
    },
    {
        label: "Urgency",
        field: "urgency"
    },
    {
        label: "Capacity and Readiness",
        field: "capacity_and_readiness"
    },
    {
        label: "Leverage",
        field: "leverage"
    }
];

class Staff_Score extends Component {
    state = {
        dont: false
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Proposal: My Staff Scoring - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);
    }

    componentDidUpdate() {
        //resets form to include id after creation of score
        if (this.state.dont) {
            this.setState({ dont: false });
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    errorValidator = values => {
        const validateScrore = score => {
            if (score === undefined || score === null || score === "")
                return null;
            if ((parseInt(score) < 0 || parseInt(score) > 10)) {
                return "Score must be between 0-10";
            } else if (isNaN(parseInt(score))) {
                return "Valid Score between 0-10 is required";
            }
            return null;
        };
        const validateScroreCombined = score => {
            if (parseInt(score) < 0 || parseInt(score) > 100) {
                return "Score must be between 0-100";
            } else if (isNaN(parseInt(score))) {
                return "Valid Score between 0-100 is required";
            }
            return null;
        };
        const validateEmpty = score => {
            if (score) {
                return "You have already entered a combined score. Either remove this value or the combined score.";
            }
            return null;
        };

        var valObj = {};

        if (values["combined"] && values["combined"] !== "") {
            valObj["combined"] = validateScroreCombined(values["combined"]);
            categories.forEach(x => {
                valObj[x.field] = validateEmpty(values[x.field]);
            });
        }
        else {
            valObj["combined"] = null;
            categories.forEach(x => {
                valObj[x.field] = validateScrore(values[x.field]);
            });
        }

        return valObj;
    };

    submit = (values, fromStepper) => {
        const { authState, proposal, staffSlot, ormStaffScoreUpdate, ormStaffScoreCreate, ormProposalUpdateLocalOnly } = this.props;

        for (var propName in values) {
            if (values[propName] === "" || values[propName] === undefined) {
                values[propName] = null
            }
          }
        //calculate total
        var total = 0;
        if (values["combined"]) {
            total += parseInt(values["combined"]);
        }
        categories.forEach(x => {
            if (values[x.field]) {
                total += parseInt(values[x.field]);
            }
        });
        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            ormProposalUpdateLocalOnly({
                id: proposal.id,
                activeStep: fromStepper["step"],
                activeSubStep: fromStepper["subStep"]
            });
        }

        if (values.id) {
            values.total = total;
            ormStaffScoreUpdate({
                ...values
            });
        } else {
            ormStaffScoreCreate({
                proposal_id: proposal.id,
                user_id: authState.user.id,
                slot: staffSlot ? staffSlot.slot : null,
                total: total,
                ...values
            }).then(id => {
                this.setState({ dont: true });
            });
        }
    };

    getTotal = formApi => {
        var total = 0;
        if (formApi.values["combined"]) {
            total += parseInt(formApi.values["combined"]);
        } else {
            categories.forEach(x => {
                if (formApi.values[x.field]) {
                    total += parseInt(formApi.values[x.field]);
                }
            });
        }
        return total;
    };

    render() {
        const { classes, settings, score, ActionMenu } = this.props;
        const { dont } = this.state;

        return (
            <>
                {!dont && (
                    <Form
                        defaultValues={score}
                        getApi={el => (this.form = el)}
                        validateOnSubmit={true}
                        validateError={this.errorValidator}
                        dontValidateOnMount={true}
                        onSubmit={(values, fromStepper) => this.submit(values, fromStepper)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16} style={{ marginTop: 0 }}>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Typography variant="titleAction">My Scoring for Final Proposal</Typography>
                                        {ActionMenu}
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <Switch
                                            disabled={settings.staff_eval_open}
                                            field="done"
                                            label="Complete"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                                            <ExpansionPanelSummary
                                                className={classes.expansionSummaryRoot}
                                                expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant="h3" style={{ width: "100%" }}>
                                                    <QuestionAnswerIcon className={classes.expansionIcon} />
                                                    Scoring
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={12}>
                                                        <Typography style={{color:"#be0000"}}>Staff: Either fill out each individual category below (0-10) <u>OR</u> enter a single combined score (0-100) at the bottom.</Typography>
                                                    </Grid>
                                                    {categories.map(x => {
                                                        return (
                                                            <Grid item xs={12} md={6} xl={4}>
                                                                <TextField
                                                                    disabled={
                                                                        settings.staff_eval_open
                                                                    }
                                                                    useFloatFormat
                                                                    field={x.field}
                                                                    fullWidth
                                                                    label={x.label}
                                                                />
                                                            </Grid>
                                                        );
                                                    })}
                                                    <Grid item xs={12}>
                                                        <Typography gutterBottom><b><i>OR</i></b></Typography>
                                                        <TextField
                                                            disabled={
                                                                settings.staff_eval_open
                                                            }
                                                            useFloatFormat
                                                            field="combined"
                                                            label="Staff Entered Score"
                                                        />
                                                        <Typography style={{marginTop:16}} className={classes.total} >
                                                            Total Score: {this.getTotal(formApi)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                    <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                        <Button
                                            disabled={settings.staff_eval_open}
                                            variant="contained"
                                            type="submit"
                                            style={{ float: "left" }}
                                            className={classes.button}>
                                            Save
                                        </Button>
                                        <Button
                                            component={Link}
                                            to="/adm/proposal/process"
                                            variant="contained"
                                            style={{ float: "left" }}
                                            className={classes.button}>
                                            Return to Proposal Process Page
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                )}
            </>
        );
    }
}

Staff_Score = connect(
    (state, ownProps) => ({
        authState: state.auth,
        score: getStaffScore(state, ownProps)
    }),
    {
        ...Proposal.actions,
        ...StaffScore.actions
    }
)(Staff_Score);

export default withStyles(styles)(withRouter(Staff_Score));
