import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import CustomTabelCell from "../../common/TableCell";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import { createSelector } from "../../common/orm";
import { MemberEvaluation, RFPMember } from "../../home/models";
import { TableBody } from "@material-ui/core";

const getEvaluations = createSelector(
    (state, ownProps) => ownProps.proposal.id,
    (schema, proposal) => {
        return schema.MemberEvaluation.filter(x => x.proposal === proposal)
            .all()
            .toModelArray()
            .map(x => {
                return {
                    member: schema.User.filter(z => z.id === x.user_id).first(),
                    ...x.ref
                };
            });
    }
);

const getRfpMembers = RFPMember.selectAll();
const getYear = createSelector(
    (state, ownProps) => ownProps.proposal.ml_year,
    (schema, year) => {
        return schema.SystemSetting.filter(x => x.year === year).first();
    }
);

const getReview = createSelector(
    (state, ownProps) => ownProps.proposal.id,
    (schema, proposal) => {
        return schema.ProposalReview.filter(x => x.proposal === proposal).first();
    }
);

function getMedian(array) {
    array.sort(function(a, b) {
        return a - b;
    });
    var mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
}

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    flex: {
        flex: 1
    },
    total: {
        fontWeight: "Bold",
        color: "#000000"
    },
    box: {
        marginTop: 8,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        backgroundColor: "#c0c0c0",
        border: "1px solid #060100",
        color: "#060100"
    },
    darkBox: {
        marginTop: 8,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        backgroundColor: "#929292",
        border: "1px solid #060100",
        color: "#060100"
    },
    bold: {
        fontWeight: "Bold",
        color: "#000000",
    },
    none: {
        borderBottom: "none !important"
    }
});

class Member_score extends Component {
    componentDidMount() {
        const { match, ormMemberEvaluationLoadDetailChildFilterMany, handleUnsavedFields } = this.props;
        document.title = "Proposal: View Member Scoring Summary - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);

        ormMemberEvaluationLoadDetailChildFilterMany({
            proposal: match.params.id
        });
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    median = key => {
        const { evals } = this.props;
        const median = getMedian(evals.filter(x => Number.isFinite(x[key])).map(x => x[key]));
        return isNaN(median) ? "-" : Math.round(median);
    };

    render() {
        const { classes, evals, members, year, proposal, review, ActionMenu } = this.props;

        const conflict = evals.map(x => ({
            member: x.member ? x.member.first_name + " " + x.member.last_name : "-",
            conflict: x.conflict
        }));

        const pre_avg = Math.round(
            evals.filter(x => Number.isFinite(x.score)).reduce((a, b) => a + b.score, 0) / evals.filter(x => Number.isFinite(x.score)).length
        );
        const post_avg = Math.round(
            evals.filter(x => Number.isFinite(x.score2)).reduce((a, b) => a + b.score2, 0) / evals.filter(x => Number.isFinite(x.score2)).length
        );

        const total = members.filter(x => x.year_id === year.id).length;
        const conflict_total = evals.filter(x => x.conflict).length;
        const selected = evals.filter(x => x.selected).length;
        const compiled = evals.length > 0 ? Math.round((selected / (total - conflict_total)) * 100) : "-";

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12} lg={10} xl={10}>
                    <Typography variant="titleAction">
                        Member Proposal Scores Summary For {proposal.proposal_unique_id + ": " + proposal.name}
                    </Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12} lg={10} xl={10}>
                    <Typography variant="titleAction">Pre-Presentation Scores</Typography>
                </Grid>
                <Grid item xs={12} lg={10} xl={10}>
                    <Grid container spacing={16}>
                        <Grid item xs={3}>
                            <Typography className={classes.bold}>Staff Score</Typography>
                            <Typography className={classes.darkBox}>
                                {review && review.final_score ? review.final_score : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.bold}># of Members Scoring</Typography>
                            <Typography className={classes.box}>
                                {evals.filter(x => Number.isFinite(x.score)).length}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.bold}>Member Average Score</Typography>
                            <Typography className={classes.box}>
                                {isNaN(pre_avg) ? "-" : pre_avg}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.bold}>Member Median Score</Typography>
                            <Typography className={classes.box}>{this.median("score")}</Typography>
                        </Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.bold}>
                                # of Members Selected for Presentation
                            </Typography>
                            <Typography className={classes.box}>
                                {evals.filter(x => x.selected).length}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.bold}>Compiled Member Evaluation #1</Typography>
                            <Typography className={classes.box}>{compiled}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={10} xl={10}>
                    <Typography variant="titleAction">Post Presentation Scores</Typography>
                </Grid>
                <Grid item xs={12} lg={10} xl={10}>
                    <Grid container spacing={16}>
                        <Grid item xs={3}>
                            <Typography className={classes.bold}># of Members Scoring</Typography>
                            <Typography className={classes.box}>
                                {evals.filter(x => Number.isFinite(x.score2)).length}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.bold}>Member Average Score</Typography>
                            <Typography className={classes.box}>
                                {isNaN(post_avg) ? "-" : post_avg}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.bold}>Member Median Score</Typography>
                            <Typography className={classes.box}>{this.median("score2")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={10} xl={10}>
                    <Grid container spacing={8}>
                        <Grid item xs>
                            <Typography variant="titleAction">Member Conflict of Interest</Typography>
                            <Table>
                                <EnhancedTableHead
                                    stickyHeader
                                    columnData={[{ id: "member", numeric: false, label: "Member" }]}
                                />
                                <TableBody>
                                    {conflict
                                        .filter(x => x.conflict)
                                        .map((x, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <CustomTabelCell>{x.member}</CustomTabelCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="titleAction">Member Shared Pre-Presentation Comments</Typography>
                            <Table>
                                <EnhancedTableHead
                                    columnData={[
                                        { id: "comments", numeric: false, label: "Comments" }
                                    ]}
                                    stickyHeader
                                />
                                <TableBody>
                                    {evals
                                        .filter(x => x.comments)
                                        .map((x, i) => {
                                            return (
                                                <TableRow>
                                                    <CustomTabelCell>{x.comments}</CustomTabelCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="titleAction">Member Shard Post Presentation Comments</Typography>
                            <Table>
                                <EnhancedTableHead
                                    columnData={[
                                        { id: "comments", numeric: false, label: "Comments" }
                                    ]}
                                    stickyHeader
                                />
                                <TableBody>
                                    {evals
                                        .filter(x => x.comments2)
                                        .map((x, i) => {
                                            return (
                                                <TableRow>
                                                    <CustomTabelCell>{x.comments2}</CustomTabelCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={10} xl={10} className={classes.rightAlign}>
                    <Button
                        component={Link}
                        to="/adm/proposal/process"
                        variant="contained"
                        style={{ float: "left" }}
                        className={classes.button}>
                        Return to Proposal Process Page
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

Member_score = connect(
    (state, ownProps) => ({
        authState: state.auth,
        review: getReview(state, ownProps),
        evals: getEvaluations(state, ownProps),
        members: getRfpMembers(state, ownProps),
        year: getYear(state, ownProps)
    }),
    {
        ...MemberEvaluation.actions
    }
)(Member_score);

export default withStyles(styles)(withRouter(Member_score));
