import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import { Proposal } from "../models";
import Switch from "../../common/Switch";
import { getValue } from "../../../api/utils";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import HelpLabel from "../../common/HelpLabel";
import { createSelector, reloadListOfModels } from "../../common/orm";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "../../common/TextField";

import { withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { Organization, ProposalReview, ProposalTab } from "../../home/models";

const getTab = ProposalTab.selectMessage(15);
const getActivities = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalActivity.filter({ proposal: id })
            .toModelArray()
            .map(activity => ({
                ...activity.ref,
                milestones: activity.milestone_activities
                    .all()
                    .orderBy("id")
                    .toRefArray()
            }));
    }
);

const getParcels = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalParcel.filter({ proposal: id })
            .orderBy("name", "asc")
            .toRefArray();
    }
);

const getBoardAttachments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalAttachment.filter({ proposal: id, type: "Board Resolution" })
            .orderBy("id")
            .count();
    }
);

const getFinancialAttachments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ProposalAttachment.filter({ proposal: id, type: "Financial Capacity" })
            .orderBy("id")
            .count();
    }
);

const getOrganizations = Organization.selectAll();

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    paddingLeft: {
        paddingLeft: "40px"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    tableCellAlignRight: {
        textAlign: "right"
    },
    tableCellAlignCenter: {
        textAlign: "center"
    },
    tableCellNoBorder: {
        border: "none"
    },
    tableCellBold: {
        fontWeight: "Bold",
        color: "#000000"
    },
    centerAlign: {
        textAlign: "center"
    },
    gotoButton: {
        padding: 4
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
});

class ReviewTab extends Component {
    state = {
        thanksDialog: false
    };

    componentDidMount() {
        const { authState, handleUnsavedFields } = this.props;
        document.title = "Proposal: Review / Submit - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            _this.props.appContainer.current.scrollTop();
        }, 1);

        if (authState && authState.user) {
            this.props.reloadListOfModels(["SystemSetting"]);
        }
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updateProposal(values, fromStepper) {
        const { ormProposalUpdate, handleUnsavedFields, getDateTimeStamp, organizations, proposal, handlePrev, history } = this.props;
        const { id } = proposal;

        // See Attachments.js for an explanation of why I'm doing this
        values.map = proposal.map;
        values.financial_capacity = proposal.financial_capacity;
        values.modify_date_label = proposal.modify_date_label;

        handleUnsavedFields(false);

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            /*
            ormProposalUpdate({
                id: id,
                ...values
            });*/
            // ML. The code above causes proposal status to be set to the prevoious status before it was submitted. So from Draft Submitted it would go back to Draft in Progress.
            ormProposalUpdate({
                id: id,
                activeStep: fromStepper["step"],
                activeSubStep: fromStepper["subStep"]
            });
        } else {
            values.update_date = getDateTimeStamp();
            if (!this.state.previousStep) {
                // They've clicked a final submit button
                if (this.state.updateStatus) {
                    const date = new Date();
                    const dateFns = new DateFnsUtils();
                    const stamp = dateFns.format(date, "yyyy-MM-dd HH:mm:ss.SSSSSS");

                    if (proposal.status === "Draft In Progress" && this.state.draftSubmittal) {
                        // RFP year allows and draft submittal button clicked
                        values["status"] = "Draft Submitted";
                        values["status_date"] = stamp;

                        const staff_lead = organizations.find(x => x.id === values.organization);
                        values["staff_lead_id"] = staff_lead ? staff_lead.staff_lead_id : null;
                    } else if (proposal.status === "Draft In Progress" && !this.state.draftSubmittal) {
                        values["status"] = "Final Submitted";
                        values["status_date"] = stamp;

                        values["primary_phase"] = "Proposal";
                        values["primary_date"] = stamp;

                        values["secondary_status"] = "Under Review";
                        values["secondary_date"] = stamp;

                        const staff_lead = organizations.find(x => x.id === values.organization);
                        values["staff_lead_id"] = staff_lead ? staff_lead.staff_lead_id : null;
                    } else if (proposal.status === "Draft Feedback Received") {
                        values["status"] = "Final Submitted";
                        values["status_date"] = stamp;

                        values["secondary_status"] = "Under Review";
                        values["secondary_date"] = stamp;

                        values["primary_phase"] = "Proposal";
                        values["primary_date"] = stamp;
                    } else if (proposal.status === "Draft Submitted") {
                        values["status"] = "Final Submitted";
                        values["status_date"] = stamp;

                        values["secondary_status"] = "Under Review";
                        values["secondary_date"] = stamp;

                        values["primary_phase"] = "Proposal";
                        values["primary_date"] = stamp;
                    } else if (proposal.status === "Revisions Needed") {
                        values["status"] = "Revisions Submitted";
                        values["status_date"] = stamp;
                    }
                }

                ormProposalUpdate({
                    id: id,
                    ...values
                });
                if (this.state.updateStatus) this.setState({ thanksDialog: true });
                else history.push("/dashboard/");
            } else {
                ormProposalUpdate({
                    id: id,
                    ...values
                });
                handlePrev();
            }
        }
    }

    zoomToField = (step, substep, field, activity_id, milestone_id) => {
        const { ormProposalUpdateLocalOnly, proposal } = this.props;
        ormProposalUpdateLocalOnly({
            id: proposal.id,
            activeStep: step,
            activeSubStep: substep,
            highlightedField: field,
            fromReview: true,
            review_activity: activity_id,
            review_milestone: milestone_id
        });
    }

    checkResults = () => {
        const { proposal, activities, parcels, authState, classes } = this.props;
        const _this = this;
        var missing = [];
        const recipient = getValue(proposal, "org_data.recipient");
        const org_name = getValue(proposal, "org_data.organization_name");
        const ml_year = getValue(proposal, "ml_year");
        const Easement = getValue(proposal, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(proposal, "land_rights").indexOf("Fee Acquisition") > -1;
        //2_1
        if (!proposal.pm_first_name) missing.push(<>Project Manager First Name (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager First Name Field" onClick={() => this.zoomToField(1, 0, "pm_first_name")}><ViewIcon /></IconButton></>);
        if (!proposal.pm_last_name) missing.push(<>Project Manager Last Name (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Last Name Field" onClick={() => this.zoomToField(1, 0, "pm_last_name")}><ViewIcon /></IconButton></>);
        if (proposal.pm_first_name !== authState.user.first_name || proposal.pm_last_name !== authState.user.last_name)
            missing.push(<>The person submitting this proposal must be the project manager. Your account name ({authState.user.first_name} {authState.user.last_name}) does not match the project manager's name ({proposal.pm_first_name} {proposal.pm_last_name}) (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager First Name Field" onClick={() => this.zoomToField(1, 0, "pm_first_name")}><ViewIcon /></IconButton></>);
        if (!proposal.pm_office_phone) missing.push(<>Project Manager Office Phone (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Office Phone Field" onClick={() => this.zoomToField(1, 0, "pm_office_phone")}><ViewIcon /></IconButton></>);
        if (!proposal.pm_email) missing.push(<>Project Manager Email (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Email Field" onClick={() => this.zoomToField(1, 0, "pm_email")}><ViewIcon /></IconButton></>);
        if (!proposal.pm_mailing_address) missing.push(<>Project Manager Mailing Address (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Mailing Address Field" onClick={() => this.zoomToField(1, 0, "pm_mailing_address")}><ViewIcon /></IconButton></>);
        if (!proposal.pm_city) missing.push(<>Project Manager City (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager City Field" onClick={() => this.zoomToField(1, 0, "pm_city")}><ViewIcon /></IconButton></>);
        if (!proposal.pm_state) missing.push(<>Project Manager State (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager State Field" onClick={() => this.zoomToField(1, 0, "pm_state")}><ViewIcon /></IconButton></>);
        if (!proposal.pm_zip) missing.push(<>Project Manager Zip Code (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Zip Code Field" onClick={() => this.zoomToField(1, 0, "pm_zip")}><ViewIcon /></IconButton></>);
        if (!proposal.pm_job_title) missing.push(<>Project Manager Job Title (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Job Title Field" onClick={() => this.zoomToField(1, 0, "pm_job_title")}><ViewIcon /></IconButton></>);
        if (!proposal.pm_qualification) missing.push(<>Project Manager Qualifications (Project Collaborators -> Project Manager Info)<IconButton className={classes.gotoButton} title="Go To Project Manager Qualifications Field" onClick={() => this.zoomToField(1, 0, "pm_qualification")}><ViewIcon /></IconButton></>);

        //2_2
        if (!proposal.organization) missing.push(<>Organization (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Organization Field" onClick={() => this.zoomToField(1, 1, "organization")}><ViewIcon /></IconButton></>);
        if (!proposal.org_description) missing.push(<>Organization Description (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Organization Description Field" onClick={() => this.zoomToField(1, 1, "org_description")}><ViewIcon /></IconButton></>);

        if (recipient !== "State Government" && recipient !== "Federal Government") {
            if (proposal.has_fiscal_agent !== true && proposal.has_fiscal_agent !== false)
                missing.push(<>Does Organization Have Fiscal Agent (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Does Organization Have Fiscal Agent Field" onClick={() => this.zoomToField(1, 1, "has_fiscal_agent")}><ViewIcon /></IconButton></>); // !proposal.has_fiscal_agent will miss false ("No")
            if (proposal.has_fiscal_agent === true) {
                if (!proposal.fiscal_agent) missing.push(<>Fiscal Agent (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Fiscal Agent Field" onClick={() => this.zoomToField(1, 1, "fiscal_agent")}><ViewIcon /></IconButton></>);
                if (!proposal.agent_contact_name) missing.push(<>Fiscal Agent Contact Name (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Fiscal Agent Contact Name Field" onClick={() => this.zoomToField(1, 1, "agent_contact_name")}><ViewIcon /></IconButton></>);
                if (!proposal.agent_contact_phone) missing.push(<>Fiscal Agent Contact Phone (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Fiscal Agent Contact Phone Field" onClick={() => this.zoomToField(1, 1, "agent_contact_email")}><ViewIcon /></IconButton></>);
                if (!proposal.agent_contact_email) missing.push(<>Fiscal Agent Contact Email (Project Collaborators -> Organization Info)<IconButton className={classes.gotoButton} title="Go To Fiscal Agent Contact Email Field" onClick={() => this.zoomToField(1, 1, "agent_contact_phone")}><ViewIcon /></IconButton></>);
            }
        }
        //2_3
        // These are checked directly in the dialog
        //3
        if (!proposal.name) missing.push(<>Project Title (General Information)<IconButton className={classes.gotoButton} title="Go To Project Title Field" onClick={() => this.zoomToField(2, 0, "name")}><ViewIcon /></IconButton></>);
        if (!proposal.final_funding_category) {
            if (!proposal.funding_category) missing.push(<>LCCMR Funding Category (General Information)<IconButton className={classes.gotoButton} title="Go To LCCMR Funding Category Field" onClick={() => this.zoomToField(2, 0, "funding_category")}><ViewIcon /></IconButton></>);
            if (proposal.funding_category === "Small Projects") {
                if (!proposal.secondary_funding_category) missing.push(<>Seconday LCCMR Funding Category (General Information)<IconButton className={classes.gotoButton} title="Go To Seconday LCCMR Funding Category Field" onClick={() => this.zoomToField(2, 0, "secondary_funding_category")}><ViewIcon /></IconButton></>);
            }
        } else {
            if (!proposal.final_funding_category) missing.push(<>LCCMR Funding Category (General Information)<IconButton className={classes.gotoButton} title="Go To LCCMR Funding Category Field" onClick={() => this.zoomToField(2, 0, "final_funding_category")}><ViewIcon /></IconButton></>);
            if (proposal.final_funding_category === "Small Projects") {
                if (!proposal.final_secondary_funding_category) missing.push(<>Seconday LCCMR Funding Category (General Information)<IconButton className={classes.gotoButton} title="Go To Seconday LCCMR Funding Category Field" onClick={() => this.zoomToField(2, 0, "final_secondary_funding_category")}><ViewIcon /></IconButton></>);
            }
        }
        if (proposal.include_research !== true && proposal.include_research !== false) {
            missing.push(<>Does your project include original, hypothesis-driven research? (General Information)<IconButton className={classes.gotoButton} title="Does your project include original, hypothesis-driven research? Field" onClick={() => this.zoomToField(2, 0, "include_research")}><ViewIcon /></IconButton></>);
        }
        // land_rights & patent_royalty_revenue_potential (checkboxes might actually be none)
        if (proposal.patent_royalty_revenue_potential.length === 0) {
            missing.push(<>Does your project have potential for royalties, copyrights, patents, sale of products and assets, or revenue generation? (General Information)<IconButton className={classes.gotoButton} title="Go To Does your project have potential for royalties, copyrights, patents, sale of products and assets, or revenue generation? Field" onClick={() => this.zoomToField(2, 0, "patent_royalty_revenue_potential")}><ViewIcon /></IconButton></>);
        } else if (proposal.patent_royalty_revenue_potential.length === 2) {
            if (proposal.understand_revenue && !proposal.reinvestment_description) {
                missing.push(<>Description of revenues intended to be reinvested into project (General Information)<IconButton className={classes.gotoButton} title="Go To Description of revenues intended to be reinvested into project Field" onClick={() => this.zoomToField(2, 0, "reinvestment_description")}><ViewIcon /></IconButton></>);
            }
        }  
        
        if (ml_year >= 2024) {
            if (proposal.asset_tenthousand !== true && proposal.asset_tenthousand !== false) {
                missing.push(<>Does your project include the pre-design, design, construction, or renovation of a building, trail, campground, or other fixed capital asset costing $10,000 or more or large-scale stream or wetland restoration? (General Information)<IconButton className={classes.gotoButton} title="Go To Does your project include the pre-design, design, construction, or renovation of a building, trail, campground, or other fixed capital asset costing $10,000 or more or large-scale stream or wetland restoration? Field" onClick={() => this.zoomToField(2, 0, "asset_tenthousand")}><ViewIcon /></IconButton></>);
            }
            if (Fee || Easement) {
                if (proposal.acquisition_tenthousand !== true && proposal.acquisition_tenthousand !== false) {
                    missing.push(<>Is the purpose of the acquisition for construction of a building(s), trail, campground, or other fixed capital asset costing $10,000 or more or large- scale stream or wetland restoration, either now or in the future? (General Information)<IconButton className={classes.gotoButton} title="Go To Is the purpose of the acquisition for construction of a building(s), trail, campground, or other fixed capital asset costing $10,000 or more or large- scale stream or wetland restoration, either now or in the future? Field" onClick={() => this.zoomToField(2, 0, "acquisition_tenthousand")}><ViewIcon /></IconButton></>);
                }
            }
            if (proposal.children_services !== true && proposal.children_services !== false) {
                missing.push(<>Do you propose using an appropriation from the Environment and Natural Resources Trust Fund to conduct a project that provides children's services, as defined in Minnesota Statutes section 299C.61 Subd.7? (General Information)<IconButton className={classes.gotoButton} title="Go To Do you propose using an appropriation from the Environment and Natural Resources Trust Fund to conduct a project that provides children's services, as defined in Minnesota Statutes section 299C.61 Subd.7? Field" onClick={() => this.zoomToField(2, 0, "children_services")}><ViewIcon /></IconButton></>);
            }
            if (proposal.children_services === true ) {
                if (proposal.background_checks !== true && proposal.background_checks !== false) {
                    missing.push(<>Do you certify that background checks are performed for background check crimes, as defined in Minnesota Statutes, section 299C.61, Subd. 2, on all employees, contractors, and volunteers who have or may have access to a child to whom children's services are provided by your organization? (General Information)<IconButton className={classes.gotoButton} title="Go To Do you certify that background checks are performed for background check crimes, as defined in Minnesota Statutes, section 299C.61, Subd. 2, on all employees, contractors, and volunteers who have or may have access to a child to whom children's services are provided by your organization? Field" onClick={() => this.zoomToField(2, 0, "background_checks")}><ViewIcon /></IconButton></>);
                }
            }
        }

        if (!proposal.final_location_scale) {
            if (!proposal.location_scale) missing.push(<>Project Location (General Information)<IconButton className={classes.gotoButton} title="Go To Project Location Field" onClick={() => this.zoomToField(2, 0, "location_scale")}><ViewIcon /></IconButton></>);
            if (proposal.location_scale && proposal.location_scale !== "Statewide") {
                if (!proposal.location_areas.length === 0) missing.push(<>Project Location Areas (General Information)<IconButton className={classes.gotoButton} title="Go To Project Location Areas Field" onClick={() => this.zoomToField(2, 0, "location_areas")}><ViewIcon /></IconButton></>);
            }
        } else {
            if (!proposal.final_location_scale) missing.push(<>Project Location (General Information)<IconButton className={classes.gotoButton} title="Go To Project Location Field" onClick={() => this.zoomToField(2, 0, "final_location_scale")}><ViewIcon /></IconButton></>);
            if (proposal.final_location_scale && proposal.final_location_scale !== "Statewide") {
                if (!proposal.final_location_areas.length === 0) missing.push(<>Project Location Areas (General Information)<IconButton className={classes.gotoButton} title="Go To Project Location Areas Field" onClick={() => this.zoomToField(2, 0, "final_location_areas")}><ViewIcon /></IconButton></>);
            }
        }

        if (!proposal.final_impact_scale) {
            if (!proposal.impact_scale) missing.push(<>Project Area Impacted (General Information)<IconButton className={classes.gotoButton} title="Go To Project Area Impacted Field" onClick={() => this.zoomToField(2, 0, "impact_scale")}><ViewIcon /></IconButton></>);
            if (proposal.impact_scale && proposal.impact_scale !== "Statewide") {
                if (!proposal.impact_areas.length === 0) missing.push(<>Project Area Impacted Areas (General Information)<IconButton className={classes.gotoButton} title="Go To Project Area Impacted Areas Field" onClick={() => this.zoomToField(2, 0, "impact_areas")}><ViewIcon /></IconButton></>);
            }
        } else {
            if (!proposal.final_impact_scale) missing.push(<>Project Area Impacted (General Information)<IconButton className={classes.gotoButton} title="Go To Project Area Impacted Field" onClick={() => this.zoomToField(2, 0, "final_impact_scale")}><ViewIcon /></IconButton></>);
            if (proposal.final_impact_scale && proposal.final_impact_scale !== "Statewide") {
                if (!proposal.final_impact_areas.length === 0) missing.push(<>Project Area Impacted Areas (General Information)<IconButton className={classes.gotoButton} title="Go To Project Area Impacted Areas Field" onClick={() => this.zoomToField(2, 0, "final_impact_areas")}><ViewIcon /></IconButton></>);
            }
        }
        // impact_occur (checkboxes might actually be none)
        // Other ENRTF Appropriations Awarded in the Last Six Years (optional?)
        // 4
        if (!proposal.summary) missing.push(<>Project Summary (Narrative)<IconButton className={classes.gotoButton} title="Go To Project Summary Field" onClick={() => this.zoomToField(3, 0, "summary")}><ViewIcon /></IconButton></>);
        if (!proposal.opportunity) missing.push(<>Opportunity or Problem Proposal Addresses (Narrative)<IconButton className={classes.gotoButton} title="Go To Opportunity or Problem Proposal Addresses Field" onClick={() => this.zoomToField(3, 0, "opportunity")}><ViewIcon /></IconButton></>);
        if (!proposal.solution) missing.push(<>Proposed Solution (Narrative)<IconButton className={classes.gotoButton} title="Go To Proposed Solution Field" onClick={() => this.zoomToField(3, 0, "solution")}><ViewIcon /></IconButton></>);
        if (!proposal.outcome) missing.push(<>Specific Project Outcomes (Narrative)<IconButton className={classes.gotoButton} title="Go To Specific Project Outcomes Field" onClick={() => this.zoomToField(3, 0, "outcome")}><ViewIcon /></IconButton></>);
        if (!proposal.timeline) missing.push(<>Timeline (Narrative)<IconButton className={classes.gotoButton} title="Go To Timeline Field" onClick={() => this.zoomToField(3, 0, "timeline")}><ViewIcon /></IconButton></>);
        if (!proposal.longterm_implementation) missing.push(<>Long-Term Implementation and Funding (Narrative)<IconButton className={classes.gotoButton} title="Go To Long-Term Implementation and Funding Field" onClick={() => this.zoomToField(3, 0, "longterm_implementation")}><ViewIcon /></IconButton></>);
        // 5
        if (activities.length === 0) {
            if (!proposal.timeline) missing.push(<>Your project must have at least one activity (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Button" aria-label="Go To Activity Button" onClick={() => this.zoomToField(4, 0, "activity_button")}><ViewIcon /></IconButton></>);
        }
        var budget_total = 0;
        activities.forEach(function(a) {
            var temptitle = a.title ? a.title : "An activity";
            if (!a.title) missing.push(<>{temptitle} is missing a Title (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Title Field" onClick={() => _this.zoomToField(4, 0, "title", a.id)}><ViewIcon /></IconButton></>);
            if (!a.description) missing.push(<>{temptitle} is missing a Description (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Description Field" onClick={() => _this.zoomToField(4, 0, "description", a.id)}><ViewIcon /></IconButton></>);
            if (!a.budget) missing.push(<>{temptitle} is missing a ENRTF Budget (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To ENRTF Budget Field" onClick={() => _this.zoomToField(4, 0, "budget", a.id)}><ViewIcon /></IconButton></>);
            budget_total += a.budget;
            if (a.milestones.length === 0) missing.push(<>{temptitle} must contain at least one milestone (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Button" aria-label="Go To Milestone Button" onClick={() => _this.zoomToField(4, 0, "milestone_button", a.id)}><ViewIcon /></IconButton></>);
            a.milestones.forEach(function(m) {
                if (m.completion_date > proposal.timeline)
                    missing.push(<>{temptitle} has a milestone completion date that is past the project timeline date (Activities and Milestones)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Milestone Table" onClick={() => _this.zoomToField(4, 0, "milestone_table", a.id, m.id)}><ViewIcon /></IconButton></>);
            });
        });
        //6
        // It seems that every budget thing is required already in the budget dialog
        // Just check if the value is rounded to the thousandth
        var is_thousand = proposal.funding_total % 1000 === 0;
        if (!is_thousand)
            missing.push(<>The Total ENRTF Funds Requested Amount is not a round thousand dollars. Currently the total is ${proposal.funding_total.toLocaleString()}. (Budget)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Budget Summary Table" onClick={() => _this.zoomToField(5, 8, "_no")}><ViewIcon /></IconButton></>);
        if (budget_total !== proposal.funding_total) {
            missing.push(<>The activity budget total of ${budget_total.toLocaleString()} does not match the total ENRTF funds requested of ${proposal.funding_total.toLocaleString()}. (Activities and Milestones or Budget)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Budget Summary Table" onClick={() => _this.zoomToField(5, 8, "_no")}><ViewIcon /></IconButton></>);
        }        
        if (!proposal.accurate && ml_year >= 2025) {
            missing.push(<>Total Project Cost accurately reflects total project costs? (Budget Summary)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Budget Summary Table" onClick={() => _this.zoomToField(5, 8, "accurate")}><ViewIcon /></IconButton></>);
        }
        //7
        // Only check for attachment fields not in a table
        // Assume they may have 0 or many attachments in the tables
        // The table fields should be checked for in the dialog on save
        if (proposal.map === null || proposal.map === "") missing.push(<>Map or Visual Component (Attachments)<IconButton className={classes.gotoButton} title="Go To Map or Visual Component Field" onClick={() => this.zoomToField(6, 0, "map")}><ViewIcon /></IconButton></>);
        if (proposal.map_description === null || proposal.map_description === "") missing.push(<>Map or Visual Component Description (Attachments)<IconButton className={classes.gotoButton} title="Go To Map or Visual Component Description Field" onClick={() => this.zoomToField(6, 0, "map_description")}><ViewIcon /></IconButton></>);
        
        if (ml_year <= 2024) {
            if (
                (recipient === "For-Profit Business/Entity" || recipient === "Private College/University" || recipient === "Non-Profit Business/Entity") &&
                (proposal.financial_capacity === null || proposal.financial_capacity === "")
            )
                missing.push(<>Financial Capacity Information (Attachments)<IconButton className={classes.gotoButton} title="Go To Financial Capacity Information Field" onClick={() => this.zoomToField(6, 0, "financial_capacity")}><ViewIcon /></IconButton></>);
        } else {
            if (
                (recipient === "For-Profit Business/Entity" || recipient === "Private College/University" || recipient === "Local/Regional Government" || recipient === "Non-Profit Business/Entity") &&
                (proposal.financial_capacity === null || proposal.financial_capacity === "") && this.props.financialAttachment === 0
            )
                missing.push(<>Financial Capacity Information (Attachments)<IconButton className={classes.gotoButton} title="Go To Financial Capacity Information Field" onClick={() => this.zoomToField(6, 0, "financial_capacity")}><ViewIcon /></IconButton></>);
        }

        const board_resolution =
            recipient === "Tribal" ||
            recipient === "Federal Government" ||
            recipient === "Local/Regional Government" ||
            recipient === "K-12 Education" ||
            recipient === "Non-Profit Business/Entity" ||
            (ml_year >= 2024 && org_name && org_name.includes("U of MN"));

        if (board_resolution && this.props.boardAttachment === 0) {
            missing.push(<>Board Resolution or Letter (Attachments)<IconButton className={classes.gotoButton} title="Go To Board Resolution or Letter Field" onClick={() => this.zoomToField(6, 0, "board_resolution")}><ViewIcon /></IconButton></>);
        }

        // 8
        // Must have selected some land rights to have access
        const Restoration = getValue(proposal, "land_rights").indexOf("Restoration") > -1;
        const Conservation = getValue(proposal, "land_rights").indexOf("Conservation Easement Acquisition") > -1;
        if (Restoration || Conservation || Easement || Fee) {
            // They must have at least 1 parcel
            if (parcels.length === 0) missing.push(<>This project must have at least one parcel (Acquisition and Restoration -> Parcel List)<IconButton className={classes.gotoButton} title="Go To Table" aria-label="Go To Parcel Table" onClick={() => this.zoomToField(7, 0, "parcel_table")}><ViewIcon /></IconButton></>);
            if (Fee) {
                if (!proposal.fee_selection_process) missing.push(<>Selection Process (Acquisition and Restoration -> Fee Acquisition)<IconButton className={classes.gotoButton} title="Go To Selection Process Field" onClick={() => this.zoomToField(7, 1, "fee_selection_process")}><ViewIcon /></IconButton></>);
                if (!proposal.fee_resource_plans) missing.push(<>Natural Resource Plans (Acquisition and Restoration -> Fee Acquisition)<IconButton className={classes.gotoButton} title="Go To Natural Resource Plans Field" onClick={() => this.zoomToField(7, 1, "fee_resource_plans")}><ViewIcon /></IconButton></>);
                if (!proposal.fee_management_plans) missing.push(<>Restoration and Management Plans (Acquisition and Restoration -> Fee Acquisition)<IconButton className={classes.gotoButton} title="Go To Restoration and Management Plans Field" onClick={() => this.zoomToField(7, 1, "fee_management_plans")}><ViewIcon /></IconButton></>);
                if (!proposal.fee_county_board_approval) missing.push(<>County Board Approval (Acquisition and Restoration -> Fee Acquisition)<IconButton className={classes.gotoButton} title="Go To County Board Approval Field" onClick={() => this.zoomToField(7, 1, "fee_county_board_approval")}><ViewIcon /></IconButton></>);
            }
            if (Conservation) {
                if (!proposal.cea_selection_process) missing.push(<>Selection Process (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Selection Process Field" onClick={() => this.zoomToField(7, 2, "cea_selection_process")}><ViewIcon /></IconButton></>);
                if (!proposal.cea_resource_plans) missing.push(<>Natural Resource Plans (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Natural Resource Plans Field" onClick={() => this.zoomToField(7, 2, "cea_resource_plans")}><ViewIcon /></IconButton></>);
                if (!proposal.cea_management_plans)
                    missing.push(<>Restoration and Management Plans (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Restoration and Management Plans Field" onClick={() => this.zoomToField(7, 2, "cea_management_plans")}><ViewIcon /></IconButton></>);
                if (!proposal.cea_county_board_approval)
                    missing.push(<>County Board Approval (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To County Board Approval Field" onClick={() => this.zoomToField(7, 2, "cea_county_board_approval")}><ViewIcon /></IconButton></>);
                if (!proposal.cea_water_quality_protection)
                    missing.push(<>Water Quality Projection (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Water Quality Projection Field" onClick={() => this.zoomToField(7, 2, "cea_water_quality_protection")}><ViewIcon /></IconButton></>);
                if (!proposal.cea_monitoring_program)
                    missing.push(<>Long Term Programs for Conservation Easements (Acquisition and Restoration -> Conservation Easement Acquisition)<IconButton className={classes.gotoButton} title="Go To Long Term Programs for Conservation Easements Field" onClick={() => this.zoomToField(7, 2, "cea_monitoring_program")}><ViewIcon /></IconButton></>);
            }
            if (Easement) {
                if (!proposal.eao_selection_process) missing.push(<>Selection Process (Acquisition and Restoration -> Easement Acquisition (Other))<IconButton className={classes.gotoButton} title="Go To Selection Process Field" onClick={() => this.zoomToField(7, 3, "eao_selection_process")}><ViewIcon /></IconButton></>);
                if (!proposal.eao_resource_plans) missing.push(<>Natural Resource Plans (Acquisition and Restoration -> Easement Acquisition (Other))<IconButton className={classes.gotoButton} title="Go To Natural Resource Plans Field" onClick={() => this.zoomToField(7, 3, "eao_resource_plans")}><ViewIcon /></IconButton></>);
                if (!proposal.eao_management_plans)
                    missing.push(<>Restoration and Management Plans (Acquisition and Restoration -> Easement Acquisition (Other))<IconButton className={classes.gotoButton} title="Go To Restoration and Management Plans Field" onClick={() => this.zoomToField(7, 3, "eao_management_plans")}><ViewIcon /></IconButton></>);
                if (!proposal.eao_county_board_approval) missing.push(<>County Board Approval (Acquisition and Restoration -> Easement Acquisition (Other))<IconButton className={classes.gotoButton} title="Go To County Board Approval Field" onClick={() => this.zoomToField(7, 3, "eao_county_board_approval")}><ViewIcon /></IconButton></>);
            }
            if (Restoration) {
                if (!proposal.restoration_permanent_protection)
                    missing.push(<>Activities Will Occur On Land Permanently Protected (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Activities Will Occur On Land Permanently Protected Field" onClick={() => this.zoomToField(7, 4, "restoration_permanent_protection")}><ViewIcon /></IconButton></>);
                if (!proposal.restoration_management_plans) missing.push(<>Restoration and Management Plans (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Restoration and Management Plans Field" onClick={() => this.zoomToField(7, 4, "restoration_management_plans")}><ViewIcon /></IconButton></>);
                if (!proposal.restoration_nveeg)
                    missing.push(<>Native Vegetation Establishment and Enhancement Guidelines (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Native Vegetation Establishment and Enhancement Guidelines Field" onClick={() => this.zoomToField(7, 4, "restoration_nveeg")}><ViewIcon /></IconButton></>);
                if (!proposal.restoration_longterm_needs) missing.push(<>Long Term Maintenance Needs (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Long Term Maintenance Needs Field" onClick={() => this.zoomToField(7, 4, "restoration_longterm_needs")}><ViewIcon /></IconButton></>);
                if (!proposal.restoration_ccm) missing.push(<>Consideration for Conservation Corps of Minnesota (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Consideration for Conservation Corps of Minnesota Field" onClick={() => this.zoomToField(7, 4, "restoration_ccm")}><ViewIcon /></IconButton></>);
                if (!proposal.restoration_evaluations) missing.push(<>Evaluations On Completed Parcels (Acquisition and Restoration -> Restoration)<IconButton className={classes.gotoButton} title="Go To Evaluations On Completed Parcels Field" onClick={() => this.zoomToField(7, 4, "restoration_evaluations")}><ViewIcon /></IconButton></>);
            }
        }
        // 9
        if (!proposal.authorized) missing.push(<>Authorized to Submit (Review / Submit)</>);
        if (!proposal.major_contributors) missing.push(<>Individuals assisting in the completion (Review / Submit)</>);
        if (missing.length > 0) {
            return (
                <>
                    <span>The following fields are required. Please complete these before submitting.</span>
                    <ul>
                        {missing.map(m => (
                            <li>{m}</li>
                        ))}
                    </ul>
                </>
            );
        }
        return null;
    };

    applyOther = (value, element) => {
        const { ormProposalUpdateLocalOnly, proposal } = this.props;
        const { id } = proposal;
        ormProposalUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    handleClose = () => {
        const { history } = this.props;
        this.setState({ thanksDialog: false });
        history.push("/dashboard/");
    };

    render() {
        const { classes, proposal, settings, handleUnsavedFields, ActionMenu, authState, tab } = this.props;

        const workPlan = proposal.primary_phase === "Draft Work Plan" || proposal.primary_phase === "Final Work Plan";

        const results = this.checkResults();

        if (["Final Submitted", "Revisions Submitted"].includes(proposal.status) || workPlan) {
            return (
                <div>
                    <Grid container spacing={16}>
                        <Grid item xs={12} lg={10} xl={7}>
                            <Typography variant="h3" gutterBottom style={{ display: "inline-block" }}>
                                Review
                            </Typography>
                            {ActionMenu}
                        </Grid>
                        <Grid item xs={12} lg={10} xl={7}>
                            <Typography variant="h3">Your proposal has already been submitted. Please wait to hear back from LCCMR staff.</Typography>
                        </Grid>
                    </Grid>
                    <Dialog role="main" aria-label="Proposal Submitted" open={this.state.thanksDialog ? true : false} onClose={this.handleClose} disableBackdropClick={true}>
                        <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                            <Typography variant="h1" className={classes.flex}>Proposal Submitted</Typography>
                            <IconButton className={classes.closeButton} onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Thank you for your submission. A confirmation email has been sent. Please be sure to check your spam folder and add
                                noreply@lccmrprojectmgmt.leg.mn to your address book to ensure receipt of future notifications.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={this.handleClose}>
                                Return to Dashboard
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }

        if (authState.user.id !== proposal.user_id) {
            return (
                <Grid container spacing={16}>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h2" gutterBottom style={{ display: "inline-block" }}>
                            Completeness Check
                        </Typography>
                        {ActionMenu}
                        <Typography style={{ color: !results ? "darkgreen" : "darkred" }}>
                            {!results ? "All required fields are complete and you may proceed to submitting." : results}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={10} xl={7}>
                        <Typography variant="h3">The owner of the proposal must be the one to to submit the final proposal for LCCMR consideration.</Typography>
                    </Grid>
                </Grid>
            );
        }

        const today = new Date();
        var draft_closed = false;
        var final_closed = false;
        var withdrawal = false;
        const allowDraftSubmittal = settings && settings.funding_open && proposal.status === "Draft In Progress";

        if (settings && settings.funding_open && settings.draft_date && today > new Date(settings.draft_date)) {
            draft_closed = true;
        }

        if (settings && settings.final_date && today > new Date(settings.final_date)) {
            final_closed = true;
        }

        //withdrawal statuses
        if (proposal.secondary_status === "Withdrawal Requested" || proposal.secondary_status === "Withdrawn") {
            withdrawal = true;
        }

        // Allows Submission of Revisions
        if (proposal.status === "Revisions Needed" && today > new Date(settings.final_date)) {
            final_closed = false;
        }

        var btnName = "";
        switch (proposal.status) {
            case "Revisions Needed":
                btnName = "Save and Re-Submit Final Proposal Revisions for LCCMR Consideration";
                break;
            default:
                btnName = "Save and Submit Final Proposal for LCCMR Consideration";
        }

        return (
            <Form
                getApi={el => (this.form = el)}
                key={proposal.id}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={proposal.formData}
                formDidUpdate={() => handleUnsavedFields(true)}
                onSubmit={(values, fromStepper) => this.updateProposal(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="titleAction">Review</Typography>
                                {ActionMenu}
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Switch field="authorized" style={{ marginTop: 0 }} eventHandle={this.applyOther} label="">
                                    <HelpLabel
                                        inputLabel="Are you authorized to submit a proposal on behalf of the applicant organization and do you certify that the information included in this proposal is accurate?"
                                        showLabel={true}
                                        htmlText={true}
                                        helpText={
                                            tab.sub_tabs
                                                .find(tht => tht.tab_name === "Review / Submit")
                                                .tab_help_texts.find(tht => tht.label === "Are you authorized to submit a proposal on behalf of the applicant organization and do you certify that the information included in this proposal is accurate?").text
                                        }
                                    />
                                </Switch>
                                <TextField field="major_contributors" eventHandle={this.applyOther} label="Provide the name(s) and organization(s) of additional individuals assisting in the completion of this proposal." multiline fullWidth/>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="h3" gutterBottom>
                                    Completeness Check
                                </Typography>
                                <Typography style={{ color: !results ? "darkgreen" : "darkred" }}>
                                    {!results ? "All required fields are complete and you may proceed to submitting." : results}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Typography variant="h3" gutterBottom>
                                    Word Report
                                </Typography>
                                <Typography gutterBottom>
                                    Do you want to create a printable version of your application? Click on the button below to create a Word file for download.
                                    This can also be generated after the application is submitted from the dashboard or from the action menu.
                                </Typography>
                                <Button variant="contained" onClick={() => window.open("/reports/proposal/" + proposal.id)}>
                                    Print to Word
                                </Button>
                            </Grid>
                            <Grid item xs={12} lg={10} xl={7}>
                                <Grid container spacing={16}>
                                    <Grid item xs={4}>
                                        <Button
                                            disabled={proposal.status === "Draft Submitted"}
                                            variant="contained"
                                            type="submit"
                                            style={{ float: "left" }}
                                            onClick={() => this.setState({ previousStep: true })}
                                            fullWidth>
                                            Save and Return to Previous Step
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            disabled={proposal.status === "Draft Submitted"}
                                            variant="contained"
                                            type="submit"
                                            onClick={() => this.setState({ previousStep: false, updateStatus: false })}
                                            fullWidth>
                                            Save Draft and Return to Dashboard
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            fullWidth
                                            disabled={results || final_closed || withdrawal}
                                            onClick={() => this.setState({ previousStep: false, updateStatus: true })}>
                                            {btnName}
                                        </Button>
                                    </Grid>
                                        <Grid item xs={4}>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                fullWidth
                                                disabled={!allowDraftSubmittal || draft_closed || (allowDraftSubmittal && !proposal.authorized && !proposal.major_contributors)}
                                                onClick={() =>
                                                    this.setState({
                                                        draftSubmittal: true,
                                                        previousStep: false,
                                                        updateStatus: true
                                                    })
                                                }>
                                                Save and Submit for Draft Review
                                            </Button>
                                        </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

ReviewTab = connect(
    (state, ownProps) => ({
        authState: state.auth,
        activities: getActivities(state, ownProps),
        organizations: getOrganizations(state, ownProps),
        tab: getTab(state, ownProps),
        parcels: getParcels(state, ownProps),
        boardAttachment: getBoardAttachments(state, ownProps),
        financialAttachment: getFinancialAttachments(state, ownProps)
    }),
    {
        ...Proposal.actions,
        ...ProposalReview.actions,
        reloadListOfModels: reloadListOfModels
    }
)(ReviewTab);

export default withStyles(styles)(withRouter(ReviewTab));
