import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";
import DateFnsUtils from "@date-io/date-fns";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import Visibility from "@material-ui/icons/Visibility";

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import Select from "../common/Select";
import TablePaginationActionsWrapped from "../common/Paginator";
import RadioGroup from "../common/RadioGroup";
import WarningDialog from "../common/WarningDialog";
import { createSelector, reloadListOfModels } from "../common/orm";
import { Proposal, ProposalView } from "../proposal/models";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        },
        marginBottom: 20
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    select: {
        width: 200
    },
    cellWidth_2: {
        minWidth: "200px !important",
        width: "200px !important"
    }
});

const getYears = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});
const getWithdrawalRequests = createSelector(schema => {
    return schema.ProposalView.filter(
        x => x.secondary_status === "Withdrawn" || x.secondary_status === "Withdrawal Requested"
    )
        .orderBy("proposal_unique_id", "desc")
        .toModelArray()
        .map(x => {
            var name,
                final_category,
                full_name,
                funding_total,
                organization_name,
                pm_first_name,
                pm_last_name,
                status_date_label,
                summary,
                category,
                secondarySuffix;

            if (x.primary_phase === "Draft Work Plan" || x.primary_phase === "Final Work Plan") {
                var plan;
                if (x.primary_phase === "Draft Work Plan") {
                    plan = schema.DraftWorkPlanView.filter(view => view.proposal_record_id === x.id).first();
                } else {
                    plan = schema.FinalWorkPlanView.filter(view => view.proposal_record_id === x.id).first();
                }

                if (plan) {
                    name = plan.name;
                    summary = plan.summary;
                    category = plan.funding_category;
                    funding_total = x.recommended_amount;
                    organization_name = plan.organization_name;
                    pm_first_name = plan ? plan.pm_first_name : "";
                    pm_last_name = plan ? plan.pm_last_name : "";
                    status_date_label = plan.status_date_label;
                    if (pm_first_name) full_name = pm_first_name;
                    if (pm_last_name) full_name += " " + pm_last_name;

                    category = schema.FundingCategory.filter(
                        c => c.category_name === plan.funding_category && c.year_label === plan.ml_year
                    ).first();

                    secondarySuffix = null;
                    if (plan.funding_category === "Small Projects") {
                        secondarySuffix = schema.FundingCategory.filter(
                            fc => fc.category_name === plan.secondary_funding_category && fc.year_label === plan.ml_year
                        ).first();
                    }

                    final_category = category && category.suffix ? category.suffix + ". " : "";
                    final_category += plan.funding_category ? plan.funding_category : "";

                    final_category +=
                        plan.funding_category === "Small Projects" && plan.secondary_funding_category
                            ? " / " +
                              (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                              plan.secondary_funding_category
                            : "";

                    var org = schema.Organization.filter(x => x.id === plan.organization_id).first();
                    organization_name = org ? org.organization_name : "";
                }
            } else {
                name = x.name;
                summary = x.summary;

                category = schema.FundingCategory.filter(
                    c =>
                        c.category_name ===
                            (x.final_funding_category ? x.final_funding_category : x.funding_category) &&
                        c.year_label === x.ml_year
                ).first();

                secondarySuffix = null;
                if (x.funding_category === "Small Projects" && !x.final_funding_category) {
                    secondarySuffix = schema.FundingCategory.filter(
                        c => c.category_name === x.secondary_funding_category && c.year_label === x.ml_year
                    ).first();
                } else if (x.final_funding_category && x.final_funding_category === "Small Projects") {
                    secondarySuffix = schema.FundingCategory.filter(
                        c => c.category_name === c.final_secondary_funding_category && c.year_label === x.ml_year
                    ).first();
                }

                final_category = category && category.suffix ? category.suffix + ". " : "";
                final_category += x.final_funding_category
                    ? x.final_funding_category
                    : x.funding_category
                    ? x.funding_category
                    : "";

                if (x.final_funding_category) {
                    final_category +=
                        x.final_funding_category === "Small Projects" && x.final_secondary_funding_category
                            ? " / " +
                              (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                              x.final_secondary_funding_category
                            : "";
                } else {
                    final_category +=
                        x.funding_category === "Small Projects" && x.secondary_funding_category
                            ? " / " +
                              (secondarySuffix ? secondarySuffix.suffix + ". " : "") +
                              x.secondary_funding_category
                            : "";
                }

                funding_total = x.funding_total ? x.funding_total : 0;
                status_date_label = x.status_date_label;
                org = schema.Organization.filter(z => z.id === x.ref.organization_id).first();
                organization_name = org ? org.organization_name : "";

                pm_first_name = x ? x.pm_first_name : "";
                pm_last_name = x ? x.pm_last_name : "";
                if (pm_first_name) full_name = pm_first_name;
                if (pm_last_name) full_name += " " + pm_last_name;
            }

            return {
                id: x.id,
                full_name,
                user_id: x.user_id,
                ml_year: x.ml_year,
                proposal_unique_id: x.proposal_unique_id,
                primary_phase: x.primary_phase,
                recommended_amount: x.recommended_amount,
                delegated_by: x.delegated_by,
                name,
                withdrawal_reason: x.withdrawal_reason,
                pm_first_name,
                pm_last_name,
                final_category,
                summary,
                prev_status: x.prev_status,
                funding_total,
                status: x.status,
                status_date_label,
                secondary_status: x.secondary_status,
                organization_name
            };
        });
});

const proposalData = [
    { id: "actions", numeric: false, label: "Actions" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "summary", numeric: false, label: "30 Word Summary", allowSort: true },
    { id: "final_category", numeric: false, label: "Category", allowSort: true },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true },
    { id: "pm_last_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "$ Requested", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true },
    { id: "secondary_status", numeric: false, label: "Legislative Status", allowSort: true },
    { id: "withdrawal_reason", numeric: false, label: "Withdrawal Reason", allowSort: true }
];

class Users extends Component {
    state = {
        page: 0,
        rowsPerPage: 100,
        activeOrder: "desc",
        activeOrderBy: "",
        order: "desc",
        orderBy: "",
        proposalAcceptObj: null,
        proposalDeclineObj: null,
        year: "All",
        filters: "All"
    };

    componentDidMount() {
        document.title = "Manage Proposal Withdrawals - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
        
        if (authState && authState.user) {
            this.props.reloadListOfModels(["ProposalView"]);
        }
    }

    getDateTimeStamp = () => {
        const date = new Date();
        const dateFns = new DateFnsUtils();
        return dateFns.format(date, "yyyy-MM-dd HH:mm:ss.SSSSSS");
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    accept = obj => {
        const { ormProposalViewUpdateLocalOnly, ormProposalUpdate } = this.props;
        ormProposalUpdate({
            id: obj.id,
            secondary_status: "Withdrawn",
            secondary_date: this.getDateTimeStamp()
        });
        ormProposalViewUpdateLocalOnly({
            id: obj.id,
            secondary_status: "Withdrawn",
            secondary_date: this.getDateTimeStamp()
        });
    };

    decline = obj => {
        const { ormProposalViewUpdateLocalOnly, ormProposalUpdate } = this.props;
        ormProposalUpdate({
            id: obj.id,
            secondary_status: obj.prev_status,
            secondary_date: this.getDateTimeStamp()
        });
        ormProposalViewUpdateLocalOnly({
            id: obj.id,
            secondary_status: obj.prev_status,
            secondary_date: this.getDateTimeStamp()
        });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        this.props.proposals.sort(function(a, b) {
            var date = proposalData.find(cD => cD.id === orderBy).date;
            var numeric = proposalData.find(cD => cD.id === orderBy).numeric;
            var bool = proposalData.find(cD => cD.id === orderBy).bool;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (bool) {
                if (order === "desc") return b[orderBy] - a[orderBy];
                else return a[orderBy] - b[orderBy];
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    getViewButton = (n, classes) => {
        return (
            <Tooltip
                title={`View ${n.status.includes("Work Plan") ? "Work Plan" : "Proposal"} ${n.proposal_unique_id}`}>
                <Button
                    color="primary"
                    className={classes.deleteWidth}
                    aria-label={`View ${n.status.includes("Work Plan") ? "Work Plan" : "Proposal"} ${
                        n.proposal_unique_id
                    }`}
                    onClick={() =>
                        window.open(
                            `#/${
                                n.status.includes("Work Plan")
                                    ? n.status.includes("Final")
                                        ? "final/workplan"
                                        : "draft/workplan"
                                    : "proposal"
                            }/${n.id}`
                        )
                    }>
                    <Visibility color="primary" />
                </Button>
            </Tooltip>
        );
    };

    render() {
        const { classes, rfpYears, proposals } = this.props;
        const { filters, page, rowsPerPage, order, orderBy, proposalAcceptObj, proposalDeclineObj, year } = this.state;

        const years = rfpYears.map(x => ({
            label: x.year,
            value: x.year
        }));

        var filtered = proposals;

        if (year && year !== "All") {
            filtered = proposals.filter(x => x.ml_year === year);
        }

        if (filters !== "All") {
            filtered = filtered.filter(
                x => x.secondary_status === (filters === "Requested" ? "Withdrawal Requested" : "Withdrawn")
            );
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Proposals Requesting Withdrawal" admin_page />
                    <Grid item xs={12}>
                        <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={{ rfp_year: "All" }}>
                            {formApi => (
                                <form>
                                    <Select
                                        className={classes.select}
                                        field="rfp_year"
                                        label="RFP Year/M.L Year"
                                        options={[{ label: "-- All --", value: "All" }, ...years]}
                                        eventHandle={val => {
                                            this.setState({ year: val });
                                        }}
                                    />
                                </form>
                            )}
                        </Form>
                        <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={{ filters: "All" }}>
                            {formApi => (
                                <form style={{ display: "inline-flex" }} onSubmit={formApi.submitForm}>
                                    <RadioGroup
                                        field="filters"
                                        name="filters"
                                        eventHandle={val => this.setState({ filters: val })}
                                        fullWidth
                                        options={[
                                            { label: "All", value: "All" },
                                            { label: "Requested", value: "Requested" },
                                            { label: "Withdrawn", value: "Withdrawn" }
                                        ]}
                                        alignment={true}
                                    />
                                </form>
                            )}
                        </Form>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                columnData={proposalData}
                                stickyHeader
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                            />
                            <TableBody>
                                {filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                    return (
                                        <TableRow key={n.id + "term"}>
                                            <CustomTableCell className={classes.nowrap}>
                                                {this.getViewButton(n, classes)}
                                                {n.secondary_status !== "Withdrawn" && (
                                                    <>
                                                        <Tooltip title="Accept Request">
                                                            <Button
                                                                color="primary"
                                                                className={classes.deleteWidth}
                                                                aria-label="Accept Request"
                                                                onClick={() =>
                                                                    this.setState({ proposalAcceptObj: n })
                                                                }>
                                                                <CheckIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Decline Request">
                                                            <Button
                                                                color="primary"
                                                                className={classes.deleteWidth}
                                                                aria-label="Decline Request"
                                                                onClick={() =>
                                                                    this.setState({ proposalDeclineObj: n })
                                                                }>
                                                                <BlockIcon color="primary" />
                                                            </Button>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </CustomTableCell>
                                            <CustomTableCell className={classes.nowrap}>
                                                {n.proposal_unique_id}
                                            </CustomTableCell>
                                            <CustomTableCell>{n.name}</CustomTableCell>
                                            <CustomTableCell className={classes.cellWidth_2}>
                                                {n.summary}
                                            </CustomTableCell>
                                            <CustomTableCell>{n.final_category}</CustomTableCell>
                                            <CustomTableCell>{n.organization_name}</CustomTableCell>
                                            <CustomTableCell>
                                                {n.pm_first_name} {n.pm_last_name}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                ${n.funding_total ? n.funding_total.toLocaleString() : 0}
                                            </CustomTableCell>
                                            <CustomTableCell>{n.status}</CustomTableCell>
                                            <CustomTableCell>{n.secondary_status}</CustomTableCell>
                                            <CustomTableCell>{n.withdrawal_reason}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {filtered.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell
                                            colSpan={proposalData.length}
                                            className={classes.centerAlign}>
                                            <caption style={{display:"inline"}}>No Proposals Found</caption>
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <Table>
                            {filtered.length > 25 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={proposalData.length}
                                            count={filtered.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </Grid>
                </Grid>
                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={() => {
                        this.accept(proposalAcceptObj);
                        this.setState({ proposalAcceptObj: null });
                    }}
                    cancelText={"No"}
                    cancelAction={() => this.setState({ proposalAcceptObj: null })}
                    open={proposalAcceptObj}
                    title={`Accept Proposal(${
                        proposalAcceptObj ? proposalAcceptObj.proposal_unique_id : null
                    }) Withdrawal Request`}
                    text={"Are you sure you want to accept?"}
                />
                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={() => {
                        this.decline(proposalDeclineObj);
                        this.setState({ proposalDeclineObj: null });
                    }}
                    cancelText={"No"}
                    cancelAction={() => this.setState({ proposalDeclineObj: null })}
                    open={proposalDeclineObj}
                    title={`Decline Proposal(${
                        proposalDeclineObj ? proposalDeclineObj.proposal_unique_id : null
                    }) Withdrawal Request`}
                    text={"Are you sure you want to decline?"}
                />
            </AppContainer>
        );
    }
}

Users = connect(
    (state, ownProps) => ({
        authState: state.auth,
        proposals: getWithdrawalRequests(state),
        rfpYears: getYears(state, ownProps)
    }),
    {
        ...Proposal.actions,
        ...ProposalView.actions,
        reloadListOfModels: reloadListOfModels
    }
)(Users);

export default withStyles(styles)(withRouter(Users));
