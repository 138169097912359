import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import TablePaginationActionsWrapped from "../common/Paginator";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import HelpLabel from "../common/HelpLabel";

import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Visibility from "@material-ui/icons/Visibility";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Switch from "@material-ui/core/Switch";
import TextField from "../common/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { createSelector } from "../common/orm";
import { Organization, User, FiscalAgent } from "../home/models";
import { ProposalActivityView, ProposalMilestoneView, ProposalCollaboratorView } from "../proposal/models";
import {
    DraftProposalActivityView,
    FinalProposalActivityView,
    DraftProposalMilestoneView,
    FinalProposalMilestoneView,
    DraftProposalCollaboratorView,
    FinalProposalCollaboratorView
} from "../workplan/models";

const styles = theme => ({
    expandOn: {
        display: "block"
    },
    expandOff: {
        display: "none"
    },
    inlineSwitch: {
        display: "inline"
    },
    block: {
        display: "block"
    },
    buttonFloatRight: {
        float: "right",
        marginRight: 20
    },
    titleTall: {
        lineHeight: 2.5
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted"
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    chip: {
        marginTop: 4,
        marginRight: 8
    },
    button: {
        marginTop: "20px",
        marginRight: "20px"
    },
    buttonLeftMargin: {
        marginLeft: "30px"
    },
    disabledButton: {
        color: "#cccccc"
    },
    filterArea: {
        marginTop: 10,
        borderRadius: 10,
        border: "1px dashed #ccc",
        maxHeight: "400px",
        backgroundColor: "#eee"
    },
    formControlArea: {
        marginTop: 15
    },
    selectcolumn3: {
        width: "32%",
        [theme.breakpoints.down("sm")]: {
            width: "49%"
        }
    },
    selectcolumn50: {
        width: "49%",
        [theme.breakpoints.down("xs")]: {
            width: "90%"
        }
    },
    flowButton: {
        marginTop: 8,
        [theme.breakpoints.down("sm")]: {
            margin: 15
        }
    },
    fundinglabel: {
        display: "inline-flex",
        padding: 12
    },
    smallradio: {
        padding: "3px 3px 3px 12px"
    },
    separateRadio: {
        borderLeft: "1px #ddd solid",
        marginLeft: "0px"
    },
    selectbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "4px 5px 4px 5px",
        width: "95%",
        maxWidth: "700px"
    },
    textfieldbox: {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: 5,
        padding: "8px",
        fontSize: "16px"
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    scrollTable: {
        position: "relative",
        overflow: "auto",
        width: "100%",
        height: 500
    },
    tablePaginator: {
        float: "right",
        width: "99%",
        display: "table",
        padding: "20px 0"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    selectwidth: {
        maxWidth: 20,
        width: 20
    },
    idwidth: {
        maxWidth: 65,
        width: 65,
        padding: "8px 4px"
    },
    small: {
        minWidth: 150,
        width: 150
    },
    smallcurrency: {
        minWidth: 70,
        width: 70,
        textAlign: "right"
    },
    middle: {
        minWidth: 300,
        width: 300
    },
    wide: {
        minWidth: 800,
        width: 800
    }
});

const getProposals = createSelector(schema => {
    return schema.ProposalView.all()
        .orderBy("proposal_unique_id", "desc")
        .toModelArray();
});
const getOrganizations = Organization.selectAll();
const getUsers = User.selectAll();
const getFiscalAgents = FiscalAgent.selectAll();

const getDraftWorkPlans = createSelector(schema => {
    return schema.DraftWorkPlanView.all()
        .orderBy("proposal_unique_id", "desc")
        .toModelArray();
});

const getFinalWorkPlans = createSelector(schema => {
    return schema.FinalWorkPlanView.filter()
        .orderBy("proposal_unique_id", "desc")
        .toModelArray();
});

const getProposalCollaborators = createSelector(schema => {
    return schema.ProposalCollaboratorView.all().toModelArray();
});

const getProposalActivities = createSelector(schema => {
    return schema.ProposalActivityView.all()
        .orderBy("id")
        .toModelArray();
});
const getProposalMilestones = createSelector(schema => {
    return schema.ProposalMilestoneView.all()
        .orderBy("activity_id")
        .toModelArray();
});
const getDraftCollaborators = createSelector(schema => {
    return schema.DraftProposalCollaboratorView.all().toModelArray();
});
const getDraftActivities = createSelector(schema => {
    return schema.DraftProposalActivityView.all()
        .orderBy("id")
        .toModelArray();
});
const getDraftMilestones = createSelector(schema => {
    return schema.DraftProposalMilestoneView.all()
        .orderBy("activity_id")
        .toModelArray();
});
const getFinalCollaborators = createSelector(schema => {
    return schema.FinalProposalCollaboratorView.all().toModelArray();
});
const getFinalActivities = createSelector(schema => {
    return schema.FinalProposalActivityView.all()
        .orderBy("id")
        .toModelArray();
});
const getFinalMilestones = createSelector(schema => {
    return schema.FinalProposalMilestoneView.all()
        .orderBy("activity_id")
        .toModelArray();
});

const columnDataProposal = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "select", numeric: false, label: "Select" },
    { id: "proposal_unique_id", numeric: false, label: "ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "funding_category", numeric: false, label: "Category", allowSort: true },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true },
    { id: "pm_first_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Requested Funding", allowSort: true },
    { id: "recommended_amount", numeric: true, label: "Amount Recommended", allowSort: true },
    { id: "appropriation_amount", numeric: true, label: "Amount Appropriated", allowSort: true },
    { id: "status", numeric: false, label: "Project Status", allowSort: true },
    { id: "secondary_status", numeric: false, label: "LCCMR / Legislative Status", allowSort: true },
    { id: "description", numeric: false, label: "Short Description" }
];

var g_org_map = null;
var g_lead_map = null;
var g_proposal_map = null;

var g_all_proposals = [];
var g_all_active = [];
var g_all_completed = [];
var g_all_draft = [];
var g_all_final = [];

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 100,
            masterFilter: ((props && props.authState && props.authState.user && props.authState.user.role === "Member") || props.location.hash === "#member") ? "Active": "Proposals",
            valid_years: [],
            proposal_year: [],
            valid_pms: [],
            proposal_pms: [],
            valid_orgs: [],
            proposal_orgs: [],
            valid_locs: ["Statewide", "Metro", "Central", "NW", "NE", "SW", "SE", "City", "County", "Watershed", "Ecological Subsection"],
            textSearch: "",
            textCache: "",
            textExact: false,
            textOptions: "title",
            valid_lscales: [],
            proposal_lscales: [],
            valid_lareas: [],
            proposal_lareas: [],
            valid_iscales: [],
            proposal_iscales: [],
            valid_iareas: [],
            proposal_iareas: [],
            valid_ioccur: ["During the Project", "In the Future"],
            proposal_ioccur: [],
            valid_features: [
                "Restoration",
                "Research",
                "Use of Classified Staff",
                "Conservation Easement Acquisition",
                "Patent or Royalty",
                "Capital Expenditure",
                "Easement (Other) Acquisition",
                "Revenue or Net Income",
                "Gen. Ineligible Costs",
                "Fee Acquisition",
                "Fiscal Agent",
                "Stewardship Funds",
                "Design, Construction, or Renovation"
            ],
            proposal_features: [],
            valid_leads: [],
            proposal_leads: [],
            valid_fundingtype: ["Requested", "Recommended", "Appropriated"],
            proposal_fundingtype: [],
            fund_min: null,
            fund_max: null,
            exportOption: "SelectedTable",
            selected: [],
            expanded: false,
            basic_tags: ((props && props.authState && props.authState.user && props.authState.user.role === "Member") || props.location.hash === "#member") ? ["Master: Active"]: ["Master: Proposals"],
            expand_tags: [],
            order: "desc",
            orderBy: "proposal_unique_id"
        };
    }
    typingTimer = null;

    build_org_map(all_orgs) {
        var map = {};
        for (var x of all_orgs) {
            if (x.department === null || x.department === "") {
                Object.defineProperty(map, x.id, { value: x.organization_name });
            } else {
                Object.defineProperty(map, x.id, { value: x.organization_name + ", " + x.department });
            }
        }
        return map;
    }

    build_lead_map(all_leads) {
        var map = {};
        for (var x of all_leads) {
            Object.defineProperty(map, x.id, { value: x.first_name + " " + x.last_name });
        }
        return map;
    }

    build_proposal_map(proposals) {
        var map = {};
        proposals.forEach((p, i) => {
            map[p.id] = i;
        });
        return map;
    }

    build_org_choice(p, org_choice) {
        var org_id = p.organization_id;
        if (p.organization_id === "" || p.organization_id === null) {
            return;
        }
        if (!org_choice.includes(org_id)) {
            org_choice.push(org_id);
        }
    }

    build_pm_choice(p, pm_choice) {
        var name = p.pm_first_name + " " + p.pm_last_name;
        var f_name = p.pm_first_name;
        var l_name = p.pm_last_name;
        if (p.pm_first_name === null || p.pm_last_name === null || p.pm_first_name === "" || p.pm_last_name === "") {
            return;
        }
        if (!Object.getOwnPropertyNames(pm_choice).includes(name)) {
            Object.defineProperty(pm_choice, name, {
                value: { full: name, first: f_name, last: l_name }
            });
        }
    }

    build_year_choice(p, year_choice) {
        var year = "-- Field is Empty --";
        if (p.ml_year !== "" && p.ml_year !== null) {
            year = p.ml_year;
        } else {
            return;
        }
        if (!year_choice.includes(year)) {
            year_choice.push(year);
        }
        year_choice.sort();
    }

    build_loc_scale_choice(p, loc_scale_choice) {
        var scale = "-- Field is Empty --";
        if (p.location_scale !== "" && p.location_scale !== null) {
            scale = p.location_scale;
        } else {
            return;
        }
        if (!loc_scale_choice.includes(scale)) {
            loc_scale_choice.push(scale);
        }
    }

    build_loc_areas_choice(p, loc_areas_choice) {
        for (var cur_area of p.location_areas) {
            var area = "-- Field is Empty --";
            if (cur_area !== "" && cur_area !== null) {
                area = cur_area;
            } else {
                continue;
            }
            if (!loc_areas_choice.includes(area)) {
                loc_areas_choice.push(area);
            }
        }
    }

    build_imp_scale_choice(p, imp_scale_choice) {
        var scale = "-- Field is Empty --";
        if (p.impact_scale !== "" && p.impact_scale !== null) {
            scale = p.impact_scale;
        } else {
            return;
        }
        if (!imp_scale_choice.includes(scale)) {
            imp_scale_choice.push(scale);
        }
    }

    build_imp_areas_choice(p, imp_areas_choice) {
        for (var cur_area of p.impact_areas) {
            var area = "-- Field is Empty --";
            if (cur_area !== "" && cur_area !== null) {
                area = cur_area;
            } else {
                continue;
            }
            if (!imp_areas_choice.includes(area)) {
                imp_areas_choice.push(area);
            }
        }
    }

    build_lead_choice(p, lead_choice) {
        var lead_id = p.staff_lead_id;
        if (lead_id === "" || lead_id === null) {
            return;
        }
        if (!lead_choice.includes(lead_id)) {
            lead_choice.push(lead_id);
        }
    }

    org_find(pr) {
        const { orgs } = this.props;
        var org = orgs.find(z => z.id === pr.organization_id);
        pr.organization_name = org ? org.organization_name + (org.department && org.deparment !== "" ? " / " + org.department : "") : null;
    }

    extract_proposal(wp, pr) {
        wp.prid = pr.id;

        wp.status = pr.status;
        wp.primary_phase = pr.primary_phase;
        wp.recommended_amount = pr.recommended_amount;
        wp.recommended_for_funding = pr.recommended_for_funding;
        wp.secondary_date = pr.secondary_date;
        wp.secondary_status = pr.secondary_status;
        wp.appropriation_amount = pr.appropriation_amount;
        wp.staff_lead_id = pr.staff_lead_id;

        wp.final_funding_category = pr.final_funding_category;
        wp.final_impact_areas = pr.final_impact_areas;
        wp.final_impact_occur = pr.final_impact_occur;
        wp.final_impact_scale = pr.final_impact_scale;
        wp.final_location_areas = pr.final_location_areas;
        wp.final_location_scale = pr.final_location_scale;
        wp.final_secondary_funding_category = pr.final_secondary_funding_category;
    }

    add_foreign_assoc = (p, masterFilter) => {
        p.assoc_text = "";
        for (var agt of this.props.agents) {
            if (agt.id === p.fiscal_agent_id) {
                p.assoc_text += " FA_ " + agt.name;
                break;
            }
        }

        var collab_list = this.props.collabs;
        var activ_list = this.props.activities;
        var miles_list = this.props.milestones;

        if (masterFilter.includes("Draft Work")) {
            collab_list = this.props.draftcollabs;
            activ_list = this.props.draftactivities;
            miles_list = this.props.draftmilestones;
        }
        
        if (masterFilter.includes("Final Work") || masterFilter.includes("Active") || masterFilter.includes("Completed")) {
            collab_list = this.props.finalcollabs;
            activ_list = this.props.finalactivities;
            miles_list = this.props.finalmilestones;
        }

        for (var clb of collab_list) {
            if (clb.proposal_id === p.id) {
                p.assoc_text += " CLB_  " + clb.name + " " + clb.organization + " " + clb.role;
            }
        }

        for (var act of activ_list) {
            if (act.proposal_id === p.id) {
                p.assoc_text += " ACT_ " + act.title + " " + act.description;
                for (var mil of miles_list) {
                    if (mil.activity_id === act.id) {
                        p.assoc_text += " MIL_ " + mil.description;
                    }
                }
            }
        }
    };

    componentDidMount() {
        document.title = "Administration - Search";
        const {
            authState,
            history,
            ormProposalActivityViewReload,
            ormDraftProposalActivityViewReload,
            ormFinalProposalActivityViewReload,
            ormProposalMilestoneViewReload,
            ormDraftProposalMilestoneViewReload,
            ormFinalProposalMilestoneViewReload,
            ormProposalCollaboratorViewReload,
            ormDraftProposalCollaboratorViewReload,
            ormFinalProposalCollaboratorViewReload
        } = this.props;

        ormProposalActivityViewReload(true);
        ormDraftProposalActivityViewReload(true);
        ormFinalProposalActivityViewReload(true);
        ormProposalMilestoneViewReload(true);
        ormDraftProposalMilestoneViewReload(true);
        ormFinalProposalMilestoneViewReload(true);
        ormProposalCollaboratorViewReload(true);
        ormDraftProposalCollaboratorViewReload(true);
        ormFinalProposalCollaboratorViewReload(true);
        if (authState && authState.user && authState.user.role !== "Staff" && authState.user.role !== "Member") {
            history.push("/dashboard");
        }
    }

    componentDidUpdate() {
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff" && authState.user.role !== "Member") {
            history.push("/dashboard");
        }
    }

    removeTags = (text, tag_list) => {
        var temp_list = [];
        tag_list.map(x => {
            if (!x.includes(text)) {
                temp_list.push(x);
            }
            return 1;
        });
        return temp_list;
    };

    handleMasterFilter = event => {
        var newFilter = event.target.value;
        this.setState({
            masterFilter: newFilter,
            basic_tags: ["Master: " + newFilter],
            valid_orgs: [],
            proposal_year: [],
            proposal_pms: [],
            proposal_orgs: [],
            proposal_lscales: [],
            proposal_lareas: [],
            proposal_iscales: [],
            proposal_iareas: [],
            proposal_ioccur: [],
            proposal_features: [],
            proposal_leads: [],
            proposal_fundingtype: [],
            fund_min: null,
            fund_max: null,
            selected: [],
            expand_tags: []
        });
    };

    changeProposalYear = year_list => {
        var cur_year = this.state.proposal_year;
        var tag_list = this.removeTags("Year: ", [...this.state.basic_tags]);
        var posAll = year_list.indexOf("All");
        if (posAll === -1 && cur_year.indexOf("All") > -1) {
            year_list = [];
        }
        if (posAll > -1 && year_list.length < this.state.proposal_year.length) {
            year_list.splice(posAll, 1);
            posAll = -1;
        }
        if (posAll > -1 && this.state.valid_years.length > 0) {
            year_list = ["All", ...this.state.valid_years];
        }
        year_list.map(tag => {
            tag_list.push("Year: " + tag);
            return 1;
        });
        this.setState({ proposal_year: year_list, basic_tags: tag_list });
    };

    handleChangeProposalYear = event => {
        this.changeProposalYear(event.target.value);
    };

    handleChangeProposalPM = event => {
        var tag_list = this.removeTags("PM: ", [...this.state.basic_tags]);
        if (event.target.value.includes("Clear")) {
            event.target.value = [];
        }
        event.target.value.map(tag => {
            tag_list.push("PM: " + tag);
            return 1;
        });
        this.setState({ proposal_pms: event.target.value, basic_tags: tag_list });
    };

    handleChangeProposalOrg = event => {
        var tag_list = this.removeTags("Org: ", [...this.state.basic_tags]);
        if (event.target.value.includes("Clear")) {
            event.target.value = [];
        }
        event.target.value.map(tag => {
            tag_list.push("Org: " + g_org_map[tag]);
            return 1;
        });
        this.setState({ proposal_orgs: event.target.value, basic_tags: tag_list });
    };

    handleChangeProposalLead = event => {
        var tag_list = this.removeTags("Staff Lead: ", [...this.state.expand_tags]);
        if (event.target.value.includes("Clear")) {
            event.target.value = [];
        }
        event.target.value.map(tag => {
            tag_list.push("Staff Lead: " + g_lead_map[tag]);
            return 1;
        });
        this.setState({ proposal_leads: event.target.value, expand_tags: tag_list });
    };

    handleChangeLocScale = event => {
        var tag_list = this.removeTags("Location Scale: ", [...this.state.expand_tags]);
        if (event.target.value.includes("Clear")) {
            event.target.value = [];
        }
        event.target.value.map(tag => {
            tag_list.push("Location Scale: " + tag);
            return 1;
        });
        this.setState({ proposal_lscales: event.target.value, expand_tags: tag_list });
    };

    handleChangeLocArea = event => {
        var tag_list = this.removeTags("Location Area: ", [...this.state.expand_tags]);
        if (event.target.value.includes("Clear")) {
            event.target.value = [];
        }
        event.target.value.map(tag => {
            tag_list.push("Location Area: " + tag);
            return 1;
        });
        this.setState({ proposal_lareas: event.target.value, expand_tags: tag_list });
    };

    handleChangeImpScale = event => {
        var tag_list = this.removeTags("Impact Scale: ", [...this.state.expand_tags]);
        if (event.target.value.includes("Clear")) {
            event.target.value = [];
        }
        event.target.value.map(tag => {
            tag_list.push("Impact Scale: " + tag);
            return 1;
        });
        this.setState({ proposal_iscales: event.target.value, expand_tags: tag_list });
    };

    handleChangeImpArea = event => {
        var tag_list = this.removeTags("Impact Area: ", [...this.state.expand_tags]);
        if (event.target.value.includes("Clear")) {
            event.target.value = [];
        }
        event.target.value.map(tag => {
            tag_list.push("Impact Area: " + tag);
            return 1;
        });
        this.setState({ proposal_iareas: event.target.value, expand_tags: tag_list });
    };

    handleBasicChipDelete = chipToDelete => () => {
        const { authState } = this.props;
        var chipArr = chipToDelete.split(": ");
        var category = chipArr[0];
        var tag = chipArr[1];
        var cur_list = [];
        var tag_list = [...this.state.basic_tags];
        tag_list.splice(tag_list.indexOf(chipToDelete), 1);

        switch (category) {
            case "Year":
                cur_list = [...this.state.proposal_year];
                cur_list.splice(cur_list.indexOf(tag), 1);
                this.changeProposalYear(cur_list);
                break;
            case "PM":
                cur_list = [...this.state.proposal_pms];
                cur_list.splice(cur_list.indexOf(tag), 1);
                this.setState({ proposal_pms: cur_list, basic_tags: tag_list });
                break;
            case "Org":
                cur_list = [...this.state.proposal_orgs];
                var tagId = Object.keys(g_org_map).find(key => g_org_map[key] === tag);
                cur_list.splice(cur_list.indexOf(tagId), 1);
                this.setState({ proposal_orgs: cur_list, basic_tags: tag_list });
                break;
            case "Master":
                if (authState.user.role === "Member") {
                    tag_list.push("Master: Active");
                    this.setState({ masterFilter: "Active", basic_tags: tag_list });
                } else {
                    tag_list.push("Master: Proposals");
                    this.setState({ masterFilter: "Proposals", basic_tags: tag_list });
                }
                break;
            default:
                break;
        }
    };

    handleExpandChipDelete = chipToDelete => () => {
        var chipArr = chipToDelete.split(": ");
        var category = chipArr[0];
        var tag = chipArr[1];
        var cur_list = [];
        var tag_list = [...this.state.expand_tags];
        tag_list.splice(tag_list.indexOf(chipToDelete), 1);

        switch (category) {
            case "Location Scale":
                cur_list = [...this.state.proposal_lscales];
                cur_list.splice(cur_list.indexOf(tag), 1);
                this.setState({ proposal_lscales: cur_list, expand_tags: tag_list });
                break;
            case "Location Area":
                cur_list = [...this.state.proposal_lareas];
                cur_list.splice(cur_list.indexOf(tag), 1);
                this.setState({ proposal_lareas: cur_list, expand_tags: tag_list });
                break;
            case "Impact Scale":
                cur_list = [...this.state.proposal_iscales];
                cur_list.splice(cur_list.indexOf(tag), 1);
                this.setState({ proposal_iscales: cur_list, expand_tags: tag_list });
                break;
            case "Impact Area":
                cur_list = [...this.state.proposal_iareas];
                cur_list.splice(cur_list.indexOf(tag), 1);
                this.setState({ proposal_iareas: cur_list, expand_tags: tag_list });
                break;
            case "Impact Occur":
                cur_list = [...this.state.proposal_ioccur];
                cur_list.splice(cur_list.indexOf(tag), 1);
                this.setState({ proposal_ioccur: cur_list, expand_tags: tag_list });
                break;
            case "Feature":
                cur_list = [...this.state.proposal_features];
                cur_list.splice(cur_list.indexOf(tag), 1);
                this.setState({ proposal_features: cur_list, expand_tags: tag_list });
                break;
            case "Staff Lead":
                cur_list = [...this.state.proposal_leads];
                var tagId = Object.keys(g_lead_map).find(key => g_lead_map[key] === tag);
                cur_list.splice(cur_list.indexOf(tagId), 1);
                this.setState({ proposal_leads: cur_list, expand_tags: tag_list });
                break;
            case "Funding Min":
                this.setState({ fund_min: null, expand_tags: tag_list });
                break;
            case "Funding Max":
                this.setState({ fund_max: null, expand_tags: tag_list });
                break;
            default:
                break;
        }
    };

    handleTextOptions = event => {
        this.setState({ textOptions: event.target.value });
    };

    handleTextField = (val) => {
        const _this = this;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function() {
            _this.setState({ textCache: val });
        }, 300);
    };

    handleTextSearch = () => {
        var masterFilter = this.state.masterFilter;
        var list_type = null;

        switch (masterFilter) {
            case "Proposals":
                list_type = g_all_proposals;
                break;
            case "Draft Work Plans":
                list_type = g_all_draft;
                break;
            case "Final Work Plans":
                list_type = g_all_final;
                break;
            case "Active":
                list_type = g_all_active;
                break;
            case "Completed":
                list_type = g_all_completed;
                break;
            default:
                list_type = g_all_proposals;
                break;
        }
        if (!list_type[0].assoc_text) {
            for (var pr of list_type) {
                this.add_foreign_assoc(pr, masterFilter);
            }
        }

        this.setState({ textSearch: this.state.textCache });
    };

    handleExact = () => {
        this.setState({ textExact: !this.state.textExact });
    };

    handleClearText = () => {
        this.setState({ textCache: "", textSearch: "" });
        this.form.resetAll();
    };

    handleExportOptions = event => {
        this.setState({ exportOption: event.target.value });
    };

    handleIOccur = event => {
        var tag_list = this.removeTags("Impact Occur: ", [...this.state.expand_tags]);
        var occur_list = [...this.state.proposal_ioccur];
        var pos = occur_list.indexOf(event.target.value);
        if (pos >= 0) {
            occur_list.splice(pos, 1);
        } else {
            occur_list.push(event.target.value);
        }
        occur_list.map(tag => {
            tag_list.push("Impact Occur: " + tag);
            return 1;
        });
        this.setState({ proposal_ioccur: occur_list, expand_tags: tag_list });
    };

    findIntersection(master_list, sub_list) {
        return master_list.filter(y => sub_list.includes(y));
    }

    getRenderString(valid_list, proposal_list, map = null) {
        var renderString = "Multiple";
        if (proposal_list.length === 0) {
            renderString = "";
        }
        if (proposal_list.length === 1) {
            renderString = proposal_list[0].toString();
            if (map != null) {
                renderString = map[proposal_list[0]];
            }
        }
        if (proposal_list.length >= valid_list.length) {
            renderString = "All";
        }
        return renderString;
    }

    handleOtherFeatures = event => {
        var tag_list = this.removeTags("Feature: ", [...this.state.expand_tags]);
        var feature_list = [...this.state.proposal_features];
        var pos = feature_list.indexOf(event.target.value);
        if (pos >= 0) {
            feature_list.splice(pos, 1);
        } else {
            feature_list.push(event.target.value);
        }
        feature_list.map(tag => {
            tag_list.push("Feature: " + tag);
            return 1;
        });
        this.setState({ proposal_features: feature_list, expand_tags: tag_list });
    };

    handleFundingType = event => {
        var tag_list = this.removeTags("Funding: ", [...this.state.expand_tags]);
        var fundingtype_list = [...this.state.proposal_fundingtype];
        var pos = fundingtype_list.indexOf(event.target.value);
        if (pos >= 0) {
            fundingtype_list.splice(pos, 1);
        } else {
            fundingtype_list.push(event.target.value);
        }
        fundingtype_list.map(tag => {
            tag_list.push("Funding: " + tag);
            return 1;
        });
        this.setState({ proposal_fundingtype: fundingtype_list, expand_tags: tag_list });
    };

    handleFundMin = event => {
        var tag_list = this.removeTags("Funding Min: ", [...this.state.expand_tags]);
        var min = parseInt(event.target.value);
        if (min === "" || min < 0 || isNaN(min)) {
            min = null;
        } else {
            tag_list.push("Funding Min: " + min);
        }
        this.setState({ fund_min: min, expand_tags: tag_list });
    };

    handleFundMax = event => {
        var tag_list = this.removeTags("Funding Max: ", [...this.state.expand_tags]);
        var max = parseInt(event.target.value);
        if (max === "" || max < 0 || isNaN(max)) {
            max = null;
        } else {
            tag_list.push("Funding Max: " + max);
        }
        this.setState({ fund_max: max, expand_tags: tag_list });
    };

    handleExpanded = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    handleClearFunding = () => {
        var tag_list = this.removeTags("Funding: ", [...this.state.expand_tags]);
        tag_list = this.removeTags("Funding Min: ", tag_list);
        tag_list = this.removeTags("Funding Max: ", tag_list);
        this.setState({
            expand_tags: tag_list,
            proposal_fundingtype: [],
            fund_min: null,
            fund_max: null
        });
    };

    handleClearAndReset = () => {
        const { authState } = this.props;
        this.form.resetAll();
        this.setState({
            page: 0,
            rowsPerPage: 100,
            masterFilter: authState.user.role === "Member" ? "Active": "Proposals",
            valid_years: [],
            proposal_year: [],
            valid_pms: [],
            proposal_pms: [],
            valid_orgs: [],
            proposal_orgs: [],
            valid_locs: ["Statewide", "Metro", "Central", "NW", "NE", "SW", "SE"],
            textSearch: "",
            textCache: "",
            textExact: false,
            textOptions: "title",
            valid_lscales: [],
            proposal_lscales: [],
            valid_lareas: [],
            proposal_lareas: [],
            valid_iscales: [],
            proposal_iscales: [],
            valid_iareas: [],
            proposal_iareas: [],
            valid_ioccur: ["During the Project", "In the Future"],
            proposal_ioccur: [],
            valid_features: [
                "Restoration",
                "Research",
                "Use of Classified Staff",
                "Conservation Easement Acquisition",
                "Patent or Royalty",
                "Capital Expenditure",
                "Easement (Other) Acquisition",
                "Revenue or Net Income",
                "Gen. Ineligible Costs",
                "Fee Acquisition",
                "Fiscal Agent",
                "Stewardship Funds",
                "Design, Construction, or Renovation"
            ],
            proposal_features: [],
            valid_leads: [],
            proposal_leads: [],
            valid_fundingtype: ["Requested", "Recommended", "Appropriated"],
            proposal_fundingtype: [],
            fund_min: null,
            fund_max: null,
            exportOption: "SelectedTable",
            selected: [],
            expanded: false,
            basic_tags: authState.user.role === "Member" ? ["Master: Active"]: ["Master: Proposals"],
            expand_tags: [],
            order: "desc",
            orderBy: "proposal_unique_id"
        });
    };

    handleSelect = event => {
        var cur_id = parseInt(event.target.value);
        var temp_selected = [...this.state.selected];
        var pos = temp_selected.indexOf(cur_id);
        if (pos >= 0) {
            temp_selected.splice(pos, 1);
        } else {
            temp_selected.push(cur_id);
        }
        this.setState({ selected: temp_selected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleRequestSort = (event, property) => {
        var new_orderBy = property;
        var cur_orderBy = this.state.orderBy;
        var cur_order = this.state.order;
        var masterFilter = this.state.masterFilter;

        let order = "desc";

        if (cur_orderBy === property && cur_order === "desc") {
            order = "asc";
        }

        let list_type = this.props.proposals;

        switch (masterFilter) {
            case "Proposals":
                list_type = g_all_proposals;
                break;
            case "Draft Work Plans":
                list_type = g_all_draft;
                break;
            case "Final Work Plans":
                list_type = g_all_final;
                break;
            case "Active":
                list_type = g_all_active;
                break;
            case "Completed":
                list_type = g_all_completed;
                break;
            default:
                list_type = g_all_proposals;
                break;
        }

        list_type.sort(function(a, b) {
            var numeric = columnDataProposal.find(cD => cD.id === property).numeric;
            if (numeric) {
                if (order === "desc") return parseFloat(b[new_orderBy]) < parseFloat(a[new_orderBy]) ? -1 : 1;
                else return parseFloat(a[new_orderBy]) < parseFloat(b[new_orderBy]) ? -1 : 1;
            } else {
                if (order === "desc") return (b[new_orderBy] || "").toUpperCase() < (a[new_orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[new_orderBy] || "").toUpperCase() < (b[new_orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy: new_orderBy });
    };

    render() {
        const { classes, proposals, dwps, fwps, orgs, users, authState } = this.props;
        const {
            masterFilter,
            expanded,
            selected,
            exportOption,
            textOptions,
            textExact,
            textSearch,
            basic_tags,
            expand_tags,
            valid_years,
            proposal_year,
            valid_pms,
            proposal_pms,
            valid_orgs,
            proposal_orgs,
            valid_lscales,
            proposal_lscales,
            valid_lareas,
            proposal_lareas,
            valid_iscales,
            proposal_iscales,
            valid_iareas,
            proposal_iareas,
            valid_ioccur,
            proposal_ioccur,
            valid_features,
            proposal_features,
            proposal_fundingtype,
            valid_leads,
            proposal_leads,
            fund_min,
            fund_max,
            page,
            rowsPerPage,
            order,
            orderBy
        } = this.state;

        var is_member = false;
        if (authState && authState.user && authState.user.role === "Member") {
            is_member = true;
        }

        var rebuild = false;
        if (valid_orgs.length === 0) {
            rebuild = true;
        }

        var filtered_proposals = []

        if (proposals.length > 0) {
            if (g_org_map === null && orgs.length > 0) {
                g_org_map = this.build_org_map(orgs);
                g_lead_map = this.build_lead_map(users);
                g_proposal_map = this.build_proposal_map(proposals);
            }
            if (g_all_proposals.length === 0) {
                g_all_proposals = proposals;
                /*g_all_proposals = proposals.filter(function(pp) {
                    if (pp.status === "Draft Submitted" || pp.status === "Draft Feedback Received" || pp.status === "Final Submitted" || pp.status === "Revisions Needed" || pp.status === "Revisions Submitted") {
                        return true;
                    }
                    return false;
                });*/
                for (var pr of g_all_proposals) {
                    pr.prid = pr.id;
                    this.org_find(pr);
                }
            }
            if (g_all_draft.length === 0) {
                for (var wp of dwps) {
                    let pr = proposals[g_proposal_map[wp.proposal_record_id]];
                    this.extract_proposal(wp, pr);
                    this.org_find(wp);
                    if (wp.status === 'Draft Work Plan Due' || wp.status === 'Draft Work Plan Submitted' ||  wp.status === 'Draft Work Plan Revisions Needed' || wp.status === 'Draft Work Plan Revisions Submitted' || wp.status === 'Draft Work Plan Accepted') {
                        g_all_draft.push(wp);
                    }
                }
            }
            if (g_all_final.length === 0) {
                for (var wpfinal of fwps) {
                    let pr = proposals[g_proposal_map[wpfinal.proposal_record_id]];
                    this.extract_proposal(wpfinal, pr);
                    this.org_find(wpfinal);
                    if (wpfinal.status === 'Final Work Plan Due' || wpfinal.status === 'Final Work Plan Submitted' ||  wpfinal.status === 'Final Work Plan Revisions Needed' || wpfinal.status === 'Final Work Plan Revisions Submitted' || wpfinal.status === 'Final Work Plan Accepted') {
                        g_all_final.push(wpfinal);
                    }
                    if (wpfinal.status === 'Final Work Plan Approved' || wpfinal.status === 'Final Abstract Due' ||  wpfinal.status === 'Final Abstract Submitted' || wpfinal.status === 'Final Abstract Revisions Needed') {
                        g_all_active.push(wpfinal);
                    }
                    if (wpfinal.status === 'Project Completed') {
                        g_all_completed.push(wpfinal);
                    }
                }
            }

            switch (masterFilter) {
                case "Proposals":
                    filtered_proposals = [...g_all_proposals];
                    break;
                case "Active":
                    filtered_proposals = [...g_all_active];
                    break;
                case "Completed":
                    filtered_proposals = [...g_all_completed];
                    break;
                case "Draft Work Plans":
                    filtered_proposals = [...g_all_draft];
                    break;
                case "Final Work Plans":
                    filtered_proposals = [...g_all_final];
                    break;
                default:
                    break;
            }
        }

        if (filtered_proposals.length > 0 && rebuild) {
            var year_choice = [];
            var pm_choice = {};
            var org_choice = [];
            var loc_scale_choice = [];
            var loc_areas_choice = ["Statewide"];
            var imp_scale_choice = [];
            var imp_areas_choice = ["Statewide"];
            var lead_choice = [];

            for (var p of filtered_proposals) {
                this.build_year_choice(p, year_choice);
                this.build_pm_choice(p, pm_choice);
                this.build_org_choice(p, org_choice);
                this.build_loc_scale_choice(p, loc_scale_choice);
                this.build_loc_areas_choice(p, loc_areas_choice);
                this.build_imp_scale_choice(p, imp_scale_choice);
                this.build_imp_areas_choice(p, imp_areas_choice);
                this.build_lead_choice(p, lead_choice);
            }

            var tag_list = basic_tags;
            year_choice.map(tag => {
                tag_list.push("Year: " + tag);
                return 1;
            });
            var temp_pms = Object.getOwnPropertyNames(pm_choice);
            temp_pms.sort(function(a, b) {
                if (pm_choice[a].last.toUpperCase() < pm_choice[b].last.toUpperCase()) {
                    return -1;
                }
                if (pm_choice[a].last.toUpperCase() > pm_choice[b].last.toUpperCase()) {
                    return 1;
                }
                return 0;
            });
            org_choice.sort(function(a, b) {
                if (g_org_map[a].toUpperCase() < g_org_map[b].toUpperCase()) {
                    return -1;
                }
                if (g_org_map[a].toUpperCase() > g_org_map[b].toUpperCase()) {
                    return 1;
                }
                return 0;
            });
            lead_choice.sort(function(a, b) {
                if (
                    g_lead_map[a]
                        .split(" ")
                        .pop()
                        .toUpperCase() <
                    g_lead_map[b]
                        .split(" ")
                        .pop()
                        .toUpperCase()
                ) {
                    return -1;
                }
                if (
                    g_lead_map[a]
                        .split(" ")
                        .pop()
                        .toUpperCase() >
                    g_lead_map[b]
                        .split(" ")
                        .pop()
                        .toUpperCase()
                ) {
                    return 1;
                }
                return 0;
            });
            this.setState({
                valid_years: [...year_choice],
                valid_pms: temp_pms,
                valid_orgs: [...org_choice],
                valid_lscales: [...loc_scale_choice.sort()],
                valid_lareas: [...loc_areas_choice.sort()],
                valid_iscales: [...imp_scale_choice.sort()],
                valid_iareas: [...imp_areas_choice.sort()],
                valid_leads: [...lead_choice],
                proposal_year: ["All", ...year_choice],
                basic_tags: [...tag_list]
            });
        }

        var renderYearString = this.getRenderString(valid_years, proposal_year);
        var renderPMString = this.getRenderString(valid_pms, proposal_pms);
        var renderOrgString = this.getRenderString(valid_orgs, proposal_orgs, g_org_map);
        var renderLScaleString = this.getRenderString(valid_lscales, proposal_lscales);
        var renderLAreaString = this.getRenderString(valid_lareas, proposal_lareas);
        var renderIScaleString = this.getRenderString(valid_iscales, proposal_iscales);
        var renderIAreaString = this.getRenderString(valid_iareas, proposal_iareas);
        var renderLeadsString = this.getRenderString(valid_leads, proposal_leads, g_lead_map);

        var lscalesIsStatewide = proposal_lscales.includes("Statewide") && proposal_lscales.length === 1;
        var iscalesIsStatewide = proposal_iscales.includes("Statewide") && proposal_iscales.length === 1;

        var expandButtontext = expanded ? "See Only Basic Filters" : "See Expanded Filters";
        var filtered_ids = [];
        var rev_text = "Potential revenue generated or net income from the sale of products or assets developed or acquired with ENRTF funding";
        var roy_text = "Patent, Copyright, or Royalty Potential";
        var land_rights = ["Restoration", "Conservation Easement Acquisition", "Easement (Other) Acquisition", "Fee Acquisition"];

        var cur_fund_min = fund_min === null ? 0 : fund_min;
        var cur_fund_max = fund_max === null ? 1000000000 : fund_max;

        for (var x = filtered_proposals.length - 1; x >= 0; x--) {
            var n = filtered_proposals[x];
            n.summary = n.summary === null ? "" : n.summary;
            var cur_year = n.ml_year;
            var cur_pm = n.pm_first_name === null || n.pm_last_name === null ? "-- Field is Empty --" : n.pm_first_name + " " + n.pm_last_name;
            var cur_org = n.organization_id === null || n.organization_id === "" ? 0 : n.organization_id;
            var cur_has_fiscal_agent = n.fiscal_agent_id !== null;
            var cur_has_construction = n.asset_tenthousand === null ? false : n.asset_tenthousand;
            var cur_include_research = n.include_research === null ? false : n.include_research;
            var cur_lscale = n.location_scale === null || n.location_scale === "" ? "-- Field is Empty --" : n.location_scale;
            var cur_lareas = n.location_areas === null || n.location_areas === "" ? "-- Field is Empty --" : n.location_areas;
            var cur_iscale = n.impact_scale === null || n.impact_scale === "" ? "-- Field is Empty --" : n.impact_scale;
            var cur_iareas = n.impact_areas === null || n.impact_areas === "" ? "-- Field is Empty --" : n.impact_areas;
            var cur_ioccur = n.impact_occur;
            var cur_staff_lead = n.staff_lead_id === null || n.staff_lead_id === "" ? 0 : n.staff_lead_id;
            var cur_funding_total = n.funding_total === null || n.funding_total === 0.0 ? -1.0 : n.funding_total;
            var cur_recommended_amount = n.recommended_amount === null || n.recommended_amount === 0.0 ? -1.0 : n.recommended_amount;
            var cur_appropriation_amount = n.appropriation_amount === null || n.appropriation_amount === 0.0 ? -1.0 : n.appropriation_amount;

            if (!proposal_year.includes("All") && !proposal_year.includes(cur_year)) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_pms.length > 0 && !proposal_pms.includes(cur_pm)) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_orgs.length > 0 && !proposal_orgs.includes(cur_org)) {
                filtered_proposals.splice(x, 1);
                continue;
            }

            if (proposal_lscales.length > 0 && !proposal_lscales.includes(cur_lscale)) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            var intersect_lareas = this.findIntersection(proposal_lareas, cur_lareas);
            if (proposal_lareas.length > 0 && intersect_lareas.length === 0) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_iscales.length > 0 && !proposal_iscales.includes(cur_iscale)) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            var intersect_iareas = this.findIntersection(proposal_iareas, cur_iareas);
            if (proposal_iareas.length > 0 && intersect_iareas.length === 0) {
                filtered_proposals.splice(x, 1);
                continue;
            }

            var intersect_ioccur = this.findIntersection(proposal_ioccur, cur_ioccur);
            if (proposal_ioccur.length > 0 && intersect_ioccur.length !== proposal_ioccur.length) {
                filtered_proposals.splice(x, 1);
                continue;
            }

            var intersect_chosen_land_rights = this.findIntersection(land_rights, proposal_features);
            var insersect_prop_land_rights = this.findIntersection(intersect_chosen_land_rights, n.land_rights);
            if (intersect_chosen_land_rights.length > 0 && insersect_prop_land_rights.length === 0) {
                filtered_proposals.splice(x, 1);
                continue;
            }

            if (proposal_features.includes("Research") && !cur_include_research) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_features.includes("Patent or Royalty") && !n.patent_royalty_revenue_potential.includes(roy_text)) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_features.includes("Revenue or Net Income") && !n.patent_royalty_revenue_potential.includes(rev_text)) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_features.includes("Fiscal Agent") && !cur_has_fiscal_agent) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_features.includes("Design, Construction, or Renovation") && !cur_has_construction) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_features.includes("Use of Classified Staff") && n.classified_count === 0) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_features.includes("Gen. Ineligible Costs") && n.ineligible_expenses_count === 0) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_features.includes("Capital Expenditure") && n.cap_exp_count === 0) {
                filtered_proposals.splice(x, 1);
                continue;
            }
            if (proposal_features.includes("Stewardship Funds") && n.stewardship_count === 0) {
                filtered_proposals.splice(x, 1);
                continue;
            }

            if (proposal_leads.length > 0 && !proposal_leads.includes(cur_staff_lead)) {
                filtered_proposals.splice(x, 1);
                continue;
            }

            if (proposal_fundingtype.length > 0) {
                var funding_req_good = false;
                var funding_rec_good = false;
                var funding_app_good = false;

                if (proposal_fundingtype.includes("Requested") && cur_fund_min <= cur_funding_total && cur_funding_total <= cur_fund_max) {
                    funding_req_good = true;
                }

                if (proposal_fundingtype.includes("Recommended") && cur_fund_min <= cur_recommended_amount && cur_recommended_amount <= cur_fund_max) {
                    funding_rec_good = true;
                }

                if (proposal_fundingtype.includes("Appropriated") && cur_fund_min <= cur_appropriation_amount && cur_appropriation_amount <= cur_fund_max) {
                    funding_app_good = true;
                }

                if (proposal_fundingtype.length === 1 && funding_req_good === false && funding_rec_good === false && funding_app_good === false) {
                    filtered_proposals.splice(x, 1);
                    continue;
                }
                if (proposal_fundingtype.length > 1 && (funding_req_good === false || funding_rec_good === false || funding_app_good === false)) {
                    filtered_proposals.splice(x, 1);
                    continue;
                }
            }

            if (textSearch !== "") {
                var foundterm = false;
                var textBlock = "";

                switch (textOptions) {
                    case "title":
                        textBlock = [n.name, n.summary].join(" ");
                        break;
                    case "narr":
                        textBlock = [n.opportunity, n.solution, n.outcome, n.longterm_implementation].join(" ");
                        break;
                    case "all":
                        textBlock = [
                            n.proposal_unique_id,
                            n.name,
                            n.summary,
                            n.opportunity,
                            n.solution,
                            n.outcome,
                            n.longterm_implementation,
                            n.pm_first_name,
                            n.pm_last_name,
                            n.funding_category,
                            n.secondary_funding_category,
                            n.status,
                            n.secondary_status,
                            n.organization_name,
                            n.assoc_text
                            //n.primary_phase,
                        ].join(" ");
                        break;
                    default:
                        break;
                }
                var textQuery = [textSearch];
                if (!textExact) {
                    textQuery = textSearch.replace(/, /gi, "~");
                    textQuery = textQuery.replace(/,/gi, "~");
                    textQuery = textQuery.replace(/ /gi, "~");
                    textQuery = textQuery.split("~");
                }
                for (var term of textQuery) {
                    if (textBlock.toLowerCase().includes(term.toLowerCase())) {
                        foundterm = true;
                        break;
                    }
                }
                if (!foundterm) {
                    filtered_proposals.splice(x, 1);
                    continue;
                }
            }
            filtered_ids.push(n.id);
        }

        var disable_export = filtered_proposals < 1 || (selected.length < 1 && exportOption.includes("Selected"));

        var getString = "?csv=y";
        switch (exportOption) {
            case "SelectedTable":
                getString += "&table=y&list=" + selected.join(",");
                break;
            case "SelectedAll":
                getString += "&table=n&list=" + selected.join(",");
                break;
            case "ResultsTable":
                getString += "&table=y&list=" + filtered_ids.join(",");
                break;
            case "ResultsAll":
                getString += "&table=n&list=" + filtered_ids.join(",");
                break;
            default:
                break;
        }

        var export_link = "/adm/reports/apd/" + getString;
        var type_link = "#/proposal/";
        if (masterFilter.includes("Draft Work")) {
            export_link = "/adm/reports/awd/" + getString + "&draft=y";
            type_link = "#/draft/workplan/";
        }
        if (masterFilter.includes("Final Work") || masterFilter.includes("Active") || masterFilter.includes("Completed")) {
            export_link = "/adm/reports/awd/" + getString + "&draft=n";
            type_link = "#/final/workplan/";
        }

        var textSearchHelp =
            "<b>Any Word</b>: All words in the text box, separated by spaces or commas, will be searched independently. <br/>" +
            "<b>Exact Phrase</b>: The entire phrase in the text box will be searched for completely.<br/>" +
            "<b>Title and Summary</b>: Only the text of the Title and Summary will be searched.<br/>" +
            "<b>Narrative</b>: Only the text of the other fields in the Narrative section will be searched (Describe the opportunity..., What is your proposed solution to the problem..., What are the specific project outcomes..., Describe how the results will be implemented...).<br/>" +
            "<b>All Fields</b>: The text of these fields will be searched:" +
            "<ul><li>Proposal ID</li><li>Title</li><li>Summary</li><li>Describe the opportunity...</li><li>What is your proposed solution to the problem...</li>" +
            "<li>What are the specific project outcomes...</li><li>Describe how the results will be implemented...</li><li>Project Manager</li>" +
            "<li>Funding Category</li><li>Status</li><li>Legislative Status</li><li>Organization (Name)</li><li>Fiscal Agent (Name)</li>" +
            "<li>Collaborators (Name + Organization + Role)</li><li>Activities (Title + Description)</li><li>Milestones (Title)</ul>";

        var locationScaleHelp =
            "Select the desired <b>Location Scales</b>.<br/>Results will be filtered out if they do not exactly match the chosen <b>Scales</b>.";

        var locationRegionHelp = "Select the desired <b>Location Areas</b>.<br/>Areas not within or intersecting the chosen areas will be filtered out.";

        var impactScaleHelp =
            "Select the desired <b>Impact Scales</b>.<br/>Results will be filtered out if they do not exactly match the chosen <b>Scales</b>.";

        var impactRegionHelp = "Select the desired <b>Impact Areas</b>.<br/>Areas not within or intersecting the chosen areas will be filtered out.";

        var fundingTypeHelp =
            "Select <b>Funding Types</b> to only show results with a value above $0.<br/> Selecting one or more Type will also enable the " +
            "<b>Funding Range</b> below.<br/>The <b>Funding Range</b> will only apply to the selected <b>Funding Types</b>.";

        var fundingRangeHelp =
            "Enter Minimum and/or Maximum dollar values to include only the results in that range (inclusive).<br>" +
            "This only applies to the <b>Funding Types</b> selected above.<br/>If these fields are disabled, please choose a <b>Funding Type</b> above.";

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Search Home" />
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> Search Filters
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={9} lg={9} xl={8}>
                                        <Typography variant="h3" className={classes.titleTall}>
                                            Basic Filters
                                            <Button color="primary" className={classes.buttonFloatRight} onClick={this.handleClearAndReset}>
                                                <Typography variant="h3">Clear and Reset</Typography>
                                            </Button>
                                        </Typography>
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="master" name="master1" value={masterFilter} onChange={this.handleMasterFilter}>
                                                {!is_member && (
                                                    <FormControlLabel value="Proposals" control={<Radio color="primary" />} label="Proposals" />
                                                )}
                                                {!is_member && (
                                                    <FormControlLabel
                                                        className={classes.separateRadio}
                                                        value="Draft Work Plans"
                                                        control={<Radio color="primary" />}
                                                        label="Draft Work Plans"
                                                    />
                                                )}
                                                {!is_member && (
                                                    <FormControlLabel
                                                        className={classes.separateRadio}
                                                        value="Final Work Plans"
                                                        control={<Radio color="primary" />}
                                                        label="Final Work Plans"
                                                    />
                                                )}
                                                <FormControlLabel
                                                    className={classes.separateRadio}
                                                    value="Active"
                                                    control={<Radio color="primary" />}
                                                    label="Active Projects"
                                                />
                                                <FormControlLabel
                                                    className={classes.separateRadio}
                                                    value="Completed"
                                                    control={<Radio color="primary" />}
                                                    label="Completed Projects"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        <p></p>
                                        <Typography id="proposal-years-checkbox-label">Select Year(s) (required):</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-years-checkbox-label"
                                            id="proposal-years-checkbox"
                                            multiple
                                            value={proposal_year}
                                            onChange={this.handleChangeProposalYear}
                                            input={<Input />}
                                            renderValue={item => {
                                                return renderYearString;
                                            }}>
                                            <MenuItem key="All" value="All">
                                                <Checkbox color="primary" checked={proposal_year.includes("All")} />
                                                <ListItemText primary="All" />
                                            </MenuItem>
                                            {valid_years.map(year => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox color="primary" checked={proposal_year.includes(year)} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="proposal-pm-checkbox-label">Select Project Manager(s):</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-pm-label"
                                            id="proposal-pm-checkbox"
                                            multiple
                                            value={proposal_pms}
                                            onChange={this.handleChangeProposalPM}
                                            input={<Input />}
                                            renderValue={item => {
                                                return renderPMString;
                                            }}>
                                            <MenuItem key="pm_Clear" value="Clear">
                                                <Checkbox color="primary" checked={false} />
                                                <ListItemText primary="-- Clear Selected --" />
                                            </MenuItem>
                                            {valid_pms.map(pm => (
                                                <MenuItem key={pm} value={pm}>
                                                    <Checkbox color="primary" checked={proposal_pms.includes(pm)} />
                                                    <ListItemText primary={pm} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="proposal-org-checkbox-label">Select Project Organization(s):</Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-org-label"
                                            id="proposal-org-checkbox"
                                            multiple
                                            value={proposal_orgs}
                                            onChange={this.handleChangeProposalOrg}
                                            input={<Input />}
                                            renderValue={item => {
                                                return renderOrgString;
                                            }}>
                                            <MenuItem key="org_Clear" value="Clear">
                                                <Checkbox color="primary" checked={false} />
                                                <ListItemText primary="-- Clear Selected --" />
                                            </MenuItem>
                                            {valid_orgs.map(org => (
                                                <MenuItem key={"org_" + org.toString()} value={org}>
                                                    <Checkbox color="primary" checked={proposal_orgs.includes(org)} />
                                                    <ListItemText primary={g_org_map[org]} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3} xl={4} className={classes.filterArea}>
                                        <Typography>Active Basic Filters</Typography>
                                        {basic_tags.map(tag => (
                                            <Chip className={classes.chip} label={tag} onDelete={this.handleBasicChipDelete(tag)} color="primary" />
                                        ))}
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={7} xl={8}>
                                        <Form getApi={el => (this.form = el)}>
                                            {formApi => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <TextField field="textsearch" fullWidth label={
                                                        <HelpLabel
                                                            inputLabel="Text Search"
                                                            htmlText={true}
                                                            title={false}
                                                            showLabel={true}
                                                            helpText={textSearchHelp}
                                                        />
                                                        
                                                        } eventHandle={this.handleTextField} />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={5} xl={4}>
                                        <Typography className={classes.inlineSwitch}>Any Word</Typography>
                                        <Switch checked={textExact} onChange={this.handleExact} color="primary" name="textExact"></Switch>
                                        <Typography className={classes.inlineSwitch}>Exact Phrase</Typography>
                                        <FormControl className={classes.block} component="fieldset">
                                            <RadioGroup row aria-label="textOptions" name="textOptions" value={textOptions} onChange={this.handleTextOptions}>
                                                <FormControlLabel
                                                    value="title"
                                                    label="Title and Summary"
                                                    control={<Radio color="primary" className={classes.smallradio} />}
                                                />
                                                <FormControlLabel
                                                    value="narr"
                                                    label="Narrative"
                                                    control={<Radio color="primary" className={classes.smallradio} />}
                                                />
                                                <FormControlLabel
                                                    value="all"
                                                    label="All Fields"
                                                    control={<Radio color="primary" className={classes.smallradio} />}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        <Button color="primary" onClick={this.handleTextSearch} className={classes.button}>
                                            <Typography>Apply Text Search</Typography>
                                        </Button>
                                        <Button color="primary" onClick={this.handleClearText} className={classes.button}>
                                            <Typography>Clear Text</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p></p>
                                        <Button color="primary" onClick={this.handleClearAndReset}>
                                            <Typography variant="h3">Clear and Reset</Typography>
                                        </Button>
                                        {!is_member && (
                                            <Button className={classes.buttonLeftMargin} onClick={this.handleExpanded}>
                                                <Typography variant="h3">{expandButtontext}</Typography>
                                            </Button>
                                        )}
                                        <p></p>
                                    </Grid>
                                    <Grid item xs={12} md={9} lg={9} xl={8} className={expanded ? classes.expandOn : classes.expandOff}>
                                        <Typography variant="h3" className={classes.titleTall}>
                                            Expanded Filters
                                        </Typography>
                                        <Typography id="proposal-lscale-label">
                                            <HelpLabel
                                                inputLabel="Select Location Scale(s):"
                                                htmlText={true}
                                                title={false}
                                                showLabel={true}
                                                helpText={locationScaleHelp}
                                            />
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-lscale-label"
                                            id="proposal-lscale-checkbox"
                                            multiple
                                            fullWidth
                                            value={proposal_lscales}
                                            onChange={this.handleChangeLocScale}
                                            input={<Input />}
                                            renderValue={item => {
                                                return renderLScaleString;
                                            }}>
                                            <MenuItem key="lscale_Clear" value="Clear">
                                                <Checkbox color="primary" checked={false} />
                                                <ListItemText primary="-- Clear Selected --" />
                                            </MenuItem>
                                            {valid_lscales.map(scale => (
                                                <MenuItem key={scale} value={scale}>
                                                    <Checkbox color="primary" checked={proposal_lscales.includes(scale)} />
                                                    <ListItemText primary={scale} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="proposal-pm-checkbox-label">
                                            <HelpLabel
                                                inputLabel="Select Location Area(s):"
                                                htmlText={true}
                                                title={false}
                                                showLabel={true}
                                                style={
                                                    lscalesIsStatewide ? { color: "#777777"} : {}
                                                }
                                                helpText={locationRegionHelp}
                                            />
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-pm-checkbox-label"
                                            id="proposal-pm-checkbox"
                                            multiple
                                            fullWidth
                                            value={proposal_lareas}
                                            onChange={this.handleChangeLocArea}
                                            input={<Input />}
                                            disabled={lscalesIsStatewide}
                                            renderValue={item => {
                                                return renderLAreaString;
                                            }}>
                                            <MenuItem key="larea_Clear" value="Clear">
                                                <Checkbox color="primary" checked={false} />
                                                <ListItemText primary="-- Clear Selected --" />
                                            </MenuItem>
                                            {valid_lareas.map(area => (
                                                <MenuItem key={area} value={area}>
                                                    <Checkbox color="primary" checked={proposal_lareas.includes(area)} />
                                                    <ListItemText primary={area} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="proposal-loc-label">
                                            <HelpLabel
                                                inputLabel="Select Impact Scale(s):"
                                                htmlText={true}
                                                title={false}
                                                showLabel={true}
                                                helpText={impactScaleHelp}
                                            />
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-loc-label"
                                            id="proposal-loc-checkbox"
                                            multiple
                                            fullWidth
                                            value={proposal_iscales}
                                            onChange={this.handleChangeImpScale}
                                            input={<Input />}
                                            renderValue={item => {
                                                return renderIScaleString;
                                            }}>
                                            <MenuItem key="iscale_Clear" value="Clear">
                                                <Checkbox color="primary" checked={false} />
                                                <ListItemText primary="-- Clear Selected --" />
                                            </MenuItem>
                                            {valid_iscales.map(scale => (
                                                <MenuItem key={scale} value={scale}>
                                                    <Checkbox color="primary" checked={proposal_iscales.includes(scale)} />
                                                    <ListItemText primary={scale} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Typography id="proposal-org-checkbox-label">
                                            <HelpLabel
                                                inputLabel="Select Impact Area(s):"
                                                htmlText={true}
                                                title={false}
                                                showLabel={true}
                                                style={
                                                    iscalesIsStatewide ? { color: "#777777" }: {}
                                                }
                                                helpText={impactRegionHelp}
                                            />
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-org-checkbox-label"
                                            id="proposal-org-checkbox"
                                            multiple
                                            fullWidth
                                            value={proposal_iareas}
                                            onChange={this.handleChangeImpArea}
                                            input={<Input />}
                                            disabled={iscalesIsStatewide}
                                            renderValue={item => {
                                                return renderIAreaString;
                                            }}>
                                            <MenuItem key="iarea_Clear" value="Clear">
                                                <Checkbox color="primary" checked={false} />
                                                <ListItemText primary="-- Clear Selected --" />
                                            </MenuItem>
                                            {valid_iareas.map(area => (
                                                <MenuItem key={area} value={area}>
                                                    <Checkbox color="primary" checked={proposal_iareas.includes(area)} />
                                                    <ListItemText primary={area} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormControl component="fieldset" fullWidth className={classes.formControlArea}>
                                            <FormLabel component="legend">When Impact will Occur :</FormLabel>
                                            <FormGroup aria-label="position" row>
                                                {valid_ioccur.map(ioccur => (
                                                    <FormControlLabel
                                                        value={ioccur}
                                                        className={classes.selectcolumn3}
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                className={classes.smallradio}
                                                                checked={proposal_ioccur.includes(ioccur)}
                                                                onClick={this.handleIOccur}
                                                            />
                                                        }
                                                        label={ioccur}
                                                        labelPlacement="end"
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>

                                        <FormControl component="fieldset" fullWidth className={classes.formControlArea}>
                                            <FormLabel component="legend">Other Selected Project Features:</FormLabel>
                                            <FormGroup aria-label="position" row>
                                                {valid_features.map(feature => (
                                                    <FormControlLabel
                                                        value={feature}
                                                        className={classes.selectcolumn3}
                                                        control={
                                                            <Checkbox
                                                                checked={proposal_features.includes(feature)}
                                                                color="primary"
                                                                className={classes.smallradio}
                                                            />
                                                        }
                                                        label={feature}
                                                        labelPlacement="end"
                                                        onChange={this.handleOtherFeatures}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </FormControl>
                                        <Typography className={classes.formControlArea} id="proposal-lead-checkbox-label">
                                            Select Staff Lead(s):
                                        </Typography>
                                        <Select
                                            className={classes.selectbox}
                                            labelId="proposal-lead-label"
                                            id="proposal-lead-checkbox"
                                            fullWidth
                                            multiple
                                            value={proposal_leads}
                                            //disabled={masterFilter !== "Proposal"}
                                            onChange={this.handleChangeProposalLead}
                                            input={<Input />}
                                            renderValue={item => {
                                                return renderLeadsString;
                                            }}>
                                            <MenuItem key="lea_Clear" value="Clear">
                                                <Checkbox color="primary" checked={false} />
                                                <ListItemText primary="-- Clear Selected --" />
                                            </MenuItem>
                                            {valid_leads.map(lead => (
                                                <MenuItem key={lead} value={lead}>
                                                    <Checkbox color="primary" checked={proposal_leads.includes(lead)} />
                                                    <ListItemText primary={g_lead_map[lead]} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <p></p>
                                        <Typography variant="h3" className={classes.titleTall}>
                                            Funding Amount Search
                                        </Typography>
                                        <FormControl component="fieldset" fullWidth className={classes.formControlArea}>
                                            <FormLabel component="legend">Funding Type:
                                        <HelpLabel
                                            inputLabel="Funding Type:"
                                            htmlText={true}
                                            title={true}
                                            showLabel={false}
                                            helpText={fundingTypeHelp}
                                        /></FormLabel>
                                            <FormGroup aria-label="position" row>
                                                {[].map(type => (
                                                    <FormControlLabel
                                                        value={type}
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                className={classes.smallradio}
                                                                checked={proposal_fundingtype.includes(type)}
                                                            />
                                                        }
                                                        label={type}
                                                        labelPlacement="end"
                                                        onChange={this.handleFundingType}
                                                    />
                                                ))}
                                                <FormControlLabel
                                                    value={"Requested"}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            className={classes.smallradio}
                                                            checked={proposal_fundingtype.includes("Requested")}
                                                        />
                                                    }
                                                    label={"Requested"}
                                                    labelPlacement="end"
                                                    onChange={this.handleFundingType}
                                                />
                                                <FormControlLabel
                                                    value={"Recommended"}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            className={classes.smallradio}
                                                            checked={proposal_fundingtype.includes("Recommended")}
                                                        />
                                                    }
                                                    label={"Recommended"}
                                                    labelPlacement="end"
                                                    onChange={this.handleFundingType}
                                                />
                                                <FormControlLabel
                                                    value={"Appropriated"}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            className={classes.smallradio}
                                                            checked={proposal_fundingtype.includes("Appropriated")}
                                                        />
                                                    }
                                                    label={"Appropriated"}
                                                    labelPlacement="end"
                                                    onChange={this.handleFundingType}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                        <p></p>
                                        <HelpLabel
                                            inputLabel="Funding Range:"
                                            htmlText={true}
                                            title={false}
                                            showLabel={true}
                                            helpText={fundingRangeHelp}
                                        />
                                        <br/>
                                        <Typography className={classes.fundinglabel}>Min: </Typography>
                                        <Input
                                            className={classes.textfieldbox}
                                            id="outlined-size-small"
                                            size="small"
                                            type="number"
                                            disabled={proposal_fundingtype.length < 1}
                                            value={fund_min === null || fund_min < 0 ? "" : fund_min}
                                            onChange={this.handleFundMin}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        />
                                        <Typography className={classes.fundinglabel}> to Max: </Typography>
                                        <Input
                                            className={classes.textfieldbox}
                                            id="outlined-size-small"
                                            size="small"
                                            type="number"
                                            disabled={proposal_fundingtype.length < 1}
                                            value={fund_max ? fund_max : ""}
                                            onChange={this.handleFundMax}
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        />
                                        <Button
                                            color="primary"
                                            style={{
                                                marginLeft: "25px"
                                            }}
                                            onClick={this.handleClearFunding}>
                                            <Typography>Clear Funding</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={3} xl={4}
                                        className={expanded ? [classes.expandOn, classes.filterArea].join(" ") : classes.expandOff}>
                                        <Typography>Active Expanded Filters</Typography>
                                        {expand_tags.map(tag => (
                                            <Chip className={classes.chip} label={tag} onDelete={this.handleExpandChipDelete(tag)} color="primary" />
                                        ))}
                                    </Grid>
                                    <Grid item xs={12} className={expanded ? classes.expandOn : classes.expandOff}>
                                        <p></p>
                                        <Button color="primary" onClick={this.handleClearAndReset}>
                                            <Typography variant="h3">Clear and Reset</Typography>
                                        </Button>
                                        <Button className={classes.buttonLeftMargin} onClick={this.handleExpanded}>
                                            <Typography variant="h3">{expandButtontext}</Typography>
                                        </Button>
                                        <p></p>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <AssessmentIcon className={classes.expansionIcon} /> Search Results
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={5}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                aria-label="export_options"
                                                name="export_options"
                                                value={exportOption}
                                                onChange={this.handleExportOptions}>
                                                <FormControlLabel
                                                    value="SelectedTable"
                                                    className={classes.selectcolumn50}
                                                    control={<Radio color="primary" />}
                                                    label="Export Selected Results (Table as shown below)"
                                                />
                                                {!is_member && (
                                                    <FormControlLabel
                                                        value="SelectedAll"
                                                        className={classes.selectcolumn50}
                                                        control={<Radio color="primary" />}
                                                        label="Export Selected Results (All Data Report)"
                                                    />
                                                )}
                                                <FormControlLabel
                                                    value="ResultsTable"
                                                    className={classes.selectcolumn50}
                                                    control={<Radio color="primary" />}
                                                    label="Export All Results (Table as shown below)"
                                                />
                                                {!is_member && (
                                                    <FormControlLabel
                                                        value="ResultsAll"
                                                        className={classes.selectcolumn50}
                                                        control={<Radio color="primary" />}
                                                        label="Export All Results (All Data Report)"
                                                    />
                                                )}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button color="primary" disabled={disable_export} className={classes.flowButton}>
                                            <Typography
                                                variant="h3"
                                                className={disable_export ? classes.disabledButton : ""}
                                                onClick={() => window.open(export_link)}>
                                                Generate Report (Excel Spreadsheet)
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className={classes.scrollTable}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                stickyHeader
                                                columnData={columnDataProposal}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                            />
                                            <TableBody>
                                                {filtered_proposals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                                    return (
                                                        <TableRow key={n.id}>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Tooltip title="View">
                                                                    <Button
                                                                        color="primary"
                                                                        className={classes.deleteWidth}
                                                                        aria-label="View"
                                                                        onClick={() => window.open(type_link + n.prid)}>
                                                                        <Visibility color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.selectwidth}>
                                                                <Checkbox
                                                                    value={n.id.toString()}
                                                                    checked={selected.includes(n.id)}
                                                                    onClick={this.handleSelect}
                                                                    color="primary"
                                                                />
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.idwidth}>{n.proposal_unique_id}</CustomTableCell>
                                                            <CustomTableCell className={classes.middle}>{n.name}</CustomTableCell>
                                                            <CustomTableCell className={classes.middle}>{n.funding_category}</CustomTableCell>
                                                            <CustomTableCell className={classes.middle}>{n.organization_name}</CustomTableCell>
                                                            <CustomTableCell className={classes.small}>
                                                                {n.pm_first_name} {n.pm_last_name}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.smallcurrency}>
                                                                {n.funding_total !== null ? "$" + n.funding_total.toLocaleString() : "-"}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.smallcurrency}>
                                                                {n.recommended_amount !== null ? "$" + n.recommended_amount.toLocaleString() : "-"}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.smallcurrency}>
                                                                {n.appropriation_amount !== null ? "$" + n.appropriation_amount.toLocaleString() : "-"}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.small}>{n.status}</CustomTableCell>
                                                            <CustomTableCell className={classes.middle}>{n.secondary_status}</CustomTableCell>
                                                            <CustomTableCell className={classes.wide}>{n.summary}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {filtered_proposals.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataProposal.length} className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Projects Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <TableFooter className={classes.tablePaginator}>
                                        <TableRow>
                                            <TablePagination
                                                colSpan={columnDataProposal.length}
                                                count={filtered_proposals.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActionsWrapped}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

Search = connect(
    (state, ownProps) => ({
        authState: state.auth,
        agents: getFiscalAgents(state, ownProps),
        orgs: getOrganizations(state, ownProps),
        users: getUsers(state, ownProps),
        proposals: getProposals(state, ownProps),
        collabs: getProposalCollaborators(state, ownProps),
        activities: getProposalActivities(state, ownProps),
        milestones: getProposalMilestones(state, ownProps),
        dwps: getDraftWorkPlans(state, ownProps),
        draftcollabs: getDraftCollaborators(state, ownProps),
        draftactivities: getDraftActivities(state, ownProps),
        draftmilestones: getDraftMilestones(state, ownProps),
        fwps: getFinalWorkPlans(state, ownProps),
        finalcollabs: getFinalCollaborators(state, ownProps),
        finalactivities: getFinalActivities(state, ownProps),
        finalmilestones: getFinalMilestones(state, ownProps)
    }),
    {
        ...ProposalActivityView.actions,
        ...DraftProposalActivityView.actions,
        ...FinalProposalActivityView.actions,
        ...ProposalMilestoneView.actions,
        ...DraftProposalMilestoneView.actions,
        ...FinalProposalMilestoneView.actions,
        ...ProposalCollaboratorView.actions,
        ...DraftProposalCollaboratorView.actions,
        ...FinalProposalCollaboratorView.actions
    }
)(Search);

export default withStyles(styles)(withRouter(Search));
