import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUISnackbar from "@material-ui/core/Snackbar";
import MUISnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withStyles, Typography, Table, TableCell, TableRow, TableHead } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import DatePicker from "../common/DatePicker";
import DateTimePicker from "../common/DateTimePicker";
import TextField from "../common/TextField";
import Select from "../common/Select";
import MonthPicker from "../common/MonthPicker";
import Switch from "../common/Switch";
import PageHeader from "../common/PageHeader";
import WarningDialog from "../common/WarningDialog";
import Snackbar from "../common/Snackbar";

import { SystemSetting } from "../home/models";
import { createSelector, reloadListOfModels } from "../common/orm";

const getRFPYears = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});

const styles = theme => ({
    select: {
        width: 200
    },
    headCustom: {
        backgroundColor: "white !important",
        color: "#495057",
        borderBottom: "2px solid black",
        fontSize: "1.25rem",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
        fontWeight: 300,
        lineHeight: "1.17",
        whiteSpace: "nowrap"
    },
    date: {
        width: 220
    },
    instructionsHead: {
        maxWidth: "220px !important",
        backgroundColor: "white !important",
        color: "#495057",
        borderBottom: "2px solid black",
        fontSize: "1.25rem",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
        fontWeight: 300,
        lineHeight: "1.17",
        whiteSpace: "nowrap"
    },
    endHead: {
        maxWidth: "260px !important",
        backgroundColor: "white !important",
        color: "#495057",
        borderBottom: "2px solid black",
        fontSize: "1.25rem",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
        fontWeight: 300,
        lineHeight: "1.17",
        whiteSpace: "nowrap"
    },
    actionHead: {
        minWidth: "190px !important",
        backgroundColor: "white !important",
        color: "#495057",
        borderBottom: "2px solid black",
        fontSize: "1.25rem",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
        fontWeight: 300,
        lineHeight: "1.17",
        whiteSpace: "nowrap"
    },
    instructions: {
        maxWidth: "300px !important"
    },
    end: {
        maxWidth: "260px !important"
    },
    action: {
        minWidth: "190px !important"
    },
    snackbar: {
        marginBottom: 24
    },
    snackbarContainer: {
        backgroundColor: "#d32f2f"
    },
    snackbarContainerSuccess: {
        backgroundColor: "#43a047"
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit * 4
    }
});

class ConfigureProposalProcess extends Component {
    state = {
        error: false,
        noError: false,
        activeRFP: null,
        confirmOpen: false,
        confirmActionOpen: false,
        dont: false,
        val: null,
        update_text: "",
        update_title: "",
        updateOpen: false,
        type: "",
        apiValues: {},
        loading: false
    };

    componentDidMount() {
        document.title = "Configure Proposal Process - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    componentDidUpdate = e => {
        //allows changing of form values to different RFP year
        if (this.state.dont) {
            this.setState({ dont: false });
        }
    };

    saveRFP = values => {
        const { ormSystemSettingUpdate } = this.props;
        const { activeRFP } = this.state;

        const lockSelectionsChanged =
            activeRFP.presentation_selection_open !== values.presentation_selection_open && values.presentation_selection_open === true;
        const lockRecommendationsChanged = activeRFP.recommendations_open !== values.recommendations_open && values.recommendations_open === true;
        const createDraft = activeRFP.draft_created !== values.draft_created && values.draft_created === true;
        const createFinal = activeRFP.final_created !== values.final_created && values.final_created === true;

        values.first_date = values.first_date ? values.first_date.replace(" 12:00:00", "") : values.first_date;
        values.second_date = values.second_date ? values.second_date.replace(" 12:00:00", "") : values.second_date;
        values.draftplan_date = values.draftplan_date ? values.draftplan_date.replace(" 12:00:00", "") : values.draftplan_date;
        values.finalplan_date = values.finalplan_date ? values.finalplan_date.replace(" 12:00:00", "") : values.finalplan_date;
        values.legislative_date = values.legislative_date ? values.legislative_date.replace(" 12:00:00", "") : values.legislative_date;
        values.governor_date = values.governor_date ? values.governor_date.replace(" 12:00:00", "") : values.governor_date;
        values.workplan_date = values.workplan_date ? values.workplan_date.replace(" 12:00:00", "") : values.workplan_date;
        this.setState({loading: true});

        if (lockSelectionsChanged) {
            this.sendRequest("update/selected/status", { year: values.year }, ["ProposalView"]);
        }

        if (lockRecommendationsChanged) {
            this.sendRequest("update/recommended/status", { year: values.year }, ["ProposalView"]);
        }

        if (createDraft) {
            this.sendRequest(
                "draft/workplans",
                {
                    year: values.year,
                    first_date: values.first_date,
                    second_date: values.second_date,
                    due_date: values.draftplan_date
                },
                ["ProposalView", "DraftWorkPlanView"]
            );
        }

        if (createFinal) {
            this.sendRequest(
                "final/workplans",
                {
                    year: values.year,
                    due_date: values.finalplan_date
                },
                ["ProposalView", "FinalWorkPlanView"]
            );
        }

        if (values.id) {
            ormSystemSettingUpdate({
                ...values,
                num_selections: values.num_selections ? parseInt(values.num_selections) : null
            });
            this.setState({ activeRFP: values });
        }
        if (!lockSelectionsChanged && !lockRecommendationsChanged && !createDraft && !createFinal) {
            this.setState({loading: false, noError: true});
        }
    };

    sendRequest = (url, data, type) => {
        const { authState } = this.props;
        return fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                ...data
            })
        })
            .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
            })
            .then(data => {
                this.props.reloadListOfModels(type);
                this.setState({loading: false, noError: true});
            })
            .catch(e => {
                console.log("error", e);
            });
    };

    handleYear = e => {
        const { rfpYears } = this.props;
        const index = rfpYears.findIndex(x => x.year === e);
        const active = index > -1 ? rfpYears[index] : {};
        this.setState({ dont: true, activeRFP: active });
    };

    componentDidUpdate = e => {
        //allows changing of form values to different RFP year
        if (this.state.dont) {
            this.setState({ dont: false });
        }
    };

    errorValidator = values => {
        const isRequired = (val, reason) => {
            return !val ? "Required" + reason : null;
        };

        var rest = {};

        if (values.draft_created) {
            rest.first_date = isRequired(values.first_date, " For Creating Draft Work Plans");
            rest.second_date = isRequired(values.second_date, " For Creating Draft Work Plans");
            rest.draftplan_date = isRequired(values.draftplan_date, " For Creating Draft Work Plans");
        } else {
            rest.first_date = null;
            rest.second_date = null;
            rest.draftplan_date = null;
        }

        if (values.final_created) {
            rest.finalplan_date = isRequired(values.finalplan_date, " For Creating Final Work Plans");
        } else {
            rest.finalplan_date = null;
        }

        return {
            ...rest,
            num_selections: values.num_selections ? (!isNaN(parseInt(values.num_selections)) ? null : "Enter valid number") : null,
            legislative_date: null,
            governor_date: null,
            workplan_date: null,
            special_session: null,
            article: null,
            citation_ml_year: null,
            chapter: null,
            section: null
        };
    };

    open = () => {
        this.setState({ confirmOpen: false, confirmActionOpen: false });
    };

    close = () => {
        if (this.formApi) {
            this.formApi.setValue(this.state.field, !this.state.val);
        }
        this.setState({ confirmOpen: false, confirmActionOpen: false });
    };

    handleOpen = (val, field) => {
        if (val) {
            this.setState({ confirmOpen: true, val: val, field: field });
        }
    };

    handleOpenWork = (val, field, title, text) => {
        if (val) {
            this.setState({ confirmActionOpen: true, val, field, text, title });
        }
    };

    createNextYear = values => {
        const { ormSystemSettingCreate } = this.props;
        ormSystemSettingCreate({}).then(id => {
            const newYear = this.props.rfpYears.find(x => x.id === id);
            if (newYear) this.setState({ dont: true, activeRFP: newYear });
            else this.setState({ dont: true, activeRFP: null });
        });
    };

    getDefaultvalues = values => {
        if (!values) {
            return null;
        }
        var defaultValues = { ...values };

        if (defaultValues.draftplan_date) defaultValues.draftplan_date += " 12:00:00";
        if (defaultValues.finalplan_date) defaultValues.finalplan_date += " 12:00:00";
        if (defaultValues.legislative_date) defaultValues.legislative_date += " 12:00:00";
        if (defaultValues.governor_date) defaultValues.governor_date += " 12:00:00";
        if (defaultValues.workplan_date) defaultValues.workplan_date += " 12:00:00";

        return defaultValues;
    };

    // Updates either Legislative, Governor, or Workplan date the saves the date.
    updateDate = () => {
        const { authState, ormSystemSettingUpdate } = this.props;
        const { activeRFP, apiValues, key, type } = this.state;

        /*if (!values[key]) {
            this.formApi.setError(key, "Required");
            return;
        } else {
            this.formApi.setError(key, null);
        }*/

        var lockKey = "";
        if (key === "legislative_date") {
            lockKey = "legal_set";
        } else if (key === "governor_date") {
            lockKey = "governor_set";
        } else {
            lockKey = "workplan_set";
        }

        ormSystemSettingUpdate({
            id: activeRFP.id,
            [key]: apiValues[key].replace(" 12:00:00", ""),
            [lockKey]: true
        });

        fetch("update/date", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                type,
                year: activeRFP.year,
                date: apiValues[key].replace(" 12:00:00", "")
            })
        })
            .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
            })
            .then(data => {
                this.setState({ activeRFP: { ...activeRFP, [lockKey]: true }, updateOpen: false, noError: true });
                this.props.reloadListOfModels(["ProposalView"]);
            })
            .catch(e => {
                this.setState({ updateOpen: false, error: true });
                console.log("error", e);
            });
    };

    legalUpdate = formApi => {
        const { authState, ormSystemSettingUpdate } = this.props;
        const { activeRFP, apiValues } = this.state;

        ormSystemSettingUpdate({
            citation_set: true,
            id: activeRFP.id,
            special_session: apiValues["special_session"],
            article: apiValues["article"],
            citation_ml_year: apiValues["citation_ml_year"],
            chapter: apiValues["chapter"],
            section: apiValues["section"]
        });

        fetch("update/legal", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                year: activeRFP.year,
                special_session: apiValues["special_session"],
                article: apiValues["article"],
                citation_ml_year: apiValues["citation_ml_year"],
                chapter: apiValues["chapter"],
                section: apiValues["section"]
            })
        })
            .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
            })
            .then(data => {
                this.setState({ activeRFP: { ...activeRFP, citation_set: true }, updateOpen: false, noError: true });
                this.props.reloadListOfModels(["ProposalView"]);
            })
            .catch(e => {
                this.setState({ updateOpen: false, error: true });
                console.log("error", e);
            });
    };

    before = (key, type, update_text, update_title, formApi) => {
        var valid = true;
        if (type === "Governor" || type === "Legistlative") {
            if (!formApi.values[key]) {
                valid = false;
                formApi.setError(key, "Required");
                if (type === "Governor") {
                    formApi.setValue("governor_set", false);
                } else if (type === "Legistlative") {
                    formApi.setValue("legal_set", false);
                }
            }
        }

        if (valid) {
            if (type === "Governor" || type === "Legistlative") {
                formApi.setError(key, null);
            }

            this.setState({
                updateOpen: true,
                apiValues: formApi.values,
                type,
                key,
                update_text,
                update_title
            });
        }
    };

    cancel = () => {
        const { type } = this.state;
        if (type === "Governor") {
            this.formApi.setValue("governor_set", false);
        } else if (type === "Legistlative") {
            this.formApi.setValue("legal_set", false);
        } else {
            this.formApi.setValue("citation_set", false);
        }
    };

    render() {
        const { classes, rfpYears } = this.props;
        const { activeRFP, confirmOpen, dont, update_text, update_title, loading } = this.state;

        const years = rfpYears.map(x => ({
            label: x.year,
            value: x.year
        }));

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Configure Proposal Process" admin_page />
                    <Grid item xs={12}>
                        <Form getApi={el => (this.yearApi = el)} dontValidateOnMount={true} onSubmit={this.createNextYear}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Select
                                        className={classes.select}
                                        field="rfp_year"
                                        label="RFP Year"
                                        options={years}
                                        eventHandle={val => {
                                            this.handleYear(val);
                                        }}
                                    />
                                    <Button variant="contained" color="primary" type="submit" style={{ marginLeft: 25, marginTop: 33 }}>
                                        Create Next RFP Year
                                    </Button>
                                </form>
                            )}
                        </Form>
                    </Grid>
                    {!dont && (
                        <Grid item xs={12}>
                            <Form
                                ref={this.Formref}
                                getApi={el => (this.formApi = el)}
                                validateOnSubmit={true}
                                validateError={this.errorValidator}
                                dontValidateOnMount={true}
                                defaultValues={this.getDefaultvalues(activeRFP)}
                                onSubmit={this.saveRFP}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={16} style={{ marginTop: 10 }}>
                                            <Grid item xs={12}>
                                                <Table aria-label="Configure Proposal Process Grid">
                                                    <TableHead>
                                                        <TableCell className={classes.headCustom}>Proposal Process Step</TableCell>
                                                        <TableCell className={classes.actionHead}>Action</TableCell>
                                                        <TableCell className={classes.headCustom}>Start Date</TableCell>
                                                        <TableCell className={classes.endHead}>End Date</TableCell>
                                                        <TableCell className={classes.instructionsHead}>Staff Instructions</TableCell>
                                                    </TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Set RFP Open Dates</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <Switch disabled={!activeRFP} field="funding_open" label="Allow Draft Submital" />
                                                        </TableCell>
                                                        <TableCell>
                                                            <DateTimePicker
                                                                clearable
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="open_date"
                                                                label="Open Date"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.end}>
                                                            <DateTimePicker
                                                                clearable
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="draft_date"
                                                                label="Draft Close Date"
                                                            />
                                                            <DateTimePicker
                                                                clearable
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="final_date"
                                                                label="Final Close Date"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>
                                                                Set the date range that the system will allow users to create a new proposal and submit it. Set
                                                                whether the system will allow a draft proposal to be submitted for review.
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Staff Evaluation</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <Switch disabled={!activeRFP} field="staff_eval_open" label="Complete and Lock Scores" />
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Staff use proposal home screen to access scoring process.</Typography>
                                                            <Typography>Once final staff score is set we come here to lock results.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Member Evaluation #1</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <Grid container>
                                                                <TextField
                                                                    disabled={!activeRFP}
                                                                    field="num_selections"
                                                                    label="# of Selections"
                                                                    style={{ marginRight: 15 }}
                                                                />
                                                            </Grid>
                                                            <Grid container>
                                                                <Switch
                                                                    disabled={!activeRFP}
                                                                    eventHandle={val => {
                                                                        this.handleOpen(val, "evaluation1_open");
                                                                    }}
                                                                    field="evaluation1_open"
                                                                    label="Open For Evaluation #1"
                                                                />
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell className={classes.end}>
                                                            <DateTimePicker
                                                                clearable
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="member1_date"
                                                                label="Close Date and Time"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Set # of Selections we are asking members to make.</Typography>
                                                            <Typography>Set deadline for making them.</Typography>
                                                            <Typography>Then Manually open for evaluation.</Typography>
                                                            <Typography>Makes eval #1 button on member dashboard active.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Compiled Evaluation #1</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <Switch disabled={!activeRFP} field="compiled" label="Show Compiled Evaluation #1" />
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Show Compiled Evaluation #1 to Members.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Presentation Selection</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <Switch
                                                                disabled={!activeRFP || activeRFP.presentation_selection_open}
                                                                field="presentation_selection_open"
                                                                label="Complete and Lock Selections"
                                                            />
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Staff use batch update to select.</Typography>
                                                            <Typography>
                                                                Button changes scondary phase status to selected for further consideration or not selected for
                                                                further consideration.
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Member Evaluation #2</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <Grid container>
                                                                <TextField
                                                                    disabled={!activeRFP}
                                                                    field="allocation_amount"
                                                                    label="Allocation Amount"
                                                                    useTextFormat
                                                                    style={{ marginRight: 15 }}
                                                                />
                                                            </Grid>
                                                            <Grid container>
                                                                <Switch
                                                                    disabled={!activeRFP}
                                                                    eventHandle={val => {
                                                                        this.handleOpen(val, "evaluation2_open");
                                                                    }}
                                                                    field="evaluation2_open"
                                                                    label="Open For Evaluation #2"
                                                                />
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell className={classes.end}>
                                                            <DateTimePicker
                                                                clearable
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="member2_date"
                                                                label="Close Date and Time"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>Set deadline for making them.</Typography>
                                                            <Typography>Then Manually open for evaluation.</Typography>
                                                            <Typography>Makes eval #2 button on member dashboard active.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Compiled Evaluation #2</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <Switch disabled={!activeRFP} field="compiled2" label="Show Compiled Evaluation #2" />
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Show Compiled Evaluation #2 to Members.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Recommendations, Allocations, and Contingencies</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <Switch
                                                                disabled={!activeRFP || activeRFP.recommendations_open}
                                                                field="recommendations_open"
                                                                label="Complete and Lock Recommendations"
                                                            />
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Sets status of recommend or not recommed for funding.</Typography>
                                                            <Typography>Email notification that status has changed.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Report Update Dates</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <MonthPicker
                                                                style={{ marginRight: 5 }}
                                                                defaultFirstDay
                                                                field="first_date"
                                                                disabled={!activeRFP}
                                                                label="First Status Date"
                                                            />
                                                            <MonthPicker defaultFirstDay field="second_date" disabled={!activeRFP} label="Second Status Date" />
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Set dates for semi-annual update reporting schedule.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Draft Work Plans</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <DatePicker
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="draftplan_date"
                                                                label="Due Date"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Switch
                                                                disabled={!activeRFP || this.state.activeRFP.draft_created}
                                                                eventHandle={val => {
                                                                    this.handleOpenWork(
                                                                        val,
                                                                        "draft_created",
                                                                        "Create Draft Workplans",
                                                                        "Confirming will create Draft Work Plans for all Proposals recommended for funding upon save."
                                                                    );
                                                                }}
                                                                field="draft_created"
                                                                label="Create Draft Work Plans"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Creates Draft Work Plans and locks once saved.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Final Work Plans</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <DatePicker
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="finalplan_date"
                                                                label="Due Date"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Switch
                                                                disabled={
                                                                    !activeRFP || this.state.activeRFP.final_created || !this.state.activeRFP.draft_created
                                                                }
                                                                eventHandle={val => {
                                                                    this.handleOpenWork(
                                                                        val,
                                                                        "final_created",
                                                                        "Create Final Workplans",
                                                                        "Confirming will create Final Work Plans for all Proposals recommended for funding upon save."
                                                                    );
                                                                }}
                                                                field="final_created"
                                                                label="Create Final Work Plans"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Creates Final Work Plans and locks once saved.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Legislative Action</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <DatePicker
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="legislative_date"
                                                                label="Legislative Action Date"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Switch
                                                                disabled={!activeRFP || activeRFP.legal_set}
                                                                eventHandle={val =>
                                                                    this.before(
                                                                        "legislative_date",
                                                                        "Legistlative",
                                                                        "This will default all proposals Legislative Action Date.",
                                                                        "Legislative Action Date",
                                                                        formApi
                                                                    )
                                                                }
                                                                field="legal_set"
                                                                label="Complete and Lock Legislative Action"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Set the Legislative action in the batch info update page.</Typography>
                                                            <Typography>Add date here.</Typography>
                                                            <Typography>
                                                                Toggle to lock and click set and save to update all final work plans pending legislative action.
                                                            </Typography>
                                                            <Typography>Batch update page sends email notification that status has changed.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Governor Action</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <DatePicker
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="governor_date"
                                                                label="Governor Action Date"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Switch
                                                                disabled={!activeRFP || activeRFP.governor_set}
                                                                eventHandle={val =>
                                                                    this.before(
                                                                        "governor_date",
                                                                        "Governor",
                                                                        "This will default all proposals Governor Action Date.",
                                                                        "Governor Action Date",
                                                                        formApi
                                                                    )
                                                                }
                                                                field="governor_set"
                                                                label="Complete and Lock Governor Action"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.end}></TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Set the Governor action in the batch info update page.</Typography>
                                                            <Typography>Add date here.</Typography>
                                                            <Typography>
                                                                Toggle to lock and click set and save to update all final work plans passed or adjusted and
                                                                passed by legislature.
                                                            </Typography>
                                                            <Typography>Batch update page sends email notification that status has changed.</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Set Legal Citation</Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.action}>
                                                            <TextField
                                                                style={{ marginRight: 5 }}
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="citation_ml_year"
                                                                label="M.L. Year"
                                                            />
                                                            <TextField disabled={!activeRFP} className={classes.date} field="chapter" label="Chapter" />
                                                            <TextField disabled={!activeRFP} className={classes.date} field="section" label="Section" />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                style={{ marginRight: 5 }}
                                                                disabled={!activeRFP}
                                                                className={classes.date}
                                                                field="special_session"
                                                                label="Special Session"
                                                            />
                                                            <TextField disabled={!activeRFP} className={classes.date} field="article" label="Article" />
                                                        </TableCell>
                                                        <TableCell className={classes.end}>
                                                            <Switch
                                                                disabled={!activeRFP || activeRFP.citation_set}
                                                                eventHandle={val =>
                                                                    this.before(
                                                                        "citation_ml_year",
                                                                        "Legal",
                                                                        "This will default all proposals Legal Citation.",
                                                                        "Legal Citation",
                                                                        formApi
                                                                    )
                                                                }
                                                                field="citation_set"
                                                                label="Complete and Lock Legal Citation"
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.instructions}>
                                                            <Typography>Enter overall legal citation information here.</Typography>
                                                            <Typography>Enter subdivision and appropriation language on batch info update page.</Typography>
                                                            <Typography>
                                                                Toggle to lock and click set and save to update all final work plan passed or adjusted and
                                                                passed by legislature.
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                {!loading ? (
                                                    <Button disabled={!activeRFP} variant="contained" color="primary" type="submit">
                                                        Save
                                                    </Button>
                                                ) : (
                                                    <CircularProgress size={24} aria-label="Loading Icon" className={classes.progress} />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </Grid>
                    )}
                </Grid>
                <WarningDialog
                    confirmAction={() => {
                        this.open();
                    }}
                    cancelAction={() => {
                        this.close();
                    }}
                    open={confirmOpen}
                    title={`Open Member Evaluation #${this.state.field === "evaluation1_open" ? "1" : "2"}`}
                    confirmText="Open"
                    text="This will open this year for evaluation."
                    cancelText="Cancel"
                />
                <WarningDialog
                    confirmAction={() => {
                        this.open();
                    }}
                    cancelAction={() => {
                        this.close();
                    }}
                    open={this.state.confirmActionOpen}
                    title={this.state.title}
                    confirmText="Confirm"
                    text={this.state.text}
                    cancelText="Cancel"
                />
                <WarningDialog
                    confirmAction={() => {
                        if (this.state.type === "Legal") {
                            this.legalUpdate();
                        } else {
                            this.updateDate();
                        }
                    }}
                    cancelAction={() => {
                        this.cancel();
                        this.setState({ updateOpen: false });
                    }}
                    open={this.state.updateOpen}
                    title={update_title}
                    confirmText="Set and Save"
                    text={update_text}
                    cancelText="Cancel"
                />
                <MUISnackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    className={classes.snackbar}
                    open={this.state.noError}
                    autoHideDuration={3000}
                    onClose={() => this.setState({ noError: false })}>
                    <MUISnackbarContent
                        className={classes.snackbarContainerSuccess}
                        message={
                            <span className={classes.snackbarMessage}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                Action Successful!
                            </span>
                        }
                    />
                </MUISnackbar>
                <Snackbar handleSnackbarClose={() => this.setState({ error: false })} snackbarOpen={this.state.error} message={"Error."} />
            </AppContainer>
        );
    }
}

ConfigureProposalProcess = connect(
    (state, ownProps) => ({
        authState: state.auth,
        rfpYears: getRFPYears(state)
    }),
    {
        ...SystemSetting.actions,
        reloadListOfModels: reloadListOfModels
    }
)(ConfigureProposalProcess);

export default withStyles(styles)(withRouter(ConfigureProposalProcess));
