import React, { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { withStyles, Typography } from "@material-ui/core";
import MUISnackbar from "@material-ui/core/Snackbar";
import MUISnackbarContent from "@material-ui/core/SnackbarContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import AppContainer from "../common/AppContainer";
import DatePicker from "../common/DatePicker";
import HelpLabel from "../common/HelpLabel";
import Checkbox from "../common/Checkbox";
import Select from "../common/Select";
import PageHeader from "../common/PageHeader";
import Snackbar from "../common/Snackbar";

import { ProposalView } from "../proposal/models";
import { DraftWorkPlanView, FinalWorkPlanView } from "../workplan/models";

import { SystemSetting } from "../home/models";
import { createSelector, reloadListOfModels } from "../common/orm";

const getProposals = createSelector(schema => {
    return schema.ProposalView.filter(
        x =>
            x.primary_phase !== "Draft Proposal" &&
            x.recommended_for_funding !== true &&
            x.secondary_status !== "Withdrawn" &&
            x.secondary_status !== "Withdrawal Requested"
    )
        .orderBy("proposal_unique_id", "desc")
        .toModelArray();
});
const getYears = createSelector(schema => {
    return schema.SystemSetting.filter(x => x.draft_created).orderBy("year", "desc").toRefArray();
});

const getStaff = createSelector(schema => {
    return schema.User.filter(x => x.role === "Staff")
        .toModelArray()
        .map(x => ({ value: x.id, label: x.first_name + " " + x.last_name }));
});
const getUsers = createSelector(schema => {
    return schema.User.filter(x => x.role === "User")
        .orderBy(["first_name", "last_name"])
        .toModelArray()
        .map(x => ({ value: x.id, label: x.first_name + " " + x.last_name }));
});

const styles = theme => ({
    link: {
        cursor: "pointer"
    },
    snackbar: {
        marginBottom: 24
    },
    snackbarContainer: {
        backgroundColor: "#d32f2f"
    },
    snackbarContainerSuccess: {
        backgroundColor: "#43a047"
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit * 4
    }
});

class CreateWorkPlan extends Component {
    state = {
        year: null,
        error: false,
        noError: false,
    };

    componentDidMount() {
        document.title = "Create Work Plan From Proposal - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    componentDidUpdate = () => {
        if (this.state.dont) {
            this.setState({ dont: false });
        }
    };

    createProposal = values => {
        const {
            authState,
            ormFinalWorkPlanViewLoadDetail,
            ormProposalViewLoadDetail,
            ormDraftWorkPlanViewLoadDetail
        } = this.props;

        if (values["wp_due_date"]) {
            values["wp_due_date"] = values["wp_due_date"].substring(0, 10);
        }

        const _this = this;

        fetch("create/workplan/proposal", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                ...values
            })
        })
            .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
            })
            .then(data => {
                ormProposalViewLoadDetail(data.proposal_id, function(data) {
                    setTimeout(function() {
                        _this.setState({ dont: true });
                    }, 1);
                });
                ormDraftWorkPlanViewLoadDetail(data.workplan_id);

                if (data.final_id) {
                    // final was created load it
                    ormFinalWorkPlanViewLoadDetail(data.final_id);
                }
                this.setState({ noError: true });
                if (this.formApi) {
                    this.formApi.setValue("id", null);
                }
            })
            .catch(e => {
                this.setState({ error: true });
            });
    };

    errorValidator = values => {
        const validateRequired = val => {
            return !val ? "Required" : null;
        };

        return {
            year: validateRequired(values.year),
            id: validateRequired(values.id),
            rfp_year: validateRequired(values.rfp_year),
            user: validateRequired(values.user),
            staff: validateRequired(values.staff)
        };
    };

    handleYear = year => {
        const { proposals, years } = this.props;
        const index = years.findIndex(x => x.year === year);
        const ml_year = index > -1 ? years[index].year : "";
        this.setState({ year, ml_year });

        if (year) {
            proposals
                .filter(x => x.ml_year === ml_year)
                .map(x => ({
                    label: x.proposal_unique_id,
                    value: x.proposal_unique_id
                }));
            this.setState({ dont: true });
        }
    };

    render() {
        const { classes, history, proposals, years, users, staff } = this.props;
        const { dont, ml_year, year } = this.state;

        const YEAR_OPTIONS = years.map(x => ({
            label: x.year,
            value: x.year
        }));

        var PROPOSAL_OPTIONS = [];
        if (year) {
            PROPOSAL_OPTIONS = proposals
                .filter(x => x.ml_year === ml_year)
                .map(x => ({
                    label: x.proposal_unique_id + " - " + x.name.substring(0, 20) + "...",
                    value: x.id
                }));
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Create Work Plan From Proposal" admin_page />
                    <Grid item xs={12}>
                        <Form
                            getApi={el => (this.formApi = el)}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            dontValidateOnMount={true}
                            onSubmit={this.createProposal}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16} style={{ marginTop: 10 }}>
                                        <Grid item xs={12}>
                                            <div class="insborder">
                                                <Typography>Instructions:</Typography>
                                                <Typography gutterBottom>
                                                    <ul>
                                                        <li>Action creates a work plan at the draft work plan or final work plan stage of the Configure RFP Process from a copy of a proposal.</li>
                                                        <li>You cannot create a work plan if the system has not been configured for work plans yet.</li>
                                                        <li>Copy can only be made from a proposal that has not been recommended for funding.</li>
                                                        <li>The Project Manager has to be a registered user in the system to give ownership.</li>
                                                        <li>This action cannot be done for M.L. years that don't have draft workplans created from the&nbsp;
                                                            <Link
                                                                onClick={() => history.push("/adm/proposalprocess")}
                                                                className={classes.link}>
                                                                Proposal Process Page
                                                            </Link>.
                                                        </li>
                                                        </ul>
                                                    
                                                    
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            fullWidth
                                                            field="rfp_year"
                                                            label="Select RFP Year To Create Work Plan In"
                                                            options={YEAR_OPTIONS}
                                                        />
                                                    </Grid>
                                                    <Select
                                                        options={YEAR_OPTIONS}
                                                        field="year"
                                                        label="RFP Year of Proposal To Copy"
                                                        eventHandle={this.handleYear}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {!dont && (
                                                        <Select
                                                            options={PROPOSAL_OPTIONS}
                                                            field="id"
                                                            label="Proposal ID and Title"
                                                            fullWidth
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Select options={users} field="user" label="Project Manager" fullWidth />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Select options={staff} field="staff" label="Staff Lead" fullWidth />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <DatePicker
                                                        fullWidth
                                                        field="wp_due_date"
                                                        label={
                                                            <HelpLabel
                                                                showLabel={true}
                                                                inputLabel="Specific Due Date(optional)"
                                                                helpText="This due date overrides the RFP year configure due date for this workplan."
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Checkbox field="added_by_legislature" label="Added By Legislature" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Checkbox field="added_by_lccmr" label="Added By LCCMR" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit">
                                                Create Work Plan From Proposal
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
                <MUISnackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    className={classes.snackbar}
                    open={this.state.noError}
                    autoHideDuration={3000}
                    onClose={() => this.setState({ noError: false })}>
                    <MUISnackbarContent
                        className={classes.snackbarContainerSuccess}
                        message={
                            <span className={classes.snackbarMessage}>
                                <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                                Work Plan Created.
                            </span>
                        }
                    />
                </MUISnackbar>
                <Snackbar
                    handleSnackbarClose={() => this.setState({ error: false })}
                    snackbarOpen={this.state.error}
                    message={"Error Creating Work Plan"}
                />
            </AppContainer>
        );
    }
}

CreateWorkPlan = connect(
    (state, ownProps) => ({
        authState: state.auth,
        proposals: getProposals(state, ownProps),
        staff: getStaff(state, ownProps),
        users: getUsers(state, ownProps),
        years: getYears(state, ownProps)
    }),
    {
        ...ProposalView.actions,
        ...DraftWorkPlanView.actions,
        ...FinalWorkPlanView.actions,
        ...SystemSetting.actions,
        reloadListOfModels: reloadListOfModels
    }
)(CreateWorkPlan);

export default withStyles(styles)(withRouter(CreateWorkPlan));
