import React, { Component } from "react";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { FormField } from "react-form";

const styles = theme => ({
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap"
    },
    rootLabelShrink: {
        marginLeft: 0,
        //marginTop: -8,
        marginBottom: -6,
        position: "relative",
        whiteSpace: "normal"
    },
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "8px 12px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,64,110,.50)"
        },
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: 300
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #be0000"
        }
    },
    disabled: {
        backgroundColor: "#EEEEEE"
    }
});

class DatePickerWrapper extends Component {
    // The first commit of Material-UI
    //const [selectedDate, setSelectedDate] = React.useState(new Date());

    handleDateChange = date => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        const dateFns = new DateFnsUtils();
        var db_format = dateFns.format(date, "yyyy-MM-dd");

        if (date) {
            setValue(db_format + " 12:00:00");
            if (eventHandle) eventHandle(db_format + " 12:00:00");
        }
    };

    render() {
        const { helperText, fieldApi, classes, disabled, ...rest } = this.props;
        const { getValue, getError, getWarning, getSuccess } = fieldApi;
        var value = getValue(),
            error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success || helperText;

        let InputProps = {};
        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput, disabled && classes.disabled),
            error: classes.bootstrapInputError
        };

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiDatePicker
                    value={value !== undefined ? value : null}
                    onChange={this.handleDateChange}
                    openTo="year"
                    views={["year", "month", "day"]}
                    keyboard
                    format="MM/dd/yyyy"
                    InputProps={InputProps}
                    InputLabelProps={{
                        shrink: true,
                        classes: {
                            root: classes.rootLabel,
                            shrink: classes.rootLabelShrink
                        }
                    }}
                    error={hasError}
                    helperText={errorText}
                    {...rest}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

const DatePicker = FormField(DatePickerWrapper);

export default withStyles(styles)(DatePicker);
