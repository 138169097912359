import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";

import MuiTextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel";

import { withStyles, Tooltip } from "@material-ui/core";

const styles = theme => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "8px 12px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,64,110,.50)"
        },
        backgroundColor: "#fff",
        fontSize: 14,
        fontWeight: 300
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #be0000"
        },
        "& textarea": {
            border: "1px solid #be0000"
        }
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #be0000"
    },
    rootLabel: {
        marginLeft: 12,
        marginTop: 3,
        whiteSpace: "nowrap"
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginBottom: -16,
        position: "relative",
        whiteSpace: "normal",
        lineHeight: 1.5
    },
    whiteSpace: {
        whiteSpace: "nowrap"
    },
    disabled: {
        backgroundColor: "#EEEEEE"
    },
    wordCount: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -16,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
    wordCountSmall: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        marginTop: -10,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4
    },
    marginDense: {
        marginTop: 8,
        marginBottom: 4
    },
    dark: {
        "& input": {
            color: "#000000 !important"
        },
        "& div": {
            color: "#000000 !important"
        },
        "& textarea": {
            color: "#000000 !important"
        }
    },
});

function NumberFormatCustom(props) {
    const { inputRef, onChange, readOnly, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                if (!readOnly) {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }
            }}
            thousandSeparator
            prefix="$"
            allowNegative={false}
            decimalScale={0}
        />
    );
}

function NumberFormatCustomReadOnly(props) {
    const { inputRef, onChange, readOnly, ...other } = props;

    return (
        <NumberFormat
            {...other}
            readOnly={true}
            getInputRef={inputRef}
            onValueChange={values => {
                if (!readOnly) {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }
            }}
            thousandSeparator
            prefix="$"
            allowNegative={false}
            decimalScale={0}
        />
    );
}

function NumberFormatCustom2(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            thousandSeparator
            allowNegative={false}
            decimalScale={2}
        />
    );
}

function PhoneFormat(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"\u2000"}
            showMask
        />
    );
}

// See https://react-form.js.org/#/custom-input
class TextFieldWrapper extends Component {
    handleChange = e => {
        let value = e.target.value;

        const { fieldApi, eventHandle, readOnly, numberRange, min, max, eventHandleProps1, maxWords } = this.props;
        const { setValue, getFieldName } = fieldApi;

        if (numberRange) {
            if (parseInt(value) < min) {
                setValue(min.toString());
                if (eventHandle) eventHandle(min.toString(), getFieldName(), eventHandleProps1);
            } else if (parseInt(value) > max) {
                setValue(max.toString());
                if (eventHandle) eventHandle(max.toString(), getFieldName(), eventHandleProps1);
            } else {
                setValue((!isNaN(parseInt(value)) ? parseInt(value, 10) : "").toString());
                if (eventHandle) eventHandle(value, getFieldName(), eventHandleProps1);
            }
            return;
        }

        if (!readOnly) {
            if (maxWords) {
                var fullStr = value + " ";
                var initial_whitespace_rExp = /^[^A-Za-z0-9]+/gi;
                var left_trimmedStr = fullStr.replace(initial_whitespace_rExp, "");
                var non_alphanumerics_rExp = /[^A-Za-z0-9'’-]+/gi;
                var cleanedStr = left_trimmedStr.replace(non_alphanumerics_rExp, " ");
                var splitString = cleanedStr.split(" ");
    
                var textLimit = parseFloat(maxWords);
    
                if (splitString.length > textLimit) {
                    var non_alphanumerics_rExp2 = /(<([^A-Za-z0-9'’-])>)+/gi;
                    var cleanedStr2 = left_trimmedStr.replace(non_alphanumerics_rExp2, " ");
                    var splitString2 = cleanedStr2.split(" ");
                    var text = "";
                    for (var i = 0; i < splitString2.length - (splitString.length - textLimit); i++) {
                        if (splitString2[i]) text += splitString2[i] + " ";
                    }
                    value = text.trim();
                }
            }

            setValue(value);

            if (eventHandle) eventHandle(value, getFieldName(), eventHandleProps1);
        }
    };
    handleClear = () => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue, getFieldName } = fieldApi;

        setValue(null);

        if (eventHandle) eventHandle(null, getFieldName());
    };

    render() {
        const {
            fieldApi,
            helperText,
            SelectProps,
            classes,
            label,
            children,
            disabled,
            unitsVisual,
            maxWords,
            useTextFormat,
            useFloatFormat,
            select,
            selectHelp,
            multiline,
            phoneNumber,
            filterInput,
            readOnly,
            rows,
            tableFormat,
            login,
            checkFocus,
            focusRemoval,
            ...rest
        } = this.props;
        const { getValue, getError, getWarning, getSuccess, /*setTouched,*/ getFieldName } = fieldApi;

        const value = getValue() || (SelectProps ? (SelectProps.multiple ? [] : "") : getValue() === 0 ? 0 : ""),
            error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success || helperText;

        let InputProps = {};
        let inputProps = {};

        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(
                classes.bootstrapInput,
                (disabled || readOnly) && classes.disabled,
                rows && classes[`row${rows}`]
            ),
            error: classes.bootstrapInputError
        };

        if (this.props.aLabel) {
            inputProps["aria-labelledby"] = this.props.aLabel;
        }

        if (this.props.tableLabel) {
            inputProps["aria-label"] = this.props.tableLabel;
        }

        // Material-UI doesn't have a way to append so build it before hand
        const fieldId = getFieldName() + Math.random();
        if (hasError) {
            if (this.props.passwordaria && errorText.toLowerCase().includes("password")) {
                inputProps["aria-describedby"] = `${fieldId}-helper-text pwdr1 pwdr2 pwdr3 pwdr4`;
            } else {
                inputProps["aria-describedby"] = `${fieldId}-helper-text`;
            }
        }

        if (useTextFormat) {
            if (readOnly) InputProps["inputComponent"] = NumberFormatCustomReadOnly;
            else InputProps["inputComponent"] = NumberFormatCustom;
        } else if (useFloatFormat) {
            InputProps["inputComponent"] = NumberFormatCustom2;
        } else if (phoneNumber) {
            InputProps["inputComponent"] = PhoneFormat;
        }

        let sProps = {};
        if (SelectProps !== undefined) {
            sProps = SelectProps;
        }
        sProps["name"] = fieldId;
        sProps["classes"] = {
            select: hasError ? classes.bootstrapSelectError : ""
        };

        var val = value.toString();

        if (val && filterInput) {
            InputProps["endAdornment"] = (
                <InputAdornment position="end">
                    <Tooltip title="Clear Search">
                        <IconButton aria-label="Clear Search" onClick={this.handleClear}>
                            <Cancel />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            );
        }

        if (maxWords) {
            var count;
            var fullStr = val + " ";
            var initial_whitespace_rExp = /^[^A-Za-z0-9]+/gi;
            var left_trimmedStr = fullStr.replace(initial_whitespace_rExp, "");
            var non_alphanumerics_rExp = /[^A-Za-z0-9'’-]+/gi;
            var cleanedStr = left_trimmedStr.replace(non_alphanumerics_rExp, " ");
            var splitString = cleanedStr.split(" ");
            count = splitString.length - 1;

            var textLimit = parseFloat(maxWords);

            if (splitString.length > textLimit) {
                var non_alphanumerics_rExp2 = /(<([^A-Za-z0-9'’-])>)+/gi;
                var cleanedStr2 = left_trimmedStr.replace(non_alphanumerics_rExp2, " ");
                var splitString2 = cleanedStr2.split(" ");
                var text = "";
                for (var i = 0; i < splitString2.length - (splitString.length - textLimit); i++) {
                    if (splitString2[i]) text += splitString2[i] + " ";
                }
                val = text.trim();
                fullStr = val + " ";
                left_trimmedStr = fullStr.replace(initial_whitespace_rExp, "");
                cleanedStr = left_trimmedStr.replace(non_alphanumerics_rExp, " ");
                splitString = cleanedStr.split(" ");
                count = splitString.length - 1;
            }

            var color = "auto";
            if (count > textLimit) color = "#be0000";
        }

        return (
            <>
                <MuiTextField
                    value={val}
                    error={hasError}
                    label={select && selectHelp ? selectHelp : !label && !select ? children : label}
                    id={fieldId}
                    disabled={disabled}
                    helperText={errorText}
                    onChange={this.handleChange}
                    //onBlur={() => setTouched()}
                    SelectProps={sProps}
                    InputProps={InputProps}
                    inputProps={inputProps} //eslint-disable-line
                    multiline={multiline}
                    readOnly
                    InputLabelProps={{
                        id: fieldId + "_label",
                        shrink: true,
                        classes: {
                            root: classes.rootLabel,
                            shrink: classes.rootLabelShrink
                        }
                    }}
                    inputRef={(input) => {
                        if (checkFocus && checkFocus.highlightedField && checkFocus.highlightedField === getFieldName() && input != null) {
                           input.focus();
                           focusRemoval({
                               id: checkFocus.id,
                               highlightedField: null,
                           })
                        }
                    }}
                    onKeyPress={e => {
                        if (e.key === "Enter" && !multiline && !login && e.target.type !== "button") e.preventDefault();
                    }}
                    children={children}
                    select={select}
                    margin={tableFormat ? "dense" : "normal"}
                    classes={{
                        marginNormal: classes.marginDense
                    }}
                    className={disabled ? classes.dark : null}
                    {...rest}
                />
                {maxWords && count > 0 && (
                    <Typography
                        className={multiline ? classes.wordCount : classes.wordCountSmall}
                        style={{ color: color, width: rows ? "calc(100% - 8px)" : "calc(100% - 10px)" }}>
                        Word Count: {count} out of {maxWords}
                    </Typography>
                )}
            </>
        );
    }
}

const TextField = FormField(TextFieldWrapper);

export default withStyles(styles)(TextField);
