import React, { Component } from "react";
import pack from "../../../package.json";
import { withStyles } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";

const styles = {
    linkColor: {
        color: "#fff",
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
            backgroundColor: "#696969"
        }
    },
    rootText: {
        background: "#333333",
        textAlign: "center",
        color: "#fff",
        padding: "8px 0"
    },
    versionText: {
        background: "#333333",
        textAlign: "center",
        color: "#fff"
    },
    border: {
        border: "none"
    }
};

class Footer extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Drawer anchor="bottom" variant="permanent" classes={{ paper: classes.border }}>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <Typography variant="caption" style={{ paddingBottom: 0 }} className={classes.rootText}>
                            <a
                                className={classes.linkColor}
                                rel="noopener noreferrer"
                                href="https://www.lccmr.mn.gov/"
                                target="_blank">
                                LCCMR Website
                            </a>
                            &nbsp;|&nbsp;
                            <a
                                className={classes.linkColor}
                                rel="noopener noreferrer"
                                href="https://www.lccmr.mn.gov/about/staff-index.html"
                                target="_blank">
                                LCCMR Staff
                            </a>
                            &nbsp;|&nbsp;
                            <a
                                className={classes.linkColor}
                                rel="noopener noreferrer"
                                href="https://www.lccmr.mn.gov/about/members/members-index.html"
                                target="_blank">
                                LCCMR Members
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" className={classes.rootText}>
                            100 Rev. Dr. Martin Luther King Jr. Blvd., State Office Building, Room 65, St. Paul, MN
                            55155
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" className={classes.versionText}>
                            Version {pack.version}
                        </Typography>
                    </Grid>
                </Grid>
            </Drawer>
        );
    }
}

export default withStyles(styles)(Footer);
