import React, { Component } from "react";
import { FormField } from "react-form";

import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";

const styles = {
    error: {
        color: "#be0000"
    }
};

class CheckboxWrapper extends Component {
    handleChange = event => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue, getFieldName } = fieldApi;

        setValue(event.target.checked);

        if (eventHandle) eventHandle(event.target.checked, this, getFieldName());
    };

    render() {
        const { fieldApi, label, align, classes, color, children, style, ...rest } = this.props;

        const { getValue, getError, getWarning, setTouched } = fieldApi;

        const value = getValue() || false,
            error = getError(),
            warning = getWarning(),
            hasError = !!(error || warning);

        let inputProps = {};
        if (this.props.aLabel) {
            inputProps["aria-labelledby"] = this.props.aLabel;
        }
        if (this.props.tableLabel) {
            inputProps["aria-label"] = this.props.tableLabel;
        }
        if (!label && !children) {
            return (
                <MuiCheckbox
                    inputProps={inputProps}
                    color={color ? color : "primary"}
                    onChange={this.handleChange}
                    onBlur={() => setTouched()}
                    onKeyPress={e => {
                        if (e.key === "Enter") e.preventDefault();
                    }}
                    checked={value}
                    {...rest}
                />
            )
        }

        return (
            <div className={align} style={style}>
                <FormControlLabel
                    control={
                        <div>
                            <MuiCheckbox
                                inputProps={inputProps}
                                color={color ? color : "primary"}
                                onChange={this.handleChange}
                                onBlur={() => setTouched()}
                                onKeyPress={e => {
                                    if (e.key === "Enter") e.preventDefault();
                                }}
                                checked={value}
                                {...rest}
                            />
                        </div>
                    }
                    label={children ? children : label}
                    classes={{
                        label: hasError ? classes.error : ""
                    }}
                />
                {hasError && (
                    <FormHelperText className={align} error={true}>
                        {error}
                    </FormHelperText>
                )}
            </div>
        );
    }
}

const Checkbox = FormField(CheckboxWrapper);

export default withStyles(styles)(Checkbox);
