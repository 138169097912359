import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CreateIcon from "@material-ui/icons/Create";
import ViewIcon from "@material-ui/icons/RemoveRedEye";

import { withStyles } from "@material-ui/core";

import { DraftProposalAttachment, FinalProposalAttachment } from "../models";
import { getValue } from "../../../api/utils";
import TextField from "../../common/TextField";
import FileInput from "../../common/FileInput";
import HelpLabel from "../../common/HelpLabel";
import Select from "../../common/Select";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import Subquestion from "../../common/Subquestion";
import { createSelector } from "../../common/orm";
import { MAKE_OPTIONS } from "../../../api/constants";
import { WorkPlanTab } from "../../home/models";

const getAttachments = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalAttachment.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        } else {
            return session.FinalProposalAttachment.filter({ proposal: id })
                .orderBy("id")
                .toModelArray();
        }
    }
);
const getTab = WorkPlanTab.selectMessage(7);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    hide: {
        display: "none"
    },
    centerAlign: {
        textAlign: "center"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    inline: {
        display: "inline"
    },
    asterisk: {
        whiteSpace: "normal",
        lineHeight: 1.5,
        color: "#212529 !important",
        fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif"
    }
});

const columnDataOptional = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        width: "20px"
    },
    { id: "description", numeric: false, label: "Description" },
    { id: "title", numeric: false, label: "Document Title" },
    { id: "file", numeric: true, label: "Link to File" },
    { id: "date_added", numeric: false, label: "Date Uploaded" }
];

const columnData = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        width: "20px"
    },
    { id: "title", numeric: false, label: "Document Title" },
    { id: "file", numeric: true, label: "Link to File" },
    { id: "date_added", numeric: false, label: "Date Uploaded" }
];

const columnData3 = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        width: "20px"
    },
    { id: "description", numeric: true, label: "Description" },
    { id: "title", numeric: false, label: "Title" },
    { id: "hyperlink", numeric: true, label: "Hyperlink" },
    { id: "date_added", numeric: false, label: "Date Link Added" }
];

class AttachmentTab extends Component {
    state = {
        uploadEdit: false,
        uploadEdit2: false,
        dialogOpen: false,
        attachmentType: null,
        key: null,
        addendumDate: null,
        isDraft: this.props.match.path === "/draft/workplan/:id",
        ormPropPrefix: this.props.match.path === "/draft/workplan/:id" ? "Draft" : "Final",
        formKey: window.performance.now()
    };

    componentDidMount() {
        const { handleUnsavedFields, ormParentUpdateLocalOnly, plan } = this.props;
        document.title = "Work Plan: Attachments - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (!plan.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormParentUpdateLocalOnly({
                    id: plan.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    updatePlan(values, fromStepper) {
        const { id } = this.props.plan;
        const {
            ormParentUpdate,
            history,
            handleUnsavedFields,
            handleNext,
            handlePrev,
            getDateTimeStamp,
            plan,
            is_read_only,
            ormParentUpdateLocalOnly
        } = this.props;
        const { uploadEdit, uploadEdit2 } = this.state;

        if (typeof fromStepper.step !== "undefined") {
            // If this is hit updateProposal initiated through a stepper click
            // Set the values of the steps to the one that was clicked.
            values.activeStep = fromStepper["step"];
            values.activeSubStep = fromStepper["subStep"];

            // If not draft just update the tab steps locally
            if (is_read_only) {
                ormParentUpdateLocalOnly({
                    id: id,
                    ...values
                });
                return;
            }
        }

        // When an attachment is submitted to the server it is transmitted as base64
        // The server returns a URL after which acts like the file itself and won't cause a reupload henceforth
        // The problem is that before the result comes back from the server the next form has already rendered
        // When the next form renders it still contains the base64 value
        // The base64 value gets retransmitted to the server everytime and the server resaves it over and over again on every form submission
        // The (poor) solution is to switch the values.[file] with the proposal.[file] value since by the time the form is submitted again the correct URL result has returned from the server
        // The good solution would be somehow refresh (re-render) the form after the result comes back from the server
        // Using the poor solution in every updateProposal function plus here with the addition of checking if the file was updated - if not perform the solution
        if (!uploadEdit && plan.map !== "") {
            values.map = plan.map;
        }
        if (!uploadEdit2 && plan.financial_capacity !== "") {
            values.financial_capacity = plan.financial_capacity;
        }
        values.modify_date_label = plan.modify_date_label;
        values.update_date = getDateTimeStamp();

        // If there's a update to addendum_date set it in form so it doesn't get set to null
        // when leaving this tab.
        if (this.state.addendumDate) {
            values["addendum_date"] = this.state.addendumDate;
        }

        ormParentUpdate({
            id: id,
            ...values
        });

        handleUnsavedFields(false);

        if (typeof fromStepper.step === "undefined") {
            // If this is hit updateProposal was initialed by the bottom save button
            // Either go to the dashboard or call handlNext to determine the next step
            if (!uploadEdit && !uploadEdit2) {
                if (this.state.draftClick) {
                    history.push("/dashboard/");
                } else if (this.state.previousStep) {
                    handlePrev();
                } else {
                    handleNext();
                }
            } else {
                const f = this;
                setTimeout(function() {
                    // FIXME: Call this function after the update has returned from the server with the uploaded URL
                    f.formKey = window.performance.now();
                    f.setState({ uploadEdit: false, uploadEdit2: false });
                }, 2000);
            }
        }
    }

    getDateTimeStamp = () => {
        const date = new Date();
        const dateFns = new DateFnsUtils();
        return dateFns.format(date, "yyyy-MM-dd HH:mm:ss.SSSSSS");
    };

    updateAddendumDate = () => {
        const { plan } = this.props;
        const { isDraft } = this.state;

        const temp_date = this.getDateTimeStamp();

        if (isDraft) {
            this.props.ormParentUpdate({
                id: plan.id,
                addendum_date: temp_date
            });
            this.setState({ addendumDate: temp_date });
        }
    };

    formatDate(dateString) {
        if (!dateString) {
            return null;
        }
        const d = new Date(dateString.replace(/-/g, "/") + " 12:00:00");
        return d.toLocaleDateString();
    }

    render() {
        const { classes, plan, proposal, handleUnsavedFields, attachments, ActionMenu, tab, authState, ormParentUpdateLocalOnly } = this.props;
        const { isDraft, dialogOpen, attachmentId, attachmentType, key } = this.state;
        var is_read_only = this.props.is_read_only;

        var is_staff = false;
        var reporting_disabled = false;
        var reporting_add_disabled = false;
        var res_add = false;
        const locked = this.props.authState && this.props.authState.user && (this.props.authState.user.role === "Member" || this.props.authState.user.is_read_only);
        if (authState && authState.user && authState.user.role === "Staff") { // Staff Never disabled
            is_read_only = false;
            is_staff = true;
        } else {
            res_add = proposal.lock_research_addendum || locked;
        }

        if (!is_staff) { // Non staff use read only parameter
            reporting_disabled = is_read_only;
            reporting_add_disabled = is_read_only;
            if (!isDraft) {
                if (proposal.status === "Project Completed" || ((proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && (
                    plan.reporting_status === "Update Due" || plan.reporting_status === "Final Update Due" || plan.reporting_status === "Additional Update In Progress" || plan.reporting_status === "Update Revisions Needed" || plan.reporting_status === "Final Update Revisions Needed" || plan.reporting_status === "Additional Update Revisions Needed" 
                ))) {
                    reporting_disabled = false;
                }
                if (!isDraft) {
                    if (proposal.status === "Project Completed" || proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") {
                        reporting_add_disabled = false;
                    }
                }
            }
        }
        if (this.props.authState && this.props.authState.user && this.props.authState.user.is_read_only) {
            is_read_only = true;
            reporting_disabled = true;
            reporting_add_disabled = true;
            res_add = true;
        }

        const Restoration = getValue(plan, "land_rights").indexOf("Restoration") > -1;
        const Conservation = getValue(plan, "land_rights").indexOf("Conservation Easement Acquisition") > -1;
        const Easement = getValue(plan, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(plan, "land_rights").indexOf("Fee Acquisition") > -1;
        var is_parcel = false;
        if (Restoration || Conservation || Easement || Fee) is_parcel = true;

        const recipient = getValue(plan, "org_data.recipient");
        const org_name = getValue(plan, "org_data.organization_name");
        const ml_year = getValue(plan, "ml_year");
        const board_resolution =
            recipient === "Tribal" ||
            recipient === "Federal Government" ||
            recipient === "Local/Regional Government" ||
            recipient === "K-12 Education" ||
            recipient === "Non-Profit Business/Entity" ||
            (ml_year >= 2024 && org_name && org_name.includes("U of MN"));
        const capacity = recipient === "For-Profit Business/Entity" || recipient === "Private College/University"|| recipient === "Local/Regional Government" || recipient === "Non-Profit Business/Entity";


        return (
            <>
                <Form
                    getApi={el => (this.form = el)}
                    key={this.formKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    defaultValues={plan.formData}
                    formDidUpdate={() => handleUnsavedFields(true)}
                    onSubmit={(values, fromStepper) => this.updatePlan(values, fromStepper)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography variant="titleAction">Required Attachments</Typography>
                                    {ActionMenu}
                                    <HelpLabel
                                        style={{ display: "inline-block", marginTop: 8 }}
                                        showLabel={true}
                                        htmlText={true}
                                        helpText={tab.tab_help_texts.find(tht => tht.label === "Required Attachments").text}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <FileInput
                                        field="map"
                                        id="map"
                                        checkFocus={plan} 
                                        focusRemoval={ormParentUpdateLocalOnly}
                                        showLoading={true}
                                        disabled={is_read_only}
                                        label={is_parcel ? "Map" : "Visual Component"}
                                        accept={is_parcel ? ".pdf" : ".pdf,.docx,.doc"}
                                        fullWidth
                                        eventHandle={() => {
                                            this.setState({ uploadEdit: true });
                                            formApi.submitForm({});
                                        }}>
                                        <HelpLabel
                                            inputLabel={is_parcel ? "Map" : "Visual Component"}
                                            style={{ display: "inline-block", marginTop: 8 }}
                                            showLabel={true}
                                            htmlText={true}
                                            helpText={
                                                is_parcel
                                                    ? tab.tab_help_texts.find(tht => tht.label === "Map").text
                                                    : tab.tab_help_texts.find(tht => tht.label === "Visual Component").text
                                            }
                                        />
                                    </FileInput>
                                    <Subquestion
                                        component={
                                            <TextField
                                                multiline
                                                disabled={is_read_only}
                                                field="map_description"
                                                checkFocus={plan} 
                                                focusRemoval={ormParentUpdateLocalOnly}
                                                maxWords="50"
                                                label="Provide description of what is shown in the visual or graphic for those not able to view them."
                                                fullWidth
                                            />
                                        }
                                    />
                                </Grid>
                                {board_resolution && (
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <HelpLabel
                                            inputLabel="Board Resolution or Letter"
                                            showLabel={true}
                                            htmlText={true}
                                            helpText={tab.tab_help_texts.find(tht => tht.label === "Board Resolution or Letter") ? tab.tab_help_texts.find(tht => tht.label === "Board Resolution or Letter").text : ""}
                                        />
                                        <Button
                                            variant="contained"
                                            style={{ float: "right" }}
                                            autoFocus={plan.highlightedField === "board_resolution"}       
                                            color="primary"
                                            disabled={is_read_only}
                                            onClick={() =>
                                                this.setState({
                                                    dialogOpen: true,
                                                    attachmentType: "Board Resolution",
                                                    attachmentId: null,
                                                    key: window.performance.now()
                                                })
                                            }>
                                            Add Board Resolution or Letter File
                                        </Button>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead stickyHeader columnData={columnData} />
                                            <TableBody>
                                                {attachments
                                                    .filter(a => a.type === "Board Resolution")
                                                    .map(n => {
                                                        return (
                                                            <TableRow key={n.id}>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Edit Attachment">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Edit Attachment"
                                                                            className={is_read_only ? classes.hide : classes.deleteWidth}
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    dialogOpen: true,
                                                                                    attachmentId: n,
                                                                                    attachmentType: "Board Resolution"
                                                                                })
                                                                            }>
                                                                            <CreateIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete Attachment">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Delete Attachment"
                                                                            className={is_read_only ? classes.hide : classes.deleteWidth}
                                                                            onClick={() =>
                                                                                this.props[`orm${this.state.ormPropPrefix}ProposalAttachmentDelete`](n.id)
                                                                            }>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="View Attachment">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="View Attachment"
                                                                            className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    dialogOpen: true,
                                                                                    attachmentId: n,
                                                                                    attachmentType: "Board Resolution"
                                                                                })
                                                                            }>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.title}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    <a href={n.file} target="_blank" rel="noopener noreferrer">
                                                                        Open {n.title}
                                                                    </a>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{this.formatDate(n.date_added)}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                {attachments.filter(a => a.type === "Board Resolution").length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Attachments Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                {capacity && ml_year <= 2024 && (
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <FileInput
                                            field="financial_capacity"
                                            id="financial_capacity"
                                            checkFocus={plan} 
                                            focusRemoval={ormParentUpdateLocalOnly}
                                            disabled={is_read_only}
                                            showLoading={true}
                                            accept=".pdf,.docx,.doc"
                                            fullWidth
                                            label="Financial Capacity Information for non-profit organizations, political subdivisions, and for-profit business entities"
                                            eventHandle={() => {
                                                this.setState({ uploadEdit2: true });
                                                formApi.submitForm({});
                                            }}>
                                            <HelpLabel
                                                inputLabel="Financial Capacity Information for non-profit organizations, political subdivisions, and for-profit business entities"
                                                showLabel={true}
                                                htmlText={true}
                                                helpText={
                                                    tab.tab_help_texts.find(
                                                        tht => tht.label === "Financial Capacity Information for non-profit organizations, political subdivisions, and for-profit business entities"
                                                    ).text
                                                }
                                            />
                                        </FileInput>
                                        <Grid item xs={12}></Grid>
                                    </Grid>
                                )}
                                {capacity && ml_year >= 2025 && (
                                    <Grid item xs={12} lg={10} xl={7}>
                                        <HelpLabel
                                            inputLabel="Financial Capacity Information for non-profit organizations, political subdivisions, and for-profit business entities"
                                            showLabel={true}
                                            htmlText={true}
                                            helpText={tab.tab_help_texts.find(tht => tht.label === "Financial Capacity Information for non-profit organizations, political subdivisions, and for-profit business entities").text}
                                        />
                                        <Button
                                            variant="contained"
                                            autoFocus={proposal.highlightedField === "financial_capacity"}          
                                            style={{ float: "right" }}
                                            color="primary"
                                            disabled={is_read_only}
                                            onClick={() =>
                                                this.setState({
                                                    dialogOpen: true,
                                                    attachmentType: "Financial Capacity",
                                                    attachmentId: null,
                                                    key: window.performance.now()
                                                })
                                            }>
                                            Add Financial Capacity Document
                                        </Button>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead stickyHeader columnData={columnData} />
                                            <TableBody>
                                                {attachments
                                                    .filter(a => a.type === "Financial Capacity")
                                                    .map(n => {
                                                        return (
                                                            <TableRow key={n.id}>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    <Tooltip title="Edit Attachment">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Edit Attachment"
                                                                            className={is_read_only ? classes.hide : classes.deleteWidth}
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    dialogOpen: true,
                                                                                    attachmentId: n,
                                                                                    attachmentType: "Financial Capacity"
                                                                                })
                                                                            }>
                                                                            <CreateIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete Attachment">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Delete Attachment"
                                                                            className={is_read_only ? classes.hide : classes.deleteWidth}
                                                                            onClick={() =>
                                                                                this.props[`orm${this.state.ormPropPrefix}ProposalAttachmentDelete`](n.id)
                                                                            }>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Edit Attachment">
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="View Attachment"
                                                                            className={!is_read_only ? classes.hide : classes.deleteWidth}
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    dialogOpen: true,
                                                                                    attachmentId: n,
                                                                                    attachmentType: "Financial Capacity"
                                                                                })
                                                                            }>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.title}</CustomTableCell>
                                                                <CustomTableCell>
                                                                    <a href={n.file} target="_blank" rel="noopener noreferrer">
                                                                        Open {n.title}
                                                                    </a>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{this.formatDate(n.date_added)}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                {attachments.filter(a => a.type === "Financial Capacity").length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Attachments Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                {proposal.peer_review && (
                                    <>
                                        <Grid item xs={12} lg={10} xl={7}>
                                            <HelpLabel
                                                inputLabel={
                                                    <>
                                                        Research Addendum
                                                        {isDraft && (
                                                            <span className={classes.asterisk}>
                                                                <b>&thinsp;(new)</b>
                                                            </span>
                                                        )}
                                                    </>
                                                }
                                                showLabel={true}
                                                htmlText={true}
                                                helpText={tab.tab_help_texts.find(tht => tht.label === "Research Addendum").text}
                                            />
                                            <Button
                                                variant="contained"
                                                style={{ float: "right" }}
                                                color="primary"
                                                disabled={res_add}
                                                onClick={() =>
                                                    this.setState({
                                                        dialogOpen: true,
                                                        attachmentType: "Research Addendum",
                                                        attachmentId: null,
                                                        key: window.performance.now()
                                                    })
                                                }>
                                                Add Research Addendum File
                                            </Button>
                                            <Table className={classes.table}>
                                                <EnhancedTableHead stickyHeader columnData={columnData} />
                                                <TableBody>
                                                    {attachments
                                                        .filter(a => a.type === "Research Addendum")
                                                        .map(n => {
                                                            return (
                                                                <TableRow key={n.id}>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        <Tooltip title="Edit Attachment">
                                                                            <Button
                                                                                color="primary"
                                                                                aria-label="Edit Attachment"
                                                                                className={res_add ? classes.hide : classes.deleteWidth}
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        dialogOpen: true,
                                                                                        attachmentId: n,
                                                                                        attachmentType: "Research Addendum"
                                                                                    })
                                                                                }>
                                                                                <CreateIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Delete Attachment">
                                                                            <Button
                                                                                color="primary"
                                                                                aria-label="Delete Attachment"
                                                                                className={res_add ? classes.hide : classes.deleteWidth}
                                                                                onClick={() => {
                                                                                    this.props[`orm${this.state.ormPropPrefix}ProposalAttachmentDelete`](n.id);
                                                                                    this.updateAddendumDate();
                                                                                }}>
                                                                                <DeleteIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <Tooltip title="View Attachment">
                                                                            <Button
                                                                                color="primary"
                                                                                aria-label="View Attachment"
                                                                                className={!res_add ? classes.hide : classes.deleteWidth}
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        dialogOpen: true,
                                                                                        attachmentId: n,
                                                                                        attachmentType: "Research Addendum"
                                                                                    })
                                                                                }>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.title}</CustomTableCell>
                                                                    <CustomTableCell>
                                                                        <a href={n.file} target="_blank" rel="noopener noreferrer">
                                                                            Open {n.title}
                                                                        </a>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{this.formatDate(n.date_added)}</CustomTableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {attachments.filter(a => a.type === "Research Addendum").length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                                                <caption style={{display:"inline"}}>No Attachments Found</caption>
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography variant="titleAction">Supplemental Attachments</Typography>
                                    <HelpLabel
                                        style={{ display: "inline-block", marginTop: 8 }}
                                        showLabel={true}
                                        htmlText={true}
                                        helpText={tab.tab_help_texts.find(tht => tht.label === "Supplemental Attachments").text}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <Typography className={classes.inline}>Capital Project Questionnaire, Budget Supplements, Support Letter, Photos, Media, Other</Typography>
                                    <Button
                                        variant="contained"
                                        style={{ float: "right" }}
                                        disabled={reporting_add_disabled}
                                        color="primary"
                                        onClick={() =>
                                            this.setState({
                                                dialogOpen: true,
                                                attachmentType: "Support Letter",
                                                attachmentId: null,
                                                key: window.performance.now()
                                            })
                                        }>
                                        Add Capital Project Questionnaire, Budget Supplements, Support Letter, Photos, Media, Other
                                    </Button>
                                    <Table className={classes.table}>
                                        <EnhancedTableHead stickyHeader columnData={columnDataOptional} />
                                        <TableBody>
                                            {attachments
                                                .filter(a => a.type === "Support Letter")
                                                .map(n => {
                                                    return (
                                                        <TableRow key={n.id}>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Tooltip title="Edit Attachment">
                                                                    <Button
                                                                        color="primary"
                                                                        aria-label="Edit Attachment"
                                                                        className={reporting_disabled ? classes.hide : classes.deleteWidth}
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                dialogOpen: true,
                                                                                attachmentId: n,
                                                                                attachmentType: "Support Letter"
                                                                            })  
                                                                        }>
                                                                        <CreateIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip title="Delete Attachment">
                                                                    <Button
                                                                        color="primary"
                                                                        aria-label="Delete Attachment"
                                                                        className={reporting_disabled ? classes.hide : classes.deleteWidth}
                                                                        onClick={() =>
                                                                            this.props[`orm${this.state.ormPropPrefix}ProposalAttachmentDelete`](n.id)
                                                                        }>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip title="View Attachment">
                                                                    <Button
                                                                        color="primary"
                                                                        aria-label="View Attachment"
                                                                        className={!reporting_disabled ? classes.hide : classes.deleteWidth}
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                dialogOpen: true,
                                                                                attachmentId: n,
                                                                                attachmentType: "Support Letter"
                                                                            })
                                                                        }>
                                                                        <ViewIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.description}</CustomTableCell>
                                                            <CustomTableCell>{n.title}</CustomTableCell>
                                                            <CustomTableCell>
                                                                <a href={n.file} target="_blank" rel="noopener noreferrer">
                                                                    Open {n.title}
                                                                </a>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{this.formatDate(n.date_added)}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {attachments.filter(a => a.type === "Support Letter").length < 1 && (
                                                <TableRow>
                                                    <CustomTableCell colSpan={5} className={classes.centerAlign}>
                                                        <caption style={{display:"inline"}}>No Attachments Found</caption>
                                                    </CustomTableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7}>
                                    <HelpLabel
                                        inputLabel={
                                            <>
                                                Hyperlinks
                                                {isDraft && (
                                                    <span className={classes.asterisk}>
                                                        <b>&thinsp;(new)</b>
                                                    </span>
                                                )}
                                            </>
                                        }
                                        showLabel={true}
                                        htmlText={true}
                                        helpText={tab.tab_help_texts.find(tht => tht.label === "Hyperlinks").text}
                                    />
                                    <Button
                                        variant="contained"
                                        style={{ float: "right" }}
                                        color="primary"
                                        disabled={reporting_add_disabled}
                                        onClick={() =>
                                            this.setState({
                                                dialogOpen: true,
                                                attachmentType: "Media Links",
                                                attachmentId: null,
                                                key: window.performance.now()
                                            })
                                        }>
                                        Add Link
                                    </Button>
                                    <Table className={classes.table}>
                                        <EnhancedTableHead stickyHeader columnData={columnData3} />
                                        <TableBody>
                                            {attachments
                                                .filter(a => a.type === "Media Links")
                                                .map(n => {
                                                    return (
                                                        <TableRow key={n.id}>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Tooltip title="Edit Attachment">
                                                                    <Button
                                                                        color="primary"
                                                                        aria-label="Edit Attachment"
                                                                        className={reporting_disabled ? classes.hide : classes.deleteWidth}
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                dialogOpen: true,
                                                                                attachmentId: n,
                                                                                attachmentType: "Media Links"
                                                                            })
                                                                        }>
                                                                        <CreateIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip title="Delete Attachment">
                                                                    <Button
                                                                        color="primary"
                                                                        aria-label="Delete Attachment"
                                                                        className={reporting_disabled ? classes.hide : classes.deleteWidth}
                                                                        onClick={() =>
                                                                            this.props[`orm${this.state.ormPropPrefix}ProposalAttachmentDelete`](n.id)
                                                                        }>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip title="View Attachment">
                                                                    <Button
                                                                        color="primary"
                                                                        aria-label="View Attachment"
                                                                        className={!reporting_disabled ? classes.hide : classes.deleteWidth}
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                dialogOpen: true,
                                                                                attachmentId: n,
                                                                                attachmentType: "Media Links"
                                                                            })
                                                                        }>
                                                                        <ViewIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.description}</CustomTableCell>
                                                            <CustomTableCell>{n.title}</CustomTableCell>
                                                            <CustomTableCell>
                                                                <a href={n.hyperlink} target="_blank" rel="noopener noreferrer">
                                                                    Open {n.title}
                                                                </a>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{this.formatDate(n.date_added)}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {attachments.filter(a => a.type === "Media Links").length < 1 && (
                                                <TableRow>
                                                    <CustomTableCell colSpan={5} className={classes.centerAlign}>
                                                        <caption style={{display:"inline"}}>No Attachments Found</caption>
                                                    </CustomTableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={12} lg={10} xl={7} className={classes.rightAlign}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        style={{ float: "left" }}
                                        disabled={is_read_only}
                                        onClick={() => this.setState({ draftClick: false, previousStep: true })}
                                        className={classes.button}>
                                        Save and Return to Previous Step
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={is_read_only}
                                        onClick={() => this.setState({ draftClick: true })}
                                        className={classes.button}>
                                        Save Draft and Return to Dashboard
                                    </Button>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={is_read_only}
                                        onClick={() => this.setState({ draftClick: false, previousStep: false })}>
                                        Save and Proceed to Next Step
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Form>
                <AttachmentDialog
                    is_read_only={is_read_only}
                    open={dialogOpen}
                    updateAddendumDate={this.updateAddendumDate}
                    ormProposalAttachmentCreate={this.props[`orm${this.state.ormPropPrefix}ProposalAttachmentCreate`]}
                    attachmentType={attachmentType}
                    ormProposalAttachmentUpdate={this.props[`orm${this.state.ormPropPrefix}ProposalAttachmentUpdate`]}
                    attachmentId={attachmentId}
                    returnDialog={() => this.setState({ dialogOpen: false })}
                    classes={classes}
                    plan={plan}
                    proposal={proposal}
                    formkey={key}
                    reporting_disabled={reporting_disabled}
                    reporting_add_disabled={reporting_add_disabled}
                    res_add={res_add}
                />
            </>
        );
    }
}

class AttachmentDialog extends Component {
    addAttachment(values) {
        const { returnDialog, ormProposalAttachmentCreate, ormProposalAttachmentUpdate, attachmentId, plan, attachmentType, updateAddendumDate } = this.props;

        if (attachmentType === "Research Addendum") {
            updateAddendumDate();
        }

        if (attachmentId) {
            ormProposalAttachmentUpdate({
                id: attachmentId.id,
                ...values
            });
        } else {
            ormProposalAttachmentCreate({
                proposal: plan.id,
                type: attachmentType,
                ...values
            });
        }
        returnDialog();
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            title: isRequired(values.title)
        };

        return valObj;
    };

    errorValidator2 = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            hyperlink: isRequired(values.hyperlink),
            title: isRequired(values.title)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, attachmentId, attachmentType, formkey, reporting_disabled, res_add, reporting_add_disabled } = this.props;

        var is_read_only = this.props.is_read_only;

        if (attachmentType === "Research Addendum") {
            is_read_only = res_add;
        } else if (attachmentType === "Media Links" || attachmentType === "Support Letter") {
            is_read_only = reporting_disabled;
            if (!attachmentId)
                is_read_only = reporting_add_disabled;
        }

        var interact_string = attachmentId ? "Edit" : "Add New";
        if (is_read_only) {
            interact_string = "View";
        }

        return (
            <Dialog role="main" aria-label={interact_string + " Attachment"} open={open}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        {interact_string} Attachment
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={attachmentId ? attachmentId.id : formkey}
                            validateOnSubmit={true}
                            validateError={attachmentType === "Media Links" ? this.errorValidator2 : this.errorValidator}
                            defaultValues={attachmentId}
                            onSubmit={values => this.addAttachment(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        {(attachmentType === "Board Resolution" || attachmentType === "Financial Capacity" || attachmentType === "Research Addendum") && (
                                            <Grid item xs={12}>
                                                <TextField field="title" label="Title" fullWidth disabled={is_read_only} />
                                                <FileInput
                                                    accept=".pdf,.docx,.doc"
                                                    field="file"
                                                    id="file"
                                                    label="Upload File"
                                                    fullWidth
                                                    disabled={is_read_only}
                                                />
                                            </Grid>
                                        )}
                                        {attachmentType === "Support Letter" && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="title"
                                                    label="Title"
                                                    fullWidth
                                                    disabled={is_read_only}
                                                />
                                                <Select
                                                    label="Description"
                                                    field="description"
                                                    options={MAKE_OPTIONS(["Photo", "Journal Article", "Media", "Report", "Letter", "Other"])}
                                                    fullWidth
                                                    disabled={is_read_only}
                                                />
                                                <FileInput
                                                    accept=".pdf,.docx,.doc,.xls,.xlsx,.jpg,.jpeg,.png,.bmp,.gif,.msg,.ppt,.pptx,.tiff,.csv"
                                                    field="file"
                                                    id="file"
                                                    label="Upload File"
                                                    fullWidth
                                                    disabled={is_read_only}
                                                />
                                            </Grid>
                                        )}
                                        {attachmentType === "Media Links" && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="title"
                                                    label="Title"
                                                    fullWidth
                                                    disabled={is_read_only}
                                                />
                                                <Select
                                                    label="Description"
                                                    field="description"
                                                    options={MAKE_OPTIONS(["Photo", "Journal Article", "Media", "Report", "Letter", "Website", "Other"])}
                                                    fullWidth
                                                    disabled={is_read_only}
                                                />
                                                <TextField
                                                    field="hyperlink"
                                                    label="Hyperlink"
                                                    fullWidth
                                                    disabled={is_read_only}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={is_read_only}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

AttachmentTab = connect(
    (state, ownProps) => ({
        attachments: getAttachments(state, ownProps),
        tab: getTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...DraftProposalAttachment.actions,
        ...FinalProposalAttachment.actions
    }
)(AttachmentTab);

export default withStyles(styles)(withRouter(AttachmentTab));
