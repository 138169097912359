export const SW_CHECKING = "SW_CHECKING";
export const SW_REGISTERED = "SW_REGISTERED";
export const SW_INSTALLED = "SW_INSTALLED";
export const SW_UPDATE = "SW_UPDATE";
export const SW_ERROR = "SW_ERROR";
export const SW_DISABLED = "SW_DISABLED";
export const STORE_READY = "STORE_READY";
export const NAV_TOGGLE_PROPOSAL = "NAV_TOGGLE_PROPOSAL";
export const NAV_TOGGLE_WORKPLAN = "NAV_TOGGLE_WORKPLAN";
export const NAV_TOGGLE_ACTIVEPROJECT = "NAV_TOGGLE_ACTIVEPROJECT";
export const NAV_TOGGLE_COMPLETEDPROJECT = "NAV_TOGGLE_COMPLETEDPROJECT";
export const NAV_TOGGLE_PEER = "NAV_TOGGLE_PEER";
export const NAV_TOGGLE_MEMBERTASK = "NAV_TOGGLE_MEMBERTASK";

export const PROCESS_SET = "PROCESS_SET";
export const PROCESS_CLEAR = "PROCESS_CLEAR";

export function swChecking() {
    return { type: SW_CHECKING };
}

export function swRegistered() {
    return { type: SW_REGISTERED };
}

export function swInstalled() {
    return { type: SW_INSTALLED };
}

export function swUpdate() {
    return { type: SW_UPDATE };
}

export function swError(error) {
    return { type: SW_ERROR, error: error };
}

export function swDisabled(reason) {
    return { type: SW_DISABLED, reason: reason };
}

export function storeReady() {
    return { type: STORE_READY };
}

export function processSet(sortInfo) {
    return {
        type: PROCESS_SET,
        sortInfo: sortInfo
    };
}

export function processClear() {
    return { type: PROCESS_CLEAR };
}

export function navToggleProposal() {
    return {
        type: NAV_TOGGLE_PROPOSAL
    };
}

export function navToggleWorkPlan() {
    return {
        type: NAV_TOGGLE_WORKPLAN
    };
}

export function navToggleActiveProject() {
    return {
        type: NAV_TOGGLE_ACTIVEPROJECT
    };
}

export function navToggleCompletedProject() {
    return {
        type: NAV_TOGGLE_COMPLETEDPROJECT
    };
}

export function navTogglePeer() {
    return {
        type: NAV_TOGGLE_PEER
    };
}

export function navToggleMemberTask() {
    return {
        type: NAV_TOGGLE_MEMBERTASK
    };
}
