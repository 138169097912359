import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AlarmIcon from "@material-ui/icons/Alarm";
import Switch from "../../common/Switch";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Checkbox from "../../common/Checkbox";
import HelpLabel from "../../common/HelpLabel";
import TextField from "../../common/TextField";
import { createSelector } from "../../common/orm";
import { StatusUpdateTab } from "../../home/models";
import { StatusUpdateActivity, StatusUpdate } from "../models";

const getStatusUpdates = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        var wp = session.FinalWorkPlan.filter(f => f.proposal_record_id === id).toModelArray()[0];
        return session.StatusUpdate.filter(x => x.work_plan === wp.id)
            .orderBy("id", "desc")
            .toModelArray()
            .map(su => ({
                _activities: su.status_update_activity
                    .all()
                    .orderBy("id")
                    .toRefArray(),
                ...su.ref
            }));
    }
);

const getStatusUpdateActivities = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.StatusUpdateActivity.all()
            .orderBy("id")
            .toRefArray();
    }
);

const getStatusUpdateTab = StatusUpdateTab.selectMessage(7);

const styles = theme => ({
    button: {
        marginRight: theme.spacing.unit * 2
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0
        }
    },
    sideCheck: {
        "& label": {
            marginRight: -16
        }
    },
    header: {
        fontWeight: "bold",
        fontSize: "0.95em"
    },
    red_color: {
        color: "#D10000"
    }
});

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

class ReportingTab extends Component {
    typingTimer = [];

    componentDidMount() {
        const { ormParentUpdateLocalOnly, plan } = this.props;
        document.title = "Work Plan: Reporting - LCCMR";
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            if (!plan.fromReview) {
                _this.props.appContainer.current.scrollTop();
            } else {
                ormParentUpdateLocalOnly({
                    id: plan.id,
                    fromReview: false
                });
            }
        }, 1);
    }

    reportDates(value) {
        const first = new Date(value);
        return `${months[first.getMonth()]} ${first.getDate()}`;
    }

    updateStatusField = (field_value, field_name, status_id, use_timer = false) => {
        const { ormStatusUpdateUpdate } = this.props;
        if (use_timer) {
            if (this.typingTimer[field_name]) clearTimeout(this.typingTimer[field_name]);
            this.typingTimer[field_name] = setTimeout(function() {
                ormStatusUpdateUpdate({
                    id: status_id,
                    [field_name]: field_value
                });
            }, 500);
        } else {
            ormStatusUpdateUpdate({
                id: status_id,
                [field_name]: field_value
            });
        }
    };

    updateStatusActivityField = (field_value, field_name, status_id, use_timer = false) => {
        const { ormStatusUpdateActivityUpdate } = this.props;
        if (use_timer) {
            if (this.typingTimer[field_name]) clearTimeout(this.typingTimer[field_name]);
            this.typingTimer[field_name] = setTimeout(function() {
                ormStatusUpdateActivityUpdate({
                    id: status_id,
                    [field_name]: field_value
                });
            }, 500);
        } else {
            ormStatusUpdateActivityUpdate({
                id: status_id,
                [field_name]: field_value
            });
        }
    };

    render() {
        const {
            classes,
            plan,
            ActionMenu,
            status_updates,
            status_update_activities,
            authState,
            ormParentUpdateLocalOnly,
            show_parcel,
            last_step,
            statusUpdateTab,
            proposal
        } = this.props;
        const { id } = this.props.plan;

        const first_date = plan.first_date ? plan.first_date + "T12:00:00" : null;
        const second_date = plan.second_date ? plan.second_date + "T12:00:00" : null;

        var in_progress_updates = status_updates.filter(su => !su.approved_date);
        var finished_updates = status_updates.filter(su => su.approved_date);

        var is_staff = false;
        if (authState && authState.user && authState.user.role === "Staff") {
            is_staff = true;
        }
        var is_dnr = false;
        if (authState && authState.user && (authState.user.is_read_only || authState.user.role === "Member")) {
            is_dnr = true;
        }

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12} lg={10} xl={7}>
                    <Typography variant="titleAction">Project Status Update Reporting</Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12} lg={10} xl={7}>
                    <div style={{ border: "1px solid darkgrey", padding: "10px" }}>
                        <Typography gutterBottom>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: statusUpdateTab.tab_help_texts.find(tht => tht.label === "Status Update Instructions").text
                                }}
                            />
                        </Typography>
                    </div>
                    <br />
                    <HelpLabel
                        inputLabel={
                            ((is_staff || is_dnr) ? "The PM has" : "You have") + " selected the following reporting schedule: " + this.reportDates(first_date) + " / " + this.reportDates(second_date)
                        }
                        title={true}
                        showLabel={true}
                        htmlText={true}
                        helpText={statusUpdateTab.tab_help_texts.find(tht => tht.label === "You have selected the following reporting schedule").text}
                    />
                    {proposal.status !== "Project Completed" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Revisions Submitted" && 
                    proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && (
                        <>
                        <br />
                        <br />
                        <HelpLabel
                            inputLabel={((is_staff || is_dnr) ? "The" : "Your") + " Next Scheduled Update Is Due " + plan.next_status_update_date_real}
                            title={true}
                            showLabel={true}
                            htmlText={true}
                            helpText={statusUpdateTab.tab_help_texts.find(tht => tht.label === "Your Next Scheduled Update Is Due").text}
                        />
                        </>
                    )}
                    {in_progress_updates
                        .filter(inp => !inp.submitted_date)
                        .map(n => {
                            const today = new Date();
                            const status_date = new Date(n.due_date + " 23:59:59");
                            if (today > status_date) {
                                return (
                                    <>
                                        <br />
                                        <br />
                                        <HelpLabel
                                            style={{ color: "#D10000" }}
                                            inputLabel={((is_staff || is_dnr) ? "The PM has" : "You have") + " an Update Past Due " + n.start_date_label}
                                            title={true}
                                            showLabel={true}
                                            htmlText={true}
                                            helpText={statusUpdateTab.tab_help_texts.find(tht => tht.label === "You have an Update Past Due").text}
                                        />
                                    </>
                                );
                            } else return null;
                        })}
                </Grid>
                {proposal.status !== "Project Completed" && (
                    <Grid item xs={12} lg={10} xl={7}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                ormParentUpdateLocalOnly({
                                    id: id,
                                    activeStep: 5,
                                    activeSubStep: 8
                                });
                            }}
                            className={classes.button}>
                            Jump to Spending Status Report
                        </Button>
                        {!show_parcel && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    ormParentUpdateLocalOnly({
                                        id: id,
                                        activeStep: 7,
                                        activeSubStep: 0
                                    });
                                }}
                                className={classes.button}>
                                Jump to Acquisition or Restoration Status Report
                            </Button>
                        )}
                        {!is_staff && !is_dnr && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    ormParentUpdateLocalOnly({
                                        id: id,
                                        activeStep: last_step,
                                        activeSubStep: 0
                                    });
                                }}
                                className={classes.button}>
                                Jump to Review and Submit
                            </Button>
                        )}
                    </Grid>
                )}
                <Grid item xs={12} lg={10} xl={7}>
                    <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                        <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h3">Status Update Reporting</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={2}>
                                {in_progress_updates.length === 0 && (
                                    <Grid item xs={12}>
                                        {proposal.status === "Project Completed" ?
                                            <Typography>
                                                <b>
                                                    Your project is complete and the final report was approved on: {plan.last_action}
                                                </b>
                                            </Typography>
                                        :
                                        (
                                            <div style={{ border: "1px solid darkgrey", padding: "10px" }}>
                                                <Typography>
                                                    <b>No status updates due.</b>
                                                </Typography>
                                                <ul>
                                                    <li>
                                                        <Typography>You have no status updates due at this time.</Typography>
                                                    </li>
                                                    {proposal.status !== "Project Completed" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Revisions Submitted" && 
                                                    proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && (
                                                        <li>
                                                            <Typography>Your Next Scheduled Update Is Due {plan.next_status_update_date_real}.</Typography>
                                                        </li>
                                                    )}
                                                    <li>
                                                        <Typography>
                                                            If you need to make a change to your work plan please submit an amendment request. Use the 'Select
                                                            Action" and select "Initiate Amendment Request" to start your request.
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography>
                                                            If you need to add an additional update use the "Select Action" and select "Add Additional Update".
                                                        </Typography>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </Grid>
                                )}
                                {in_progress_updates.map(n => {
                                    const today = new Date();
                                    const status_date = new Date(n.due_date + " 23:59:59");
                                    var red_color = false;
                                    if (today > status_date && !n.submitted_date) red_color = true;
                                    if ((is_staff || is_dnr) && !n.submitted_date)
                                        return (
                                            <Grid item xs={12}>
                                                <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                                                    <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                                        <Typography
                                                            variant="h3"
                                                            style={{ fontSize: "1em", width: "100%" }}
                                                            className={red_color && classes.red_color}>
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <AlarmIcon style={{ marginRight: 8, marginBottom: -5 }} />
                                                                    <span>
                                                                        {n.is_additional ? "Additional " : ""}
                                                                        {n.is_final ? <b>Final </b> : ""}
                                                                        {red_color && "Past Due "}Status Update {n.start_date_label}
                                                                    </span>
                                                                </Grid>
                                                                <Grid item xs={3} style={{ marginTop: 5 }}>
                                                                    {n.submitted_date && <span>Submitted {n.submitted_date_label}</span>}
                                                                </Grid>
                                                                <Grid item xs={3} style={{ marginTop: 5 }}>
                                                                    {n.approved_date && <span>Approved by LCCMR {n.approved_date_label}</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            This Status Update is in progress waiting to be submitted by the project manager.
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        );
                                    if (is_dnr) {
                                        return (
                                            <Grid item xs={12}>
                                                <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                                                    <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                                        <Typography
                                                            variant="h3"
                                                            style={{ fontSize: "1em", width: "100%" }}
                                                            className={red_color && classes.red_color}>
                                                            <Grid container>
                                                                <Grid item xs={6}>
                                                                    <AlarmIcon style={{ marginRight: 8, marginBottom: -5 }} />
                                                                    <span>
                                                                        {n.is_additional ? "Additional " : ""}
                                                                        {n.is_final ? <b>Final </b> : ""}
                                                                        {red_color && "Past Due "}Status Update {n.start_date_label}
                                                                    </span>
                                                                </Grid>
                                                                <Grid item xs={3} style={{ marginTop: 5 }}>
                                                                    {n.submitted_date && <span>Submitted {n.submitted_date_label}</span>}
                                                                </Grid>
                                                            </Grid>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Typography>
                                                            A status update or revisions to an update has been submitted and is pending review by LCCMR staff.
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        );
                                    }
                                    return (
                                        <Grid item xs={12}>
                                            <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                                                <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                                    <Typography
                                                        variant="h3"
                                                        style={{ fontSize: "1em", width: "100%" }}
                                                        className={red_color && classes.red_color}>
                                                        <Grid container>
                                                            <Grid item xs={6}>
                                                                <AlarmIcon style={{ marginRight: 8, marginBottom: -5 }} />
                                                                <span>
                                                                    {n.is_additional ? "Additional " : ""}
                                                                    {n.is_final ? <b>Final </b> : ""}
                                                                    {red_color && "Past Due "}Status Update {n.start_date_label}
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={3} style={{ marginTop: 5 }}>
                                                                {n.submitted_date && <span>Submitted {n.submitted_date_label}</span>}
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={n}>
                                                        {formApi => (
                                                            <form onSubmit={formApi.submitForm}>
                                                                <Grid container spacing={8}>
                                                                    <Grid item xs={12}>
                                                                        <Typography className={classNames(classes.header, red_color && classes.red_color)}>
                                                                            Overall Update
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <TextField
                                                                            field="overall_update"
                                                                            maxWords="200"
                                                                            multiline
                                                                            checkFocus={plan.review_activity === n.id && plan}
                                                                            focusRemoval={ormParentUpdateLocalOnly}
                                                                            eventHandle={(field_value, field_name) => {
                                                                                this.updateStatusField(field_value, field_name, n.id, true);
                                                                            }}
                                                                            disabled={n.submitted_date || is_staff || is_dnr}
                                                                            label="Please review your originally stated Outcomes on the Narrative page (Tab 4). Give us a summary of your progress towards achieving those outcomes. Please include progress since your last update as well as cumulative progress toward your overall project outcomes, especially those that are quantifiable. We will ask you to go into more detail in the subsequent fields. (200 word limit)"
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {is_staff && !is_dnr && (
                                                                            <>
                                                                                <Checkbox
                                                                                    align={classes.sideCheck}
                                                                                    eventHandle={(field_value, e, field_name) => {
                                                                                        this.updateStatusField(field_value, field_name, n.id);
                                                                                    }}
                                                                                    field="overall_update_staff"
                                                                                    label="Staff have reviewed and approve"
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                    {status_update_activities
                                                                        .filter(sua => sua.status_update_id === n.id || sua.status_update === n.id)
                                                                        .map((o, index) => {
                                                                            return (
                                                                                <Grid item xs={12}>
                                                                                    <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={o}>
                                                                                        {formApiC => (
                                                                                            <form onSubmit={formApiC.submitForm}>
                                                                                                <Grid container spacing={8}>
                                                                                                    <Grid item xs={12}>
                                                                                                        <Typography
                                                                                                            className={classNames(
                                                                                                                classes.header,
                                                                                                                red_color && classes.red_color
                                                                                                            )}>
                                                                                                            Activity {index + 1} Update
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={10}>
                                                                                                        <TextField
                                                                                                            field="update_text"
                                                                                                            maxWords="200"
                                                                                                            checkFocus={plan.review_activity === n.id && plan.review_milestone === o.id && plan}
                                                                                                            focusRemoval={ormParentUpdateLocalOnly}
                                                                                                            multiline
                                                                                                            eventHandle={(field_value, field_name) => {
                                                                                                                this.updateStatusActivityField(
                                                                                                                    field_value,
                                                                                                                    field_name,
                                                                                                                    o.id,
                                                                                                                    true
                                                                                                                );
                                                                                                            }}
                                                                                                            disabled={n.submitted_date || is_staff || is_dnr}
                                                                                                            label={
                                                                                                                "Please review your Activity " +
                                                                                                                (index + 1) +
                                                                                                                " Description and Milestones (Tab 5). Provide an update on work done and progress made since your last update as well as cumulative progress toward achieving your outcomes/outputs for this activity. Please upload any outputs (reports, brochures, papers, etc.) to the Attachments page (tab 7) (200 word limit)"
                                                                                                            }
                                                                                                            fullWidth
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item xs={2}>
                                                                                                        <Checkbox
                                                                                                            align={classes.sideCheck}
                                                                                                            field="activity_complete"
                                                                                                            disabled={n.submitted_date || is_staff || is_dnr}
                                                                                                            eventHandle={(field_value, e, field_name) => {
                                                                                                                this.updateStatusActivityField(
                                                                                                                    field_value,
                                                                                                                    field_name,
                                                                                                                    o.id
                                                                                                                );
                                                                                                            }}
                                                                                                            label={
                                                                                                                "Activity " + (index + 1) + " is complete"
                                                                                                            }></Checkbox>
                                                                                                        {is_staff && !is_dnr && (
                                                                                                            <>
                                                                                                                <Checkbox
                                                                                                                    align={classes.sideCheck}
                                                                                                                    eventHandle={(
                                                                                                                        field_value,
                                                                                                                        e,
                                                                                                                        field_name
                                                                                                                    ) => {
                                                                                                                        this.updateStatusActivityField(
                                                                                                                            field_value,
                                                                                                                            field_name,
                                                                                                                            o.id
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    field="activity_complete_staff"
                                                                                                                    label="Staff have reviewed and approve"
                                                                                                                />
                                                                                                            </>
                                                                                                        )}
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </form>
                                                                                        )}
                                                                                    </Form>
                                                                                </Grid>
                                                                            );
                                                                        })}
                                                                    <Grid item xs={12}>
                                                                        <Typography className={classNames(classes.header, red_color && classes.red_color)}>
                                                                            Dissemination Update
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <TextField
                                                                            field="dissemination_update"
                                                                            maxWords="200"
                                                                            disabled={n.submitted_date || is_staff || is_dnr}
                                                                            checkFocus={plan.review_activity === n.id && plan}
                                                                            focusRemoval={ormParentUpdateLocalOnly}
                                                                            multiline
                                                                            eventHandle={(field_value, field_name) => {
                                                                                this.updateStatusField(field_value, field_name, n.id, true);
                                                                            }}
                                                                            label="Please review your Dissemination Description (Tab 9). Provide an update on any dissemination activities you have completed since your last update. We encourage you to post news reports, social media links, links to websites produced, and any other dissemination products on the Attachments page (Tab 7). (200 word limit)"
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {is_staff && !is_dnr && (
                                                                            <>
                                                                                <Checkbox
                                                                                    align={classes.sideCheck}
                                                                                    eventHandle={(field_value, e, field_name) => {
                                                                                        this.updateStatusField(field_value, field_name, n.id);
                                                                                    }}
                                                                                    field="dissemination_update_staff"
                                                                                    label="Staff have reviewed and approve"
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Switch
                                                                            field="budget_amount"
                                                                            checkFocus={plan.review_activity === n.id && plan}
                                                                            focusRemoval={ormParentUpdateLocalOnly}
                                                                            eventHandle={(field_value, field_name) => {
                                                                                this.updateStatusField(field_value, field_name, n.id);
                                                                            }}
                                                                            disabled={n.submitted_date || is_staff || is_dnr}
                                                                            label="Budget amount spent is correct and up to date."
                                                                        />
                                                                    </Grid>
                                                                    {!show_parcel && (
                                                                        <Grid item xs={12}>
                                                                            <Switch
                                                                                field="parcel_list"
                                                                                checkFocus={plan.review_activity === n.id && plan}
                                                                                focusRemoval={ormParentUpdateLocalOnly}
                                                                                eventHandle={(field_value, field_name) => {
                                                                                    this.updateStatusField(field_value, field_name, n.id);
                                                                                }}
                                                                                disabled={n.submitted_date || is_staff || is_dnr}
                                                                                label="Parcel list status is correct and up to date."
                                                                            />
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </form>
                                                        )}
                                                    </Form>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                <Grid item xs={12} lg={10} xl={7}>
                    <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={false}>
                        <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h3">Approved Status Updates</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {finished_updates.length === 0 && (
                                <Grid item xs={12}>
                                    <Typography>No Previous Status Updates</Typography>
                                </Grid>
                            )}
                            <Grid container>
                                {finished_updates.map(n => {
                                    return (
                                        <Grid item xs={12}>
                                            <ExpansionPanel defaultExpanded={true}>
                                                <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                                    <Typography variant="h3" style={{ fontSize: "1em", width: "100%" }}>
                                                        <Grid container>
                                                            <Grid item xs={5}>
                                                                <AlarmIcon style={{ marginRight: 8, marginBottom: -5 }} />
                                                                {n.is_additional ? "Additional " : ""}
                                                                {n.is_final ? <b>Final </b> : ""}
                                                                <span>Status Update {n.start_date_label}</span>
                                                            </Grid>
                                                            <Grid item xs={3} style={{ marginTop: 5 }}>
                                                                {n.submitted_date && <span>Submitted {n.submitted_date_label}</span>}
                                                            </Grid>
                                                            <Grid item xs={4} style={{ marginTop: 5 }}>
                                                                {n.approved_date && <span>Approved by LCCMR {n.approved_date_label}</span>}
                                                            </Grid>
                                                        </Grid>
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={n}>
                                                        {formApi => (
                                                            <form onSubmit={formApi.submitForm}>
                                                                <Grid container spacing={8}>
                                                                    <Grid item xs={12}>
                                                                        <Typography className={classes.header}>Overall Update</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <TextField
                                                                            field="overall_update"
                                                                            maxWords="200"
                                                                            multiline
                                                                            disabled={true}
                                                                            label="Please review your originally stated Outcomes on the Narrative page (Tab 4). Give us a summary of your progress towards achieving those outcomes. Please include progress since your last update as well as cumulative progress toward your overall project outcomes, especially those that are quantifiable. We will ask you to go into more detail in the subsequent fields. (200 word limit)"
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}></Grid>
                                                                    {status_update_activities
                                                                        .filter(sua => sua.status_update_id === n.id || sua.status_update === n.id)
                                                                        .map((o, index) => {
                                                                            return (
                                                                                <Grid item xs={12}>
                                                                                    <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={o}>
                                                                                        {formApiC => (
                                                                                            <form onSubmit={formApiC.submitForm}>
                                                                                                <Grid container spacing={8}>
                                                                                                    <Grid item xs={12}>
                                                                                                        <Typography className={classes.header}>
                                                                                                            Activity {index + 1} Update
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={10}>
                                                                                                        <TextField
                                                                                                            field="update_text"
                                                                                                            maxWords="200"
                                                                                                            multiline
                                                                                                            disabled={true}
                                                                                                            label={
                                                                                                                "Please review your Activity " +
                                                                                                                (index + 1) +
                                                                                                                " Description and Milestones (Tab 5). Provide an update on work done and progress made since your last update as well as cumulative progress toward achieving your outcomes/outputs for this activity. Please upload any outputs (reports, brochures, papers, etc.) to the Attachments page (tab 7) (200 word limit)"
                                                                                                            }
                                                                                                            fullWidth
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item xs={2}>
                                                                                                        <Checkbox
                                                                                                            align={classes.sideCheck}
                                                                                                            field="activity_complete"
                                                                                                            disabled={true}
                                                                                                            label={
                                                                                                                "Activity " + (index + 1) + " is complete"
                                                                                                            }></Checkbox>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </form>
                                                                                        )}
                                                                                    </Form>
                                                                                </Grid>
                                                                            );
                                                                        })}
                                                                    <Grid item xs={12}>
                                                                        <Typography className={classes.header}>Dissemination Update</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <TextField
                                                                            field="dissemination_update"
                                                                            maxWords="200"
                                                                            disabled={true}
                                                                            multiline
                                                                            label="Please review your Dissemination Description (Tab 9). Provide an update on any dissemination activities you have completed since your last update. We encourage you to post news reports, social media links, links to websites produced, and any other dissemination products on the Attachments page (Tab 7). (200 word limit)"
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}></Grid>
                                                                </Grid>
                                                            </form>
                                                        )}
                                                    </Form>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
            </Grid>
        );
    }
}

ReportingTab = connect(
    (state, ownProps) => ({
        authState: state.auth,
        status_updates: getStatusUpdates(state, ownProps),
        statusUpdateTab: getStatusUpdateTab(state, ownProps),
        status_update_activities: getStatusUpdateActivities(state, ownProps)
    }),
    {
        ...StatusUpdate.actions,
        ...StatusUpdateActivity.actions
    }
)(ReportingTab);

export default withStyles(styles)(withRouter(ReportingTab));
