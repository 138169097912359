import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core";

import TextField from "./TextField";

const styles = theme => ({
    listItem: {
        borderBottom: "1px solid #666666",
        "&:focus": {
            backgroundColor: "rgba(0, 0, 0, 0.08) !important"
        }
    },
})

// FIXME: If a menu item with a value of 0 is selected the item doesn't appear to be selected
// https://github.com/mui-org/material-ui/issues/10531
// https://github.com/mui-org/material-ui/issues/8990

//SelectProps={{displayEmpty:true}}
//SelectProps={{...rest}}
const Select = ({ options, native, children, classes, ...rest }) => (
    <TextField select selectHelp={children} {...rest}>
        {native &&
            options.map(({ label, value, optgroup, items }) => {
                if (optgroup) {
                    return (
                        <optgroup label={label}>
                            {items.map(({ iLabel, iValue }) => (
                                <option value={iValue}>{iLabel}</option>
                            ))}
                        </optgroup>
                    );
                } else {
                    return (
                        <option value={value} key={value}>
                            {label}
                        </option>
                    );
                }
            })}
        {!native && options.map(({ label, value }) => <MenuItem className={classes.listItem} value={value}>{label}</MenuItem>)}
    </TextField>
);

export default withStyles(styles)(Select);
