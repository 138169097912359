import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import HelpIcon from "@material-ui/icons/Help";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SettingsIcon from "@material-ui/icons/Settings";

import header from "../../images/header.webp";
import headerdev from "../../images/headerdev.webp";

import { connect } from "react-redux";
import * as authActions from "../auth/actions";
import * as navActions from "./actions";
import { reloadAll, ormLogOutSync, createSelector, clearAll } from "./orm";
import NewProposal from "../proposal/NewProposal";

const allProposals = createSelector(schema => {
    return schema.Proposal.all()
        .orderBy(["ml_year", "name"], ["desc", "asc"])
        .toModelArray();
});

const styles = theme => ({
    header: {
        height: "12rem",
        backgroundImage: "url(" + header + ")",
        backgroundPosition: "0% 40%",
        backgroundSize: "cover"
    },
    headerdev: {
        height: "12rem",
        backgroundImage: "url(" + headerdev + ")",
        backgroundPosition: "0% 40%",
        backgroundSize: "cover"
    },
    headerContainer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        background: "#333333"
    },
    updateReady: {
        backgroundColor: "#4e4e4e",
        width: "100%",
        height: "10em"
    },
    headerButton: {
        color: "#fff",
        "& svg": {
            fontSize: 23,
            marginRight: 4
        },
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.25)"
        },
        "&:focus": {
            backgroundColor: "rgba(255, 255, 255, 0.25)"
        }
    },
    listItem: {
        borderBottom: "1px solid #666666",
        "&:focus": {
            backgroundColor: "rgba(0, 0, 0, 0.08) !important"
        }
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    navBar: {
        textAlign: "right",
        backgroundColor: "#00406e"
    },
    heading: {
        fontSize: "2em",
        textDecoration: "none",
        fontFamily: "georgia, serif",
        textShadow: "1px 1px 1px #000",
        letterSpacing: ".15rem",
        color: "#fff !important",
        display: "inline-block",
        lineHeight: 1.33
    },
    nameHeader: {
        display: "inline",
        color: "white",
        fontWeight: "bold",
        marginRight: 24
    },
    displayNone: {
        display: "none"
    }
});

class Header extends Component {
    constructor() {
        super();
        this.state = {
            newDialogOpen: false,
            resourceMenuAnchor: false,
            memberresourceMenuaAnchor: false,
            helpMenuAnchor: false,
            userMenuAnchor: false,
            reportMenuAnchor: false,
            adminMenuAnchor: false
        };
    }

    handleMenu = (event, menu) => {
        this.setState({ [menu]: event.currentTarget });
    };

    handleMenuClose = menu => {
        this.setState({ [menu]: false });
    };

    logOut = () => {
        const { authLogout, ormLogOutSync, clearAll, history } = this.props;
        authLogout();
        ormLogOutSync();
        history.push("/");
        clearAll();
    };

    handleKeyPress = (e, url) => {
        if (e.which === 32 || e.which === 13)
            window.open(url);
    }

    getLink = proposal => {
        if (proposal && proposal.status && proposal.status.includes("Draft Work Plan")) {
            return "/draft/workplan/" + proposal.id;
        } else if (proposal && proposal.status && proposal.status.includes("Final Work Plan")) {
            return "/final/workplan/" + proposal.id;
        } else {
            return "/proposal/" + proposal.id;
        }
    };

    render() {
        const { classes, authenticated, authState, navState, syncState, history } = this.props;
        const { newDialogOpen, resourceMenuAnchor, memberresourceMenuaAnchor, helpMenuAnchor, userMenuAnchor, adminMenuAnchor } = this.state;

        if (!authState) return "...";

        var syncProgress = Math.round((syncState.progress / syncState.total) * 100);
        if (isNaN(syncProgress)) {
            syncProgress = 100;
        }

        const updateReady = navState.sw && navState.sw.update;
        var name = null;
        if (authState && authState.user) name = authState.user.first_name + " " + authState.user.last_name;

        var is_admin = false;
        if (authState && authState.user && authState.user.role === "Staff") {
            is_admin = true;
            name += ", Staff";
        }

        var is_member = false;
        if (authState && authState.user && authState.user.role === "Member") {
            is_member = true;
            name += ", Member";
        }

        if (authState && authState.user && authState.user.is_read_only) {
            name += ", Pass Through View";
        }


        const UpdateHeader = () => (
            <Toolbar className={classes.updateReady}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="subheading" color="inherit">
                            A newer version of the application is available. Please save your work and refresh your browser. &nbsp;
                            <Button variant="contained" onClick={() => window.location.reload()}>
                                Refresh Now
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        );

        return (
            <AppBar position="absolute" className={window.location.hostname === "lccmrprojectmgmt.leg.mn" ? classes.header : classes.headerdev}>
                {updateReady && <UpdateHeader />}
                {!syncState.ready && <LinearProgress variant="determinate" aria-label="Loading Progress" value={syncProgress} />}
                <div className={classes.headerContainer}>
                    <Grid container justify="center">
                        <Grid item>
                            <Typography variant="h1" className={classes.heading}>
                                LCCMR Proposal and Grant Management System
                            </Typography>
                        </Grid>
                        {authenticated &&
                        !is_member &&
                        !is_admin && ( // Regular user navigation
                                <Grid item xs={12} className={classes.navBar}>
                                    <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} className={classes.headerButton} component={Link} to="/dashboard">
                                        <HomeIcon />
                                        <Typography color="inherit">Dashboard</Typography>
                                    </IconButton>
                                    <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "resourceMenuAnchor")}>
                                        <InfoIcon />
                                        <Typography color="inherit">Resources</Typography>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "helpMenuAnchor")}>
                                        <HelpIcon />
                                        <Typography color="inherit">Help</Typography>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "userMenuAnchor")}>
                                        <AccountCircle />
                                        <Typography color="inherit">Account</Typography>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <Typography className={classes.nameHeader}>{name}</Typography>
                                </Grid>
                            )}
                        {authenticated && is_member && (
                            <Grid item xs={12} className={classes.navBar}>
                                <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} className={classes.headerButton} component={Link} to="/dashboard">
                                    <HomeIcon />
                                    <Typography color="inherit">Dashboard</Typography>
                                </IconButton>
                                <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "resourceMenuAnchor")}>
                                    <InfoIcon />
                                    <Typography color="inherit">Resources</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <IconButton
                                    TouchRippleProps={{ classes: { root: classes.displayNone } }}
                                    aria-haspopup="true"
                                    className={classes.headerButton}
                                    onClick={e => this.handleMenu(e, "memberresourceMenuaAnchor")}>
                                    <InfoIcon />
                                    <Typography color="inherit">Member-Specific Resources</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "helpMenuAnchor")}>
                                    <HelpIcon />
                                    <Typography color="inherit">Help</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "userMenuAnchor")}>
                                    <AccountCircle />
                                    <Typography color="inherit">Account</Typography>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                                <Typography className={classes.nameHeader}>{name}</Typography>
                            </Grid>
                        )}
                        {authenticated &&
                        is_admin && ( // Admin navigation
                                <Grid item xs={12} className={classes.navBar}>
                                    <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} className={classes.headerButton} component={Link} to="/dashboard">
                                        <HomeIcon />
                                        <Typography color="inherit">Dashboard</Typography>
                                    </IconButton>
                                    <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "resourceMenuAnchor")}>
                                        <InfoIcon />
                                        <Typography color="inherit">Resources</Typography>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "adminMenuAnchor")}>
                                        <SettingsIcon />
                                        <Typography color="inherit">Administration</Typography>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "helpMenuAnchor")}>
                                        <HelpIcon />
                                        <Typography color="inherit">Help</Typography>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <IconButton TouchRippleProps={{ classes: { root: classes.displayNone } }} aria-haspopup="true" className={classes.headerButton} onClick={e => this.handleMenu(e, "userMenuAnchor")}>
                                        <AccountCircle />
                                        <Typography color="inherit">Account</Typography>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <Typography className={classes.nameHeader}>{name}</Typography>
                                </Grid>
                            )}
                    </Grid>
                </div>
                <NewProposal returnDialog={() => this.handleMenuClose("newDialogOpen")} open={newDialogOpen} />
                <Toolbar>
                    <Menu
                        anchorEl={resourceMenuAnchor ? resourceMenuAnchor : null}
                        classes={{ paper: classes.list }}
                        open={resourceMenuAnchor}
                        onClose={() => this.handleMenuClose("resourceMenuAnchor")}>
                        <MenuItem autoFocus button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/funding_process/process_main.html")}>
                            <ListItemText primary="Funding Process" onClick={() => window.open("https://www.lccmr.mn.gov/funding_process/process_main.html")} />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/pm_info/manager_info_index.html#requirements")}>
                            <ListItemText
                                primary="General Project Requirements"
                                onClick={() => window.open("https://www.lccmr.mn.gov/pm_info/manager_info_index.html#requirements")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/pm_info/guidance_on_allowable_expenses.pdf")}>
                            <ListItemText
                                primary="Guidance On Allowable Expenses"
                                onClick={() => window.open("https://www.lccmr.mn.gov/pm_info/guidance_on_allowable_expenses.pdf")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/pm_info/manager_info_index.html#acknowledgement")}>
                            <ListItemText
                                primary="Acknowledgement Requirements"
                                onClick={() => window.open("https://www.lccmr.mn.gov/pm_info/manager_info_index.html#acknowledgement")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/pm_info/manager_info_index.html#acq-rst_req")}>
                            <ListItemText
                                primary="Land Acquisition & Restoration Requirements"
                                onClick={() => window.open("https://www.lccmr.mn.gov/pm_info/manager_info_index.html#acq-rst_req")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/calendar/calendar-index.html")}>
                            <ListItemText
                                primary="LCCMR Meeting Schedule"
                                onClick={() => window.open("https://www.lccmr.mn.gov/calendar/calendar-index.html")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/")}>
                            <ListItemText primary="LCCMR Website" onClick={() => window.open("https://www.lccmr.mn.gov/")} />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.legacy.mn.gov/")}>
                            <ListItemText primary="Legacy Website" onClick={() => window.open("https://www.legacy.mn.gov/")} />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.dnr.state.mn.us/grants/passthrough/index.html")}>
                            <ListItemText
                                primary="DNR Grants Website"
                                onClick={() => window.open("https://www.dnr.state.mn.us/grants/passthrough/index.html")}
                            />
                        </MenuItem>
                    </Menu>
                    <Menu
                        anchorEl={memberresourceMenuaAnchor ? memberresourceMenuaAnchor : null}
                        classes={{ paper: classes.list }}
                        open={memberresourceMenuaAnchor}
                        onClose={() => this.handleMenuClose("memberresourceMenuaAnchor")}>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/documents/pdf_docs/lccmr_operating_procedures.pdf#page=4")}>
                            <ListItemText
                                primary="Conflict of Interest Policy"
                                onClick={() => window.open("https://www.lccmr.mn.gov/documents/pdf_docs/lccmr_operating_procedures.pdf#page=4")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/member_info/lccmr_adopted_evaluation_criteria_for_2021.pdf")}>
                            <ListItemText
                                primary="Evaluation Criteria"
                                onClick={() => window.open("https://www.lccmr.mn.gov/member_info/lccmr_adopted_evaluation_criteria_for_2021.pdf")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/documents/pdf_docs/lccmr_operating_procedures.pdf")}>
                            <ListItemText
                                primary="Operating Procedures"
                                onClick={() => window.open("https://www.lccmr.mn.gov/documents/pdf_docs/lccmr_operating_procedures.pdf")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/calendar/calendar-index.html")}>
                            <ListItemText primary="LCCMR Calendar" onClick={() => window.open("https://www.lccmr.mn.gov/calendar/calendar-index.html")} />
                        </MenuItem>
                    </Menu>
                    <Menu
                        anchorEl={userMenuAnchor ? userMenuAnchor : null}
                        classes={{ paper: classes.list }}
                        open={userMenuAnchor}
                        onClose={() => this.handleMenuClose("userMenuAnchor")}>
                        <MenuItem button component={Link} to="/updateuser" className={classes.listItem} onKeyDown={e => {if (e.which === 32 || e.which === 13) history.push("/updateuser")}}>
                            <ListItemText primary="Update Profile" />
                        </MenuItem>
                        <MenuItem button onClick={this.logOut} className={classes.listItem}>
                            <ListItemText primary="Log Out" />
                        </MenuItem>
                    </Menu>
                    <Menu
                        anchorEl={helpMenuAnchor ? helpMenuAnchor : null}
                        classes={{ paper: classes.list }}
                        open={helpMenuAnchor}
                        onClose={() => this.handleMenuClose("helpMenuAnchor")}>
                        <MenuItem button className={classes.listItem}>
                            <ListItemText
                                primary="Project Manager Orientation"
                                onClick={() => window.open("https://www.youtube.com/watch?v=RRjgxUIeS1Q")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.dnr.state.mn.us/grants/passthrough/fl.html")}>
                            <ListItemText
                                primary="Requesting Reimbursement"
                                onClick={() => window.open("https://www.dnr.state.mn.us/grants/passthrough/fl.html")}
                            />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} onKeyDown={e => this.handleKeyPress(e, "https://www.lccmr.mn.gov/about/staff-index.html")}>
                            <ListItemText primary="Contact LCCMR Staff" onClick={() => window.open("https://www.lccmr.mn.gov/about/staff-index.html")} />
                        </MenuItem>
                    </Menu>
                    <Menu
                        anchorEl={adminMenuAnchor ? adminMenuAnchor : null}
                        classes={{ paper: classes.list }}
                        open={adminMenuAnchor}
                        onClose={() => this.handleMenuClose("adminMenuAnchor")}>
                        <MenuItem button className={classes.listItem} component={Link} to="/adm/home" onKeyDown={e => {if (e.which === 32 || e.which === 13) history.push("/adm/home")}}>
                            <ListItemText primary="Home Page" />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} component={Link} to="/adm/search" onKeyDown={e => {if (e.which === 32 || e.which === 13) history.push("/adm/search")}}>
                            <ListItemText primary="Search" />
                        </MenuItem>
                        <MenuItem button className={classes.listItem} component={Link} to="/adm/reports" onKeyDown={e => {if (e.which === 32 || e.which === 13) history.push("/adm/reports")}}>
                            <ListItemText primary="Reports" />
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        );
    }
}

Header = connect(
    state => ({
        proposals: allProposals(state),
        navState: state.nav,
        authState: state.auth,
        syncState: state.sync
    }),
    {
        ...authActions,
        ...navActions,
        ormLogOutSync: ormLogOutSync,
        reload: reloadAll,
        clearAll
    }
)(Header);

export default withStyles(styles)(withRouter(Header));
