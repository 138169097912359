import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import SettingsIcon from "@material-ui/icons/Settings";
import ExtensionIcon from "@material-ui/icons/Extension";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";

import * as navActions from "../common/actions";

import { createSelector } from "../common/orm";

const getLatestYear = createSelector(schema => {
    return schema.SystemSetting.all().orderBy("year", "desc").first()
});

const styles = theme => ({
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted"
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    button: {
        justifyContent: "left"
    }
});

class AdminHome extends Component {
    componentDidMount() {
        document.title = "Administration Home - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    componentDidUpdate() {
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    render() {
        const { classes, rfpyear } = this.props;

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Administration Home" />
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <GroupAddIcon className={classes.expansionIcon} /> Manage Users or Organizations
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button fullWidth className={classes.button} component={Link} to="/adm/users">
                                            Manage General Users
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button fullWidth className={classes.button} component={Link} to="/adm/members">
                                            Manage Member Users
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button fullWidth className={classes.button}>
                                            Manage Peer Reviewer Users (TODO)
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button fullWidth className={classes.button} component={Link} to="/adm/staff">
                                            Manage Staff Users
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/organizations">
                                            Manage Organizations
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button fullWidth className={classes.button} component={Link} to="/adm/agents">
                                            Manage Fiscal Agents
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <SettingsIcon className={classes.expansionIcon} /> System Configuration
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/proposalprocess">
                                            Configure Proposal Process
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/configure/members">
                                            Configure Members for RFP Year
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/categories">
                                            Configure RFP Categories
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/messages">
                                            Configure User Dashboard Messages
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/member/messages">
                                            Configure Member Dashboard Messages
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/helpmessages/member">
                                            Configure Member Instructions
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/helpmessages/proposal">
                                            Configure Proposal Help Messages
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/helpmessages/workplan">
                                            Configure Work Plan Help Messages
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/configure/email-templates">
                                            Configure Email Templates
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/helpmessages/status-update">
                                            Configure Active Project Help Messages
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <ExtensionIcon className={classes.expansionIcon} /> RFP Review Process Functions
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            disabled={rfpyear ? !rfpyear.evaluation1_open : true}
                                            to={`/adm/select/presentation/${rfpyear ? rfpyear.year : ""}`}>
                                            Staff Enter Member Evaluation #1
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            disabled={rfpyear ? !rfpyear.evaluation2_open : true}
                                            to={`/adm/evaluation/presentation/${rfpyear ? rfpyear.year : ""}`}>
                                            Staff Enter Member Evaluation #2
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            onClick={e => this.props.processClear()}
                                            to="/adm/proposal/process">
                                            Proposal Process
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary
                                className={classes.expansionSummaryRoot}
                                expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3">
                                    <ExtensionIcon className={classes.expansionIcon} /> Misc. Admin Functions
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/batch/update">
                                            Batch Update Information
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/adm/withdrawal">
                                            Withdrawal Proposals
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/create/workplan">
                                            Create New Work Plan
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <Button
                                            fullWidth
                                            className={classes.button}
                                            component={Link}
                                            to="/late/workplan">
                                            Create Work Plan from Proposal
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}></Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

AdminHome = connect(
    (state, ownProps) => ({
        authState: state.auth,
        rfpyear: getLatestYear(state, ownProps)
    }),
    {
        ...navActions
    }
)(AdminHome);

export default withStyles(styles)(withRouter(AdminHome));
