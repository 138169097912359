/******************************************************************************
 *  If member display member dashboard. Else dashboard handles other users for now.
 *  I didn't want to seperate out parts in Dashboard.js unless necessary.
 ******************************************************************************/
import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../home/Dashboard";
import MemberDashboard from "../home/MemberDashboard";
import DnrDashboard from "../home/DnrDashboard";
import AdminDashBoard from "../home/StaffWorkLoadManagement";

class ParentParentDashBoard extends Component {
    render() {
        var is_member = false;
        var is_admin = false;
        var is_dnr = false;
        if (this.props.authState && this.props.authState.user && this.props.authState.user.role === "Member")
            is_member = true;
        else if (this.props.authState && this.props.authState.user && this.props.authState.user.role === "Staff")
            is_admin = true;
        else if (this.props.authState && this.props.authState.user && this.props.authState.user.is_read_only)
            is_dnr = true;

        return (
            <>
                {is_admin && <AdminDashBoard />}
                {is_member && <MemberDashboard />}
                {is_dnr && <DnrDashboard />}
                {!is_member && !is_admin && !is_dnr && <Dashboard />}
            </>
        );
    }
}

ParentParentDashBoard = connect(
    state => ({
        authState: state.auth
    }),
    null
)(ParentParentDashBoard);

export default ParentParentDashBoard;
