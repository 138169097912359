import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Snackbar } from "@material-ui/core";
import { SnackbarContent } from "@material-ui/core";
import { CheckCircleSharp } from "@material-ui/icons";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import TextField from "../common/TextField";
import { createSelector } from "../common/orm";
import { EmailTemplate } from "../home/models";
import Select from "../common/Select";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const getTemplates = createSelector(schema => {
    return schema.EmailTemplate.all()
        .orderBy("id")
        .toRefArray();
});

const styles = () => ({
    form: {
        padding: "15px",
        border: "1px solid #eee",
        margin: "10px 0px 0px 0px",
        marginBottom: 20,
        width: "1250px"
    },
    fields: {
        background: "aliceblue",
        padding: "10px",
        margin: "0px 0px 10px 0px",
        borderRadius: "4px"
    },
    error: {
        color: "red"
    },
    snackbarStyleViaNestedContent: {
        backgroundColor: "lightgreen",
        color: "black"
    },
    copyMsg: {
        display: "flex",
        alignItems: "center"
    }
});

class EmailTemplatesConf extends Component {
    // Body Text Field Options
    toolbarConfig = {
        options: ["inline", "link", "list"],
        inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"]
        },
        link: {
            inDropdown: false,
            showOpenOptionOnHover: true,
            defaultTargetOption: "_self",
            options: ["link", "unlink"]
        },
        list: {
            inDropdown: false,
            options: ["ordered", "unordered"]
        }
    };
    // RegEx to get items in curly brackets
    extractVars = /{([^}]+)}/g;

    state = {
        subjectVars: [],
        bodyVars: [],
        varCopied: false,
        copyMsg: "",
        selectedTemplate: {},
        template_saved: false
    };

    componentDidMount() {
        document.title = "Configure Email Templates - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    switchTemplate = template_value => {
        const { templates } = this.props;
        const selectedTemplate = templates.find(tem => tem.template_name === template_value);
        const subjectVars = selectedTemplate.subject.match(this.extractVars);
        const bodyVars = selectedTemplate.body.match(this.extractVars);
        selectedTemplate.body_editor = this.getHTMLToDraft(selectedTemplate.body);

        this.setState({
            selectedTemplate: selectedTemplate,
            subjectVars: subjectVars !== null ? subjectVars : [],
            bodyVars: bodyVars !== null ? bodyVars : []
        });
    };

    changeEditorState = contentState => {
        let { selectedTemplate } = this.state;
        selectedTemplate["body_editor"] = contentState;
        this.setState(prev => ({ ...prev, selectedTemplate }));
    };

    getHTMLToDraft = html => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
    };

    submitForm = values => {
        const { ormEmailTemplateUpdate } = this.props;
        let { selectedTemplate } = this.state;
        values["body"] = draftToHtml(convertToRaw(selectedTemplate.body_editor.getCurrentContent()));
        ormEmailTemplateUpdate(values);
        this.setState({ template_saved: true });
    };

    copyVar = variable => {
        const msg = (
            <span>
                <strong>&nbsp;{variable}</strong> Copied!
            </span>
        );
        this.setState(prev => ({ ...prev, varCopied: true, copyMsg: msg }));
    };

    render() {
        const { subjectVars, bodyVars, copyMsg, varCopied, selectedTemplate, template_saved } = this.state;
        const { classes, templates } = this.props;

        const templateOptions = templates.map(template => ({
            label: template.template_name,
            value: template.template_name
        }));

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Configure Email Templates" />
                    <Grid item lg={9} xl={7} xs={12}>
                        <Form dontValidateOnMount={true} key={selectedTemplate.id} defaultValues={selectedTemplate} onSubmit={this.submitForm}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Select
                                                fullWidth
                                                field="template_name"
                                                label="Select Template"
                                                options={templateOptions}
                                                eventHandle={this.switchTemplate}
                                            />
                                        </Grid>
                                        {Object.keys(selectedTemplate).length > 0 && (
                                            <>
                                                <Grid container className={classes.form}>
                                                    <Grid item xs={12}>
                                                        <TextField field="description" label="Description" multiline rows={4} fullWidth />
                                                        <TextField field="to" label="To" disabled fullWidth />
                                                        <TextField field="carbon_copy" label="CC (comma separated email addresses)" fullWidth />
                                                        <TextField field="blind_carbon_copy" label="BCC (comma separated email addresses)" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.fields}>
                                                        <TextField field="subject" fullWidth label="Subject" />
                                                        {!!subjectVars.length && (
                                                            <>
                                                                <Typography variant={"subtitle2"}>
                                                                    You can use the following dynamic variables in Subject field: &nbsp;
                                                                    {subjectVars.map(v => {return v + " "})}
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.fields}>
                                                        <Editor
                                                            placeholder={"Add message here..."}
                                                            toolbar={this.toolbarConfig}
                                                            editorState={selectedTemplate.body_editor}
                                                            wrapperStyle={{
                                                                display: "flex",
                                                                flexDirection: "column-reverse",
                                                                border: "1px solid #979797",
                                                                borderRadius: "4px",
                                                                "&:focus": {
                                                                    boxShadow: "0 0 0 0.2rem rgba(0,64,110,.50)"
                                                                },
                                                                margin: "5px 0px",
                                                                overflow: "hidden"
                                                            }}
                                                            editorStyle={{
                                                                padding: "0px 8px",
                                                                minHeight: "170px",
                                                                height: "100%",
                                                                background: "#ffffff"
                                                            }}
                                                            toolbarStyle={{
                                                                marginBottom: "0px"
                                                            }}
                                                            onEditorStateChange={this.changeEditorState}>
                                                            <textarea disabled value={selectedTemplate["body_editor"]} />
                                                        </Editor>
                                                        {!!bodyVars.length && (
                                                            <>
                                                                <Typography variant={"subtitle2"}>
                                                                    You can use the following dynamic variables in Body field:&nbsp;
                                                                    {bodyVars.map(v => {return v + " "})}
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth type="submit" variant="contained" color="primary">
                                                        Save
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth component={Link} to="/adm/home">
                                                        Return to Admin Home
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Form>
                        <Snackbar open={varCopied} autoHideDuration={2000} onClose={() => this.setState(prev => ({ ...prev, varCopied: false }))}>
                            <SnackbarContent
                                className={classes.snackbarStyleViaNestedContent}
                                message={
                                    <span className={classes.copyMsg}>
                                        <CheckCircleSharp />
                                        {copyMsg}
                                    </span>
                                }
                            />
                        </Snackbar>
                        <Snackbar open={template_saved} autoHideDuration={2000} onClose={() => this.setState({ template_saved: false })}>
                            <SnackbarContent
                                className={classes.snackbarStyleViaNestedContent}
                                message={
                                    <span className={classes.copyMsg}>
                                        <CheckCircleSharp />
                                        {selectedTemplate.template_name} has been saved.
                                    </span>
                                }
                            />
                        </Snackbar>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

EmailTemplatesConf = connect(
    (state, ownProps) => ({
        authState: state.auth,
        templates: getTemplates(state, ownProps)
    }),
    {
        ...EmailTemplate.actions
    }
)(EmailTemplatesConf);

export default withStyles(styles)(withRouter(EmailTemplatesConf));
