import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CreateIcon from "@material-ui/icons/Create";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { withStyles } from "@material-ui/core";

import { DraftProposalParcel, FinalProposalParcel } from "../models";
import TextField from "../../common/TextField";
import SortSelect from "../../common/SortSelect";
import Select from "../../common/Select";
import MultiSelect from "../../common/MultiSelect";
import HelpLabel from "../../common/HelpLabel";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import Subquestion from "../../common/Subquestion";
import counties from "../../common/counties.json";
import { getValue } from "../../../api/utils";
import TablePaginationActionsWrapped from "../../common/Paginator";
import { createSelector } from "../../common/orm";
import { StatusUpdateTab, WorkPlanTab } from "../../home/models";

const getParcels = createSelector(
    (state, ownProps) => ownProps.plan.id,
    (state, ownProps) => ownProps.match.path === "/draft/workplan/:id",
    (session, id, isDraft) => {
        if (isDraft) {
            return session.DraftProposalParcel.filter({ proposal: id })
                .orderBy("id", "asc")
                .toRefArray();
        } else {
            return session.FinalProposalParcel.filter({ proposal: id })
                .orderBy("id", "asc")
                .toRefArray();
        }
    }
);

const getTab = WorkPlanTab.selectMessage(8);
const getStatusUpdateTab = StatusUpdateTab.selectMessage(4);

const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    centerAlign: {
        textAlign: "center"
    },
    hide: {
        display: "none"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    }
});

const columnData = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        width: "20px"
    },
    { id: "name", numeric: false, label: "Name" },
    { id: "county", numeric: false, label: "County" },
    {
        id: "site_significance",
        numeric: false,
        label: "Site Significance"
    },
    { id: "activity", numeric: false, label: "Activity" },
    { id: "acres", numeric: true, label: "Acres" },
    { id: "miles", numeric: true, label: "Miles" },
    {
        id: "estimated_cost",
        numeric: true,
        label: "Estimated Cost"
    },
    {
        id: "owner_type",
        numeric: false,
        label: "Type of Landowner",
        allowSort: true
    },
    {
        id: "title_holder",
        numeric: false,
        label: "Proposed Title or Easement Holder"
    },
    { id: "status", numeric: false, label: "Status of Work" },
    { id: "notes", numeric: false, label: "Notes" }
];

class ParcelTab extends Component {
    state = {
        sortValue: "-- Empty --",
        dialogOpen: false,
        parcelId: null,
        key: null,
        page: 0,
        rowsPerPage: 25,
        ormPropPrefix: this.props.match.path === "/draft/workplan/:id" ? "Draft" : "Final"
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        document.title = "Work Plan: Parcel List - LCCMR";
        this.props.onRef(this);
        const _this = this;
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function() {
            handleUnsavedFields(false);
            if (_this.props.appContainer.current) {
                _this.props.appContainer.current.scrollTop();
            }
        }, 1);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleRequestSort = sortValue => {
        if (sortValue.target.value === "-- Empty --") {
            this.setState({ sortValue: sortValue.target.value });
            return;
        }

        const orderBy = sortValue.target.value.split("-")[0];
        const order = sortValue.target.value.split("-")[1];

        this.props.parcels.sort(function(a, b) {
            var date = columnData.find(cD => cD.id === orderBy).date;
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            if (date) {
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc") return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ sortValue: sortValue.target.value });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    updateRecord = (value, element, id) => {
        const { ormPropPrefix } = this.state;
        const ormUpdate = this.props[`orm${ormPropPrefix}ProposalParcelUpdate`];

        const val = {
            id: id,
            [element]: value
        };
        ormUpdate(val);
    };

    render() {
        const {
            classes,
            plan,
            proposal,
            is_read_only,
            parcels,
            ActionMenu,
            handleNext,
            handlePrev,
            history,
            ormParentUpdateLocalOnly,
            statusUpdateTab,
            tab,
            last_step, authState
        } = this.props;
        const { dialogOpen, parcelId, ormPropPrefix, key, page, sortValue, rowsPerPage } = this.state;
        const { id } = this.props.plan;

        var is_dnr = false;
        if (authState && authState.user && authState.user.is_read_only)
            is_dnr = true;

        var totalAcres = 0.0;
        var totalMiles = 0.0;
        var totalCost = 0;

        parcels.forEach(p => {
            if (p.acres) {
                totalAcres += parseFloat(p.acres);
            }
            if (p.miles) {
                totalMiles += parseFloat(p.miles);
            }
            if (p.estimated_cost) {
                totalCost += parseInt(p.estimated_cost);
            }
        });

        var sortOptions = [];
        columnData.forEach(x => {
            sortOptions.push({
                label: x.label + " - ascending",
                value: x.id + "-asc"
            });
            sortOptions.push({
                label: x.label + " - descending",
                value: x.id + "-desc"
            });
        });

        return (
            <Grid container spacing={16} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                    <Typography variant="titleAction">Parcel List</Typography>
                    {ActionMenu}
                </Grid>
                <Grid item xs={12}>
                    <div class="insborder">
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: (proposal.status !== "Final Work Plan Approved" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && proposal.status !== "Project Completed")
                                        ? tab.sub_tabs.find(tht => tht.tab_name === "Parcel List").tab_help_texts.find(tht => tht.label === "Parcel List").text
                                        : statusUpdateTab.sub_tabs
                                              .find(tht => tht.tab_name === "Parcel List")
                                              .tab_help_texts.find(tht => tht.label === "Parcel List").text
                                }}
                            />
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        style={{ marginBottom: 8 }}
                        variant="contained"
                        fullWidth
                        disabled={is_read_only}
                        autoFocus={plan.highlightedField === "parcel_table"}
                        color="primary"
                        onClick={() => {
                            this.props[`orm${ormPropPrefix}ProposalParcelCreate`]({ proposal: plan.id }).then(response => {
                                this.setState({
                                    dialogOpen: true,
                                    parcelId: response,
                                    key: window.performance.now()
                                });
                            });
                        }}>
                        <AddCircleIcon />
                        &nbsp;&nbsp;&nbsp;Add Parcel
                    </Button>
                    <SortSelect
                        label="Sort:"
                        labelId="pacel-sort-label"
                        id="pacel-sort"
                        fullWidth
                        includeEmpty
                        value={sortValue}
                        handleChange={this.handleRequestSort}
                        sortOptions={sortOptions}
                    />
                    <Table className={classes.table}>
                        <EnhancedTableHead stickyHeader columnData={columnData} />
                        <TableBody>
                            {parcels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                var status_options = [];
                                if (n.activity === "Restoration") {
                                    status_options = [
                                        { label: "Has Not Begun", value: "Has Not Begun" },
                                        { label: "In Progress", value: "In Progress" },
                                        { label: "Completed", value: "Completed" },
                                        {
                                            label: "No Longer Being Considered",
                                            value: "No Longer Being Considered"
                                        }
                                    ];
                                } else {
                                    status_options = [
                                        { label: "Has Not Begun", value: "Has Not Begun" },
                                        {
                                            label: "Negotiations In Progress",
                                            value: "Negotiations In Progress"
                                        },
                                        { label: "Property Under Contract", value: "Property Under Contract" },
                                        { label: "Purchased", value: "Purchased" },
                                        {
                                            label: "No Longer Being Considered",
                                            value: "No Longer Being Considered"
                                        }
                                    ];
                                }
                                return (
                                    <TableRow key={n.id}>
                                        <CustomTableCell className={classes.nowrap}>
                                            <Tooltip title="Edit Parcel">
                                                <Button
                                                    color="primary"
                                                    aria-label="Edit Parcel"
                                                    className={
                                                        is_dnr || 
                                                        (proposal.status !== "Final Work Plan Approved" && proposal.status !== "Final Abstract Due" && proposal.status !== "Final Abstract Submitted" && proposal.status !== "Final Abstract Revisions Needed" && is_read_only) ||
                                                        (
                                                            (proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") &&
                                                            plan.reporting_status !== "Update Due" &&
                                                            plan.reporting_status !== "Final Update Due" &&
                                                            plan.reporting_status !== "Additional Update In Progress" &&
                                                            plan.reporting_status !== "Update Revisions Needed" &&
                                                            plan.reporting_status !== "Final Update Revisions Needed" &&
                                                            plan.reporting_status !== "Additional Update Revisions Needed" &&
                                                            plan.status !== "Amendment In Progress" &&
                                                            plan.status !== "Amendment Revisions Needed"
                                                        )
                                                            ? classes.hide
                                                            : classes.deleteWidth
                                                    }
                                                    onClick={() =>
                                                        this.setState({
                                                            dialogOpen: true,
                                                            parcelId: n.id,
                                                            key: window.performance.now()
                                                        })
                                                    }>
                                                    <CreateIcon color="primary" />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Delete Parcel">
                                                <Button
                                                    color="primary"
                                                    aria-label="Delete Parcel"
                                                    className={is_read_only ? classes.hide : classes.deleteWidth}
                                                    onClick={() => this.props[`orm${ormPropPrefix}ProposalParcelDelete`](n.id)}>
                                                    <DeleteIcon color="primary" />
                                                </Button>
                                            </Tooltip>
                                        </CustomTableCell>
                                        <CustomTableCell>{n.name}</CustomTableCell>
                                        <CustomTableCell>{n.county.join(", ")}</CustomTableCell>
                                        <CustomTableCell>{n.site_significance}</CustomTableCell>
                                        <CustomTableCell>{n.activity}</CustomTableCell>
                                        <CustomTableCell>{n.acres}</CustomTableCell>
                                        <CustomTableCell>{n.miles}</CustomTableCell>
                                        <CustomTableCell>{n.estimated_cost ? n.estimated_cost.toLocaleString() : null}</CustomTableCell>
                                        <CustomTableCell>{n.owner_type}</CustomTableCell>
                                        <CustomTableCell>{n.title_holder}</CustomTableCell>
                                        <CustomTableCell>
                                            {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Reivsions Needed" || proposal.status === "Final Abstract Submitted") && !is_dnr && 
                                            (plan.reporting_status === "Update Due" || plan.reporting_status === "Final Update Due" ||
                                                plan.reporting_status === "Additional Update In Progress" ||
                                                plan.reporting_status === "Update Revisions Needed" || plan.reporting_status === "Final Update Revisions Needed" ||
                                                plan.reporting_status === "Additional Update Revisions Needed") ? (
                                                <Form key={n.id + "status"} defaultValues={n}>
                                                    {formApi => (
                                                        <form onSubmit={formApi.submitForm}>
                                                            <Select
                                                                style={{ width: 200 }}
                                                                tableFormat
                                                                options={status_options}
                                                                eventHandleProps1={n.id}
                                                                eventHandle={this.updateRecord}
                                                                field="status"
                                                                label=""
                                                                fullWidth
                                                            />
                                                        </form>
                                                    )}
                                                </Form>
                                            ) : (
                                                n.status
                                            )}
                                        </CustomTableCell>
                                        <CustomTableCell>{n.notes}</CustomTableCell>
                                    </TableRow>
                                );
                            })}
                            {parcels.length < 1 && (
                                <TableRow>
                                    <CustomTableCell colSpan={11} className={classes.centerAlign}>
                                        <caption style={{display:"inline"}}>No Parcels Found</caption>
                                    </CustomTableCell>
                                </TableRow>
                            )}
                            {parcels.length > 0 && (
                                <TableRow>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell>
                                        <b>Totals</b>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <b>{Math.round((totalAcres + Number.EPSILON) * 100) / 100}</b>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <b>{totalMiles.toPrecision(3)}</b>
                                    </CustomTableCell>
                                    <CustomTableCell>
                                        <b>{"$ " + totalCost.toLocaleString()}</b>
                                    </CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                    <CustomTableCell></CustomTableCell>
                                </TableRow>
                            )}
                            {parcels.length > 25 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={12}
                                            count={parcels.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
                {(proposal.status === "Final Work Plan Approved" || proposal.status === "Final Abstract Due" || proposal.status === "Final Abstract Submitted" || proposal.status === "Final Abstract Revisions Needed") && (
                    <Grid item xs={12} className={classes.rightAlign}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                ormParentUpdateLocalOnly({
                                    id: id,
                                    activeStep: last_step,
                                    activeSubStep: 0
                                });
                            }}
                            className={classes.button}>
                            Jump to Written Status Report
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                ormParentUpdateLocalOnly({
                                    id: id,
                                    activeStep: 5,
                                    activeSubStep: 8
                                });
                            }}
                            className={classes.button}>
                            Jump to Spending Status Report
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.rightAlign}>
                    <Button
                        variant="contained"
                        type="submit"
                        style={{ float: "left" }}
                        disabled={is_read_only}
                        onClick={() => handlePrev()}
                        className={classes.button}>
                        Save and Return to Previous Step
                    </Button>
                    <Button variant="contained" type="submit" disabled={is_read_only} onClick={() => history.push("/dashboard/")} className={classes.button}>
                        Save Draft and Return to Dashboard
                    </Button>
                    <Button variant="contained" disabled={is_read_only} type="submit" color="primary" onClick={() => handleNext()}>
                        Save and Proceed to Next Step
                    </Button>
                </Grid>
                <ParcelDialog
                    is_read_only={is_read_only}
                    open={dialogOpen}
                    ormProposalParcelUpdateLocalOnly={this.props[`orm${ormPropPrefix}ProposalParcelUpdateLocalOnly`]}
                    ormProposalParcelUpdate={this.props[`orm${ormPropPrefix}ProposalParcelUpdate`]}
                    parcelId={parcelId}
                    returnDialog={() => this.setState({ dialogOpen: false })}
                    classes={classes}
                    formkey={key}
                    parcels={parcels}
                    proposal={proposal}
                    tab={tab}
                />
            </Grid>
        );
    }
}

class ParcelDialog extends Component {
    state = {
        selectKey: window.performance.now()
    };

    addParcel(values) {
        const { returnDialog, ormProposalParcelUpdate } = this.props;

        ormProposalParcelUpdate({
            ...values
        });

        returnDialog();
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name)
        };

        return valObj;
    };

    applyOther = (value, element) => {
        const { ormProposalParcelUpdateLocalOnly, parcelId, parcels } = this.props;
        const parc = parcels.find(p => p.id === parcelId);
        ormProposalParcelUpdateLocalOnly({
            ...parc,
            [element]: value
        });
        this.setState({ selectKey: window.performance.now() });
    };

    render() {
        const { classes, is_read_only, open, returnDialog, parcelId, formkey, parcels, tab } = this.props;
        const { selectKey } = this.state;

        const parc = parcels.find(p => p.id === parcelId);
        const owner_type = getValue(parc, "owner_type");
        const activity = getValue(parc, "activity");

        var interact_string = parc && parc.name ? "Edit" : "Add";
        if (is_read_only) {
            interact_string = "View";
        }

        var show_justification = false;
        if ((owner_type === "Private" && activity === "Restoration") || (owner_type === "Public" && activity === "Fee Title")) show_justification = true;

        var status_options = [];
        if (activity === "Restoration") {
            status_options = [
                { label: "Has Not Begun", value: "Has Not Begun" },
                { label: "In Progress", value: "In Progress" },
                { label: "Completed", value: "Completed" },
                {
                    label: "No Longer Being Considered",
                    value: "No Longer Being Considered"
                }
            ];
        } else {
            status_options = [
                { label: "Has Not Begun", value: "Has Not Begun" },
                {
                    label: "Negotiations In Progress",
                    value: "Negotiations In Progress"
                },
                { label: "Property Under Contract", value: "Property Under Contract" },
                { label: "Purchased", value: "Purchased" },
                {
                    label: "No Longer Being Considered",
                    value: "No Longer Being Considered"
                }
            ];
        }

        return (
            <Dialog role="main" aria-label={interact_string + " Parcel"} open={open}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        {interact_string} Parcel
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={formkey}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={parc}
                            onSubmit={values => this.addParcel(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField field="name" fullWidth disabled={is_read_only}>
                                                <HelpLabel
                                                    inputLabel="Parcel Name"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Parcel List")
                                                            .tab_help_texts.find(tht => tht.label === "Parcel Name").text
                                                    }
                                                />
                                            </TextField>
                                            <MultiSelect field="county" fullWidth disabled={is_read_only} label="County" options={counties} />
                                            <TextField field="site_significance" fullWidth disabled={is_read_only}>
                                                <HelpLabel
                                                    inputLabel="Site Significance"
                                                    showLabel={true}
                                                    htmlText={true}
                                                    helpText={
                                                        tab.sub_tabs
                                                            .find(tht => tht.tab_name === "Parcel List")
                                                            .tab_help_texts.find(tht => tht.label === "Site Significance").text
                                                    }
                                                />
                                            </TextField>
                                            <Select
                                                eventHandle={this.applyOther}
                                                field="activity"
                                                label="Activity"
                                                disabled={is_read_only}
                                                options={[
                                                    { label: "Fee Title", value: "Fee Title" },
                                                    {
                                                        label: "Conservation Easement",
                                                        value: "Conservation Easement"
                                                    },
                                                    {
                                                        label: "Easement - Other",
                                                        value: "Easement - Other"
                                                    },
                                                    { label: "Restoration", value: "Restoration" }
                                                ]}
                                                fullWidth
                                            />
                                            <Grid container spacing={16}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        useFloatFormat
                                                        field="acres"
                                                        label="Number of Acres"
                                                        disabled={is_read_only}
                                                        fullWidth></TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField useFloatFormat field="miles" fullWidth disabled={is_read_only}>
                                                        <HelpLabel
                                                            inputLabel="Miles"
                                                            showLabel={true}
                                                            htmlText={true}
                                                            helpText={
                                                                tab.sub_tabs
                                                                    .find(tht => tht.tab_name === "Parcel List")
                                                                    .tab_help_texts.find(tht => tht.label === "Miles").text
                                                            }
                                                        />
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField useTextFormat field="estimated_cost" label="Estimated Cost" fullWidth disabled={is_read_only} />
                                                </Grid>
                                            </Grid>
                                            <Select
                                                eventHandle={this.applyOther}
                                                field="owner_type"
                                                label="Type of Current Land Owner"
                                                disabled={is_read_only}
                                                options={[
                                                    { label: "Public", value: "Public" },
                                                    { label: "Private", value: "Private" }
                                                ]}
                                                fullWidth
                                            />
                                            {show_justification && (
                                                <Subquestion
                                                    component={<TextField field="justification" label="Justification" fullWidth disabled={is_read_only} />}
                                                />
                                            )}
                                            <TextField field="title_holder" label="Proposed Title or Easement Holder" fullWidth disabled={is_read_only} />
                                            <Select
                                                key={selectKey}
                                                field="status"
                                                label="Status of Work"
                                                options={status_options}
                                                fullWidth
                                                //disabled={is_read_only}
                                            />
                                            <TextField multiline field="notes" label="Notes" fullWidth disabled={is_read_only} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth onClick={() => returnDialog()}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button fullWidth type="submit" variant="contained" color="primary" disabled={is_read_only}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

ParcelTab = connect(
    (state, ownProps) => ({
        parcels: getParcels(state, ownProps),
        tab: getTab(state, ownProps),
        statusUpdateTab: getStatusUpdateTab(state, ownProps),
        authState: state.auth
    }),
    {
        ...DraftProposalParcel.actions,
        ...FinalProposalParcel.actions
    }
)(ParcelTab);

export default withStyles(styles)(withRouter(ParcelTab));
