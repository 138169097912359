import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import Visibility from "@material-ui/icons/Visibility";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import CreateIcon from "@material-ui/icons/Create";
import AnnouncementIcon from "@material-ui/icons/Announcement";

import Messages from "./Messages";
import AppContainer from "../common/AppContainer";
import RadioGroup from "../common/RadioGroup";
import PageHeader from "../common/PageHeader";
import TextField from "../common/TextField";
import NewProposal from "../proposal/NewProposal";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import TablePaginationActionsWrapped from "../common/Paginator";
import { createSelector, reloadListOfModels } from "../common/orm";
import { ProposalReview, SystemSetting } from "./models";
import { editWorkPlanStatuses } from "../../api/constants";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        marginBottom: 20,
        backgroundColor: "#fff"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginTop: 24
        }
    },
    expansionSummaryRoot2: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginTop: 0
        }
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    flex: {
        flex: 1
    },
    progress: {
        width: "100%",
        textAlign: "center"
    }
});

const getReviews = ProposalReview.selectAll();
const getSystemSettings = SystemSetting.selectAll();
const getProposals = createSelector(
    (state, ownProps) => (state.auth && state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema, id, auth) => {
        const years = schema.SystemSetting.all().toRefArray();
        const reviews = schema.ProposalReview.all().toRefArray();

        // Only allow proposal without detail key(This is when a id is loaded by entering a id into URL which doesn't exist it has this key with "Not Found.").
        // Staff dashboard use proposal view so doesn't need this check.
        return schema.Proposal.filter(x => !x.detail)
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(x => {
                var name,
                    category,
                    full_name,
                    funding_total,
                    organization_name,
                    pm_first_name,
                    pm_last_name,
                    status_date_label,
                    wp_due_date,
                    org,
                    next_status_update_date,
                    reporting_status,
                    last_action;

                if (x.primary_phase === "Draft Work Plan" || x.primary_phase === "Final Work Plan") {
                    var plan;
                    if (x.primary_phase === "Draft Work Plan") {
                        plan = schema.DraftWorkPlan.filter(view => view.proposal_record_id === x.id).first();
                    } else {
                        plan = schema.FinalWorkPlan.filter(view => view.proposal_record_id === x.id).first();
                    }

                    if (plan) {
                        name = plan.name;
                        category = plan.funding_category;
                        funding_total = x.appropriation_amount ? x.appropriation_amount : x.recommended_amount;
                        organization_name = plan.organization_name;
                        pm_first_name = plan ? plan.pm_first_name : "";
                        pm_last_name = plan ? plan.pm_last_name : "";
                        status_date_label = plan.status_date_label;
                        if (pm_first_name) full_name = pm_first_name;
                        if (pm_last_name) full_name += " " + pm_last_name;
                        org = schema.Organization.filter(z => z.id === plan.ref.organization).first();
                        organization_name = org ? org.organization_name : "";

                        wp_due_date = plan.wp_due_date;
                        next_status_update_date = plan.next_status_update_date;
                        reporting_status = plan.reporting_status;
                        last_action = plan.last_action;
                    }
                } else {
                    name = x.name;
                    category = !x.final_funding_category ? (x.funding_category ? x.funding_category : "") : x.final_funding_category;
                    funding_total = x.funding_total ? x.funding_total : 0;
                    status_date_label = x.status_date_label;
                    org = schema.Organization.filter(z => z.id === x.ref.organization).first();
                    organization_name = org ? org.organization_name : "";

                    pm_first_name = x ? x.pm_first_name : "";
                    pm_last_name = x ? x.pm_last_name : "";
                    if (pm_first_name) full_name = pm_first_name;
                    if (pm_last_name) full_name += " " + pm_last_name;
                }

                return {
                    id: x.id,
                    full_name,
                    citation_ml_year: x.citation_ml_year,
                    user_id: x.user_id,
                    ml_year: x.ml_year,
                    proposal_unique_id: x.proposal_unique_id,
                    primary_phase: x.primary_phase,
                    recommended_amount: x.recommended_amount,
                    delegated_by: x.delegated_by,
                    name,
                    peer_status: x.peer_status,
                    peer_review: x.peer_review,
                    pm_first_name,
                    pm_last_name,
                    category,
                    funding_total,
                    status: x.status,
                    status_date_label,
                    staff_lead_label: x.staff_lead_label,
                    secondary_status: x.secondary_status,
                    warningMessage: checkForWarning(years, reviews, x, auth, wp_due_date),
                    table_due_date: getDueDate(years, x, wp_due_date),
                    organization_name,
                    subdivision: x.subdivision,
                    appropriation_amount: x.appropriation_amount,
                    next_status_update_date,
                    statusWarningMessage: checkForStatus(next_status_update_date, reporting_status, null, null, null, null),
                    reporting_status,
                    last_action
                };
            });
    }
);

const getPlans = createSelector(
    (state, ownProps) => (state.auth && state.auth.user ? state.auth.user.id : null),
    (state, ownProps) => state.auth,
    (schema, id, auth) => {
        const years = schema.SystemSetting.all().toRefArray();
        const reviews = schema.ProposalReview.all().toRefArray();

        // Only allow proposal without detail key(This is when a id is loaded by entering a id into URL which doesn't exist it has this key with "Not Found.").
        // Staff dashboard use proposal view so doesn't need this check.
        return schema.Proposal.filter(x => !x.detail)
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(x => {
                var name,
                    category,
                    full_name,
                    funding_total,
                    organization_name,
                    pm_first_name,
                    pm_last_name,
                    status_date_label,
                    wp_due_date,
                    org,
                    next_status_update_date,
                    reporting_status,
                    amendment_status,
                    includes_amendment,
                    amendment_due_date,
                    last_action,
                    amount_spent;

                if (x.primary_phase === "Draft Work Plan" || x.primary_phase === "Final Work Plan") {
                    var plan;
                    if (x.primary_phase === "Draft Work Plan") {
                        plan = schema.DraftWorkPlan.filter(view => view.proposal_record_id === x.id).first();
                    } else {
                        plan = schema.FinalWorkPlan.filter(view => view.proposal_record_id === x.id).first();
                    }

                    if (plan) {
                        name = plan.name;
                        category = plan.funding_category;
                        funding_total = x.appropriation_amount ? x.appropriation_amount : x.recommended_amount;
                        organization_name = plan.organization_name;
                        pm_first_name = plan ? plan.pm_first_name : "";
                        pm_last_name = plan ? plan.pm_last_name : "";
                        status_date_label = plan.status_date_label;
                        if (pm_first_name) full_name = pm_first_name;
                        if (pm_last_name) full_name += " " + pm_last_name;
                        org = schema.Organization.filter(z => z.id === plan.ref.organization).first();
                        organization_name = org ? org.organization_name : "";

                        wp_due_date = plan.wp_due_date;
                        next_status_update_date = plan.next_status_update_date;
                        reporting_status = plan.reporting_status;
                        last_action = plan.last_action;
                        amendment_status = plan.status;
                        includes_amendment = plan.includes_amendment
                        amendment_due_date = plan.amendment_due_date
                        amount_spent = plan.amount_spent;
                    }
                } else {
                    name = x.name;
                    category = !x.final_funding_category ? (x.funding_category ? x.funding_category : "") : x.final_funding_category;
                    funding_total = x.funding_total ? x.funding_total : 0;
                    status_date_label = x.status_date_label;
                    org = schema.Organization.filter(z => z.id === x.ref.organization).first();
                    organization_name = org ? org.organization_name : "";

                    pm_first_name = x ? x.pm_first_name : "";
                    pm_last_name = x ? x.pm_last_name : "";
                    if (pm_first_name) full_name = pm_first_name;
                    if (pm_last_name) full_name += " " + pm_last_name;
                }

                return {
                    id: x.id,
                    full_name,
                    citation_ml_year: x.citation_ml_year,
                    user_id: x.user_id,
                    ml_year: x.ml_year,
                    proposal_unique_id: x.proposal_unique_id,
                    primary_phase: x.primary_phase,
                    recommended_amount: x.recommended_amount,
                    delegated_by: x.delegated_by,
                    name,
                    peer_status: x.peer_status,
                    peer_review: x.peer_review,
                    pm_first_name,
                    pm_last_name,
                    category,
                    funding_total,
                    status: x.status,
                    amendment_status,
                    status_date_label,
                    includes_amendment,
                    staff_lead_label: x.staff_lead_label,
                    secondary_status: x.secondary_status,
                    warningMessage: checkForWarning(years, reviews, x, auth, wp_due_date),
                    table_due_date: getDueDate(years, x, wp_due_date),
                    organization_name,
                    subdivision: x.subdivision,
                    appropriation_amount: x.appropriation_amount,
                    next_status_update_date,
                    statusWarningMessage: checkForStatus(next_status_update_date, reporting_status, amendment_status, amendment_due_date, x.status, x.abstract_due_date),
                    reporting_status,
                    amount_spent,
                    last_action
                };
            });
    }
);

function checkForWarning(years, reviews, obj, auth, wp_due_date = null) {
    const year = years.find(x => obj && x.year === obj.ml_year);
    var warning = false;
    var warningMessage = "";

    if (year) {
        const today = new Date();
        const due_date = new Date(obj.due_date + "T23:00:00"); //almost end of day since not really strict date

        if (obj.primary_phase.includes("Work Plan") && obj.status.includes("Due")) {
            const close_date = year.final_created ? new Date(year.finalplan_date) : new Date(year.draftplan_date);
            const override_due_date = wp_due_date ? new Date(wp_due_date) : null;
            const status_string = obj.primary_phase.includes("Final") ? "Final" : "Draft";

            if (override_due_date && today > override_due_date) {
                warning = true;
                warningMessage = ` ${status_string} Work Plan not submitted`;
            } else if (!override_due_date && today > close_date) {
                warning = true;
                warningMessage = ` ${status_string} Work Plan not submitted`;
            }
        }

        //revisions common for both user and staff
        if (obj.status.includes("Revisions Needed") && today > due_date) {
            warning = true;
            warningMessage = "Revisions Over Due";

            //for staff display if score exists
            if (auth && auth.user && auth.user.role === "Staff") {
                const review = reviews.find(x => x.proposal === obj.id);
                warningMessage += `. ${review && review.final_score ? "Has Score." : "Missing Staff Score."}`;
            }
        }
    }

    if (warning) {
        return warningMessage;
    } else {
        return null;
    }
}

function checkForStatus(next_status_update_date, reporting_status, amendment_status, amendment_due_date, proposal_status, abstract_due_date) {
    var today = new Date();
    var next_date = new Date(next_status_update_date);
    if (abstract_due_date)
        var timeline_date = new Date(abstract_due_date);

    var arr_values = [];

    if (today > next_date && (reporting_status === "Update Due" || reporting_status === "Final Update Due"))
        arr_values.push("Update Past Due");

    if (today > next_date && (reporting_status === "Update Revisions Needed" || reporting_status === "Final Update Revisions Needed")) 
        arr_values.push("Update Revisions Past Due");

    if (today > next_date && reporting_status === "Additional Update Revisions Needed") 
        arr_values.push("Additional Update Revisions Past Due");

    if (amendment_due_date && today > amendment_due_date && amendment_status === "Amendment Revisions Needed") 
        arr_values.push("Amendment Revisions Past Due");

    if (abstract_due_date) {
        if (today >= timeline_date && proposal_status === "Final Abstract Due") 
            arr_values.push("Final Abstract Past Due");

        if (today >= timeline_date && proposal_status === "Final Abstract Revisions Needed") 
            arr_values.push("Final Abstract Revisions Past Due");
    }

    if (arr_values.length === 0)
        return null;

    return arr_values.join("; ");

}

function formatDate(date, add) {
    if (!date) {
        return "";
    }
    var temp;
    if (add) {
        temp = new Date(date + "T12:00:00");
    } else {
        temp = new Date(date);
    }
    const dateFns = new DateFnsUtils();
    return dateFns.format(temp, "MM/dd/yyyy");
}

function getDueDate(years, object, wp_due_date = null) {
    const year = years.find(x => x.year === object.ml_year);
    const date = new Date();
    if (year) {
        var final_date = new Date(year.final_date);
        var draft_date = new Date(year.draft_date);
        if (object.primary_phase === "Draft Proposal" || object.primary_phase === "Proposal") {
            if (object.status === "Draft In Progress") {
                if (year.funding_open && date < draft_date) {
                    return formatDate(year.draft_date, false);
                } else {
                    return formatDate(final_date);
                }
            } else if (object.status === "Revisions Needed") {
                return formatDate(object.due_date, true);
            } else {
                return formatDate(final_date, false);
            }
        } else if (object.primary_phase === "Draft Work Plan" || object.primary_phase === "Final Work Plan") {
            if (object.status === "Draft Work Plan Due") {
                if (wp_due_date) {
                    return formatDate(wp_due_date, true);
                } else {
                    return formatDate(year.draftplan_date, true);
                }
            } else if (object.status === "Final Work Plan Due") {
                if (wp_due_date) {
                    return formatDate(wp_due_date, true);
                } else {
                    return formatDate(year.finalplan_date, true);
                }
            } else if (object.status === "Draft Work Plan Revisions Needed" || object.status === "Final Work Plan Revisions Needed") {
                return formatDate(object.due_date, true);
            }
        }
    } else {
        return "---";
    }
}

const columnDataProposal = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "category", numeric: false, label: "Category", allowSort: true },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true },
    { id: "status_date_label", numeric: false, label: "Date Of Last Action", date: true, allowSort: true },
    { id: "table_due_date", numeric: false, label: "Next Due Date", allowSort: true, date: true },
    { id: "staff_lead_label", numeric: false, label: "LCCMR Lead", allowSort: true },
    { id: "peer_status", numeric: false, label: "Peer Review Status", allowSort: true },
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true },
    { id: "warningMessage", numeric: false, label: "Warning", allowSort: true }
];

const columnDataWorkPlan = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Project ID", allowSort: true },
    { id: "ml_year", numeric: false, label: "ML Year / Subd", allowSort: true, combined: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "category", numeric: false, label: "Category", allowSort: true },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "reporting_status", numeric: false, label: "Reporting Status", allowSort: true },
    { id: "last_action", numeric: false, label: "Date Of Last Action", date: true, allowSort: true },
    { id: "next_status_update_date", numeric: false, label: "Next Due Date", allowSort: true, date: true },
    { id: "staff_lead_label", numeric: false, label: "LCCMR Lead", allowSort: true },
    { id: "includes_amendment", numeric: false, label: "Update Includes Amendment Request", allowSort: true },
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Process Status", allowSort: true },
    { id: "ilr", numeric: false, label: "Initial Land Acquisition Reporting Status", allowSort: true },
    { id: "statusWarningMessage", numeric: false, label: "Warning", allowSort: true }
];
const columnDataCompletedProject = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "proposal_unique_id", numeric: false, label: "Project ID", allowSort: true },
    { id: "ml_year", numeric: false, label: "ML Year / Subd", allowSort: true, combined: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "category", numeric: false, label: "Category", allowSort: true },
    { id: "organization_name", numeric: false, label: "Organization", allowSort: true },
    { id: "full_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "amount_spent", numeric: true, label: "Amount Spent", allowSort: true },
    { id: "last_action", numeric: false, label: "Date Of Last Action", date: true, allowSort: true },
    { id: "ilr", numeric: false, label: "Land Acquisition Annual Reporting", allowSort: true },
    { id: "next_status_update_date", numeric: false, label: "Next Due Date", allowSort: true, date: true },
    { id: "staff_lead_label", numeric: false, label: "LCCMR Lead", allowSort: true },
];

class Dashboard extends Component {
    state = {
        page: 0,
        rowsPerPage: 100,
        proposalFilter: null,
        proposalFilterYear: null,
        order: "desc",
        orderBy: "proposal_unique_id",
        wpPage: 0,
        wpRowsPerPage: 100,
        wpFilter: null,
        wpFilterYear: null,
        wpOrder: "desc",
        wpOrderBy: "proposal_unique_id",
        cPage: 0,
        cRowsPerPage: 100,
        cFilter: null,
        cOrder: "desc",
        cOrderBy: "proposal_unique_id",
    };

    componentDidMount() {
        const { authState } = this.props;
        document.title = "Dashboard - LCCMR Proposal and Grant Management System";

        if (authState && authState.user) {
            this.props.reloadListOfModels(["SystemSetting"]);
        }
    }

    filterByValue = (array, string) => {
        return array.filter(o =>
            [
                "proposal_unique_id",
                "name",
                "full_name",
                "category",
                "organization_name",
                "funding_total",
                "pm_first_name",
                "status",
                "peer",
                "status_date_label",
                "table_due_date",
                "secondary_status"
            ].some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    wpHandleChangePage = (event, wpPage) => {
        this.setState({ wpPage });
    };

    cHandleChangePage = (event, cPage) => {
        this.setState({ cPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    wpHandleChangeRowsPerPage = event => {
        this.setState({ wpRowsPerPage: event.target.value, wpPage: 0 });
    };

    cHandleChangeRowsPerPage = event => {
        this.setState({ cRowsPerPage: event.target.value, cPage: 0 });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        var sort = this.props.proposals;

        sort.sort(function(a, b) {
            var date = columnDataProposal.find(cD => cD.id === orderBy).date;
            var numeric = columnDataProposal.find(cD => cD.id === orderBy).numeric;
            if (date) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
            } else if (numeric) {
                if (a[orderBy] === b[orderBy]) return 0;
                else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
            } else {
                if (order === "desc") return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ order, orderBy });
    };

    wpHandleRequestSort = (event, property) => {
        const wpOrderBy = property;
        let wpOrder = "desc";

        if (this.state.wpOrderBy === property && this.state.wpOrder === "desc") {
            wpOrder = "asc";
        }

        var sort = this.props.plans;

        sort.sort(function(a, b) {
            var date = columnDataWorkPlan.find(cD => cD.id === wpOrderBy).date;
            var numeric = columnDataWorkPlan.find(cD => cD.id === wpOrderBy).numeric;
            var combined = columnDataWorkPlan.find(cD => cD.id === wpOrderBy).combined;
            if (date) {
                if (a[wpOrderBy] === b[wpOrderBy]) return 0;
                else if (a[wpOrderBy] === null || typeof a[wpOrderBy] === "undefined" || a[wpOrderBy] === "-") return 1;
                else if (b[wpOrderBy] === null || typeof b[wpOrderBy] === "undefined" || b[wpOrderBy] === "-") return -1;
                if (wpOrder === "desc") return new Date(b[wpOrderBy]) < new Date(a[wpOrderBy]) ? -1 : 1;
                else return new Date(a[wpOrderBy]) < new Date(b[wpOrderBy]) ? -1 : 1;
            } else if (numeric) {
                if (a[wpOrderBy] === b[wpOrderBy]) return 0;
                else if (a[wpOrderBy] === null || typeof a[wpOrderBy] === "undefined") return 1;
                else if (b[wpOrderBy] === null || typeof b[wpOrderBy] === "undefined") return -1;
                if (wpOrder === "desc") return parseFloat(b[wpOrderBy]) < parseFloat(a[wpOrderBy]) ? -1 : 1;
                else return parseFloat(a[wpOrderBy]) < parseFloat(b[wpOrderBy]) ? -1 : 1;
            } else if (combined) {
                if (wpOrder === "desc")
                    return (b[wpOrderBy] + " " + b["subdivision"] || "").toUpperCase() < (a[wpOrderBy] + " " + a["subdivision"] || "").toUpperCase() ? -1 : 1;
                else return (a[wpOrderBy] + " " + a["subdivision"] || "").toUpperCase() < (b[wpOrderBy] + " " + b["subdivision"] || "").toUpperCase() ? -1 : 1;
            } else {
                if (wpOrder === "desc") return (b[wpOrderBy] || "").toUpperCase() < (a[wpOrderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[wpOrderBy] || "").toUpperCase() < (b[wpOrderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ wpOrder, wpOrderBy });
    };

    cHandleRequestSort = (event, property) => {
        const cOrderBy = property;
        let cOrder = "desc";

        if (this.state.cOrderBy === property && this.state.cOrder === "desc") {
            cOrder = "asc";
        }

        var sort = this.props.plans;

        sort.sort(function(a, b) {
            var date = columnDataWorkPlan.find(cD => cD.id === cOrderBy).date;
            var numeric = columnDataWorkPlan.find(cD => cD.id === cOrderBy).numeric;
            var combined = columnDataWorkPlan.find(cD => cD.id === cOrderBy).combined;
            if (date) {
                if (a[cOrderBy] === b[cOrderBy]) return 0;
                else if (a[cOrderBy] === null || typeof a[cOrderBy] === "undefined" || a[cOrderBy] === "-") return 1;
                else if (b[cOrderBy] === null || typeof b[cOrderBy] === "undefined" || b[cOrderBy] === "-") return -1;
                if (cOrder === "desc") return new Date(b[cOrderBy]) < new Date(a[cOrderBy]) ? -1 : 1;
                else return new Date(a[cOrderBy]) < new Date(b[cOrderBy]) ? -1 : 1;
            } else if (numeric) {
                if (a[cOrderBy] === b[cOrderBy]) return 0;
                else if (a[cOrderBy] === null || typeof a[cOrderBy] === "undefined") return 1;
                else if (b[cOrderBy] === null || typeof b[cOrderBy] === "undefined") return -1;
                if (cOrder === "desc") return parseFloat(b[cOrderBy]) < parseFloat(a[cOrderBy]) ? -1 : 1;
                else return parseFloat(a[cOrderBy]) < parseFloat(b[cOrderBy]) ? -1 : 1;
            } else if (combined) {
                if (cOrder === "desc")
                    return (b[cOrderBy] + " " + b["subdivision"] || "").toUpperCase() < (a[cOrderBy] + " " + a["subdivision"] || "").toUpperCase() ? -1 : 1;
                else return (a[cOrderBy] + " " + a["subdivision"] || "").toUpperCase() < (b[cOrderBy] + " " + b["subdivision"] || "").toUpperCase() ? -1 : 1;
            } else {
                if (cOrder === "desc") return (b[cOrderBy] || "").toUpperCase() < (a[cOrderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[cOrderBy] || "").toUpperCase() < (b[cOrderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ cOrder, cOrderBy });
    };

    determineIfOpen = () => {
        const { years } = this.props;
        const date = new Date();

        //check year + 1 is open
        const matching = years.find(x => parseInt(x.year) === date.getUTCFullYear());
        if (matching && matching.open_date && new Date(matching.open_date) < date && new Date(matching.final_date) > date) {
            return true;
        } else {
            const next = years.find(x => parseInt(x.year) === date.getUTCFullYear() + 1);
            if (next && next.open_date && new Date(next.open_date) < date && new Date(next.final_date) > date) {
                return true;
            } else {
                return false;
            }
        }
    };

    getViewButton = (n, classes) => {
        const is_editable = ["Draft In Progress", "Draft Feedback Received", "Revisions Needed", ...editWorkPlanStatuses].includes(n.status);

        return (
            <Tooltip title={(is_editable ? "Edit" : "View") + ` ${n.status.includes("Work Plan") ? "Work Plan" : "Proposal"} ${n.proposal_unique_id}`}>
                <Button
                    color="primary"
                    className={classes.deleteWidth}
                    aria-label={(is_editable ? "Edit" : "View") + ` ${n.status.includes("Work Plan") ? "Work Plan" : "Proposal"} ${n.proposal_unique_id}`}
                    onClick={() => window.open(`#/${n.status.includes("Work Plan") ? (n.status.includes("Final") ? "final/workplan" : "draft/workplan") : "proposal"}/${n.id}`)}>
                    {is_editable ? <CreateIcon color="primary" /> : <Visibility color="primary" />}
                </Button>
            </Tooltip>
        );
    };

    wpGetViewButton = (n, classes) => {
        var today = new Date();
        var next_date = new Date(n.next_status_update_date);
        next_date.setDate(next_date.getDate() - 45);
        if (
            today >= next_date ||
            n.reporting_status === "Update Due" ||
            n.reporting_status === "Final Update Due" ||
            n.reporting_status === "Additional Update In Progress" ||
            n.reporting_status === "Additional Update Revisions Needed" ||
            n.reporting_status === "Update Revisions Needed" ||
            n.reporting_status === "Final Update Revisions Needed" ||
            n.amendment_status === "Amendment In Progress" ||
            n.amendment_status === "Amendment Revisions Needed" ||
            n.status === "Final Abstract Due" ||
            n.status === "Final Abstract Revisions Needed"
        ) {
            return (
                <Tooltip title={`Edit Active Project ${n.proposal_unique_id}`}>
                    <Button
                        color="primary"
                        className={classes.deleteWidth}
                        aria-label={`Edit Active Project ${n.proposal_unique_id}`}
                        onClick={() => window.open(`#/final/workplan/${n.id}`)}>
                        <CreateIcon color="primary" />
                    </Button>
                </Tooltip>
            );
        }

        return (
            <Tooltip title={`View Active Project ${n.proposal_unique_id}`}>
                <Button
                    color="primary"
                    className={classes.deleteWidth}
                    aria-label={`View Active Project ${n.proposal_unique_id}`}
                    onClick={() => window.open(`#/final/workplan/${n.id}`)}>
                    <Visibility color="primary" />
                </Button>
            </Tooltip>
        );
    };

    displayFunding = n => {
        return n.funding_total !== null && typeof n.funding_total !== "undefined" ? "$" + n.funding_total.toLocaleString() : "-";
    };

    wpDisplayFunding = n => {
        return n.appropriation_amount !== null && typeof n.appropriation_amount !== "undefined" ? "$" + n.appropriation_amount.toLocaleString() : "-";
    };
    wpDisplaySpent = n => {
        return n.amount_spent !== null && typeof n.amount_spent !== "undefined" ? "$" + n.amount_spent.toLocaleString() : "-";
    };  
    
    displayCombinedStatus = n => {
        var return_string = ""
        if (n.status === "Final Work Plan Approved") {
            if (n.reporting_status !== "-") { // Has Status Update
                return_string += n.reporting_status
            }
            if (n.amendment_status) { // Has Active Amendment
                if (return_string !== "")
                    return_string += " & " + n.amendment_status
                else
                    return_string += n.amendment_status
            }
            if (return_string === "") // No status update or amendment - return status (Final Work Plan Approved)
                return_string += n.status
        } else {
            return_string += n.status
            if (n.reporting_status !== "-" && n.reporting_status !== "Update Approved" && n.reporting_status !== "Final Update Approved") { // Has Status Update
                if (return_string !== "")
                    return_string += " & " + n.reporting_status
                else
                    return_string += n.reporting_status
            }
            if (n.amendment_status && n.amendment_status !== "Amendment Approved") { // Has Active Amendment
                if (return_string !== "")
                    return_string += " & " + n.amendment_status
                else
                    return_string += n.amendment_status
            }
        }
        return return_string;
    };

    render() {
        const { authState, classes, years } = this.props;
        const { proposals, plans } = this.props;
        const {
            newDialogOpen,
            page,
            rowsPerPage,
            proposalFilter,
            proposalFilterYear,
            orderBy,
            order,
            wpPage,
            wpRowsPerPage,
            wpFilter,
            wpFilterYear,
            wpOrderBy,
            wpOrder,
            cPage,
            cRowsPerPage,
            cFilter,
            cOrderBy,
            cOrder,
        } = this.state;

        const proposals_open = years && this.determineIfOpen();

        // Text Filter
        var filtered_proposals = proposals.filter(p => p.status !== "Final Work Plan Approved" && p.status !== "Final Abstract Due" && p.status !== "Final Abstract Submitted" && p.status !== "Final Abstract Revisions Needed" && p.status !== "Project Completed");
        if (proposalFilter) {
            filtered_proposals = this.filterByValue(filtered_proposals, proposalFilter);
        }
        var filtered_plans = plans.filter(p => p.status === "Final Work Plan Approved" || p.status === "Final Abstract Due" || p.status === "Final Abstract Submitted" || p.status === "Final Abstract Revisions Needed");
        if (wpFilter) {
            filtered_plans = this.filterByValue(filtered_plans, wpFilter);
        }
        var completed_projects = plans.filter(p => p.status === "Project Completed");
        if (cFilter) {
            completed_projects = this.filterByValue(completed_projects, cFilter);
        }

        // Year Filter
        if (proposalFilterYear && proposalFilterYear !== "All") {
            filtered_proposals = filtered_proposals.filter(p => {
                if (proposalFilterYear === "CurrentYear") return parseInt(p.ml_year) === new Date().getFullYear() + 1;
                else return parseInt(p.ml_year) <= new Date().getFullYear();
            });
        }
        if (wpFilterYear && wpFilterYear !== "All") {
            var today = new Date();
            filtered_plans = filtered_plans.filter(p => {
                var next_date = new Date(p.next_status_update_date);
                next_date.setDate(next_date.getDate() - 45);
                return (
                    today >= next_date ||
                    p.reporting_status === "Update Due" ||
                    p.reporting_status === "Final Update Due" ||
                    p.reporting_status === "Additional Update In Progress" ||
                    p.reporting_status === "Additional Update Revisions Needed" ||
                    p.reporting_status === "Update Revisions Needed" ||
                    p.reporting_status === "Final Update Revisions Needed" ||
                    p.amendment_status === "Amendment In Progress" ||
                    p.amendment_status === "Amendment Revisions Needed"
                );
            });
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Dashboard" />
                    <Messages />
                    {proposals_open && (
                        <Grid item xs={12} style={{ marginTop: 8 }}>
                            <Button variant="contained" color="primary" onClick={() => this.setState({ newDialogOpen: true })}>
                                <AddCircleIcon />
                                &nbsp;&nbsp;&nbsp;Create New Proposal
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> My Proposals
                                    <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={{ filters: "All" }}>
                                        {formApi => (
                                            <form style={{ float: "right", marginTop: -24 }} onSubmit={formApi.submitForm}>
                                                <RadioGroup
                                                    field="filters"
                                                    style={{ marginBottom: 0 }}
                                                    name="filters"
                                                    eventHandle={val => this.setState({ proposalFilterYear: val })}
                                                    fullWidth
                                                    options={[
                                                        { label: "All", value: "All" },
                                                        { label: "Current Year", value: "CurrentYear" },
                                                        { label: "Past", value: "Past" }
                                                    ]}
                                                    alignment={true}
                                                    label="Search by Year"
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right", marginTop: -16 }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={val => this.setState({ proposalFilter: val })}
                                                        field="filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                columnData={columnDataProposal}
                                                stickyHeader
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                            />
                                            <TableBody>
                                                {filtered_proposals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                                    return (
                                                        <TableRow key={n.id}>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                {this.getViewButton(n, classes)}
                                                                {authState.user && n.user_id !== authState.user.id && (
                                                                    <Tooltip title={"Delegated by: " + n.delegated_by}>
                                                                        <AssignmentInd color="primary" />
                                                                    </Tooltip>
                                                                )}
                                                            </CustomTableCell>
                                                            <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                            <CustomTableCell>{n.category}</CustomTableCell>
                                                            <CustomTableCell>{n.organization_name}</CustomTableCell>
                                                            <CustomTableCell>{n.full_name}</CustomTableCell>
                                                            <CustomTableCell>{this.displayFunding(n)}</CustomTableCell>
                                                            <CustomTableCell>{n.status}</CustomTableCell>
                                                            <CustomTableCell>{n.status_date_label}</CustomTableCell>
                                                            <CustomTableCell>{n.table_due_date}</CustomTableCell>
                                                            <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                                                            <CustomTableCell>{n.peer_review ? n.peer_status : ""}</CustomTableCell>
                                                            <CustomTableCell>{n.secondary_status}</CustomTableCell>
                                                            <CustomTableCell>
                                                                {n.warningMessage ? (
                                                                    <Tooltip placement="left" title={n.warningMessage}>
                                                                        <IconButton style={{ color: "#a80a05" }}>
                                                                            <AnnouncementIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {filtered_proposals.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={14} className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Proposals Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                            {filtered_proposals.length > 10 && (
                                <ExpansionPanelDetails>
                                    <Table>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    colSpan={14}
                                                    count={filtered_proposals.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActionsWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </ExpansionPanelDetails>
                            )}
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> My Active Projects
                                    <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={{ filters: "All" }}>
                                        {formApi => (
                                            <form style={{ float: "right", marginTop: -24 }} onSubmit={formApi.submitForm}>
                                                <RadioGroup
                                                    field="filters"
                                                    name="filters"
                                                    style={{ marginBottom: 0 }}
                                                    eventHandle={val => this.setState({ wpFilterYear: val })}
                                                    fullWidth
                                                    options={[
                                                        { label: "All", value: "All" },
                                                        { label: "Action Pending", value: "ActionPending" }
                                                    ]}
                                                    alignment={true}
                                                    label="Filters"
                                                />
                                            </form>
                                        )}
                                    </Form>
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right", marginTop: -16 }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={val => this.setState({ wpFilter: val })}
                                                        field="filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                columnData={columnDataWorkPlan}
                                                stickyHeader
                                                order={wpOrder}
                                                orderBy={wpOrderBy}
                                                onRequestSort={this.wpHandleRequestSort}
                                            />
                                            <TableBody>
                                                {filtered_plans.slice(wpPage * wpRowsPerPage, wpPage * wpRowsPerPage + wpRowsPerPage).map(n => {
                                                    return (
                                                        <TableRow key={n.id}>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                {this.wpGetViewButton(n, classes)}
                                                                {authState.user && n.user_id !== authState.user.id && (
                                                                    <Tooltip title={"Delegated by: " + n.delegated_by}>
                                                                        <AssignmentInd color="primary" />
                                                                    </Tooltip>
                                                                )}
                                                            </CustomTableCell>
                                                            <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                                            <CustomTableCell>{n.citation_ml_year + " " + n.subdivision}</CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                            <CustomTableCell>{n.category}</CustomTableCell>
                                                            <CustomTableCell>{n.organization_name}</CustomTableCell>
                                                            <CustomTableCell>
                                                                {n.pm_first_name} {n.pm_last_name}
                                                            </CustomTableCell>
                                                            <CustomTableCell>{this.wpDisplayFunding(n)}</CustomTableCell>
                                                            <CustomTableCell>{this.displayCombinedStatus(n)}</CustomTableCell>
                                                            <CustomTableCell>
                                                                {n.last_action !== "-" ? n.last_action : n.status_date_label.split(" ")[0]}
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.next_status_update_date}</CustomTableCell>
                                                            <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                                                            <CustomTableCell>{n.includes_amendment}</CustomTableCell>
                                                            <CustomTableCell>{n.secondary_status}</CustomTableCell>
                                                            <CustomTableCell></CustomTableCell>
                                                            <CustomTableCell>
                                                                {n.statusWarningMessage ? (
                                                                    <Tooltip placement="left" title={n.statusWarningMessage}>
                                                                        <IconButton style={{ color: "#a80a05" }}>
                                                                            <AnnouncementIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {filtered_plans.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={14} className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Projects Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                            {filtered_plans.length > 10 && (
                                <ExpansionPanelDetails>
                                    <Table>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    colSpan={columnDataWorkPlan.length}
                                                    count={filtered_plans.length}
                                                    rowsPerPage={wpRowsPerPage}
                                                    page={wpPage}
                                                    onChangePage={this.wpHandleChangePage}
                                                    onChangeRowsPerPage={this.wpHandleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActionsWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </ExpansionPanelDetails>
                            )}
                        </ExpansionPanel>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={true}>
                            <ExpansionPanelSummary className={classes.expansionSummaryRoot2} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h3" style={{ width: "100%" }}>
                                    <BookmarksIcon className={classes.expansionIcon} /> My Completed Projects
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                            {formApi => (
                                                <form style={{ float: "right", marginTop: -16 }} onSubmit={formApi.submitForm}>
                                                    <TextField
                                                        eventHandle={val => this.setState({ cFilter: val })}
                                                        field="filter"
                                                        label="Search"
                                                        filterInput
                                                    />
                                                </form>
                                            )}
                                        </Form>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                columnData={columnDataCompletedProject}
                                                stickyHeader
                                                order={cOrder}
                                                orderBy={cOrderBy}
                                                onRequestSort={this.cHandleRequestSort}
                                            />
                                            <TableBody>
                                                {completed_projects.slice(cPage * cRowsPerPage, cPage * cRowsPerPage + cRowsPerPage).map(n => {
                                                    return (
                                                        <TableRow key={n.id}>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Tooltip title={`View Completed Project ${n.proposal_unique_id}`}>
                                                                    <Button
                                                                        color="primary"
                                                                        className={classes.deleteWidth}
                                                                        aria-label={`View Completed Project ${n.proposal_unique_id}`}
                                                                        onClick={() => window.open(`#/final/workplan/${n.id}`)}>
                                                                        <Visibility color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                                {authState.user && n.user_id !== authState.user.id && (
                                                                    <Tooltip title={"Delegated by: " + n.delegated_by}>
                                                                        <AssignmentInd color="primary" />
                                                                    </Tooltip>
                                                                )}
                                                            </CustomTableCell>
                                                            <CustomTableCell component="th">{n.proposal_unique_id}</CustomTableCell>
                                                            <CustomTableCell>{n.citation_ml_year + " " + n.subdivision}</CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                            <CustomTableCell>{n.category}</CustomTableCell>
                                                            <CustomTableCell>{n.organization_name}</CustomTableCell>
                                                            <CustomTableCell>
                                                                {n.pm_first_name} {n.pm_last_name}
                                                            </CustomTableCell>
                                                            <CustomTableCell>{this.wpDisplayFunding(n)}</CustomTableCell>
                                                            <CustomTableCell>{this.wpDisplaySpent(n)}</CustomTableCell>
                                                            <CustomTableCell>
                                                                {n.last_action !== "-" ? n.last_action : n.status_date_label.split(" ")[0]}
                                                            </CustomTableCell>
                                                            <CustomTableCell></CustomTableCell>
                                                            <CustomTableCell></CustomTableCell>
                                                            <CustomTableCell>{n.staff_lead_label}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {completed_projects.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataCompletedProject.length} className={classes.centerAlign}>
                                                            <caption style={{display:"inline"}}>No Projects Found</caption>
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                            {completed_projects.length > 10 && (
                                <ExpansionPanelDetails>
                                    <Table>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    colSpan={columnDataCompletedProject.length}
                                                    count={completed_projects.length}
                                                    rowsPerPage={cRowsPerPage}
                                                    page={cPage}
                                                    onChangePage={this.cHandleChangePage}
                                                    onChangeRowsPerPage={this.cHandleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActionsWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </ExpansionPanelDetails>
                            )}
                        </ExpansionPanel>
                    </Grid>
                </Grid>
                <NewProposal returnDialog={() => this.setState({ newDialogOpen: false })} open={newDialogOpen} />
            </AppContainer>
        );
    }
}

Dashboard = connect(
    (state, ownProps) => ({
        authState: state.auth,
        proposals: getProposals(state, ownProps),
        plans: getPlans(state, ownProps),
        years: getSystemSettings(state),
        reviews: getReviews(state, ownProps)
    }),
    {
        reloadListOfModels: reloadListOfModels
    }
)(Dashboard);

export default withStyles(styles)(withRouter(Dashboard));
