import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import TextField from "../common/TextField";
import TablePaginationActionsWrapped from "../common/Paginator";
import RadioGroup from "../common/RadioGroup";
import WarningDialog from "../common/WarningDialog";
import { createSelector } from "../common/orm";
import { User } from "../home/models";

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    space: {
        marginTop: 10,
        marginBottom: 10
    }
});

const getActiveuser = createSelector(schema => {
    return schema.User.filter(u => u.role === "Member")
        .orderBy(["last_name"], ["asc"])
        .toModelArray()
        .map(member => {
            return {
                terms: member.term_member
                    .all()
                    .orderBy("end_date", ["desc"])
                    .toModelArray()
                    .map(x => x.str_term)
                    .join(", "),
                active: member.term_member.filter(term => term.within_rfp_year === true).toRefArray()[0],
                ...member.ref
            };
        });
});

const getUsers = createSelector(schema => {
    return schema.User.filter(u => u.role === "Member")
        .orderBy(["last_name"], ["asc"])
        .toModelArray()
        .map(member => {
            return {
                terms: member.term_member
                    .all()
                    .orderBy("end_date", ["desc"])
                    .toModelArray()
                    .map(x => x.str_term)
                    .join(", "),
                active: member.term_member.filter(term => term.within_rfp_year === true).toRefArray()[0],
                ...member.ref
            };
        });
});

const columnActiveMembers = [
    { id: "member_name", numeric: false, label: "Member Name", allowSort: true },
    { id: "appointing_authority", numeric: false, label: "Appointing Authority", allowSort: true },
    { id: "str_term", numeric: false, label: "Current Term", allowSort: true }
];

const columnDataUsers = [
    { id: "actions", numeric: false, label: "Actions", width: "20px" },
    { id: "name", numeric: false, label: "Prefix First Name Last Name", allowSort: true },
    { id: "terms", numeric: false, label: "Terms", allowSort: true }
];

class Users extends Component {
    state = {
        page: 0,
        rowsPerPage: 25,
        activeOrder: "desc",
        activeOrderBy: "",
        order: "desc",
        orderBy: "",
        memberListFilter: null,
        warningDialog: false,
        warngingDialogID: null
    };

    componentDidMount() {
        document.title = "Manage Members - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        if (orderBy === "name") {
            this.props.users.sort(function(a, b) {
                if (order === "desc")
                    return (b["last_name"] || "").toUpperCase() < (a["last_name"] || "").toUpperCase() ? -1 : 1;
                else return (a["last_name"] || "").toUpperCase() < (b["last_name"] || "").toUpperCase() ? -1 : 1;
            });
        } else {
            this.props.users.sort(function(a, b) {
                if (order === "desc")
                    return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            });
        }

        this.setState({ order, orderBy });
    };

    handleActiveSort = (evet, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.activeOrderBy === property && this.state.activeOrder === "desc") {
            order = "asc";
        }

        if (orderBy === "member_name") {
            this.props.activeUsers.sort(function(a, b) {
                if (order === "desc")
                    return (b["last_name"] || "").toUpperCase() < (a["last_name"] || "").toUpperCase() ? -1 : 1;
                else return (a["last_name"] || "").toUpperCase() < (b["last_name"] || "").toUpperCase() ? -1 : 1;
            });
        } else {
            this.props.activeUsers.sort(function(a, b) {
                if (order === "desc")
                    return (b.active ? b.active[orderBy] : "").toUpperCase() <
                        (a.active ? a.active[orderBy] : "").toUpperCase()
                        ? -1
                        : 1;
                else
                    return (a.active ? a.active[orderBy] : "").toUpperCase() <
                        (b.active ? b.active[orderBy] : "").toUpperCase()
                        ? -1
                        : 1;
            });
        }
        this.setState({ activeOrder: order, activeOrderBy: orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    filterByValue = (array, string) => {
        var keys = ["terms", "last_name", "first_name", "gov_prefix"];
        return array.filter(o =>
            keys.some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    render() {
        const { activeUsers, classes, ormUserDelete, users } = this.props;
        const {
            activeOrder,
            activeOrderBy,
            page,
            rowsPerPage,
            order,
            orderBy,
            memberListFilter,
            memberListType,
            warningDialog,
            warngingDialogID
        } = this.state;

        //const active = activeUsers.filter(x => x.active);
        const active = activeUsers.filter(x => x.is_active);

        var filtered_users;
        if (memberListFilter) {
            filtered_users = this.filterByValue(users, memberListFilter);
        } else {
            filtered_users = users;
        }

        if (memberListType && memberListType !== "All") {
            //filtered_users = filtered_users.filter(x => x.active);
            filtered_users = filtered_users.filter(x => x.is_active);
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Manage Members" admin_page />
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.space}
                            component={Link}
                            to="/adm/members/new">
                            <AddCircleIcon />
                            &nbsp;&nbsp;&nbsp;Create New Member
                        </Button>
                        <Typography className={classes.space} variant="h3">
                            Current Member List
                        </Typography>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                columnData={columnActiveMembers}
                                stickyHeader
                                order={activeOrder}
                                orderBy={activeOrderBy}
                                onRequestSort={this.handleActiveSort}
                            />
                            <TableBody>
                                {active.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                    return (
                                        <TableRow key={n.id}>
                                            <CustomTableCell>
                                                {n.gov_prefix ? n.gov_prefix : ""} {n.first_name} {n.last_name}
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {n.active ? n.active.appointing_authority : ""}
                                            </CustomTableCell>
                                            <CustomTableCell>{n.active ? n.active.str_term : ""}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {active.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                            <caption style={{display:"inline"}}>No Active Members Found</caption>
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            {active.length > 25 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={7}
                                            count={filtered_users.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                        <div style={{ display: "inline-flex", marginRight: "5%" }}>
                            <Typography variant="h3">List of All Members</Typography>
                        </div>
                        <Form dontValidateOnMount={true} validateOnSubmit={true} defaultValues={{ filters: "All" }}>
                            {formApi => (
                                <form style={{ display: "inline-flex" }} onSubmit={formApi.submitForm}>
                                    <RadioGroup
                                        field="filters"
                                        name="filters"
                                        eventHandle={val => this.setState({ memberListType: val })}
                                        fullWidth
                                        options={[
                                            { label: "All", value: "All" },
                                            { label: "Active", value: "Active" }
                                        ]}
                                        alignment={true}
                                    />
                                </form>
                            )}
                        </Form>
                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                            {formApi => (
                                <form style={{ float: "right", marginTop: 10 }} onSubmit={formApi.submitForm}>
                                    <TextField
                                        eventHandle={val => this.setState({ memberListFilter: val })}
                                        field="filter"
                                        label="Search List"
                                        filterInput
                                    />
                                </form>
                            )}
                        </Form>
                        <Table className={classes.table}>
                            <EnhancedTableHead
                                columnData={columnDataUsers}
                                stickyHeader
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                            />
                            <TableBody>
                                {filtered_users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                    return (
                                        <TableRow key={n.id + "term"}>
                                            <CustomTableCell className={classes.nowrap}>
                                                <Tooltip title="Edit Member">
                                                    <Button
                                                        color="primary"
                                                        className={classes.deleteWidth}
                                                        component={Link}
                                                        to={"/adm/members/" + n.id}>
                                                        <CreateIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Delete Member">
                                                    <Button
                                                        onClick={() =>
                                                            this.setState({
                                                                warngingDialogID: n.id,
                                                                warningDialog: true
                                                            })
                                                        }
                                                        color="primary"
                                                        className={classes.deleteWidth}>
                                                        <DeleteIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                            <CustomTableCell>
                                                {n.gov_prefix ? n.gov_prefix : ""} {n.first_name} {n.last_name}
                                            </CustomTableCell>
                                            <CustomTableCell>{n.terms ? n.terms : "---"}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {filtered_users.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={7} className={classes.centerAlign}>
                                            <caption style={{display:"inline"}}>No Members Found</caption>
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            {filtered_users.length > 25 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={7}
                                            count={filtered_users.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </Grid>
                </Grid>
                <WarningDialog
                    confirmText={"Yes"}
                    confirmAction={() => {
                        ormUserDelete(warngingDialogID);
                        this.setState({ warningDialog: false });
                    }}
                    cancelText={"No"}
                    cancelAction={() => this.setState({ warningDialog: false })}
                    open={warningDialog}
                    title="Delete Member"
                    text={"Are you sure you want to delete this Member?"}
                />
            </AppContainer>
        );
    }
}

Users = connect(
    (state, ownProps) => ({
        authState: state.auth,
        users: getUsers(state, ownProps),
        activeUsers: getActiveuser(state, ownProps)
    }),
    {
        ...User.actions
    }
)(Users);

export default withStyles(styles)(withRouter(Users));
