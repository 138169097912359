import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import { MemberHelpText } from "../home/models";
import { createSelector } from "../common/orm";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const getHelpMessages = createSelector(schema => {
    return schema.MemberHelpText.all()
        .orderBy("id", "asc")
        .toRefArray();
});


const styles = () => ({
    fields: {
        padding: "15px",
        border: "1px solid #eee",
        marginBottom: 30
    },
    error: {
        color: "red"
    }
});

class MemberHelpMessages extends Component {
    toolbarConfig = {
        options: ["inline", "link", "list"],
        inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"]
        },
        link: {
            inDropdown: false,
            showOpenOptionOnHover: true,
            defaultTargetOption: "_self",
            options: ["link", "unlink"]
        },
        list: {
            inDropdown: false,
            options: ["ordered", "unordered"]
        }
    };

    constructor(props) {
        super(props);
        this.state = { formData: {}, errors: {}, updated: false };
    }

    componentDidMount() {
        document.title = "Configure Help Messages for Member Screens - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
        this.setFormData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.messages !== this.props.messages) {
            const { updated } = this.state;
            if (updated) {
                return;
            }
            this.setFormData();
        }
    }

    setFormData = async () => {
        const { messages } = this.props;
        const { errors } = this.state;

        await Object.keys(errors).forEach(field => (errors[field].required = false));
        await this.setState(prev => ({ ...prev, errors }));

        let fieldIdx = 0;
        let formData = {};

        await messages.forEach(obj => {
            formData[`${obj.label}-${fieldIdx}`] = this.getHTMLToDraft(obj.text);
            errors[`${obj.label}-${fieldIdx}`] = { required: false };
            fieldIdx++;
        });

        await this.setState(prev => ({ ...prev, formData, errors }));
    };

    changeEditorState = (contentState, field) => {
        let { formData, errors } = this.state;
        formData[field] = contentState;
        errors[field].required = !(draftToHtml(convertToRaw(formData[field].getCurrentContent())).length > 8);
        this.setState(prev => ({ ...prev, formData, errors }));
    };

    getHTMLToDraft = html => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
    };

    ifErrors = () => {
        const { errors } = this.state;
        return Object.keys(errors).filter(field => errors[field].required).length > 0;
    };

    submitForm = (values) => {
        const { ormMemberHelpTextUpdate, messages } = this.props;
        const { formData } = this.state;
        let updatedData = [];
        let updatedForm = {};

        Object.keys(formData).forEach(async (field, idx) => {
            let temp = {};
            let fieldID;

            fieldID = messages.find(
                msg =>
                    msg.label === `${field.replace(`-${idx}`, "")}`
            ).id;

            if (!(fieldID in updatedData.map(p => p.id))) {
                temp.id = fieldID;
                temp.text = draftToHtml(convertToRaw(formData[field].getCurrentContent()));
                updatedData.push(temp);
                updatedForm[field] = formData[field];

                this.setState(prev => ({ ...prev, updated: true }));
                await ormMemberHelpTextUpdate(temp);
            }
        });
        this.setState(prev => ({ ...prev, formData: updatedForm }));
    };

    render() {
        const { classes } = this.props;
        const { formData, errors } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Configure Help Messages for Member Screens" />

                    {!!Object.keys(formData).length && (
                        <Grid item xs={12}>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                defaultValues={formData}
                                onSubmit={this.submitForm}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            {Object.keys(formData).map((field, idx) => (
                                                <Grid item xs={12} key={`parent-${field}`} className={classes.fields}>
                                                    <label>
                                                        <h3>{field.replace(`-${idx}`, "")}</h3>
                                                    </label>
                                                    <Editor
                                                        toolbar={this.toolbarConfig}
                                                        editorState={formData[field]}
                                                        onEditorStateChange={editorState =>
                                                            this.changeEditorState(editorState, field)
                                                        }>
                                                        <textarea
                                                            disabled
                                                            value={draftToHtml(
                                                                convertToRaw(formData[field].getCurrentContent())
                                                            )}
                                                        />
                                                    </Editor>
                                                    {errors[field].required && (
                                                        <div className={classes.error}>*This field is required!</div>
                                                    )}
                                                </Grid>
                                            ))}

                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.ifErrors()}>
                                                    Save
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth component={Link} to="/adm/home">
                                                    Return to Admin Home
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </Grid>
                    )}
                </Grid>
            </AppContainer>
        );
    }
}

MemberHelpMessages = connect(
    (state, ownProps) => ({
        authState: state.auth,
        messages: getHelpMessages(state, ownProps),
    }),
    {
        ...MemberHelpText.actions,
    }
)(MemberHelpMessages);

export default withStyles(styles)(withRouter(MemberHelpMessages));
