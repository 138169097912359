import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Form } from "react-form";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { default as MUILink } from "@material-ui/core/Link";

import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Visibility from "@material-ui/icons/Visibility";

import AppContainer from "../common/AppContainer";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import PageHeader from "../common/PageHeader";
import SortSelect from "../common/SortSelect";
import TablePaginationActionsWrapped from "../common/Paginator";
import TextField from "../common/TextField";

import { MemberEvaluation, ProposalReview } from "../home/models";
import { createSelector } from "../common/orm";

const getWorkPlans = createSelector(
    (state, ownProps) => ownProps.match.params["year"],
    (schema, year) => {
        return schema.ProposalView.all()
            .filter(x => x.ml_year === year && (x.status === "Draft Work Plan Accepted" || x.primary_phase === "Final Work Plan"))
            .orderBy("proposal_unique_id", "desc")
            .toModelArray()
            .map(view => {
                var plan;
                if (view.primary_phase === "Draft Work Plan") {
                    plan = schema.DraftWorkPlanView.filter(x => x.proposal_record_id === view.id).first();
                } else {
                    plan = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === view.id).first();
                }

                var category = schema.FundingCategory.filter(x => x.category_name === plan.funding_category && x.year_label === plan.ml_year).first();

                var secondarySuffix = null;
                if (plan.funding_category === "Small Projects") {
                    secondarySuffix = schema.FundingCategory.filter(
                        x => x.category_name === plan.secondary_funding_category && x.year_label === plan.ml_year
                    ).first();
                }

                var final_category = category && category.suffix ? category.suffix + ". " : "";
                final_category += plan.funding_category ? plan.funding_category : "";

                final_category +=
                    plan.funding_category === "Small Projects" && plan.secondary_funding_category
                        ? " / " + (secondarySuffix ? secondarySuffix.suffix + ". " : "") + plan.secondary_funding_category
                        : "";

                var org = schema.Organization.filter(x => x.id === plan.organization_id).first();

                var status = view.status

                var plan_id = plan.id;

                if (!view.status.includes("Final")) {
                    var temp = schema.DraftWorkPlanView.filter(x => x.proposal_record_id === view.id).first();
                    plan_id = temp.id;
                }

                return {
                    id: view.id,
                    plan_id,
                    ml_year: view.ml_year,
                    proposal_unique_id: view.proposal_unique_id,
                    recommended_amount: view.appropriation_amount !== null ? view.appropriation_amount : view.recommended_amount,
                    name: plan.name,
                    pm_first_name: plan.pm_first_name,
                    pm_last_name: plan.pm_last_name,
                    final_category,
                    funding_total: plan.funding_total,
                    orig_status: view.status,
                    peer_review: "",
                    special_session: view.special_session,
                    article: view.article,
                    citation_ml_year: view.citation_ml_year,
                    chapter: view.chapter,
                    section: view.section,
                    subdivision: view.subdivision,
                    status,
                    summary: plan ? plan.summary : view.summary,
                    legal_citation: view.legal_citation,
                    pm_full_name: plan.pm_first_name + " " + plan.pm_last_name,
                    organization_name: org ? org.organization_name + (org.department ? " / " + org.department : "") : "",
                    contingencies: view.contingencies,
                    secondarySuffix: secondarySuffix ? secondarySuffix.suffix : null,
                    secondary_category: plan.secondary_funding_category,
                    suffix: category ? category.suffix : null,
                    category
                };
            });
    }
);

const getSystemSetting = createSelector(
    (state, ownProps) => ownProps.match.params["year"],
    (schema, year) => {
        return schema.SystemSetting.filter(x => x.year === year).first();
    }
);

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        backgroundColor: "#fff"
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8
    },
    centerAlign: {
        textAlign: "center"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    link: {
        "&:hover": {
            cursor: "pointer"
        },
        color: "#006a9d",
        textDecoration: "underline"
    },
    cellWidth_1: {
        minWidth: "125px !important"
    },
    cellWidth_2: {
        minWidth: "200px !important"
    },
    cellWidth_4: {
        minWidth: "400px !important"
    },
    cellWidth_45: {
        minWidth: "450px !important"
    },
    floatLeft: {
        float: "left"
    }
});

const columnData = [
    { id: "workplan_actions", numeric: false, label: "Actions" },
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID" },
    { id: "legal_citation", numeric: false, label: "Legal Citation" },
    { id: "name", numeric: false, label: "Project Title" },
    { id: "summary", numeric: false, label: "Summary" },
    { id: "final_category", numeric: false, label: "Category/Subcategory" },
    { id: "organization", numeric: false, label: "Organization" },
    { id: "pm_full_name", numeric: false, label: "Project Manager" },
    { id: "recommended_amount", numeric: true, label: "Recommended Amount" },
    { id: "status", numeric: true, label: "Work Plan Status" },
    {
        id: "final_member_comments2",
        numeric: false,
        label: "Specfic Work Plan Approval, Notes, and Contingencies"
    }
];

class WorkPlanReview extends Component {
    state = {
        page: 0,
        rowsPerPage: 100,
        order: "asc",
        review: false,
        member: false,
        loaded: false,
        defaultSorted: true,
        sortValue: "-- Empty --"
    };

    componentDidMount() {
        document.title = "Work Plan Review - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;

        if (authState && authState.user && authState.user.role !== "Member") {
            history.push("/dashboard");
        }
    }

    componentDidUpdate() {
        const { authState, history } = this.props;

        if (authState && authState.user && authState.user.role !== "Member") {
            history.push("/dashboard");
        }
    }

    handleRequestSort = sortValue => {
        if (sortValue.target.value === "-- Empty --") {
            this.setState({ sortValue: sortValue.target.value });
            return;
        }

        const orderBy = sortValue.target.value.split("-")[0];
        const order = sortValue.target.value.split("-")[1];

        this.props.plans.sort(function(a, b) {
            var numeric = columnData.find(cD => cD.id === orderBy).numeric;
            var bool = columnData.find(cD => cD.id === orderBy).bool;
            if (bool) {
                if (order === "desc") return (b[orderBy] || false) - (a[orderBy] || false);
                else return (a[orderBy] || false) - (b[orderBy] || false);
            } else if (numeric) {
                if (order === "desc") return parseFloat(b[orderBy] || 0) < parseFloat(a[orderBy] || 0) ? -1 : 1;
                else return parseFloat(a[orderBy] || 0) < parseFloat(b[orderBy] || 0) ? -1 : 1;
            } else {
                if (order === "desc") return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
            }
        });

        this.setState({ sortValue: sortValue.target.value });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    filterByValue = (array, string) => {
        var keys = columnData.map(x => x.id);
        return array.filter(o =>
            keys.some(
                k =>
                    o[k] &&
                    o[k]
                        .toString()
                        .toLowerCase()
                        .includes(string.toLowerCase())
            )
        );
    };

    formatLegal = n => {
        var arr = [];
        [
            { key: "citation_ml_year", label: "M.L. " },
            { key: "special_session", label: " Special Session" },
            { key: "chapter", label: "Chp. " },
            { key: "article", label: "Art. " },
            { key: "section", label: "Sec. " },
            { key: "subdivision", label: "Subd. " },
            { key: "additional_citation", label: " " }
        ].forEach(x => {
            if (n[x.key]) {
                if (x.key === "special_session") {
                    arr.push(n[x.key]);
                } else {
                    arr.push(x.label + n[x.key]);
                }
            }
        });
        return arr.join(", ");
    };

    sortSubCategory = (list, radio) => {
        var grouped = {};

        for (var i = 0; i < list.length; i += 1) {
            if (list[i].secondarySuffix && !grouped[list[i].secondarySuffix]) {
                grouped[list[i].secondarySuffix] = [];
            } else if (!list[i].secondarySuffix && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[list[i].secondarySuffix ? list[i].secondarySuffix : ""].push(list[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                if (radio) {
                    if (a[radio] === b[radio]) return 0;
                    else if (a[radio] === null || typeof a[radio] === "undefined") return 1;
                    else if (b[radio] === null || typeof b[radio] === "undefined") return -1;
                    return parseInt(b[radio]) - parseInt(a[radio]);
                } else {
                    return (a.proposal_unique_id || "").toUpperCase() < (b.proposal_unique_id || "").toUpperCase() ? -1 : 1;
                }
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        return arr;
    };

    defaultSort = radio => {
        const { plans } = this.props;

        var grouped = {};

        for (var i = 0; i < plans.length; i += 1) {
            if (plans[i].category && !grouped[plans[i].category.suffix]) {
                grouped[plans[i].category.suffix] = [];
            } else if (!plans[i].category && !grouped[""]) {
                grouped[""] = [];
            }
            grouped[plans[i].category ? plans[i].category.suffix : ""].push(plans[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(function(a, b) {
                if (radio) {
                    if (a[radio] === b[radio]) return 0;
                    else if (a[radio] === null || typeof a[radio] === "undefined") return 1;
                    else if (b[radio] === null || typeof b[radio] === "undefined") return -1;
                    return parseInt(b[radio]) - parseInt(a[radio]);
                } else {
                    return (a.proposal_unique_id || "").toUpperCase() < (b.proposal_unique_id || "").toUpperCase() ? -1 : 1;
                }
            });
        }
        var keys = Object.keys(grouped).sort(function(a, b) {
            if (a === "") return 1;
            if (b === "") return -1;

            return (b || "").toUpperCase() > (a || "").toUpperCase() ? -1 : 1;
        });

        // This sorts H by secondary category and then ID.
        if (grouped["H"] && grouped["H"].length > 1) {
            grouped["H"] = this.sortSubCategory(grouped["H"], radio);
        }

        var arr = [];

        keys.forEach(x => {
            arr = arr.concat(grouped[x]);
        });

        this.props.plans.sort(function(a, b) {
            return arr.findIndex(x => x.id === a.id) - arr.findIndex(z => z.id === b.id);
        });

        this.setState({ defaultSorted: false });
    };

    render() {
        const { classes, plans, sync } = this.props;
        const { defaultSorted, filter, rowsPerPage, page, sortValue } = this.state;

        if (sync && sync.ready && defaultSorted) {
            this.defaultSort(null);
        }

        var filtered_plans;
        if (filter) {
            filtered_plans = this.filterByValue(plans, filter);
        } else {
            filtered_plans = plans;
        }

        var sortOptions = [];
        columnData.slice(1, columnData.length).forEach(x => {
            sortOptions.push({
                label: x.label + " - ascending",
                value: x.id + "-asc"
            });
            sortOptions.push({
                label: x.label + " - descending",
                value: x.id + "-desc"
            });
        });

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Work Plan Review" />
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                {formApi => (
                                    <form style={{ display: "inline-flex" }} onSubmit={formApi.submitForm}>
                                        <SortSelect
                                            label="Sort:"
                                            labelId="workplan-sort-label"
                                            id="workplan-sort"
                                            fullWidth
                                            includeEmpty
                                            value={sortValue}
                                            handleChange={this.handleRequestSort}
                                            sortOptions={sortOptions}
                                        />
                                    </form>
                                )}
                            </Form>
                        </Grid>
                        <Grid item xs={2}>
                            <Form dontValidateOnMount={true} validateOnSubmit={true}>
                                {formApi => (
                                    <form style={{ float: "right" }} onSubmit={formApi.submitForm}>
                                        <TextField eventHandle={val => this.setState({ filter: val })} field="filter" label="Search List" filterInput />
                                    </form>
                                )}
                            </Form>
                        </Grid>
                        <Grid item xs={12} style={{overflowX:"auto", height: "calc(100vh - 330px)"}}>
                            <Table className={classes.table}>
                                <EnhancedTableHead stickyHeader columnData={columnData} />
                                <TableBody>
                                    {filtered_plans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                                        return (
                                            <TableRow key={n.id}>
                                                <CustomTableCell className={classes.nowrap}>
                                                    <Tooltip title={`View Work Plan ${n.proposal_unique_id}`}>
                                                        <Button
                                                            color="primary"
                                                            className={classes.deleteWidth}
                                                            aria-label={`View Work Plan ${n.proposal_unique_id}`}
                                                            onClick={() =>
                                                                window.open(
                                                                    "#" +
                                                                        (n.status.includes("Final")
                                                                            ? "/final"
                                                                            : "/draft") +
                                                                        "/workplan/" +
                                                                        n.id
                                                                )
                                                            }>
                                                            <Visibility color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                </CustomTableCell>
                                                <CustomTableCell className={classes.cellWidth_1} component="th">
                                                    <Typography>
                                                        <MUILink
                                                            href={`/reports/workplan/${n.plan_id}?draft=${
                                                                n.status.includes("Final")
                                                                    ? "n"
                                                                    : "y"
                                                            }`}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            {n.proposal_unique_id}
                                                        </MUILink>
                                                    </Typography>
                                                </CustomTableCell>
                                                <CustomTableCell className={classes.cellWidth_2}>{this.formatLegal(n)}</CustomTableCell>
                                                <CustomTableCell className={classes.cellWidth_2}>{n.name}</CustomTableCell>
                                                <CustomTableCell className={classes.cellWidth_45}>{n.summary}</CustomTableCell>
                                                <CustomTableCell className={classes.cellWidth_2}>{n.final_category}</CustomTableCell>
                                                <CustomTableCell className={classes.cellWidth_2}>{n.organization_name}</CustomTableCell>
                                                <CustomTableCell>{n.pm_full_name}</CustomTableCell>
                                                <CustomTableCell>${n.recommended_amount ? n.recommended_amount.toLocaleString() : 0}</CustomTableCell>
                                                <CustomTableCell className={classes.cellWidth_2}>{n.status}</CustomTableCell>
                                                <CustomTableCell className={classes.cellWidth_4}>{n.contingencies ? n.contingencies : "-"}</CustomTableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {filtered_plans.length < 1 && (
                                        <TableRow>
                                            <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                                <caption style={{display:"inline"}}>No Work Plans Found</caption>
                                            </CustomTableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                                {filtered_plans.length > 25 && (
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                colSpan={columnData.length}
                                                count={filtered_plans.length}
                                                rowsPerPage={rowsPerPage}
                                                classes={{toolbar: classes.floatLeft}}
                                                page={page}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActionsWrapped}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                )}
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

WorkPlanReview = connect(
    (state, ownProps) => ({
        sync: state.sync,
        authState: state.auth,
        plans: getWorkPlans(state, ownProps),
        setting: getSystemSetting(state, ownProps)
    }),
    {
        ...MemberEvaluation.actions,
        ...ProposalReview.actions
    }
)(WorkPlanReview);

export default withStyles(styles)(withRouter(WorkPlanReview));
