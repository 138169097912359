import {
    SW_CHECKING,
    SW_REGISTERED,
    SW_INSTALLED,
    SW_UPDATE,
    SW_ERROR,
    SW_DISABLED,
    STORE_READY,
    PROCESS_SET,
    PROCESS_CLEAR,
    NAV_TOGGLE_PROPOSAL,
    NAV_TOGGLE_WORKPLAN,
    NAV_TOGGLE_ACTIVEPROJECT,
    NAV_TOGGLE_COMPLETEDPROJECT,
    NAV_TOGGLE_PEER,
    NAV_TOGGLE_MEMBERTASK
} from "./actions";

function navReducer(state = {}, action) {
    switch (action.type) {
        case PROCESS_SET:
            return {
                ...state,
                sortInfo: action.sortInfo
            };
        case PROCESS_CLEAR:
            return {
                ...state,
                sortInfo: null
            };
        case SW_CHECKING:
            return {
                ...state,
                sw: { checking: true }
            };

        case SW_REGISTERED:
            return {
                ...state,
                sw: { registered: true }
            };

        case SW_INSTALLED:
            return {
                ...state,
                sw: { installed: true }
            };

        case SW_UPDATE:
            return {
                ...state,
                sw: { update: true }
            };

        case SW_ERROR:
            return {
                ...state,
                sw: { error: true }
            };

        case SW_DISABLED:
            return {
                ...state,
                sw: null
            };

        case STORE_READY:
            return {
                ...state,
                storeReady: true
            };

        case NAV_TOGGLE_PROPOSAL:
            return {
                ...state,
                proposalExpand: !state.proposalExpand
            };

        case NAV_TOGGLE_WORKPLAN:
            return {
                ...state,
                workPlanExpand: !state.workPlanExpand
            };

        case NAV_TOGGLE_ACTIVEPROJECT:
            return {
                ...state,
                activeProjectExpand: !state.activeProjectExpand
            };

        case NAV_TOGGLE_COMPLETEDPROJECT:
            return {
                ...state,
                completedProjectExpand: !state.completedProjectExpand
            };

        case NAV_TOGGLE_PEER:
            return {
                ...state,
                peerExpand: !state.peerExpand
            };

        case NAV_TOGGLE_MEMBERTASK:
            return {
                ...state,
                memberTaskExpanded: !state.memberTaskExpanded
            };

        default:
            return state;
    }
}

export default navReducer;
