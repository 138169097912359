import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";

import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    root: {
        display: "flex"
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit
    },
    group: {
        margin: "0"
    },
    fullWidth: {
        width: "100%"
    },
    horizontal: {
        margin: "0",
        display: "inline"
    },
    groupName: {
        color: "#212529"
    },
    error: {
        "& h3": {
            // No way to access actual text - probably because typography tag is being passed as label
            color: "#be0000"
        },
        "& svg": {
            // Keep color on help icon
            color: "rgba(0, 0, 0, 0.87)"
        }
    }
});

class RadioStandAlone extends Component {
    handleChange = (event, value) => {
        const { fieldApi, eventHandle, name } = this.props;
        const { setValue } = fieldApi;

        setValue(event.target.value);

        if (eventHandle) eventHandle(event.target.value, name);
    };

    render() {
        const {
            fieldApi,
            label,
            children,
            options,
            classes,
            required,
            alignment,
            fullWidth,
            style,
            optionvalue,
            ...rest
        } = this.props;

        const { getValue, getError } = fieldApi;

        var value = getValue(),
            error = getError();

        // FIXME: Material UI requires string values
        // Radio values are returned from API as boolean
        if (value === true) value = "true";
        if (value === false) value = "false";

        return (
            <FormControl
                component="fieldset"
                style={style}
                required={required}
                error={error && true}
                className={classNames(classes.formControl, fullWidth && classes.fullWidth)}>
                <FormLabel component="legend" className={classes.groupName} classes={{ error: classes.error }}>
                    {label || children}
                </FormLabel>
                <Radio
                    disabled={rest.disabled}
                    color="primary"
                    checked={value === optionvalue}
                    onChange={this.handleChange}
                    value={optionvalue}
                />
                {error && <FormHelperText style={{ marginTop: 0 }}>{error}</FormHelperText>}
            </FormControl>
        );
    }
}

const FppRadio = FormField(RadioStandAlone);

export default withStyles(styles)(FppRadio);
