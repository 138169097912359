import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import TextField from "../common/TextField";

import { connect } from "react-redux";
import * as authActions from "./actions";

const styles = {
    passwordError: {
        color: "#be0000",
        textAlign: "center"
    },
    space: {
        marginTop: 20
    }
};

class PasswordSetPage extends Component {
    errorValidator = values => {
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        return {
            password: validatePassword(values.password, values.password2),
            password2: validatePassword(values.password, values.password2)
        };
    };

    passwordSubmit = values => {
        this.props.authPasswordRecover({
            uid: this.props.match.params["uid"],
            token: this.props.match.params["token"],
            new_password: values.password
        });
    };

    render() {
        const { classes, passwordRecover } = this.props;

        return (
            <AppContainer>
                <Grid container justify="center">
                    <Grid item xs={11} sm={10} md={6}>
                        <Card>
                            <CardContent>
                                <Typography variant="h2" gutterBottom>
                                    Set Password
                                </Typography>
                                <Form
                                    dontValidateOnMount="true"
                                    validateOnSubmit="true"
                                    validateError={this.errorValidator}
                                    onSubmit={this.passwordSubmit}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Typography className={classes.passwordError}>
                                                {passwordRecover.error}
                                            </Typography>
                                            {passwordRecover.success && (
                                                <Typography>
                                                    Your password has been set. Return to the home page to log in. After
                                                    log in save page to tool bar!
                                                </Typography>
                                            )}
                                            {!passwordRecover.success && (
                                                <>
                                                    <TextField
                                                        field="password"
                                                        type="password"
                                                        label="Password"
                                                        helperText="Must be at least 8 characters."
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        field="password2"
                                                        type="password"
                                                        label="Password"
                                                        helperText="Must match."
                                                        fullWidth
                                                    />
                                                    <Typography>
                                                        Password Requirements:
                                                        <ul>
                                                            <li>Minimum 8 characters</li>
                                                            <li>Cannot be part of your name or email</li>
                                                            <li>Cannot be entirely numeric</li>
                                                        </ul>
                                                    </Typography>
                                                    <Submit
                                                        label="Set"
                                                        loading={passwordRecover.pending}
                                                        altAction="/"
                                                        altLabel="Return to Home Page"
                                                    />
                                                </>
                                            )}
                                            {passwordRecover.success && (
                                                <Button className={classes.space} fullWidth component={Link} to="/">
                                                    Return to Home page
                                                </Button>
                                            )}
                                        </form>
                                    )}
                                </Form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

PasswordSetPage = connect(
    state => ({ passwordRecover: (state.auth && state.auth.passwordRecover) || {} }),
    authActions
)(PasswordSetPage);

export default withStyles(styles)(withRouter(PasswordSetPage));
