import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";

import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import MailIcon from "@material-ui/icons/Mail";
import CloseIcon from "@material-ui/icons/Close";
import CommentIcon from "@material-ui/icons/Comment";
import BuildIcon from "@material-ui/icons/Build";
import DeleteIcon from "@material-ui/icons/Delete";
import EventIcon from "@material-ui/icons/Event";
import GetApp from "@material-ui/icons/GetApp";
import LockIcon from "@material-ui/icons/LockOutlined";
import Close from "@material-ui/icons/Close";
import GroupAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabled from "@material-ui/icons/PersonAddDisabled";
import SendIcon from "@material-ui/icons/Send";
import Visibility from "@material-ui/icons/Visibility";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import DatePicker from "../common/DatePicker";
import WarningDialog from "../common/WarningDialog";
import Checkbox from "../common/Checkbox";
import { createSelector } from "../common/orm";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import RadioGroup from "../common/RadioGroup";
import TextField from "../common/TextField";
import Select from "../common/Select";
import Snackbar from "../common/Snackbar";
import { getValue } from "../../api/utils";

import IntroductionTab from "./tabs/Introduction";
import CollaboratorsProjectManagerTab from "./tabs/Collaborators_ProjectManager";
import CollaboratorsOrganizationTab from "./tabs/Collaborators_Organization";
import CollaboratorsPartnersTab from "./tabs/Collaborators_Partners";
import GeneralTab from "./tabs/General";
import NarrativeTab from "./tabs/Narrative";
import ActivityTab from "./tabs/Activity";
import BudgetPersonnelTab from "./tabs/Budget_Personnel";
import BudgetContractsTab from "./tabs/Budget_Contracts";
import BudgetExpendituresTab from "./tabs/Budget_Expenditures";
import BudgetAcquisitionTab from "./tabs/Budget_Acquisition";
import BudgetTravelTab from "./tabs/Budget_Travel";
import BudgetPrintingTab from "./tabs/Budget_Printing";
import BudgetOtherTab from "./tabs/Budget_Other";
import BudgetFundsTab from "./tabs/Budget_Funds";
import BudgetSummaryTab from "./tabs/Budget_Summary";
import AttachmentsTab from "./tabs/Attachments";
import AcquisitionParcelsTab from "./tabs/Acquisition_Parcels";
import AcquisitionFeeTab from "./tabs/Acquisition_Fee";
import AcquisitionConservationTab from "./tabs/Acquisition_Conservation";
import AcquisitionOtherTab from "./tabs/Acquisition_Other";
import AcquisitionRestorationTab from "./tabs/Acquisition_Restoration";
import ReviewTab from "./tabs/Review";
import StaffScoreTab from "./tabs/Staff_Score";
import FinalComments from "./tabs/Final_comments";
import AggregateScoring from "./tabs/Aggregate_score";
import MemberScore from "./tabs/MemberScore";
import StaffAdmin from "./tabs/StaffAdmin";
//import CommentsTab from "./tabs/Comments";
import {
    Proposal,
    ProposalCollaborator,
    ProposalActivity,
    ProposalMilestone,
    ProposalPastProject,
    ProposalPersonnel,
    ProposalContract,
    ProposalExpenditure,
    ProposalAcquisition,
    ProposalTravel,
    ProposalPrinting,
    ProposalExpense,
    ProposalNonENRTFFund,
    ProposalAttachment,
    ProposalParcel,
    ProposalDelegate,
    ProposalView,
    StaffComment
} from "./models";
import { ProposalReview, StaffSlots, StaffScore, SystemSetting, ProposalTab } from "../home/models";
import { ManagerRequest } from "../workplan/models";


const getManagerRequests = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.ManagerRequest.filter(x => x.proposal_id === id).orderBy("id", "desc").toModelArray();
    }
);

const getProposal = Proposal.selectByUrlId(proposal => ({
    formData: proposal.ref,
    ...proposal.ref,
    org_data: proposal.organization,
    agent_data: proposal.fiscal_agent
}));
const getProposalPersonnel = ProposalPersonnel.selectByProposalId();
const getProposalContracts = ProposalContract.selectByProposalId();
const getProposalExpenditures = ProposalExpenditure.selectByProposalId();
const getProposalAcquisitions = ProposalAcquisition.selectByProposalId();
const getProposalTravels = ProposalTravel.selectByProposalId();
const getProposalPrintings = ProposalPrinting.selectByProposalId();
const getProposalExpenses = ProposalExpense.selectByProposalId();
const getProposalNonENRTFFunds = ProposalNonENRTFFund.selectByProposalId();
const getStaffSlots = StaffSlots.selectAll();
const getRFPs = SystemSetting.selectAll();
const getHelpMessages = ProposalTab.selectMessage();
const styles = theme => ({
    listItem: {
        borderBottom: "1px solid #666666",
        "&:focus": {
            backgroundColor: "rgba(0, 0, 0, 0.08) !important"
        }
    },
    stepRoot: {
        backgroundColor: "#eeeeee",
        marginBottom: theme.spacing.unit * 2,
        padding: theme.spacing.unit,
        paddingLeft: 0,
        paddingRight: 0
    },
    pointer: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        "&:hover": {
            borderBottom: "8px solid lightgray"
        }
    },
    pointerr: {
        borderBottom: "8px solid lightgray",
        marginBottom: "-8px !important"
    },
    pointer2: {
        cursor: "pointer",
        "& > span:first-child": {
            cursor: "pointer"
        },
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        borderBottom: "8px solid " + theme.palette.primary.main
    },
    activePoint: {
        borderBottom: "8px solid #4f98ce",
        marginBottom: "-8px !important"
    },
    btnroot: {
        padding: 0,
        margin: 0
    },
    stepColor: {
        "& svg": {
            color: "#fff" // + " !important",
        },
        "& svg text": {
            fill: "#000" // + " !important",
        }
    },
    lessMargin: {
        "& span": {
            marginTop: "8px !important",
            color: "#000"
        }
    },
    stepperWidth: {
        marginLeft: -20,
        marginRight: -20,
        maxWidth: "calc(100% + 40px)",
        flexBasis: "unset",
        width: "calc(100% + 40px)",
        padding: "0 !important"
    },
    list: {
        "& ul": {
            padding: 0
        }
    },
    topListText: {
        textAlign: "center",
        padding: 0
    },
    hideIcon: {
        display: "none"
    },
    subStep: {
        marginLeft: 16,
        marginTop: -16,
        padding: 8,
        backgroundColor: "#00406e",
        alignItems: "flex-end"
    },
    subStepContainer: {
        marginLeft: -30,
        marginRight: -30,
        backgroundColor: "#00406e"
    },
    whiteLabel: {
        color: "#fff !important"
    },
    disabledStep: {
        borderBottom: "8px solid transparent",
        marginBottom: -8,
        paddingRight: "8px !important",
        paddingLeft: "8px !important"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    },
    dialogTitleMargin: {
        marginRight: "48px"
    },
    closeButton: {
        position: "absolute",
        right: 8,
        top: 8
    },
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 24
    },
    titleContainer: {
        backgroundColor: theme.palette.primary.main,
        textAlign: "center"
    },
    questionTitle: {
        marginTop: ".6em",
        color: theme.palette.primary.main,
        fontWeight: 500,
        fontSize: "1.15rem",
        lineHeight: 1.2
    },
    greyButton: {
        backgroundColor: "#808080",
        color: "#fff"
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24
    },
    nooverflow: {
        overflow: "hidden"
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    }
});
function getSteps() {
    return [
        "Introduction",
        "Project Collaborators",
        "General Information",
        "Narrative",
        "Activities and Milestones",
        "Budget",
        "Attachments",
        "Acquisition and Restoration",
        "Review / Submit"
    ];
}
function getStaffSteps() {
    return [
        "Introduction",
        "Project Collaborators",
        "General Information",
        "Narrative",
        "Activities and Milestones",
        "Budget",
        "Attachments",
        "Acquisition and Restoration",
        "Review and Scoring"
    ];
}

function getDNRSteps() {
    return [
        "Introduction",
        "Project Collaborators",
        "General Information",
        "Narrative",
        "Activities and Milestones",
        "Budget",
        "Attachments",
        "Acquisition and Restoration"
    ];
}

function getCollaboratorSteps() {
    return ["Project Manager Info", "Organization Info", "Project Partner Info"];
}
function getBudgetSteps() {
    return [
        "Personnel",
        "Professional / Technical Contracts",
        "Capital, Equipment, Tools, and Supplies",
        "Acquisition",
        "Travel and Conferences",
        "Printing and Publication",
        "Other",
        "Non-ENRTF Funds Contributed",
        "Summary"
    ];
}
function getMemberBugetSteps() {
    return ["Summary"];
}
function getParcelSteps() {
    return ["Parcel List", "Fee Acquisition", "Conservation Easement Acquisition", "Easement Acquisition (Other)", "Restoration"];
}

function getStaffScoringSteps() {
    return [
        "Draft Proposal Review Comments",
        "My Staff Scoring",
        "Final Proposal Review Comments",
        "Staff Aggregate Scoring and Final Staff Score",
        "View Member Scoring Summary",
        "Staff Admin"
    ];
}

const getDelegations = createSelector(schema => {
    return schema.ProposalDelegate.all().toRefArray();
});
const getTab = ProposalTab.selectMessage(12);

function canViewstaffScore(index, final_open, staffBln) {
    if (!staffBln) {
        if (index === 0) {
            return true;
        } else if (final_open && index !== 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return index === 0 || final_open;
    }
}

function getStaffLockLabel(index, final_open, staffBln) {
    if (final_open && staffBln && index === 1) {
        return "Must have Staff Slot to Score.";
    } else {
        return "Proposal must be in Final Submitted and Staff Evaluation must be open";
    }
}

class List extends Component {
    constructor(props) {
        super(props);
        this.appContainer = React.createRef();
        this.targetRef = React.createRef();
    }
    state = {
        unsavedFields: false,
        snackbarOpen: false,
        anchorEl: null,
        delegateDialog: false,
        formkey: null,
        deleteOptions: [],
        deleteDelegateDialog: false,
        deleteOpen2: false,
        submitFeedBackComments: false,
        addStaffComment: false,
        reqeuestWidrawal: false,
        changedStep: false,
        viewMode: this.props.match.path === "/proposalview/:id" ? true : false
    };

    dueDate = values => {
        const { proposal, ormProposalUpdate, ormProposalViewUpdateLocalOnly } = this.props;
        ormProposalUpdate({
            id: proposal.id,
            status: "Revisions Needed",
            status_date: this.getDateTimeStamp(),
            due_date: values.due_date.substring(0, 10)
        });
        ormProposalViewUpdateLocalOnly({
            id: proposal.id,
            status: "Revisions Needed",
            status_date: this.getDateTimeStamp(),
            due_date: values.due_date.substring(0, 10)
        });
        this.setState({ revisionOpen: false });
        this.actionMenuClose();
        this.handleStep(8, false, 0);
    };
    validateDueDate = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            due_date: isRequired(values.due_date)
        };

        return valObj;
    };
    submitCommentsApplicant = () => {
        const { proposal, ormProposalUpdate, ormProposalViewUpdateLocalOnly } = this.props;
        ormProposalUpdate({
            id: proposal.id,
            status: "Draft Feedback Received",
            status_date: this.getDateTimeStamp()
        });
        ormProposalViewUpdateLocalOnly({
            id: proposal.id,
            status: "Draft Feedback Received",
            status_date: this.getDateTimeStamp()
        });
        this.setState({ submitFeedBackComments: false });
        this.actionMenuClose();
    };
    addStaffComment = values => {
        const { authState, proposal, ormStaffCommentCreate } = this.props;
        ormStaffCommentCreate({
            proposal: proposal.id,
            user_id: authState.user.id,
            draft: proposal.status === "Draft Submitted" ? true : false,
            ...values
        });
        this.setState({ addStaffComment: false });
        this.actionMenuClose();
    };
    validateComment = values => {
        const isRequired = val => {
            return !val ? "Reason Required" : null;
        };
        var valObj = {
            comment: isRequired(values.comment)
        };

        return valObj;
    };
    requestWithDrawal = values => {
        const { proposal, ormProposalUpdate, ormProposalViewUpdateLocalOnly } = this.props;
        ormProposalUpdate({
            id: proposal.id,
            prev_status: proposal.secondary_status,
            secondary_status: "Withdrawal Requested",
            secondary_date: this.getDateTimeStamp(),
            withdrawal_reason: values.withdrawal_reason
        });
        ormProposalViewUpdateLocalOnly({
            id: proposal.id,
            prev_status: proposal.secondary_status,
            secondary_status: "Withdrawal Requested",
            secondary_date: this.getDateTimeStamp(),
            withdrawal_reason: values.withdrawal_reason
        });
        this.setState({ reqeuestWidrawal: false });
        this.actionMenuClose();
    };
    validateWithdrawal = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            withdrawal_reason: isRequired(values.withdrawal_reason)
        };

        return valObj;
    };
    // Grabs the four staff slots to for creation of ProposalReview to store who scored
    getStaffKeys = () => {
        const { staffSlots } = this.props;
        var arr = [];
        var obj = {};

        staffSlots.forEach(x => {
            if (x.slot) {
                arr[x.slot] = x;
            }
        });

        staffSlots.forEach(x => {
            obj[`staff_${x.slot}`] = x.staff;
        });

        return obj;
    };
    getDateTimeStamp = () => {
        const date = new Date();
        const dateFns = new DateFnsUtils();
        return dateFns.format(date, "yyyy-MM-dd HH:mm:ss.SSSSSS");
    };
    handleUnsavedFields = unsavedFields => {
        this.setState({ unsavedFields });
    };

    isLastStep(step) {
        return step === getSteps().length - 1;
    }

    isFirstStep(step) {
        return step === 0;
    }

    calcFundingTotal = () => {
        const {
            proposal,
            proposalpersonnel,
            proposalcontracts,
            proposalexpenditures,
            proposalacquisitions,
            proposaltravels,
            proposalprintings,
            proposalexpenses,
            //proposalfunds,
            ormProposalUpdate
        } = this.props;
        var id = proposal.id;
        var full_list = [
            ...proposalpersonnel,
            ...proposalcontracts,
            ...proposalexpenditures,
            ...proposalacquisitions,
            ...proposaltravels,
            ...proposalprintings,
            ...proposalexpenses
            // ...proposalfunds,
        ];
        var tempFundingTotal = 0.0;
        full_list.map(x => (tempFundingTotal += parseInt(x.amount)));
        ormProposalUpdate({
            id: id,
            funding_total: tempFundingTotal
        });
    };
    // This is called when the user clicks the save button at the bottom of the page.
    // Just determine what the next tab should be and pass to handle step.
    handleNext = () => {
        const { history, proposal } = this.props;
        let { activeStep, activeSubStep } = proposal;
        const Restoration = getValue(proposal, "land_rights").indexOf("Restoration") > -1;
        const Conservation = getValue(proposal, "land_rights").indexOf("Conservation Easement Acquisition") > -1;
        const Easement = getValue(proposal, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(proposal, "land_rights").indexOf("Fee Acquisition") > -1;
        if (!activeStep) activeStep = 0;
        // They've saved the last step - reset the numbering system and go to the dashboard.
        if (this.isLastStep(activeStep)) {
            activeStep = 0;
            activeSubStep = 0;
            history.push("/dashboard");
        } else {
            // These are the steps without any substeps - just increment as normal
            if (activeStep === 0 || activeStep === 2 || activeStep === 3 || activeStep === 4 || activeStep === 6 || activeStep === 8) {
                if (activeStep === 6 && !Restoration && !Conservation && !Easement && !Fee) {
                    // Skip Acquistion and Restoration if nothing checked
                    activeStep = (activeStep || 0) + 2;
                } else {
                    activeStep = (activeStep || 0) + 1;
                }
                activeSubStep = 0;
            } else {
                if (activeStep === 1) {
                    // The step is over, increment the main step
                    if (getCollaboratorSteps().length - 1 === activeSubStep) {
                        activeStep = (activeStep || 0) + 1;
                        activeSubStep = 0;
                    } else {
                        activeSubStep = (activeSubStep || 0) + 1;
                    }
                } else if (activeStep === 5) {
                    // The step is over, increment the main step
                    if (getBudgetSteps().length - 1 === activeSubStep) {
                        activeStep = (activeStep || 0) + 1;
                        activeSubStep = 0;
                    } else {
                        activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 3 && !Fee && !Conservation && !Easement) {
                            activeSubStep = (activeSubStep || 0) + 1;
                        }
                    }
                } else if (activeStep === 7) {
                    // The step is over, increment the main step
                    if (getParcelSteps().length - 1 === activeSubStep) {
                        activeStep = (activeStep || 0) + 1;
                        activeSubStep = 0;
                    } else {
                        activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 1 && !Fee) activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 2 && !Conservation) activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 3 && !Easement) activeSubStep = (activeSubStep || 0) + 1;
                        if (activeSubStep === 4 && !Restoration) {
                            activeStep = (activeStep || 0) + 1;
                            activeSubStep = 0;
                        }
                    }
                }
            }
        }
        this.handleStep(activeStep, false, activeSubStep);
    };
    handlePrev = () => {
        const { history, proposal } = this.props;
        let { activeStep, activeSubStep } = proposal;
        const Restoration = getValue(proposal, "land_rights").indexOf("Restoration") > -1;
        const Conservation = getValue(proposal, "land_rights").indexOf("Conservation Easement Acquisition") > -1;
        const Easement = getValue(proposal, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(proposal, "land_rights").indexOf("Fee Acquisition") > -1;
        if (!activeStep || activeStep <= 0) {
            activeStep = 0;
            activeSubStep = 0;
            history.push("/dashboard");
        }
        if (activeStep === 1) {
            if (activeSubStep === 0) {
                activeStep = (activeStep || 0) - 1;
                activeSubStep = 0;
            } else {
                activeSubStep = (activeSubStep || 0) - 1;
            }
        }
        if (activeStep === 2) {
            activeStep = (activeStep || 0) - 1;
            activeSubStep = getCollaboratorSteps().length - 1;
        }
        if (activeStep === 3) {
            activeStep = (activeStep || 0) - 1;
            activeSubStep = 0;
        }
        if (activeStep === 4) {
            activeStep = (activeStep || 0) - 1;
            activeSubStep = 0;
        }
        if (activeStep === 5) {
            if (activeSubStep === 0) {
                activeStep = (activeStep || 0) - 1;
                activeSubStep = 0;
            } else if (activeSubStep === 4 && !Conservation && !Easement && !Fee) {
                activeSubStep = (activeSubStep || 0) - 2;
            } else {
                activeSubStep = (activeSubStep || 0) - 1;
            }
        }
        if (activeStep === 6) {
            activeStep = (activeStep || 0) - 1;
            activeSubStep = getBudgetSteps().length - 1;
        }
        if (activeStep === 7) {
            if (activeSubStep === 0) {
                activeStep = (activeStep || 0) - 1;
                activeSubStep = 0;
            } else {
                activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 3 && !Easement) activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 2 && !Conservation) activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 1 && !Fee) activeSubStep = (activeSubStep || 0) - 1;
            }
        }
        if (activeStep === 8) {
            if (!Restoration && !Conservation && !Easement && !Fee) {
                // Skip Acquistion and Restoration if nothing checked
                activeStep = (activeStep || 0) - 2;
                activeSubStep = 0;
            } else {
                activeStep = (activeStep || 0) - 1;
                activeSubStep = getParcelSteps().length - 1;
                if (activeSubStep === 4 && !Restoration) {
                    activeSubStep = (activeSubStep || 0) - 1;
                }
                if (activeSubStep === 3 && !Easement) activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 2 && !Conservation) activeSubStep = (activeSubStep || 0) - 1;
                if (activeSubStep === 1 && !Fee) activeSubStep = (activeSubStep || 0) - 1;
            }
        }
        this.handleStep(activeStep, false, activeSubStep);
    };
    // This is called either from clicking on the stepper (fromStepper) or from handleNext.
    // If it is fromStepper it will perform a submitForm on the form of the active tab.
    // If it is from handleNext then the steps are just updated.
    // Note that handleNext is called from the form submission but only if the form isn't submitted from handleStep (!Number.isInteger(fromStepper))
    handleStep = (step, fromStepper, subStep = 0) => {
        const { id } = this.props.proposal;
        this.handleUnsavedFields(false);
        // Hacky method to save form when using stepper
        if (fromStepper) {
            // Apparently just having a single "activeForm" works because only one stepper detail can be active at once
            if (this.activeForm) {
                const f = this;
                setTimeout(function() {
                    // The state is not set right away...
                    // If there is a series of forms save all of the forms (Activity Tab + others?)
                    if (Array.isArray(f.activeForm.form)) {
                        f.activeForm.form.forEach(function(fo) {
                            fo.submitForm();
                        });
                        // Then save the next steps onto the proposal because those forms above are not the proposal.
                        f.props.ormProposalUpdateLocalOnly({
                            id: id,
                            activeStep: step,
                            activeSubStep: subStep
                        });
                    } else if (f.activeForm.form) {
                        // Regular form submission - let the form save the proposal
                        f.activeForm.form.submitForm({ step: step, subStep: subStep });
                    } else {
                        // The parcel list has no form - update the step here
                        f.props.ormProposalUpdateLocalOnly({
                            id: id,
                            activeStep: step,
                            activeSubStep: subStep
                        });
                    }
                }, 50);
            }
        } else {
            this.props.ormProposalUpdateLocalOnly({
                id: id,
                activeStep: step,
                activeSubStep: subStep
            });
        }
        if (this.props.proposal === "Draft In Progress") this.setState({ snackbarOpen: true });
    };
    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    componentDidMount() {
        const {
            proposal,
            ormProposalLoadDetail,
            match,
            authState,
            ormProposalCollaboratorLoadDetailChild,
            ormProposalActivityLoadDetailChild,
            ormProposalMilestoneLoadDetailChild,
            ormProposalPastProjectLoadDetailChild,
            ormProposalPersonnelLoadDetailChild,
            ormProposalContractLoadDetailChild,
            ormProposalExpenditureLoadDetailChild,
            ormProposalAcquisitionLoadDetailChild,
            ormProposalTravelLoadDetailChild,
            ormProposalPrintingLoadDetailChild,
            ormProposalExpenseLoadDetailChild,
            ormProposalNonENRTFFundLoadDetailChild,
            ormProposalAttachmentLoadDetailChild,
            ormProposalParcelLoadDetailChild,
            ormProposalReviewLoadDetailChildFilterMany,
            ormStaffScoreLoadDetailChildFilterMany,
            ormStaffCommentLoadDetailChildFilterMany,
            ormManagerRequestLoadDetailChild,
        } = this.props;

        // If the proposal hasn't been loaded yet - most likely because staff is viewing the record
        // Then we must query the server directly because staff can't load in every single proposal at once
        // Regular uses would know the ids of every proposal through ProposalView but we are checking if they really are staff on the server before rturning all data regarding proposal
        if (!proposal && authState && authState.user) {
            ormProposalLoadDetail(match.params.id);
            ormProposalCollaboratorLoadDetailChild(match.params.id);
            ormProposalActivityLoadDetailChild(match.params.id);
            ormProposalMilestoneLoadDetailChild(match.params.id);
            ormProposalPastProjectLoadDetailChild(match.params.id);
            ormProposalPersonnelLoadDetailChild(match.params.id);
            ormProposalContractLoadDetailChild(match.params.id);
            ormProposalExpenditureLoadDetailChild(match.params.id);
            ormProposalAcquisitionLoadDetailChild(match.params.id);
            ormProposalTravelLoadDetailChild(match.params.id);
            ormProposalPrintingLoadDetailChild(match.params.id);
            ormProposalExpenseLoadDetailChild(match.params.id);
            ormProposalNonENRTFFundLoadDetailChild(match.params.id);
            ormProposalAttachmentLoadDetailChild(match.params.id);
            ormProposalParcelLoadDetailChild(match.params.id);
            ormProposalReviewLoadDetailChildFilterMany({ proposal: match.params.id });
            ormStaffScoreLoadDetailChildFilterMany({ proposal: match.params.id });
            ormStaffCommentLoadDetailChildFilterMany({ proposal: match.params.id });
            ormManagerRequestLoadDetailChild(match.params.id);
        }
    }

    // Copy of componentDidMount incase they load another proposal
    componentDidUpdate() {
        const {
            synced,
            proposal,
            ormProposalLoadDetail,
            match,
            authState,
            ormProposalCollaboratorLoadDetailChild,
            ormProposalActivityLoadDetailChild,
            ormProposalMilestoneLoadDetailChild,
            ormProposalPastProjectLoadDetailChild,
            ormProposalPersonnelLoadDetailChild,
            ormProposalContractLoadDetailChild,
            ormProposalExpenditureLoadDetailChild,
            ormProposalAcquisitionLoadDetailChild,
            ormProposalTravelLoadDetailChild,
            ormProposalPrintingLoadDetailChild,
            ormProposalExpenseLoadDetailChild,
            ormProposalNonENRTFFundLoadDetailChild,
            ormProposalAttachmentLoadDetailChild,
            ormProposalParcelLoadDetailChild,
            ormProposalReviewLoadDetailChildFilterMany,
            ormStaffScoreLoadDetailChildFilterMany,
            ormStaffCommentLoadDetailChildFilterMany,
            ormManagerRequestLoadDetailChild,
        } = this.props;

        if (!proposal && authState && authState.user && synced && synced.ready) {
            ormProposalLoadDetail(match.params.id);
            ormProposalCollaboratorLoadDetailChild(match.params.id);
            ormProposalActivityLoadDetailChild(match.params.id);
            ormProposalMilestoneLoadDetailChild(match.params.id);
            ormProposalPastProjectLoadDetailChild(match.params.id);
            ormProposalPersonnelLoadDetailChild(match.params.id);
            ormProposalContractLoadDetailChild(match.params.id);
            ormProposalExpenditureLoadDetailChild(match.params.id);
            ormProposalAcquisitionLoadDetailChild(match.params.id);
            ormProposalTravelLoadDetailChild(match.params.id);
            ormProposalPrintingLoadDetailChild(match.params.id);
            ormProposalExpenseLoadDetailChild(match.params.id);
            ormProposalNonENRTFFundLoadDetailChild(match.params.id);
            ormProposalAttachmentLoadDetailChild(match.params.id);
            ormProposalParcelLoadDetailChild(match.params.id);
            ormProposalReviewLoadDetailChildFilterMany({ proposal: match.params.id });
            ormStaffScoreLoadDetailChildFilterMany({ proposal: match.params.id });
            ormStaffCommentLoadDetailChildFilterMany({ proposal: match.params.id });
            ormManagerRequestLoadDetailChild(match.params.id);
        }

        if (proposal) {
            if (this.props.location.search && this.props.location.search !== "" && !this.state.changedStep && this.props.synced && this.props.synced.ready) {
                this.setState({ changedStep: true });
                this.handleStep(parseInt(this.props.location.search.split("=")[1]), false, 0);
            }
        }
    }

    actionMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    actionMenuClose = () => {
        this.setState({ anchorEl: null });
    };
    goFeedBack = () => {
        this.props.history.push(
            "/feedback/" + this.props.proposal.id + "?draft=" + (this.props.proposal.status === "Draft Feedback Received" ? "true" : "false")
        );
    };
    goLog = () => {
        this.props.history.push("/activity/log/" + this.props.proposal.id);
    };
    ActionMenu = ({ options, actionMenuOpen, actionMenuClose, anchorEl, proposal, classes, authState, proposalDelegates, 
        manager_requests, ormManagerRequestDelete, history, ...rest }) => {
        var is_admin = false;
        var is_dnr = false;
        if (authState && authState.user.role === "Staff" && authState.user.email !== "monica.weber@state.mn.us") is_admin = true;
        if (authState && (authState.user.is_read_only || authState.user.role === "Member")) is_dnr = true;

        var pds = [];
        if (!is_admin) {
            proposalDelegates.forEach(function(pD) {
                if (pD.proposal === proposal.id) pds.push(pD.id);
            });
        }

        return (
            <div style={{ float: "right" }}>
                <Button variant="contained" color="primary" onClick={actionMenuOpen}>
                    <BuildIcon />
                    &nbsp;&nbsp;&nbsp;Select Action
                </Button>
                <Menu className={classes.list} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={actionMenuClose}>
                    <MenuItem
                        className={classes.listItem}
                        onClick={() => window.open("/reports/proposal/" + proposal.id)}>
                        <ListItemIcon>
                            <GetApp />
                        </ListItemIcon>
                        <ListItemText primary="Generate Report (Word Doc)" />
                    </MenuItem>
                    {proposal.status === "Draft In Progress" && !is_dnr && (
                        <MenuItem className={classes.listItem} onClick={() => this.setState({ deleteOpen2: true })}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delete Proposal" />
                        </MenuItem>
                    )}
                    {!is_admin && !is_dnr && proposal.user_id === authState.user.id && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => this.setState({ delegateDialog: true, formkey: window.performance.now() })}>
                            <ListItemIcon>
                                <GroupAddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Delegate Access" />
                        </MenuItem>
                    )}
                    {pds.length > 0 && !is_dnr && proposal.user_id === authState.user.id && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => this.setState({ deleteOptions: pds, deleteDelegateDialog: true })}>
                            <ListItemIcon>
                                <PersonAddDisabled />
                            </ListItemIcon>
                            <ListItemText primary="Remove Delegate Access" />
                        </MenuItem>
                    )}
                    {!is_admin && !is_dnr &&
                        proposal.user_id === authState.user.id &&
                        proposal.status !== "Draft In Progress" &&
                        proposal.status !== "Draft Submitted" &&
                        proposal.secondary_status !== "Withdrawal Requested" &&
                        proposal.secondary_status !== "Withdrawn" && (
                            <MenuItem className={classes.listItem} onClick={() => this.setState({ reqeuestWidrawal: true })}>
                                <ListItemIcon>
                                    <MailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Request Withdrawal" />
                            </MenuItem>
                        )}
                    {(proposal.status === "Draft Feedback Received" || proposal.status === "Revisions Needed") && !is_dnr && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={this.goFeedBack}>
                            <ListItemIcon>
                                <Visibility />
                            </ListItemIcon>
                            <ListItemText
                                primary={"View LCCMR Feedback on " + (proposal.status === "Draft Feedback Received" ? "Draft Submittal" : "Submitted Proposal")}
                            />
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr && (
                        <>
                            {proposal.activeStep &&
                                proposal.activeStep !== 0 &&
                                proposal.activeStep !== 8 &&
                                ["Draft Submitted", "Final Submitted", "Revisions Submitted"].includes(proposal.status) && (
                                    <MenuItem className={classes.listItem} onClick={() => this.setState({ addStaffComment: true })}>
                                        <ListItemIcon>
                                            <CommentIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Add Staff Comment" />
                                    </MenuItem>
                                )}
                            {proposal.status === "Draft Submitted" && proposal.activeStep === 8 && proposal.activeSubStep === 0 && (
                                <MenuItem className={classes.listItem} onClick={() => this.setState({ submitFeedBackComments: true })}>
                                    <ListItemIcon>
                                        <SendIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Submit Comments to Applicant" />
                                </MenuItem>
                            )}
                            {["Final Submitted", "Revisions Submitted"].includes(proposal.status) && proposal.activeStep === 8 && proposal.activeSubStep === 2 && (
                                <MenuItem className={classes.listItem} onClick={() => this.setState({ revisionOpen: true })}>
                                    <ListItemIcon>
                                        <EventIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Set Due Date and Send Proposal Back to Applicant for Revisions" />
                                </MenuItem>
                            )}
                        </>
                    )}
                    
                    {!is_admin && !is_dnr && manager_requests.length > 0 && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                ormManagerRequestDelete(manager_requests[0].id);
                            }}>
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                            <ListItemText primary="Withdraw Project Manager Change Request" />
                        </MenuItem>
                    )}
                    {is_admin && !is_dnr && proposal.status !== "Final Work Plan Approved" && manager_requests.length > 0 && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={() => {
                                history.push("/adm/manager_request/" + manager_requests[0].id);
                            }}>
                            <ListItemIcon>
                            <Visibility />
                            </ListItemIcon>
                            <ListItemText primary="Review Request To Change Project Manager" />
                        </MenuItem>
                    )}
                    {proposal.status !== "Draft In Progress" && (
                        <MenuItem
                            className={classes.listItem}
                            onClick={this.goLog}>
                            <ListItemIcon>
                                <Visibility />
                            </ListItemIcon>
                            <ListItemText primary="View Activity Log" />
                        </MenuItem>
                    )}
                    <MenuItem style={{ borderBottom: "1px solid #666666", backgroundColor: "#eee", cursor: "default" }}>
                        <ListItemText primary={"Proposal Status: " + proposal.status} />
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    go = e => {
        e.preventDefault();
        this.props.history.push({ hash: "skipprojectnavigation", search: this.props.location.search });
        e.target.blur();
        this.targetRef.current.scrollIntoView();
        this.targetRef.current.focus();
    };

    getSubStep = (is_staff) => {
        const { proposal } = this.props;
        if (proposal.activeStep === 1)
            return getCollaboratorSteps()[proposal.activeSubStep]
        if (proposal.activeStep === 5)
            return getBudgetSteps()[proposal.activeSubStep]
        if (proposal.activeStep === 7)
            return getParcelSteps()[proposal.activeSubStep]
        if (proposal.activeStep === 8 && is_staff)
            return getStaffScoringSteps()[proposal.activeSubStep]
        return null;
    }

    render() {
        const {
            classes,
            proposal,
            authState,
            staffSlots,
            rfps,
            manager_requests,
            ormProposalDelegateReload,
            proposalDelegates,
            ormProposalDelegateDelete,
            ormProposalDelete,
            ormManagerRequestDelete,
            history,
            helpmessages
        } = this.props;
        const { snackbarOpen, delegateDialog, deleteDelegateDialog, formkey, deleteOpen2, submitFeedBackComments, viewMode } = this.state;
        if (!proposal) {
            return <AppContainer authenticated synced={true} />;
        }
        const Restoration = getValue(proposal, "land_rights").indexOf("Restoration") > -1;
        const Conservation = getValue(proposal, "land_rights").indexOf("Conservation Easement Acquisition") > -1;
        const Easement = getValue(proposal, "land_rights").indexOf("Easement (Other) Acquisition") > -1;
        const Fee = getValue(proposal, "land_rights").indexOf("Fee Acquisition") > -1;

        //check if user can score proposals
        const ReviewScoring = staffSlots.find(x => authState.user && x.staff === authState.user.id);
        const adminRole = authState && authState.user.role === "Staff" && authState.user.email !== "monica.weber@state.mn.us";
        const is_dnr = authState && authState.user.is_read_only;
        const submitted =
            (proposal && (proposal.status === "Final Submitted" || proposal.status === "Revisions Submitted")) || proposal.primary_phase !== "Draft Proposal";
        const settings = rfps.find(x => x.year === proposal.ml_year);

        const final_open = submitted;

        var steps;
        if (is_dnr || viewMode) {
            steps = getDNRSteps;
        } else if (ReviewScoring || adminRole) {
            steps = getStaffSteps;
        } else  {
            steps = getSteps;
        }

        return (
            <AppContainer authenticated innerRef={this.appContainer}>
                <Grid container spacing={16}>
                    <a
                        href={`#${this.props.location.pathname}${
                            this.props.location.search ? this.props.location.search : ""
                        }`}
                        onClick={this.go}
                        class="skip-navigation">
                        Skip proposal navigation
                    </a>
                    <PageHeader 
                        title={proposal.proposal_unique_id + ": " + proposal.name} 
                        activeStep={steps()[proposal.activeStep]} 
                        activeSubStep={this.getSubStep(ReviewScoring || adminRole)}
                    />
                    <Grid item xs={12} className={classes.stepperWidth}>
                        <Stepper nonLinear className={classes.stepRoot} alternativeLabel activeStep={proposal.activeStep || 0}>
                            {steps().map((label, index) => {
                                if (index === 7 && !Restoration && !Conservation && !Easement && !Fee) {
                                    return (
                                        <Tooltip title="You must select one of the options for the question 'Does your project include restoration or acquisition of land rights' under the General Step">
                                            <Step key={label}>
                                                <StepButton
                                                    disabled
                                                    className={classes.btnroot}
                                                    focusVisibleClassName={classes.pointerr}
                                                    onClick={() => this.handleStep(index, true)}>
                                                    <StepLabel
                                                        StepIconComponent={LockIcon}
                                                        classes={{
                                                            labelContainer: classes.lessMargin
                                                        }}>
                                                        {label}
                                                    </StepLabel>
                                                </StepButton>
                                            </Step>
                                        </Tooltip>
                                    );
                                }

                                return (
                                    <Step key={label} className={proposal.activeStep !== index ? classes.pointer : classes.pointer2}>
                                        <StepButton
                                            className={classes.btnroot}
                                            focusVisibleClassName={classes.pointerr}
                                            onClick={() => this.handleStep(index, true)}>
                                            <StepLabel
                                                classes={{
                                                    labelContainer: classes.lessMargin,
                                                    iconContainer: proposal.activeStep !== index && classes.stepColor
                                                }}>
                                                {label}
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        
                        <a // eslint-disable-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
                            tabIndex="-1"
                            id={`project${this.props.location.pathname}${
                                this.props.location.search ? this.props.location.search : ""
                            }`}
                            name={`${this.props.location.pathname}${
                                this.props.location.search ? this.props.location.search : ""
                            }`}
                            ref={this.targetRef}
                        />
                        <div style={{ marginRight: 30, marginLeft: 30 }}>
                            {(proposal.activeStep === 0 || !proposal.activeStep) && (
                                <IntroductionTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    settings={settings}
                                    helpmessages={helpmessages}
                                    ActionMenu={
                                        <this.ActionMenu
                                            proposalDelegates={proposalDelegates}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            manager_requests={manager_requests}
                                            authState={authState}
                                            classes={classes}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            history={history}
                                            actionMenuClose={this.actionMenuClose}
                                        />
                                    }
                                />
                            )}
                            {proposal.activeStep === 1 && (
                                <>
                                    <div className={classes.subStepContainer}>
                                        <Stepper
                                            nonLinear
                                            alternativeLabel
                                            activeStep={proposal.activeSubStep || 0}
                                            connector={null}
                                            className={classes.subStep}>
                                            {getCollaboratorSteps().map((label, subIndex) => (
                                                <Step
                                                    key={label}
                                                    className={classNames(classes.pointer, subIndex === proposal.activeSubStep && classes.activePoint)}>
                                                    <StepButton
                                                        className={classes.btnroot}
                                                        focusVisibleClassName={classes.pointerr}
                                                        onClick={() => this.handleStep(proposal.activeStep, true, subIndex)}>
                                                        <StepLabel
                                                            classes={{
                                                                labelContainer: classes.lessMargin,
                                                                iconContainer: classes.hideIcon,
                                                                label: classes.whiteLabel
                                                            }}>
                                                            {label}
                                                        </StepLabel>
                                                    </StepButton>
                                                </Step>
                                            ))}
                                        </Stepper>
                                    </div>
                                    {proposal.activeSubStep === 0 && (
                                        <CollaboratorsProjectManagerTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            manager_requests={manager_requests}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            helpmessages={helpmessages}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 1 && (
                                        <CollaboratorsOrganizationTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 2 && (
                                        <CollaboratorsPartnersTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {proposal.activeStep === 2 && (
                                <GeneralTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    calcFundingTotal={this.calcFundingTotal}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    ActionMenu={
                                        <this.ActionMenu
                                            proposalDelegates={proposalDelegates}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            manager_requests={manager_requests}
                                            authState={authState}
                                            classes={classes}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            history={history}
                                            actionMenuClose={this.actionMenuClose}
                                        />
                                    }
                                />
                            )}
                            {proposal.activeStep === 3 && (
                                <NarrativeTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    ActionMenu={
                                        <this.ActionMenu
                                            proposalDelegates={proposalDelegates}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            manager_requests={manager_requests}
                                            authState={authState}
                                            classes={classes}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            history={history}
                                            actionMenuClose={this.actionMenuClose}
                                        />
                                    }
                                />
                            )}
                            {proposal.activeStep === 4 && (
                                <ActivityTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    viewMode={viewMode}
                                    ActionMenu={
                                        <this.ActionMenu
                                            proposalDelegates={proposalDelegates}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            manager_requests={manager_requests}
                                            authState={authState}
                                            classes={classes}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            history={history}
                                            actionMenuClose={this.actionMenuClose}
                                        />
                                    }
                                />
                            )}
                            {proposal.activeStep === 5 && (
                                <>
                                    <div className={classes.subStepContainer}>
                                        <Stepper
                                            nonLinear
                                            alternativeLabel
                                            activeStep={proposal.activeSubStep || 0}
                                            connector={null}
                                            className={classes.subStep}>
                                            {(viewMode && authState && authState.user.role === "Member" ? getMemberBugetSteps() : getBudgetSteps()).map(
                                                (label, subIndex) => {
                                                    if (subIndex === 3 && !Conservation && !Easement && !Fee) {
                                                        return (
                                                            <Tooltip title="You must select one of the options for the question 'Does your project include restoration or acquisition of land rights' under the General Step 3 above.">
                                                                <Step key={label} className={classes.disabledStep}>
                                                                    <StepButton className={classes.btnroot} focusVisibleClassName={classes.pointerr}>
                                                                        <StepLabel
                                                                            classes={{
                                                                                labelContainer: classes.lessMargin,
                                                                                iconContainer: classes.hideIcon,
                                                                                label: classes.whiteLabel
                                                                            }}>
                                                                            {label}
                                                                        </StepLabel>
                                                                    </StepButton>
                                                                </Step>
                                                            </Tooltip>
                                                        );
                                                    }
                                                    return (
                                                        <Step
                                                            key={label}
                                                            className={classNames(classes.pointer, subIndex === proposal.activeSubStep && classes.activePoint)}>
                                                            <StepButton
                                                                className={classes.btnroot}
                                                                focusVisibleClassName={classes.pointerr}
                                                                onClick={() => this.handleStep(proposal.activeStep, true, subIndex)}>
                                                                <StepLabel
                                                                    classes={{
                                                                        labelContainer: classes.lessMargin,
                                                                        iconContainer: classes.hideIcon,
                                                                        label: classes.whiteLabel
                                                                    }}>
                                                                    {label}
                                                                </StepLabel>
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                }
                                            )}
                                        </Stepper>
                                    </div>
                                    {proposal.activeSubStep === (viewMode ? -1 : 0) && (
                                        <BudgetPersonnelTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 1 && (
                                        <BudgetContractsTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 2 && (
                                        <BudgetExpendituresTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 3 && (
                                        <BudgetAcquisitionTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 4 && (
                                        <BudgetTravelTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 5 && (
                                        <BudgetPrintingTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 6 && (
                                        <BudgetOtherTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 7 && (
                                        <BudgetFundsTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === (viewMode ? 0 : 8) && (
                                        <BudgetSummaryTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            calcFundingTotal={this.calcFundingTotal}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {proposal.activeStep === 6 && (
                                <AttachmentsTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    viewMode={viewMode}
                                    ActionMenu={
                                        <this.ActionMenu
                                            proposalDelegates={proposalDelegates}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            manager_requests={manager_requests}
                                            authState={authState}
                                            classes={classes}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            history={history}
                                            actionMenuClose={this.actionMenuClose}
                                        />
                                    }
                                />
                            )}
                            {proposal.activeStep === 7 && (
                                <>
                                    <div className={classes.subStepContainer}>
                                        <Stepper
                                            nonLinear
                                            alternativeLabel
                                            activeStep={proposal.activeSubStep || 0}
                                            connector={null}
                                            className={classes.subStep}>
                                            {getParcelSteps().map((label, subIndex) => {
                                                if (
                                                    (subIndex === 4 && !Restoration) ||
                                                    (subIndex === 2 && !Conservation) ||
                                                    (subIndex === 3 && !Easement) ||
                                                    (subIndex === 1 && !Fee)
                                                ) {
                                                    return (
                                                        <Tooltip title="You must select the appropriate answer for the question 'Does your project include restoration or acquisition of land rights' under the General Step">
                                                            <Step
                                                                key={label}
                                                                className={classNames(
                                                                    classes.disabledStep,
                                                                    subIndex === proposal.activeSubStep && classes.activePoint
                                                                )}>
                                                                <StepButton
                                                                    disabled
                                                                    className={classes.btnroot}
                                                                    focusVisibleClassName={classes.pointerr}
                                                                    onClick={() => this.handleStep(proposal.activeStep, true, subIndex)}>
                                                                    <StepLabel
                                                                        classes={{
                                                                            labelContainer: classes.lessMargin,
                                                                            iconContainer: classes.hideIcon,
                                                                            label: classes.whiteLabel
                                                                        }}>
                                                                        {label}
                                                                    </StepLabel>
                                                                </StepButton>
                                                            </Step>
                                                        </Tooltip>
                                                    );
                                                }
                                                return (
                                                    <Step
                                                        key={label}
                                                        className={classNames(classes.pointer, subIndex === proposal.activeSubStep && classes.activePoint)}>
                                                        <StepButton
                                                            className={classes.btnroot}
                                                            focusVisibleClassName={classes.pointerr}
                                                            onClick={() => this.handleStep(proposal.activeStep, true, subIndex)}>
                                                            <StepLabel
                                                                classes={{
                                                                    labelContainer: classes.lessMargin,
                                                                    iconContainer: classes.hideIcon,
                                                                    label: classes.whiteLabel
                                                                }}>
                                                                {label}
                                                            </StepLabel>
                                                        </StepButton>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </div>
                                    {proposal.activeSubStep === 0 && (
                                        <AcquisitionParcelsTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 1 && (
                                        <AcquisitionFeeTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 2 && (
                                        <AcquisitionConservationTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 3 && (
                                        <AcquisitionOtherTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 4 && (
                                        <AcquisitionRestorationTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            getDateTimeStamp={this.getDateTimeStamp}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            )}
                            {proposal.activeStep === 8 && !is_dnr && !viewMode && !adminRole && (
                                <ReviewTab
                                    onRef={el => (this.activeForm = el)}
                                    proposal={proposal}
                                    appContainer={this.appContainer}
                                    handleNext={this.handleNext}
                                    handlePrev={this.handlePrev}
                                    handleUnsavedFields={this.handleUnsavedFields}
                                    getDateTimeStamp={this.getDateTimeStamp}
                                    settings={settings}
                                    getStaffKeys={this.getStaffKeys}
                                    ActionMenu={
                                        <this.ActionMenu
                                            proposalDelegates={proposalDelegates}
                                            ormManagerRequestDelete={ormManagerRequestDelete}
                                            manager_requests={manager_requests}
                                            authState={authState}
                                            classes={classes}
                                            proposal={proposal}
                                            actionMenuOpen={this.actionMenuOpen}
                                            anchorEl={this.state.anchorEl}
                                            history={history}
                                            actionMenuClose={this.actionMenuClose}
                                        />
                                    }
                                />
                            )}
                            {proposal.activeStep === 8 && !is_dnr && !viewMode && adminRole && (
                                <>
                                    <div className={classes.subStepContainer}>
                                        <Stepper
                                            nonLinear
                                            alternativeLabel
                                            activeStep={proposal.activeSubStep || 0}
                                            connector={null}
                                            className={classes.subStep}>
                                            {getStaffScoringSteps().map((label, subIndex) => {
                                                if (canViewstaffScore(subIndex, final_open, ReviewScoring)) {
                                                    return (
                                                        <Step
                                                            key={label}
                                                            className={classNames(classes.pointer, subIndex === proposal.activeSubStep && classes.activePoint)}>
                                                            <StepButton
                                                                className={classes.btnroot}
                                                                focusVisibleClassName={classes.pointerr}
                                                                onClick={() => this.handleStep(proposal.activeStep, true, subIndex)}>
                                                                <StepLabel
                                                                    classes={{
                                                                        labelContainer: classes.lessMargin,
                                                                        iconContainer: classes.hideIcon,
                                                                        label: classes.whiteLabel
                                                                    }}>
                                                                    {label}
                                                                </StepLabel>
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                } else {
                                                    return (
                                                        <Tooltip title={getStaffLockLabel(subIndex, final_open, !ReviewScoring)}>
                                                            <Step
                                                                key={label}
                                                                className={classNames(
                                                                    classes.disabledStep,
                                                                    subIndex === proposal.activeSubStep && classes.activePoint
                                                                )}>
                                                                <StepButton
                                                                    disabled
                                                                    className={classes.btnroot}
                                                                    focusVisibleClassName={classes.pointerr}
                                                                    onClick={() => this.handleStep(proposal.activeStep, true, subIndex)}>
                                                                    <StepLabel
                                                                        classes={{
                                                                            labelContainer: classes.lessMargin,
                                                                            iconContainer: classes.hideIcon,
                                                                            label: classes.whiteLabel
                                                                        }}>
                                                                        {label}
                                                                    </StepLabel>
                                                                </StepButton>
                                                            </Step>
                                                        </Tooltip>
                                                    );
                                                }
                                            })}
                                        </Stepper>
                                    </div>
                                    {proposal.activeSubStep === 0 && (
                                        <FinalComments
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            settings={settings}
                                            draftPage={true}
                                            getSteps={steps}
                                            getStaffKeys={this.getStaffKeys}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 1 && (
                                        <StaffScoreTab
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            settings={settings}
                                            staffSlot={ReviewScoring}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 2 && (
                                        <FinalComments
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            settings={settings}
                                            draftPage={false}
                                            getSteps={steps}
                                            getStaffKeys={this.getStaffKeys}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 3 && (
                                        <AggregateScoring
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            settings={settings}
                                            getStaffKeys={this.getStaffKeys}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 4 && (
                                        <MemberScore
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                    {proposal.activeSubStep === 5 && (
                                        <StaffAdmin
                                            onRef={el => (this.activeForm = el)}
                                            proposal={proposal}
                                            appContainer={this.appContainer}
                                            handleNext={this.handleNext}
                                            handlePrev={this.handlePrev}
                                            handleUnsavedFields={this.handleUnsavedFields}
                                            settings={settings}
                                            ActionMenu={
                                                <this.ActionMenu
                                                    proposalDelegates={proposalDelegates}
                                                    ormManagerRequestDelete={ormManagerRequestDelete}
                                                    manager_requests={manager_requests}
                                                    authState={authState}
                                                    classes={classes}
                                                    proposal={proposal}
                                                    actionMenuOpen={this.actionMenuOpen}
                                                    anchorEl={this.state.anchorEl}
                                                    history={history}
                                                    actionMenuClose={this.actionMenuClose}
                                                />
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={this.state.reqeuestWidrawal}
                    onClose={() => {
                        this.setState({ reqeuestWidrawal: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateWithdrawal}
                        defaultValues={proposal}
                        dontValidateOnMount={true}
                        onSubmit={values => this.requestWithDrawal(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Request Withdrawal</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ reqeuestWidrawal: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <TextField field="withdrawal_reason" label="Reason" fullWidth multiline />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ reqeuestWidrawal: null })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Send Request
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Dialog
                    open={this.state.revisionOpen}
                    onClose={() => {
                        this.setState({ revisionOpen: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateDueDate}
                        defaultValues={proposal}
                        dontValidateOnMount={true}
                        onSubmit={values => this.dueDate(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Add Revision Due Date</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ revisionOpen: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <DatePicker field="due_date" label="Select Due Date" fullWidth />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ revisionOpen: null })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Set Due Date and Send Proposal Back to Applicant for Revisions
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Dialog
                    open={this.state.addStaffComment}
                    onClose={() => {
                        this.setState({ addStaffComment: false });
                    }}
                    disableBackdropClick={true}>
                    <Form
                        validateOnSubmit={true}
                        validateError={this.validateComment}
                        defaultValues={{
                            comment_for: "Applicant",
                            step: proposal.activeStep
                        }}
                        dontValidateOnMount={true}
                        onSubmit={values => this.addStaffComment(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <DialogTitle className={classes.dialogTitleMargin} disableTypography id="form-dialog-title">
                                    <Typography variant="h3">Add Staff Comment</Typography>
                                    <IconButton
                                        aria-label="Close Dialog"
                                        className={classes.closeButton}
                                        onClick={() => {
                                            this.setState({ addStaffComment: false });
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <Typography>Step: {steps()[proposal.activeStep]}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                field="comment_for"
                                                name="comment_for"
                                                fullWidth
                                                options={[
                                                    {
                                                        label: "Applicant",
                                                        value: "Applicant"
                                                    },
                                                    {
                                                        label: "Member",
                                                        value: "Member"
                                                    },
                                                    {
                                                        label: "Staff",
                                                        value: "Staff"
                                                    }
                                                ]}
                                                label="Comment For"
                                                alignment={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField field="comment" label="Comment or requirement not met detail" fullWidth multiline />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ marginRight: 5 }} onClick={() => this.setState({ addStaffComment: null })}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" variant="contained" type="submit">
                                        Add Comment
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Form>
                </Dialog>
                <Snackbar handleSnackbarClose={this.handleSnackbarClose} isSuccess snackbarOpen={snackbarOpen} />
                <DelegateDialog
                    open={delegateDialog}
                    proposal={proposal}
                    bearer={authState}
                    ormProposalDelegateReload={ormProposalDelegateReload}
                    returnDialog={() => this.setState({ delegateDialog: false })}
                    classes={classes}
                    formkey={formkey}
                />
                <DeleteDelegateDialog
                    open={deleteDelegateDialog}
                    proposal={proposal}
                    delegates={proposalDelegates}
                    ormDelete={ormProposalDelegateDelete}
                    returnDialog={() => this.setState({ deleteDelegateDialog: false })}
                    classes={classes}
                    formkey={formkey}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormProposalDelete(proposal.id);
                        this.setState({ deleteOpen2: false });
                        history.push("/dashboard");
                    }}
                    cancelAction={() => this.setState({ deleteOpen2: false })}
                    open={deleteOpen2}
                    title="Permanently Delete Proposal"
                    confirmText="Permanently Delete Proposal"
                    text={"Are you sure you wish to delete " + proposal.name + "? All data will be permanently lost."}
                />
                <WarningDialog
                    confirmAction={() => {
                        this.submitCommentsApplicant();
                    }}
                    cancelAction={() => this.setState({ submitFeedBackComments: false })}
                    open={submitFeedBackComments}
                    title="Submit Comments to Applicant"
                    confirmText="Submit"
                    cancelText="Cancel"
                    text={"Are you sure you wish to submit comments to applicant? This will set status to Proposal Feedback Received."}
                />
            </AppContainer>
        );
    }
}

class DeleteDelegateDialog extends Component {
    deleteDelegation(values) {
        console.log(values);
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            id: isRequired(values.id)
        };
        return valObj;
    };

    render() {
        const { classes, delegates, open, returnDialog, formkey, proposal } = this.props;
        const filteredDelegates = delegates.filter(x => x.proposal === proposal.id);

        return (
            <Dialog role="main" aria-label="Remove Delegate Access" open={open} classes={{ paper: classes.nooverflow }} maxWidth="sm" fullWidth>
                <DialogTitle className={classes.titleContainer}>
                    <ErrorOutline className={classes.supportIcon} />
                    <IconButton
                        onClick={() => {
                            returnDialog();
                        }}
                        className={classes.closeIcon}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h1" className={classes.questionTitle} gutterBottom>
                        Remove Delegate Access
                    </Typography>
                    <DialogContentText>
                        <Typography>
                            <b>Removing Delegate Access means the user can no longer edit this proposal!</b>
                        </Typography>
                    </DialogContentText>
                    <Form
                        dontValidateOnMount={true}
                        key={formkey}
                        validateOnSubmit={true}
                        validateError={this.errorValidator}
                        onSubmit={values => this.deleteDelegation(values)}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Select
                                            required
                                            label="Select User"
                                            field="id"
                                            options={filteredDelegates.map(x => ({ value: x.id, label: x.email }))}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                this.setState({ message: null, loading: false });
                                                returnDialog();
                                            }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth type="submit" variant="contained" color="primary">
                                            Remove
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Form>
                </DialogContent>
            </Dialog>
        );
    }
}

class DelegateDialog extends Component {
    state = {
        loading: false,
        message: null
    };

    addDelegation(values) {
        const { ormProposalDelegateReload, proposal, returnDialog, bearer } = this.props;
        this.setState({ loading: true, message: null });

        fetch("/api/db/proposaldelegates/delegate", {
            method: "POST",
            headers: {
                Authorization: "Token " + bearer.user.auth_token,
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: JSON.stringify({
                email: values.email,
                proposal_id: proposal.id
            })
        })
            .then(resp => resp.json())
            .then(data => {
                if (data.error) {
                    this.setState({ message: data.message, loading: false });
                } else {
                    ormProposalDelegateReload();
                    this.setState({
                        message: null,
                        loading: false
                    });
                    returnDialog();
                }
            });
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            email: isRequired(values.email),
            agree: isRequired(values.agree)
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, formkey, proposal } = this.props;
        const { loading, message } = this.state;

        return (
            <Dialog role="main" aria-label="Add New User Permission" open={open}>
                <Toolbar>
                    <Typography variant="h1" className={classes.flex}>
                        Add New User Permission
                    </Typography>
                    <IconButton
                        aria-label="Close Dialog"
                        onClick={() => {
                            this.setState({ message: null, loading: false });
                            returnDialog();
                        }}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={formkey}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={values => this.addDelegation(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField field="email" label="Enter Email of User to Delegate Permission to" fullWidth />
                                            <Checkbox
                                                style={{ marginTop: 16 }}
                                                field="agree"
                                                label={
                                                    'I agree to give this user full access to edit my proposal ["' +
                                                    proposal.name +
                                                    '"], but understand they can not submit my proposal. As project manager, I retain responsibility for the accuracy of all information.'
                                                }
                                            />
                                        </Grid>
                                        {loading && (
                                            <div className={classes.progress}>
                                                <CircularProgress aria-label="Loading Icon" size={24} />
                                            </div>
                                        )}
                                        {!loading && (
                                            <>
                                                <Grid item xs={6}>
                                                    <Button
                                                        fullWidth
                                                        onClick={() => {
                                                            this.setState({ message: null, loading: false });
                                                            returnDialog();
                                                        }}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth type="submit" variant="contained" color="primary">
                                                        Save
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} style={{ color: "#be0000" }}>
                                                    {message}
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

List = connect(
    (state, ownProps) => ({
        synced: state.sync,
        proposal: getProposal(state, ownProps),
        proposalpersonnel: getProposalPersonnel(state, ownProps),
        proposalcontracts: getProposalContracts(state, ownProps),
        proposalexpenditures: getProposalExpenditures(state, ownProps),
        proposalacquisitions: getProposalAcquisitions(state, ownProps),
        proposaltravels: getProposalTravels(state, ownProps),
        proposalprintings: getProposalPrintings(state, ownProps),
        proposalexpenses: getProposalExpenses(state, ownProps),
        proposalfunds: getProposalNonENRTFFunds(state, ownProps),
        proposalDelegates: getDelegations(state, ownProps),
        helpmessages: getHelpMessages(state),
        authState: state.auth,
        staffSlots: getStaffSlots(state, ownProps),
        tab: getTab(state, ownProps),
        manager_requests: getManagerRequests(state, ownProps),
        rfps: getRFPs(state, ownProps)
    }),
    {
        ...Proposal.actions,
        ...ProposalCollaborator.actions,
        ...ProposalActivity.actions,
        ...ProposalMilestone.actions,
        ...ProposalPastProject.actions,
        ...ProposalPersonnel.actions,
        ...ProposalContract.actions,
        ...ProposalExpenditure.actions,
        ...ProposalAcquisition.actions,
        ...ProposalTravel.actions,
        ...ProposalPrinting.actions,
        ...ProposalExpense.actions,
        ...ProposalNonENRTFFund.actions,
        ...ProposalAttachment.actions,
        ...ProposalParcel.actions,
        ...ProposalDelegate.actions,
        ...ProposalView.actions,
        ...ProposalReview.actions,
        ...StaffScore.actions,
        ...StaffComment.actions,
        ...ManagerRequest.actions
    }
)(List);
export default withStyles(styles)(withRouter(List));
