import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";

import TextField from "../common/TextField";
import { ManagerRequest } from "../workplan/models";


const styles = theme => ({
    rightAlign: {
        textAlign: "right"
    },
    button: {
        marginRight: theme.spacing.unit * 2
    },
    link: {
        cursor: "pointer"
    },
    flex: {
        flex: 1,
        fontWeight: 500,
        fontSize: "1.15rem",
        color: "#495057",
        lineHeight: 1.2
    }
});

const getManager = ManagerRequest.selectByUrlId(proposal => ({
    formData: proposal.ref,
    ...proposal.ref,
}));

class ManagerApprove extends Component {
    state = {
        OrgThanksDialogOpen: false,
        try_again: false
    };

    componentDidMount() {
        document.title = "Approve Project Manager - LCCMR Proposal and Grant Management System";
        const { managerequest, authState, history, match, ormManagerRequestLoadDetail } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
        const _this = this;
        if (!managerequest && authState && authState.user) {
            ormManagerRequestLoadDetail(match.params.id, function() {
                setTimeout(function() {
                    _this.setState({ formkey: window.performance.now() });
                }, 1);
            });
        }
    }

    componentDidUpdate = e => {
        const { managerequest, authState, match, ormManagerRequestLoadDetail } = this.props;
        const { try_again } = this.state;
        const _this = this;
        if (!managerequest && authState && authState.user && !try_again)
            ormManagerRequestLoadDetail(match.params.id, function(dta) {
                if (!dta || dta[0].detail === "Not found.")
                    _this.setState({ try_again: true });
                else {
                    setTimeout(function() {
                        _this.setState({ formkey: window.performance.now() });
                    }, 1);
                }
            });
    };

    saveRequest(values) {
        const { ormManagerRequestUpdate, managerequest, history } = this.props;
        values.status = "Declined";
        ormManagerRequestUpdate({
            id: managerequest.id,
            ...values
        });
        history.push("/dashboard");
    }

    saveRequestApprove() {
        const { ormManagerRequestUpdate, managerequest, history } = this.props;
        ormManagerRequestUpdate({
            id: managerequest.id,
            status: "Approved"
        });
        history.push("/dashboard");
    }

    errorValidator = values => {
        const validateRequired = name => {
            return !name ? "Required" : null;
        };
        var valObj = {
            decline_reason: validateRequired(values.decline_reason),
        };

        return valObj;
    };

    returnDialog = values => {
        const { history } = this.props;
        history.push("/dashboard");
    };

    render() {
        const { classes, managerequest } = this.props;
        const { formkey } = this.state

        return (
            <AppContainer authenticated>
                <PageHeader title="" admin_page />
                <Dialog role="main" aria-label="Request to Change Project Manager" open={true}>
                    <Toolbar>
                        <Typography variant="h1" className={classes.flex}>
                            Request to Change Project Manager
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.returnDialog()}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            {!managerequest ? (
                                <Typography>This request does not exist.</Typography>
                            ) : (
                                <Form
                                    dontValidateOnMount={true}
                                    key={formkey}
                                    defaultValues={managerequest}
                                    validateOnSubmit={true}
                                    validateError={this.errorValidator}
                                    onSubmit={values => this.saveRequest(values)}>
                                    {formApi => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Grid container spacing={16}>
                                                <Grid item xs={12}>
                                                    <Typography>A request to change the project manager for project {managerequest.info_string}.</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField disabled label="First Name" field="first_name" fullWidth />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField disabled label="Last Name" field="last_name" fullWidth />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField disabled label="Email" field="email" fullWidth />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField disabled multiline label="Why is the change needed?" field="why" fullWidth />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField multiline label="Reason for declining request" field="decline_reason" fullWidth />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth type="submit">
                                                        Decline
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth variant="contained" color="primary" onClick={() => this.saveRequestApprove()}>
                                                        Approve
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Form>
                            )}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainer>
        );
    }
}


ManagerApprove = connect(
    (state, ownProps) => ({
        authState: state.auth,
        managerequest: getManager(state, ownProps),
    }),
    {
        ...ManagerRequest.actions
    }
)(ManagerApprove);

export default withStyles(styles)(withRouter(ManagerApprove));
